import { formatToInt, round } from "lib/number";
import {
  DisplayRendererMetric,
  Metric,
  Project,
  RendererMetric,
  Summary,
} from "lib/types";
import { emptyRendererMetric } from "./constants";
import { makeSummaryMetrics } from "./table-summary";
import {
  calculateConferenceRoomCount,
  calculateDensity,
  calculateDeskToDoor,
} from "./util";

export const findBuildingMetrics = (
  project: Project,
  buildingID: string,
  strategyID: string
): Metric[] => {
  return Object.values(project.metrics).filter((m) => {
    return m.buildingID === buildingID && m.strategyID === strategyID;
  });
};

export const makeRendererSummary = (
  project: Project,
  buildingID: string,
  strategyID: string
): Summary => {
  const buildingMetrics = findBuildingMetrics(project, buildingID, strategyID);
  return makeSummaryMetrics(buildingMetrics);
};

// generates values for floor & building metrics table
export const makeRendererMetrics = (
  metrics: Metric[]
): DisplayRendererMetric => {
  const summary = Object.values(metrics).reduce<RendererMetric>(
    (acc, curr) => {
      let convertedPhonesCount = 0;
      if (curr.userConvertedMeetingRooms) {
        Object.values(curr.userConvertedMeetingRooms).forEach((conversion) => {
          if (conversion && conversion.length !== 0) {
            conversion.forEach((room) => {
              if (room.conferenceRoomType === "phone") convertedPhonesCount++;
            });
          }
        });
      }

      // misplacedPhones is used to track phones in buildings that were dxf
      // imported. When this happens, phonebooths are not saved to the right location
      acc.seatCount += curr.seatCount;
      acc.workpoints += curr.workpoints || 0;
      acc.convertedSeats += curr.convertedSeats;
      acc.collaborationZonesArea += curr.collaborationZonesArea;
      acc.collaborationZones += curr.collaborationZones;
      acc.totalFloorArea += curr.totalFloorArea;
      acc.convertedMeetingRooms += curr.convertedMeetingRooms;
      acc.collaborationSeats += curr.collaborationSeats;
      acc.baselineConferenceRoomsCount += curr.baselineConferenceRoomsCount;
      acc.totalCost += curr.totalCost;
      acc.costHC += curr.costHC;
      acc.costUnit += curr.costUnit;
      acc.costGSF += curr.costGSF;
      acc.baselineHeadCount += curr.baselineHeadCount;
      acc.baselineSeatCount += curr.baselineSeatCount;
      acc.assignableSeats +=
        (curr.nooksAssignable || 0) +
        (curr.deskCount || 0) +
        // LM-Note: the logic below for handling floorscopeDesks is clarified
        // within the MR below, in the description and in a thread with JV
        // https://gitlab.com/outerlabs/portfolio/-/merge_requests/1039
        (curr.floorscopeDesks !== 0 ||
        (curr.floorscopeDesks === 0 &&
          (curr.deskCount || curr.phonesCount || curr.nooksCount))
          ? curr.floorscopeDesks
          : curr.seatCount);
      acc.efficiencyRatio = curr.efficiencyRatio;
      acc.nooksCount += curr.nooksCount;
      // all phones from within desks
      acc.phonesCount += curr.phonesCount;
      // phones from within desks and floorscope phones
      acc.phone +=
        (curr.phone ? curr.phone.count : 0) +
        curr.phonesCount -
        convertedPhonesCount;

      acc.doors += curr.doors
        ? curr.doors
        : (curr.phone ? curr.phone.count : 0) +
          (curr.huddle ? curr.huddle.count : 0) +
          (curr.extraLarge ? curr.extraLarge.count : 0) +
          (curr.large ? curr.large.count : 0) +
          (curr.medium ? curr.medium.count : 0) +
          (curr.small ? curr.small.count : 0) +
          (curr.phonesCount || 0);

      acc.assetHC += curr.assetHC;

      return acc;
    },
    { ...emptyRendererMetric }
  );

  // calculateDeskToDoor divides the number of seats by the number of doors,
  // and does not take an average across floors
  // do not use this value for a building desk-to-door ratio
  const deskToDoor = calculateDeskToDoor(summary);
  const density = calculateDensity(summary);

  return {
    ...summary,
    conferenceRoomsCount: calculateConferenceRoomCount(summary),
    avgCollabArea:
      summary.collaborationZonesArea === 0
        ? "0"
        : formatToInt(
            summary.collaborationZonesArea / summary.collaborationZones,
            "sf"
          ),
    deskToDoor,
    density: !isNaN(density) && density !== Infinity ? round(density) : 0,
    collabTotal: formatToInt(summary.collaborationZonesArea, "sf"),
  };
};
