import { makeStyles } from "@material-ui/core";
import * as React from "react";
import { Block } from "../lib/types";
import { useBlocksCtrl } from "../../lib/containers";
import { emptyDefinitionProp, getters } from "../lib/constants";
import { ReactComponent as VerticalIcon } from "../assets/vertical.svg";
import { ReactComponent as HorizontalIcon } from "../assets/horizontal.svg";
const blue = "67,132,247";

export const useLayersStyles = makeStyles(() => ({
  root: {
    border: "1px solid #e8e8e8",
    position: "absolute",
    top: 0,
    left: 0,
    background: "white",
    height: "auto",
    minWidth: 240,
    paddingRight: 16,
    paddingBottom: 20,
  },
  title: {
    padding: "16px",
    fontSize: "1.0rem",
  },
}));

export const useLayerStyles = makeStyles(() => ({
  root: {
    lineHeight: 2,
  },
  active: {
    background: `rgba(${blue},0.1)`,
  },
  icon: {
    height: 10,
    width: 10,
    marginRight: 6,
  },
  title: {
    "&:hover": {
      background: `rgba(${blue},0.3)`,
    },
    fontSize: "0.9rem",
    paddingLeft: 16,
    cursor: "pointer",
  },
}));

type LayersProps = {
  open: boolean;
  block: Block;
  onHover: (id: string) => void;
  onClick: (id: string) => void;
};

type LayerProps = {
  i: string;
  block: Block;
  level: number;
  onHover: (id: string) => void;
  onClick: (id: string) => void;
};

const Layer = ({ block, level, onHover, onClick, i }: LayerProps) => {
  const classes = useLayerStyles();
  const { getBlockById } = useBlocksCtrl();
  const ref = getBlockById(block.symbol);
  const def = ref ? getters.getDefinition(ref) : { ...emptyDefinitionProp };
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const mode = block.props.layout!.flex!.mode || "";
  const flexMode = mode.charAt(0).toUpperCase() + mode.slice(1);
  const [isHovering, setIsHovering] = React.useState(false);
  let Icon;
  if (flexMode === "Vertical") Icon = VerticalIcon;
  else if (flexMode === "Horizontal") Icon = HorizontalIcon;
  return (
    <div
      className={`${classes.root} ${isHovering ? classes.active : ""}`}
      style={{ marginLeft: `${level * 12}px` }}
    >
      <div
        className={classes.title}
        onMouseEnter={() => {
          setIsHovering(true);
          onHover(block.id);
        }}
        onMouseLeave={() => {
          setIsHovering(false);
          onHover("");
        }}
        onClick={() => {
          onClick(block.id);
        }}
      >
        {block.children.length > 0 ? (
          <>
            {Icon && <Icon className={classes.icon} />} {flexMode} Container
          </>
        ) : (
          <>{def.name}</>
        )}
      </div>
      {block.children.length > 0 &&
        block.children.map((child, j) => (
          <Layer
            block={child}
            onClick={onClick}
            level={level + 1}
            onHover={onHover}
            i={`${i}.${j + 1}`}
          />
        ))}
    </div>
  );
};

const Layers = ({ open, block, onHover, onClick }: LayersProps) => {
  const classes = useLayersStyles();
  if (!open) return null;
  return (
    <div className={classes.root}>
      <div className={classes.title}>Layers</div>
      <Layer
        block={block}
        level={0}
        onHover={onHover}
        onClick={onClick}
        i={1 + ""}
      />
    </div>
  );
};

export default Layers;
