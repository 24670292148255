import getFeatures from "lib/api/getFeatures";
import generatePortfolioBlockInstances from "lib/isp-canvas/generate-block-instance";
import {
  getRegionLengths,
  sortRegion,
  toRegion,
  toRotation,
} from "lib/isp-canvas/utils";
import { encodeMetricId } from "lib/metrics/id";
import { calcDesks } from "lib/metrics/remove-desks";
import { PortfolioBlockInstance, RendererSettings } from "lib/types";
import { ComponentType } from "react";
import { ContainerProviderProps, createContainer } from "unstated-next";
import { useISPTransformCtrl } from "./isp-transform";
import { useProjectCtrl } from "./project";
import { BlockType } from "./renderer";
import { useSettingsCtrl } from "./settings";
import { useBlocksCtrl } from "./blocks";
import { getters } from "../../blocks/lib/constants";
import { Block } from "../../blocks/lib/types";
import { mat4, quat, vec3 } from "gl-matrix";
import { mergeDeep } from "blocks/lib/util";

export interface UseBlockInstanceState {
  updateSelectedBlock({
    blockID,
    idx,
    buildingID,
    floorID,
    strategyID,
  }: {
    blockID: string;
    idx: number;
    buildingID: string;
    floorID: string;
    strategyID: string;
  }): Promise<void>;
  addInstance({
    buildingID,
    floorID,
    strategyID,
    region,
    blockID,
    blockType,
    instances,
    blockMutation,
  }: {
    buildingID: string;
    floorID: string;
    strategyID: string;
    region?: number[][];
    blockID?: string;
    blockType: BlockType;
    instances?: Block[];
    blockMutation?: Partial<Block>;
  }): Promise<RendererSettings | undefined>;
  newInstance({
    blockId,
    region,
    blockType,
    instances,
  }: {
    region?: number[][];
    blockId?: string;
    blockType: BlockType;
    instances?: Block[];
  }): PortfolioBlockInstance[];

  duplicateInstance({
    buildingID,
    floorID,
    strategyID,
    instances,
  }: {
    buildingID: string;
    floorID: string;
    strategyID: string;
    instances: PortfolioBlockInstance[][];
  }): Promise<PortfolioBlockInstance[][] | undefined>;
  removeInstance(
    buildingId: string,
    floorId: string,
    strategyID: string,
    regionIdx: number[]
  ): Promise<RendererSettings | undefined>;
  updateInstance({
    buildingID,
    floorID,
    strategyID,
    regionIdx,
    instance,
    resize,
  }: {
    buildingID: string;
    floorID: string;
    strategyID: string;
    regionIdx: number;
    instance: PortfolioBlockInstance[];
    resize: boolean;
  }): Promise<RendererSettings | undefined>;
  moveInstances({
    idx,
    buildingID,
    floorID,
    strategyID,
    x,
    y,
  }: {
    idx: number[];
    buildingID: string;
    floorID: string;
    strategyID: string;
    x: number;
    y: number;
  }): Promise<void>;
}

export const useBlockInstanceState = (): UseBlockInstanceState => {
  const { getBlockById, getBlocksBySizeAndType, defaultFocusBlockID } =
    useBlocksCtrl();
  const { project } = useProjectCtrl();
  const { setTransformBlockInstance } = useISPTransformCtrl();
  const { currentSettings, saveFloor } = useSettingsCtrl();

  // create a block instance given a region and optional blockId
  const createBlockInstances = ({
    blockId,
    region,
    blockType,
    instances,
  }: {
    region?: number[][];
    blockId?: string;
    blockType: BlockType;
    instances?: Block[];
  }): Block[][] => {
    let shouldRotate = false;
    if (!instances) {
      if (!region) return [];
      const [width, height] = getRegionLengths(region);
      // get all blocks that can fit in this region, along with the optimal rotation
      const blockResults = getBlocksBySizeAndType(width, height, blockType);
      if (blockResults.length > 0) {
        let idx = 0;
        // if we have a blockId, we still want to find it in the blockResults as it has the optimal rotation
        if (blockId) {
          const match = blockResults.findIndex(({ id }) => id === blockId);
          if (match !== -1) shouldRotate = blockResults[match].rotated;
        } else if (blockType === BlockType.Focus) {
          blockResults.forEach(({ id }, i) => {
            if (id === defaultFocusBlockID) idx = i;
          });
        }
        // only assign an id if we don't have one already
        // we currently pick the first result since they are sorted by fit
        if (!blockId && blockResults.length > 0) {
          blockId = blockResults[idx].id;
          shouldRotate = blockResults[idx].rotated;
        }
      }

      const block = getBlockById(blockId);
      if (!block) return [];
      if (shouldRotate) {
        const sortedRegion = sortRegion(region);
        region = sortedRegion;
        region[2][0] = region[0][0] + height;
        region[3][0] = region[0][0] + height;
        region[1][1] = region[0][1];
        region[2][1] = region[0][1];
        region[0][1] = region[0][1] - width;
        region[3][1] = region[0][1] - width;
      }

      instances = generatePortfolioBlockInstances({
        block,
        region,
        blockRotation: shouldRotate ? 90 : 0,
        regionRotation: 0,
        getBlockById,
      });
      if (shouldRotate) {
        instances.forEach((el: Block) => {
          el.matrix = mat4.mul(
            mat4.create(),
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            el.matrix!,
            mat4.fromRotationTranslation(
              mat4.create(),
              quat.fromEuler(quat.create(), 0, 0, 90),
              vec3.fromValues(width, -height, 0)
            )
          );
        });
      }
      instances.forEach((el: Block) => {
        const layout = getters.getLayout(el);
        layout.mirrorY = true;
        el.props.layout = layout;
      });
    }
    return [instances];
  };

  // creates a new block instance, recomputes metrics, and saves the settings
  const addInstance = async ({
    buildingID,
    floorID,
    strategyID,
    blockID,
    region,
    blockType,
    instances,
    // V Can be supplied to add some additional properties to newly created blocks
    blockMutation,
  }: {
    buildingID: string;
    floorID: string;
    strategyID: string;
    region?: number[][];
    blockID?: string;
    blockType: BlockType;
    instances?: Block[];
    blockMutation?: Partial<Block>;
  }): Promise<RendererSettings | undefined> => {
    if (!project) return;
    const newBlocks = createBlockInstances({
      region,
      blockId: blockID,
      blockType,
      instances,
    });
    if (blockMutation) {
      // Note: only used in matchmaker team, so we are manually putting this part in...
      if (blockMutation.props?.instance?.teamInfo) {
        for (let i = 0; i < newBlocks.length; i++) {
          for (let j = 0; j < newBlocks[i].length; j++) {
            newBlocks[i][j] = mergeDeep(newBlocks[i][j], blockMutation);
          }
        }
      }
    }
    const blockInstances = currentSettings.blocks.concat(newBlocks);
    const features = await getFeatures(buildingID, floorID);
    const id = encodeMetricId(buildingID, strategyID, floorID);
    const metric = project.metrics[id];
    const desks = calcDesks(metric, features, blockInstances);
    return {
      ...currentSettings,
      desks,
      blocks: blockInstances,
    };
  };

  // creates a new block instance
  const newInstance = ({
    blockId,
    region,
    blockType,
    instances,
  }: {
    region?: number[][];
    blockId?: string;
    blockType: BlockType;
    instances?: Block[];
  }): PortfolioBlockInstance[] => {
    const blockInstances = createBlockInstances({
      region,
      blockId,
      blockType,
      instances,
    });
    return blockInstances.map((el) => el[0]);
  };

  const duplicateInstance = async ({
    buildingID,
    floorID,
    strategyID,
    instances,
  }: {
    buildingID: string;
    floorID: string;
    strategyID: string;
    instances: PortfolioBlockInstance[][];
  }): Promise<PortfolioBlockInstance[][] | undefined> => {
    if (!project) return;
    const newInstances = instances.map((instance) => {
      const nr = toRegion(instance[0]).map((a: number[]) => [
        a[0] + 40,
        a[1] - 40,
      ]);
      const block = getBlockById(instance[0].symbol);
      const newInst = generatePortfolioBlockInstances({
        block,
        region: nr,
        matrix: instance[0].matrix,
        blockRotation: 0,
        regionRotation: 0,
        getBlockById,
      });
      const layout = getters.getLayout(instance[0]);
      newInst[0].props.layout = layout;
      const tx = mat4.mul(
        mat4.create(),
        mat4.fromTranslation(mat4.create(), vec3.fromValues(40, -40, 0)),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        newInst[0].matrix!
      );
      newInst[0].matrix = tx;
      return newInst;
    });
    const blocks =
      currentSettings && currentSettings.blocks
        ? [...currentSettings.blocks, ...newInstances]
        : newInstances;

    const features = await getFeatures(buildingID, floorID);
    const id = encodeMetricId(buildingID, strategyID, floorID);
    const metric = project.metrics[id];
    const desks = calcDesks(metric, features, blocks);
    await saveFloor({
      settings: {
        ...currentSettings,
        desks,
        blocks,
      },
      buildingID,
      floorID,
      strategyID,
      projectID: project.id,
    });
    return newInstances;
  };

  const removeInstance = async (
    buildingID: string,
    floorID: string,
    strategyID: string,
    regionIdx: number[]
  ): Promise<RendererSettings | undefined> => {
    if (!project) return;
    const features = await getFeatures(buildingID, floorID);
    const id = encodeMetricId(buildingID, strategyID, floorID);
    const metric = project.metrics[id];
    const remove: string[] = [];
    const blockArr: Block[][] = [];
    regionIdx.forEach((idx) => {
      blockArr.push(currentSettings.blocks[idx]);
    });

    blockArr.forEach((block) => {
      const blockInd = currentSettings.blocks.indexOf(block);
      if (
        currentSettings &&
        currentSettings.blocks &&
        currentSettings.blocks[blockInd]
      ) {
        remove.push(currentSettings.blocks[blockInd][0].id);
      }

      currentSettings.blocks.splice(blockInd, 1);
    });

    const desks = calcDesks(metric, features, currentSettings?.blocks);
    let blockDeleteArr: string[];

    // prevent overwriting of blockChanges.delete when doing multiple deletions
    if (
      currentSettings &&
      currentSettings.blockChanges &&
      currentSettings.blockChanges.delete
    ) {
      blockDeleteArr = [...currentSettings.blockChanges.delete, ...remove];
    } else blockDeleteArr = remove;

    return {
      ...currentSettings,
      desks,
      blocks: currentSettings ? currentSettings.blocks : [],
      blockChanges: { put: [], delete: blockDeleteArr },
    };
  };

  const updateInstance = async ({
    buildingID,
    floorID,
    strategyID,
    regionIdx,
    instance,
    resize,
  }: {
    buildingID: string;
    floorID: string;
    strategyID: string;
    regionIdx: number;
    instance: PortfolioBlockInstance[];
    resize: boolean;
  }): Promise<RendererSettings | undefined> => {
    if (!project || !currentSettings) return;
    const features = await getFeatures(buildingID, floorID);
    const id = encodeMetricId(buildingID, strategyID, floorID);
    const metric = project.metrics[id];

    const old = currentSettings.blocks[regionIdx];
    currentSettings.blocks[regionIdx] = instance;

    const desks = calcDesks(metric, features, currentSettings.blocks);

    // if you only moved a block, else (you stretched a block)
    if (old[0].id === instance[0].id) {
      return {
        ...currentSettings,
        desks,
        blocks: currentSettings ? currentSettings.blocks : [],
        blockChanges: { put: [instance[0].id], delete: [] },
      };
    } else if (resize) {
      return {
        ...currentSettings,
        desks,
        blocks: currentSettings ? currentSettings.blocks : [],
        blockChanges: { put: [instance[0].id], delete: [old[0].id] },
      };
    }
  };

  const moveInstances = async ({
    idx,
    buildingID,
    floorID,
    strategyID,
    x,
    y,
  }: {
    idx: number[];
    buildingID: string;
    floorID: string;
    strategyID: string;
    x: number;
    y: number;
  }): Promise<void> => {
    if (!project || !currentSettings || !currentSettings.blocks) return;
    const instances: Block[][] = [];
    const ids: string[] = [];
    idx.forEach((i) => {
      const instance = currentSettings.blocks[i];
      instance[0].matrix = mat4.mul(
        mat4.create(),
        mat4.fromTranslation(mat4.create(), vec3.fromValues(x, y, 0)),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        instance[0].matrix!
      );
      instances.push(instance);
      ids.push(instance[0].id);
    });
    setTransformBlockInstance(instances);
    await saveFloor({
      settings: currentSettings,
      buildingID,
      floorID,
      strategyID,
      projectID: project.id,
    });
  };

  const updateSelectedBlock = async ({
    blockID,
    idx,
    buildingID,
    floorID,
    strategyID,
  }: {
    blockID: string;
    idx: number;
    region: number[][];
    buildingID: string;
    floorID: string;
    strategyID: string;
  }): Promise<void> => {
    if (!project || !currentSettings || !currentSettings.blocks) return;
    const block = getBlockById(blockID);
    const instance = currentSettings.blocks[idx];
    if (!block || !block.props.metrics) return;
    const { sizeRange } = getters.getMetrics(block);
    const { size } = getters.getMetrics(instance[0]);
    const s = [
      Math.max(Math.min(size[0], sizeRange[0][1]), sizeRange[0][0]),
      Math.max(Math.min(size[1], sizeRange[1][1]), sizeRange[1][0]),
    ];
    const region = sortRegion([
      [0, s[1]],
      [0, 0],
      [s[0], 0],
      [s[0], s[1]],
    ]);

    const newNewInstance = generatePortfolioBlockInstances({
      block,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      matrix: instance[0].matrix!,
      region,
      blockRotation: toRotation(instance[0].matrix),
      regionRotation: toRotation(instance[0].matrix),
      getBlockById,
    });
    newNewInstance.forEach((el: Block) => {
      const layout = getters.getLayout(el);
      layout.mirrorY = true;
      el.props.layout = layout;
    });
    newNewInstance[0].id = instance[0].id;
    currentSettings.blocks[idx] = newNewInstance;
    setTransformBlockInstance([newNewInstance]);
    await saveFloor({
      settings: currentSettings,
      buildingID,
      floorID,
      strategyID,
      projectID: project.id,
    });
  };

  return {
    updateSelectedBlock,
    duplicateInstance,
    addInstance,
    newInstance,
    removeInstance,
    updateInstance,
    moveInstances,
  };
};

export const BlockInstanceController = createContainer(useBlockInstanceState);
export const BlockInstanceProvider: ComponentType<ContainerProviderProps> =
  BlockInstanceController.Provider;
export const useBlockInstanceCtrl = () =>
  BlockInstanceController.useContainer();
