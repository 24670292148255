import { ExtShape, ShapeStyleProps } from "@outerlabs/shapes-core";
import { Box, Edge } from "@outerlabs/shapes-geometry";
import { mat4 } from "gl-matrix";
import { MMUserProfile, MMUserProfileMetadata, Team } from "lib/containers";

//define metrics at the block and asset level
export type StyleProps = ShapeStyleProps & {
  radius?: [number, number, number, number];
  fillOpacity?: number;
};

export type ShapeProps = {
  name: string;
  sides?: SideProps;
  layout?: LayoutProps;
  figma?: string;
  constraints?: ConstraintProps;
  style?: StyleProps;
  definition?: DefinitionProps;
  metadata?: MetadataProps;
  metrics?: MetricsProps;
  paths?: string[];
  svg?: string;
  instance?: InstanceProps;
};

export type InstanceProps = {
  teamInfo?: BlockMatchMakerTeamInfoForProps;
  instanceId: string;
};

export type MetadataProps = {
  authorName: string;
  authorEmail: string;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  deletedBy?: string;
};

export type MetricsProps = {
  size: [number, number];
  seats: number;
  workpoints: number;
  headcount: number;
  area: number;
  areaRange: Range;
  sizeRange: [Range, Range];
  seatsRange: Range;
  workpointsRange: Range;
  headcountRange: Range;
  tags: Counts;
  names: Counts;
  types: { [k: string]: TypeMetric };
  // noise level - 0 = quiet, 1 = vibrant
  vibrancy?: number;
  // walls - 0 = open, 0.5 = translucent, 1 = closed/partitioned
  openness?: number;
  costUnit: number;
  costGSF: number;
  // costHC is defined per the HC value, not taken from range
  costHC: number;
  // totalCost is defined per the GSF value, not taken from range
  totalCost: number;
  // costHC range is used to calculate the min and max cost per seat, doesnt depend on GSF
  costHCRange: number[];
  // totalCostRange is used to calculate the total possible cost range, doesnt depend on GSF
  totalCostRange: number[];
  // sharing ratio
  sharingRatio: number;
  // door count
  doors: number;
  doorsRange: [number, number];
  // asset count for costing
  assetHC: number;
};

export type TypeMetric = {
  area: number;
  seats: number;
  workpoints: number;
  headcount: number;
  areaRange: Range;
  seatsRange: Range;
  workpointsRange: Range;
  headcountRange: Range;
  tags: Counts;
  names: Counts;
  costGSF: number;
  costUnit: number;
  costHC: number;
  totalCost: number;
  costHCRange: number[];
  totalCostRange: number[];
  sharingRatio: number;
  doors: number;
};

export type Counts = { [k: string]: number };
export type Range = [number, number];
// define blocks metrics
export type BlockMetrics = {
  area: number;
  seats: number;
  tags: Counts;
  names: Counts;
  types: {
    [k: string]: {
      area: number;
      seats: number;
      tags: Counts;
      names: Counts;
    };
  };
  costUnit: number;
  costGSF: number;
  totalCost: number[];
  costHC: number[];
  doors: number;
  doorsRange: [number, number];
};

export type BlockMatchMakerTeamInfoForProps = {
  team: Team;
  matchmakerProfileMap: Record<MMUserProfile, MMUserProfileMetadata>;
  teamIdx: number;
};

export type DefinitionProps = {
  name: string;
  description: string;
  library: string;
  // ex: Team
  category: string;
  // ex: Workspace
  subcategory: string;
  // ex: Focus
  type: string;
  // ex: Small
  subtype: string;
  // background color
  fillColor: string;
  // ex: Blueprint URL
  url: string;
  // ex: ["Review", "Research"]
  // or: ["activity=Review", "activity=Research""]??
  tags: string[];
  status: string;
  activity: string;
  // ex: Individual
  matchmakerTeamInfo?: BlockMatchMakerTeamInfoForProps;
};

export type ConstraintProps = {
  // ignored if parent is not flex
  // determines alignment on the parent's counter axis, defaults to inherit
  align?: "min" | "center" | "max" | "stretch" | "inherit";
  // will this block grow to fill available space
  grow?: number;
  vertical?: "min" | "center" | "max" | "stretch" | "scale";
  horizontal?: "min" | "center" | "max" | "stretch" | "scale";
};

export type Divider = {
  type: string; // locker, wall, glass...
  size?: number; // only if mode is fixed
  start?: number; // only if mode is auto
  end?: number; // only if mode is auto
  mode?: "fixed" | "auto";
  align?: "min" | "center" | "max" | "stretch";
  // < 0 for min direction, > 0 for max direction
  // if align = max, offset <= 0; align = min, offset >= 0;
  // has no effect if align = stretch
  offset?: number;
};

export type Constraints = {
  // proximity to facade - 0 = core, 1 = facade
  facade?: number;
  // noise level - 0 = quiet, 1 = vibrant
  vibrancy?: number;
  // walls - 0 = open, 0.5 = translucent, 1 = closed/partitioned
  openness?: number;
};

export type Conditions = {
  open?: number;
};

export type SideProps = {
  constraints?: [Constraints, Constraints, Constraints, Constraints];
  conditions?: [Conditions, Conditions, Conditions, Conditions];
  dividers?: [Divider, Divider, Divider, Divider];
};

export type LayoutPropsFlex = {
  mode?: Direction;
  // properties below applicable only if layoutMode != "NONE"
  primarySizing?: "fixed" | "auto";
  crossSizing?: "fixed" | "auto";
  primaryAlign?: "min" | "max" | "center" | "spaceBetween" | "spaceAround";
  crossAlign?: "min" | "max" | "center";
  spacing?: number;
  repeat?: [number, number];
  primaryMirror?: boolean;
  crossMirror?: boolean;
};

export type LayoutPropsGrid = {
  horizontalSpacing?: number[];
  verticalSpacing?: number[];
  repeat?: [[number, number], [number, number]];
  horizontalMirror: boolean;
  verticalMirror: boolean;
};

export type LayoutProps = {
  mode: "flex" | "grid" | "none";
  flex?: LayoutPropsFlex;
  grid?: LayoutPropsGrid;
  offset?: [number, number, number, number];
  padding?: [number, number, number, number];
  rotation?: number;
  mirrorX?: boolean;
  mirrorY?: boolean;
  flexibility?: [number, number];
};

export type Block = ExtShape<ShapeProps, unknown>;

export type BlockGetter = (id: string | undefined) => Block | undefined;

export interface Coord {
  x: number;
  y: number;
}

export interface Rect {
  x: number;
  y: number;
  w: number;
  h: number;
}

export enum Position {
  Top = "top",
  Bottom = "bottom",
  Left = "left",
  Right = "right",
}

export interface Button extends Rect {
  p: Position;
}

export interface Transform {
  scale: number;
  tl: Coord;
  rotation: number;
}

export interface Dimensions {
  w: number;
  h: number;
}

export type Direction = "horizontal" | "vertical" | "none";

export type HitBox = {
  box: Box;
  depth: number;
  block: Block;
  blockMat: mat4;
  line: Edge;
  mat: mat4;
  idx: number;
  outer: boolean;
};

export type LibraryState = "unpublished" | "published" | "approved" | "deleted";
export type Library = {
  id: string;
  title: string;
  description: string;
  image: string;
  state: LibraryState;
  deletedBy: string;
  createdAt: string;
  updated: string;
  deletedAt: string;
};

export type BlockResult = {
  id: string;
  score: number;
  rotated: boolean;
};
