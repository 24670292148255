import React, { useCallback } from "react";
import { Div } from "@outerlabs/canvas-reconciler";
import { SyntheticEvent } from "@outerlabs/canvas-reconciler/types";
import {
  ConferenceRooms,
  NavTabs,
  PortfolioBlockInstance,
  SelectedMeetingRoom,
  XForm,
} from "lib/types";
import {
  getBoundingBox,
  getMxMy,
  isInsideRegion,
  toRegion,
  toRotation,
} from "lib/isp-canvas/utils";
import { polygonContains } from "lib/isp-canvas/geometry";
import { RendererMode } from "lib/containers";
import { mat4 } from "gl-matrix";

interface Props {
  active: boolean;
  selectRegion(idx: number[] | undefined): void;
  selectRooms(rooms: SelectedMeetingRoom[] | undefined): void;
  selectedRooms?: SelectedMeetingRoom[];
  xform: XForm;
  instances: PortfolioBlockInstance[][];
  updateNavBarState: (selected: NavTabs) => void;
  conferenceRooms?: ConferenceRooms | undefined;
  selectedRegion?: number[];
  rendererMode: RendererMode;
  setTransformBlockInstance(
    instance: PortfolioBlockInstance[][] | undefined
  ): void;
  blockInstances?: PortfolioBlockInstance[][];
}

export const Select: React.FC<Props> = ({
  selectRegion,
  selectRooms,
  selectedRooms,
  xform,
  active,
  instances,
  updateNavBarState,
  conferenceRooms,
  selectedRegion,
  rendererMode,
  setTransformBlockInstance,
  blockInstances,
}) => {
  const activeCursor = active ? <cursor cursor="default" /> : null;
  const bounds =
    blockInstances && blockInstances.length > 0 && blockInstances[0].length > 0
      ? getBoundingBox(blockInstances.map((inst) => inst[0]))
      : // ? getBoundingBox(
        //   blockInstances.map((inst) => toRegion(inst[0])),
        //   [toRotation(blockInstances[0][0].matrix)]
        // )
        undefined;

  const resetSelect = useCallback(() => {
    // In meeting room metrics, clicking within a meeting room polygon is
    // reserved for selecting it and showing detailed information in the
    // metrics sidebar.
    if (rendererMode === RendererMode.MeetingRoomMetric) return;
    updateNavBarState(NavTabs.Performance);
    selectRegion(undefined);
    setTransformBlockInstance(undefined);
  }, [
    selectRegion,
    setTransformBlockInstance,
    updateNavBarState,
    rendererMode,
  ]);

  const onClick = useCallback(
    (e: SyntheticEvent) => {
      if (!active) return;
      const { offsetX, offsetY, shiftKey } = e;
      const [mx, my] = getMxMy(offsetX, offsetY, xform);
      const point = { x: mx, y: my };
      if (conferenceRooms && rendererMode !== RendererMode.Transforming) {
        if (e.shiftKey && rendererMode !== RendererMode.MeetingRoomMetric) {
          Object.keys(conferenceRooms).forEach((conferenceRoomType) => {
            Object.keys(conferenceRooms[conferenceRoomType]).forEach(
              (conferenceRoomId) => {
                const meetingRoomRegionConverted = conferenceRooms[
                  conferenceRoomType
                ][conferenceRoomId].map((c) => [c.x, c.y]);
                const isInMeetingRoomRegion = polygonContains(
                  point,
                  meetingRoomRegionConverted
                );
                if (isInMeetingRoomRegion) {
                  if (selectedRooms !== undefined) {
                    if (
                      !selectedRooms.some(
                        (room) =>
                          room.conferenceRoomId === String(conferenceRoomId)
                      )
                    ) {
                      selectRooms([
                        ...selectedRooms,
                        { conferenceRoomId, conferenceRoomType },
                      ]);
                    }
                  } else {
                    selectRooms([{ conferenceRoomId, conferenceRoomType }]);
                  }
                }
              }
            );
          });
        } else {
          selectRooms([]);
          Object.keys(conferenceRooms).forEach((conferenceRoomType) => {
            Object.keys(conferenceRooms[conferenceRoomType]).forEach(
              (conferenceRoomId) => {
                const meetingRoomRegionConverted = conferenceRooms[
                  conferenceRoomType
                ][conferenceRoomId].map((c) => [c.x, c.y]);
                const isInMeetingRoomRegion = polygonContains(
                  point,
                  meetingRoomRegionConverted
                );
                if (isInMeetingRoomRegion) {
                  selectRooms([{ conferenceRoomId, conferenceRoomType }]);
                }
              }
            );
          });
        }
      }

      e.preventDefault();
      e.stopPropagation();

      const clickedRegions: number[] = instances.reduce(
        (acc: any, instance, i) => {
          if (!instance[0]) return acc;
          const { matrix } = instance[0];
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          mat4.invert(mat4.create(), matrix!);
          const isInRegion = isInsideRegion(
            { x: mx, y: my },
            toRegion(instance[0]),
            toRotation(matrix)
          );
          if (isInRegion) {
            acc.push(i);
          }

          return acc;
        },
        []
      );

      const top = clickedRegions.pop();

      if (rendererMode !== RendererMode.Select && top === undefined) {
        resetSelect();
      }
      if (top === undefined) return;
      if (!shiftKey && bounds && isInsideRegion({ x: mx, y: my }, bounds, 0)) {
        return;
      }
      if (shiftKey) {
        let newRegions: number[] = [];
        if (selectedRegion && selectedRegion.length > 0) {
          if (selectedRegion.includes(top))
            newRegions = selectedRegion.filter((r) => r !== top);
          else newRegions = [...selectedRegion, top];
        } else if (top) newRegions = [top];
        if (newRegions.length === 0) {
          resetSelect();
        } else {
          selectRegion(newRegions);
          setTransformBlockInstance(
            instances.filter((inst, i) => newRegions.includes(i))
          );
          updateNavBarState(NavTabs.Zone);
        }
      } else if (!shiftKey) {
        selectRegion([top]);
        updateNavBarState(NavTabs.Zone);
        setTransformBlockInstance([instances[top]]);
      }
    },
    [
      active,
      xform,
      conferenceRooms,
      rendererMode,
      instances,
      resetSelect,
      bounds,
      selectedRooms,
      selectRooms,
      selectedRegion,
      selectRegion,
      setTransformBlockInstance,
      updateNavBarState,
    ]
  );

  const eventHandler = <Div onClick={onClick} />;

  return (
    <>
      {activeCursor}
      {eventHandler}
    </>
  );
};

export default Select;
