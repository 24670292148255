import React, { useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { mdiLightbulbOnOutline } from "@mdi/js";
import Icon from "@mdi/react";
import Fab from "@material-ui/core/Fab";
import CheckIcon from "@material-ui/icons/Check";
import CreateIcon from "@material-ui/icons/Create";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import { ReactComponent as MatchMakerIcon } from "assets/icons/MatchMaker.svg";
import { ReactComponent as AutoMagicIcon } from "assets/icons/AutoMagic.svg";
import { Menu, MenuItem, ListItemIcon } from "@material-ui/core";
import {
  BlockType,
  RendererMode,
  useAutoMagicCtrl,
  useMatchMakerCtrl,
  useRendererCtrl,
} from "lib/containers";
import { Dialog } from "@outerlabs/ol-ui";

const useStyles = makeStyles(() => ({
  footer: {
    position: "absolute",
    right: 106,
    bottom: 36,
    zIndex: 10,
    paddingTop: 8,
  },
  fab: {
    fontSize: 16,
    fontWeight: 700,
    fontFamily: "GoogleSansBold",
    textTransform: "none",
    boxShadow: "0 2px 8px 2px rgba(0,0,0,0.1)",
  },
  fabSecondary: {
    boxShadow: "0 2px 8px 2px rgba(0,0,0,0.1)",
    fontSize: 16,
    fontWeight: 700,
    fontFamily: "GoogleSansBold",
    backgroundColor: "white",
    marginRight: 24,
    textTransform: "none",
  },
  menu: {
    // root: {
    boxShadow: "0 2px 8px 2px rgba(0,0,0,0.1)",
    // },
  },
  menuItem: {
    fontSize: 14,
    fontFamily: "GoogleSansRegular",
    height: 48,
  },
  itemIcon: {
    minWidth: 24,
    paddingRight: 12,
  },
  check: { marginRight: 10 },
}));

interface Props {
  disabled: boolean;
}

const FooterButtons: React.FC<Props> = ({ disabled }) => {
  const {
    id: projectID,
    buildingID,
    strategyID,
  } = useParams<{ [key: string]: string }>();
  const { rendererMode, setRendererMode, setBlockType } = useRendererCtrl();
  const { autoMagicBuilding } = useAutoMagicCtrl();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { setMatchMakerActive } = useMatchMakerCtrl();
  const footerRef = useRef(null);
  const menuOpen = Boolean(anchorEl);
  const [modalOpen, setModalOpen] = useState(false);
  const [autoMagicLoading, setAutoMagicLoading] = useState(false);

  const { id } = useParams<{ id: string }>();
  const classes = useStyles();

  const onDrawRegion = (typeSelection: BlockType) => {
    setRendererMode(RendererMode.Draw);
    setBlockType(typeSelection);
  };

  const handleMatchMaker = () => {
    setMatchMakerActive(true);
    setRendererMode(RendererMode.Select);
  };

  const handleDone = () => {
    setRendererMode(RendererMode.Select);
  };

  const handleMagicLayout = () => {
    setModalOpen(true);
  };

  const handleOpenMenu = () => {
    setAnchorEl(footerRef.current);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const closeModal = () => {
    if (!autoMagicLoading) {
      setModalOpen(false);
    }
  };

  const onAutoMagicProceed = async () => {
    setAutoMagicLoading(true);
    await autoMagicBuilding(projectID, buildingID, strategyID);
    setModalOpen(false);
    setAutoMagicLoading(false);
  };

  const isAnalyticMode = [
    RendererMode.BuzzMetric,
    RendererMode.DrawCirculation,
    RendererMode.DeleteCirculation,
    RendererMode.EditCirculation,
  ].includes(rendererMode);

  return (
    <>
      <div
        ref={footerRef}
        className={classes.footer}
        data-testid="footer-buttons"
      >
        {isAnalyticMode ? (
          <Fab
            color="primary"
            variant="extended"
            onClick={handleDone}
            className={classes.fab}
          >
            <Icon
              path={mdiLightbulbOnOutline}
              className="clay-portfolio::-MuiSvgIcon-root"
              style={{ marginRight: 10 }}
            />
            Done
          </Fab>
        ) : (
          <>
            <Link to={`/${id}/strategies`}>
              <Fab variant="extended" className={classes.fabSecondary}>
                <CheckIcon className={classes.check} />
                Save and Close
              </Fab>
            </Link>
            <Fab
              color="primary"
              variant="extended"
              onClick={handleOpenMenu}
              disabled={disabled}
              className={classes.fab}
            >
              <CreateIcon className={classes.check} />
              Begin Layout
            </Fab>
          </>
        )}

        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={menuOpen}
          onClose={handleCloseMenu}
          elevation={2}
          getContentAnchorEl={null}
          classes={{ paper: classes.menu }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <MenuItem
            onClick={() => {
              handleMatchMaker();
              handleCloseMenu();
            }}
            className={classes.menuItem}
          >
            <ListItemIcon className={classes.itemIcon}>
              <MatchMakerIcon />
            </ListItemIcon>
            MatchMaker Group
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleMagicLayout();
              handleCloseMenu();
            }}
            className={classes.menuItem}
          >
            <ListItemIcon className={classes.itemIcon}>
              <AutoMagicIcon />
            </ListItemIcon>
            AutoMagic Layout
          </MenuItem>
          <MenuItem
            onClick={() => {
              onDrawRegion(BlockType.Focus);
              handleCloseMenu();
            }}
            className={classes.menuItem}
          >
            <ListItemIcon className={classes.itemIcon}>
              <CreateOutlinedIcon />
            </ListItemIcon>
            Draw a Region
          </MenuItem>
        </Menu>
      </div>

      <Dialog.Sm
        open={modalOpen}
        title="AutoMagic"
        onSave={onAutoMagicProceed}
        onClose={closeModal}
        loading={autoMagicLoading}
        submitButtonText="Proceed"
      >
        This action will replan the entire building with regions of focus desks.
        You can make manual edits to the regions afterwards.
      </Dialog.Sm>
    </>
  );
};

export default React.memo(FooterButtons);
