import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { formatPercentage, round, formatPercentOutput } from "lib/number";
import { Colors } from "@outerlabs/ol-ui";

interface Props {
  baseline: number;
  value: number;
  unit?: "percent" | "value";
  type?: "integer" | null;
}

const useStyles = makeStyles({
  chip: {
    height: 24,
    width: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "27px",
    paddingRight: "6px",
    paddingLeft: "6px",
    color: Colors.Dark6,
    backgroundColor: Colors.Blue1,
  },
  leftBuffer: { marginLeft: 3 },
});

const AddIcon = () => {
  return (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 0H3V3H0V5H3V8H5V5H8V3H5V0Z"
        fill={Colors.Dark1}
      />
    </svg>
  );
};

const SubtractIcon = () => {
  return (
    <svg
      width="8"
      height="2"
      viewBox="0 0 8 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="2"
        width="2"
        height="8"
        transform="rotate(-90 0 2)"
        fill={Colors.Dark1}
      />
    </svg>
  );
};

export const FormattedDelta: React.FC<Props> = ({
  baseline,
  value,
  unit = "percent",
  type,
}) => {
  const classes = useStyles();
  const change = value - baseline;
  const delta = baseline !== 0 ? change / baseline : 0;
  const gtz = delta >= 0;
  const percentDelta = formatPercentage(Math.abs(delta), 2);
  const decimalDelta =
    type === "integer"
      ? round(Math.abs(baseline - value))
      : round(Math.abs(baseline - value), 2);

  return (
    <div
      className={classes.chip}
      style={{ width: unit === "percent" ? "56px" : "auto" }}
    >
      {gtz ? <AddIcon /> : <SubtractIcon />}
      <div className={classes.leftBuffer}>
        {unit === "percent"
          ? formatPercentOutput(decimalDelta, percentDelta)
          : decimalDelta}
      </div>
    </div>
  );
};

export default FormattedDelta;
