import { format } from "./utils";

export enum Unit {
  Unitless = 0,
  UnitInches = 1,
  UnitFeet = 2,
  UnitMiles = 3,
  UnitMillimeters = 4,
  UnitCentimeters = 5,
  UnitMeters = 6,
  UnitKilometers = 7,
  UnitMicroinches = 8,
  UnitMils = 9,
  UnitYards = 10,
  UnitAngstroms = 11,
  UnitNanometers = 12,
  UnitMicrons = 13,
  UnitDecimeters = 14,
  UnitDecameters = 15,
  UnitHectometers = 16,
  UnitGigameters = 17,
  UnitAstronomicalUnits = 18,
  UnitLightYears = 19,
  UnitParsecs = 20,
}

export type Header = { units: Unit };

export const convertHeader = ({ units }: Header): string => {
  // prettier-ignore
  return (
    format("0","SECTION") +
    format("2", "HEADER") +
    formatVariable("$INSUNITS", "70", units + "") +
    format("0","ENDSEC")
  );
};

const formatVariable = (variable: string, groupCode: string, value: string) => {
  return `9\n${variable}\n${groupCode}\n${value}\n`;
};
