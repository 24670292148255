/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from "react";
import { DisplayRendererMetric, Summary } from "lib/types";
import HeaderItem from "components/summary/header-item";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useProjectCtrl } from "lib/containers";
import { findBuildingMetrics } from "lib/metrics/renderer-metrics";
import { formatMetric } from "lib/number";
import {
  calculateBuildingDeskToDoor,
  calculateBuildingDeskToDoorWithNooks,
} from "lib/metrics/util";
interface Props {
  summary: Summary;
  isTableView?: boolean;
  isPercent?: boolean;
  selected?: string[];
  focusFloor?: string;
  floorMetrics?: DisplayRendererMetric;
  buildingMetrics?: DisplayRendererMetric;
}

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "flex-start",
  },
  subText: {
    color: "#919499",
    fontSize: "12px",
  },
});

const Header: React.FC<Props> = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const {
      summary,
      isTableView,
      isPercent,
      selected, // which header items to be displayed
      focusFloor, //  "building" or "floor"
      floorMetrics,
      buildingMetrics,
    } = props;
    const { project } = useProjectCtrl();
    const { buildingID, strategyID } = useParams<{
      [key: string]: string;
    }>();

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const buildingMetric = findBuildingMetrics(
      project!,
      buildingID,
      strategyID
    );

    const classes = useStyles();

    let buildingDeskToDoorWithNooks = NaN;
    let floorDeskToDoorWithNooks = NaN;
    let buildingDeskToDoorWithoutNooks = NaN;

    if (!isTableView) {
      buildingDeskToDoorWithNooks =
        calculateBuildingDeskToDoorWithNooks(buildingMetric);

      floorDeskToDoorWithNooks =
        floorMetrics!.assignableSeats /
        (floorMetrics!.conferenceRoomsCount + floorMetrics!.nooksCount);

      buildingDeskToDoorWithoutNooks =
        calculateBuildingDeskToDoor(buildingMetric);
    }

    const handleDensityBaseline = (
      activeMetric: string | undefined,
      buildingSummary: Summary,
      floorMetric: DisplayRendererMetric | undefined
    ) => {
      if (activeMetric === "building") {
        return (
          (buildingSummary.totalFloorArea * buildingSummary.efficiencyRatio) /
          buildingSummary.baselineSeatCount
        );
      } else if (activeMetric === "floor") {
        return floorMetric
          ? Math.round(
              (floorMetric.totalFloorArea * floorMetric.efficiencyRatio) /
                floorMetric.baselineSeatCount
            )
          : 0;
      }
    };

    return (
      <div {...props} ref={ref} className={classes.container}>
        {isTableView && (
          <>
            <HeaderItem
              total={summary.totalFloorArea}
              title="Total GSF"
              helpguideTooltip="GSF"
              isTableView={isTableView}
              isPercent={isPercent}
            />
            <HeaderItem
              total={summary.seatCount}
              baseline={summary.baselineSeatCount}
              title="Total Seats"
              helpguideTooltip="Total Seats"
              isTableView={isTableView}
              isPercent={isPercent}
            />
            <HeaderItem
              total={summary.density}
              baseline={summary.baselineDensity}
              title="Density"
              helpguideTooltip="GSF / Total Seats"
              isTableView={isTableView}
              isPercent={isPercent}
            />
            <HeaderItem
              total={formatMetric(summary.deskToDoor, 2)}
              baseline={summary.baselineDeskToDoor}
              title="Desk to Door"
              helpguideTooltip="Desk to Door"
              isTableView={isTableView}
              isPercent={isPercent}
            />
          </>
        )}
        {!isTableView && selected?.includes("totalSeats") && (
          <Box width={"30%"}>
            <HeaderItem
              total={
                focusFloor === "building"
                  ? summary.seatCount
                  : floorMetrics!.seatCount
              }
              baseline={
                focusFloor === "building"
                  ? summary.baselineSeatCount
                  : floorMetrics?.baselineSeatCount
              }
              title="Total Seats"
              helpguideTooltip="Total Seats"
              isTableView={isTableView}
              isPercent={isPercent}
            />
            <div className={classes.subText}>People</div>
          </Box>
        )}
        {/* density */}
        {!isTableView && selected?.includes("density") && (
          <Box width={"30%"}>
            <HeaderItem
              total={
                focusFloor === "building"
                  ? summary.density
                  : floorMetrics!.density
              }
              baseline={handleDensityBaseline(
                focusFloor,
                summary,
                floorMetrics
              )}
              title="Density"
              helpguideTooltip="GSF / Total Seats"
              isTableView={isTableView}
              isPercent={isPercent}
            />
            <div className={classes.subText}>NOA/Assign. Seats</div>
          </Box>
        )}
        {/* desk to door without nooks */}
        {!isTableView && selected?.includes("deskToDoorWithoutNooks") && (
          <Box width={"30%"}>
            <HeaderItem
              total={
                focusFloor === "building"
                  ? formatMetric(buildingDeskToDoorWithoutNooks, 2)
                  : floorMetrics
                  ? formatMetric(
                      floorMetrics.assignableSeats /
                        floorMetrics.conferenceRoomsCount,
                      2
                    )
                  : 0
              }
              baseline={
                focusFloor === "building"
                  ? buildingMetrics
                    ? buildingMetrics?.baselineSeatCount /
                      buildingMetrics?.baselineConferenceRoomsCount
                    : 0
                  : floorMetrics
                  ? floorMetrics.baselineSeatCount /
                    floorMetrics.baselineConferenceRoomsCount
                  : 0
              }
              title="Desk to Door"
              helpguideTooltip="Desk to Door"
              isTableView={isTableView}
              isPercent={isPercent}
            />
          </Box>
        )}
        {/* desk to door with nooks */}
        {!isTableView && selected?.includes("deskToDoorWithNooks") && (
          <Box width={"30%"}>
            <HeaderItem
              total={
                focusFloor === "building"
                  ? formatMetric(buildingDeskToDoorWithNooks, 2)
                  : formatMetric(floorDeskToDoorWithNooks, 2)
              }
              baseline={
                focusFloor === "building"
                  ? buildingMetrics
                    ? buildingMetrics?.baselineSeatCount /
                      buildingMetrics?.baselineConferenceRoomsCount
                    : 0
                  : floorMetrics
                  ? floorMetrics.baselineSeatCount /
                    floorMetrics.baselineConferenceRoomsCount
                  : 0
              }
              title="Desk to Door"
              helpguideTooltip="Desk to Door"
              isTableView={isTableView}
              isPercent={isPercent}
            />
            <div className={classes.subText}>Incl. Nooks</div>
          </Box>
        )}
        {/* will be total capacity */}
        {!isTableView && selected?.includes("capacity") && (
          <Box width={"30%"}>
            <HeaderItem
              total={
                focusFloor === "building"
                  ? summary.seatCount
                  : floorMetrics!.seatCount
              }
              baseline={
                focusFloor === "building"
                  ? summary.baselineSeatCount
                  : floorMetrics?.baselineSeatCount
              }
              title="Total Capacity"
              helpguideTooltip=""
              isTableView={isTableView}
              isPercent={isPercent}
            />
            <div className={classes.subText}>People</div>
          </Box>
        )}
        {!isTableView && selected?.includes("assignableSeats") && (
          <Box width={"30%"}>
            <HeaderItem
              total={
                focusFloor === "building"
                  ? summary.assignableSeats
                  : floorMetrics!.assignableSeats
              }
              baseline={
                focusFloor === "building"
                  ? buildingMetrics?.baselineSeatCount
                  : floorMetrics?.baselineSeatCount
              }
              title="Assign. Seats"
              helpguideTooltip=""
              isTableView={isTableView}
              isPercent={isPercent}
            />
          </Box>
        )}
      </div>
    );
  }
);

export default Header;
