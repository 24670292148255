import React, { useCallback, useEffect, useRef, useState } from "react";
import { Mutation, RendererSettings, XForm } from "lib/types";
import { getMxMy, snap, sortRegion } from "lib/isp-canvas/utils";
import { Canvas } from "@outerlabs/canvas-reconciler";
import { BlockType, RendererMode } from "lib/containers";
import { Block, BlockResult } from "../../blocks/lib/types";
import { renderBlockInstance } from "../../blocks/lib/canvas";
import { mat4, quat, vec3 } from "gl-matrix";
import { makeBlockInstance } from "../../blocks/lib/instance";
import { createHiDPICanvas } from "../../blocks/lib/util";
import { colors } from "../../lib/isp-canvas/constants";
import { getters } from "../../blocks/lib/constants";
import analytics from "../../lib/analytics";

interface Props {
  active: boolean;
  xform: XForm;
  handleAddRegion(region: number[][]): void;
  isValidDimension(w: number, h: number): boolean;
  isErasing: boolean;
  getBlocksBySizeAndType: (
    w: number,
    h: number,
    type: BlockType
  ) => BlockResult[];
  getBlockById: (id: string | undefined) => Block | undefined;
  setRendererMode(mode: RendererMode): void;
  width: number;
  height: number;
  filteredLibraries: string[];
  filterBlocksByLibrary(
    blockResults: BlockResult[],
    filteredLibraries: string[]
  ): BlockResult[];
  settings: RendererSettings;
  addInstance({
    buildingID,
    floorID,
    strategyID,
    blockType,
    region,
    blockID,
    instances,
    blockMutation,
  }: {
    buildingID: string;
    floorID: string;
    strategyID: string;
    blockType: BlockType;
    region?: number[][];
    blockID?: string;
    instances?: Block[];
    blockMutation?: Partial<Block>;
  }): Promise<RendererSettings | undefined>;
  saveFloor({
    buildingID,
    strategyID,
    floorID,
    projectID,
    mutation,
    settings,
    dontAddToHistoryStack,
    initializeHistory,
    dontUpdateContainerState,
    dontSaveProject,
  }: {
    buildingID: string;
    strategyID: string;
    floorID: string;
    projectID?: string;
    mutation?: Mutation;
    settings?: RendererSettings;
    dontAddToHistoryStack?: boolean;
    initializeHistory?: boolean;
    dontUpdateContainerState?: boolean;
    dontSaveProject?: boolean;
  }): Promise<void>;
  buildingID: string;
  strategyID: string;
  floorID: string;
}

export const DrawRegion: React.FC<Props> = ({
  setRendererMode,
  getBlocksBySizeAndType,
  getBlockById,
  xform,
  active,
  isValidDimension,
  isErasing,
  children,
  width,
  height,
  filteredLibraries,
  filterBlocksByLibrary,
  settings,
  addInstance,
  saveFloor,
  buildingID,
  strategyID,
  floorID,
}) => {
  const [start, setStart] = useState<number[] | undefined>();
  const [translation, setTranslation] = useState<number[]>([0, 0]);
  const [instance, setInstance] = useState<Block>();
  const canvas = useRef<HTMLCanvasElement>(null);

  const activeCursor = active ? <cursor cursor="crosshair" /> : null;
  useEffect(() => {
    const el = canvas.current;
    if (el) createHiDPICanvas(el, width, height);
  }, [width, height]);

  const onStart = useCallback(
    (e: any) => {
      const { offsetX, offsetY } = e;
      const [mx, my] = getMxMy(offsetX, offsetY, xform);

      setStart(snap([mx, my]));
    },
    [setStart, xform]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const placeBlock = async (block: Block, region: number[][]) => {
    if (!block) return;

    const b = await addInstance({
      blockType: BlockType.Focus,
      buildingID,
      floorID,
      strategyID,
      region,
      blockID: block.id,
    });
    analytics.blockAdded();
    await saveFloor({
      buildingID,
      strategyID,
      floorID,
      settings: {
        ...settings,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        blockChanges: b!.blockChanges,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        blocks: b!.blocks,
      },
    });
  };

  const onEnd = useCallback(
    async (e: any) => {
      if (!start) return;
      const { offsetX, offsetY } = e;
      const [mx, my] = snap(getMxMy(offsetX, offsetY, xform));
      const w = Math.abs(mx - start[0]);
      const h = Math.abs(my - start[1]);
      if (Math.abs(mx - start[0]) > 10 && Math.abs(my - start[1])) {
        if (isValidDimension(w, h) || isErasing) {
          const region = sortRegion([
            [start[0], start[1]],
            [start[0], my],
            [mx, my],
            [mx, start[1]],
          ]);
          if (instance) placeBlock(instance, region);
        }
      } else {
        setRendererMode(RendererMode.Select);
      }
      setStart(undefined);
      setTranslation([0, 0]);
    },
    [
      start,
      xform,
      isValidDimension,
      isErasing,
      instance,
      placeBlock,
      setRendererMode,
    ]
  );

  const onMouseMove: any = useCallback(
    async (e: MouseEvent) => {
      const { offsetX, offsetY } = e;
      const [mx, my] = getMxMy(offsetX, offsetY, xform);

      setTranslation(snap([mx, my]));
      e.preventDefault();
    },
    [xform]
  );

  // const props: any = { type: "DrawInteraction" };
  const eventHandler = active ? (
    <div
      {...getters}
      onMouseMove={onMouseMove}
      onMouseDown={onStart}
      onMouseUp={onEnd}
    />
  ) : null;

  const c = active ? (
    <Canvas
      render={(ctx: CanvasRenderingContext2D) => {
        if (!start) return;
        const [mx, my] = translation;
        const w = Math.abs(mx - start[0]);
        const h = Math.abs(my - start[1]);
        const s = [Math.max(mx, start[0]), Math.min(my, start[1])];
        const blockResults = getBlocksBySizeAndType(w, h, BlockType.Focus);
        let filteredResults: BlockResult[];
        if (filteredLibraries.length !== 0) {
          filteredResults = filterBlocksByLibrary(
            blockResults,
            filteredLibraries
          );
        } else filteredResults = blockResults;
        const size: [number, number] = [w, h];
        if (filteredResults.length > 0) {
          const b = getBlockById(filteredResults[0].id);
          if (!b) return;
          const metrics = getters.getMetrics(b);
          const { flexibility } = getters.getLayout(b);
          const flex = flexibility || [0, 0];
          const capped = [
            Math.min(size[0], metrics.sizeRange[0][1] + flex[0]),
            Math.min(size[1], metrics.sizeRange[1][1] + flex[1]),
          ];
          let xform2 = mat4.fromRotationTranslationScale(
            mat4.create(),
            quat.fromValues(0, 0, 0, 1),
            vec3.fromValues(s[0] - w, s[1] + (h - capped[1]), 0),
            vec3.fromValues(1, 1, 1)
          );
          if (filteredResults[0].rotated) {
            size[1] = Math.min(w, metrics.sizeRange[1][1] + flex[1]);
            size[0] = Math.min(h, metrics.sizeRange[0][1] + flex[0]);
            xform2 = mat4.mul(
              mat4.create(),
              mat4.fromRotationTranslationScale(
                mat4.create(),
                quat.fromEuler(quat.create(), 0, 0, 90),
                vec3.fromValues(s[0] - (w - size[1]), s[1] + (h - size[0]), 0),
                vec3.fromValues(1, 1, 1)
              ),
              mat4.create()
            );
          } else {
            size[0] = Math.min(size[0], metrics.sizeRange[0][1] + flex[0]);
            size[1] = Math.min(size[1], metrics.sizeRange[1][1] + flex[1]);
          }
          if (b) {
            const bi = makeBlockInstance(b, mat4.create(), size, getBlockById);
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            bi.props.layout!.mirrorY = true;
            setInstance(bi);
            renderBlockInstance(
              ctx,
              bi,
              xform2,
              getBlockById,
              false,
              false,
              undefined,
              true
            );
          }
        }

        ctx.save();
        ctx.beginPath();
        ctx.lineWidth = 4;
        ctx.strokeStyle = colors.selectedRegionColor;
        ctx.moveTo(start[0], start[1]);
        ctx.lineTo(start[0], my);
        ctx.lineTo(mx, my);
        ctx.lineTo(mx, start[1]);
        ctx.lineTo(start[0], start[1]);
        ctx.stroke();
        if (!isValidDimension(w, h)) {
          ctx.fillStyle = colors.selectedRegionColorWithOpacity;
          ctx.fill();
        }
        ctx.restore();
      }}
    />
  ) : null;

  return (
    <>
      {activeCursor}
      {eventHandler}
      {c}
      {children}
    </>
  );
};

export default React.memo(DrawRegion);
