// Colors
const meetingColor = "#7087BB";
const teamColor = "#d9f2fc";
const focusColor = "#2F73FF";
const teamBorderColor = "rgba(23,57,112,1)";
const deletedSeatsBorderColor = "rgba(255,255,255,1)";
const teamBorderHighlightColor = "#2EF9FF";
const teamBorderActiveColor = "#ff0000";
const selectedRegionColor = "#FF35EB";
const selectedRegionColorWithOpacity = "#FF35EB1A";
const disableColor = "#CCCCCC";
const collabColor = "#FFAF38";
const mixedUseColor = "#D3B1FC";
export const colors = {
  teamBorderHighlightColor,
  teamBorderActiveColor,
  meetingColor,
  teamColor,
  disableColor,
  collabColor,
  focusColor,
  teamBorderColor,
  deletedSeatsBorderColor,
  mixedUseColor,
  selectedRegionColor,
  selectedRegionColorWithOpacity,
};
