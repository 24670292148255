import { format } from "./utils";
import { shape, Shape } from "./shape";

export type Layer = {
  locked?: boolean;
  type: "layer";
  name: string;
  color: number;
  lineTypeName: string;
  lineWeight: number;
  shapes: Shape[];
};

export const convertLayer = ({
  name,
  color,
  lineTypeName,
  locked,
}: Layer): string => {
  // prettier-ignore
  return (
    format("0", "LAYER") +
    format("2", name) +
    format("70", locked ? 4 : 0) +
    format("62", color) +
    format("6", lineTypeName)
  );
};

export const convertLayerShapes = ({ name, shapes }: Layer): string => {
  return shapes
    .map((s) => {
      const out = shape(s, name);
      if (out === "") return "";
      return out + format("8", name);
    })
    .join("");
};
