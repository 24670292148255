import axios from "axios";
import config from "config";
import { ComponentType, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ContainerProviderProps, createContainer } from "unstated-next";
import { getAuthHeader } from "../auth";
import { Building, Metrics, Project } from "../types";
import analytics from "../analytics";
import { v4 as uuidv4 } from "uuid";
import fetchNewFloorMetrics from "../metrics/fetch-new-floor-metrics";
export interface UseProjectType {
  project?: Project;
  saveProject(p: Project): void;
  addBuildings(buildings: Building[]): void;
}

// NOTE: Project state is handled in history (undo/redo), but WILL NOT BE
// if you use saveProject from this file.
// All history management stuff is in containers/settings.ts.
// At the time, the only places a user saved the project were either:
//
// A. Related to strategies / accessible in the strategies table, or
// B. Within `saveFloor` (the catchall save/persist/remember in history function
//    from containers/settings.ts)
// ... So, remembering project state in history is handled in
// containers/settings.ts ONLY for now.
export const useProjectContainer = (
  initialState: string | undefined
): UseProjectType => {
  const [project, setProject] = useState<Project | undefined>(undefined);
  const history = useHistory();

  useEffect(() => {
    const fetchProject = async (): Promise<void> => {
      try {
        const headers = await getAuthHeader();
        const { data } = await axios.get(
          config.baseUrl + `/api/planning/projects/${initialState}`,
          {
            headers,
          }
        );

        // removes need to type data as Project in every appearance
        const projectData = data as Project;
        // sets last opened date
        setProject(() => projectData);
        Object.values(projectData.metrics).forEach(async (metric) => {
          // handles dxf import bug where phonebooths are placed in the wrong
          // metric location

          // TODO_LM fix dxf import script to assign phones to correct
          // location
          if (metric.phoneBooths && metric.phoneBooths !== 0) {
            metric.phone.capacity += metric.phoneBooths;
            metric.phone.count += metric.phoneBooths;
            metric.baselineConferenceRoomsCount += metric.phoneBooths;
            metric.phoneBooths = 0;
          }
          // handles bug where buildingID is not saved
          if (metric.buildingID === "") {
            // all metrics are saved with a strategyID
            // use that to locate the associated building from projectData
            const metricStrat = metric.strategyID;
            const allStrategies = projectData.primaryStrategies;
            for (const key in allStrategies) {
              if (allStrategies[key] === metricStrat) {
                metric.buildingID = key;
                metric.displayName = key.concat(metric.displayName);
                break;
              }
            }
          }
        });
        saveProject(projectData);
      } catch (error: any) {
        if (error && error.response && error.response.status === 401) {
          history.replace("/lnogin");
        }
        console.error("Error: ", error);
      }
    };

    if (initialState && !project) fetchProject();
  }, [history, initialState, project, setProject]);

  const saveProject = async (p: Project) => {
    try {
      const headers = await getAuthHeader();
      setProject(() => p);
      axios
        .put(config.baseUrl + `/api/planning/projects/${p.id}`, p, {
          headers,
        })
        .catch(() => {
          console.error("Failed to save project");
        });
    } catch (error: any) {
      console.error("Error: ", error);
    }
  };

  const addBuildings = async (buildings: Building[]) => {
    if (!project) return;
    const buildingIds = buildings.map((b) => b.BuildingId);
    const newIds = buildingIds.filter(
      (id) => project && !project.buildingIds.includes(id)
    );
    if (newIds.length !== 0) analytics.buildingsAdded(newIds.length);

    const newBuildings = buildings.filter(
      (b) => newIds.indexOf(b.BuildingId) >= 0
    );
    let floorMetrics: Metrics = {};
    const primaries: { [key: string]: string } = {};

    for (const b of newBuildings) {
      const newId = uuidv4();
      //TODO_LM: Metrics aren't pulling from new buildings correctly
      const newMetrics = await fetchNewFloorMetrics(
        b.AvailableFloors,
        b.BuildingId,
        newId
      );

      floorMetrics = { ...floorMetrics, ...newMetrics };
      primaries[b.BuildingId] = newId;
    }
    const metrics = { ...project.metrics, ...floorMetrics };
    const reducedNewBuildings = newBuildings.reduce<{
      [key: string]: Building;
    }>((accum, curr) => {
      accum[curr.BuildingId] = curr;
      return accum;
    }, {});
    const allBuildings = { ...project.buildings, ...reducedNewBuildings };
    const buildingIDs = [...project.buildingIds, ...newIds];

    return saveProject({
      ...project,
      metrics,
      buildings: allBuildings,
      buildingIds: buildingIDs,
      primaryStrategies: { ...project.primaryStrategies, ...primaries },
    });
  };
  return {
    project,
    saveProject,
    addBuildings,
  };
};

export const ProjectController = createContainer(useProjectContainer);
export const ProjectProvider: ComponentType<ContainerProviderProps<string>> =
  ProjectController.Provider;
export const useProjectCtrl = () => ProjectController.useContainer();
