import React from "react";
import { Canvas } from "@outerlabs/canvas-reconciler";
import { Coordinate } from "lib/types";
import {
  renderOffsetFeatureCirculation,
  renderFeatureCirculation,
} from "lib/isp-canvas";

interface Props {
  circulation?: Coordinate[][];
  activeControl: string;
  circulationBuffer: number;
}

export const Circulation: React.FC<Props> = ({
  circulation,
  activeControl,
  circulationBuffer,
}) => {
  const canvas = (
    <Canvas
      render={(ctx: CanvasRenderingContext2D) => {
        if (circulation) {
          if (activeControl === "circulationBuffer") {
            renderOffsetFeatureCirculation(ctx, circulation, circulationBuffer);
            renderFeatureCirculation(ctx, circulation);
          }
        }
      }}
    />
  );

  return <>{canvas}</>;
};

export default React.memo(Circulation);
