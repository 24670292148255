import React, { ComponentType, FC } from "react";
import { BlockImagesProvider } from "../containers";

function withBlockImages<Props>(Component: ComponentType<Props>): FC<Props> {
  return (props) => (
    <BlockImagesProvider>
      <Component {...props} />
    </BlockImagesProvider>
  );
}

export default withBlockImages;
