import React, { useCallback } from "react";
import { Div } from "@outerlabs/canvas-reconciler";
import { SyntheticEvent } from "@outerlabs/canvas-reconciler/types";
import { PortfolioBlockInstance, RendererSettings, XForm } from "lib/types";
import {
  getMxMy,
  isInsideRegion,
  toRegion,
  toRotation,
} from "lib/isp-canvas/utils";

interface Props {
  active: boolean;
  xform: XForm;
  instances: PortfolioBlockInstance[][];
  onDelete(idx: number[]): void;
  settings: RendererSettings;
}

export const Delete: React.FC<Props> = ({
  xform,
  active,
  onDelete,
  instances,
  settings,
}) => {
  const activeCursor = active ? <cursor cursor="default" /> : null;

  const onClick = useCallback(
    (e: SyntheticEvent) => {
      const { offsetX, offsetY } = e;
      const [mx, my] = getMxMy(offsetX, offsetY, xform);

      instances.forEach((instance) => {
        const point = { x: mx, y: my };
        const isInRegion = isInsideRegion(
          point,
          toRegion(instance[0]),
          toRotation(instance[0].matrix)
        );
        if (isInRegion) {
          const blockInd = settings.blocks.indexOf(instance);
          onDelete([blockInd]);
        }
      });

      e.preventDefault();
      e.stopPropagation();
    },
    [xform, instances, settings.blocks, onDelete]
  );

  const eventHandler = active ? <Div onClick={onClick} /> : null;

  return (
    <>
      {activeCursor}
      {eventHandler}
    </>
  );
};

export default React.memo(Delete);
