import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Colors } from "@outerlabs/ol-ui";
import FormattedDelta from "components/formatted-delta";

interface Props {
  type: string;
  value: number;
  typicalValue: number;
  unit?: string;
}

const useStyle = makeStyles({
  type: {
    color: Colors.Text.Light,
    fontSize: 14,
  },
  value: {
    display: "flex",
    alignItems: "baseline",
  },
  label: {
    fontFamily: "GoogleSansMedium",
    fontSize: "24px",
    padding: "8px, 0",
  },
  unit: {
    fontSize: "16px",
    margin: 5,
  },
  gray: {
    // this is "textMedium" from Clay
    color: "rgba(0, 0, 0, 0.6)",
  },
  delta: {
    width: 50,
    fontSize: "14px",
    marginBottom: 5,
  },
  compText: {
    fontSize: "12px",
    // this is "textLight" from Clay
    color: "rgba(0, 0, 0, 0.38)",
  },
});

// BarChartTag: this is the tag which shows up alongside the stacked bar chart
// when the user is hovering over a portion of the bar chart.
// Provides additional information around that particular bar segment.
// This is NOT the value which is displayed within the bar segment itself.
const BarChartTagFromClay: React.FC<Props> = ({
  type,
  value,
  typicalValue,
  unit,
}) => {
  const classes = useStyle();
  const delta = value - typicalValue;

  let compText = "Over Typology";
  if (delta < -0.01) {
    compText = "Under Typology";
  } else if (Math.abs(delta) < 0.01) {
    compText = "~ Equal to Typology";
  }

  return (
    <div className={classes.gray}>
      <div className={classes.type}>{type}</div>
      <div className={classes.value}>
        <div className={classes.label}>{value}</div>
        <div className={classes.unit}>{unit}</div>
      </div>
      <div className={classes.delta}>
        {typicalValue !== 0 && Math.abs(delta) > 0.01 && (
          <FormattedDelta baseline={typicalValue} value={value} />
        )}
      </div>
      {typicalValue !== 0 && <div className={classes.compText}>{compText}</div>}
    </div>
  );
};

export default BarChartTagFromClay;
