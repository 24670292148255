import { makeStyles } from "@material-ui/core";
import { Colors, Font } from "@outerlabs/ol-ui";
import { halftoneColors } from "blocks/lib/constants";
import { Block } from "blocks/lib/types";
import {
  getAmenityDensities,
  getDensities,
  getProgramDisplayName,
  sumDensities,
} from "lib/metrics/wpi-metric-helpers";
import React, { useEffect, useState } from "react";
import { BarChart, Bar, XAxis, YAxis, LabelList } from "recharts";
import { legendColorCode } from "renderer/legend";
import AmenitySubBarChart from "./AmenitySubBarChart";
import AmenityTypicalSubBarChart from "./AmenityTypicalSubBarChart";
import BarChartTagFromClay from "./BarChartTag";
import { SvgDottedLine } from "./SvgDottedLine";

interface Props {
  blocks: Block[][] | undefined;
}

interface TotalValueProps {
  currentOption: number;
  typical: number;
  labels?: ChartLabels;
  asterisk?: string;
}

interface ChartLabels {
  left: string;
  right: string;
}

const colorTextMedium = "#5F6166";
const colorTextLight = "#919499";

const useStyles = makeStyles(() => ({
  // for labels below the two main program stack bars
  barLabelContainer: {
    display: "flex",
    justifyContent: "center",
    gap: 20,
    marginTop: -30,
  },
  // for left label group within `barLabelContainer,
  // label below this project's bar chart
  thisBrief: {
    width: 96,
    paddingLeft: 7,
    paddingRight: 7,
  },
  // for right label group within `barLabelContainer,
  // label below typical project's bar chart
  typology: {
    width: 96,
  },

  // numeric total, shown below the bar
  value: {
    textAlign: "center",
    color: Colors.Text.Light,
    ...Font.Medium,
  },

  // text label below each vertical bar, eg "Single Building Typology"
  label: {
    textAlign: "center",
    fontSize: "12px",
    color: Colors.Text.Light,
  },

  // asterick explanation text
  description: {
    marginTop: "40px",
    fontSize: 12,
    color: colorTextMedium,
  },

  // the two main vertical stack bars
  barChart: {
    "& tspan": {
      display: "none", // hides the dataKey labels
    },
    display: "flex",
    justifyContent: "center",
    gap: 30,
    width: 220,
  },
  barStyle: {
    // places main bars and amenity subbar charts in one row
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  // for each of the amenity sub stack bar charts
  subBar: {
    padding: 0,
    marginTop: 70,
    width: "auto",
  },
}));

// uses a white outline to fake in a gap between bars
// recharts currently does not support the use of classNames
const barStyle = {
  stroke: "#fff",
  strokeWidth: 2,
};

// labels beneath each stack bar chart
const TotalValuesFromClay: React.FC<TotalValueProps> = ({
  currentOption,
  typical,
  labels = {
    left: "Total Blocks this Level",
    right: "Single Building Typology",
  },
  asterisk,
}) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.barLabelContainer} style={{ ...Font.Regular }}>
        <div className={classes.thisBrief}>
          <div className={classes.value}>{currentOption}</div>
          <div className={classes.label}>{labels.left}</div>
        </div>
        <div className={classes.typology}>
          <div className={classes.value}>
            {typical}
            {asterisk ? "*" : ""}
          </div>
          <div className={classes.label}>{labels.right}</div>
        </div>
      </div>
      {asterisk && <div className={classes.description}>*{asterisk}</div>}
    </>
  );
};

// main component - - - - - - - - -
// - - - - - - - - - - - - - - - - -

const ProgramBarChart: React.FC<Props> = ({ blocks }) => {
  const classes = useStyles();

  const densities = getDensities(blocks);
  const amenityDensities = getAmenityDensities(blocks);

  // recharts requires its data to be passed in as an array
  const data: [{ [k: string]: string | number }] = [
    {
      name: "This Project",
      team: densities.team,
      meeting: densities.meeting,
      amenity: densities.amenity,
      businessSupport: densities.businessSupport,
      events: densities.events,
    },
  ];

  const dataTypical: [{ [k: string]: string | number }] = [
    {
      name: "Typical Project",
      team: 70,
      meeting: 29,
      amenity: 32,
      businessSupport: 2,
      events: 0,
      // circulation: 37,
      // core: 35,
    },
  ];

  const amenitiesData = [
    [
      {
        name: "Amenities, This Project",
        workplaceSupport: amenityDensities.workplaceSupport,
        food: amenityDensities.food,
        transportation: amenityDensities.transportation,
        healthAndPerformance: amenityDensities.health,
        extendedAmenities: amenityDensities.extendedAmenities,
        networkInfrastructure: 0,
      },
    ],
  ];

  const totalDensityTypical = sumDensities(dataTypical[0]);

  // creates and renders the labels for each bar
  const renderNonZero = (dataKey: string, total: number) => (props: any) => {
    const { x, y, width: barWidth, height: barHeight, value, id } = props;
    return value === 0 ? null : (
      <g style={{ pointerEvents: "none" }} id={id}>
        <text
          x={x + barWidth / 2 - 15}
          y={y + barHeight / 2}
          fill={data[0][dataKey] === 0 ? colorTextLight : "#000"}
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize={14}
          fontFamily={Font.Medium.fontFamily}
          fontWeight={Font.Medium.fontWeight}
          letterSpacing={1}
        >
          {value}
        </text>
        <text
          x={x + barWidth / 2 + 15}
          y={y + barHeight / 2}
          fill={data[0][dataKey] === 0 ? colorTextLight : "#000"}
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize={12}
          fontFamily={Font.Regular.fontFamily}
          fontWeight={Font.Regular.fontWeight}
        >
          {total && `${+((value * 100) / total).toFixed(1)}%`}
        </text>
      </g>
    );
  };

  const totalDensityProject = sumDensities(data[0]);
  const amenitiesValue = data[0].amenity;

  // SVG lines for the amenities sub-barchart
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

  // the line to the top of the amnenity subchart is dynamically drawn,
  // based on the values of the amenities
  const [lowerRight, setLowerRight] = useState(0);
  const [upperLeft, setUpperLeft] = useState(0);

  useEffect(() => {
    // we use a slight delay to allow for the bars to fully load on the page
    setTimeout(() => {
      try {
        // lowerRight is the point on the main chart
        const amenityBar = document.getElementById("amenitiesBar") as any;
        const height = amenityBar.attributes.height.value;
        setLowerRight(parseFloat(height));

        // upperRight is the point on the sub chart
        let height2 = 0;
        const subbars = document.querySelectorAll(
          "#amenity-subbar g.recharts-bar-rectangle path"
        );
        subbars.forEach((bar) => {
          const barHeightString = bar.attributes.getNamedItem("height")?.value;
          if (barHeightString) {
            const barHeightNum = parseFloat(barHeightString);
            height2 += barHeightNum;
          }
        });

        setUpperLeft(height2);
      } catch (e) {
        console.error(e);
      }
    }, 10);
  }, [amenitiesValue]);

  const lineLeftTop = SvgDottedLine(
    { x: 0, y: 263 - upperLeft + 4 },
    {
      x: 12,
      y: 263 - lowerRight + 4, // 3 for the stroke
    }
  );
  // the rest of the lines are static, as long as
  // the values for a typical project don't change, and
  // amenities is on the bottom of the stack bar char
  const lineLeftBottom = SvgDottedLine({ x: 0, y: 263 }, { x: 12, y: 263 });
  const lineRightTop = SvgDottedLine({ x: 93, y: 263 }, { x: 80, y: 263 });
  const lineRightBottom = SvgDottedLine({ x: 93, y: 110 }, { x: 80, y: 229 });

  const [hoverTagVisible, setHoverTagVisible] = useState(false);
  const [amenitySubBarsVisible, setAmenitySubBarsVisible] = useState(false);

  const [tagName, setTagName] = useState("");
  const [tagValue, setTagValue] = useState(0);
  const [tagTypicalValue, setTagTypicalValue] = useState(0);

  const handleMouseOverBars = (props: any) => {
    setHoverTagVisible(true);
    const category = props.tooltipPayload[0].name as string;
    setTagValue(data[0][category] as number);
    setTagTypicalValue(dataTypical[0][category] as number);
    setTagName(getProgramDisplayName(category));
  };

  const handleMouseLeftBars = () => {
    setHoverTagVisible(false);
  };

  const handleMouseOverAmenity = (props: any) => {
    handleMouseOverBars(props);
    setAmenitySubBarsVisible(true);
  };
  const handleMouseLeftAmenity = () => {
    handleMouseLeftBars();
    setAmenitySubBarsVisible(false);
  };

  const tag = (
    <BarChartTagFromClay
      type={tagName}
      value={tagValue}
      typicalValue={tagTypicalValue}
      unit={"GIA/person"}
    />
  );

  return (
    <div style={{ width: 400, overflowX: "hidden" }}>
      <div style={{ width: 200, height: 65 }}>{hoverTagVisible && tag}</div>
      <div className={classes.barStyle}>
        {/* amenities subbar chart (this project) */}
        <div
          className={classes.subBar}
          style={{ opacity: amenitySubBarsVisible ? 1 : 0 }}
        >
          <AmenitySubBarChart
            amenitiesData={amenitiesData[0]}
            totalDensity={totalDensityProject}
          />
        </div>
        {/* main bar chart with two vertical bars */}
        <div className={classes.barChart}>
          {/* bar chart for this project */}
          <BarChart
            width={93}
            height={300}
            data={data}
            barSize={80}
            margin={{
              top: 20,
              bottom: 5,
              left: 10,
              right: 0,
            }}
          >
            <YAxis type="number" domain={[0, 205 || 0]} hide />
            <XAxis axisLine={false} dataKey="name" tickLine={false} />
            <Bar
              key={"amenity"}
              dataKey={"amenity"}
              stackId="a"
              fill={legendColorCode.Amenity}
              isAnimationActive={false}
              style={barStyle}
              onMouseEnter={handleMouseOverAmenity}
              onMouseLeave={handleMouseLeftAmenity}
              id={"amenitiesBar"}
            >
              <LabelList
                dataKey={"amenity"}
                position="center"
                content={renderNonZero("amenity", totalDensityProject)}
                id={"amenityLabel"}
              />
            </Bar>
            <Bar
              key={"meeting"}
              dataKey={"meeting"}
              stackId="a"
              fill={legendColorCode.Meeting}
              isAnimationActive={false}
              style={barStyle}
              onMouseEnter={handleMouseOverBars}
              onMouseLeave={handleMouseLeftBars}
            >
              <LabelList
                dataKey={"meeting"}
                position="center"
                content={renderNonZero("meeting", totalDensityProject)}
                id={"meetingLabel"}
              />
            </Bar>
            <Bar
              key={"team"}
              dataKey={"team"}
              stackId="a"
              fill={legendColorCode["Team Space"]}
              isAnimationActive={false}
              style={barStyle}
              onMouseEnter={handleMouseOverBars}
              onMouseLeave={handleMouseLeftBars}
            >
              <LabelList
                dataKey={"team"}
                position="center"
                content={renderNonZero("team", totalDensityProject)}
                id={"teamLabel"}
              />
            </Bar>
            <Bar
              key={"businessSupport"}
              dataKey={"businessSupport"}
              stackId="a"
              fill={legendColorCode.BusinessSupport}
              isAnimationActive={false}
              style={barStyle}
              onMouseEnter={handleMouseOverBars}
              onMouseLeave={handleMouseLeftBars}
            >
              <LabelList
                dataKey={"businessSupport"}
                position="center"
                content={renderNonZero("businessSupport", totalDensityProject)}
                id={"cbsLabel"}
              />
            </Bar>
            <Bar
              key={"events"}
              dataKey={"events"}
              stackId="a"
              fill={legendColorCode.Events}
              isAnimationActive={false}
              style={barStyle}
              onMouseEnter={handleMouseOverBars}
              onMouseLeave={handleMouseLeftBars}
            >
              <LabelList
                dataKey={"events"}
                position="center"
                content={renderNonZero("events", totalDensityProject)}
                id={"eventsLabel"}
              />
            </Bar>
            <use href="#amenityLabel-0" />
            <use href="#meetingLabel-0" />
            <use href="#teamLabel-0" />
            <use href="#cbsLabel-0" />
            <use href="#eventsLabel-0" />
            {amenitySubBarsVisible && data[0].amenity !== 0 && lineLeftTop}
            {amenitySubBarsVisible && data[0].amenity !== 0 && lineLeftBottom}
          </BarChart>
          {/* typical bar chart */}
          <BarChart
            width={93}
            height={300}
            data={dataTypical}
            barSize={80}
            margin={{
              top: 20,
              bottom: 5,
              left: 0,
              right: 10,
            }}
          >
            <YAxis type="number" domain={[0, 205 || 0]} hide />
            <XAxis axisLine={false} dataKey="name" tickLine={false} />
            <Bar
              key={"amenity"}
              dataKey={"amenity"}
              stackId="a"
              fill={
                data[0].amenity === 0
                  ? halftoneColors.amenityHalftone
                  : legendColorCode.Amenity
              }
              isAnimationActive={false}
              style={barStyle}
              onMouseEnter={handleMouseOverAmenity}
              onMouseLeave={handleMouseLeftAmenity}
            >
              <LabelList
                dataKey={"amenity"}
                position="center"
                content={renderNonZero("amenity", totalDensityTypical)}
              />
            </Bar>
            <Bar
              key={"meeting"}
              dataKey={"meeting"}
              stackId="a"
              fill={
                data[0].meeting === 0
                  ? halftoneColors.meetingHalftone
                  : legendColorCode.Meeting
              }
              isAnimationActive={false}
              style={barStyle}
              onMouseEnter={handleMouseOverBars}
              onMouseLeave={handleMouseLeftBars}
            >
              <LabelList
                dataKey={"meeting"}
                position="center"
                content={renderNonZero("meeting", totalDensityTypical)}
              />
            </Bar>
            <Bar
              key={"team"}
              dataKey={"team"}
              stackId="a"
              fill={
                data[0].team === 0
                  ? halftoneColors.teamHalftone
                  : legendColorCode["Team Space"]
              }
              isAnimationActive={false}
              style={barStyle}
              onMouseEnter={handleMouseOverBars}
              onMouseLeave={handleMouseLeftBars}
            >
              <LabelList
                dataKey={"team"}
                position="center"
                content={renderNonZero("team", totalDensityTypical)}
              />
            </Bar>
            <Bar
              key={"businessSupport"}
              dataKey={"businessSupport"}
              stackId="a"
              fill={
                data[0].businessSupport === 0
                  ? halftoneColors.cbsHalftone
                  : legendColorCode.businessSupport
              }
              isAnimationActive={false}
              style={barStyle}
              onMouseEnter={handleMouseOverBars}
              onMouseLeave={handleMouseLeftBars}
            >
              <LabelList
                dataKey={"businessSupport"}
                position="center"
                content={renderNonZero("businessSupport", totalDensityTypical)}
              />
            </Bar>
            <Bar
              key={"events"}
              dataKey={"events"}
              stackId="a"
              fill={
                data[0].events === 0
                  ? halftoneColors.eventsHalftone
                  : legendColorCode.Events
              }
              isAnimationActive={false}
              style={barStyle}
              onMouseEnter={handleMouseOverBars}
              onMouseLeave={handleMouseLeftBars}
            >
              <LabelList
                dataKey={"events"}
                position="center"
                content={renderNonZero("events", totalDensityTypical)}
              />
            </Bar>
            {amenitySubBarsVisible && lineRightTop}
            {amenitySubBarsVisible && lineRightBottom}
          </BarChart>
        </div>
        <div
          className={classes.subBar}
          style={{ opacity: amenitySubBarsVisible ? 1 : 0 }}
        >
          <AmenityTypicalSubBarChart
            projectAmenityData={amenitiesData[0]}
            totalDensity={totalDensityTypical}
          />
        </div>
      </div>
      <TotalValuesFromClay
        currentOption={totalDensityProject}
        typical={totalDensityTypical}
      />
    </div>
  );
};

export default ProgramBarChart;
