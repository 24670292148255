import React from "react";
import { makeStyles } from "@material-ui/core/styles";

export interface Props {
  title: string;
  subtitle: string;
}

const useStyles = makeStyles(() => ({
  label: {
    padding: "12px 0 2px 0",
    color: "rgba(0,0,0,0.6)",
    fontSize: 14,
  },
  container1: {
    padding: "12px 0 4px 16px",
  },
  container2: {
    display: "flex",
    flexDirection: "row",
  },
  container3: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 12,
  },
  text: {},
  title: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "224px",
    color: "#4A4A4A",
    fontSize: "20px",
    letterSpacing: "-0.625px",
  },
  subtitle: {
    paddingBottom: 18,
  },
}));

const Sidebar: React.FC<Props> = ({ title, subtitle, children }) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.container1}>
        <div className={classes.container2}>
          <div className={classes.container3}>
            <div className={classes.title}>{title}</div>
            <div className={classes.subtitle}>{subtitle}</div>
          </div>
        </div>
      </div>
      {children}
    </React.Fragment>
  );
};

export default Sidebar;
