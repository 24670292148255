import React from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Project, Metric, RendererSettings } from "lib/types";
import { decodeMetricId } from "lib/metrics/id";
import Group from "components/toolbar/group";
import ToolbarNavButtons from "./nav-buttons";
import RegionButtons from "./region-buttons";
import FileButtons from "./file-buttons";
import { Divider } from "@material-ui/core";
import FloorSelector from "components/controls/floor-selector";

export interface Props {
  project: Project;
  floors: string[];
  settings: RendererSettings | undefined;
  onChangeFloors(value: string): void;
}

const useStyles = makeStyles({
  wrapper: {
    height: "100%",
    width: "70px",
    position: "fixed",
    right: 0,
    top: 72,
    backgroundColor: "white",
    borderLeft: "1px solid #E4E4E4",
  },
  container: {
    marginTop: "0px",
  },
  spacer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "100%",
  },
  pop: {
    padding: 6,
    border: "1px solid rgba(0,0,0,0.2)",
    backgroundColor: "white",
    marginTop: 6,
    display: "flex",
  },
});

const EditorToolbar: React.FC<Props> = ({
  project,
  onChangeFloors,
  floors,
  settings,
}) => {
  const classes = useStyles();
  const { buildingID, strategyID, floorID } =
    useParams<{ [key: string]: string }>();
  const floorMetrics: { [floorID: string]: Metric } = {};

  Object.keys(project.metrics).forEach((key) => {
    const {
      buildingID: bID,
      strategyID: sID,
      floorID: fID,
    } = decodeMetricId(key);
    if (buildingID === bID && sID === strategyID) {
      floorMetrics[fID] = project.metrics[key];
    }
  });

  return (
    <React.Fragment>
      <div className={classes.wrapper}>
        <div className={classes.container}>
          <Group>
            <RegionButtons project={project} />
          </Group>
          <Divider variant="middle" />
          <Group>
            <ToolbarNavButtons
              project={project}
              floors={floors}
              settings={settings}
              onChangeFloors={onChangeFloors}
            />
          </Group>
          <Group>
            <FileButtons
              buildingID={buildingID}
              floorID={floorID}
              projectID={project.id}
              strategyID={strategyID}
            />
          </Group>
          <FloorSelector
            project={project}
            onChange={onChangeFloors}
            buildingId={buildingID}
            floorID={floorID}
            floors={floors}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default React.memo(EditorToolbar);
