import { format } from "./utils";

export type LineType = {
  type: "lineType";
  name: string;
  description: string;
  elements: number[];
};

export const convertLineType = ({ name, description, elements }: LineType) => {
  return (
    format("0", "LTYPE") +
    format("70", 64) +
    format("72", 65) +
    format("2", name) +
    format("3", description) +
    format("73", elements.length) +
    format(
      "40",
      elements.reduce((a, b) => a + b, 0)
    ) +
    elements.map((e) => format("49", e)).join("")
  );
};
