import React from "react";

import { truncateHeaderNumber } from "lib/number";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import FormattedDelta from "components/formatted-delta";
import {
  HelpguideHandleSpan,
  HelpguideWrapperSpan,
} from "@outerlabs/helpguide";
interface ItemProps {
  total: number | string;
  baseline?: number;
  title: string;
  helpguideTooltip: string;
  isTableView?: boolean;
  isPercent?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    headerContainerLarge: {
      display: "flex",
      flexDirection: "column",
      width: "200px",
    },
    headerContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      width: "120px",
    },
    headerItemTitleLarge: {
      display: "flex",
      flexDirection: "row",
      color: "#919499",
      fontWeight: 100,
      width: "auto",
      height: "24px",
      fontSize: "16px",
    },
    headerItemTitle: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      color: "#919499",
      width: "auto",
      height: "36px",
      fontSize: "16px",
      textAlign: "left",
    },
    headerItemLarge: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      color: "#555555",
      fontSize: "32px",
    },
    headerItem: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      color: "#555555",
      fontSize: "32px",
    },
    percentDeltaLarge: {
      fontSize: "14px",
      marginLeft: "16px",
    },
    percentDelta: {
      fontSize: "14px",
    },
  })
);

const HeaderItem: React.FC<ItemProps> = ({
  total,
  baseline,
  title,
  helpguideTooltip,
  isTableView,
  isPercent,
}) => {
  const classes = useStyles();
  return (
    <div
      className={`${
        isTableView ? classes.headerContainerLarge : classes.headerContainer
      } `}
    >
      <HelpguideWrapperSpan>
        <span
          className={
            isTableView ? classes.headerItemTitleLarge : classes.headerItemTitle
          }
        >
          {helpguideTooltip ? (
            <HelpguideHandleSpan tooltipKey={helpguideTooltip}>
              {title}
            </HelpguideHandleSpan>
          ) : (
            title
          )}
        </span>
      </HelpguideWrapperSpan>
      <div
        className={isTableView ? classes.headerItemLarge : classes.headerItem}
      >
        {title === "Desk to Door"
          ? total
          : truncateHeaderNumber(total as number)}
        {baseline !== undefined && (
          <div
            className={
              isTableView ? classes.percentDeltaLarge : classes.percentDelta
            }
          >
            {!(isNaN(baseline) || baseline === Infinity) && (
              <FormattedDelta
                baseline={baseline}
                value={total as number}
                unit={isPercent ? "percent" : "value"}
                type={title === "GSF/Total Seats" ? "integer" : null}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default HeaderItem;
