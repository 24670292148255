import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles({
  circle: { position: "absolute", top: "50%", left: "50%", zIndex: 100 },
});

const Loading: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.circle}>
      <CircularProgress />
    </div>
  );
};

export default Loading;
