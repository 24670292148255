import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    backgroundColor: "white",
    fontSize: "16.57px",
    lineHeight: "28px",
    letterSpacing: "0.5px",
    color: "#00000070",
    fontFamily: "GoogleSansRegular",
  },
});

const SimpleSnackbar = ({ open = true }) => {
  const classes = useStyles();
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={open}
    >
      <SnackbarContent
        classes={{ root: classes.root }}
        message="DXF download is in progress"
      />
    </Snackbar>
  );
};

export default SimpleSnackbar;
