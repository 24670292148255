import { getAuthHeader } from "../auth";
import axios from "axios";
import config from "../../config";

export interface EmailVerificationResponse {
  invalidAddresses: string[];
  verified: boolean;
}

export const verifyEmailAddresses = async (
  emailAddresses: string[]
): Promise<EmailVerificationResponse> => {
  try {
    const headers = await getAuthHeader();
    const api = axios.create({
      headers,
    });

    const data = await api.post(`${config.baseUrl}/api/planning/users/verify`, {
      emailAddresses,
    });
    return data.data as EmailVerificationResponse;
  } catch (e) {
    console.error(e);
    return {
      invalidAddresses: emailAddresses,
      verified: false,
    };
  }
};
