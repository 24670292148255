import { Block } from "blocks/lib/types";
import { Metric } from "lib/types";

// gets counts of nooks, every nook is one nook, thats it
export const getNooks = (blocks: Block[][]) => {
  let nooks = 0;

  blocks.forEach((arr) =>
    arr.forEach((b) => {
      // if we want to do it by block instead of asset...
      const key = Object.keys(b.props.metrics?.names || {}).find((name) =>
        name.match(/nook/i)
      );
      if (key) {
        nooks += b.props?.metrics?.names?.[key] || 0;
      }
    })
  );
  return nooks;
};

// gets all assigned seats of nooks
export const getNookAssignedSeats = (blocks: Block[][]) => {
  let nooks = 0;

  blocks.forEach((arr) =>
    arr.forEach((b) => {
      // if we want to do it by block instead of asset...
      const key = Object.keys(b.props.metrics?.names || {}).find((name) =>
        name.match(/nook/i)
      );
      if (key) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const types = b!.props!.metrics!.types!;
        const nookIndex = Object.keys(types).indexOf("nook");
        nooks += Object.values(types)[nookIndex]?.headcount || 0;
      }
    })
  );
  return nooks;
};

export const getNookDeskToDoor = (metric: Metric) => {
  const nooks = metric.nooksCount;
  const seats = metric.assignableSeats;
  const phones = metric.phonesCount;
  const meetings =
    metric.baselineConferenceRoomsCount + metric.convertedMeetingRooms + phones;
  return lowerLevelGetNookDeskToDoor(nooks, seats, meetings);
};

export const lowerLevelGetNookDeskToDoor = (
  nooks: number,
  seats: number,
  meetings: number
) => {
  const deskToDoorWithNooks = seats / (nooks + meetings);
  return deskToDoorWithNooks;
};
