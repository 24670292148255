import React, { ChangeEvent, useEffect } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SettingsIcon from "@material-ui/icons/Settings";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useHistory } from "react-router-dom";
import { Block, Library, LibraryState } from "../lib/types";
import { getters } from "../lib/constants";
import { makeBlockThumbnails } from "../lib/util";
import { useBlockLibrariesCtrl, useBlocksCtrl } from "lib/containers";
import { formatBudget, formatToInt } from "../../lib/number";
import dayjs from "dayjs";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import TextField from "./textfield";
import { getProgramDisplayName } from "lib/metrics/wpi-metric-helpers";

interface Data {
  headcount?: string;
  activities?: string;
  id: string;
  role: string;
  name: string;
  cost: string;
  costGSF: string;
  focus: string;
  category: string;
  subcategory: string;
  type: string;
  subtype: string;
  collab: string;
  sqFeet: string;
  seats: string;
  workpoints: string;
  assignedSeats: string;
  doors: string;
  doorsRange: string;
  tags: string;
  author: string;
  lastUpdated: string;
  lastUpdatedDate: Date;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  let x = a[orderBy];
  let y = b[orderBy];

  // special case comparisons
  if (typeof x === "string" && typeof y == "string") {
    if (orderBy === "name") {
      (x as string) = x.toLowerCase();
      (y as string) = y.toLowerCase();
    } else if (orderBy === "sqFeet") {
      // remove commas for parseInt, ie "1,563 sq ft"
      const str1 = (x as string).replace(/,/g, "");
      const str2 = (y as string).replace(/,/g, "");
      (x as unknown as number) = parseInt(str1);
      (y as unknown as number) = parseInt(str2);
    } else if (orderBy === "cost") {
      x = a["costRangeLower" as keyof T];
      y = b["costRangeLower" as keyof T];
    } else if (orderBy === "costGSF") {
      (x as unknown as number) = parseInt((x as string).replace(/\$/, ""));
      (y as unknown as number) = parseInt((y as string).replace(/\$/, ""));
    } else if (orderBy === "lastUpdated") {
      // get lastUpdatedDate
      x = a["lastUpdatedDate" as keyof T];
      y = b["lastUpdatedDate" as keyof T];
      // format as dayjs objects
      const day1 = dayjs(x as unknown as string);
      const day2 = dayjs(y as unknown as string);

      if (day2.isAfter(day1)) {
        return -1;
      } else if (day2.isBefore(day1)) {
        return 1;
      } else {
        return 0;
      }
    }
    // mixed numbers and strings, such as when a quantity range is given
    // (eg comparing 2 against 1-5, such as in headcount, seats, or workpoints
  } else if (typeof x !== typeof y) {
    if (typeof x === "string") {
      (x as unknown as number) = parseInt(x as string);
    }
    if (typeof y === "string") {
      (y as unknown as number) = parseInt(y as string);
    }
  }

  // all other comparisons
  if (y < x) {
    return -1;
  } else if (y > x) {
    return 1;
  } else {
    return 0;
  }
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

// column headers for blocks table in blockmaker
const headCellsBlocks: HeadCell[] = [
  { id: "name", numeric: false, disablePadding: true, label: "NAME" },
  {
    id: "cost",
    numeric: true,
    disablePadding: false,
    label: "TOTAL BUDGET RANGE",
  },
  { id: "focus", numeric: true, disablePadding: false, label: "INDIVIDUAL" },
  { id: "collab", numeric: true, disablePadding: false, label: "COMMUNAL" },
  { id: "sqFeet", numeric: true, disablePadding: false, label: "SQ FEET" },
  { id: "seats", numeric: true, disablePadding: false, label: "SEATS" },
  {
    id: "workpoints",
    numeric: true,
    disablePadding: false,
    label: "WORKPOINTS",
  },
  {
    id: "headcount",
    numeric: true,
    disablePadding: false,
    label: "ASSIGNABLE SEATS",
  },
  {
    id: "doorsRange",
    numeric: true,
    disablePadding: false,
    label: "DOORS",
  },
  {
    id: "activities",
    numeric: true,
    disablePadding: false,
    label: "TAGS",
  },
  { id: "author", numeric: true, disablePadding: false, label: "AUTHOR" },
  {
    id: "lastUpdated",
    numeric: true,
    disablePadding: false,
    label: "LAST UPDATED",
  },
];

// column headers for assets table in blockmaker
const headCellsAssets: HeadCell[] = [
  { id: "name", numeric: false, disablePadding: true, label: "NAME" },
  {
    id: "subcategory",
    numeric: true,
    disablePadding: false,
    label: "PROGRAM CATEGORY",
  },
  { id: "type", numeric: true, disablePadding: false, label: "PROGRAM L1" },
  { id: "type", numeric: true, disablePadding: false, label: "PROGRAM L2" },
  { id: "sqFeet", numeric: true, disablePadding: false, label: "SQ FEET" },
  { id: "seats", numeric: true, disablePadding: false, label: "SEATS" },
  {
    id: "workpoints",
    numeric: true,
    disablePadding: false,
    label: "WORKPOINTS",
  },
  {
    id: "assignedSeats",
    numeric: true,
    disablePadding: false,
    label: "ASSIGNED SEATS",
  },
  {
    id: "doors",
    numeric: true,
    disablePadding: false,
    label: "DOORS",
  },
  {
    id: "tags",
    numeric: true,
    disablePadding: false,
    label: "TAGS",
  },
  { id: "author", numeric: true, disablePadding: false, label: "AUTHOR" },
  {
    id: "lastUpdated",
    numeric: true,
    disablePadding: false,
    label: "LAST UPDATED",
  },
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  order: Order;
  orderBy: string;
  cells: HeadCell[];
}

function EnhancedTableHead(_props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort } = _props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        <TableCell padding="checkbox"></TableCell>
        {_props.cells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell key={"blank"} align={"left"} />
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: "100%",
      marginBottom: "10px",
    },
    accordionSummaryRoot: {
      minHeight: 64,
    },
    accordionSummaryContent: {
      margin: "20px 0",
    },
    accordionSummary: {
      flexDirection: "row-reverse",
    },
    accordionTitle: { marginLeft: 8 },
    placeholder: {
      height: 40,
      width: 40,
    },
    paper: {
      width: "100%",
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    row: {
      marginBottom: 16,
    },
    radios: {},
    radio: {
      height: 60,
    },
    radioTitle: {
      fontSize: 14,
    },
    radioSubtitle: {
      fontSize: 12,
      color: "#949494",
    },
  })
);

interface BlockListProps {
  blocks: Block[];
  library?: Library;
  title?: string;
  libraryType?: string;
}

const formatSeats = (seatRange: number[]) => {
  if (!seatRange) return "";
  return seatRange[0] === seatRange[1] ? seatRange[0] : seatRange.join(" - ");
};

const formatDoorsRange = (doorsRange: number[]) => {
  if (!doorsRange) return "";
  return doorsRange[0] === doorsRange[1]
    ? doorsRange[0]
    : doorsRange.join(" - ");
};

const formatArea = (sizeRange: number[][]) => {
  const min = formatToInt((sizeRange[0][0] / 12) * (sizeRange[1][0] / 12));
  const max = formatToInt((sizeRange[0][1] / 12) * (sizeRange[1][1] / 12));
  return min === max ? `${min} sqft` : `${min} - ${max} sqft`;
};

const BlockTable = ({
  title,
  blocks,
  library,
  libraryType,
}: BlockListProps) => {
  const { saveLibrary } = useBlockLibrariesCtrl();
  const { copyBlock, deleteBlock, getBlockById } = useBlocksCtrl();
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("name");
  const [images, setImages] = React.useState<{ [k: string]: string }>({});
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [activeBlock, setActiveBlock] = React.useState<Block | null>(null);
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [newLibraryTitle, setNewLibraryTitle] = React.useState(library?.title);
  const [newLibraryDesription, setNewLibraryDescription] = React.useState(
    library?.description
  );
  const [newLibraryState, setNewLibraryState] = React.useState(library?.state);
  const history = useHistory();

  const handleMenu = (
    event: React.MouseEvent<HTMLTableHeaderCellElement>,
    id: string
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    const found = blocks.find((block) => block.id === id);
    if (found) setActiveBlock(found);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
    setActiveBlock(null);
  };

  const handleCopy = (event: React.MouseEvent<HTMLElement>, id: string) => {
    handleClose(event);
    copyBlock(id);
  };

  const handleDelete = (event: React.MouseEvent<HTMLElement>, id: string) => {
    handleClose(event);
    deleteBlock(id);
  };

  useEffect(() => {
    const renderBlocks = async () => {
      const _images = await makeBlockThumbnails(blocks, getBlockById);
      const imageMap: { [k: string]: string } = {};
      blocks.forEach((b, i) => (imageMap[b.id] = _images[i]));
      setImages(imageMap);
    };
    renderBlocks();
  }, [blocks, getBlockById]);

  const onChangeState = (e: ChangeEvent<HTMLInputElement>, v: string) => {
    if (library) {
      setNewLibraryState(v as LibraryState);
    }
  };
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const onDescriptionChange = (value: string) => {
    if (library) {
      setNewLibraryDescription(value);
    }
  };

  const onLibraryTitleChange = (value: string) => {
    if (library) {
      setNewLibraryTitle(value);
    }
  };

  // save changes and close dialog
  const handleLibraryEditModalClose = () => {
    if (library) {
      saveLibrary({
        ...library,
        title: newLibraryTitle as string,
        description: newLibraryDesription as string,
        state: newLibraryState as LibraryState,
      });
    }

    setModalOpen(false);
  };

  // persist accordian expansion state beyond page refresh
  /////////////////////////////////////////////////////////////////////////

  // get expansion state for library accordians
  // if there is a state in localStorage, use that
  // otherwise, if it's a named library, by default, it should be collapsed
  // if it's uncategorized, by default, it should be expanded

  const getIsExpanded = () => {
    const localVar =
      library !== undefined
        ? library.id
        : libraryType !== undefined
        ? libraryType
        : "";
    const localData = window.localStorage.getItem(localVar);

    if (localData !== null) {
      return JSON.parse(localData);
    } else {
      return title === "Uncategorized";
    }
  };

  const [expanded, setExpanded] = React.useState(getIsExpanded);

  // set expansion state for accordians upon page load/refresh
  useEffect(() => {
    setExpanded(getIsExpanded);
    // eslint-disable-next-line
  }, []);

  // save expansion to localStorage whenever it is changed
  useEffect(() => {
    if (library !== undefined) {
      window.localStorage.setItem(library.id, JSON.stringify(expanded));
    } else {
      if (libraryType !== undefined) {
        window.localStorage.setItem(libraryType, JSON.stringify(expanded));
      }
    }
    // eslint-disable-next-line
  }, [expanded]);

  const rows = blocks.map((block) => {
    const definition = getters.getDefinition(block);
    const metrics = getters.getMetrics(block);
    const metadata = getters.getMetadata(block);
    let focusArea = "0%";
    let collabArea = "0%";
    if (metrics.types && Object.keys(metrics.types).length > 0) {
      if (metrics.types.collaboration) {
        const collab = (metrics.types.collaboration.area / metrics.area) * 100;
        collabArea = formatToInt(collab) + "%";
        focusArea = formatToInt(100 - collab) + "%";
      } else {
        focusArea = "100%";
        collabArea = "0%";
      }
    } else if (definition.type) {
      if (definition.type === "collaboration") {
        collabArea = "100%";
      } else {
        focusArea = "100%";
      }
    }

    return {
      id: block.id,
      role: block.role || "",
      name: block.props.definition?.name || "",
      library: block.props.definition?.library || "",
      category: definition.category,
      subcategory: definition.subcategory,
      type: definition.type,
      cost: formatBudget(metrics.totalCostRange),
      costRangeLower: metrics.costGSF ? metrics.totalCostRange[0] : 0,
      costGSF: metrics.costGSF ? "$" + metrics.costGSF : "$" + 0,
      subtype: definition.subtype,
      focus: focusArea,
      collab: collabArea,
      sqFeet: formatArea(metrics.sizeRange),
      seats: formatSeats(metrics.seatsRange),
      workpoints: formatSeats(metrics.workpointsRange),
      assignedSeats: formatSeats(metrics.headcountRange),
      tags: definition.tags?.join(", "),
      author: metadata.authorName,
      lastUpdated: dayjs(Date.parse(metadata.updatedAt)).fromNow(),
      lastUpdatedDate: metadata.updatedAt,
      headcount: metrics.headcount,
      doors: metrics.doors || 0,
      doorsRange: formatDoorsRange(metrics.doorsRange),
      activities: definition.activity,
    };
  });
  const head =
    blocks.length > 0 && blocks[0].role === "asset"
      ? headCellsAssets.slice()
      : headCellsBlocks.slice();

  // generate block or asset details table in blockmaker
  return (
    <div className={classes.root}>
      {library && (
        <Dialog
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          maxWidth={"xs"}
          fullWidth={true}
        >
          <DialogTitle>Edit Library</DialogTitle>
          <DialogContent>
            <TextField
              label={"Name"}
              fullWidth={true}
              defaultValue={library.title}
              className={classes.row}
              onChange={(e) => onLibraryTitleChange(e.target.value)}
              value={newLibraryTitle}
            />
            <TextField
              className={classes.row}
              fullWidth={true}
              rows={2}
              multiline={true}
              label={"Description"}
              onChange={(e) => onDescriptionChange(e.target.value)}
              value={newLibraryDesription}
            />
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="status"
                name="status"
                defaultValue={library.state}
                value={newLibraryState}
                onChange={onChangeState}
                className={classes.radios}
              >
                <FormControlLabel
                  className={classes.radio}
                  value="unpublished"
                  control={<Radio color={"primary"} />}
                  label={
                    <>
                      <div className={classes.radioTitle}>Unpublished</div>
                      <div className={classes.radioSubtitle}>
                        Unpublished libraries are private.
                      </div>
                    </>
                  }
                />
                <FormControlLabel
                  className={classes.radio}
                  value="published"
                  control={<Radio color={"primary"} />}
                  label={
                    <>
                      <div className={classes.radioTitle}>Published (beta)</div>
                      <div className={classes.radioSubtitle}>
                        Published to Clay tools with a (beta) label.
                      </div>
                    </>
                  }
                />
                <FormControlLabel
                  className={classes.radio}
                  value="approved"
                  control={<Radio color={"primary"} />}
                  label={
                    <>
                      <div className={classes.radioTitle}>Published</div>
                      <div className={classes.radioSubtitle}>
                        Published to Clay tools.
                      </div>
                    </>
                  }
                />
              </RadioGroup>
            </FormControl>
          </DialogContent>
          <DialogActions>
            {/*<Button onClick={() => setModalOpen(false)}>Cancel</Button>*/}
            <Button color={"primary"} onClick={handleLibraryEditModalClose}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Accordion
        elevation={0}
        expanded={expanded}
        onClick={() => {
          setExpanded(!expanded);
        }}
      >
        <AccordionSummary
          className={classes.accordionSummary}
          classes={{
            content: classes.accordionSummaryContent,
            root: classes.accordionSummaryRoot,
          }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            {library ? (
              <div className={classes.accordionTitle}>{library.title}</div>
            ) : (
              <div className={classes.accordionTitle}>{title || ""}</div>
            )}
            {library && (
              <div style={{ marginLeft: "10px", color: "#80848E" }}>
                (
                {library.state === "approved"
                  ? "published"
                  : library.state === "published"
                  ? "beta"
                  : "unpublished"}
                )
              </div>
            )}
            {library && (
              <SettingsIcon
                style={{ marginLeft: "10px", width: "20px", color: "#80848E" }}
                onClick={(e) => {
                  e.stopPropagation();
                  setModalOpen(true);
                }}
              />
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Paper
            className={classes.paper}
            elevation={0}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={"medium"}
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  cells={head}
                />
                <TableBody>
                  {stableSort(rows, getComparator(order, orderBy)).map(
                    (row) => {
                      return (
                        <TableRow
                          hover
                          style={{ cursor: "pointer" }}
                          tabIndex={-1}
                          key={row.id}
                          onClick={() => {
                            history.push(`/blocks/${row.id}`);
                          }}
                        >
                          <TableCell></TableCell>
                          <TableCell>
                            {images[row.id] ? (
                              <img
                                src={images[row.id]}
                                alt={"block name"}
                                style={{
                                  width: "50px",
                                  maxHeight: "50px",
                                  objectFit: "contain",
                                  paddingRight: "10px",
                                }}
                              />
                            ) : (
                              <div className={classes.placeholder} />
                            )}
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            {row.name}
                          </TableCell>
                          {row.role === "block" && (
                            <TableCell align="left">{row.cost}</TableCell>
                          )}
                          {row.role === "asset" && (
                            <TableCell align="left">
                              {getProgramDisplayName(row.category as string) ||
                                " "}
                            </TableCell>
                          )}
                          {row.role === "asset" && (
                            <TableCell align="left">
                              {getProgramDisplayName(
                                row.subcategory as string
                              ) || " "}
                            </TableCell>
                          )}
                          {row.role === "asset" && (
                            <TableCell align="left">
                              {getProgramDisplayName(row.type as string) || " "}
                            </TableCell>
                          )}
                          {row.role === "block" && (
                            <TableCell align="left">{row.focus}</TableCell>
                          )}
                          {row.role === "block" && (
                            <TableCell align="left">{row.collab}</TableCell>
                          )}
                          <TableCell align="left">{row.sqFeet}</TableCell>
                          <TableCell align="left">{row.seats}</TableCell>
                          <TableCell align="left">{row.workpoints}</TableCell>
                          <TableCell align="left">
                            {row.assignedSeats}
                          </TableCell>
                          {row.role === "block" && (
                            <TableCell align="left">{row.doorsRange}</TableCell>
                          )}
                          {row.role === "asset" && (
                            <TableCell align="left">{row.doors}</TableCell>
                          )}
                          <TableCell align="left">{row.tags}</TableCell>
                          <TableCell align="left">{row.author}</TableCell>
                          <TableCell align="left">{row.lastUpdated}</TableCell>
                          <TableCell
                            align="left"
                            onClick={(e) => handleMenu(e, row.id as string)}
                          >
                            <MoreVertIcon />
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
              {activeBlock && (
                <Menu
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={(e) => handleCopy(e, activeBlock.id + "")}>
                    Make Copy
                  </MenuItem>
                  {
                    <MenuItem
                      onClick={(e) => handleDelete(e, activeBlock.id + "")}
                    >
                      Delete
                    </MenuItem>
                  }
                </Menu>
              )}
            </TableContainer>
          </Paper>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default BlockTable;
