import { Coordinate, Features, RoomBounds } from "../types";
import { findFeatureCentroid } from "../isp-canvas/utils";
import {
  distanceBetweenPoints,
  distanceToLine,
  distanceToLineWithIntersectionPoint,
  isPointOnLine,
} from "./remove-desks";
import { findSegmentIntersection } from "line-intersection";
import { v4 as uuid4 } from "uuid";
import {
  EdgeType,
  Graph as BuzzGraph,
  GraphEdge as BuzzEdge,
  GraphNode as BuzzNode,
  NodeType as BuzzNodeType,
  NavTrip as BuzzTrip,
  GraphType,
  Point3,
  solveBuzzGraph,
  GraphEdge,
} from "@outerlabs/buzzsolver";
import axios from "axios";
import config from "../../config";
import { getAuthHeader } from "../auth";
import { getBuzzSettings } from "../api/buzzSettings";
import { HOVER_TOLERANCE } from "../isp-canvas/circulation";

export interface Vec3 {
  x: number;
  y: number;
  z: number;
}

export enum NodeType {
  Intersection,
  Desk,
  LargeMeetingRoom,
  ExtraLarge,
  MediumMeetingRoom,
  SmallMeetingRoom,
  HuddleRoom,
  PhoneBooth,
  InterviewRoom,
  Restroom,
  Circulation,
  BikeParkingRack,
  BikeParkingRoom,
  BikeRepair,
  CreativeRoom,
  FitnessGym,
  FitnessOther,
  FitnessStudio,
  GameRoom,
  HealthExamRoom,
  HealthLab,
  HealthRehab,
  LaundryRoom,
  Makerspace,
  MassageRoom,
  MeditationRoom,
  MusicRoom,
  NapSpace,
  SalonAesthetic,
  Collaboration,
  CopyAndPrint,
  FocusRoom,
  Lobby,
  Lockers,
  MailArea,
  MonitorRoom,
  MothersRoom,
  PackageRoom,
  ParentsRoom,
  Reception,
  SecurityAndBadging,
  ShippingAndReceiving,
  Storage,
  Techstop,
  AudioVideoEquipmentLarge,
  AudioAndVideoEquipment,
  Auditorium,
  CoatCheck,
  ControlRoom,
  EditingSuite,
  EventProductionSpace,
  EventStudio,
  GreenRoom,
  PreFunction,
  TechTalk,
  TrainingRoom,
  BreakSpace,
  Hub,
  HydrationStation,
  MicroKitchen,
  Seating,
  NonEnclosedRoof,
  OutdoorSpace,
  Atrium,
  Elevator,
  Stairway,
  EquipmentStation,
  OfficeHuddleCombo,
  OfficeLarge,
  OfficeMedium,
  OfficeSmall,
}

export interface GraphNode {
  id: string; // TODO give existing ID
  position: Vec3;
  type: NodeType;
  connectsTo: string[];
}

export type Graph = { [key: string]: GraphNode };

// Adds features to the graph for metrics calculations
export const supplementGraph = (current: Graph, features: Features): Graph => {
  // i want to leave the current graph, nodes, and connections in place
  // just in case the caller want to use them in their original state
  // for something else
  const copy = JSON.parse(JSON.stringify(current)) as Graph;
  // meeting rooms
  addFeatureToGraph(
    copy,
    features.conferenceRooms.extraLarge,
    NodeType.ExtraLarge
  );
  addFeatureToGraph(
    copy,
    features.conferenceRooms.large,
    NodeType.LargeMeetingRoom
  );
  addFeatureToGraph(
    copy,
    features.conferenceRooms.medium,
    NodeType.MediumMeetingRoom
  );
  addFeatureToGraph(
    copy,
    features.conferenceRooms.small,
    NodeType.SmallMeetingRoom
  );
  addFeatureToGraph(copy, features.conferenceRooms.huddle, NodeType.HuddleRoom);
  addFeatureToGraph(
    copy,
    features.conferenceRooms.interview,
    NodeType.InterviewRoom
  );
  addFeatureToGraph(copy, features.conferenceRooms.phone, NodeType.PhoneBooth);

  // amenities
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.amenities.bikeParkingRack,
    NodeType.BikeParkingRack
  );
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.amenities.bikeParkingRoom,
    NodeType.BikeParkingRoom
  );
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.amenities.bikeRepair,
    NodeType.BikeRepair
  );
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.amenities.creativeRoom,
    NodeType.CreativeRoom
  );
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.amenities.fitnessGym,
    NodeType.FitnessGym
  );
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.amenities.fitnessOther,
    NodeType.FitnessOther
  );
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.amenities.fitnessStudio,
    NodeType.FitnessStudio
  );
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.amenities.gameRoom,
    NodeType.GameRoom
  );
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.amenities.healthExamRoom,
    NodeType.HealthExamRoom
  );
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.amenities.healthLab,
    NodeType.HealthLab
  );
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.amenities.healthRehab,
    NodeType.HealthRehab
  );
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.amenities.laundryRoom,
    NodeType.LaundryRoom
  );
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.amenities.makerspace,
    NodeType.Makerspace
  );
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.amenities.massageRoom,
    NodeType.MassageRoom
  );
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.amenities.meditationRoom,
    NodeType.MeditationRoom
  );
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.amenities.musicRoom,
    NodeType.MusicRoom
  );
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.amenities.napSpace,
    NodeType.NapSpace
  );
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.amenities.salonAesthetic,
    NodeType.SalonAesthetic
  );

  //buildingInfrastructure
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.buildingInfrastructure.restrooms,
    NodeType.Restroom
  );

  //businessSupport
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.businessSupport.collaboration,
    NodeType.Collaboration
  );
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.businessSupport.copyAndPrint,
    NodeType.CopyAndPrint
  );
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.businessSupport.focusRoom,
    NodeType.FocusRoom
  );
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.businessSupport.lobby,
    NodeType.Lobby
  );
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.businessSupport.lockers,
    NodeType.Lockers
  );
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.businessSupport.mailArea,
    NodeType.MailArea
  );
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.businessSupport.monitorRoom,
    NodeType.MonitorRoom
  );
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.businessSupport.mothersRoom,
    NodeType.MothersRoom
  );
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.businessSupport.packageRoom,
    NodeType.PackageRoom
  );
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.businessSupport.parentsRoom,
    NodeType.ParentsRoom
  );
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.businessSupport.reception,
    NodeType.Reception
  );
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.businessSupport.securityAndBadging,
    NodeType.SecurityAndBadging
  );
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.businessSupport.shippingAndReceiving,
    NodeType.ShippingAndReceiving
  );
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.businessSupport.storage,
    NodeType.Storage
  );
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.businessSupport.techstop,
    NodeType.Techstop
  );

  //events
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.events.audioVideoEquipmentLarge,
    NodeType.AudioVideoEquipmentLarge
  );
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.events.audioAndVideoEquipment,
    NodeType.AudioAndVideoEquipment
  );
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.events.auditorium,
    NodeType.Auditorium
  );
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.events.coatCheck,
    NodeType.CoatCheck
  );
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.events.controlRoom,
    NodeType.ControlRoom
  );
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.events.editingSuite,
    NodeType.EditingSuite
  );
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.events.eventProductionSpace,
    NodeType.EventProductionSpace
  );
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.events.eventStudio,
    NodeType.EventStudio
  );
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.events.greenRoom,
    NodeType.GreenRoom
  );
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.events.preFunction,
    NodeType.PreFunction
  );
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.events.techTalk,
    NodeType.TechTalk
  );
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.events.trainingRoom,
    NodeType.TrainingRoom
  );

  //food
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.food.breakSpace,
    NodeType.BreakSpace
  );
  addFeatureToGraph(copy, features.pointsOfInterest.food.hub, NodeType.Hub);
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.food.hydrationStation,
    NodeType.HydrationStation
  );
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.food.microKitchen,
    NodeType.MicroKitchen
  );
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.food.seating,
    NodeType.Seating
  );

  //nonMeasuredSpace
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.nonMeasuredSpace.nonEnclosedRoof,
    NodeType.NonEnclosedRoof
  );
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.nonMeasuredSpace.outdoorSpace,
    NodeType.OutdoorSpace
  );

  //verticalPenetration
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.verticalPenetration.atrium,
    NodeType.Atrium
  );
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.verticalPenetration.elevator,
    NodeType.Elevator
  );
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.verticalPenetration.stairway,
    NodeType.Stairway
  );

  //workspace
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.workspace.equipmentStation,
    NodeType.EquipmentStation
  );
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.workspace.officeHuddleCombo,
    NodeType.OfficeHuddleCombo
  );
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.workspace.officeLarge,
    NodeType.OfficeLarge
  );
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.workspace.officeMedium,
    NodeType.OfficeMedium
  );
  addFeatureToGraph(
    copy,
    features.pointsOfInterest.workspace.officeSmall,
    NodeType.OfficeSmall
  );

  features.desks.forEach((c, i) => {
    addBoundedCentroidToGraph(copy, `desk-${i}`, c, NodeType.Desk);
  });

  return copy;
};

// extends a single node to the nearest graph edge and returns the line that is intersected and the intersection point
export const extendNodeToLine = (
  graph: Graph,
  node: GraphNode
): { line: string[]; intersectionPoint: number[] } => {
  let closestDist = Infinity;
  const closestLine: string[] = ["", ""];
  const intersectionPoint: number[] = [0, 0];
  Object.values(graph).forEach((n) => {
    n.connectsTo.forEach((connectionID) => {
      const connectedNode = graph[connectionID];

      const { distance, intersection } = distanceToLineWithIntersectionPoint(
        [node.position.x, node.position.y],
        [
          [n.position.x, n.position.y],
          [connectedNode.position.x, connectedNode.position.y],
        ]
      );
      if (distance < closestDist) {
        closestLine[0] = n.id;
        closestLine[1] = connectedNode.id;
        intersectionPoint[0] = intersection[0];
        intersectionPoint[1] = intersection[1];
        closestDist = distance;
      }
    });
  });
  return { line: closestLine, intersectionPoint };
};

// updates the connectsTo array for two nodes on an edge when they are split by an intersecting node
export const filterConnections = (
  graph: Graph,
  nid1: string,
  nid2: string,
  intersectionId: string
): void => {
  const n1 = graph[nid1];
  n1.connectsTo = n1.connectsTo.filter((v) => v !== nid2);
  n1.connectsTo.push(intersectionId);

  const n2 = graph[nid2];
  n2.connectsTo = n2.connectsTo.filter((v) => v !== nid1);
  n2.connectsTo.push(intersectionId);
};

// checks if a given node is located on an edge of the graph and adds
export const intersectNodeWithLine = (graph: Graph, node: GraphNode): void => {
  Object.values(graph).forEach((n) => {
    n.connectsTo.forEach((connectionID) => {
      const connectedNode = graph[connectionID];
      if (
        isPointOnLine(
          [node.position.x, node.position.y],
          [
            [n.position.x, n.position.y],
            [connectedNode.position.x, connectedNode.position.y],
          ]
        )
      ) {
        breakLineAtNode(graph, [n.id, connectedNode.id], node.id);
      }
    });
  });
};

// Finds and returns the id of a node given a its position coordinate
export const findNodeByPoints = (
  graph: Graph,
  pts: Coordinate
): string | false => {
  const [node] = Object.values(graph).filter(
    (n) => n.position.x === pts.x && n.position.y === pts.y
  );
  if (!node) {
    return false;
  }
  return node.id;
};

// checks if a given line intersects with an edge of the graph, adds it and rebuilds the graph
export const intersectLines = (
  graph: Graph,
  nid1: string,
  nid2: string
): void => {
  const n1 = graph[nid1];
  const n2 = graph[nid2];
  Object.values(graph).forEach((n) => {
    n.connectsTo.forEach((connectionID) => {
      const connectedNode = graph[connectionID];
      const intersection = findSegmentIntersection([
        n1.position,
        n2.position,
        n.position,
        connectedNode.position,
      ]);
      if (intersection) {
        if (!findNodeByPoints(graph, intersection)) {
          const id = addNode(graph, intersection);
          breakLineAtNode(graph, [nid1, nid2], id);
          breakLineAtNode(graph, [n.id, connectedNode.id], id);
        }
      }
    });
  });
};

// adds a new intersection type node to the graph at a given  point
export const addNode = (graph: Graph, point: Coordinate): string => {
  const newId = uuid4();
  graph[newId] = {
    id: newId,
    position: {
      x: point.x,
      y: point.y,
      z: 0,
    },
    type: NodeType.Intersection,
    connectsTo: [],
  };
  return newId;
};

// adds a new node at a coordinate and updates the graph with an edge to a connecting node
export const addIntersectionAndNode = (
  graph: Graph,
  node: GraphNode,
  line: string[],
  intersectionPoint: number[]
): void => {
  const [nid1, nid2] = line;
  const newId = uuid4();
  const newIntersection: GraphNode = {
    id: newId,
    position: {
      x: intersectionPoint[0],
      y: intersectionPoint[1],
      z: 0,
    },
    type: NodeType.Intersection,
    connectsTo: [nid1, nid2],
  };

  const nodePoint = {
    id: node.id,
    position: {
      x: node.position.x,
      y: node.position.y,
      z: 0,
    },
    type: node.type,
    connectsTo: [newIntersection.id],
  };

  newIntersection.connectsTo.push(nodePoint.id);

  graph[newIntersection.id] = newIntersection;
  graph[nodePoint.id] = nodePoint;

  filterConnections(graph, nid1, nid2, newIntersection.id);
};

// Breaks a line at an node into two line segments
export const breakLineAtNode = (
  graph: Graph,
  line: string[],
  nodeId: string
): void => {
  const [nid1, nid2] = line;
  graph[nodeId].connectsTo.push(nid1);
  graph[nodeId].connectsTo.push(nid2);

  filterConnections(graph, nid1, nid2, nodeId);
};

// Adds the meeting rooms to the graph
const addFeatureToGraph = (
  graph: Graph,
  rooms: RoomBounds,
  roomType: NodeType
): void => {
  Object.entries(rooms).forEach((entry) => {
    const [id, coords] = entry;
    addBoundedCentroidToGraph(graph, id, coords, roomType);
  });
};

const addBoundedCentroidToGraph = (
  graph: Graph,
  id: string,
  coords: Coordinate[],
  roomType: NodeType
): void => {
  const center = findFeatureCentroid(coords);
  addPointToGraph(graph, id, center, roomType);
};

const addPointToGraph = (
  graph: Graph,
  id: string,
  point: Coordinate,
  roomType: NodeType
): void => {
  const node: GraphNode = {
    position: { x: point.x, y: point.y, z: 0 },
    id,
    type: roomType,
    connectsTo: [],
  };
  const { line, intersectionPoint } = extendNodeToLine(graph, node);
  if (line[0] !== "")
    addIntersectionAndNode(graph, node, line, intersectionPoint);
};

// adds a new line to the graph give and start and an end node
export const addLineToGraph = (
  graph: Graph,
  start: GraphNode,
  end: GraphNode
): void => {
  if (Object.values(graph).length === 0) {
    start.connectsTo = [end.id];
    end.connectsTo = [start.id];
    graph[start.id] = start;
    graph[end.id] = end;
    return;
  }

  const startClass = classifyNode(graph, start);
  const endClass = classifyNode(graph, end);

  const intersections: {
    position: Vec3;
    n1: GraphNode;
    n2: GraphNode;
  }[] = [];

  const visited: Set<string> = new Set<string>();
  Object.values(graph).forEach((currentNode) => {
    visited.add(currentNode.id);
    currentNode.connectsTo.forEach((connectionID) => {
      if (visited.has(connectionID)) return;

      const connection = graph[connectionID];
      const intersection = findSegmentIntersection([
        startClass.edgeIntersection,
        endClass.edgeIntersection,
        currentNode.position,
        connection.position,
      ]);
      if (intersection) {
        intersections.push({
          position: { ...intersection, z: 0 },
          n1: currentNode,
          n2: connection,
        });
      }
    });
  });

  intersections.sort((i1, i2) => {
    const i1Dist = distanceBetweenPoints(
      [start.position.x, start.position.y],
      [i1.position.x, i1.position.y]
    );
    const i2Dist = distanceBetweenPoints(
      [start.position.x, start.position.y],
      [i2.position.x, i2.position.y]
    );
    if (i1Dist < i2Dist) return -1;
    else if (i2Dist > i1Dist) return 1;

    return 0;
  });

  let lastNode: GraphNode = start;
  if (!startClass.isExistingNode && !startClass.isOnEdge) {
    // brand new node
    graph[start.id] = start;
  } else if (startClass.isOnEdge && !startClass.isExistingNode) {
    // node on edge
    const { n1, n2 } = startClass;
    if (!n1 || !n2) return;

    n1.connectsTo = n1.connectsTo.filter((id) => id !== n2.id);
    n2.connectsTo = n2.connectsTo.filter((id) => id !== n1.id);
    n1.connectsTo.push(start.id);
    n2.connectsTo.push(start.id);
    start.connectsTo.push(n1.id, n2.id);
    graph[start.id] = start;
  }

  intersections.forEach((i) => {
    if (
      distanceBetweenPoints(
        [start.position.x, start.position.y],
        [i.position.x, i.position.y]
      ) < HOVER_TOLERANCE
    ) {
      return;
    }
    if (
      distanceBetweenPoints(
        [end.position.x, end.position.y],
        [i.position.x, i.position.y]
      ) < HOVER_TOLERANCE
    ) {
      return;
    }

    const atIntersection = findNodeOnGraph(graph, {
      id: uuid4(),
      type: NodeType.Intersection,
      position: i.position,
      connectsTo: [],
    });

    const { n1, n2 } = i;
    if (!n1 || !n2) return;

    n1.connectsTo = n1.connectsTo.filter((id) => id !== n2.id);
    n2.connectsTo = n2.connectsTo.filter((id) => id !== n1.id);
    n1.connectsTo.push(atIntersection.id);
    n2.connectsTo.push(atIntersection.id);
    atIntersection.connectsTo.push(n1.id, n2.id, lastNode.id);
    lastNode.connectsTo.push(atIntersection.id);
    graph[atIntersection.id] = atIntersection;
    lastNode = atIntersection;
  });

  if (endClass.isExistingNode) {
    lastNode.connectsTo.push(end.id);
    end.connectsTo.push(lastNode.id);
  } else if (endClass.isOnEdge) {
    // node on edge
    const { n1, n2 } = endClass;
    if (!n1 || !n2) return;

    n1.connectsTo = n1.connectsTo.filter((id) => id !== n2.id);
    n2.connectsTo = n2.connectsTo.filter((id) => id !== n1.id);
    n1.connectsTo.push(end.id);
    n2.connectsTo.push(end.id);
    end.connectsTo.push(n1.id, n2.id, lastNode.id);
    lastNode.connectsTo.push(end.id);
    graph[end.id] = end;
  } else {
    end.connectsTo.push(lastNode.id);
    lastNode.connectsTo.push(end.id);
    graph[end.id] = end;
  }
};

interface NodeClassification {
  isExistingNode: boolean;
  isOnEdge: boolean;
  n1?: GraphNode;
  n2?: GraphNode;
  edgeIntersection?: Vec3;
}

const classifyNode = (graph: Graph, n: GraphNode): NodeClassification => {
  if (graph[n.id])
    return {
      isExistingNode: true,
      isOnEdge: false,
      edgeIntersection: n.position,
    };

  const result: NodeClassification = {
    isExistingNode: false,
    isOnEdge: false,
    edgeIntersection: n.position,
  };

  const visited: Set<string> = new Set<string>();
  Object.values(graph).forEach((node) => {
    visited.add(node.id);
    node.connectsTo.forEach((connectionID) => {
      if (visited.has(connectionID)) return;

      const connection = graph[connectionID];
      const { distance, intersection } = distanceToLineWithIntersectionPoint(
        [n.position.x, n.position.y],
        [
          [node.position.x, node.position.y],
          [connection.position.x, connection.position.y],
        ]
      );

      if (distance < HOVER_TOLERANCE) {
        result.isOnEdge = true;
        result.n1 = node;
        result.n2 = connection;
        result.edgeIntersection = {
          x: intersection[0],
          y: intersection[1],
          z: 0,
        };
      }
    });
  });

  return result;
};

const findNodeOnGraph = (graph: Graph, node: GraphNode): GraphNode => {
  if (graph[node.id]) return node;

  let closeNode: GraphNode = JSON.parse(JSON.stringify(node));
  let closestDist = Infinity;

  Object.values(graph).forEach((n) => {
    const dist = distanceBetweenPoints(
      [node.position.x, node.position.y],
      [n.position.x, n.position.y]
    );
    if (dist < closestDist) {
      closeNode = n;
      closestDist = dist;
    }
  });

  if (closestDist < HOVER_TOLERANCE) {
    return closeNode;
  }

  return node;
};

// Constructs lines for the renderer -- currently includes duplicate edges (n1 -> n2 && n2 -> n1)
export const constructLines = (graph: Graph): number[][][] => {
  const allLines: number[][][] = [];
  Object.values(graph).forEach((node) => {
    node.connectsTo.forEach((n) => {
      const end = graph[n].position;
      allLines.push([
        [node.position.x, node.position.y],
        [end.x, end.y],
      ]);
    });
  });
  return allLines;
};

// Removes an edge that connects two nodes from the graph
// Does not remove any intersecting nodes that may exist along that edge
export const removeLineFromGraph = (
  graph: Graph,
  start: GraphNode,
  end: GraphNode
): void => {
  graph[start.id].connectsTo = graph[start.id].connectsTo.filter(
    (id) => id !== end.id
  );
  graph[end.id].connectsTo = graph[end.id].connectsTo.filter(
    (id) => id !== start.id
  );

  if (graph[start.id].connectsTo.length === 0) delete graph[start.id];
  if (graph[end.id].connectsTo.length === 0) delete graph[end.id];
};

export interface EdgeValue {
  from: Vec3;
  to: Vec3;
  value: number;
}

export const calculateBuzzGraph = async (
  graph: Graph,
  features: Features
): Promise<EdgeValue[]> => {
  const supplementedGraph = supplementGraph(graph, features);
  const buzzGraph: BuzzGraph = makeBuzzGraph(supplementedGraph);
  const trips: BuzzTrip[] = await makeBuzzTrips();

  const solved = solveBuzzGraph(buzzGraph, [], trips);

  return solved.edges
    .filter((e) => lineIsOnOriginalGraph(graph, supplementedGraph, e))
    .map<EdgeValue>((e) => {
      const n0 = supplementedGraph[e.to[0]].position;
      const n1 = supplementedGraph[e.to[1]].position;

      return {
        from: n0,
        to: n1,
        value: e.props.buzz?.density || 0,
      };
    });
};

const lineIsOnOriginalGraph = (
  graph: Graph,
  supplementedGraph: Graph,
  e: GraphEdge
): boolean => {
  const p0 = supplementedGraph[e.to[0]].position;
  const p1 = supplementedGraph[e.to[1]].position;

  return pointLiesOnGraph(graph, p0) && pointLiesOnGraph(graph, p1);
};

const pointLiesOnGraph = (graph: Graph, p: Vec3): boolean => {
  const arrPoint = [p.x, p.y];
  const nodes = Object.values(graph);
  const visited: Set<string> = new Set<string>();
  for (let i = 0; i < nodes.length; i++) {
    const n = nodes[i];
    const nP = [n.position.x, n.position.y];
    visited.add(n.id);

    const didFind = n.connectsTo.reduce<boolean>((acc, curr) => {
      if (acc) return acc;
      if (visited.has(curr)) return acc;

      const n2 = graph[curr];
      const n2P = [n2.position.x, n2.position.y];

      return distanceToLine(arrPoint, [nP, n2P]) < 1;
    }, false);

    if (didFind) {
      return true;
    }
  }

  return false;
};

const makeBuzzGraph = (graph: Graph): BuzzGraph => {
  const buzzGraph: BuzzGraph = {
    id: "buzzGraph",
    nodes: [],
    edges: [],
    props: {
      type: GraphType.Nav,
    },
  };

  // make nodes
  Object.values(graph).forEach((n) => {
    const pos3: Point3 = [n.position.x, n.position.y, n.position.z];
    const buzzNode: BuzzNode = {
      id: n.id,
      props: {
        type: mapNodeTypeToBuzzNodeType(n.type),
        tags: [],
        buzz: {
          weight: 0,
        },
      },
      point3: pos3,
    };

    if (buzzNode.props.type === BuzzNodeType.Sink) {
      buzzNode.props.tags?.push(`buzz/${n.type}`);
    }

    buzzGraph.nodes.push(buzzNode);
  });

  const visitedNodes: { [key: string]: boolean } = {};

  Object.values(graph).forEach((n) => {
    visitedNodes[n.id] = true;

    n.connectsTo.forEach((nid) => {
      if (visitedNodes[nid]) return;

      const edge: BuzzEdge = {
        id: `${n.id}:${nid}`,
        props: {
          type: EdgeType.Pair,
        },
        to: [n.id, nid],
      };

      buzzGraph.edges.push(edge);
    });
  });

  return buzzGraph;
};

const mapNodeTypeToBuzzNodeType = (t: NodeType): BuzzNodeType => {
  if (t === NodeType.Intersection) return BuzzNodeType.Node;
  if (t === NodeType.Desk) return BuzzNodeType.Source;

  return BuzzNodeType.Sink;
};

const makeBuzzTrips = async (): Promise<BuzzTrip[]> => {
  const settings = await getBuzzSettings();
  if (!settings || !settings.trips.length) return [];

  return settings.trips.map<BuzzTrip>((t) => {
    return {
      tags: [`buzz/${t.type}`],
      weight: t.weight,
      range: [0, Infinity],
    };
  });
};

export const putBuzzSettings = async () => {
  const trips = [
    { type: NodeType.BikeParkingRoom, weight: 0.1 },
    { type: NodeType.CreativeRoom, weight: 0.1 },
    { type: NodeType.FitnessGym, weight: 0.2 },
    { type: NodeType.FitnessOther, weight: 0.2 },
    { type: NodeType.FitnessStudio, weight: 0.2 },
    { type: NodeType.GameRoom, weight: 0.4 },
    { type: NodeType.Makerspace, weight: 0.2 },
    { type: NodeType.MassageRoom, weight: 0.2 },
    { type: NodeType.MeditationRoom, weight: 0.5 },
    { type: NodeType.MusicRoom, weight: 0.2 },
    { type: NodeType.NapSpace, weight: 0.1 },
    { type: NodeType.SalonAesthetic, weight: 0.1 },
    { type: NodeType.Restroom, weight: 2 },
    { type: NodeType.Collaboration, weight: 1 },
    { type: NodeType.CopyAndPrint, weight: 1 },
    { type: NodeType.FocusRoom, weight: 0.5 },
    { type: NodeType.Lobby, weight: 5 },
    { type: NodeType.MothersRoom, weight: 0.2 },
    { type: NodeType.Techstop, weight: 0.5 },
    { type: NodeType.PreFunction, weight: 0.5 },
    { type: NodeType.TechTalk, weight: 0.5 },
    { type: NodeType.TrainingRoom, weight: 0.2 },
    { type: NodeType.TechTalk, weight: 0.5 },
    { type: NodeType.TrainingRoom, weight: 0.2 },
    { type: NodeType.BreakSpace, weight: 1 },
    { type: NodeType.Hub, weight: 1 },
    { type: NodeType.HydrationStation, weight: 3 },
    { type: NodeType.MicroKitchen, weight: 5 },
    { type: NodeType.Seating, weight: 1 },
    { type: NodeType.ExtraLarge, weight: 0.2 },
    { type: NodeType.LargeMeetingRoom, weight: 0.2 },
    { type: NodeType.MediumMeetingRoom, weight: 0.5 },
    { type: NodeType.SmallMeetingRoom, weight: 1 },
    { type: NodeType.HuddleRoom, weight: 0.5 },
    { type: NodeType.InterviewRoom, weight: 0.2 },
    { type: NodeType.PhoneBooth, weight: 2 },
    { type: NodeType.OutdoorSpace, weight: 1 },
    { type: NodeType.Elevator, weight: 5 },
    { type: NodeType.Stairway, weight: 5 },
    { type: NodeType.EquipmentStation, weight: 0.5 },
    { type: NodeType.OfficeHuddleCombo, weight: 0.2 },
    { type: NodeType.OfficeLarge, weight: 0.2 },
    { type: NodeType.OfficeMedium, weight: 0.2 },
    { type: NodeType.OfficeSmall, weight: 0.2 },
  ];

  const settings = {
    trips,
  };

  const headers = await getAuthHeader();

  axios
    .put(config.baseUrl + `/api/planning/settings/buzz`, settings, {
      headers,
    })
    .catch(() => {
      console.error("Failed to save project");
    });

  // NodeType.CleanroomLab	TRUE	0.1
  // NodeType.ElectronicsLabWet	TRUE	0.1
  // NodeType.EnvironmentalLab	TRUE	0.1
  // NodeType.GLSWetLab	TRUE	0.1
  // NodeType.HeavyBenchLab	TRUE	0.1
  // NodeType.MediaLab	TRUE	0.1
  // NodeType.ModelShop	TRUE	0.1
  // NodeType.NGPServerLab	TRUE	0.1
  // NodeType.OtherCustomizedBusinessSupport	TRUE	0.1          TODO: Am I Missing these?
  // NodeType.RFIAnechoicChamberLab	TRUE	0.1
  // NodeType.RackSystemsLab	TRUE	0.1
  // NodeType.StandardBenchLab	TRUE	0.1
  // NodeType.Studio	TRUE	0.1
  // NodeType.UXLabObservation	TRUE	0.1
  // NodeType.UXLabParticipant	TRUE	0.1
};
