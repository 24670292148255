import React from "react";
import { Canvas } from "@outerlabs/canvas-reconciler";
import { renderShaders } from "lib/isp-canvas";

interface Props {
  imageCache: string;
  sizes: any;
}

export const Shaders: React.FC<Props> = ({ imageCache, sizes }) => {
  const canvas = (
    <Canvas
      render={(ctx: CanvasRenderingContext2D) => {
        renderShaders(ctx, imageCache, sizes);
      }}
    />
  );

  return <>{canvas}</>;
};

export default React.memo(Shaders);
