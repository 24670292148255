import { findFeatureCentroid } from "lib/isp-canvas/utils";
import {
  Coordinate,
  Features,
  Metric,
  PortfolioBlockInstance,
} from "lib/types";
import { Point } from "@outerlabs/shapes-geometry";
import { vec3 } from "gl-matrix";
import { pointInPolygon } from "../../blocks/lib/util";
import { FeaturesExt } from "../api/getFeatures";

// Used as an offset for blocks when considering collisions with desks.
// On average a desk region is 6' x 6' so this is a ~3' offset.
// For the time being we are keeping this approach as it lets us test a box (Block)
// against a point (Desk) and is more performant than testing box with polygon.
export const BLOCK_OFFSET = 35;

export const calcDesks = (
  metric: Metric,
  features: Pick<FeaturesExt, "uniqueDesks" | "primaryCirculation">,
  instances?: PortfolioBlockInstance[][]
) => {
  const { uniqueDesks, primaryCirculation } = features;
  // Only run if the feature is used, this is expensive
  const disabledCirculationDesks =
    metric.corridorWidth > 0
      ? disableCirculationDesks(
          uniqueDesks,
          metric.corridorWidth,
          primaryCirculation
        )
      : [];
  const removedDesks = instances
    ? removeRegionDesks(uniqueDesks, instances)
    : [];
  const disabledDistanceDesks = updateDistanceDesks(
    uniqueDesks,
    metric.deskSpacing
  );
  const current = calculateCurrentDesks({
    desks: uniqueDesks,
    disabledCirculationDesks,
    disabledDistanceDesks,
    removedDesks,
  });
  return {
    disabledCirculationDesks,
    removedDesks,
    disabledDistanceDesks,
    ...current,
  };
};

function contains(arr: Coordinate[], c: Coordinate): boolean {
  let i = arr.length;
  while (i--) {
    if (arr[i].x === c.x && arr[i].y === c.y) {
      return true;
    }
  }
  return false;
}

export const calculateCurrentDesks = ({
  desks,
  removedDesks,
  disabledCirculationDesks,
  disabledDistanceDesks,
}: {
  desks: Coordinate[];
  removedDesks: Coordinate[];
  disabledCirculationDesks?: Coordinate[];
  disabledDistanceDesks?: Coordinate[];
}): { keptCenters: Coordinate[]; disabledCenters: Coordinate[] } => {
  const disC = disabledCirculationDesks ? disabledCirculationDesks : [];
  const disD = disabledDistanceDesks ? disabledDistanceDesks : [];
  const disabled = disC.concat(disD);
  const disabledCenters: Coordinate[] = disabled.filter((c, i) => {
    return !contains(removedDesks, c) && disabled.indexOf(c) === i;
  });
  const keptCenters = desks.filter((c) => {
    return !contains(removedDesks, c) && !contains(disabledCenters, c);
  });
  return { disabledCenters, keptCenters };
};

export const disableCirculationDesks = (
  desks: Coordinate[],
  corridorWidth: number,
  primaryCirculation: number[][][]
): Coordinate[] => {
  const disabled: Coordinate[] = [];
  desks.forEach((c: Coordinate) => {
    const keep = removeDesksFromCirculation(
      c,
      primaryCirculation,
      corridorWidth
    );
    if (!keep) disabled.push(c);
  });
  return disabled;
};

function uniqBy(a: Coordinate[]) {
  const seen: { [key: string]: any } = {};
  return a.filter(function (item) {
    const k = JSON.stringify(item);
    // eslint-disable-next-line no-prototype-builtins
    return seen.hasOwnProperty(k) ? false : (seen[k] = true);
  });
}

export const removeRegionDesks = (
  desks: Coordinate[],
  instances: PortfolioBlockInstance[][]
): Coordinate[] => {
  const removedDesks: Coordinate[] = [];

  const transformedRegions: Point[][] = instances.map((instance, i) => {
    if (!instances[i][0]) {
      return [];
    }
    const block = instances[i][0];
    const { box } = block.geometry;

    if (box) {
      const b = [
        vec3.fromValues(box[0][0] - BLOCK_OFFSET, box[1][1] + BLOCK_OFFSET, 0),
        vec3.fromValues(box[0][0] - BLOCK_OFFSET, box[0][1] - BLOCK_OFFSET, 0),
        vec3.fromValues(box[1][0] + BLOCK_OFFSET, box[0][1] - BLOCK_OFFSET, 0),
        vec3.fromValues(box[1][0] + BLOCK_OFFSET, box[1][1] + BLOCK_OFFSET, 0),
      ];
      const t: Point[] = b
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .map((p) => vec3.transformMat4(vec3.create(), p, block.matrix!))
        .map((p) => [p[0], p[1]]);
      return t;
    } else return [];
  });

  desks.forEach((c) => {
    transformedRegions.forEach((region) => {
      const isInside = pointInPolygon([c.x, c.y], region);
      if (isInside) removedDesks.push(c);
    });
  });

  const uniq = uniqBy(removedDesks);
  return uniq;
};

export const updateDistanceDesks = (
  desks: Coordinate[],
  deskSpacing: number
): Coordinate[] => {
  const kept: Coordinate[] = [];
  const disabled: Coordinate[] = [];
  desks.forEach((c) => {
    const keep = desksMinDistanceApart(c, kept, deskSpacing);
    if (!keep) disabled.push(c);
    else kept.push(c);
  });
  return disabled;
};

export const distanceBetweenPoints = (p1: number[], p2: number[]): number => {
  const [p1x, p1y] = p1;
  const [p2x, p2y] = p2;

  return Math.sqrt(Math.pow(p1x - p2x, 2) + Math.pow(p1y - p2y, 2));
};

export const distanceToLine = (point: number[], line: number[][]): number => {
  const { distance } = distanceToLineWithIntersectionPoint(point, line);
  return distance;
};

export const isPointOnLine = (point: number[], line: number[][]): boolean => {
  const [px, py] = point;
  const [[l1x, l1y], [l2x, l2y]] = line;

  if (
    (l1x === l2x && l1y === l2y) ||
    (px === l1x && py === l1y) ||
    (px === l2x && py === l2y)
  ) {
    return false;
  } else {
    return (py - l1y) * (l2x - l1x) === (l2y - l1y) * (px - l1x);
  }
};

// politely borrowed from https://github.com/vhf/distance-to-polygon
export const distanceToLineWithIntersectionPoint = (
  point: number[],
  line: number[][]
): { distance: number; intersection: number[] } => {
  const [px, py] = point;
  const [[l1x, l1y], [l2x, l2y]] = line;

  if (l1x === l2x && l1y === l2y) {
    return {
      distance: distanceBetweenPoints(point, line[0]),
      intersection: line[0],
    };
  }

  const xD = l2x - l1x;
  const yD = l2y - l1y;

  const u = ((px - l1x) * xD + (py - l1y) * yD) / (xD * xD + yD * yD);

  let closestLine;
  if (u < 0) {
    closestLine = [l1x, l1y];
  } else if (u > 1) {
    closestLine = [l2x, l2y];
  } else {
    closestLine = [l1x + u * xD, l1y + u * yD];
  }

  const dist = distanceBetweenPoints([px, py], closestLine);

  return {
    distance: dist,
    intersection: closestLine,
  };
};

const distanceToPolygon = (point: number[], poly: number[][]): number => {
  const dists = poly.map<number>((l1: number[], idx: number) => {
    const prev = idx === 0 ? poly.length - 1 : idx - 1;
    const l0 = poly[prev];

    const lineDist = distanceToLine(point, [l0, l1]);
    return lineDist;
  });

  return dists.reduce(
    (min: number, curr: number) => Math.min(min, curr),
    Infinity
  );
};

export const minDistanceToPolygons = (
  point: number[],
  polys: number[][][]
): number => {
  const polyDists = polys.map<number>((curr) => distanceToPolygon(point, curr));
  return polyDists.reduce((min, curr) => Math.min(min, curr), Infinity);
};

export const distancePoint = (p0: Coordinate, p1: Coordinate) => {
  const dx = Math.abs(p0.x - p1.x);
  const dy = Math.abs(p0.y - p1.y);
  return Math.sqrt(dx * dx + dy * dy);
};

export const nullCoordinates = (v: Coordinate) => v.x !== 0 || v.y !== 0;

export const getUniqueWorkstations = (features: Features): Coordinate[] => {
  const workstationCenters =
    features.desks.map(findFeatureCentroid).filter(nullCoordinates) || [];

  const uniqueWorkstationMap: { [k: string]: Coordinate } = {};
  workstationCenters.forEach((point: Coordinate) => {
    uniqueWorkstationMap[`${point.x}/${point.y}`] = point;
  });

  return Object.values(uniqueWorkstationMap);
};

export const getPrimaryCirculation = (features: Features): number[][][] => {
  const primCir: number[][][] = features.circulation.map<number[][]>((e) => {
    if (!e) return [];
    return e.map<number[]>((c: Coordinate) => [c.x, c.y]);
  });

  return [...primCir];
};

export const removeDesksFromCirculation = (
  center: Coordinate,
  primaryCirc: number[][][],
  circulationBuffer: number
): boolean => {
  const distanceToCirc = minDistanceToPolygons(
    [center.x, center.y],
    primaryCirc
  );
  return circulationBuffer < distanceToCirc;
};

export const desksMinDistanceApart = (
  center: Coordinate,
  keptCenters: Coordinate[],
  minDistance: number
): boolean => {
  for (let j = 0; j < keptCenters.length; j++) {
    if (distancePoint(center, keptCenters[j]) < minDistance) {
      return false;
    }
  }
  return true;
};
