import { format, Point } from "./utils";

export type Polyline = {
  type: "polyline";
  points: Point[];
};

export const polyline = ({ points }: Polyline, name: string): string => {
  // let closed = points.length > 1 && points[0][0] === points[points.length - 1][0] && points[0][1] === points[points.length - 1][1];
  if (points.length === 0) return "";
  // prettier-ignore
  return (
    format("0", "POLYLINE") +
    format("10", 0) +
    format("20", 0) +
    format("30", 0) +
    format("8", name) +
    // closed ? format("70", 1) : format("70", 0) +
    format("70", 0) +
    format("66", 1) +
    points.map((p) => {
      return (
        format("0", "VERTEX") +
        format("8", name) +
        format("10", p[0]) +
        format("20", p[1]) +
        format("30", 0)
      )
    }).join("") +
    format("0", "SEQEND")
  );
};
