import { Block } from "blocks/lib/types";

export const getBooths = (blocks: Block[][]) => {
  let booths = 0;

  blocks.forEach((arr) =>
    arr.forEach((b) => {
      // if we want to do it by block instead of asset...
      // (b.props.definition?.name || "").match(/nook/i)
      const key = Object.keys(b.props.metrics?.names || {}).filter((name) =>
        name.match(/booth/i)
      )?.[0];
      if (key) {
        booths += b.props?.metrics?.names?.[key] || 0;
      }
    })
  );
  return booths;
};
