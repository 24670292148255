import { Block } from "../lib/types";
import { getters } from "../lib/constants";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { getBlockMinMaxDimensions } from "../lib/blocks";
import { round } from "../../lib/number";
import { formatInches } from "../lib/canvas";
import { useDrag } from "react-dnd";
const blue = "47,115,255";

const useStyles = makeStyles(() => ({
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
    alignCards: "center",
    padding: 8,
    transition: "0.2s ease-in",
    "&:hover > :first-child": {
      border: "3px solid rgba(0,0,0,0.2)",
    },
  },
  active: {
    "& > :first-child": {
      border: `3px solid rgba(${blue},1)`,
    },
    "&:hover > :first-child": {
      border: `3px solid rgba(${blue},1)`,
    },
  },
  title: {
    fontSize: "0.9rem",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
  },
  sizeDefault: {
    height: 110,
    width: 110,
  },
  sizeLarge: {
    height: 140,
    width: 160,
  },
  image: {
    display: "flex",
    alignCards: "center",
    justifyContent: "center",
    border: "3px solid rgba(0,0,0,0)",
    borderRadius: 8,
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },
  },
  content: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    fontSize: "0.8rem",
  },
  property: {
    display: "flex",
  },
  fieldTitle: {
    paddingRight: "5px",
    paddingBottom: "0px",
    fontWeight: "bold",
  },
}));

const calculateSize = (block: Block) => {
  const { sizeRange } = getters.getMetrics(block);
  return `${formatInches(sizeRange[0][0])} - ${formatInches(sizeRange[1][0])}`;
};

const calculateArea = (block: Block) => {
  const coordinate = getBlockMinMaxDimensions(block);
  const minArea = round((coordinate[0][0] / 12) * (coordinate[1][0] / 12));
  const maxArea = round((coordinate[0][1] / 12) * (coordinate[1][1] / 12));
  if (minArea === maxArea) {
    return `${minArea} SF`;
  }
  return `${minArea} SF to ${maxArea} SF`;
};

const BlockCard = ({
  block,
  onClick,
  image,
  showSize = false,
  showArea = false,
  showLibrary = false,
  showSeats = false,
  showHeadcount = false,
  showWorkpoints = false,
  size = "default",
  showSeatsRange = false,
  active = false,
}: {
  block: Block;
  onClick: (id: string) => void;
  image: string | null;
  size?: string;
  showSize?: boolean;
  showArea?: boolean;
  showLibrary?: boolean;
  showSeats?: boolean;
  showHeadcount?: boolean;
  showWorkpoints?: boolean;
  showIndividualArea?: boolean;
  showCommunalArea?: boolean;
  showSeatsRange?: boolean;
  active?: boolean;
}) => {
  const classes = useStyles();
  const { seatsRange, seats, headcount, workpoints } =
    getters.getMetrics(block);
  const { name, library } = getters.getDefinition(block);

  const [, drag] = useDrag(() => ({
    type: "block",
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    item: { data: { id: block.id } },
  }));
  const seatsValue = seats + "";
  const workpointsValue = workpoints + "";
  const headcountValue = headcount + "";
  let seatsRangeValue = "0";
  if (seatsRange[0] === seatsRange[1]) {
    seatsRangeValue = seatsRange[0] + "";
  } else {
    seatsRangeValue = seatsRange[0] + " - " + seatsRange[1];
  }
  const s = size === "default" ? classes.sizeDefault : classes.sizeLarge;

  return (
    <div
      className={`${classes.item} ${active ? classes.active : ""}`}
      onClick={() => onClick(block.id)}
    >
      <div className={`${classes.image} ${s}`}>
        {image && <img src={image} alt={block.props.name} ref={drag} />}
      </div>
      <div className={classes.content}>
        <div className={classes.title}>{name}</div>
        {showLibrary && (
          <div className={classes.property}>
            <div className={classes.fieldTitle}>Library Name: </div>
            <div>{library.length === 0 ? "No library" : library}</div>
          </div>
        )}
        {showSeatsRange && (
          <div className={classes.property}>
            <div>{seatsRangeValue} seats</div>
          </div>
        )}
        {showHeadcount && (
          <div className={classes.property}>
            <div>{headcountValue} Assignable Seats</div>
          </div>
        )}
        {showSeats && (
          <div className={classes.property}>
            <div>{seatsValue} seats</div>
          </div>
        )}
        {showWorkpoints && (
          <div className={classes.property}>
            <div>{workpointsValue} workpoints</div>
          </div>
        )}
        {showArea && (
          <div className={classes.property}>
            <div>{calculateArea(block)}</div>
          </div>
        )}
        {showSize && (
          <div className={classes.property}>
            <div className={classes.fieldTitle}>Size: </div>
            <div>{calculateSize(block)}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BlockCard;
