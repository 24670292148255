import { ComponentType, useCallback, useEffect, useState } from "react";
import { ContainerProviderProps, createContainer } from "unstated-next";
import {
  Coordinate,
  Features,
  XForm,
  SelectedMeetingRoom,
  ConferenceRooms,
  RoomBounds,
} from "lib/types";
import { FloorConfiguration, getFloorConfiguration } from "lib/api/floorConfig";
import { generateXform } from "lib/isp-canvas/utils";
import getFloorCirculation from "lib/api/floorCirculation";
import { Graph } from "lib/metrics/buzz";
import { useProjectCtrl } from ".";
import getFeatures from "lib/api/getFeatures";
import { PIXEL_RATIO } from "../../blocks/lib/util";
import {
  ALL_MEETING_ROOMS,
  MeetingRoomMetricsLevels,
  MeetingRoomMetricsType,
  MeetingRoomSizeAndAllType,
} from "./meeting-room-metrics-manager";

export enum RendererMode {
  Pan,
  Draw,
  Delete,
  Resize,
  Select,
  Move,
  Transforming,
  DrawCirculation,
  DeleteCirculation,
  Selected,
  EditCirculation,
  BuzzMetric,
  DaylightMetric,
  MeetingRoomMetric,
  WalkabilityMetric,
  WalkabilityOutput,
  DrawWalkabilityCirculation,
  DeleteWalkabilityCirculation,
  EditWalkabilityCirculation,
}

export enum BlockType {
  All,
  EraseSeats,
  Collaboration,
  /** @deprecated Use MixedUse */
  Mixed,
  Focus,
  MixedUse,
}

export enum WalkabilityMetric {
  Microkitchen = "microKitchen",
  ConnectingStair = "connectingStair",
  Restroom = "restroom",
  PhoneBooth = "phoneBooth",
  Huddle = "huddle",
  SmallMeetingRoom = "smallMeetingRoom",
  MedMeetingRoom = "mediumMeetingRoom",
  LargeMeetingRoom = "largeMeetingRoom",
  AllAmenities = "allAmenities",
}

interface Store {
  width: number;
  height: number;
  activeControl: string;
  offsetX: number;
  offsetY: number;
  rendererMode: RendererMode;
  blockType: BlockType;
  xform?: XForm;
  initScale?: number;
  initPosition?: Coordinate;
  fitScreenScale?: number;
  fitScreenPosition?: Coordinate;
  selectedRegion?: number[];
  showBuzz: boolean;
  showDaylightMetric: boolean;
  selectedWalkability?: string;
  daylightTexture: string;
  meetingRoomMetricSelectedRoom: string | null;
  meetingRoomMetricsSize: MeetingRoomSizeAndAllType;
  meetingRoomMetricsType: MeetingRoomMetricsType;
  meetingRoomMetricsLevels: MeetingRoomMetricsLevels;
  wpiActivitiesMode: boolean;
  walkabilityMetric?: WalkabilityMetric;
  walkabilityOutput?: {
    seatCoords: Coordinate;
    distance: number;
    feature: RoomBounds | undefined;
    path: Coordinate[];
  }[];
  walkabilityBuildingOutput?: {
    seatCoords: Coordinate;
    distance: number;
    feature: RoomBounds | undefined;
    path: Coordinate[];
  }[];
  selectedRoom?: SelectedMeetingRoom;
  selectedRooms?: SelectedMeetingRoom[];
  allConferenceRooms?: [];
  defaultCirculation?: Graph;
  combinedConferenceRooms?: ConferenceRooms;
  dxfDownloadInProgress?: boolean;
  features?: Features;
}

interface UseRendererState extends Store {
  setStoreValue(name: string, value: any): void;
  setActiveControl(value: string): void;
  setFloorplan({
    projectID,
    buildingID,
    floorID,
    strategyID,
  }: InitialState): void;
  setRendererMode(mode: RendererMode): void;
  setBlockType(typeSelection: BlockType): void;
  setXform(width: number, height: number, features: Features): Promise<void>;
  selectRegion(idx: number[] | undefined): void;
  selectRooms(rooms: SelectedMeetingRoom[] | undefined): void;
  updateXform(
    fn: (xform: XForm) => {
      scale?: number;
      translation?: { x: number; y: number };
    }
  ): void;
  resetXform(features: Features): void;
  setShowBuzz(showBuzz: boolean): void;
  setSelectedWalkability(selectedWalkability: string): void;
  setShowDaylightMetric(showDaylightMetric: boolean): void;
  setDaylightTexture(daylightTexture: string): void;
  setMeetingRoomMetricsType(type: string): void;
  setMeetingRoomMetricsSelectedRoom(x: string | null): void;
  setMeetingRoomMetricsSize(type: MeetingRoomSizeAndAllType): void;
  setWpiActivitiesMode(type: boolean): void;
  setMeetingRoomMetricsLevels(type: string): void;
  setWalkabilityMetric(metric: string): void;
  setWalkabilityOutput(
    output: {
      seatCoords: Coordinate;
      distance: number;
      feature: {
        distance: number;
        line: string[];
        intersection: Coordinate;
        origin: Coordinate;
      };
      path: Coordinate[];
    }[]
  ): void;
  setWalkabilityBuildingOutput(
    output: {
      seatCoords: Coordinate;
      distance: number;
      feature: {
        distance: number;
        line: string[];
        intersection: Coordinate;
        origin: Coordinate;
      };
      path: Coordinate[];
    }[]
  ): void;
  setDxfDownloadInProgress(dxfDonwloadInProgress: boolean): void;
  loaded: boolean;
  floorLoaded: boolean;
}

const defaultXform: XForm = {
  position: { x: 0, y: 0 },
  height: 0,
  width: 0,
  scale: 1,
  globalScale: PIXEL_RATIO,
};

// Renderer mode for initial page load and when 'resetting' renderer mode
export const DEFAULT_RENDERER_MODE = RendererMode.Select;

const defaultStore: Store = {
  width: 0,
  height: 0,
  activeControl: "",
  offsetX: 0,
  offsetY: 0,
  rendererMode: DEFAULT_RENDERER_MODE,
  blockType: BlockType.Focus,
  xform: defaultXform,
  showBuzz: false,
  showDaylightMetric: true,
  selectedWalkability: "",
  daylightTexture: "",
  meetingRoomMetricSelectedRoom: null,
  meetingRoomMetricsType: MeetingRoomMetricsType.BookingRate,
  meetingRoomMetricsSize: ALL_MEETING_ROOMS,
  meetingRoomMetricsLevels: MeetingRoomMetricsLevels.Current,
  wpiActivitiesMode: false,
  walkabilityMetric: WalkabilityMetric.AllAmenities,
  dxfDownloadInProgress: false,
};

interface InitialState {
  projectID: string;
  buildingID: string;
  floorID: string;
  strategyID: string;
}

export const useRendererState = (
  initialState: InitialState | undefined
): UseRendererState => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [floorLoaded, setFloorLoaded] = useState<boolean>(false);
  const [store, setStore] = useState<Store>(defaultStore);
  const [state, setState] = useState<InitialState | undefined>();
  const { project } = useProjectCtrl();

  const updateXform = (
    fn: (xform: XForm) => {
      scale?: number;
      translation?: { x: number; y: number };
    }
  ): void => {
    if (!store.xform) return;
    setStore((s) => {
      if (s.xform) {
        const { scale, translation } = fn(s.xform);

        const newPosition = translation
          ? {
              x: s.xform?.position.x + translation.x,
              y: s.xform?.position.y + translation.y,
            }
          : s.xform.position;
        const newScale = scale || s.xform.scale;

        // defaultXform.scale = newScale;
        // defaultXform.position = newPosition;

        return {
          ...s,
          xform: {
            ...s.xform,
            position: newPosition,
            scale: newScale,
          },
        };
      }
      return s;
    });
  };

  const resetXform = async (features: Features) => {
    setXform(store.width, store.height, features);
  };

  const setXform = async (
    width: number,
    height: number,
    features: Features
  ): Promise<void> => {
    if (!features) return;
    const xform = generateXform(
      defaultXform,
      features.floorplate,
      width * defaultXform.globalScale,
      height * defaultXform.globalScale
    );
    setStore((s) => {
      return { ...s, xform, width, height };
    });
  };

  const setFloorplan = useCallback(
    async ({ projectID, buildingID, floorID, strategyID }: InitialState) => {
      if (!projectID || !buildingID || !floorID || !strategyID) return;
      setState({ projectID, buildingID, strategyID, floorID });

      setFloorLoaded(false);
      let offsets = await getFloorConfiguration(buildingID, floorID);
      if (offsets === undefined) {
        offsets = { offsetX: 0, offsetY: 0 } as FloorConfiguration;
      }
      const defaultCirculation = await getFloorCirculation(buildingID, floorID);

      let combinedConferenceRooms: ConferenceRooms = {
        extraLarge: {},
        huddle: {},
        interview: {},
        large: {},
        medium: {},
        phone: {},
        small: {},
      };
      if (project) {
        const allConferenceRooms: ConferenceRooms[] = [];
        const metricBuilding = project.metrics;
        const metricsObjects = Object.values(metricBuilding).filter(
          (metric) => metric.strategyID === strategyID
        );

        await Promise.all(
          metricsObjects.map(async (metric) => {
            const feature = await getFeatures(buildingID, metric.floor);
            if (feature && feature.conferenceRooms) {
              allConferenceRooms.push(feature.conferenceRooms);
            }
          })
        );

        combinedConferenceRooms = allConferenceRooms.reduce(
          (acc: ConferenceRooms, curr: ConferenceRooms) => {
            acc.extraLarge = { ...acc.extraLarge, ...curr.extraLarge };
            acc.huddle = { ...acc.huddle, ...curr.huddle };
            acc.large = { ...acc.large, ...curr.large };
            acc.medium = { ...acc.medium, ...curr.medium };
            acc.phone = { ...acc.phone, ...curr.phone };
            acc.small = { ...acc.small, ...curr.small };
            return acc;
          },
          {
            extraLarge: {},
            huddle: {},
            interview: {},
            large: {},
            medium: {},
            phone: {},
            small: {},
          }
        );
      }

      await setStore((s) => ({
        ...s,
        ...offsets,
        selectedRegion: undefined,
        selectedRoom: undefined,
        rendererMode:
          s.rendererMode === RendererMode.BuzzMetric
            ? RendererMode.BuzzMetric
            : RendererMode.Selected,
        defaultCirculation,
        combinedConferenceRooms,
      }));
      setFloorLoaded(true);
    },
    [project]
  );

  const setActiveControl = (value: string) => {
    setStore((s) => ({ ...s, activeControl: value }));
  };

  useEffect(() => {
    const setInitialValues = async () => {
      if (initialState) {
        setState(initialState);
        await setFloorplan(initialState);
        setLoaded(true);
      }
    };
    if (!loaded && !state?.buildingID) setInitialValues();
  }, [initialState, setFloorplan, loaded, state]);

  useEffect(() => {
    if (!state || !state.buildingID || !state.floorID) return;
    getFeatures(state?.buildingID as string, state?.floorID as string).then(
      (features) => {
        if (features) {
          const form = generateXform(
            defaultXform,
            features?.floorplate as Coordinate[][],
            store.width * defaultXform.globalScale,
            store.height * defaultXform.globalScale
          );
          setStore((s) => ({
            ...s,
            features,
            fitScreenScale: form.scale,
            fitScreenPosition: form.position,
          }));
        }
      }
    );
  }, [
    state,
    state?.buildingID,
    state?.floorID,
    store.width,
    store.height,
    setStore,
  ]);

  const setStoreValue = (name: string, value: any) => {
    setStore((s) => ({ ...s, [name]: value }));
  };

  const setStoreValues = (o: { [k: string]: any }) => {
    setStore((s) => ({ ...s, ...o }));
  };

  const setRendererMode = (mode: RendererMode) => {
    setStoreValue("rendererMode", mode);
  };

  const setBlockType = (blockTypeSelection: BlockType) => {
    setStoreValue("blockType", blockTypeSelection);
  };

  const selectRegion = (idx: number[] | undefined) => {
    setStoreValues({
      selectedRegion: idx,
      rendererMode: idx ? RendererMode.Selected : RendererMode.Select,
    });
  };

  const selectRooms = (rooms: SelectedMeetingRoom[]) => {
    setStoreValue("selectedRooms", rooms);
  };

  const setShowBuzz = (showBuzz: boolean) => {
    setStoreValue("showBuzz", showBuzz);
  };

  const setShowDaylightMetric = (showDaylightMetric: boolean) => {
    setStoreValue("showDaylightMetric", showDaylightMetric);
  };

  const setSelectedWalkability = (selectedWalkability: string) => {
    setStoreValue("selectedWalkability", selectedWalkability);
  };

  const setDaylightTexture = (daylightTexture: string) => {
    setStoreValue("daylightTexture", daylightTexture);
  };

  const setMeetingRoomMetricsType = (type: string) => {
    setStoreValue("meetingRoomMetricsType", type);
  };

  const setMeetingRoomMetricsSelectedRoom = (x: string | null) => {
    setStoreValue("meetingRoomMetricSelectedRoom", x);
  };

  const setMeetingRoomMetricsSize = (type: MeetingRoomSizeAndAllType) => {
    setStoreValue("meetingRoomMetricsSize", type);
  };

  const setMeetingRoomMetricsLevels = (type: string) => {
    setStoreValue("meetingRoomMetricsLevels", type);
  };

  const setWpiActivitiesMode = (type: boolean) => {
    setStoreValue("wpiActivitiesMode", type);
  };
  const setWalkabilityMetric = (metric: string) => {
    setStoreValue("walkabilityMetric", metric);
  };

  const setWalkabilityOutput = (
    output: {
      seatCoords: Coordinate;
      distance: number;
      feature: {
        distance: number;
        line: string[];
        intersection: Coordinate;
        origin: Coordinate;
      };
      path: Coordinate[];
    }[]
  ) => {
    setStoreValue("walkabilityOutput", output);
  };

  const setWalkabilityBuildingOutput = (
    output: {
      seatCoords: Coordinate;
      distance: number;
      feature: {
        distance: number;
        line: string[];
        intersection: Coordinate;
        origin: Coordinate;
      };
      path: Coordinate[];
    }[]
  ) => {
    setStoreValue("walkabilityBuildingOutput", output);
  };

  const setDxfDownloadInProgress = (dxfDownloadInProgress: boolean) => {
    setStoreValue("dxfDownloadInProgress", dxfDownloadInProgress);
  };

  return {
    ...store,
    setStoreValue,
    setActiveControl,
    setFloorplan,
    setRendererMode,
    setBlockType,
    selectRegion,
    selectRooms,
    setXform,
    updateXform,
    resetXform,
    loaded,
    floorLoaded,
    setShowBuzz,
    setShowDaylightMetric,
    setSelectedWalkability,
    setDaylightTexture,
    setMeetingRoomMetricsSelectedRoom,
    setMeetingRoomMetricsType,
    setMeetingRoomMetricsSize,
    setMeetingRoomMetricsLevels,
    setWpiActivitiesMode,
    setWalkabilityMetric,
    setDxfDownloadInProgress,
    setWalkabilityOutput,
    setWalkabilityBuildingOutput,
  };
};

export const RendererController = createContainer(useRendererState);
export const RendererProvider: ComponentType<
  ContainerProviderProps<InitialState>
> = RendererController.Provider;
export const useRendererCtrl = () => RendererController.useContainer();
