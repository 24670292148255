import React from "react";
import { makeStyles, Tooltip } from "@material-ui/core";
import { emptyMetricsProp, getters } from "../lib/constants";
import { useBlocksCtrl, useRendererCtrl } from "../../lib/containers";
import { mergeChildMetrics } from "../lib/blocks";
import { RendererSettings } from "../../lib/types";
import { MetricsProps } from "../lib/types";
import { ReactComponent as BlueprintIcon } from "assets/icons/BlueprintButton.svg";
import LinkIcon from "@material-ui/icons/OpenInNew";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    margin: "0 -24px",
  },
  titles: {
    marginRight: 60,
  },
  values: {
    flexGrow: 1,
  },
  urls: {
    width: 40,
  },
  row: {
    display: "flex",
    fontSize: "0.9rem",
    width: "100%",
    padding: "0 24px",
    height: 32,
    color: "#5F6166",
    alignItems: "center",
    "&:hover": {
      background: "#f4f4f6",
    },
    "&:hover svg": {
      opacity: 1,
    },
  },
  title: {
    width: 160,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  value: {
    flexGrow: 1,
    marginLeft: 16,
  },
  link: {
    "& svg": {
      opacity: 0,
    },
    position: "relative",
  },
  bullet: {
    height: 10,
    width: 10,
    marginRight: 12,
    borderRadius: 10,
    paddingTop: 8,
  },
});

interface Props {
  settings?: RendererSettings;
}

const bulletColors = [
  "#6dccda",
  "#cdcc5d",
  "#a2a2a2",
  "#ed97ca",
  "#a8786e",
  "#ad8bc9",
  "#ed665d",
  "#67bf5c",
  "#ff9e4a",
  "#729ece",
];

export const BlockComponentsSettings: React.FC<Props> = ({ settings }) => {
  const { selectedRegion } = useRendererCtrl();
  if (selectedRegion === undefined || !settings) return null;
  const baseMetrics = { ...emptyMetricsProp };
  let metrics = baseMetrics;
  selectedRegion.forEach((r) => {
    const block = settings.blocks ? settings.blocks[r] : undefined;
    if (block) {
      const mets = getters.getMetrics(block[0]);
      metrics = mergeChildMetrics([metrics, mets]);
    }
  });
  return <BlockComponentsMetrics metrics={metrics} />;
};

const BlockComponentsMetrics: React.FC<{ metrics: MetricsProps }> = ({
  metrics,
}) => {
  const classes = useStyles();
  const { blocks } = useBlocksCtrl();
  if (!metrics.names) return null;
  const names = Object.keys(metrics.names)
    .map((k) => ({ name: k, value: metrics.names[k] }))
    .sort((a, b) => (a.value > b.value ? -1 : a.value < b.value ? 1 : 0));
  const urls = names.map(({ name }) => {
    const block = blocks.find(
      (el) => el.props.definition && el.props.definition.name === name
    );
    const url =
      block && block.props.definition && block.props.definition
        ? block.props.definition.url
        : "";
    return url;
  });

  // return (
  //   <div className={classes.root}>
  //     <div className={classes.titles}>
  //       {names.map(({ name, value }, i) => (
  //         <div key={name} className={classes.row}>
  //           <div className={classes.bullet} style={{ backgroundColor: bulletColors[i] }} />
  //           <div className={classes.title}>{name}</div>
  //         </div>
  //       ))}
  //     </div>
  //
  //     <div className={classes.values}>
  //       {names.map(({ name, value }, i) => (
  //         <div key={name} className={classes.row}>
  //           <div className={classes.value}>{value}</div>
  //         </div>
  //       ))}
  //     </div>
  //     <div className={classes.urls}>
  //       {names.map(({ name, value }, i) => (
  //         <div key={name} className={classes.row}>
  //           {urls[i] && urls[i] !== "" && (
  //             <Tooltip title={"Open in Blueprint"} placement={"right"}>
  //               <div className={classes.link}>
  //                 <a href={urls[i]}>
  //                   <BlueprintIcon />
  //                 </a>
  //               </div>
  //             </Tooltip>
  //           )}
  //         </div>
  //       ))}
  //     </div>
  //   </div>
  // );
  return (
    <div className={classes.root}>
      {names.map(({ name, value }, i) => (
        <div
          key={name}
          className={classes.row}
          style={{ cursor: urls[i] && urls[i] !== "" ? "pointer" : "default" }}
          onClick={() => {
            if (urls[i] && urls[i] !== "") {
              window.open(urls[i], "_blank");
            }
          }}
        >
          <div
            className={classes.bullet}
            style={{ backgroundColor: bulletColors[i] }}
          />
          <div className={classes.title}>{name}</div>
          <div className={classes.value}>{value}</div>
          {urls[i] &&
            urls[i] !== "" &&
            (urls[i]?.match(/blueprint\.site/g) ? (
              <Tooltip title={"Open in Blueprint"} placement={"right"}>
                <div className={classes.link}>
                  <BlueprintIcon />
                </div>
              </Tooltip>
            ) : (
              <Tooltip title={"Open in new tab"} placement={"right"}>
                <div className={classes.link}>
                  <LinkIcon />
                </div>
              </Tooltip>
            ))}
        </div>
      ))}
    </div>
  );
};

export default React.memo(BlockComponentsMetrics);
