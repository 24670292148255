import React, { useEffect, useState, SyntheticEvent } from "react";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import EditIcon from "@material-ui/icons/Edit";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import { ReactComponent as ResetIcon } from "assets/icons/Reset.svg";
import ArrowForwardIosOutlinedIcon from "@material-ui/icons/ArrowForwardIos";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormattedDelta from "components/formatted-delta";
import {
  useTableCtrl,
  useProjectCtrl,
  useStrategyCtrl,
  useAutoMagicCtrl,
  useSettingsCtrl,
} from "lib/containers";
import { toPercent, fromPercent, truncateNumber } from "lib/number";
import { TableRowMetric, DisplayTableRowMetric } from "lib/types";
import { InputBase } from "@material-ui/core";
import Loading from "components/loading";
import { mdiPinOutline } from "@mdi/js";
import Icon from "@mdi/react";
import analytics from "../../lib/analytics";
import { useHistory, useParams } from "react-router-dom";
import { Dialog, Menu, MenuItem, MenuDivider } from "@outerlabs/ol-ui";
import TextField from "blocks/components/textfield";

import { Columns } from "./index";
import { permanentHeaders, populatedHeaders } from "./head";

interface StyleProps {
  backgroundColor: string;
}
interface HeaderChevronCellProps {
  open: boolean;
  setOpen(open: boolean): void;
}
interface MenuCellProps {
  isPrimary: boolean;
  projectID: string;
  buildingID: string;
  strategyID: string;
  redirectURL?: string;
  displayName?: string;
}
interface CellSetProps {
  row: DisplayTableRowMetric;
  editable?: boolean;
  isPrimary?: boolean;
  name?: string;
  isHeader?: boolean;
  collapsedRow?: boolean;
  redirectURL?: string;
}

export const cellStyles = {
  width: "8%",
  padding: "5px 0",
  display: "flex",
  height: "48px",
  lineHeight: "16px",
};

export const actionStyles = {
  width: "3%",
  padding: "16px 0",
  display: "flex",
  alignItems: "center",
  height: "48px",
};

const useStyles = makeStyles({
  cell: (props: StyleProps) => ({
    backgroundColor: props.backgroundColor,
    justifyContent: "flex-start",
    alignItems: "center",
    ...cellStyles,
    fontSize: "14px",
  }),
  lastCell: (props: StyleProps) => ({
    backgroundColor: props.backgroundColor,
    ...cellStyles,
    paddingRight: 16,
    justifyContent: "center",
  }),
  nameCell: (props: StyleProps) => ({
    backgroundColor: props.backgroundColor,
    ...cellStyles,
    width: "16%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    fontSize: "14px",
    color: "black",
  }),
  nameCellCollapsed: (props: StyleProps) => ({
    backgroundColor: props.backgroundColor,
    ...cellStyles,
    width: "16%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    fontSize: "14px",
    color: "#949494",
  }),
  nameCellContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  actionCellFirst: (props: StyleProps) => ({
    backgroundColor: props.backgroundColor,
    ...actionStyles,
    justifyContent: "center",
    alignItems: "center",
  }),
  actionCell: () => ({
    backgroundColor: "transparent",
    ...actionStyles,
  }),
  actionPinCell: () => ({
    backgroundColor: "transparent",
    flexDirection: "row",
    justifyContent: "center",
    width: "3%",
    display: "flex",
    alignItems: "center",
    height: "48px",
  }),
  inputCell: {
    backgroundColor: "rgba(0,0,0,0.04)",
    borderRadius: "4px",
    paddingLeft: "8px",
    paddingRight: "8px",
    width: "70%",
  },
  efficiencyLoading: { marginLeft: -2 },
  add: {
    backgroundColor: "#F6FAFE",
    fontSize: 14,
    color: "#4384F7",
    cursor: "pointer",
    alignItems: "center",
    display: "flex",
    width: "100%",
    marginLeft: "25px",
  },
  loading: {
    backgroundColor: "#FBFCFF",
    width: "100%",
  },
  empty: {
    width: 26,
    height: 26,
  },
  selected: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#878787",
  },
  overflowY: { overflowY: "auto !important" as "auto" },
  deltaContainer: { paddingLeft: "8px" },
});

// choose a color based on whether the value is above or below the baseline
function pickColor(value: number, baseline: number): string {
  if (isNaN(baseline) || baseline === Infinity) {
    return "text-black";
  } else if (value > baseline) {
    return "text-green-600";
  } else if (value < baseline) {
    return "text-red-600";
  } else {
    return "text-black";
  }
}

export const VisualizeCell: React.FC = () => {
  const classes = useStyles({ backgroundColor: "transparent" });
  return (
    <React.Fragment>
      <Cell classes={classes.actionCellFirst}>
        <div />
      </Cell>
    </React.Fragment>
  );
};

export function Cell(props: {
  children: React.ReactNode;
  classes: string;
  onClick?: () => void;
  "data-testid"?: string;
}) {
  const { children, classes, onClick, "data-testid": dataTestId } = props;
  return (
    <div className={classes} onClick={onClick} data-testid={dataTestId}>
      {children}
    </div>
  );
}

export function HeaderChevronCellSet(props: HeaderChevronCellProps) {
  const { setOpen, open } = props;
  const backgroundColor = { backgroundColor: "transparent" };
  const classes = useStyles(backgroundColor);

  return (
    <React.Fragment>
      <Cell classes={classes.actionCellFirst}>
        <div />
      </Cell>
      <Cell classes={classes.actionPinCell}>
        <IconButton size="small" onClick={() => setOpen(!open)}>
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </Cell>
    </React.Fragment>
  );
}

// Button used to "pin" the row as the selected strategy
const PinCell: React.FC<MenuCellProps> = ({
  isPrimary,
  buildingID,
  strategyID,
}) => {
  const backgroundColor = { backgroundColor: "transparent" };
  const classes = useStyles(backgroundColor);
  const [hover, setHover] = useState(false);
  const { setStrategyAsPrimary } = useStrategyCtrl();
  const onClick = async (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setHover(false);
    await setStrategyAsPrimary(buildingID, strategyID);
  };
  return (
    <React.Fragment>
      <div
        className={classes.actionPinCell}
        onClick={onClick}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {hover && !isPrimary ? (
          <Icon
            path={mdiPinOutline}
            size={"16px"}
            style={{ color: "#ADC9FF" }}
          />
        ) : null}
        {isPrimary ? (
          <Icon
            path={mdiPinOutline}
            size={"16px"}
            style={{ color: "#6196FF" }}
          />
        ) : null}
      </div>
    </React.Fragment>
  );
};

export function NewStrategyCell(props: { buildingID: string }) {
  const { buildingID } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [newStrategyModal, setNewStrategyModal] = useState(false);
  const [strategyName, setStrategyName] = useState("Untitled Strategy");
  const { newStrategy } = useStrategyCtrl();

  const handleNewStrategy = async () => {
    setIsLoading(true);
    analytics.strategyAdded();
    await newStrategy(buildingID, strategyName);
    setNewStrategyModal(false);
    setIsLoading(false);
  };

  const backgroundColor = { backgroundColor: "#FBFCFF" };
  const classes = useStyles(backgroundColor);

  return (
    <React.Fragment>
      <Cell classes={classes.actionCell}>
        <div className={classes.empty} />
      </Cell>{" "}
      <Cell
        classes={classes.add}
        onClick={() => {
          setStrategyName("Untitled Strategy");
          setNewStrategyModal(true);
        }}
      >
        {isLoading ? (
          <div className={classes.loading}>
            <CircularProgress />
          </div>
        ) : (
          `Add a new strategy`
        )}
      </Cell>
      <Dialog.Sm
        open={newStrategyModal}
        title="Name Strategy"
        onSave={handleNewStrategy}
        onClose={() => setNewStrategyModal(false)}
        submitButtonText="Save"
        // prevents page reload upon 'enter' keypress
        onSubmit={(e) => {
          e.preventDefault();
        }}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            handleNewStrategy();
          }
        }}
      >
        <TextField
          autoFocus
          onChange={(e) => {
            setStrategyName(e.target.value);
          }}
          value={strategyName}
          style={{ width: "100%", height: 40 }}
        />
      </Dialog.Sm>
    </React.Fragment>
  );
}

// Cell used to show a value and it's change relative to the baseline
const ChangeCell: React.FC<{
  baseline: number;
  value: number;
}> = ({ baseline, value }) => {
  const { isPercent } = useTableCtrl();
  const backgroundColor = { backgroundColor: "transparent" };
  const classes = useStyles(backgroundColor);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start ",
        alignItems: "center",
        width: "40px",
        color: pickColor(value, baseline),
      }}
    >
      {value !== Infinity ? value : 0}
      <div className={classes.deltaContainer}>
        {!(isNaN(baseline) || baseline === Infinity || isNaN(value)) && (
          <FormattedDelta
            baseline={baseline}
            value={value}
            unit={isPercent ? "percent" : "value"}
          />
        )}
      </div>
    </div>
  );
};

// Kebab menu with additional options shown on every strategy row
const MenuCell: React.FC<MenuCellProps> = ({
  isPrimary,
  projectID,
  buildingID,
  strategyID,
  redirectURL,
  displayName,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [autoMagicLoading, setAutoMagicLoading] = useState(false);
  const [renameModalOpen, setRenameModalOpen] = useState(false);
  const [duplicateModalOpen, setDuplicateModalOpen] = useState(false);
  const [strategyName, setStrategyName] = useState(displayName);
  const [duplicateStrategyName, setDuplicateStrategyName] = useState(
    `Copy of ${displayName}`
  );
  const [duplicateLoading, setDuplicateLoading] = useState(false);

  const {
    setStrategyAsPrimary,
    deleteStrategy,
    duplicateStrategy,
    resetStrategy,
  } = useStrategyCtrl();
  const { autoMagicBuilding } = useAutoMagicCtrl();
  const { toggleIsResetting } = useTableCtrl();
  const { push } = useHistory();

  const menuItemStyle = {
    width: 220,
  };

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = async () => {
    setAnchorEl(null);
    await setStrategyAsPrimary(buildingID, strategyID);
  };

  const handleReset = async () => {
    setAnchorEl(null);
    toggleIsResetting();
    await resetStrategy({ buildingID, strategyID });
    toggleIsResetting();
  };

  const handleDelete = async () => {
    setAnchorEl(null);
    await deleteStrategy(buildingID, strategyID);
  };

  const handleDuplicateModalOpen = async () => {
    setAnchorEl(null);
    setDuplicateModalOpen(true);
  };

  const handleDuplicate = async () => {
    setDuplicateLoading(true);
    analytics.strategyDuplicated();
    await duplicateStrategy(buildingID, strategyID, duplicateStrategyName);
    setDuplicateModalOpen(false);
    setDuplicateLoading(false);
  };

  const handleRename = () => {
    setAnchorEl(null);
    setRenameModalOpen(true);
  };

  const onAutoMagicProceed = async () => {
    setAutoMagicLoading(true);
    await autoMagicBuilding(projectID, buildingID, strategyID);
    setAutoMagicLoading(false);
    setModalOpen(false);
    if (redirectURL) {
      push(redirectURL);
    }
  };

  const closeModal = () => {
    if (!autoMagicLoading) {
      setModalOpen(false);
    }
  };

  const closeRenameModal = () => {
    setRenameModalOpen(false);
  };

  const { updateBuildingMetrics } = useSettingsCtrl();

  const updateStrategyName = async () => {
    await updateBuildingMetrics(
      { displayName: strategyName },
      buildingID,
      strategyID
    );
    setRenameModalOpen(false);
  };

  return (
    <React.Fragment>
      <IconButton onClick={handleOpen} size="small">
        <MoreVertIcon style={{ width: 32 }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={anchorEl !== null}
        onClose={handleClose}
      >
        {!isPrimary ? (
          <MenuItem
            iconLeft={<Icon path={mdiPinOutline} size={"21px"} />}
            style={menuItemStyle}
            onClick={handleSelect}
          >
            Pin Strategy
          </MenuItem>
        ) : null}
        <MenuItem
          iconLeft={<FileCopyIcon />}
          style={menuItemStyle}
          onClick={handleDuplicateModalOpen}
        >
          Duplicate
        </MenuItem>
        <MenuItem
          iconLeft={<EditIcon />}
          style={menuItemStyle}
          onClick={handleRename}
        >
          Rename
        </MenuItem>
        <MenuItem
          iconLeft={<ResetIcon />}
          style={menuItemStyle}
          onClick={handleReset}
        >
          Reset
        </MenuItem>
        <MenuItem
          iconLeft={<RemoveCircleOutlineIcon />}
          style={menuItemStyle}
          onClick={handleDelete}
        >
          Delete Strategy
        </MenuItem>
        <MenuDivider />
        <MenuItem
          iconLeft={<ArrowForwardIosOutlinedIcon />}
          style={menuItemStyle}
          onClick={() => {
            if (redirectURL) {
              push(redirectURL);
            }
          }}
        >
          Go to Floor Plan
        </MenuItem>
      </Menu>

      <Dialog.Sm
        open={modalOpen}
        title="AutoMagic"
        onSave={onAutoMagicProceed}
        onClose={closeModal}
        loading={autoMagicLoading}
        submitButtonText="Proceed"
      >
        This action will replan the entire building with regions of focus desks.
        You can make manual edits to the regions afterwards.
      </Dialog.Sm>
      <Dialog.Sm
        open={renameModalOpen}
        title="Name Strategy"
        onSave={updateStrategyName}
        onClose={closeRenameModal}
        submitButtonText="Rename"
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            updateStrategyName();
          }
        }}
      >
        <TextField
          autoFocus
          onChange={(e) => {
            setStrategyName(e.target.value);
          }}
          value={strategyName}
          style={{ width: "100%", height: 40 }}
        />
      </Dialog.Sm>

      <Dialog.Sm
        open={duplicateModalOpen}
        onSave={handleDuplicate}
        title="Duplicate Strategy"
        loading={duplicateLoading}
        onClose={() => setDuplicateModalOpen(false)}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            handleDuplicate();
          }
        }}
      >
        <TextField
          autoFocus
          onChange={(e) => {
            setDuplicateStrategyName(e.target.value);
          }}
          value={duplicateStrategyName}
          style={{ width: "100%", height: 40 }}
        />
      </Dialog.Sm>
    </React.Fragment>
  );
};

// Input for letting users set the "efficiency" value for the building
// a few other metrics get recalibrated based on the "efficiency" value
const EfficiencyInputCell: React.FC<{
  row: TableRowMetric;
}> = ({ row }) => {
  const [value, setValue] = useState<number | undefined>(row.efficiencyRatio);
  const [loading, setLoading] = useState<boolean>(false);
  const backgroundColor = { backgroundColor: "transparent" };
  const classes = useStyles(backgroundColor);
  const { project } = useProjectCtrl();
  const { isResetting } = useTableCtrl();
  const { updateBuildingMetrics } = useSettingsCtrl();

  // Reset value on metrics reset
  useEffect(() => {
    if (isResetting) {
      setValue(0.85);
    }
  }, [isResetting]);

  if (!project) return null;

  const handleBlur = async (e: any) => {
    setLoading(true);
    e.persist();
    let efficiencyRatio = fromPercent(parseFloat(e.target.value));
    if (isNaN(efficiencyRatio)) efficiencyRatio = 0;
    setValue(efficiencyRatio);
    const usableFloorArea = row.totalFloorArea * efficiencyRatio;
    await updateBuildingMetrics(
      { efficiencyRatio, usableFloorArea },
      row.buildingID,
      row.strategyID
    );
    setLoading(false);
  };

  const handleChange = (e: any) => {
    const efficiencyRatio = fromPercent(parseFloat(e.target.value));
    if (isNaN(efficiencyRatio)) setValue(undefined);
    else setValue(efficiencyRatio);
  };

  return (
    <>
      {loading ? (
        <div className={classes.efficiencyLoading}>
          <Loading />
        </div>
      ) : (
        <InputBase
          type={"tel"}
          inputProps={{
            min: 0,
            max: 100,
          }}
          onClick={(e) => e.stopPropagation()}
          className={classes.inputCell}
          value={value ? toPercent(value) : ""}
          endAdornment={"%"}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      )}
    </>
  );
};

// generate cells, pass in data from cellSetProps
export default function CellSet(props: CellSetProps) {
  const { projectID } = useParams<{ [key: string]: string }>();
  const { row, isPrimary, isHeader, collapsedRow, editable, redirectURL } =
    props;
  const backgroundColor = { backgroundColor: "transparent" };
  const classes = useStyles(backgroundColor);
  const { push } = useHistory();

  const redirectToUrl = () => {
    if (redirectURL) {
      push(redirectURL);
    }
  };

  // used to rectify the mismatched data structures between cells.tsx and head.tsx
  const cellIdConversion: {
    id: string;
    row?: string | number;
    value?: number | string;
    baseline?: number | string;
    cellType: string;
  }[] = [
    { id: "buildingID", row: row.buildingID, cellType: "D" },
    { id: "floorCount", row: row.floorCount, cellType: "A" },
    { id: "totalFloorArea", row: row.displayFloorArea, cellType: "A" },
    { id: "efficiencyRatio", row: row.displayERatio, cellType: "B" },
    { id: "usableFloorArea", row: row.displayUsableFloorArea, cellType: "A" },
    {
      id: "assignableSeats",
      value: row.assignableSeats,
      baseline: row.baselineSeatCount,
      cellType: "C",
    },
    {
      id: "seatCount",
      value: row.seatCount,
      baseline: row.baselineSeatCount,
      cellType: "C",
    },
    { id: "convertedSeats", row: row.convertedSeats, cellType: "A" },
    {
      id: "density",
      value: Math.round(row.usableFloorArea / row.assignableSeats),
      baseline: Math.round(row.usableFloorArea / row.baselineSeatCount),
      cellType: "C",
    },
    { id: "potentialSeats", row: row.potentialSeats, cellType: "A" },
    { id: "workpoints", row: row.workpoints, cellType: "A" },
    {
      id: "conferenceRoomSeats",
      value: row.conferenceRoomSeats,
      baseline: row.displayBaselineConferenceCapacity,
      cellType: "C",
    },
    {
      id: "conferenceRoomSeatsPerHC",
      value: row.displayConferenceSeatsPerHC,
      baseline: row.baselineConferenceRoomsSeatsPerHC,
      cellType: "C",
    },
    {
      id: "displayDeskToDoor",
      value: row.displayDeskToDoor,
      baseline: row.baselineSeatCount / row.baselineConferenceRoomsCount,
      cellType: "C",
    },
    {
      id: "displayDeskToDoorWithNooks",
      value: row.displayDeskToDoorWithNooks,
      baseline: row.baselineSeatCount / row.baselineConferenceRoomsCount,
      cellType: "C",
    },
    { id: "totalCost", row: row.displayTotalCost, cellType: "A" },
    {
      id: "costUnit",
      row: row.displayCostUnit,
      cellType: "A",
    },
    { id: "costGSF", row: row.displayCostGSF, cellType: "A" },
    {
      id: "costHC",
      row:
        row.assetHC !== 0 ? truncateNumber(row.totalCost / row.assetHC) : "N/A",
      cellType: "A",
    },
    { id: "nooksCount", value: row.nooksCount, baseline: 0, cellType: "C" },
    {
      id: "phones",
      value: row.phones,
      baseline: row.baselinePhoneCount,
      cellType: "C",
    },
    {
      id: "doors",
      value: row.doors - row.convertedMeetingRooms,
      baseline: row.baselineConferenceRoomsCount,
      cellType: "C",
    },
  ];

  function SpaceRender() {
    const column = Columns.useContainer();
    return (
      <>
        {column.spaces.map((el) => {
          return (
            <Cell classes={classes.cell} key={el}>
              {""}
            </Cell>
          );
        })}
      </>
    );
  }

  function PermanentCellRender() {
    return (
      <>
        {permanentHeaders.map((currHeader, index) => {
          return generateCells(currHeader, index);
        })}
      </>
    );
  }

  function PopulatedCellRender() {
    return (
      <>
        {populatedHeaders.map((currHeader, index) => {
          return generateCells(currHeader, index);
        })}
      </>
    );
  }

  //function to populate cells for the map
  function generateCells(header: any, index: number) {
    const tempArr = [];
    for (let i = 0; i < cellIdConversion.length; i++) {
      if (header.id === cellIdConversion[i].id) {
        tempArr.push(cellIdConversion[i]);
      }
    }
    for (let j = 0; j < tempArr.length; j++) {
      const rowTag = tempArr[j].row;
      const valueTag = tempArr[j].value;
      const baselineTag = tempArr[j].baseline;

      // switch case to set type of cell, four basic cell types to choose from
      switch (tempArr[j].cellType) {
        case "A": {
          return (
            <Cell onClick={redirectToUrl} classes={classes.cell} key={index}>
              {rowTag}
            </Cell>
          );
        }
        case "B": {
          return (
            <Cell onClick={redirectToUrl} classes={classes.cell} key={index}>
              {editable && <EfficiencyInputCell row={row} />}
              {!editable && row.displayERatio}
            </Cell>
          );
        }
        case "C": {
          return (
            <Cell onClick={redirectToUrl} classes={classes.cell} key={index}>
              <ChangeCell
                baseline={baselineTag as number}
                value={valueTag as number}
              />
            </Cell>
          );
        }
        case "D": {
          return (
            <Cell
              onClick={redirectToUrl}
              classes={
                collapsedRow ? classes.nameCellCollapsed : classes.nameCell
              }
              key={index}
              data-testid={
                isHeader ? "building-name-column" : "strategy-name-column"
              }
            >
              <div className={classes.nameCellContainer}>
                <div>{isHeader ? row.buildingID : row.displayName}</div>
              </div>
            </Cell>
          );
        }
      }
    }
  }

  return (
    <React.Fragment>
      {isHeader ? (
        ""
      ) : (
        <PinCell
          isPrimary={!!isPrimary}
          projectID={projectID}
          buildingID={row.buildingID}
          strategyID={row.strategyID}
        />
      )}
      <PermanentCellRender />
      <PopulatedCellRender />
      <SpaceRender />
      <Cell classes={classes.cell}>{""}</Cell>
      <Cell classes={classes.cell}>{""}</Cell>
      <Cell classes={classes.lastCell}>
        {!isHeader && (
          <MenuCell
            redirectURL={redirectURL}
            isPrimary={!!isPrimary}
            projectID={projectID}
            buildingID={row.buildingID}
            strategyID={row.strategyID}
            displayName={row.displayName}
          />
        )}
      </Cell>
    </React.Fragment>
  );
}
