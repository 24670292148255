import React from "react";
import { makeStyles, styled } from "@material-ui/core/styles";
import { AccordionProps } from "@material-ui/core/Accordion";
import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary,
} from "../components/sideBar/AccordionComponents";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloseIcon from "@material-ui/icons/Close";
import { RendererMode, useFeaturesCtrl, useRendererCtrl } from "lib/containers";
import Buzz from "components/controls/buzz";
import DaylightMetric from "components/controls/daylightMetric";
import MeetingRoomMetrics from "../components/controls/meetingRoomMetrics";
import { useParams } from "react-router-dom";
import Walkability from "components/controls/walkability";

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {};

const useStyles = makeStyles({
  accordianSummaryTitle: {
    fontSize: "16.57px",
    lineHeight: "28px",
    letterSpacing: "0.5px",
    color: "#00000070",
    fontFamily: "GoogleSansRegular",
  },
});

const Accordion = styled(MuiAccordion)({
  flex: (props: AccordionProps) => (props?.expanded ? 1 : 0),
});

const SidebarAnalytics: React.FC<Props> = () => {
  const classes = useStyles();
  const { currentFeatures } = useFeaturesCtrl();
  const { rendererMode, setRendererMode } = useRendererCtrl();
  const { buildingID } = useParams<{ [key: string]: string }>();

  const hasDaylight = React.useMemo(
    () =>
      currentFeatures &&
      currentFeatures.walls &&
      currentFeatures.walls.length > 0 &&
      currentFeatures.glazingExterior &&
      currentFeatures.glazingExterior.length > 0,
    [currentFeatures]
  );

  const handleClose = () => {
    setRendererMode(RendererMode.Select);
  };

  if (
    rendererMode !== RendererMode.BuzzMetric &&
    rendererMode !== RendererMode.DaylightMetric &&
    rendererMode !== RendererMode.MeetingRoomMetric &&
    rendererMode !== RendererMode.DeleteCirculation &&
    rendererMode !== RendererMode.DrawCirculation &&
    rendererMode !== RendererMode.EditCirculation &&
    rendererMode !== RendererMode.WalkabilityMetric &&
    rendererMode !== RendererMode.DrawWalkabilityCirculation &&
    rendererMode !== RendererMode.DeleteWalkabilityCirculation &&
    rendererMode !== RendererMode.EditWalkabilityCirculation &&
    rendererMode !== RendererMode.WalkabilityOutput
  ) {
    return null;
  }

  return (
    <>
      <AccordionSummary expandIcon={<CloseIcon />} onClick={handleClose}>
        Analytics
      </AccordionSummary>
      <div
        style={{
          height: "calc(100% - 56px)",
        }}
      >
        <Accordion
          expanded={
            rendererMode === RendererMode.DrawCirculation ||
            rendererMode === RendererMode.DeleteCirculation ||
            rendererMode === RendererMode.EditCirculation ||
            rendererMode === RendererMode.BuzzMetric
          }
          onChange={() => {
            setRendererMode(RendererMode.BuzzMetric);
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="buzz"
            id="buzz"
            className={classes.accordianSummaryTitle}
          >
            Buzz
          </AccordionSummary>
          <AccordionDetails>
            <Buzz />
          </AccordionDetails>
        </Accordion>
        {hasDaylight && (
          <Accordion
            expanded={rendererMode === RendererMode.DaylightMetric}
            onChange={() => {
              setRendererMode(RendererMode.DaylightMetric);
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="daylightMetric"
              id="daylightMetric"
              className={classes.accordianSummaryTitle}
            >
              Daylight Metric
            </AccordionSummary>
            <AccordionDetails>
              <DaylightMetric />
            </AccordionDetails>
          </Accordion>
        )}
        {buildingID === "US-MTV-40" && (
          <Accordion
            expanded={rendererMode === RendererMode.MeetingRoomMetric}
            onChange={() => {
              setRendererMode(RendererMode.MeetingRoomMetric);
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="meetingRooms"
              id="meetingRooms"
              className={classes.accordianSummaryTitle}
            >
              Meeting Room Feedback
            </AccordionSummary>
            <AccordionDetails>
              <MeetingRoomMetrics />
            </AccordionDetails>
          </Accordion>
        )}
        <Accordion
          expanded={
            rendererMode === RendererMode.WalkabilityMetric ||
            rendererMode === RendererMode.DrawWalkabilityCirculation ||
            rendererMode === RendererMode.DeleteWalkabilityCirculation ||
            rendererMode === RendererMode.EditWalkabilityCirculation
          }
          onChange={() => {
            setRendererMode(RendererMode.WalkabilityMetric);
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="walkabilityMetric"
            id="walkabilityMetric"
            className={classes.accordianSummaryTitle}
          >
            Walkability
          </AccordionSummary>
        </Accordion>
        {(rendererMode === RendererMode.WalkabilityMetric ||
          rendererMode === RendererMode.DrawWalkabilityCirculation ||
          rendererMode === RendererMode.DeleteWalkabilityCirculation ||
          rendererMode === RendererMode.EditWalkabilityCirculation ||
          rendererMode === RendererMode.WalkabilityOutput) && <Walkability />}
      </div>
    </>
  );
};

export default SidebarAnalytics;
