import React, { useState } from "react";
import { useParams } from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";

import { Project, RendererSettings } from "lib/types";
import {
  findBuildingMetrics,
  makeRendererMetrics,
} from "lib/metrics/renderer-metrics";
import {
  RendererMode,
  useBlocksCtrl,
  useMatchMakerCtrl,
  useRendererCtrl,
} from "lib/containers";
import { encodeMetricId } from "lib/metrics/id";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../components/sideBar/AccordionComponents";

import { Tab } from "@outerlabs/ol-ui/dist/components/Tab";
import { ModernTabs } from "@outerlabs/ol-ui/dist/components/Tabs";

import ToolsPanel, { ToolsProps } from "../components/sideBar/ToolsPanel";
import FloorAndBuildingMetricsTable from "components/metrics/floorAndBuildingMetricsTable";
import ZoneMetricsTable from "components/metrics/zoneMetricsTable";
import { ReactComponent as BlueprintIcon } from "assets/icons/BlueprintButton.svg";
import LinkIcon from "@material-ui/icons/OpenInNew";

import SidebarNoSelection from "./sidebar-noselection";
import SidebarRegions from "./sidebar-selectedregions";
import SidebarRooms from "./sidebar-selectedrooms";
import SidebarHeader from "./sidebar-header";
import { Check, Settings, Visibility, VisibilityOff } from "@material-ui/icons";
import { Box, Button, IconButton, Modal } from "@material-ui/core";
import { Block } from "blocks/lib/types";
import { getTotalBlockArea } from "lib/metrics/wpi-metric-helpers";
import { Type } from "@outerlabs/ol-ui";

export interface Props {
  project: Project;
  onChange(name: string, value: any): void;
  tools: ToolsProps;
  settings: RendererSettings | undefined;
  selectedTab: string;
  setSelectedTab: any;
}

const useStyles = makeStyles({
  blockInfoHeader: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    padding: "0px 16px",
  },
  accordionPanelTitleMoreOrLessContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    fontFamily: "GoogleSansRegular",
    fontSize: "15.5px",
  },
  accordionPanelTitleMoreOrLessTitle: {
    color: "#2F73FF",
    fontWeight: "bold",
    fontFamily: "GoogleSansBold",
    fontSize: 14,
    lineHeight: "26px",
  },
  toggle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  toggleText: {
    fontSize: "12px",
    fontWeight: 400,
  },
  menu: {
    alignContent: "center",
    width: "250px",
    background: "white",
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    position: "relative",
    left: 460,
    top: 110,
  },
  menuHeader: {
    display: "flex",
    margin: "0px",
    padding: "16px 12px 16px 16px",
    color: "#919499",
    textTransform: "uppercase",
    fontWeight: 700,
    fontSize: "12px",
    lineHeight: "16px",
    flexDirection: "row",
    alignItems: "flex-start",
    gap: "10px",
    letterSpacing: "1.5px",
  },
  buttonBox: {
    borderBottom: "2px #C1C4C9 solid",
    display: "flex",
    justifyContent: "space-around",
    alignContent: "left",
    paddingTop: "4px",
    paddingBottom: "12px",
  },
  buttonActive: {
    background: "#2F73FF",
    borderRadius: "18px",
    color: "white",
    padding: "8px",
    textTransform: "none",
    fontSize: "12px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    gap: "8px",
    width: "100px",
  },
  button: {
    background: "#F0F5FF",
    borderRadius: "18px",
    color: "black",
    padding: "8px",
    textTransform: "none",
    fontSize: "12px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    gap: "8px",
    width: "100px",
  },
  menuSubHeader: {
    display: "flex",
    margin: "0px",
    padding: "4px 0px 0px 12px",
    color: "#5F6166",
    fontSize: "12px",
  },
  menuItem: {
    width: "100%",
    padding: "12px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "left",
    textTransform: "capitalize",
  },
  menuItemText: { margin: 0, paddingLeft: "12px" },
  tabsAndToggle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 13,
  },
});

export type WpiSidebarTabValue = "program" | "activities";

const SidebarBaseMode: React.FC<Props> = ({
  project,
  onChange,
  tools,
  settings,
  selectedTab,
  setSelectedTab,
}) => {
  const { buildingID, strategyID, floorID } = useParams<{
    [key: string]: string;
  }>();
  const classes = useStyles();
  const [expanded, setExpanded] = useState("");
  const { selectedRegion, rendererMode, wpiActivitiesMode } = useRendererCtrl();
  const { matchMakerActive } = useMatchMakerCtrl();
  const { getBlockById } = useBlocksCtrl();
  const id = encodeMetricId(buildingID, strategyID, floorID);
  const metric = project.metrics[id];
  const buildingMetrics = findBuildingMetrics(project, buildingID, strategyID);
  const reducedBuildingMetrics = makeRendererMetrics(buildingMetrics);
  const floorMetricsSubset = makeRendererMetrics([metric]);

  // gets first selected block
  const instance =
    selectedRegion !== undefined && settings && settings.blocks
      ? settings.blocks[selectedRegion[0]]
      : undefined;

  // gets all selected blocks
  const instances: Block[][] = [];
  if (selectedRegion && settings && settings.blocks) {
    selectedRegion?.forEach((i) => {
      instances.push(settings.blocks[i]);
    });
  }

  const hasSelectedRegion = (selectedRegion || [])?.length > 0;

  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [activeMetric, setActiveMetric] = React.useState<string>("building");
  const [selectedArr, setSelectedArr] = React.useState<string[]>([
    "totalSeats",
    "density",
    "deskToDoorWithoutNooks",
  ]);

  const [totalSeatsVisible, setTotalSeatsVisible] =
    React.useState<boolean>(true);
  const [densityVisible, setDensityVisible] = React.useState<boolean>(true);
  const [deskToDoorVisible, setDeskToDoorVisible] =
    React.useState<boolean>(true);
  const [capacityVisible, setCapacityVisible] = React.useState<boolean>(false);
  const [assignableVisible, setAssignableVisible] =
    React.useState<boolean>(false);

  const handleTabChange = (e: EventTarget, newValue: string) => {
    setSelectedTab(newValue);
  };

  const block = instance ? getBlockById(instance[0].symbol) : undefined;
  let url = "";
  let name = "";
  let headcount = 0;
  if (block) {
    url =
      block.props.definition && block.props.definition.url
        ? block.props.definition.url
        : "";
    name =
      block.props.definition && block.props.definition.name
        ? block.props.definition.name
        : "";
    // eslint-disable-next-line
    headcount =
      block.props.metrics && block.props.metrics.headcount
        ? block.props.metrics.headcount
        : 0;
  }

  if (
    rendererMode === RendererMode.Draw ||
    rendererMode === RendererMode.BuzzMetric ||
    rendererMode === RendererMode.DaylightMetric ||
    rendererMode === RendererMode.MeetingRoomMetric ||
    rendererMode === RendererMode.DrawCirculation ||
    rendererMode === RendererMode.DeleteCirculation ||
    rendererMode === RendererMode.EditCirculation ||
    matchMakerActive ||
    rendererMode === RendererMode.WalkabilityMetric ||
    rendererMode === RendererMode.DrawWalkabilityCirculation ||
    rendererMode === RendererMode.DeleteWalkabilityCirculation ||
    rendererMode === RendererMode.EditWalkabilityCirculation ||
    rendererMode === RendererMode.WalkabilityOutput
  ) {
    return null;
  }

  const selectedParse = (item: string) => {
    const temp = [...selectedArr];
    if (temp.includes(item)) {
      const index = temp.indexOf(item);
      temp.splice(index, 1);
    } else {
      temp.push(item);
    }
    setSelectedArr(temp);
  };

  const onClickFunc = () => {
    setOpenModal(true);
  };

  const handleIconRender = (
    key: boolean,
    value: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    value(!key);
  };

  const BuildingFloorModal = (
    <Modal
      keepMounted={true}
      open={openModal}
      onClose={() => {
        setOpenModal(false);
      }}
      disableBackdropClick={false}
      closeAfterTransition={false}
      BackdropProps={{
        timeout: 500,
        invisible: true,
      }}
    >
      <Box className={classes.menu}>
        <div className={classes.menuHeader}>Performance Metrics</div>
        <Box className={classes.buttonBox}>
          <Button
            onClick={() => setActiveMetric("building")}
            className={
              activeMetric === "building"
                ? classes.buttonActive
                : classes.button
            }
            size={"small"}
          >
            <Check /> Building
          </Button>
          <Button
            onClick={() => setActiveMetric("floor")}
            className={
              activeMetric === "floor" ? classes.buttonActive : classes.button
            }
            size={"small"}
          >
            <Check />
            Floor
          </Button>
        </Box>
        <div className={classes.menuSubHeader}>Select up to 3 metrics</div>
        <Box display="flex" flexDirection="column" alignItems="baseline">
          <Button
            onClick={() => {
              selectedParse("totalSeats");
              handleIconRender(totalSeatsVisible, setTotalSeatsVisible);
            }}
            disabled={
              selectedArr.length === 3 && !selectedArr.includes("totalSeats")
            }
            className={classes.menuItem}
          >
            {selectedArr.includes("totalSeats") ? (
              <Visibility />
            ) : (
              <VisibilityOff />
            )}{" "}
            <div className={classes.menuItemText}>Total Seats</div>
          </Button>
          <Button
            onClick={() => {
              selectedParse("density");
              handleIconRender(densityVisible, setDensityVisible);
            }}
            disabled={
              selectedArr.length === 3 && !selectedArr.includes("density")
            }
            className={classes.menuItem}
          >
            {selectedArr.includes("density") ? (
              <Visibility />
            ) : (
              <VisibilityOff />
            )}{" "}
            <div className={classes.menuItemText}>Density</div>
          </Button>
          <Button
            onClick={() => {
              selectedParse("deskToDoorWithoutNooks");
              handleIconRender(deskToDoorVisible, setDeskToDoorVisible);
            }}
            disabled={
              selectedArr.length === 3 &&
              !selectedArr.includes("deskToDoorWithoutNooks")
            }
            className={classes.menuItem}
          >
            {selectedArr.includes("deskToDoorWithoutNooks") ? (
              <Visibility />
            ) : (
              <VisibilityOff />
            )}{" "}
            <div className={classes.menuItemText}>Desk To Door</div>
          </Button>
          <Button
            onClick={() => {
              selectedParse("deskToDoorWithNooks");
              handleIconRender(deskToDoorVisible, setDeskToDoorVisible);
            }}
            disabled={
              selectedArr.length === 3 &&
              !selectedArr.includes("deskToDoorWithNooks")
            }
            className={classes.menuItem}
          >
            {selectedArr.includes("deskToDoorWithNooks") ? (
              <Visibility />
            ) : (
              <VisibilityOff />
            )}{" "}
            <div className={classes.menuItemText}>
              Desk To Door (Incl. Nooks)
            </div>
          </Button>
          <Button
            onClick={() => {
              selectedParse("capacity");
              handleIconRender(capacityVisible, setCapacityVisible);
            }}
            disabled={
              selectedArr.length === 3 && !selectedArr.includes("capacity")
            }
            className={classes.menuItem}
          >
            {selectedArr.includes("capacity") ? (
              <Visibility />
            ) : (
              <VisibilityOff />
            )}{" "}
            <div className={classes.menuItemText}>Total Capacity</div>
          </Button>
          <Button
            onClick={() => {
              selectedParse("assignableSeats");
              handleIconRender(assignableVisible, setAssignableVisible);
            }}
            disabled={
              selectedArr.length === 3 &&
              !selectedArr.includes("assignableSeats")
            }
            className={classes.menuItem}
          >
            {selectedArr.includes("assignableSeats") ? (
              <Visibility />
            ) : (
              <VisibilityOff />
            )}{" "}
            <div className={classes.menuItemText}>Assignable Seats</div>
          </Button>
        </Box>
      </Box>
    </Modal>
  );

  const BuildingFloorToggle = (
    <div className={classes.toggle}>
      <div className={classes.toggleText}>
        {activeMetric === "building" ? "Building" : "Floor"}
      </div>
      <IconButton onClick={onClickFunc}>
        <Settings />
      </IconButton>
      {BuildingFloorModal}
    </div>
  );

  // Note: this is the default styling for an accordion header from olui
  const selectedTitle = (
    <div style={{ maxWidth: 270 }}>
      <Type.Subtitle1>
        {selectedRegion
          ? selectedRegion.length === 1
            ? name
            : selectedRegion?.length + " Blocks Selected"
          : ""}
      </Type.Subtitle1>
    </div>
  );

  return (
    <>
      <div className={classes.tabsAndToggle}>
        <ModernTabs
          value={selectedTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange as any}
          style={{ paddingLeft: 7 }}
        >
          <Tab format="modern" label="Program" value="program" />
          <Tab format="modern" label="Activities" value="activities" />
        </ModernTabs>

        {hasSelectedRegion ? " " : BuildingFloorToggle}
      </div>

      <SidebarHeader
        project={project}
        settings={settings}
        floorMetrics={floorMetricsSubset}
        buildingMetrics={reducedBuildingMetrics}
        selectedArr={selectedArr}
        activeMetric={activeMetric}
      />

      <React.Fragment>
        {!wpiActivitiesMode && selectedRegion ? (
          <div className={classes.blockInfoHeader}>
            {selectedTitle}
            <div style={{ color: "#FFBAA3", marginLeft: 20 }}>
              {Math.round(getTotalBlockArea(instances))} SF
            </div>
          </div>
        ) : (
          ""
        )}
        {(wpiActivitiesMode || (!wpiActivitiesMode && !selectedRegion)) && (
          <Accordion
            expanded={expanded === "metrics"}
            onChange={() =>
              expanded === "metrics" ? setExpanded("") : setExpanded("metrics")
            }
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ stroke: "#2F73FF" }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ borderTop: 0 }}
            >
              <div className={classes.accordionPanelTitleMoreOrLessContainer}>
                <div
                  style={{
                    flexGrow: 1,
                    display: "flex",
                    fontSize: 16,
                    alignItems: "center",
                  }}
                >
                  {selectedTitle}
                  <div
                    style={{ display: "flex", cursor: "pointer" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (url && url !== "") {
                        window.open(url, "_blank");
                      }
                    }}
                  >
                    {url && url !== "" ? (
                      url.match(/blueprint\.site/) ? (
                        <BlueprintIcon
                          style={{
                            height: 20,
                            width: 20,
                            marginLeft: 12,
                            marginRight: 12,
                          }}
                        />
                      ) : (
                        <div style={{ padding: 6 }}>
                          <LinkIcon
                            style={{
                              height: 20,
                              width: 20,
                              marginLeft: 12,
                              marginRight: 12,
                            }}
                          />
                        </div>
                      )
                    ) : undefined}{" "}
                  </div>
                </div>
                <div className={classes.accordionPanelTitleMoreOrLessTitle}>
                  Details
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              {selectedRegion === undefined ? (
                <FloorAndBuildingMetricsTable
                  floorMetrics={floorMetricsSubset}
                  buildingMetrics={reducedBuildingMetrics}
                />
              ) : (
                <ZoneMetricsTable settings={settings} />
              )}
            </AccordionDetails>
          </Accordion>
        )}

        <SidebarRegions settings={settings} />
        <SidebarRooms project={project} settings={settings} />
        <SidebarNoSelection
          project={project}
          onChange={onChange}
          settings={settings}
        />
        <ToolsPanel {...tools} />
      </React.Fragment>
    </>
  );
};

export default SidebarBaseMode;
