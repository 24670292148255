import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useDropzone } from "react-dropzone";
import { ParsedDXFFile } from "../types";
import BuildingLayers from "../layers";
import CircularProgress from "@material-ui/core/CircularProgress";
import FileList from "../components/FileList";
import { parseDXFFiles } from "../lib/dxf";

interface DXFImportProps {
  files: Record<string, ParsedDXFFile>;
  updateAll: (next: Record<string, ParsedDXFFile>) => void;
  addFiles: (files: File[]) => void;
  removeFile: (name: string) => void;
  ready: boolean;
  setReady: (b: boolean) => void;
}

const useStyles = makeStyles({
  container: {
    display: "flex",
    flex: 1,
  },
  uploader: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  fileList: {
    width: "100%",
  },
  leftSide: {
    display: "flex",
    flex: 1,
  },
  rightSide: {
    display: "flex",
    flexDirection: "column",
    flex: 2,
  },
  dropzoneText: {
    fontSize: 24,
    color: "#919499",
    fontWeight: 500,
    fontFamily: "Google Sans",
  },
  dropzone: {
    padding: "1.5rem",
    margin: "0 0 0.75rem 0",
    minWidth: "508px",
    minHeight: "100px",
    display: "flex",
    alignItems: "center",
    background:
      "linear-gradient(to right, #d4d4d4 40%, #fff 0%) top/20px 2px repeat-x,linear-gradient(#d4d4d4 40%, #fff 0%) right/2px 20px repeat-y,linear-gradient(to right, #d4d4d4 40%, #fff 0%) bottom/20px 2px repeat-x,linear-gradient(#d4d4d4 40%, #fff 0%) left/2px 20px repeat-y",
    justifyContent: "center",
    "&:hover": {
      background:
        "linear-gradient(to right, #999999 40%, #fff 0%) top/20px 2px repeat-x,linear-gradient(#999999 40%, #fff 0%) right/2px 20px repeat-y,linear-gradient(to right, #999999 40%, #fff 0%) bottom/20px 2px repeat-x,linear-gradient(#999999 40%, #fff 0%) left/2px 20px repeat-y",
      cursor: "pointer",
    },
  },
  dropZoneTall: {
    minHeight: "210px",
  },
  head: {
    transform: "translateY(-40px)",
    color: "#5F6166",
  },
  heading: {
    fontFamily: "Google Sans",
    fontSize: 20,
    fontWeight: 500,
  },
  subheading: {
    fontFamily: "Google Sans",
    fontSize: 14,
  },
});

const DndUploader: React.FC<DXFImportProps & { tall?: boolean }> = ({
  addFiles,
  tall,
}) => {
  const onDrop = (acceptedFiles: File[]) => {
    addFiles(acceptedFiles);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    // Disable click and keydown behavior
    noKeyboard: true,
  });

  const classes = useStyles({ isDragActive });

  return (
    <div className={classes.uploader}>
      <div {...getRootProps()}>
        <input {...getInputProps()} />

        <div
          className={`${classes.dropzone} ${tall ? classes.dropZoneTall : ""}`}
        >
          <Typography
            variant="body1"
            className={classes.dropzoneText}
            style={{ margin: ".5rem 0 .25rem 0" }}
          >
            Drag & Drop your .dxf files here
          </Typography>
        </div>
      </div>
    </div>
  );
};

// StepImport is the second step in the import process. User uploads one or many
// .dxf files in this step. The app does some basic file validation here.
const StepImport: React.FC<DXFImportProps> = ({
  files,
  addFiles,
  removeFile,
  updateAll,
  ready,
  setReady,
}) => {
  const classes = useStyles({});
  const [isLoading, setLoading] = useState(true);

  /*eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (Object.values(files).length) {
      const parser = async () => {
        const rawFiles = Object.values(files).map((v) => v.rawFile);

        const { files: parsedFiles, errors } = await parseDXFFiles(rawFiles);

        const next = Object.assign(files);
        for (const f of parsedFiles) {
          next[`${f.name}.dxf`].parsed = f;
        }

        for (const e of errors) {
          next[e.file].error = e;
        }
        updateAll(next);
      };

      parser()
        .then(() => setLoading(false))
        .catch((e) => {
          console.error(e);
          setLoading(false);
        });
    } else {
      if (ready) setReady(false);
    }
  }, [files]);
  return (
    <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
      <div className={classes.head}>
        <Typography variant="h6" className={classes.heading}>
          Import Project Files
        </Typography>
        <Typography variant="body2" className={classes.subheading}>
          Please upload formatted .dxf files.
        </Typography>
      </div>
      <div className={classes.container}>
        <div className={classes.leftSide}>
          <BuildingLayers />
        </div>
        <div className={classes.rightSide}>
          {files &&
            Object.keys(files).length > 0 &&
            (isLoading ? (
              <CircularProgress />
            ) : (
              <FileList
                files={files}
                removeFile={removeFile}
                updateAll={updateAll}
                ready={ready}
                setReady={setReady}
              />
            ))}
          <DndUploader
            ready={ready}
            setReady={setReady}
            tall={files && Object.keys(files).length === 0}
            files={files}
            addFiles={addFiles}
            updateAll={updateAll}
            removeFile={removeFile}
          />
        </div>
      </div>
    </div>
  );
};

export default StepImport;
