import React, { useEffect } from "react";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import Autocomplete, { Option } from "./AutoComplete";

const addressOpt = {
  debounce: 300,
  requestOptions: {
    componentRestrictions: { country: ["us", "ca"] },
    fields: ["address_components", "geometry"],
    types: ["address"],
  },
};

interface AddressSearchProps {
  name: string;
  placeholder?: string;
  label: string;
  value?: string;
  onChange: (address: string, latlng: { lat: number; lng: number }) => void;
  className?: string;
  error?: boolean;
}

const defaultLatLng = {
  lat: 43.4133299,
  lng: -122.1163721,
};

const Component = (props: AddressSearchProps) => {
  const {
    value: searchValue,
    suggestions: { data },
    setValue: setSearchValue,
    clearSuggestions,
  } = usePlacesAutocomplete({ ...addressOpt });

  const ref = useOnclickOutside(() => clearSuggestions());
  const [options, setOptions] = React.useState<Option[]>([]);
  const { value, onChange } = props;

  /*eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (value && !searchValue) {
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      setSearchValue(value, false);
    }
  }, [value]);

  React.useEffect(() => {
    if (data.length === 0) return;
    setOptions(
      data.map((el) => ({
        title:
          el.structured_formatting.main_text +
          ", " +
          el.structured_formatting.secondary_text,
        value: el.place_id,
      }))
    );
  }, [data]);

  return (
    <div ref={ref} style={{ width: "100%" }}>
      <Autocomplete
        placeholder={"Street, City, State, Country"}
        label={"Address"}
        options={options}
        onInput={(_value) => {
          if (_value === "") {
            onChange("", defaultLatLng);
          }
          setSearchValue(_value);
        }}
        value={searchValue}
        onChange={(_value) => {
          setSearchValue(_value.title);
          getGeocode({ address: _value.title }).then((results) => {
            const { lat, lng } = getLatLng(results[0]);
            props.onChange(_value.title, { lat, lng });
          });
        }}
      />
    </div>
  );
};

export default Component;
