import React, { useState, useEffect } from "react";
import Slider from "@material-ui/core/Slider";
import { makeStyles } from "@material-ui/core/styles";

import { convertToFt } from "lib/number";
import { HelpguideHandle, HelpguideWrapperDiv } from "@outerlabs/helpguide";

const useStyles = makeStyles({
  container1: { marginBottom: 10 },
  title: {
    color: "#555555",
    fontFamily: "GoogleSansRegular",
    fontSize: "12px",
    lineHeight: "15px",
  },
  value: {
    color: "#ADADAD",
    fontFamily: "GoogleSansRegular",
    fontSize: "12px",
    lineHeight: "15px",
  },
});

interface Props {
  value: number;
  onChangeCommitted: (
    key: string,
    event: any,
    newValue: number | number[]
  ) => void;
  min: number;
  max: number;
  title: string;
  apiName: string;
  helpguideTooltip?: string;
  disabled?: boolean;
}

const PortfolioSlider: React.FC<Props> = ({
  value: initialValue,
  onChangeCommitted,
  title,
  min,
  max,
  apiName,
  helpguideTooltip = "",
  disabled = false,
}) => {
  const [value, setValue] = useState<number>(initialValue);
  const classes = useStyles();
  const handleChange = (event: any, newValue: number | number[]) => {
    setValue(newValue as number);
  };

  useEffect(() => {
    if (initialValue === 0 || initialValue === 36) setValue(0);
    if (initialValue !== 0 && initialValue !== 36) setValue(initialValue);
  }, [initialValue]);

  return (
    <HelpguideWrapperDiv>
      <div className={classes.container1}>
        <div className={classes.title}>
          {title}
          {helpguideTooltip && (
            <HelpguideHandle isSmall tooltipKey={helpguideTooltip} />
          )}
        </div>
        <Slider
          value={value}
          onChange={handleChange}
          onChangeCommitted={(event, _value) =>
            onChangeCommitted(apiName, event, _value)
          }
          min={min}
          max={max}
          disabled={disabled}
          style={{ paddingTop: 20, paddingBottom: 10 }}
        />
        <div className={classes.value}>{convertToFt(value)}</div>
      </div>
    </HelpguideWrapperDiv>
  );
};

export default PortfolioSlider;
