import React, { useState } from "react";
import { Block } from "blocks/lib/types";
import { ProgramAreaAccordian } from "./ProgramAreaAccordian";
import {
  getBlockAreas,
  getProgramColor,
  getProgramDisplayName,
} from "lib/metrics/wpi-metric-helpers";

export const ProgramAreaContent: React.FC<{
  blocks: Block[][] | undefined;
}> = ({ blocks }) => {
  const [expanded, setExpanded] = useState("");
  // eslint-disable-next-line
  const handleChange = (panel: string) => (event: any, isExpanded: boolean) => {
    setExpanded(expanded === panel ? "" : panel);
  };

  const blockAreas = getBlockAreas(blocks);

  const makeChildren = (areaArr: any, spaceType: string, depth: number) => {
    const areas = areaArr[spaceType] as { [k: string]: any };
    const children = [];

    for (const type in areas as { [k: string]: any }) {
      if (type !== "total" && areas[type].total !== 0) {
        children.push(
          <ProgramAreaAccordian
            expanded={true}
            color={getProgramColor(type)}
            programCategory={getProgramDisplayName(type)}
            area={areas[type].total ? areas[type].total : areas[type]}
            children={makeChildren(areas, type, depth + 1)}
            depth={depth}
          />
        );
      }
    }

    return children;
  };

  const programAreaRows = [];

  for (const category in blockAreas) {
    if (blockAreas[category].total !== 0) {
      programAreaRows.push(
        <ProgramAreaAccordian
          expanded={expanded === category}
          color={getProgramColor(category)}
          programCategory={getProgramDisplayName(category)}
          area={blockAreas[category].total}
          children={makeChildren(blockAreas, category, 1)}
          onChange={handleChange(category)}
          depth={0}
        />
      );
    }
  }

  return <>{programAreaRows}</>;
};
