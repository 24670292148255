import { TextField } from "@material-ui/core";
import React from "react";

type NumberFieldProps = {
  endAdornment?: React.ReactNode;
  startAdornment?: React.ReactNode;
  label?: string;
  value: number;
  onChange: (v: number) => void;
  positive?: boolean;
  min?: number;
  max?: number;
  // eslint-disable-next-line no-restricted-globals
  onWheel?: (v: WheelEvent) => void;
  disabled?: boolean;
  hideScroll?: boolean;
};

const NumberField = ({
  label,
  value,
  onChange,
  min,
  max,
  positive = true,
  endAdornment,
  startAdornment,
  disabled,
  hideScroll,
}: NumberFieldProps) => {
  const ref = React.useRef<HTMLInputElement>(null);
  return (
    <TextField
      type={!hideScroll ? "number" : "tel"}
      style={{ background: "#ffffff", borderBottom: 0 }}
      fullWidth={true}
      inputRef={ref}
      disabled={disabled}
      InputProps={{
        disableUnderline: true,
        endAdornment,
        startAdornment,
        style: {
          background: "#F4F4F6",
          borderRadius: "4px",
          padding: "0 8px",
        },
      }}
      label={label}
      value={value}
      inputProps={{ inputMode: "numeric", pattern: "[0-9]+" }}
      onChange={(e) => {
        let _value = parseInt(e.target.value);
        if (!_value) _value = 0;
        if (positive) _value = Math.max(_value, 0);
        if (min) _value = Math.max(_value, min);
        if (max) _value = Math.min(_value, max);
        onChange(_value);
      }}
      onWheel={(e) => {
        if (e.target instanceof HTMLElement) e.target.blur();
      }}
    />
  );
};

export default NumberField;
