import React from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import { DisplayRendererMetric, Project, RendererSettings } from "lib/types";
import Header from "components/summary/header";
import { makeRendererSummary } from "lib/metrics/renderer-metrics";
import { useRendererCtrl } from "lib/containers";
// import ZoneHeader from "components/sideBar/ZoneHeaderRevised";
import ZoneHeader from "components/sideBar/ZoneHeader";
import { Block } from "../blocks/lib/types";
import { Type } from "@outerlabs/ol-ui";
import { Tooltip } from "@material-ui/core";

export interface Props {
  project: Project;
  settings: RendererSettings | undefined;
  floorMetrics: DisplayRendererMetric;
  buildingMetrics: DisplayRendererMetric;
  selectedArr: string[];
  activeMetric: string;
}

const useStyles = makeStyles({
  sideBarHeaderTitle: {
    marginLeft: "15px",
    marginBottom: "15px",
    marginTop: "15px",
    color: "#555555",
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingLeft: 20,
    height: 200,
  },
});

const SidebarHeader: React.FC<Props> = ({
  project,
  settings,
  floorMetrics,
  buildingMetrics,
  selectedArr,
  activeMetric,
}) => {
  const { buildingID, strategyID } = useParams<{ [key: string]: string }>();
  const classes = useStyles();
  const { selectedRegion } = useRendererCtrl();

  const hasSelectedRegion = (selectedRegion || [])?.length > 0;

  const blocks = (selectedRegion || [])
    ?.map((i) =>
      settings && settings.blocks && settings.blocks[i]
        ? settings?.blocks[i][0]
        : undefined
    )
    .filter((el) => el) as Block[];

  return (
    <div className={classes.headerContainer}>
      {hasSelectedRegion ? (
        <ZoneHeader blocks={blocks} />
      ) : (
        <Tooltip
          title={
            <Type.Caption
              style={{ maxWidth: 950, color: "white", fontSize: 10.3 }}
            >
              {" "}
              KPIs are based on the sum of the Blocks on this{" "}
              {activeMetric === "building" ? "building" : "floor"}.
            </Type.Caption>
          }
        >
          <Header
            summary={makeRendererSummary(project, buildingID, strategyID)}
            selected={selectedArr}
            focusFloor={activeMetric}
            floorMetrics={floorMetrics}
            buildingMetrics={buildingMetrics}
          />
        </Tooltip>
      )}
    </div>
  );
};

export default SidebarHeader;
