import { PortfolioBlockInstance } from "lib/types";
import { renderBlockInstance } from "blocks/lib/canvas";
import { Block, BlockGetter } from "blocks/lib/types";
import { getRegionLengths, toRegion } from "./utils";
import generatePortfolioBlockInstances from "./generate-block-instance";
import { mat4 } from "gl-matrix";

export const renderPortfolioBlockInstance = ({
  ctx,
  instances,
  getBlockById,
  wpiActivitiesMode,
  selected,
}: {
  ctx: CanvasRenderingContext2D;
  instances: PortfolioBlockInstance[];
  getBlockById: BlockGetter;
  flipVertical?: boolean;
  flipHorizontal?: boolean;
  wpiActivitiesMode?: boolean;
  selected?: string;
}) => {
  if (!instances || instances.length === 0) return;
  const region = toRegion(instances[0]);
  if (region && region.length > 0) {
    instances.forEach((instance: PortfolioBlockInstance) => {
      renderBlockInstance(
        ctx,
        instance,
        mat4.create(),
        getBlockById,
        false,
        false,
        selected,
        true,
        wpiActivitiesMode
      );
    });
  }
};

export const createBlockImage = ({
  region,
  block,
  getBlockById,
  wpiActivitiesMode,
}: {
  region: number[][];
  block: Block;
  getBlockById: BlockGetter;
  wpiActivitiesMode: boolean;
}): string => {
  const instances = generatePortfolioBlockInstances({
    block,
    region,
    matrix: mat4.create(),
    regionRotation: 0,
    blockRotation: 0,
    getBlockById,
  });
  const [width, height] = getRegionLengths(region);
  const hiddenCanvas = document.createElement("canvas");
  hiddenCanvas.width = width;
  hiddenCanvas.height = height;
  const ctx = hiddenCanvas.getContext("2d");
  if (ctx)
    renderPortfolioBlockInstance({
      ctx,
      instances,
      getBlockById,
      wpiActivitiesMode,
    });
  return hiddenCanvas.toDataURL("image/png");
};
