import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  InputLabel,
  TextField,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import {
  useRendererCtrl,
  useFeaturesCtrl,
  useBuildingsCtrl,
  useProjectCtrl,
  useSettingsCtrl,
} from "lib/containers";
import { Button } from "@outerlabs/ol-ui";
import { getFeatures } from "lib/api/getFeatures";
import { calcDesks } from "lib/metrics/remove-desks";
import { encodeMetricId } from "lib/metrics/id";
import { DaylightModule, daylightUtils } from "@outerlabs/metric-solver";
import { getDesksFromBlocks, PIXEL_RATIO } from "blocks/lib/util";
import { Coordinate } from "lib/types";
import { checkOSForWebgl } from "renderer/isp-canvas-shaders";
import Tooltip from "../tooltip";
import { HelpguideHandleSpan, HelpguideWrapperDiv } from "@outerlabs/helpguide";

const { prepareSolverInput, generateParams, TEXTURE_SCALE } = daylightUtils;

interface Metrics {
  [key: string]: { [key: string]: number };
}

interface DefaultState {
  isInPercents: boolean;
  isAllBuildingMetric: boolean;
  isCalculateDaylightMetric: boolean;
  floorToFloor: number;
  floorMetrics: Metrics;
  buildingMetrics: Metrics;
  calculateButtonDisabled: boolean;
}

const defaultMetrics = {
  numbers: {
    daylightScore: 0,
    noFlyZone: 0,
    primarySidelitZone: 0,
    secondarySidelitZone: 0,
    toplitZone: 0,
    noDaylightZone: 0,
  },
  percents: {
    daylightScore: 0,
    untappedDaylightPotential: 0,
    noFlyZone: 0,
    primarySidelitZone: 0,
    secondarySidelitZone: 0,
    toplitZone: 0,
    noDaylightZone: 0,
  },
};

const initialState: DefaultState = {
  isInPercents: false,
  isAllBuildingMetric: false,
  isCalculateDaylightMetric: false,
  floorToFloor: 15,
  floorMetrics: defaultMetrics,
  buildingMetrics: defaultMetrics,
  calculateButtonDisabled: false,
};

const INPUT_PROPERTIES = new Set<SettableStateKey>([
  "floorToFloor",
  "isAllBuildingMetric",
]);

const reducer = (
  state: DefaultState,
  action: DaylightMetricAction<SettableStateKey>
): DefaultState => {
  switch (action.type) {
    case "set":
      if (state[action.key] === action.value) {
        return state;
      }

      if (INPUT_PROPERTIES.has(action.key)) {
        return {
          ...state,
          [action.key]: action.value,
          calculateButtonDisabled: false,
        };
      }

      return { ...state, [action.key]: action.value };
    case "started-calculating":
      return {
        ...state,
        isCalculateDaylightMetric: true,
        calculateButtonDisabled: true,
      };
    case "finished-calculating":
      return { ...state, isCalculateDaylightMetric: false };
    case "to-default":
      return { ...initialState };
  }
};

type SettableStateKey = keyof Omit<
  DefaultState,
  "calculateButtonDisabled" | "isCalculateDaylightMetric"
>;

interface Action<T extends string> {
  type: T;
}

interface SetAction<K extends SettableStateKey> extends Action<"set"> {
  key: K;
  value: DefaultState[K];
}

type ToDefaultAction = Action<"to-default">;
type StartedCalculatingAction = Action<"started-calculating">;
type FinishedCalculatingAction = Action<"finished-calculating">;
type DaylightMetricAction<K extends SettableStateKey> =
  | SetAction<K>
  | ToDefaultAction
  | StartedCalculatingAction
  | FinishedCalculatingAction;

// The daylighting calculation is pretty slow and locks up the UI.
// This delay allows the UI to settle before we start calculating which is essential for the tooltip fading and
// the button's style changing to be disabled.
const DELAY_BEFORE_CALCULATING_MS = 300;

const useDaylightMetricStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    justifyContent: "center",
    alignItems: "stretch",
  },
  settings: {
    display: "flex",
    flexDirection: "column",
  },
  settingsItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 12,
  },
  textField: {
    width: 64,
  },
  title: {
    margin: "12px 0 12px 0",
    fontSize: 16,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#5F6166",
  },
  calculateButtonContainer: {
    marginBottom: 12,
    width: "40%",
    alignSelf: "end",
  },
  calculateButton: {
    width: "100%",
  },
  bullet: {
    width: "12px",
    height: "12px",
    marginRight: "8px",
    borderRadius: "50%",
  },
  metricTitleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    marginBottom: 6,
  },
  metricTitle: {
    fontFamily: "GoogleSansRegular",
    fontSize: "15.5px",
    marginLeft: 16,
  },
  helpIcon: {
    marginLeft: "0.5rem",
    fontSize: "18px",
  },
  inputLabel: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    marginLeft: 12,
    width: 45,
  },
});

const DaylightMetric: React.FC = () => {
  const styles = useDaylightMetricStyles();
  const { currentFeatures } = useFeaturesCtrl();
  const { buildings } = useBuildingsCtrl();
  const { project } = useProjectCtrl();
  const { setShowDaylightMetric, showDaylightMetric, setDaylightTexture } =
    useRendererCtrl();
  const { buildingID, floorID, strategyID } = useParams<{
    [key: string]: string;
  }>();
  const [state, dispatch] = useReducer(reducer, initialState);

  const setState = useCallback(
    <K extends SettableStateKey>(key: K, value: DefaultState[K]) => {
      dispatch({
        type: "set",
        key,
        value,
      });
    },
    [dispatch]
  );

  const setStateToDefault = useCallback(() => {
    dispatch({ type: "to-default" });
  }, [dispatch]);

  const startedCalculating = useCallback(() => {
    dispatch({ type: "started-calculating" });
  }, [dispatch]);

  const finishedCalculating = useCallback(() => {
    dispatch({ type: "finished-calculating" });
  }, [dispatch]);

  const { currentSettings: settings } = useSettingsCtrl();
  const [metricsContainer, setMetricsContainer] = useState<Metrics[]>([]);
  const [allBuildingDesksCount, setAllBuildingDesksCount] = useState<number>(0);
  const [allBuildingGlazingLength, setAllBuildingGlazingLength] =
    useState<number>(0);
  const [allBuildingDaylightScore, setAllBuildingDaylightScore] =
    useState<number>(0);
  const [
    allBuildingUntappedDaylightPotential,
    setAllBuildingUntappedDaylightPotential,
  ] = useState<number>(0);
  const {
    isInPercents,
    floorToFloor,
    floorMetrics,
    buildingMetrics,
    isAllBuildingMetric,
  } = state;
  const blockDesks = useMemo(
    () =>
      getDesksFromBlocks(settings && settings.blocks ? settings.blocks : []),
    [settings]
  );
  const { AvailableFloors: availableFloors } = buildings[buildingID];
  const scale = 1 / (PIXEL_RATIO * TEXTURE_SCALE);

  const calculateLengthOfGlazingExterior = (
    glazingExterior: Coordinate[][]
  ) => {
    return glazingExterior.reduce(
      (length: number, coordinate: Coordinate[]) => {
        const x = coordinate[1].x - coordinate[0].x;
        const y = coordinate[1].y - coordinate[0].y;
        length += Math.sqrt(x * x + y * y);
        return length;
      },
      0.0
    );
  };

  const handleShowDaylightMetric = () => {
    setShowDaylightMetric(!showDaylightMetric);
  };

  const handleFloorMetrics = useCallback(
    (options: any) => {
      const { metrics, daylightScore, untappedDaylightPotential } = options;
      if (!currentFeatures) return;

      const { numbers, percents } = Object.keys(metrics).reduce(
        (calculated: any, metric: string) => {
          calculated.numbers[metric] = metrics[metric];
          calculated.percents[metric] =
            (metrics[metric] * 100) / currentFeatures.desks.length;

          return calculated;
        },
        { numbers: {}, percents: {} }
      );

      Object.keys(percents).forEach((key) => {
        const rounded = Math.round(percents[key] * 10) / 10;
        percents[key] = rounded;
      });

      percents.daylightScore = (
        (daylightScore * 100) /
        currentFeatures.desks.length
      ).toFixed(1);
      percents.untappedDaylightPotential = (
        100 -
        (untappedDaylightPotential * 100) /
          calculateLengthOfGlazingExterior(currentFeatures.glazingExterior)
      ).toFixed(1);

      setState("floorMetrics", { numbers, percents });
    },
    [currentFeatures, setState]
  );

  const compute = useCallback(() => {
    (document.activeElement as HTMLElement).blur();

    const renderDaylight = async (fid: string, withScreenshot: boolean) => {
      if (!project || checkOSForWebgl()) {
        return;
      }

      try {
        const features = await getFeatures(buildingID, fid);
        const daylightModule = new DaylightModule();
        const id = encodeMetricId(buildingID, strategyID, fid);
        const metric = project.metrics[id];
        const { keptCenters } = calcDesks(metric, features, []);
        const desks = [...keptCenters, ...blockDesks];
        const solverInput = prepareSolverInput(features);
        const metricHandler = !isAllBuildingMetric
          ? handleFloorMetrics
          : handleAllBuildingMetrics;

        const { cameraParams, rendererParams, cameraPosition } = generateParams(
          solverInput.boundary,
          scale
        );
        const {
          resolution,
          sumAvarageRatio,
          primarySecondaryRatio,
          linearQuadraticDecayRatio,
        } = solverInput;
        const floorParams = {
          floorToFloor,
          resolution,
          sumAvarageRatio,
          primarySecondaryRatio,
          linearQuadraticDecayRatio,
          scale,
        };

        daylightModule.createRenderer();
        daylightModule.createCamera(
          cameraParams.left,
          cameraParams.right,
          cameraParams.top,
          cameraParams.bottom
        );
        daylightModule.setRendererParams(
          Math.floor(rendererParams.width),
          Math.floor(rendererParams.height)
        );
        daylightModule.createRenderTarget();
        daylightModule.setCameraPosition(
          cameraPosition.x,
          cameraPosition.y,
          cameraPosition.scale
        );
        daylightModule.setBoundary(solverInput.boundary);
        daylightModule.buildSources(solverInput.sources);
        daylightModule.buildObstructions(solverInput.obstructions);
        daylightModule.addDesks(desks);
        daylightModule.createFloor(floorParams);
        daylightModule.calculateMetric(
          desks,
          solverInput.boundary,
          scale,
          metricHandler,
          fid
        );

        if (isAllBuildingMetric && allBuildingDesksCount === 0) {
          setAllBuildingDesksCount((prev) => prev + desks.length);
        }

        if (isAllBuildingMetric && allBuildingGlazingLength === 0) {
          const { glazingExterior } = features;
          setAllBuildingGlazingLength(
            (prev) => prev + calculateLengthOfGlazingExterior(glazingExterior)
          );
        }

        if (withScreenshot) {
          daylightModule.render();
          daylightModule.takeScreenshot(setDaylightTexture);
        }
      } catch (err) {
        console.error("Error in daylight metric", err);
      }
    };

    startedCalculating();
    setAllBuildingDaylightScore(0);
    setAllBuildingUntappedDaylightPotential(0);
    setState("buildingMetrics", {
      numbers: { ...defaultMetrics.numbers },
      percents: { ...defaultMetrics.percents },
    });

    if (isAllBuildingMetric) {
      setTimeout(async () => {
        for (const floor of availableFloors) {
          await renderDaylight(floor, floorID === floor);
        }
        finishedCalculating();
      }, DELAY_BEFORE_CALCULATING_MS);
    } else {
      setTimeout(async () => {
        await renderDaylight(floorID, true);
        finishedCalculating();
      }, DELAY_BEFORE_CALCULATING_MS);
    }
  }, [
    isAllBuildingMetric,
    floorID,
    availableFloors,
    allBuildingGlazingLength,
    allBuildingDesksCount,
    buildingID,
    floorToFloor,
    handleFloorMetrics,
    project,
    scale,
    setDaylightTexture,
    strategyID,
    blockDesks,
    finishedCalculating,
    setState,
    startedCalculating,
  ]);

  const handleAllBuildingMetrics = (metrics: any) => {
    setAllBuildingDaylightScore((pre) => pre + metrics.daylightScore);
    setAllBuildingUntappedDaylightPotential(
      (pre) => pre + metrics.untappedDaylightPotential
    );
    setMetricsContainer((pre) => [...pre, metrics]);
  };

  useEffect(() => {
    if (metricsContainer.length !== availableFloors.length) return;

    const { numbers } = metricsContainer.reduce(
      (calculated: any, currentMetric: Metrics) => {
        Object.keys(currentMetric.metrics).forEach((metric: string) => {
          calculated.numbers[metric] += Number(currentMetric.metrics[metric]);
        });

        if (floorID === String(currentMetric.floorID)) {
          handleFloorMetrics(currentMetric);
        }
        return calculated;
      },
      { numbers: { ...defaultMetrics.numbers } }
    );

    setState("buildingMetrics", { ...buildingMetrics, numbers });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metricsContainer, availableFloors]);

  useEffect(() => {
    if (metricsContainer.length !== availableFloors.length) return;

    const { percents } = Object.keys(buildingMetrics.numbers).reduce(
      (calculated: any, currentMetric: string) => {
        const pct = (
          (buildingMetrics.numbers[currentMetric] * 100) /
          allBuildingDesksCount
        ).toFixed(1);
        calculated.percents[currentMetric] = Number(pct);
        return calculated;
      },
      { percents: { ...defaultMetrics.percents } }
    );

    Object.keys(percents).forEach((key) => {
      const rounded = Math.round(percents[key] * 10) / 10;
      percents[key] = rounded;
    });

    setState("buildingMetrics", { ...buildingMetrics, percents });
    setMetricsContainer([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildingMetrics]);

  useEffect(() => {
    setStateToDefault();
    setAllBuildingDesksCount(0);
    setAllBuildingDaylightScore(0);
    setAllBuildingUntappedDaylightPotential(0);
  }, [floorID, setStateToDefault]);

  const handleFloorToFloorInput = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = Number(e.target.value);

    if (value && value < 0) {
      return setState("floorToFloor", 1);
    }

    setState("floorToFloor", value);
  };

  const handleShowInPercents = () => setState("isInPercents", !isInPercents);

  const handleShowAllBuildingMetric = () =>
    setState("isAllBuildingMetric", !isAllBuildingMetric);

  const generateDaylitZonesMetrics = () => {
    const list = [
      {
        label: "Desks in No Fly Zone",
        metric: "noFlyZone",
        color: "#f05306",
      },
      {
        label: "Desks in Primary Sidelit Zone",
        metric: "primarySidelitZone",
        color: "#fee145",
      },
      {
        label: "Desks in Toplit Zone",
        metric: "toplitZone",
        color: "#fee145",
      },
      {
        label: "Desks in Secondary Sidelit Zone",
        metric: "secondarySidelitZone",
        color: "#bbd2da",
      },
      {
        label: "Desks in No Daylight Zone",
        metric: "noDaylightZone",
        color: "#708eac",
      },
    ];
    const valueType: string = isInPercents ? "percents" : "numbers";

    return list.map((item, key) => {
      // floor values
      const floor = `${floorMetrics[valueType][item.metric]}${
        !isInPercents ? `/${currentFeatures?.desks.length}` : ""
      }${isInPercents ? "%" : ""}`;
      // building values
      const building =
        buildingMetrics[valueType][item.metric] !== 0
          ? `${buildingMetrics[valueType][item.metric]}${
              !isInPercents ? `/${allBuildingDesksCount}` : ""
            }${isInPercents ? "%" : ""}`
          : "-";

      return (
        <div className={styles.settingsItem} key={`metric-${key}`}>
          <InputLabel style={{ justifyContent: "flex-start", display: "flex" }}>
            <div
              className={styles.bullet}
              style={{ backgroundColor: item.color }}
            />
            {item.label}
          </InputLabel>
          <div className={styles.metricTitleContainer}>
            <InputLabel
              style={{
                justifyContent:
                  buildingMetrics[valueType][item.metric] !== 0
                    ? "flex-end"
                    : "center",
              }}
              className={styles.inputLabel}
            >
              {building}
            </InputLabel>
            <InputLabel className={styles.inputLabel}>{floor}</InputLabel>
          </div>
        </div>
      );
    });
  };

  const getAllBuildingDaylightScore = () => {
    return allBuildingDaylightScore
      ? `${((allBuildingDaylightScore * 100) / allBuildingDesksCount).toFixed(
          1
        )}%`
      : "-";
  };

  const getAllBuildingUntappedDaylightPotential = () => {
    return allBuildingUntappedDaylightPotential
      ? `${(
          ((allBuildingGlazingLength - allBuildingUntappedDaylightPotential) *
            100) /
          allBuildingGlazingLength
        ).toFixed(1)}%`
      : "-";
  };

  return (
    <div className={styles.container}>
      <div className={styles.settings}>
        <div className={styles.settingsItem}>
          <InputLabel>Floor to floor:</InputLabel>
          <TextField
            className={styles.textField}
            label=""
            type="number"
            value={floorToFloor}
            onChange={handleFloorToFloorInput}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className={styles.settingsItem}>
          <InputLabel>Show Daylight</InputLabel>
          <Switch
            onClick={handleShowDaylightMetric}
            color="primary"
            checked={showDaylightMetric}
          />
        </div>
        <div className={styles.settingsItem}>
          <InputLabel>Calculate building</InputLabel>
          <Switch
            onClick={handleShowAllBuildingMetric}
            color="primary"
            checked={isAllBuildingMetric}
          />
        </div>
        <div className={styles.settingsItem}>
          <div className={styles.title}>Desks in Daylit Zones</div>
          <FormControlLabel
            style={{ marginRight: "0px" }}
            control={
              <Switch
                onClick={handleShowInPercents}
                color="primary"
                checked={isInPercents}
              />
            }
            label="%"
            labelPlacement={"start"}
          />
        </div>
        <div className={styles.metricTitleContainer}>
          <div className={styles.metricTitle}>Building</div>
          <div className={styles.metricTitle}>Floor</div>
        </div>
        {generateDaylitZonesMetrics()}
        <div data-lookatme className={styles.settingsItem}>
          <HelpguideWrapperDiv className={styles.title}>
            <HelpguideHandleSpan tooltipKey="Daylight Score">
              Daylight Score
            </HelpguideHandleSpan>
          </HelpguideWrapperDiv>
          <div className={styles.metricTitleContainer}>
            <InputLabel
              style={{
                justifyContent:
                  allBuildingDaylightScore !== 0 ? "flex-end" : "center",
              }}
              className={styles.inputLabel}
            >
              {getAllBuildingDaylightScore()}
            </InputLabel>
            <InputLabel className={styles.inputLabel}>
              {floorMetrics.percents.daylightScore}%
            </InputLabel>
          </div>
        </div>
        <div className={styles.settingsItem}>
          <HelpguideWrapperDiv className={styles.title}>
            <HelpguideHandleSpan tooltipKey="Untapped Daylight Potential">
              Untapped Daylight Potential
            </HelpguideHandleSpan>
          </HelpguideWrapperDiv>
          <div className={styles.metricTitleContainer}>
            <InputLabel
              style={{
                justifyContent:
                  allBuildingUntappedDaylightPotential !== 0
                    ? "flex-end"
                    : "center",
              }}
              className={styles.inputLabel}
            >
              {getAllBuildingUntappedDaylightPotential()}
            </InputLabel>
            <InputLabel className={styles.inputLabel}>
              {floorMetrics.percents.untappedDaylightPotential}%
            </InputLabel>
          </div>
        </div>
        <Tooltip
          title="Please note, calculation may take time to process"
          arrow
          placement="left"
        >
          <div className={styles.calculateButtonContainer}>
            <Button.Sm.Sqr.Primary
              variant="contained"
              className={styles.calculateButton}
              color="primary"
              onClick={compute}
              disabled={state.calculateButtonDisabled}
            >
              Calculate
            </Button.Sm.Sqr.Primary>
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default DaylightMetric;
