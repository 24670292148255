import axios from "axios";
import { putDataFile } from "lib/api/dataFile";
import { setupCache } from "axios-cache-adapter";
import { getAuthHeader } from "lib/auth";
import config from "config";

export interface FloorConfiguration {
  offsetX: number;
  offsetY: number;
}

const cache = setupCache({
  maxAge: 30 * 60 * 1000,
});

export const getFloorConfiguration = async (
  buildingID: string,
  floorID: string
): Promise<FloorConfiguration | undefined> => {
  try {
    const headers = await getAuthHeader();
    const api = axios.create({
      headers,
      adapter: cache.adapter,
    });

    const data = await api.get(
      `${config.baseUrl}/api/planning/buildings/${buildingID}/${floorID}/floor_config.json`
    );

    return data.data;
  } catch (e) {
    return undefined;
  }
};

export const setFloorConfiguration = async (
  buildingID: string,
  floorID: string,
  fc: FloorConfiguration
): Promise<void> => {
  await putDataFile(buildingID, floorID, "floor_config.json", fc);
};
