import React, { ComponentType, FC } from "react";

import { BackgroundProvider } from "lib/containers";
import { useParams } from "react-router-dom";

function withBackgrounds<Props>(Component: ComponentType<Props>): FC<Props> {
  const Consumer: React.FC<Props> = (props) => {
    return <Component {...{ ...props }} />;
  };

  return (props) => {
    const {
      buildingID,
      strategyID,
      floorID,
      id: projectID,
    } = useParams<{ [key: string]: string }>();
    return (
      <BackgroundProvider
        initialState={{ floorID, strategyID, buildingID, projectID }}
      >
        <Consumer {...props} />
      </BackgroundProvider>
    );
  };
}

export default withBackgrounds;
