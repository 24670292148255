import { Chip as MaterialChip, makeStyles } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import * as React from "react";

export const useChipStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    background: "#E3EDFE",
    boxSizing: "border-box",
    border: "1px solid",
    borderRadius: 4,
  },
  primary: {
    color: theme.palette.primary.main,
  },
}));

const Chip = (props: any) => {
  const classes = useChipStyles();
  return (
    <MaterialChip
      size="small"
      deleteIcon={<Close className={classes.primary} />}
      classes={{ root: classes.root }}
      {...props}
    />
  );
};

export default Chip;
