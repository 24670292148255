import { useState } from "react";
import { createContainer } from "unstated-next";
import { BlockType } from "./renderer";

export interface UseBlockFilterSettings {
  blockTypeSelection: BlockType;
  selectedActivities: string[];
  setBlockTypeSelection(t: BlockType): void;
  setSelectedActivities(acts: string[]): void;
}

export const useBlockFilterSettingsContainer = (): UseBlockFilterSettings => {
  const [blockTypeSelection, setBlockTypeSelection] = useState(BlockType.Focus);
  const [selectedActivities, setSelectedActivities] = useState<string[]>([]);

  return {
    blockTypeSelection,
    setBlockTypeSelection,
    selectedActivities,
    setSelectedActivities,
  };
};

export const BlockFilterSettingsController = createContainer(
  useBlockFilterSettingsContainer
);
export const BlockFilterSettingsProvider =
  BlockFilterSettingsController.Provider;
export const useBlockFilterSettingsCtrl = () =>
  BlockFilterSettingsController.useContainer();
