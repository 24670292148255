import React, { useEffect, useState } from "react";

import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import EditIcon from "@material-ui/icons/Edit";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    letterSpacing: 0,
    height: "2rem",
    fontSize: "20px",
  },
  iconContainer: {
    width: "32px",
    height: "32px",
    color: "#ADADAD",
    borderRadius: "4px",
    marginLeft: "6px",
    "& svg": {
      width: "18px",
      height: "18px",
      color: "#ADADAD",
    },
    "&:hover, &.Mui-focusVisible": { backgroundColor: "#E9E9EB" },
  },
  titleContainer: {
    position: "relative",
    width: "fit-content",
    display: "flex",
  },
  title: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "224px",
    textTransform: "capitalize",
    color: "#4A4A4A",
    fontSize: "20px",
    letterSpacing: "-0.625px",
  },
});

interface Props {
  onBlur: (value: string) => void;
  title: string;
}

export const Title: React.FC<Props> = ({ onBlur, title }) => {
  const [name, setName] = useState(title);
  const [editMode, setEditMode] = useState(false);
  const [, setHover] = useState(false);
  const classes = useStyles();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };
  const handleBlur = () => {
    onBlur(name);
    setEditMode(!editMode);
  };
  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleBlur();
    }
  };
  const onClick = () => {
    setEditMode(!editMode);
  };

  useEffect(() => {
    setName(title);
  }, [title]);

  return (
    <React.Fragment>
      {editMode ? (
        <form className={classes.root}>
          <InputBase
            autoFocus
            onBlur={handleBlur}
            className={classes.root}
            value={name}
            onChange={onChange}
            onKeyPress={onKeyPress}
            inputProps={{
              "data-testid": "study-name-input",
            }}
          />
        </form>
      ) : (
        <div className={classes.titleContainer}>
          <div
            className={classes.title}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={onClick}
          >
            {name}
          </div>
          <IconButton
            aria-label="Edit"
            onClick={onClick}
            className={classes.iconContainer}
            data-testid="edit-study-name-button"
          >
            <EditIcon fontSize="medium" />
          </IconButton>
        </div>
      )}
    </React.Fragment>
  );
};

export default React.memo(Title);
