/* eslint-disable @typescript-eslint/no-shadow */
// TODO Stop violating this rule so much

import { calculateBlockSeats } from "./metrics";
import { Block, ShapeProps, BlockGetter, Counts, TypeMetric } from "./types";
import { getters, dividerWidths } from "./constants";
import {
  GeometryType,
  getBoundingBox,
  boxToSize,
} from "@outerlabs/shapes-geometry";
import { makeShape } from "@outerlabs/shapes-core";
import { mat4, quat, vec3 } from "gl-matrix";
import { applyOverrides, mergeChildMetrics } from "./blocks";

export const makeBlockInstance = (
  block: Block,
  mat: mat4,
  size: [number, number],
  getBlockById: BlockGetter,
  level = 0
): Block => {
  const { sizeRange } = getters.getMetrics(block);
  const layout = getters.getLayout(block);
  const sides = getters.getSides(block);
  const style = getters.getStyle(block);
  const definition = getters.getDefinition(block);
  const [width, height] = size;
  const padding = layout.padding || [0, 0, 0, 0];
  const dividers = sides.dividers
    ? sides.dividers.map((d) => dividerWidths[d.type] || 0)
    : [0, 0, 0, 0];
  const pos = [padding[3] + dividers[3], padding[0] + dividers[0]];
  // asset with geometry children
  if (block.role === "asset") {
    const name = block.props.name || "";
    if (block.symbol && block.symbol.startsWith("Block")) {
      const ref = getBlockById(block.symbol);
      if (ref) {
        return makeBlockInstance(ref, mat, size, getBlockById, level + 1);
      } else {
        throw new Error("no block ref");
      }
    }
    const tagMetrics: Counts = {};
    definition.tags.forEach((tag) => (tagMetrics[tag] = 1));
    const typeMetrics: { [k: string]: TypeMetric } = {
      [definition.type]: {
        area: width * height,
        seats: 1,
        workpoints: 1,
        headcount: 1,
        seatsRange: [1, 1],
        workpointsRange: [1, 1],
        headcountRange: [1, 1],
        areaRange: [width * height, width * height],
        tags: tagMetrics,
        names: {},
        costUnit: 1,
        costGSF: 1,
        totalCost: 1,
        costHC: 1,
        totalCostRange: [1, 1],
        costHCRange: [1, 1],
        sharingRatio: 1,
        doors: 1,
      },
    };

    // prettier-ignore
    return makeShape({
      role: "asset-instance",
      symbol: block.symbol,
      matrix: mat,
      props: {
        name,
        style,
        definition: { ...definition, name },
        metrics: {
          size: [width, height],
          area: width * height,
          tags: tagMetrics,
          types: typeMetrics,
        },
        sides,
        layout,
      },
      geometry: {
        type: "box" as GeometryType,
        box: [[0, 0], [width, height]],
      },
    });
  }

  // block with block children
  if (block.children.length > 0) {
    if (layout.mode === "flex" && layout.flex) {
      let growSum = 0;
      let repeatSum = 0;

      let dim = 0;
      if (layout.flex.mode === "vertical") dim = 1;

      // compute the sum of "grow" and "repeat" across children
      block.children.forEach((child) => {
        const { grow: childGrow } = getters.getConstraints(child);
        const { sizeRange: childSizeRange } = getters.getMetrics(child);
        growSum += childGrow || 0;
        if (childSizeRange[dim][0] !== childSizeRange[dim][1]) {
          repeatSum += childSizeRange[dim][1];
        }
      });

      let primaryDiff = (dim ? height : width) - sizeRange[dim][0];
      const spacing = layout.flex.spacing || 0;
      let taken = 0;

      // add up child dimensions
      const childSizes: [number, number][] = block.children.map((child) => {
        const { sizeRange: childSizeRange } = getters.getMetrics(child);
        let added = 0;
        // if the child doesn't have a fixed size (is flexible) in this dimension
        if (childSizeRange[dim][0] !== childSizeRange[dim][1]) {
          const sizeRatio = childSizeRange[dim][1] / repeatSum;
          added = primaryDiff * sizeRatio;
          taken += added;
        }
        const childSize = childSizeRange[dim][0] + added;
        const newSize: [number, number] = [size[0], size[1]];
        newSize[dim] = childSize;
        return newSize;
      });
      primaryDiff -= taken;
      const totalSpacing = spacing * (block.children.length - 1);
      const childInstances = block.children.map((child, i) => {
        const size = childSizes[i];
        let added = 0;
        // distribute remaining space to child blocks
        if (growSum === 0) {
          // if none of them "grow", they all get proportional space
          const sizeRatio = size[dim] / (sizeRange[dim][0] - totalSpacing);
          added = primaryDiff * sizeRatio;
        } else {
          // otherwise they grow based on the "grow" ratio
          const { grow } = getters.getConstraints(child);
          const growRatio = (grow || 0) / growSum;
          added = primaryDiff * growRatio;
        }
        const childSize = size[dim] + added;
        // create a translation matrix
        const n = mat4.fromTranslation(
          mat4.create(),
          vec3.fromValues(pos[0], pos[1], 0)
        );
        // offset position by child size + spacing (for the next loop)
        pos[dim] += childSize + spacing || 0;
        // compute size while taking into account padding and dividers
        const newSize: [number, number] = [
          size[0] - padding[1] - padding[3] - dividers[1] - dividers[3],
          size[1] - padding[0] - padding[2] - dividers[0] - dividers[2],
        ];
        newSize[dim] = childSize;
        return makeBlockInstance(child, n, newSize, getBlockById, level + 1);
      });
      const metrics = mergeChildMetrics(
        childInstances.map((child) => getters.getMetrics(child))
      );
      metrics.size = [width, height];
      metrics.area = width * height;
      // prettier-ignore
      const out = makeShape({
        id: block.id,
        role: "block-instance",
        matrix: mat,
        children: childInstances,
        geometry: {
          type: "box" as GeometryType,
          box: [[0, 0], [width, height]],
        },
        props: { metrics, layout, sides },
      });
      return applyOverrides(out, block.props);
    } else {
      const box = block.geometry.box;
      const metrics = mergeChildMetrics(
        block.children.map((child) => getters.getMetrics(child))
      );
      if (box) {
        metrics.size = boxToSize(box);
        metrics.area = metrics.size[0] * metrics.size[1];
        // prettier-ignore
        const out = makeShape({
          id: block.id,
          role: "block-instance",
          matrix: mat,
          geometry: {
            type: "box" as GeometryType,
            box: [[0, 0], [width, height]],
          },
          props: { metrics, layout, sides },
          children: block.children.map((child) => makeBlockInstance(child, mat, size, getBlockById, level+1)),
        });
        return applyOverrides(out, block.props);
      } else {
        return makeShape({});
      }
    }
  } else {
    // block symbol (with block or asset children)
    const symbol = getBlockById(block.symbol);
    if (symbol && symbol.role === "block") {
      const copy = { ...symbol, id: block.id };
      return makeBlockInstance(copy, mat, size, getBlockById, level);
    } else {
      // prettier-ignore
      const instance = makeShape<ShapeProps>({
        id: block.id,
        role: "block-instance",
        symbol: block.symbol,
        matrix: mat,
        geometry: {
          type: "box" as GeometryType,
          box: [[0, 0], [width, height]],
        },
        props: {
          definition: { type: definition.type },
          metrics: { size: [width, height] },
          layout,
          sides,
        },
      });
      instance.children = makeAssetInstances(block, instance, getBlockById);
      const mergedMetrics = mergeChildMetrics(
        instance.children.map((block) => getters.getMetrics(block))
      );
      Object.keys(mergedMetrics.types).forEach((k) => {
        mergedMetrics.types[k].area = width * height;
      });
      instance.props.metrics = {
        ...mergedMetrics,
        size: [width, height],
        area: width * height,
      };
      applyOverrides(instance, block.props);
      return instance;
    }
  }
};

export const makeAssetInstances = (
  block: Block,
  instance: Block,
  getBlockById: BlockGetter
): Block[] => {
  const definition = getters.getDefinition(block);
  const layout = getters.getLayout(block);
  const flex = layout.flex;
  const { size } = getters.getMetrics(instance);
  const style = getters.getStyle(block);
  const sides = getters.getSides(block);
  const dividers = sides.dividers
    ? sides.dividers.map((d) => dividerWidths[d.type] || 0)
    : [0, 0, 0, 0];
  const repeat = flex && flex.repeat ? flex.repeat : [1, 1];
  const spacing = flex && flex.spacing ? flex.spacing : 0;
  const rotation = layout.rotation ? layout.rotation : 0;
  const angle = rotation * (Math.PI / 180);
  const direction =
    flex && flex.mode === "horizontal" ? "horizontal" : "vertical";
  let margin = layout.padding ? layout.padding : [0, 0, 0, 0];
  margin = margin.map((m, i) => m + dividers[i]);
  const align = [
    flex && flex.primaryAlign ? flex.primaryAlign : "min",
    flex && flex.crossAlign ? flex.crossAlign : "min",
  ];

  const asset = getBlockById(block.symbol);
  if (!asset) return [];
  const assetMetrics = getters.getMetrics(asset);
  const assetDefinition = getters.getDefinition(asset);

  const [w, h] = size;
  const rep =
    direction === "horizontal"
      ? { max: [repeat[1], 1], min: [repeat[0], 1] }
      : { max: [1, repeat[1]], min: [1, repeat[0]] };
  const { horizontalSeats, verticalSeats } = calculateBlockSeats(
    asset,
    layout.rotation || 0,
    rep,
    margin,
    spacing,
    w,
    h
  );
  const bb = getBoundingBox(instance.geometry);
  const [x, y] = bb[0];
  const r = Math.round((rotation || 0) / 90);

  // Shift offsets based on the asset rotation
  // TODO: offset should be number[] but for now it's any
  const offset: any =
    r > 0
      ? layout.offset?.slice(r).concat(layout.offset.slice(0, r))
      : layout.offset;
  const uOffset = offset[1] + offset[3];
  const vOffset = offset[2] + offset[0];
  let uSize = assetMetrics.size[0] + uOffset;
  let vSize = assetMetrics.size[1] + vOffset;

  let yRes = 0;
  let xRes = 0;
  let yStart = 0;
  let xStart = 0;

  const rot = layout.rotation || 0;
  const sx = 0;
  const sy = 0;

  const sin = Math.abs(Math.sin(angle));
  const cos = Math.abs(Math.cos(angle));
  const uuSize = uSize * cos + vSize * sin;
  const vvSize = vSize * cos + uSize * sin;
  const puSize = uSize;
  const pvSize = vSize;
  uSize = uuSize;
  vSize = vvSize;

  if (direction === "horizontal") {
    // Get number of assets in x and y
    xRes =
      w -
      horizontalSeats * uSize -
      (horizontalSeats - 1) * spacing -
      margin[1] -
      margin[3];
    yRes = h - vSize - margin[0] - margin[2];
    // Align left, center or right
    xStart = x + offset[3];
    if (align[0] === "center") xStart = x + Math.floor(xRes / 2) + offset[3];
    else if (align[0] === "max") xStart = x + xRes + offset[3];
    // Align top, center or bottom
    yStart = y + margin[0] + offset[0];
    if (align[1] === "center") yStart = y + margin[0] + yRes / 2 + offset[0];
    else if (align[1] === "max") yStart = y + margin[0] + yRes + offset[0];
  } else {
    // Get number of assets in x and y
    xRes = w - uSize - margin[1] - margin[3];
    yRes =
      h -
      verticalSeats * vSize -
      (verticalSeats - 1) * spacing -
      margin[0] -
      margin[2];
    // Align left, center or right
    xStart = x + margin[3] + offset[3];
    if (align[1] === "center") xStart = x + margin[3] + xRes / 2 + offset[3];
    else if (align[1] === "max") xStart = x + margin[3] + xRes + offset[3];
    // Align top, center or bottom
    yStart = y + offset[0];
    if (align[0] === "center") yStart = y + Math.floor(yRes / 2) + offset[0];
    else if (align[0] === "max") yStart = y + yRes + offset[0];
  }

  const instances: Block[] = [];

  for (let x = 0; x < horizontalSeats; x++) {
    const xNext =
      xStart +
      (direction === "horizontal" ? margin[3] : 0) +
      uSize * x +
      (x > 0 ? spacing : 0) * x;
    for (let y = 0; y < verticalSeats; y++) {
      const yNext =
        yStart +
        (direction === "vertical" ? margin[0] : 0) +
        vSize * y +
        (y > 0 ? spacing : 0) * y;
      const angle = rot * (Math.PI / 180);
      const sin = Math.sin(angle);
      const cos = Math.cos(angle);
      let ox = 0;
      let oy = 0;
      if (rot <= 90) {
        ox = sin * pvSize;
      } else if (rot <= 180) {
        oy = -cos * pvSize;
        ox = sin * pvSize - cos * puSize;
      } else if (rot <= 270) {
        ox = -cos * puSize;
        oy = -sin * puSize - cos * pvSize;
      } else {
        oy = -sin * puSize;
      }
      const m = mat4.fromRotationTranslationScale(
        mat4.create(),
        quat.setAxisAngle(quat.create(), vec3.fromValues(0, 0, 1), angle),
        vec3.fromValues(xNext + sx + ox, yNext + sy + oy, 0),
        vec3.fromValues(1, 1, 1)
      );
      const tagMetrics: Counts = {};
      definition.tags.forEach((tag) => (tagMetrics[tag] = 1));
      const area = uSize * vSize;
      const typeMetrics: { [k: string]: TypeMetric } = {
        [assetDefinition.type]: {
          area: area,
          seats: assetMetrics.seats,
          workpoints: assetMetrics.workpoints,
          headcount: assetMetrics.headcount,
          seatsRange: [assetMetrics.seats, assetMetrics.seats],
          workpointsRange: [assetMetrics.workpoints, assetMetrics.workpoints],
          headcountRange: [assetMetrics.headcount, assetMetrics.headcount],
          areaRange: [area, area],
          tags: tagMetrics,
          names: { [assetDefinition.name]: 1 },
          costUnit: 1,
          costGSF: 1,
          costHCRange: [1, 1],
          totalCostRange: [1, 1],
          costHC: 1,
          totalCost: 1,
          sharingRatio: 1,
          doors: assetMetrics.doors,
        },
      };
      instances.push(
        // prettier-ignore
        makeShape({
          role: "asset-instance",
          symbol: block.symbol,
          matrix: m,
          props: {
            ...asset.props,
            metrics: {
              ...assetMetrics,
              area,
              size: [uSize, vSize],
              sizeRange: [[uSize, uSize], [vSize, vSize]],
              tags: tagMetrics,
              types: typeMetrics,
              names: { [assetDefinition.name]: 1 },
              doors: assetMetrics.doors,
              doorsRange: assetMetrics.doorsRange
            },
            layout: { rotation: layout.rotation || 0 },
            style,
            definition: assetDefinition,
          },
          geometry: {
            type: "box" as GeometryType,
            box: [[xNext, yNext], [xNext + uSize, yNext + vSize]],
          },
        })
      );
    }
  }
  return instances;
};
