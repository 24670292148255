import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Switch as Switch_, makeStyles } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles({
  labelStyle: {
    color: "#5F6166",
    fontFamily: "GoogleSansRegular",
    fontSize: "12px",
    lineHeight: "16px",
    marginBottom: 10,
    marginTop: 10,
  },
});

const BasicSwitch = withStyles({
  switchBase: {
    "&$checked": {
      color: "#2F73FF",
    },
    "&$checked + $track": {
      backgroundColor: "#2F73FF50",
    },
    "&:hover": {
      background: "none",
    },
  },
  checked: {},
  track: { backgroundColor: "#221F1F70" },
})(Switch_);

interface SwtichProps {
  label: string;
  checked: boolean;
  onChange: () => void;
  disabled: boolean;
}

const Switch: React.FC<SwtichProps> = ({
  label,
  checked,
  onChange,
  disabled,
}) => {
  const classes = useStyles();
  return (
    <FormControlLabel
      control={
        <BasicSwitch
          disableRipple
          checked={checked}
          onChange={onChange}
          color="default"
          disabled={disabled}
        />
      }
      label={label}
      className={classes.labelStyle}
    />
  );
};

export default Switch;
