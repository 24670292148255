import React, { useEffect, useRef, useState, useMemo } from "react";
import { CanvasReconciler } from "@outerlabs/canvas-reconciler";
import { Xform, Shaders } from "components/isp-canvas";
import { RendererMode, useFeaturesCtrl, useRendererCtrl } from "lib/containers";
import { SolverInput, daylightUtils } from "@outerlabs/metric-solver";
import { createHiDPICanvas, getDesksFromBlocks } from "../blocks/lib/util";
import { RendererSettings } from "../lib/types";

const { prepareSolverInput, getBboxDimensions } = daylightUtils;

interface Props {
  width: number;
  height: number;
  settings?: RendererSettings;
}

type SizeInPixels = Props;

// Causes OS crashes on nick's ubuntu laptop :(
export const checkOSForWebgl = () => {
  return window.navigator.platform === "Linux x86_64";
};

export const ISPCanvasShaders: React.FC<Props> = ({
  width,
  height,
  settings,
}) => {
  const shadersCanvasRef = useRef<HTMLCanvasElement | null>();
  const { currentFeatures } = useFeaturesCtrl();
  const {
    xform,
    setXform,
    rendererMode,
    setRendererMode,
    showDaylightMetric,
    daylightTexture,
  } = useRendererCtrl();
  const [solverInput, setSolverInput] = useState<SolverInput | null>(null);
  const [sizeInPixels, setSizeInPixels] = useState<SizeInPixels | null>(null);
  const blockDesks = useMemo(
    () =>
      getDesksFromBlocks(settings && settings.blocks ? settings.blocks : []),
    [settings]
  );

  const renderWithImage = () => {
    if (!solverInput || !sizeInPixels) {
      return;
    }

    const {
      width: coordinateWidth,
      height: coordinateHeight,
      bbMin,
      bbMax,
    } = getBboxDimensions(solverInput.boundary);
    const sizes = {
      coordinates: {
        width: coordinateWidth,
        height: coordinateHeight,
        bbMin,
        bbMax,
      },
      pixels: sizeInPixels,
    };

    setTimeout(() => {
      if (shadersCanvasRef.current && xform && daylightTexture) {
        const canvas = shadersCanvasRef.current;
        createHiDPICanvas(canvas, width, height);
        CanvasReconciler.render(
          <>
            <Xform xform={xform}>
              <Shaders imageCache={daylightTexture} sizes={sizes} />
            </Xform>
          </>,
          canvas
        );
      }
    }, 0);
  };

  const hasDaylight = React.useMemo(
    () =>
      currentFeatures &&
      currentFeatures.walls &&
      currentFeatures.walls.length > 0 &&
      currentFeatures.glazingExterior &&
      currentFeatures.glazingExterior.length > 0,
    [currentFeatures]
  );
  const init = () => {
    if (checkOSForWebgl()) return;
    if (currentFeatures)
      if (!solverInput && currentFeatures && hasDaylight) {
        setSolverInput(
          prepareSolverInput({
            ...currentFeatures,
            uniqueDesks: currentFeatures.uniqueDesks.concat(blockDesks),
          })
        );
      }
  };

  useEffect(init, [currentFeatures, solverInput, hasDaylight, blockDesks]);
  useEffect(renderWithImage, [
    solverInput,
    xform,
    currentFeatures,
    height,
    rendererMode,
    setRendererMode,
    width,
    daylightTexture,
    sizeInPixels,
    blockDesks,
  ]);

  useEffect(() => {
    if (!xform) return;

    if (!sizeInPixels && Boolean(xform?.scale)) {
      const calculatedWidth = (width * xform.globalScale) / -xform.scale;
      const calculatedHeight = (height * xform.globalScale) / -xform.scale;
      setSizeInPixels({ width: calculatedWidth, height: calculatedHeight });
    }
  }, [height, setXform, sizeInPixels, width, xform]);
  if (checkOSForWebgl() || rendererMode !== RendererMode.DaylightMetric) {
    return null;
  }

  return (
    <>
      <canvas
        width={width}
        height={height}
        style={{
          position: "absolute",
          top: 0,
          opacity: 0.7,
          display: showDaylightMetric ? "block" : "none",
        }}
        ref={(el) => (shadersCanvasRef.current = el)}
      />
    </>
  );
};
