import React from "react";
import { StrategyMode } from "lib/types";
import WarningModal from "./warning";
import Switch from "components/controls/switch";
import analytics from "../../lib/analytics";

export interface Props {
  floorID?: string;
  onMetricChange: (mutation: { [key: string]: any }) => void;
  mode: StrategyMode;
  disabled: boolean;
}

const Strategy: React.FC<Props> = ({
  floorID,
  mode,
  onMetricChange,
  disabled,
}) => {
  const [open, setOpen] = React.useState(false);
  const [selection, setSelection] = React.useState<number | undefined>(mode);

  const onToggle = (modeSelection: number | undefined) => {
    setSelection(modeSelection);
    handleOpen();
  };

  const onModeChange = async () => {
    if (selection === undefined) return;
    handleClose();
    const newMode = selection as StrategyMode;
    const mutation = {
      mode: newMode,
      deskSpacing: 36,
      corridorWidth: 0,
    };

    analytics.buildingModeToggled();
    await onMetricChange(mutation);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {floorID ? (
        <Switch
          label={"Same for All Levels"}
          onChange={() =>
            onToggle(
              mode === StrategyMode.Building
                ? StrategyMode.Zone
                : StrategyMode.Building
            )
          }
          checked={mode === StrategyMode.Building}
          disabled={disabled}
        />
      ) : null}
      <WarningModal
        handleClose={handleClose}
        open={open}
        message="Toggling the scope between Custom and Whole Building will clear any edits. If you would like to keep any edits and switch scope, please go to the list view and duplicate your study or create a new one. Would you like to proceed?"
        onAccept={onModeChange}
      />
    </>
  );
};

export default Strategy;
