import { defaultValues } from "lib/constants";
import {
  translateFeatureToValue,
  calculateFeatureArea,
} from "lib/isp-canvas/utils";
import { Features } from "lib/types";

export const metricConvertToCollaboration = (
  features: Features,
  convertToCollaboration: string[]
) => {
  let collabCount = 0;
  let collabArea = 0.0;
  let collabZones = 0;
  if (features.conferenceRooms) {
    Object.keys(features.conferenceRooms).forEach((f) => {
      const feature = translateFeatureToValue(f);
      if (convertToCollaboration?.find((c2c) => c2c === feature)) {
        Object.values(features.conferenceRooms[f]).forEach((rm) => {
          const area = calculateFeatureArea(rm);
          collabArea += area;
          collabCount += Math.max(
            Math.floor(area / defaultValues.collabSqFtPerPerson),
            1
          );
          collabZones += 1;
        });
      }
    });
  }
  return { collabCount, collabZones, collabArea };
};
