import React, { useEffect } from "react";
import Switch from "components/controls/switch";

export interface Props {
  disabled: boolean;
  desksOff: boolean;
  onMetricChange: (mutation: { [key: string]: any }) => void;
}

const DeskToggle: React.FC<Props> = ({
  disabled,
  desksOff,
  onMetricChange,
}) => {
  const [enabled, setEnabled] = React.useState(desksOff);

  const handleToggleChange = () => {
    onMetricChange({ desksOff: !desksOff });
    setEnabled(desksOff);
  };

  // update the state of the toggle whenever desksOff is changed
  // for example, when changing between floor levels
  useEffect(() => {
    setEnabled(desksOff);
  }, [desksOff]);

  return (
    <>
      <Switch
        label={"Remove Existing Desks"}
        onChange={handleToggleChange}
        checked={enabled}
        disabled={disabled}
      />
    </>
  );
};

export default DeskToggle;
