import {
  getSettingsFile,
  putSettingsFile,
  deleteSettingsFile,
} from "lib/api/rendererSettings";
import usePrevious from "lib/hooks/use-previous";
import { RendererSettings, Metrics, Mutation } from "lib/types";
import { ComponentType, useCallback, useEffect, useState } from "react";
import { ContainerProviderProps, createContainer } from "unstated-next";
import { EdgeValue } from "../metrics/buzz";
import { calculateFloorMetrics } from "lib/metrics/calculate-metrics";
import {
  addToHistoryStack,
  getHistoryBackState,
  getHistoryForwardState,
  historyInitialize,
  backEnabled,
  forwardEnabled,
} from "./history-manager";
import { encodeMetricId } from "lib/metrics/id";
import { calcDesks } from "lib/metrics/remove-desks";
import getFeatures from "lib/api/getFeatures";
import { useProjectCtrl } from "./project";
import { deepCopy } from "blocks/lib/constants";
import getFloorCirculation from "lib/api/floorCirculation";

export const emptySettingsFactory: () => RendererSettings = () => {
  return {
    blocks: [],
    blockChanges: { put: [], delete: [] },
    desks: {
      disabledCirculationDesks: [],
      disabledDistanceDesks: [],
      removedDesks: [],
      keptCenters: [],
      disabledCenters: [],
    },
    circulation: null,
  };
};

// Basically all functions here use these...
// Just saves a couple lines
type CommonSettingsArgs = {
  buildingID: string;
  strategyID: string;
  projectID: string;
};

export interface UseSettingsState {
  currentSettings: RendererSettings;

  resetSettings(
    arg: CommonSettingsArgs & {
      availableFloors: string[];
    }
  ): void;

  getHistoryBackEnabled(): boolean;

  getHistoryForwardEnabled(): boolean;

  duplicateSettings(
    arg: CommonSettingsArgs & {
      floorIDs: string[];
      newStrategyID: string;
    }
  ): Promise<void>;

  historyGoBack(
    arg: CommonSettingsArgs & {
      floorID: string;
    }
  ): Promise<void>;

  historyGoForward(
    arg: CommonSettingsArgs & {
      floorID: string;
    }
  ): Promise<void>;

  calculatedBuzz: EdgeValue[];

  setCalculatedBuzz: (buzz: EdgeValue[]) => void;

  saveFloor(arg: {
    buildingID: string;
    strategyID: string;
    projectID?: string; // does nothing...
    floorID: string;
    mutation?: Mutation;
    settings?: RendererSettings;
    dontAddToHistoryStack?: boolean;
    initializeHistory?: boolean;
    dontUpdateContainerState?: boolean;
    dontSaveProject?: boolean;
  }): Promise<void>;

  updateBuildingMetrics(
    mutation: Mutation,
    buildingID: string,
    strategyID: string,
    currentFloor?: string
  ): Promise<void>;

  // Behaves more akin to saveFloor, but for the whole building.  Causes only 1 refresh
  updateAllFloorMetrics(
    buildingID: string,
    strategyID: string,
    settings: { [key: string]: RendererSettings }
  ): Promise<void>;
}

type InitialState = CommonSettingsArgs & {
  floorID: string;
};

export const useSettingsState = (
  initialState: InitialState | undefined
): UseSettingsState => {
  const [currentSettings, setCurrentSettings] = useState<RendererSettings>(
    emptySettingsFactory()
  );
  const [currentCalculatedBuzz, setCurrentCalculatedBuzz] = useState<
    EdgeValue[]
  >([]);

  // Local state variables just to trigger useEffect when floor or building id change
  // because useEffect loads floor data from the backend
  const prevState: InitialState | undefined = usePrevious(initialState);

  const { project, saveProject } = useProjectCtrl();

  /**
   * The most useful function in this file.
   * Calculates downstream things like metrics and desks,
   * saves whatever change you've made to the history stack,
   * and saves to the backend.
   */
  const saveFloor = useCallback(
    async ({
      buildingID,
      strategyID,
      floorID,
      mutation,
      settings,
      dontAddToHistoryStack,
      dontUpdateContainerState,
      dontSaveProject,
      initializeHistory,
    }: {
      buildingID: string;
      strategyID: string;
      floorID: string;
      mutation?: Mutation;
      settings?: RendererSettings;
      dontAddToHistoryStack?: boolean;
      dontUpdateContainerState?: boolean;
      dontSaveProject?: boolean;
      initializeHistory?: boolean;
    }): Promise<void> => {
      const localMutation = mutation ?? {};
      if (!project) return;

      // Determine metrics
      const id = encodeMetricId(buildingID, strategyID, floorID);
      const metric = { ...project.metrics[id], ...localMutation };
      const newMetrics: Metrics = deepCopy(project.metrics);
      const features = await getFeatures(buildingID, floorID);

      // Determine desks.
      // Check that settings exists, because some calls to saveFloor don't
      if (features && settings && newMetrics) {
        newMetrics[id] = calculateFloorMetrics({
          metric,
          blocks: settings?.blocks,
          features,
        });
        // TODO: We need initializeHistory here to set up desks.
        // It's not clear why desks still get recalculated when we are, for example,
        // moving blocks around
        if (
          initializeHistory ||
          localMutation.deskSpacing ||
          localMutation.corridorWidth
        ) {
          const desks = calcDesks(metric, features, []);
          settings = { ...(settings || emptySettingsFactory()), desks };
        }
      }

      const newProject = {
        ...project,
        metrics: {
          // ...project.metrics,
          ...newMetrics,
        },
      };

      // Like we say above, not all calls to saveFloor pass in settings.
      // If it does though, save it in history, and also save it on the backend
      if (settings) {
        if (initializeHistory) {
          historyInitialize(
            buildingID,
            strategyID,
            floorID,
            newProject,
            settings
          );
        } else if (!dontAddToHistoryStack) {
          addToHistoryStack({
            buildingID,
            strategyID,
            floorID,
            project: newProject,
            rendererSettings: {
              ...settings,
              blocks: settings.blocks.map((b) => b.map((c) => deepCopy(c))),
            },
          });
        }

        if (!dontUpdateContainerState) {
          setCurrentSettings(settings);
        }

        await putSettingsFile(
          project.id,
          buildingID,
          floorID,
          strategyID,
          settings
        );
      }

      // Important in history stack operations.
      // Note: this bool is functionally identical to dontAddToHistoryStack;
      // it is only true when running historyGoBack and historyGoForward.
      // However, we separated them for more code clarity
      if (!dontSaveProject) {
        await saveProject(newProject);
      }
    },
    [project, saveProject]
  );

  /**
   * NOTE: this should be refactored.
   * Updates building information, and occasionally updates every floor --
   * really only if you have supplied a deskSpacing or corridorWidth key to
   * your mutation.
   *
   * Ideally the every-floor mutations would use updateAllFloorMetrics.
   */
  const updateBuildingMetrics = async (
    mutation: Mutation,
    buildingID: string,
    strategyID: string,
    currentFloor?: string
  ): Promise<void> => {
    if (!project) return;
    const floorCode = project.buildings[buildingID].AvailableFloors;
    const newMetrics: Metrics = {};
    const newSettingses: { [key: string]: RendererSettings } = {};
    for (const fc of floorCode) {
      const id = encodeMetricId(buildingID, strategyID, fc);
      const metric = { ...project.metrics[id], ...mutation };
      if (mutation.displayName) {
        newMetrics[id] = metric;
      } else {
        // get current settings for this floor
        const settings = await getSettingsFile(
          project.id,
          buildingID,
          fc,
          strategyID
        );
        const features = await getFeatures(buildingID, fc);
        if (
          mutation.deskSpacing ||
          mutation.corridorWidth ||
          mutation.desksOff
        ) {
          const desks = calcDesks(metric, features, []);
          // Block Instances not allowed in whole building mode
          newSettingses[fc] = { desks, blocks: settings?.blocks || [] };
        }
        newMetrics[id] = calculateFloorMetrics({
          metric,
          blocks: settings?.blocks || [],
          features,
        });
      }
    }

    if (mutation.deskSpacing || mutation.corridorWidth) {
      const keys = Object.keys(newSettingses);
      for (let i = 0; i < keys.length; i++) {
        const floorID = keys[i];
        const settings = newSettingses[floorID];
        const dontUpdateContainerState = floorID !== currentFloor;
        await saveFloor({
          settings,
          buildingID,
          floorID,
          strategyID,
          dontUpdateContainerState,
        });
      }
    }

    await saveProject({
      ...project,
      metrics: {
        ...project.metrics,
        ...newMetrics,
      },
    });
  };

  /**
   * updateAllFloorMetrics is ONLY used by AutoMagic!
   * It's different from other mass-update-floor-metrics functions because it
   * takes in a map of settings, with all the different floors and their updated settingses.
   */
  const updateAllFloorMetrics = async (
    buildingID: string,
    strategyID: string,
    settings: { [key: string]: RendererSettings }
  ) => {
    if (!project) return;
    const newMetrics: Metrics = {};
    const building = project.buildings[buildingID];

    for (const fc of building.AvailableFloors) {
      const id = encodeMetricId(buildingID, strategyID, fc);
      const currSettings = settings[fc];
      if (!currSettings) continue;

      const metric = { ...project.metrics[id] };
      const features = await getFeatures(buildingID, fc);

      newMetrics[id] = calculateFloorMetrics({
        metric,
        blocks: currSettings.blocks,
        features,
      });
      await saveFloor({
        settings: currSettings,
        buildingID,
        floorID: fc,
        strategyID,
      });
    }

    await saveProject({
      ...project,
      metrics: {
        ...project.metrics,
        ...newMetrics,
      },
    });
  };

  /**
   * History functions
   */
  const getHistoryBackEnabled = useCallback(() => {
    return backEnabled();
  }, []);

  const getHistoryForwardEnabled = useCallback(() => {
    return forwardEnabled();
  }, []);

  const historyGoBack = useCallback(
    async ({ buildingID, floorID, strategyID }) => {
      const backState = await getHistoryBackState();
      if (backState === null) {
        console.warn("you tried to go back in history!");
        return;
      }
      await saveProject(backState.project);

      return saveFloor({
        settings: backState.rendererSettings,
        buildingID,
        floorID,
        strategyID,
        dontAddToHistoryStack: true,
        dontSaveProject: true,
      });
    },
    [saveFloor, saveProject]
  );

  const historyGoForward = useCallback(
    async ({ buildingID, floorID, strategyID }) => {
      const forwardState = await getHistoryForwardState();
      if (forwardState === null) {
        console.warn("you tried to go forward in history!");
        return;
      }
      await saveProject(forwardState.project);

      return saveFloor({
        settings: forwardState.rendererSettings,
        buildingID,
        floorID,
        strategyID,
        dontAddToHistoryStack: true,
        dontSaveProject: true,
      });
    },
    [saveFloor, saveProject]
  );

  // Initialize state, either from backend, or initialize an empty state
  useEffect(() => {
    if (
      !(window.location.pathname || "").match(/\/.+\/building\/.+\/\d/)?.[0]
    ) {
      return;
    }
    const setSettings = async () => {
      if (!initialState) {
        throw new Error("no initial state, we didnt think this would happen");
      }
      const { buildingID, strategyID, floorID, projectID } = initialState;
      const data = await getSettingsFile(
        projectID,
        buildingID,
        floorID,
        strategyID
      );
      const possibleDefaultCirc = await getFloorCirculation(
        buildingID,
        floorID
      );
      if (!data) {
        // The user has never saved their settings on this floor before!
        // Need to create settings from scratch and initialize history with them
        await saveFloor({
          ...initialState,
          settings: {
            ...emptySettingsFactory(),
            circulation: possibleDefaultCirc,
          },
          initializeHistory: true,
        });
      } else {
        // Initialize history, but with the settings from the backend
        await saveFloor({
          ...initialState,
          settings: {
            ...data,
            circulation: data.circulation || possibleDefaultCirc,
          },
          initializeHistory: true,
        });
      }
      setCurrentCalculatedBuzz([]);
    };
    if (
      !prevState ||
      prevState?.floorID !== initialState?.floorID ||
      prevState?.buildingID !== initialState?.buildingID
    ) {
      setSettings();
    }
  }, [initialState, saveFloor, setCurrentCalculatedBuzz, prevState]);

  const duplicateSettings = async ({
    buildingID,
    floorIDs,
    strategyID,
    newStrategyID,
    projectID,
  }: CommonSettingsArgs & {
    floorIDs: string[];
    newStrategyID: string;
  }) => {
    for (let i = 0; i < floorIDs.length; i++) {
      const fc = floorIDs[i];

      const settings = await getSettingsFile(
        projectID,
        buildingID,
        fc,
        strategyID
      );
      if (settings) {
        await saveFloor({
          settings,
          buildingID,
          floorID: fc,
          strategyID: newStrategyID,
        });
      }
    }
  };

  const resetSettings = async ({
    buildingID,
    strategyID,
    projectID,
    availableFloors,
  }: CommonSettingsArgs & {
    availableFloors: string[];
  }) => {
    setCurrentSettings(emptySettingsFactory());
    await Promise.all(
      availableFloors.map((fc) =>
        deleteSettingsFile(projectID, buildingID, fc, strategyID)
      )
    );
  };

  return {
    currentSettings,
    saveFloor,
    resetSettings,
    duplicateSettings,
    calculatedBuzz: currentCalculatedBuzz,
    setCalculatedBuzz: setCurrentCalculatedBuzz,
    historyGoBack,
    historyGoForward,
    getHistoryBackEnabled,
    getHistoryForwardEnabled,
    updateAllFloorMetrics,
    updateBuildingMetrics,
  };
};

export const SettingsController = createContainer(useSettingsState);
export const SettingsProvider: ComponentType<
  ContainerProviderProps<InitialState>
> = SettingsController.Provider;
export const useSettingsCtrl = () => SettingsController.useContainer();
