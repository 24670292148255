import * as React from "react";
import { makeStyles } from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import TextField from "./textfield";
import Chip from "./chip";

type TagsProps = {
  options: string[];
  onChange: (tags: string[]) => void;
  value: string[];
};

type Tag = { value: string; inputValue?: string };
const filter = createFilterOptions<Tag>();

const useStyles = makeStyles(() => ({
  root: {
    "& button": {
      marginRight: 8,
    },
  },
}));

const Tags = ({ value, options, onChange }: TagsProps) => {
  const classes = useStyles();
  return (
    <Autocomplete
      multiple
      classes={{ root: classes.root }}
      options={options.map((option) => ({ value: option })) as Tag[]}
      getOptionLabel={(option) => option.value}
      getOptionSelected={(option, _value) => option.value === _value.value}
      onChange={(e, _value) => {
        if (!Array.isArray(_value)) {
          onChange([_value]);
        } else {
          onChange(_value.map((el) => el.inputValue || el.value));
        }
      }}
      value={value.map((_value) => ({ value: _value })) as Tag[]}
      filterOptions={(_options, params) => {
        const filtered = filter(_options, params);
        // Suggest the creation of a new value
        if (params.inputValue !== "") {
          filtered.push({
            inputValue: params.inputValue,
            value: `Add "${params.inputValue}"`,
          });
        }
        return filtered;
      }}
      filterSelectedOptions={true}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((option, index) => (
          <Chip {...getTagProps({ index })} label={option.value} />
        ));
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={Object.assign(
            { shrink: true },
            params.InputLabelProps
          )}
          InputProps={Object.assign(
            {
              disableUnderline: true,
              style: {
                background: "#F4F4F6",
                borderRadius: "4px",
                paddingLeft: "8px",
              },
            },
            params.InputProps
          )}
          label="Tags"
        />
      )}
    />
  );
};

export default Tags;
