import axios from "axios";
import jwt_decode from "jwt-decode";

import config from "../../config";
import { getAuthToken, setAuthToken } from "../storage/session";

export interface Claims {
  exp: number;
  sessionId: string;
  name: string;
  emailAddress: string;
  picture: string;
  profileId: string;
  groups: string[];
}

interface AuthHeader {
  [Key: string]: string;
}

export const authorizeUser = async (username: string, password: string) => {
  let isAuthed = false;

  await axios
    .post(`${config.baseUrl}/api/auth/`, { username, password })
    .then((resp) => {
      if (!resp.data.token) {
        isAuthed = false;
        return;
      }

      setAuthToken(resp.data.token);
      isAuthed = true;
    })
    .catch(() => (isAuthed = false));

  return isAuthed;
};

export const getAuthHeader = async (): Promise<AuthHeader> => {
  const token = getAuthToken();
  if (!token) return {};

  const tokenData: Claims = jwt_decode(token);
  const now = new Date();
  const currentUTCSeconds = Math.floor(now.getTime() / 1000);
  if (tokenData.exp - currentUTCSeconds <= 600) {
    const refreshedToken = await refreshToken(token);
    if (refreshedToken)
      return {
        Authorization: `Bearer ${refreshedToken}`,
      };
    else return {};
  }

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  return headers;
};

export const isAuthTokenValid = () => {
  const token = getAuthToken();
  if (!token) return false;
  const tokenData: Claims = jwt_decode(token);
  const now = new Date();
  const currentUTCSeconds = Math.floor(now.getTime() / 1000);
  if (tokenData.exp - currentUTCSeconds > 10) {
    return true;
  } else {
    return Promise.resolve(refreshToken(token)).then(
      (refreshedToken) => !!refreshedToken
    );
  }
};

export const getUserDetails = (): Claims | undefined => {
  const token = getAuthToken();
  if (!token) return undefined;

  const tokenData: Claims = jwt_decode(token);
  return tokenData;
};

export const refreshToken = async (
  currentToken: string | null
): Promise<string> =>
  axios
    .get(`${config.baseUrl}/api/auth/refresh`, {
      headers: {
        Authorization: `Bearer ${currentToken}`,
      },
    })
    .then((resp) => {
      setAuthToken(resp.data.token);
      return resp.data.token;
    })
    .catch((error) => {
      if (error.response && error.response.status === 401 && window !== null) {
        window.location.href = "/login";
      }
      return "";
    });
