import React from "react";
import { Canvas } from "@outerlabs/canvas-reconciler";
import { renderCirculationPath } from "lib/isp-canvas";
import { Coordinate, RendererSettings, RoomBounds } from "lib/types";
import { Graph } from "lib/metrics/buzz";
import {
  renderSelectedWalkPoints,
  renderWalkCirculationPath,
} from "lib/isp-canvas/featureRenderer";

interface Props {
  settings?: RendererSettings;
  defaultCirculation?: Graph;
  walkabilityOutput?: {
    seatCoords: Coordinate;
    distance: number;
    feature: RoomBounds | undefined;
    path: Coordinate[];
  }[];
  selected: string;
  metric?: string;
}

export const WalkabilityOutput: React.FC<Props> = ({
  settings,
  defaultCirculation,
  walkabilityOutput,
  selected,
}) => {
  const canvas = (
    <Canvas
      render={(ctx: CanvasRenderingContext2D) => {
        if (settings && settings.circulation) {
          renderCirculationPath(ctx, settings.circulation, true);
        } else if (defaultCirculation) {
          renderCirculationPath(ctx, defaultCirculation, true);
        }

        if (walkabilityOutput) {
          renderSelectedWalkPoints(ctx, walkabilityOutput, 40, selected);
        }
        if (walkabilityOutput && selected === "") {
          renderWalkCirculationPath(ctx, walkabilityOutput);
        }
      }}
    />
  );

  return <>{canvas}</>;
};

export default React.memo(WalkabilityOutput);
