import { Block } from "../lib/types";
import { getters } from "../lib/constants";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { getBlockMinMaxDimensions } from "../lib/blocks";
import { round } from "../../lib/number";
import { formatInches } from "../lib/canvas";

const useStyles = makeStyles(() => ({
  item: {
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    padding: 10,
    transition: "0.2s ease-in",
    borderBottom: "1px solid #e8e8e8",
    margin: 8,
    background: "white",
  },
  active: {
    background: "#f0f0f0",
  },
  title: {
    fontSize: "1rem",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
  },
  image: {
    height: 40,
    width: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 16,
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    fontSize: "0.8rem",
  },
  property: {
    display: "flex",
    width: "180px",
  },
  fieldTitle: {
    paddingRight: "5px",
    paddingBottom: "0px",
    fontWeight: "bold",
  },
}));

const calculateSize = (block: Block) => {
  const { sizeRange } = getters.getMetrics(block);
  return `${formatInches(sizeRange[0][0])} - ${formatInches(sizeRange[1][0])}`;
};

const calculateArea = (block: Block) => {
  const coordinate = getBlockMinMaxDimensions(block);
  const minArea = round((coordinate[0][0] / 12) * (coordinate[1][0] / 12), 1);
  const maxArea = round((coordinate[0][1] / 12) * (coordinate[1][1] / 12), 1);
  return `${minArea} ft² to ${maxArea} ft²`;
};

const BlockItem = ({
  block,
  image,
  onClick,
  showSize = true,
  showArea = false,
  showLibrary = false,
  showSeats = false,
  active = false,
}: {
  block: Block;
  image: string | null;
  onClick: (id: string) => void;
  showSize?: boolean;
  showArea?: boolean;
  showLibrary?: boolean;
  showSeats?: boolean;
  active?: boolean;
}) => {
  const classes = useStyles();
  const { seatsRange } = getters.getMetrics(block);
  const { name, library } = getters.getDefinition(block);
  return (
    <div
      className={`${classes.item} ${active ? classes.active : ""}`}
      onClick={() => onClick(block.id)}
    >
      <div className={classes.image}>
        {image && <img src={image} alt={block.props.name} />}
      </div>
      <div className={classes.content}>
        <div className={classes.title}>{name}</div>
        {showLibrary && (
          <div className={classes.property}>
            <div className={classes.fieldTitle}>Library Name: </div>
            <div>{library.length === 0 ? "No library" : library}</div>
          </div>
        )}
        {showSeats && (
          <div className={classes.property}>
            <div className={classes.fieldTitle}>Range of Seats: </div>
            <div>
              {seatsRange[0] || 0} - {seatsRange[1] || 0} seats
            </div>
          </div>
        )}
        {showArea && (
          <div className={classes.property}>
            <div className={classes.fieldTitle}>Area: </div>
            <div>{calculateArea(block)}</div>
          </div>
        )}
        {showSize && (
          <div className={classes.property}>
            <div className={classes.fieldTitle}>Size: </div>
            <div>{calculateSize(block)}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BlockItem;
