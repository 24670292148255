import axios from "axios";
import { setupCache } from "axios-cache-adapter";
import config from "config";
import { getAuthHeader } from "lib/auth";

const cache = setupCache({
  maxAge: 30 * 60 * 1000,
});

export const getFloorRegions = async (
  buildingID: string,
  floorID: string
): Promise<number[][][] | undefined> => {
  try {
    const headers = await getAuthHeader();
    const api = axios.create({
      headers,
      adapter: cache.adapter,
    });

    const data = await api.get(
      `${config.baseUrl}/api/planning/buildings/${buildingID}/${floorID}/regions`
    );
    const keys = Object.keys(data.data);
    if (keys.length === 1 && keys[0] === "regions") return data.data.regions;
    return data.data;
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

export default getFloorRegions;
