import React, { useMemo, useRef } from "react";
import { CanvasReconciler } from "@outerlabs/canvas-reconciler";
import { Background, Xform } from "components/isp-canvas";
import {
  useRendererCtrl,
  useFeaturesCtrl,
  useBackgroundCtrl,
} from "lib/containers";
import { filterBackground } from "lib/isp-canvas/utils";
import { createHiDPICanvas } from "../blocks/lib/util";

interface Props {
  width: number;
  height: number;
}

export const ISPCanvasMiddle: React.FC<Props> = ({ width, height }) => {
  const middleCanvasRef = useRef<HTMLCanvasElement | null>();
  const { xform, offsetX, offsetY } = useRendererCtrl();
  const { currentFeatures } = useFeaturesCtrl();
  const { currentBackground } = useBackgroundCtrl();
  const filteredBackground = useMemo(() => {
    if (currentFeatures)
      return filterBackground(
        currentBackground,
        currentFeatures.floorplate,
        offsetX || 0,
        offsetY || 0
      );
  }, [offsetX, offsetY, currentBackground, currentFeatures]);

  // Use setTimeout so that HTML render completes immediately
  setTimeout(() => {
    if (middleCanvasRef.current && xform) {
      const canvas = middleCanvasRef.current;
      createHiDPICanvas(canvas, width, height);
      CanvasReconciler.render(
        <>
          <Xform xform={xform}>
            <Background
              background={filteredBackground}
              offsetX={offsetX}
              offsetY={offsetY}
            />
          </Xform>
        </>,
        canvas
      );
    }
  }, 0);
  if (!filteredBackground) return null;

  return (
    <>
      <canvas
        width={width}
        height={height}
        style={{ position: "absolute", top: 0 }}
        ref={(el) => (middleCanvasRef.current = el)}
      />
    </>
  );
};

export default React.memo(ISPCanvasMiddle);
