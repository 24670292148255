import axios from "axios";
import config from "config";
import { getAuthHeader } from "lib/auth";
import { Graph } from "lib/metrics/buzz";

export const getFloorCirculation = async (
  buildingID: string,
  floorID: string
): Promise<Graph | undefined> => {
  try {
    const headers = await getAuthHeader();
    const api = axios.create({
      headers,
    });

    const data = await api.get(
      `${config.baseUrl}/api/planning/buildings/${buildingID}/${floorID}/circulation`
    );
    return data.data;
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

export const putFloorCirculation = async (
  buildingID: string,
  floorID: string,
  content: Graph
): Promise<void> => {
  try {
    const headers = await getAuthHeader();
    const api = axios.create({
      headers,
    });

    await api.put(
      `${config.baseUrl}/api/planning/buildings/${buildingID}/${floorID}/circulation`,
      content
    );

    return;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export default getFloorCirculation;
