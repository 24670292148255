import { LayerName, LayerType } from "../types";

// Required
//
// A-BACKGROUND
// A-FLOOR-AREA
// MTG-ROOMS-HUDDLES
// MTG-ROOMS-HUDDLES-MINI
// MTG-ROOMS-XL
// MTG-ROOMS-LARGE
// MTG-ROOMS-MEDIUM
// MTG-ROOMS-SMALL
// MTG-ROOMS-PHONEBOOTHS
// MTG-ROOMS-PODS
//
// Optional
//
// Recommended:
// A-TEAMSPACE
// A-DESKS
// A-COLS-E
// A-FLORS-STRS-E
// A-GLAZ-EXTR-E
// S-GRID

export const portfolioLayers: LayerName[] = [
  {
    name: "A-BACKGROUND",
    display: "Structural Grid",
    type: LayerType.background,
    required: true,
  },
  {
    name: "A-FLOOR-AREA",
    altName: "FLOOR AREA",
    display:
      "A closed polyline tracing the entire floor area (inside of exterior walls)",
    type: LayerType.floorArea,
    required: true,
  },
  {
    name: "MTG-ROOMS-HUDDLES",
    altName: "MEETING ROOMS HUDDLE",
    display: "Huddle rooms",
    type: LayerType.rooms,
    required: false,
  },
  {
    name: "MTG-ROOMS-HUDDLES-MINI",
    altName: "MEETING ROOMS HUDDLE MINI",
    display: "Mini huddle rooms",
    type: LayerType.rooms,
    required: false,
  },
  {
    name: "MTG-ROOMS-XL",
    altName: "MEETING ROOMS HUDDLE",
    display: "Extra large meeting rooms",
    type: LayerType.rooms,
    required: false,
  },
  {
    name: "MTG-ROOMS-LARGE",
    altName: "MEETING ROOMS LARGE",
    display: "Large meeting rooms",
    type: LayerType.rooms,
    required: false,
  },
  {
    name: "MTG-ROOMS-MEDIUM",
    altName: "MEETING ROOMS MEDIUM",
    display: "Medium meeting rooms",
    type: LayerType.rooms,
    required: false,
  },
  {
    name: "MTG-ROOMS-SMALL",
    altName: "MEETING ROOMS SMALL",
    display: "Small meeting rooms",
    type: LayerType.rooms,
    required: false,
  },
  {
    name: "MTG-ROOMS-PHONEBOOTHS",
    altName: "MEETING ROOMS PHONE BOOTH",
    display: "Phone booths",
    type: LayerType.rooms,
    required: false,
  },
  {
    name: "MTG-ROOMS-PODS",
    altName: "MEETING ROOMS PODS",
    display: "Floating pods (Orangebox or Spacestor type)",
    type: LayerType.rooms,
    required: false,
  },
  {
    name: "A-TEAMSPACE (opt)",
    display: "Closed polylines tracing the team space regions",
    type: LayerType.space,
    required: false,
  },
  {
    name: "A-DESKS",
    display: "Desks (opt)",
    type: LayerType.desk,
    required: false,
  },
  {
    name: "A-COLS-E",
    display: "Columns (opt)",
    type: LayerType.columns,
    required: false,
  },
  {
    name: "A-FLORS-STRS-E",
    display: "Stairs (opt)",
    type: LayerType.stairs,
    required: false,
  },
  {
    name: "A-GLAZ-EXTR-E",
    display: "Exterior Glazing (opt)",
    type: LayerType.glazing,
    required: false,
  },
  {
    name: "A-ROOF-SKLG-E",
    display: "Skylights (opt)",
    type: LayerType.skylights,
    required: false,
  },
  {
    name: "S-GRID",
    display: "Structural Grid (opt)",
    type: LayerType.grid,
    required: false,
  },
];
