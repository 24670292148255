/* eslint-disable no-console */

import { Button } from "@material-ui/core";
import React from "react";
import { Add } from "@material-ui/icons";

const Upload = ({
  id,
  label,
  color = "primary",
  onChange,
  accept = "application/JSON",
}: {
  id: string;
  label: string;
  onChange: (name: string, data: string) => void;
  accept: string;
  color: "primary" | "default";
}) => {
  const handleFile = (e: any) => {
    const files: File[] = Array.from(e.target.files);
    console.log("e", e, files);
    files.forEach((file: File) => {
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        const binaryStr = reader.result;
        const t = new TextDecoder("utf-8");
        let s = "";
        if (typeof binaryStr === "string") {
          s = binaryStr;
        } else if (binaryStr) {
          s = t.decode(new Uint8Array(binaryStr));
        }
        onChange(file.name.split(".")[0], s);
      };
      reader.readAsArrayBuffer(file);
    });
  };
  return (
    <>
      <input
        accept={accept}
        id={id}
        type="file"
        style={{ display: "none" }}
        onChange={handleFile}
      />
      <label htmlFor={id}>
        <Button
          color={color}
          variant="contained"
          aria-label="upload picture"
          component="span"
          style={{
            width: "auto",
            height: "48px",
            padding: "0 16px",
            margin: "0 8px",
            minWidth: "48px",
            minHeight: "auto",
            borderRadius: "24px",
            boxShadow:
              "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)",
          }}
        >
          <Add />
          <div style={{ marginLeft: 8 }}>{label}</div>
        </Button>
      </label>
    </>
  );
};

export default Upload;
