import Drawing, { Block as DXFBlock, Insert as DXFInsert } from "./dxf";
import getBackground from "./api/getBackground";
import { getSettingsFile } from "./api/rendererSettings";
import { encode } from "./dxf/utils";
import { Coordinate, Drawable, PortfolioBlockInstance } from "./types";
import { Block, BlockGetter, StyleProps } from "../blocks/lib/types";
import { toRegion } from "./isp-canvas/utils";
import { mat4, quat, vec3 } from "gl-matrix";
import {
  dividerLengths,
  dividerWidths,
  getters,
} from "../blocks/lib/constants";
import { Geometry, Point } from "@outerlabs/shapes-geometry";
import { Shape } from "./dxf/shape";
import { UseBuildingsType } from "./containers";
import getFeatures from "./api/getFeatures";
import { Path } from "./dxf/path";

export const dxfExport = async ({
  buildings,
  buildingID,
  projectID,
  strategyID,
  getBlockById,
}: {
  buildingID: string;
  projectID: string;
  strategyID: string;
  buildings: UseBuildingsType;
  getBlockById(id: string | undefined): Block | undefined;
}) => {
  const bldg = buildings.buildings[buildingID];
  const d = new Drawing();
  d.addLineType("CONTINUOUS", "------", []);

  const deskSize = 18;
  const deskRadius = 10;
  d.addBlock("desk");
  // prettier-ignore
  d.addBlockShape("desk", { type: "roundedRectangle", points: [[-deskSize, -deskSize], [deskSize, deskSize]], radius: [deskRadius,deskRadius,deskRadius,deskRadius] });

  await Promise.all(
    bldg.AvailableFloors.map(async (floor) => {
      const features = await getFeatures(buildingID, floor);
      const background = await getBackground(buildingID, floor);
      // background
      if (background) {
        const l = `level-${floor}-background`;
        d.addLayer(l, 7, "CONTINUOUS", 1, true);
        Object.keys(background.inserts).forEach((k) => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const insert = background!.inserts[k];
          d.addBlock(k);
          insert.forEach((el) => {
            d.addBlockShape(k, convert(el));
          });
        });
        background.elements.forEach((el) => {
          d.addLayerShape(l, convert(el));
        });
      }
      const settings = await getSettingsFile(
        projectID,
        buildingID,
        floor,
        strategyID
      );
      const blocksLayer = `level-${floor}-blocks`;
      const desksLayer = `level-${floor}-desks`;
      d.addLayer(blocksLayer, 2, "CONTINUOUS", 1);
      d.addLayer(desksLayer, 4, "CONTINUOUS", 1);
      if (settings) {
        // blocks
        const instances = settings?.blocks ? settings.blocks : [];
        if (!instances || instances?.length === 0) return;
        await Promise.all(
          instances.map(async (newInstances) => {
            await convertPortfolioBlockInstance({
              instances: newInstances,
              getBlockById,
              drawing: d,
              layer: blocksLayer,
            });
          })
        );
        // desks
        if (settings.desks && settings?.desks?.keptCenters?.length > 0) {
          settings.desks?.keptCenters.forEach((c) => {
            d.addLayerShape(desksLayer, {
              type: "insert",
              ref: encode("desk"),
              r: 0,
              x: c.x - deskSize,
              y: c.y - deskSize,
              xs: 1,
              ys: 1,
            });
          });
        }
      } else {
        features.uniqueDesks.forEach((c: Coordinate) => {
          d.addLayerShape(desksLayer, {
            type: "insert",
            ref: encode("desk"),
            r: 0,
            x: c.x - deskSize,
            y: c.y - deskSize,
            xs: 1,
            ys: 1,
          });
        });
      }
    })
  );

  const s = d.toDXFString();

  const a = document.createElement("a");

  a.style.display = "none";
  a.style.height = "0px";
  a.style.width = "0px";
  a.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(s)
  );
  a.setAttribute("download", "export.dxf");
  a.click();

  URL.revokeObjectURL(a.href);
};

export const convertPortfolioBlockInstance = ({
  layer,
  instances,
  getBlockById,
  drawing,
}: {
  instances: PortfolioBlockInstance[];
  getBlockById: BlockGetter;
  drawing: Drawing;
  layer: string;
}) => {
  if (!instances || instances?.length === 0) return;
  const region = toRegion(instances[0]);
  if (region && region.length > 0) {
    instances.forEach((instance: PortfolioBlockInstance) => {
      convertBlockInstance(drawing, instance, mat4.create(), getBlockById);
      const layout = getters.getLayout(instance);
      const metrics = getters.getMetrics(instance);
      if (instance.matrix) {
        drawing.layers[layer].shapes.push(
          convertInstance(
            instance.id,
            instance.matrix,
            layout.mirrorX,
            layout.mirrorY,
            metrics.size
          )
        );
      }
    });
  }
};

export const convertBlockInstance = (
  drawing: Drawing,
  instance: Block,
  mat: mat4,
  getBlockById: BlockGetter
) => {
  drawing.addBlock(instance.id);
  const db = drawing.blocks[encode(instance.id)];
  const m = mat4.create();
  if (!instance.children.length) {
    if (instance.role === "asset-instance")
      convertAsset(drawing, db, instance, getBlockById, m);
  } else {
    instance.children.forEach((child) => {
      if (child.role === "asset-instance") {
        convertAsset(drawing, db, child, getBlockById, m);
      } else {
        convertSubBlockInstance(drawing, db, child, m, getBlockById);
      }
    });
  }
};

export const convertSubBlockInstance = async (
  drawing: Drawing,
  dxfBlock: DXFBlock,
  instance: Block,
  m: mat4,
  getBlockById: BlockGetter
) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const mm = mat4.mul(mat4.create(), m, instance.matrix!);
  convertDividers(drawing, dxfBlock, instance, mm);
  instance.children.forEach((child) => {
    if (child.role === "asset-instance") {
      convertAsset(drawing, dxfBlock, child, getBlockById, mm);
    } else {
      convertSubBlockInstance(drawing, dxfBlock, child, mm, getBlockById);
    }
  });
};

const convertInstance = (
  name: string,
  mat: mat4,
  mirrorX = false,
  mirrorY = false,
  size: number[] = [0, 0]
): DXFInsert => {
  const s = mat4.getScaling(vec3.create(), mat);
  const q = mat4.getRotation(quat.create(), mat);
  let angle = quat.getAxisAngle(vec3.fromValues(0, 0, 1), q);
  if (q[2] < 0) angle = -angle;
  const pos = [mat[12] / s[0], mat[13] / s[1]];
  let px = pos[0];
  let py = pos[1];
  if (mirrorX && mirrorY) {
    mirrorX = false;
    mirrorY = false;
    px += -(size[1] * Math.sin(angle)) + size[0] * Math.cos(angle);
    py += size[1] * Math.cos(angle) + size[0] * Math.sin(angle);
    angle += Math.PI;
  } else if (mirrorY) {
    px -= size[1] * Math.sin(angle);
    py += size[1] * Math.cos(angle);
  } else if (mirrorX) {
    px -= size[0] * Math.sin(angle);
    py += size[0] * Math.cos(angle);
  }

  return {
    type: "insert",
    ref: encode(name),
    r: angle * (180 / Math.PI),
    x: px,
    y: py,
    xs: s[0] * (mirrorX ? -1 : 1),
    ys: s[1] * (mirrorY ? -1 : 1),
  };
};

export const convertAsset = (
  drawing: Drawing,
  dxfBlock: DXFBlock,
  assetInst: Block,
  getBlockById: BlockGetter,
  mat: mat4
) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const m = mat4.mul(mat4.create(), mat || mat4.create(), assetInst.matrix!);
  if (assetInst?.children?.length) {
    assetInst.children.forEach((child) => {
      convertAsset(drawing, dxfBlock, child, getBlockById, m);
    });
  } else if (assetInst.symbol) {
    const base = getBlockById(assetInst.symbol);
    if (base && !drawing.blocks[encode(assetInst.symbol)]) {
      drawing.addBlock(assetInst.symbol);
      convertAsset(
        drawing,
        drawing.blocks[encode(assetInst.symbol)],
        base,
        getBlockById,
        mat4.create()
      );
    }
    dxfBlock.shapes.push(convertInstance(assetInst.symbol, m));
  } else {
    const id = Math.random().toString(32).slice(2, 10);
    drawing.addBlock(id);
    const db = drawing.blocks[encode(id)];
    const style = getters.getStyle(assetInst);
    if (assetInst.props.paths) {
      db.shapes.push(
        ...assetInst.props.paths.map((path) => ({ type: "path", path } as Path))
      );
    } else convertGeometry(drawing, db, assetInst.geometry, style);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    dxfBlock.shapes.push(convertInstance(id, assetInst.matrix!));
  }
};

export const convertGeometry = (
  drawing: Drawing,
  dxfBlock: DXFBlock,
  geometry: Geometry,
  style: StyleProps
) => {
  let points: Point[] | undefined;
  switch (geometry.type) {
    case "box":
      points = geometry?.box;
      if (points) {
        if (style.radius) {
          dxfBlock.shapes.push({
            type: "roundedRectangle",
            points,
            radius: style.radius,
          });
        } else {
          dxfBlock.shapes.push({ type: "rectangle", points });
        }
      }
      break;
    case "ellipse":
      points = geometry?.box;
      if (points) {
        const hw = Math.abs(points[1][0] - points[0][0]) / 2;
        const hh = Math.abs(points[1][1] - points[0][1]) / 2;
        dxfBlock.shapes.push({
          type: "ellipse",
          x: hw,
          y: hh,
          rx: hw,
          ry: hh,
        });
      }
      break;
    case "polygon":
      // eslint-disable-next-line no-case-declarations
      const paths = geometry?.polygon;
      if (paths)
        paths.forEach((path) => {
          path.push([...path[0]]);
          dxfBlock.shapes.push({
            type: "polyline",
            points: path.map((p) => [p[0], p[1]]),
          });
        });
      break;
    default:
      break;
  }
};

export const convertDividers = (
  drawing: Drawing,
  db: DXFBlock,
  instance: Block,
  mat: mat4
) => {
  const sides = instance.props.sides;
  if (sides && sides?.dividers) {
    sides.dividers.forEach((s, i) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const side = s!;
      if (side.type !== "none") {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const dividerWidth = dividerWidths[side.type!];
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const dividerLength = dividerLengths[side.type!];
        const size = instance.props.metrics?.size || [0, 0];
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const fullLength = size[i % 2]!;
        let length = fullLength;
        let offset = 0;
        if (side.mode === "auto") {
          offset = side.start || 0;
          length = length - offset - (side.end || 0);
        } else {
          length = side.size || 12;
          offset =
            side.align === "min"
              ? 0
              : side.align === "max"
              ? fullLength - length
              : (fullLength - length) / 2;
        }
        const direction = i % 2 === 1;
        const pos: [number, number] =
          i === 0
            ? [offset, 0]
            : i === 1
            ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              [size[0]! - dividerWidth, offset]
            : i === 2
            ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              [offset, size[1]! - dividerWidth]
            : [0, offset];

        const id = Math.random().toString(32).slice(2, 10);
        drawing.addBlock(id);
        const dbb = drawing.blocks[encode(id)];
        dbb.shapes = dbb.shapes.concat(
          convertSegmentedDivider(
            length,
            dividerWidth,
            dividerLength,
            direction,
            pos
          )
        );
        db.shapes.push(convertInstance(id, mat));
      }
    });
  }
};

export const convertSegmentedDivider = (
  length: number,
  thickness: number,
  segmentLength: number,
  vertical = false,
  pos: [number, number] = [0, 0]
): Shape[] => {
  const numSegments = Math.floor(length / segmentLength);
  const remainder = length - numSegments * segmentLength;
  const out: Shape[] = [];
  if (vertical) {
    for (let i = 0; i < numSegments; i++) {
      out.push({
        type: "rectangle",
        points: [pos, [pos[0] + thickness, pos[1] + segmentLength]],
      });
      pos[1] += segmentLength;
    }
    out.push({
      type: "rectangle",
      points: [pos, [pos[0] + thickness, pos[1] + remainder]],
    });
  } else {
    for (let i = 0; i < numSegments; i++) {
      out.push({
        type: "rectangle",
        points: [pos, [pos[0] + segmentLength, pos[1] + thickness]],
      });
      pos[0] += segmentLength;
    }
    out.push({
      type: "rectangle",
      points: [pos, [pos[0] + remainder, pos[1] + thickness]],
    });
  }
  return out;
};

const convert = (a: Drawable): Shape => {
  if (!a?.coordinates || a.coordinates?.length === 0) {
    return { type: "polyline", points: [] };
  }
  switch (a.type) {
    case "INSERT":
      if (a.insertName.toLowerCase().includes("mullion")) break;
      return {
        type: "insert",
        name: a.insertName,
        r: a.coordinates[0].angle || 0,
        x: a.coordinates[0].x || 0,
        y: a.coordinates[0].y || 0,
        ref: encode(a.insertName),
      };
    case "LINE":
      return {
        type: "line",
        x1: a.coordinates[0].x || 0,
        y1: a.coordinates[0].y || 0,
        x2: a.coordinates[1].x || 0,
        y2: a.coordinates[1].y || 0,
      };
    case "LWPOLYLINE":
      return {
        type: "polyline",
        points: a.coordinates.map((el) => [el.x, el.y]),
      };
    case "ARC":
      return {
        type: "arc",
        x: a.coordinates[0].x,
        y: a.coordinates[0].y,
        r: a.coordinates[0].radius || 0,
        startAngle: a.coordinates[0].angle || 0,
        endAngle: a.coordinates[0].endAngle || 0,
      };
    case "CIRCLE":
      return {
        type: "circle",
        x: a.coordinates[0].x,
        y: a.coordinates[0].y,
        r: a.coordinates[0].radius || 0,
      };
    // case "MTEXT":
    //   return {
    //     type: "text",
    //     value: a.coordinates[0].
    //   };
    default:
      break;
  }
  return { type: "polyline", points: [] };
};
