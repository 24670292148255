import React, { useState } from "react";
// later versions of this break icons
// eslint-disable-next-line import/no-extraneous-dependencies
import { makeStyles } from "@material-ui/styles";
import IconButton from "@material-ui/core/IconButton";
import { defaultValues } from "lib/constants";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import ShareIcon from "@material-ui/icons/Share";
import Welcome from "components/welcome";
import { Menu, MenuItem, styled } from "@material-ui/core";
import { useHelpguideCtrl } from "@outerlabs/helpguide";

export interface Props {
  subTitle?: string;
  ProjectTitle?: any;
  UserAvatar?: any;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  divider?: boolean;
  onShare?: (event: React.MouseEvent<HTMLElement>) => void;
}

const useStyles = makeStyles({
  headerContainer: {
    background: "white",
    width: "100%",
    height: "75px",
    position: "absolute",
    backgroundColor: "white",
    top: "0px",
    left: "0px",
    zIndex: 100,
  },
  contentContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    height: "75px",
  },
  title: {
    fontFamily: "GoogleSansMedium",
    color: "#4A4A4A",
    fontSize: "20px",
    width: "auto",
    height: "25px",
  },
  subTitle: {
    fontFamily: "GoogleSansMedium",
    color: "#00000050",
    fontSize: "14.5px",
    width: "auto",
    letterSpacing: "1.25px",
  },
  leftContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    background: "transparent",
    width: "auto",
    marginLeft: "80px",
  },
  leftTitleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    background: "transparent",
    width: "auto",
    position: "relative",
    top: "-4px",
  },
  leftSubtitleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    background: "transparent",
    width: "auto",
    marginTop: "0px",
  },
  rightContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    background: "transparent",
    width: "auto",
    marginRight: "20px",
  },
  rightActionIconsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: "10px",
  },
  avatarContainer: {
    width: "32px",
    height: "32px",
    borderRadius: "50%",
    marginLeft: "40px",
  },
  actionIcon: {
    width: "20px",
    height: "20px",
    color: "#555555",
  },
});

const StyledMenuItem = styled(MenuItem)({
  padding: "16px 24px",
});

const TopNavBar: React.FC<Props> = ({
  subTitle,
  ProjectTitle,
  UserAvatar,
  divider,
  onShare,
}) => {
  const [showWelcome, setShowWelcome] = useState(false);

  const classes = useStyles();
  const { feedbackFormURL } = defaultValues;
  const handleFeedbackClick = () => {
    const win = window.open(feedbackFormURL, "_blank");
    if (win) win.focus();
  };
  const onHelpClick = () => {
    setShowWelcome(true);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const helpOpen = Boolean(anchorEl);
  const onMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleHelpClose = () => {
    setAnchorEl(null);
  };

  const { goToKeyboardShortcuts, goToHome } = useHelpguideCtrl();

  return (
    <>
      <div className={classes.headerContainer}>
        <div className={classes.contentContainer}>
          <div className={classes.leftContainer}>
            {ProjectTitle && (
              <div className={classes.leftTitleContainer}>
                <div className={classes.title}>{ProjectTitle}</div>
              </div>
            )}
            {subTitle && (
              <div className={classes.leftSubtitleContainer}>
                <div className={classes.subTitle}> {subTitle}</div>
              </div>
            )}
          </div>
          <div className={classes.rightContainer}>
            <div className={classes.rightActionIconsContainer}>
              {onShare && (
                <IconButton onClick={onShare}>
                  <ShareIcon className={classes.actionIcon} />
                </IconButton>
              )}
              <IconButton onClick={onMenuClick}>
                <HelpOutlineIcon className={classes.actionIcon} />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={helpOpen}
                onClose={handleHelpClose}
              >
                <StyledMenuItem
                  onClick={() => {
                    onHelpClick();
                    handleHelpClose();
                  }}
                >
                  Welcome
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={() => {
                    goToKeyboardShortcuts();
                    handleHelpClose();
                  }}
                >
                  Keyboard Shortcuts
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={() => {
                    goToHome();
                    handleHelpClose();
                  }}
                >
                  Help Guide
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={() => {
                    handleFeedbackClick();
                    handleHelpClose();
                  }}
                >
                  Give Feedback
                </StyledMenuItem>
              </Menu>
            </div>
            <div className={classes.avatarContainer}>{UserAvatar}</div>
            {divider ? (
              <div
                style={{
                  position: "relative",
                  right: "49px",
                  height: "72px",
                  borderLeft: "1px solid #E4E4E4 ",
                }}
              />
            ) : null}
          </div>
        </div>
      </div>
      <Welcome
        open={showWelcome}
        page={0}
        onClose={() => setShowWelcome(false)}
      />
    </>
  );
};

export default React.memo(TopNavBar);
