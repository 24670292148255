import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { Cell, cellStyles, actionStyles } from "./cells";
import { DisplayTableRowMetric } from "lib/types";
import { Order } from "./table";
import { Editing, Columns } from "./index";

import Backdrop from "@material-ui/core/Backdrop";

import {
  HelpguideHandle,
  HelpguideWrapperDiv,
  HelpguideWrapperSpan,
} from "@outerlabs/helpguide";
import {
  IconButton,
  MenuItem,
  FormControl,
  Button,
  Select,
  Modal,
  Box,
} from "@material-ui/core";
import { Settings, Check, AddCircle, VisibilityOff } from "@material-ui/icons";

export interface HeadCell {
  id: keyof DisplayTableRowMetric;
  label: string;
  helpguideTooltip?: string;
}

// define all available head cells, set up reference to help guide
const headCells: HeadCell[] = [
  { id: "buildingID", label: "Building", helpguideTooltip: "Building" },
  { id: "floorCount", label: "Floors", helpguideTooltip: "Floors" },
  { id: "totalFloorArea", label: "GSF", helpguideTooltip: "GSF" },
  {
    id: "efficiencyRatio",
    label: "Efficiency",
    helpguideTooltip: "Efficiency",
  },
  { id: "usableFloorArea", label: "USF", helpguideTooltip: "USF" },
  {
    id: "assignableSeats",
    label: "Assignable Seats",
    helpguideTooltip: "Total Seats",
  },
  { id: "seatCount", label: "Total Seats", helpguideTooltip: "Total Seats" },
  {
    id: "convertedSeats",
    label: "Converted Seats",
    helpguideTooltip: "Converted Seats",
  },
  {
    id: "density",
    label: "Density",
    helpguideTooltip: "Density",
  },
  {
    id: "potentialSeats",
    label: "Potential Seats",
    helpguideTooltip: "Potential Seats",
  },
  { id: "workpoints", label: "Workpoints", helpguideTooltip: "Workpoints" },
  {
    id: "conferenceRoomSeats",
    label: "Meeting Seats",
    helpguideTooltip: "Meeting Seats",
  },
  {
    id: "conferenceRoomSeatsPerHC",
    label: "Mtg. Seats/ Total Seats",
    helpguideTooltip: "Mtg Seats / Total Seats",
  },
  {
    id: "displayDeskToDoor",
    label: "Desk to Door",
    helpguideTooltip: "Desk to Door",
  },
  {
    id: "displayDeskToDoorWithNooks",
    label: "Desk to Door (incl. Nooks)",
    helpguideTooltip: "Desk to Door",
  },
  {
    id: "totalCost",
    label: "Total Budget",
    helpguideTooltip: "Total Budget (Relevant GSF)",
  },
  {
    id: "nooksCount",
    label: "Total Nooks",
    helpguideTooltip: "Nooks",
  },
  {
    id: "phones",
    label: "Total Phonebooths",
    helpguideTooltip: "Phonebooths",
  },
  {
    id: "doors",
    label: "Total Doors (incl. Nooks)",
    helpguideTooltip: "Doors",
  },
];

// define head cells that will always populate the page
export const permanentHeaders: HeadCell[] = [
  { id: "buildingID", label: "Building", helpguideTooltip: "Building" },
  { id: "floorCount", label: "Floors", helpguideTooltip: "Floors" },
  { id: "totalFloorArea", label: "GSF", helpguideTooltip: "GSF" },
];

// define head cells that are modifiable, two options shown by default
export const populatedHeaders: HeadCell[] = [
  {
    id: "efficiencyRatio",
    label: "Efficiency",
    helpguideTooltip: "Efficiency",
  },
  { id: "usableFloorArea", label: "USF", helpguideTooltip: "USF" },
];

export let allHeaders = permanentHeaders.concat(populatedHeaders);

// calculate header length for consistent spacing of the menu, calcSpaces() is ran to generate that initial value
const headerLength: number = permanentHeaders.length + populatedHeaders.length;

let emptyCells: number, maxCells: number;
export const emptyCellsArr: number[] = [];

export const calcSpaces = () => {
  if (window.innerWidth >= 1440) {
    maxCells = 9;
    emptyCells = maxCells - headerLength;
  } else if (window.innerWidth < 1439 && window.innerWidth >= 1220) {
    maxCells = 7;
    emptyCells = maxCells - headerLength;
  } else if (window.innerWidth < 1219) {
    maxCells = 5;
    emptyCells = maxCells - headerLength;
  }
  for (let i = 0; i < emptyCells; i++) {
    emptyCellsArr.push(0);
  }
  return emptyCellsArr;
};

calcSpaces();

//define header styles
const useStyles = makeStyles(() =>
  createStyles({
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
      alignItems: "flex-start",
    },
    head: {
      backgroundColor: "white",
      position: "relative",
      fontWeight: 200,
      fontSize: "12px",
      ...cellStyles,
      height: 64,
      width: "8%",
      color: "#a6a6a6",
      flexDirection: "row",
      textAlign: "left",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
    buttonCell: {
      backgroundColor: "white",
      position: "relative",
      fontWeight: 200,
      fontSize: "12px",
      ...cellStyles,
      height: 64,
      width: "8%",
      color: "#a6a6a6",
      flexDirection: "row",
      textAlign: "left",
      alignItems: "center",
      justifyContent: "center",
    },
    headName: {
      position: "relative",
      backgroundColor: "white",
      fontWeight: 200,
      fontSize: "12px",
      ...cellStyles,
      height: 64,
      width: "15.5%",
      color: "#a6a6a6",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
    headSpace: {
      backgroundColor: "white",
      height: 64,
      width: "3%",
    },
    headAction: {
      backgroundColor: "white",
      ...actionStyles,
    },
    wrapper: {
      minWidth: "1440px",
      display: "flex",
      flexDirection: "row",
    },
    select: {
      fontFamily: "GoogleSansRegular",
      fontSize: "12px",
      fontWeight: 400,
      backgroundColor: "#E9E9EB",
      margin: "10px 5px 0 0",
      padding: "4px",
      flexDirection: "row",
      alignItems: "flex-start",
      borderRadius: "4px",
    },
    box: {
      position: "relative",
      top: 282,
      margin: "auto",
      display: "flex",
      flexDirection: "row",
      width: "90vw",
      borderRadius: 12,
    },
  })
);

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof DisplayTableRowMetric
  ) => void;
  order: Order;
  orderBy: string;
  handleUpdate: () => void;
}

// build table header, exported to table.tsx file
export default function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort, handleUpdate } = props;
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const classes = useStyles();
  const createSortHandler =
    (property: keyof DisplayTableRowMetric) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  function EditableButton() {
    const isEditable = Editing.useContainer();
    const closeModal = () => {
      handleUpdate();
      setOpenModal(!openModal);
    };

    const triggerModal = () => {
      setOpenModal(!openModal);
    };

    const onClickFunc = () => {
      isEditable.changeEditable();
      if (isEditable.editable) {
        closeModal();
      } else {
        triggerModal();
      }
    };

    return (
      <IconButton onClick={onClickFunc}>
        {isEditable.editable ? <Check /> : <Settings />}
      </IconButton>
    );
  }

  function ColumnsButton() {
    const column = Columns.useContainer();

    const addColumn = () => {
      if (column.spaces.length === 0) {
        console.error("Maximum amount of elements reached");
      } else {
        for (let i = 0; i < headCells.length; i++) {
          const check = allHeaders.some(
            (head) => head.label === headCells[i].label
          );
          if (check === false) {
            populatedHeaders.push(headCells[i]);
            break;
          }
        }
        allHeaders = permanentHeaders.concat(populatedHeaders);

        column.spaces.pop();
        column.setSpaces(column.spaces);
        column.updateHeader(allHeaders);
      }
    };

    return (
      <IconButton
        onClick={addColumn}
        disabled={column.spaces.length === 0 ? true : false}
      >
        <AddCircle />
      </IconButton>
    );
  }

  function ColumnsButtonRender() {
    const isEditable = Editing.useContainer();
    const styles = useStyles();

    return (
      <Cell classes={styles.buttonCell}>
        {!isEditable.editable ? "" : <ColumnsButton />}
      </Cell>
    );
  }

  // control render of Selects vs Standard Menu Item
  function HeadsRender() {
    return (
      <>
        {populatedHeaders.map((headCell) => {
          return (
            <Cell
              classes={
                headCell.id === "buildingID" ? classes.headName : classes.head
              }
              key={headCell.id}
            >
              <HelpguideWrapperSpan>
                <div style={{ height: 20 }}>
                  {headCell.helpguideTooltip && (
                    <HelpguideHandle
                      tooltipKey={headCell.helpguideTooltip}
                      isSmall
                    />
                  )}
                </div>
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                  hideSortIcon={
                    headCell.id === "efficiencyRatio" ? true : false
                  }
                >
                  <div>
                    {headCell.id === "displayDeskToDoorWithNooks" ||
                    headCell.id === "doors" ? (
                      <div>
                        {headCell.id === "displayDeskToDoorWithNooks"
                          ? "Desk to Door"
                          : "Total Doors"}
                        <br /> (incl. Nooks)
                      </div>
                    ) : (
                      headCell.label
                    )}
                  </div>
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              </HelpguideWrapperSpan>
            </Cell>
          );
        })}
      </>
    );
  }

  function SelectsRender() {
    const column = Columns.useContainer();

    return (
      <>
        {populatedHeaders.map((headCell) => {
          const index = populatedHeaders.findIndex(
            (head) => head.label === headCell.label
          );
          let display = headCell.label;
          return (
            <Cell classes={classes.head}>
              <FormControl fullWidth={true}>
                <Select
                  disableUnderline={true}
                  label="Column"
                  variant="standard"
                  onChange={(e) => {
                    const newData: HeadCell =
                      headCells.find((x) => x.id === e.target.value) ||
                      headCells[0];
                    display = newData.label;
                    populatedHeaders[index] = newData;
                    allHeaders = permanentHeaders.concat(populatedHeaders);
                  }}
                  displayEmpty={true}
                  renderValue={() => display}
                  className={classes.select}
                  SelectDisplayProps={{ style: { paddingLeft: 4 } }}
                >
                  {headCells.map(function (el) {
                    if (
                      allHeaders.findIndex(
                        (allHeadersElem) => allHeadersElem.id === el.id
                      ) !== -1
                    ) {
                      return (
                        <MenuItem key={el.id} value={el.id} disabled={true}>
                          {el.label}
                        </MenuItem>
                      );
                    } else {
                      return (
                        <MenuItem key={el.id} value={el.id}>
                          {el.label}
                        </MenuItem>
                      );
                    }
                  })}
                  <MenuItem>
                    <Button
                      onClick={() => {
                        if (index !== -1) {
                          populatedHeaders.splice(index, 1);
                          allHeaders =
                            permanentHeaders.concat(populatedHeaders);
                          column.setHeaders(allHeaders);
                          column.spaces.push(0);
                          column.setSpaces(column.spaces);
                        }
                      }}
                      style={{
                        padding: 0,
                        margin: 0,
                        fontFamily: "GoogleSansRegular",
                        fontSize: "0.8571rem",
                        fontWeight: 400,
                      }}
                    >
                      <VisibilityOff />
                      Hide Datapoint
                    </Button>
                  </MenuItem>
                </Select>
              </FormControl>
            </Cell>
          );
        })}
      </>
    );
  }

  function SpaceRender() {
    const column = Columns.useContainer();
    return (
      <>
        {column.spaces.map((el) => {
          return (
            <Cell classes={classes.head} key={el}>
              {""}
            </Cell>
          );
        })}
      </>
    );
  }

  return (
    <HelpguideWrapperDiv>
      <Editing.Provider>
        <Columns.Provider>
          <div className={classes.wrapper}>
            <Cell classes={classes.headSpace}> </Cell>
            <Cell classes={classes.headSpace}> </Cell>
            {permanentHeaders.map((headCell) => {
              return (
                <Cell
                  classes={
                    headCell.id === "buildingID"
                      ? classes.headName
                      : classes.head
                  }
                  key={headCell.id}
                >
                  <HelpguideWrapperSpan>
                    <div style={{ height: 20 }}>
                      {headCell.helpguideTooltip && (
                        <HelpguideHandle
                          tooltipKey={headCell.helpguideTooltip}
                          isSmall
                        />
                      )}
                    </div>
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={createSortHandler(headCell.id)}
                      hideSortIcon={
                        headCell.id === "efficiencyRatio" ? true : false
                      }
                    >
                      <div>{headCell.label}</div>
                      {orderBy === headCell.id ? (
                        <span className={classes.visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </HelpguideWrapperSpan>
                </Cell>
              );
            })}
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={openModal}
              onClose={() => setOpenModal(!openModal)}
              disableBackdropClick={true}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Box
                className={classes.box}
                style={{ border: "4px solid white" }}
              >
                <Cell classes={classes.headSpace}> </Cell>
                <Cell classes={classes.headSpace}> </Cell>
                {permanentHeaders.map((headCell) => {
                  return (
                    <Cell
                      classes={
                        headCell.id === "buildingID"
                          ? classes.headName
                          : classes.head
                      }
                      key={headCell.id}
                    >
                      <HelpguideWrapperSpan>
                        <div style={{ height: 20 }}>
                          {headCell.helpguideTooltip && (
                            <HelpguideHandle
                              tooltipKey={headCell.helpguideTooltip}
                              isSmall
                            />
                          )}
                        </div>
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : "asc"}
                          onClick={createSortHandler(headCell.id)}
                          hideSortIcon={
                            headCell.id === "efficiencyRatio" ? true : false
                          }
                        >
                          <div>{headCell.label}</div>
                          {orderBy === headCell.id ? (
                            <span className={classes.visuallyHidden}>
                              {order === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </span>
                          ) : null}
                        </TableSortLabel>
                      </HelpguideWrapperSpan>
                    </Cell>
                  );
                })}
                <SelectsRender />
                <SpaceRender />
                <ColumnsButtonRender />
                <Cell classes={classes.buttonCell}>
                  <EditableButton />
                </Cell>
                <Cell classes={classes.head}> </Cell>
              </Box>
            </Modal>
            <HeadsRender />
            <SpaceRender />
            <ColumnsButtonRender />
            <Cell classes={classes.buttonCell}>
              <EditableButton />
            </Cell>
            <Cell classes={classes.head}> </Cell>
          </div>
        </Columns.Provider>
      </Editing.Provider>
    </HelpguideWrapperDiv>
  );
}
