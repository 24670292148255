import { Option } from "./types";

export const defaultValues = {
  collabSqFtPerPerson: 25,
  deskHeight: 40,
  deskWidth: 40,
  feedbackFormURL:
    "https://docs.google.com/forms/d/e/1FAIpQLSd4wvY8nBRZQeOdbNdkVYspwer2fsfHUYW41XeKpPTLmpG1XA/viewform",
  appIconSrc:
    "https://storage.googleapis.com/portfolio-274919.appspot.com/images/Portfolio256.svg",
  blockIconSrc:
    "https://storage.googleapis.com/portfolio-274919.appspot.com/images/Blocks256.svg",
};

export const options: Option[] = [
  {
    value: "large",
    name: "Lg Conf Rooms",
  },
  {
    value: "medium",
    name: "Med Conf Rooms",
  },
  {
    value: "small",
    name: "Small Conf Rooms",
  },
  {
    value: "huddle",
    name: "Huddle",
  },

  {
    value: "phone",
    name: "Phone Booths",
  },
];

export const allOptions: Option[] = [
  ...options,
  {
    value: "officeHuddle",
    name: "Office - Huddle Combo",
  },
  {
    value: "lgOffice",
    name: "Office - Large",
  },
  {
    value: "mdOffice",
    name: "Office - Medium",
  },
  {
    value: "smOffice",
    name: "Office - Small",
  },
  {
    value: "interview",
    name: "Interview",
  },
  {
    value: "creativeRoom",
    name: "Creative Room",
  },
  {
    value: "fitnessGym",
    name: "Fitness - Gym",
  },
  {
    value: "fitnessOther",
    name: "Fitness - Other",
  },
  {
    value: "fitnessStudio",
    name: "Fitness - Studio",
  },
  {
    value: "gameRoom",
    name: "Game Room",
  },
  {
    value: "healthExamRoom",
    name: "Health Exam Room",
  },
  {
    value: "healthLab",
    name: "Health Lab",
  },
  {
    value: "healthRehab",
    name: "Health Rehab",
  },
  {
    value: "massageRoom",
    name: "Massage Room",
  },
  {
    value: "meditationRoom",
    name: "Meditation Room",
  },
  {
    value: "musicRoom",
    name: "Music Room",
  },
  {
    value: "collaboration",
    name: "Collaboration",
  },
  {
    value: "focusRoom",
    name: "Focus Room",
  },
  {
    value: "eventSpace",
    name: "Event Space",
  },
  {
    value: "auditorium",
    name: "Auditorium",
  },
  {
    value: "techTalk",
    name: "Tech Talk",
  },
  {
    value: "trainingRoom",
    name: "Training Room",
  },
  {
    value: "meetingSpace",
    name: "Meeting Space",
  },
];

// These two urls are the same as in main clay repo, and are used by help guide
export const feedbackFormURL =
  "https://docs.google.com/forms/d/e/1FAIpQLSd4wvY8nBRZQeOdbNdkVYspwer2fsfHUYW41XeKpPTLmpG1XA/viewform?usp=sf_link";

export const aboutClayURL =
  "https://sites.google.com/google.com/projectclay/project-clay";
