import { Point } from "@outerlabs/shapes-geometry";
import { parseSVG } from "svg-path-parser";
import { polygonizeCurve } from "./utils";
import { polyline } from "./polyline";

export type Path = {
  type: "path";
  path: string;
};

export const path = ({ path: localPath }: Path, layerName: string): string => {
  const commands = parseSVG(localPath);
  let position: Point = [0, 0];
  let points: Point[] = [];
  commands.forEach((command) => {
    switch (command.code) {
      // move
      case "M":
        position = [command.x, command.y];
        points.push([...position]);
        break;
      case "m":
        position = [command.x + position[0], command.y + position[1]];
        points.push([...position]);
        break;
      // line
      case "L":
        position = [command.x, command.y];
        points.push([...position]);
        break;
      case "l":
        position[0] += command.x;
        position[1] += command.y;
        points.push([...position]);
        break;
      // horizontal line
      case "H":
        position = [command.x, position[1]];
        points.push([...position]);
        break;
      case "h":
        position = [command.x + position[0], position[1]];
        points.push([...position]);
        break;
      // vertical line
      case "V":
        position = [position[0], command.y];
        points.push([...position]);
        break;
      case "v":
        position = [position[0], position[1] + command.y];
        points.push([...position]);
        break;
      // cubic curve
      case "C":
        points = points.concat(
          polygonizeCurve(
            [
              [position[0], position[1]],
              [command.x1, command.y1],
              [command.x2, command.y2],
              [command.x, command.y],
            ],
            8
          )
        );
        position = [command.x, command.y];
        break;
      case "c":
        points = points.concat(
          polygonizeCurve(
            [
              [position[0], position[1]],
              [command.x1 + position[0], command.y1 + position[1]],
              [command.x2 + position[0], command.y2 + position[1]],
              [command.x + position[0], command.y + position[1]],
            ],
            2
          )
        );
        position = [command.x + position[0], command.y + position[1]];
        break;
      // smooth cubic curve (duplicate start point)
      case "S":
        points = points.concat(
          polygonizeCurve(
            [
              [position[0], position[1]],
              [position[0], position[1]],
              [command.x2, command.y2],
              [command.x, command.y],
            ],
            2
          )
        );
        position = [command.x, command.y];
        break;
      case "s":
        points = points.concat(
          polygonizeCurve(
            [
              [position[0], position[1]],
              [position[0], position[1]],
              [command.x2 + position[0], command.y2 + position[1]],
              [command.x + position[0], command.y + position[1]],
            ],
            2
          )
        );
        position = [command.x + position[0], command.y + position[1]];
        break;
      // quadratic curve
      case "Q":
        points = points.concat(
          polygonizeCurve(
            [
              [position[0], position[1]],
              [command.x1, command.y1],
              [command.x, command.y],
            ],
            2
          )
        );
        position = [command.x, command.y];
        break;
      case "q":
        points = points.concat(
          polygonizeCurve(
            [
              [position[0], position[1]],
              [command.x1 + position[0], command.y1 + position[1]],
              [command.x + position[0], command.y + position[1]],
            ],
            2
          )
        );
        position = [command.x + position[0], command.y + position[1]];
        break;
      // smooth quadratic curve (duplicate start point)
      case "T":
        points = points.concat(
          polygonizeCurve(
            [
              [position[0], position[1]],
              [position[0], position[1]],
              [command.x, command.y],
            ],
            2
          )
        );
        position = [command.x, command.y];
        break;
      case "t":
        points = points.concat(
          polygonizeCurve(
            [
              [position[0], position[1]],
              [position[0], position[1]],
              [command.x + position[0], command.y + position[1]],
            ],
            2
          )
        );
        position = [command.x + position[0], command.y + position[1]];
        break;
      // arc
      // TODO: line for now
      case "A":
        position = [command.x, command.y];
        points.push([...position]);
        break;
      case "a":
        position[0] += command.x;
        position[1] += command.y;
        points.push([...position]);
        break;
      // close path
      case "Z":
      case "z":
        // if (path[0][0] !== path[path.length - 1][0] || path[0][1] !== path[path.length - 1][1]) {
        //   path.push([...path[0]]);
        // }
        break;
    }
  });
  return polyline({ type: "polyline", points: points }, layerName);
};
