import { ComponentType } from "react";
import { ContainerProviderProps, createContainer } from "unstated-next";
import { makeBlockThumbnailMatchmaker } from "../../blocks/lib/util";
import { useBlocksCtrl } from "./blocks";
import { Block } from "../../blocks/lib/types";
import { getters } from "../../blocks/lib/constants";

// Work in progress container, where the idea is to cache snapshots of blocks
// WIP part is: using this in other places where we render block images, and
// also maybe saving to localstorage. that would be nice
//
// Currently this is ONLY used in matchmaker block image rendering.

interface BlockImagesState {
  getBlockImage: (block: Block) => Promise<string>;
}

const cache: { [k: string]: { [k: string]: Promise<string> } } = {};
export const useBlockImagesState = (): BlockImagesState => {
  const { getBlockById } = useBlocksCtrl();
  const getBlockImage = async (block: Block): Promise<string> => {
    const { sizeRange } = getters.getMetrics(block);
    let { size } = getters.getMetrics(block);
    if (!size || size[0] === 0 || size[1] === 0) {
      size = [sizeRange[0][0], sizeRange[1][0]];
    }
    const s = size.join(",");
    const id = block.id;
    if (cache[id] !== undefined && cache[id][s] !== undefined)
      return cache[id][s];
    if (!cache[id]) cache[id] = {};
    cache[id][s] = makeBlockThumbnailMatchmaker(block, getBlockById, size);
    return cache[id][s];
  };
  return {
    getBlockImage,
  };
};

export const BlockImagesController = createContainer(useBlockImagesState);
export const BlockImagesProvider: ComponentType<ContainerProviderProps> =
  BlockImagesController.Provider;
export const useBlockImagesCtrl = () => BlockImagesController.useContainer();
