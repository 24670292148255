import React from "react";
import { Avatar, Menu, MenuItem } from "@material-ui/core";
import { Claims, getUserDetails } from "../lib/auth";
import { setAuthToken } from "lib/storage/session";
import { useHistory } from "react-router-dom";

const UserAvatar: React.FC = () => {
  const { push } = useHistory();
  const claims: Claims | undefined = getUserDetails();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  const handleLogout = () => {
    setAuthToken("");
    push("/login");
  };

  if (!claims) return <div />;

  return (
    <React.Fragment>
      <Avatar
        src={claims.picture}
        onClick={handleMenuOpen}
        style={{ width: "32px", height: "32px", cursor: "pointer" }}
      />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleLogout}>Log Out</MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default React.memo(UserAvatar);
