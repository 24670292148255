import React from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import { Mutation, Project, RendererSettings } from "lib/types";
import {
  useMatchMakerCtrl,
  useRendererCtrl,
  useSettingsCtrl,
} from "lib/containers";
import { encodeMetricId } from "lib/metrics/id";
import { ToolsProps } from "../components/sideBar/ToolsPanel";
import StrategyTitle from "./strategy-title";

import SidebarBaseMode, { WpiSidebarTabValue } from "./sidebar-basemode";
import SidebarDrawMode from "./sidebar-drawmode";
import SidebarMatchMaker from "./sidebar-matchmaker";
import SidebarAnalytics from "./sidebar-analytics";

export interface Props {
  project: Project;
  onChange(name: string, value: any): void;
  tools: ToolsProps;
  settings: RendererSettings | undefined;
}

const useStyles = makeStyles({
  sideBarContainer: {
    height: "calc(100% - 78px)",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    padding: 12,
  },
  wrap: {
    position: "relative",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    overflowY: "auto",
    height: "100%",
  },
});

const SidebarControls: React.FC<Props> = ({
  project,
  onChange,
  tools,
  settings,
}) => {
  const { buildingID, strategyID, floorID } = useParams<{
    [key: string]: string;
  }>();
  const classes = useStyles();
  const { matchMakerActive } = useMatchMakerCtrl();
  const { updateBuildingMetrics } = useSettingsCtrl();
  const { setWpiActivitiesMode, wpiActivitiesMode } = useRendererCtrl();
  const id = encodeMetricId(buildingID, strategyID, floorID);
  const metric = project.metrics[id];

  const selectedTab: WpiSidebarTabValue = wpiActivitiesMode
    ? "activities"
    : "program";
  const setSelectedTab = (newVal: WpiSidebarTabValue) => {
    setWpiActivitiesMode(newVal === "activities");
  };

  const updateStrategyName = async (mutation: Mutation) => {
    await updateBuildingMetrics(mutation, buildingID, strategyID);
  };

  return (
    <div className={classes.wrap}>
      <React.Fragment>
        <div className={classes.container}>
          <StrategyTitle
            onBlur={updateStrategyName}
            displayName={metric.displayName}
            buildingID={buildingID}
          />
        </div>
        <div className={classes.sideBarContainer}>
          {matchMakerActive && <SidebarMatchMaker />}
          <SidebarAnalytics />
          <SidebarDrawMode />
          <SidebarBaseMode
            project={project}
            onChange={onChange}
            tools={tools}
            settings={settings}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        </div>
      </React.Fragment>
    </div>
  );
};

export default React.memo(SidebarControls);
