import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      padding: 24,
      width: 500,
      backgroundColor: "white",
      borderRadius: 4,
    },
    title: {
      fontSize: 20,
      marginBottom: 24,
    },
    buttons: {
      marginTop: 24,
      display: "flex",
      justifyContent: "space-between",
    },
  })
);

export default function WarningModal({
  message,
  onAccept,
  open,
  handleClose,
}: {
  message: React.ReactNode;
  onAccept(e: React.MouseEvent): void;
  open: boolean;
  handleClose(e: React.MouseEvent): void;
}) {
  const classes = useStyles();

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className={classes.title}>Attention</div>
            <div>{message}</div>
            <div className={classes.buttons}>
              <Button onClick={handleClose}>Cancel</Button>
              <Button variant="contained" color="primary" onClick={onAccept}>
                PROCEED
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
