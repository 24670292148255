import React, { useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import IconDomain from "@material-ui/icons/Domain";
import MoreButton, { Item } from "components/controls/more-button";
import { useProjectsCtrl } from "lib/containers";
import { Project } from "lib/types";
import { makeStyles } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import calendar from "dayjs/plugin/calendar";

const useStyles = makeStyles({
  container: {
    margin: "0px 10px 20px 10px",
    width: 255,
    height: 206,
  },
  imageContainer: {
    display: "flex",
    width: "100%",
    height: 145,
    border: "1px solid lightGray",
    borderRadius: 8,
  },
  descriptionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 16,
    width: "100%",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  name: { fontStyle: "GoogleSansMedium", fontSize: 14.5 },
  lastOpened: {
    fontStyle: "GoogleSansMedium",
    fontSize: 12,
    color: "#00000060",
  },
  iconButton: {
    position: "relative",
    top: 12,
    right: 12,
    backgroundColor: "rgba(255, 255, 255, 0.85)",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)",
    width: "30px",
    height: "30px",
  },
  icon: {
    width: "20px",
    height: "20px",
    color: "#ADADAD",
  },
  cardImageIcon: {
    position: "relative",
    right: -14,
    width: 64,
    height: 64,
    margin: "auto",
  },
});

export interface Props {
  id: string;
  project: Project;
}

dayjs.extend(relativeTime);
dayjs.extend(calendar);

const HomeCard: React.FC<RouteComponentProps & Props> = ({
  history,
  id,
  project,
}) => {
  const classes = useStyles();
  const { deleteProject } = useProjectsCtrl();
  const handleClick = () => {
    history.push(`/${id}/strategies`);
  };

  const onDelete = () => {
    deleteProject(id);
    setOpen(false);
  };

  const [open, setOpen] = useState<boolean>(false);
  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const iconStyle =
    project.users.length <= 1 ? { color: "#ADADAD" } : { color: "#4384F7" };

  return (
    <Card
      className={classes.container}
      elevation={0}
      data-testid="project-card"
    >
      <CardMedia className="cursor-pointer">
        <div className={classes.imageContainer} onClick={handleClick}>
          <IconDomain color="primary" className={classes.cardImageIcon} />
          <IconButton className={classes.iconButton}>
            <PeopleAltIcon className={classes.icon} style={iconStyle} />
          </IconButton>
        </div>
      </CardMedia>
      {project && (
        <div className={classes.descriptionContainer}>
          <div className={classes.titleContainer}>
            <div className={classes.name} data-testid="project-card-name">
              {project.name}
            </div>
            {String(project.dateLastOpened) !== "0001-01-01T00:00:00Z" ? (
              <div className={classes.lastOpened}>
                Opened{" "}
                {dayjs(project.dateLastOpened).calendar(null, {
                  sameDay: "[today]",
                  lastDay: "[yesterday]",
                  sameElse: "on MM/DD/YYYY",
                  nextWeek: "on MM/DD/YYYY", // The next week ( Sunday at 2:30 AM )
                  lastWeek: "on MM/DD/YYYY", // Last week ( Last Monday at 2:30 AM )
                })}
              </div>
            ) : null}
          </div>
          <div>
            <MoreButton
              open={open}
              onOpen={onOpen}
              onClose={onClose}
              data-testid="project-menu-button"
            >
              <Item onClick={onDelete} data-testid="delete-project-option">
                Delete {project.name}
              </Item>
            </MoreButton>
          </div>
        </div>
      )}
    </Card>
  );
};

export default withRouter(HomeCard);
