import { addComma, formatMetric, round, truncateNumber } from "lib/number";
import { Metrics, Metric, Project, DisplayTableRowMetric } from "lib/types";
import { emptyTableMetric } from "./constants";
import { decodeMetricId } from "./id";
import {
  calculateBaselineDensity,
  calculateDensity,
  calculateBaselineUsableDensity,
  calculateUseableFloorArea,
  calculateUsableDensity,
  baselineConferenceRoomsSeatsPerHC,
  conferenceRoomSeatsPerHC,
  calculateBuildingDeskToDoor,
  calculateBuildingBaselineDeskToDoor,
  calculateBuildingDeskToDoorWithNooks,
} from "./util";

// compile, style and return values for display in portfolio strategies page
export const reduceTableMetric = (
  metrics: Metrics,
  buildingID: string,
  floorCount: number
): DisplayTableRowMetric => {
  const acc = { ...emptyTableMetric };
  acc.buildingID = buildingID;
  Object.values(metrics).forEach(async (m) => {
    let convertedPhonesCount = 0;
    if (m.userConvertedMeetingRooms) {
      Object.values(m.userConvertedMeetingRooms).forEach((conversion) => {
        if (conversion && conversion.length !== 0) {
          conversion.forEach((room) => {
            if (room.conferenceRoomType === "phone") convertedPhonesCount++;
          });
        }
      });
    }

    acc.displayName = m.displayName;
    acc.strategyID = m.strategyID;
    acc.totalFloorArea += m.totalFloorArea;
    acc.efficiencyRatio = m.efficiencyRatio;
    acc.baselineHeadCount += m.baselineHeadCount;
    acc.baselineSeatCount += m.baselineSeatCount;
    acc.seatCount += m.seatCount;
    acc.potentialSeats += m.potentialSeats;
    acc.convertedSeats += m.convertedSeats;
    acc.floorCount = floorCount;
    acc.baselineConferenceRoomsCount += m.baselineConferenceRoomsCount;
    acc.baselineConferenceRoomsCapacity += m.baselineConferenceRoomsCapacity;
    acc.conferenceRoomSeats += m.conferenceRoomSeats;
    acc.convertedMeetingRooms += m.convertedMeetingRooms || 0;
    acc.workpoints += m.workpoints || 0;
    acc.usableFloorArea = calculateUseableFloorArea(acc);
    acc.baselineDensity = calculateBaselineDensity(acc);
    acc.density = calculateDensity(acc);
    acc.baselineUseableDensity = calculateBaselineUsableDensity(acc);
    acc.useableDensity = calculateUsableDensity(acc);
    acc.baselineConferenceRoomsSeatsPerHC =
      baselineConferenceRoomsSeatsPerHC(acc);
    acc.conferenceRoomSeatsPerHC = conferenceRoomSeatsPerHC(acc);
    acc.totalCost += m.totalCost;
    acc.costUnit += m.costUnit;
    acc.costGSF += m.costGSF;
    acc.costHC += m.costHC;
    m.assignableSeats =
      (m.nooksAssignable || 0) +
      (m.deskCount || 0) +
      // LM-Note: the logic below for handling floorscopeDesks is clarified
      // within the MR below, in the description and in a thread with JV
      // https://gitlab.com/outerlabs/portfolio/-/merge_requests/1039
      (m.floorscopeDesks !== 0 ||
      (m.floorscopeDesks === 0 &&
        (m.deskCount || m.phonesCount || m.nooksCount))
        ? m.floorscopeDesks
        : m.seatCount);
    acc.assignableSeats += m.assignableSeats;
    acc.phonesCount += m.phonesCount;
    acc.phones +=
      m.phonesCount + (m.phone ? m.phone.count : 0) - convertedPhonesCount;
    acc.baselinePhoneCount += m.phone ? m.phone.count : 0;
    acc.nooksCount += m.nooksCount;
    acc.doors += m.doors
      ? m.doors + m.nooksCount
      : (m.phone ? m.phone.count : 0) +
        (m.huddle ? m.huddle.count : 0) +
        (m.extraLarge ? m.extraLarge.count : 0) +
        (m.large ? m.large.count : 0) +
        (m.medium ? m.medium.count : 0) +
        (m.small ? m.small.count : 0) +
        (m.phonesCount || 0) +
        (m.nooksCount || 0);
    acc.assetHC += m.assetHC;
  });

  const buildingMetrics: Metric[] = [];
  Object.values(metrics).forEach((floor) => {
    buildingMetrics.push(floor);
  });

  acc.deskToDoor = calculateBuildingDeskToDoor(buildingMetrics);
  acc.deskToDoorWithNooks =
    calculateBuildingDeskToDoorWithNooks(buildingMetrics);
  acc.baselineDeskToDoor = calculateBuildingBaselineDeskToDoor(buildingMetrics);

  const summary = {
    ...acc,
    displayFloorArea: addComma(round(acc.totalFloorArea)),
    displayERatio: Math.round(acc.efficiencyRatio * 100) + "%",
    displayUsableFloorArea: addComma(round(acc.usableFloorArea)),
    displayBaselineDensity: round(acc.baselineDensity),
    displayDensity: round(acc.density),
    displayMeetingRooms: acc.convertedMeetingRooms,
    displayBaselineUseableDensity: round(acc.baselineUseableDensity),
    displayUseableDensity: round(acc.useableDensity),
    displayConferenceSeats: round(acc.conferenceRoomSeats),
    displayBaselineConferenceCapacity: round(
      acc.baselineConferenceRoomsCapacity
    ),
    displayConferenceSeatsPerHC: parseFloat(
      acc.conferenceRoomSeatsPerHC.toFixed(2)
    ),
    displayBaselineConferenceSeatsPerHC: parseFloat(
      acc.baselineConferenceRoomsSeatsPerHC.toFixed(2)
    ),

    displayDeskToDoor: formatMetric(acc.deskToDoor, 2),
    displayDeskToDoorWithNooks: formatMetric(acc.deskToDoorWithNooks, 2),
    displayBaselineDeskToDoor: formatMetric(acc.baselineDeskToDoor, 2),
    displayTotalCost: "$" + truncateNumber(acc.totalCost),
    displayCostUnit: "$" + round(acc.costUnit),
    displayCostGSF: "$" + round(acc.costGSF),
    displayCostHC: "$" + addComma(round(acc.costHC)),
  };
  return summary;
};

export const reduceTableMetrics = (
  project: Project
): DisplayTableRowMetric[][] => {
  const m = Object.assign({}, project.metrics);
  const results: DisplayTableRowMetric[][] = [];
  const sm: {
    [buildingID: string]: {
      [strategyID: string]: { [floorID: string]: Metric };
    };
  } = {};
  Object.keys(m).forEach((key) => {
    const { buildingID, strategyID, floorID } = decodeMetricId(key);
    let strategy = {};
    if (sm[buildingID] && sm[buildingID][strategyID])
      strategy = { ...sm[buildingID][strategyID] };
    sm[buildingID] = {
      ...sm[buildingID],
      [strategyID]: { ...strategy, [floorID]: m[key] },
    };
  });
  Object.keys(sm).forEach((buildingID) => {
    const strategies = sm[buildingID];
    const strategyResults: DisplayTableRowMetric[] = [];
    Object.keys(strategies).forEach((strategyID) => {
      const floorCount = project.buildings[buildingID].AvailableFloors.length;
      const metrics = sm[buildingID][strategyID];
      const acc = reduceTableMetric(metrics, buildingID, floorCount);
      strategyResults.push(acc);
    });
    results.push(strategyResults);
  });
  return results;
};
