import { Metric } from "lib/types";
import calculatePotentialSeats from "./calculate-potential-seats";
import { roomTypes } from "./constants";

export const metricConvertToWorkStation = (
  convertToWorkstations: string[],
  metric: Metric
) => {
  const update = { workstations: 0, count: 0, capacity: 0 };

  convertToWorkstations?.forEach((key: string) => {
    if (roomTypes.includes(key)) {
      const metricKey = key as keyof Metric;
      let capacity =
        metric[metricKey].capacity !== undefined
          ? metric[metricKey].capacity
          : metric[metricKey];
      let count =
        metric[metricKey].count !== undefined
          ? metric[metricKey].count
          : metric[metricKey];
      let workstations = calculatePotentialSeats(key, metric, count);

      //subtract any rooms if the rooms of converted group type is converted into another type
      if (metric.userConvertedMeetingRooms !== undefined) {
        Object.keys(metric.userConvertedMeetingRooms).forEach((aN: string) => {
          if (
            metric.userConvertedMeetingRooms &&
            metric.userConvertedMeetingRooms[aN]
          ) {
            if (aN !== "convertToWorkstations") {
              Object.values(metric.userConvertedMeetingRooms[aN]).forEach(
                (room) => {
                  if (room.conferenceRoomType === key) {
                    workstations =
                      workstations -
                      calculatePotentialSeats(
                        room.conferenceRoomType,
                        metric,
                        1
                      );
                    capacity =
                      capacity - metric[metricKey].capacity !== undefined
                        ? metric[metricKey].capacity
                        : metric[metricKey];
                    count = count - 1;
                  }
                }
              );
            }
          }
        });
      }
      update.workstations += workstations;
      update.count += count;
      update.capacity -= capacity;
    }
  });
  return update;
};
