import React from "react";
import { makeStyles } from "@material-ui/core/styles";
interface Props {
  children: React.ReactNode;
}

export const SIDEBAR_WIDTH = 401;
const useStyles = makeStyles(() => ({
  sidebarContainer: {
    position: "fixed",
    left: "64px",
    height: "100%",
    width: SIDEBAR_WIDTH,
    overflowY: "auto",
    overflowX: "hidden",
    backgroundColor: "white",
    borderRight: "1px solid #E4E4E4",
    zIndex: 200,
    display: "flex",
    flexDirection: "column",
  },
}));

const Sidebar: React.FC<Props> = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.sidebarContainer}>{children}</div>;
};

export default React.memo(Sidebar);
