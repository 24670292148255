import {
  TextField as MaterialTextField,
  TextFieldProps,
} from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  textFieldInput: {
    "& > input": {
      padding: "10px 12px 10px",
    },
    "& > input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    backgroundColor: "#F4F4F6",
    height: 32,
    fontSize: 14,
    fontFamily: "Google Sans",
  },
  textFieldLabel: {
    fontFamily: "Google Sans",
    color: "#5F6166",
    transform: "translate(2px, -20px) scale(1) !important",
    fontSize: 12,
  },
  textField: {
    marginTop: 24,
    marginBottom: 20,
    width: "100%",
    "& > input": {},
  },
});

const TextField: React.FC<TextFieldProps> = (props) => {
  const classes = useStyles();
  return (
    <MaterialTextField
      {...props}
      variant="filled"
      className={classes.textField}
      InputProps={{
        ...(props.InputProps || {}),
        disableUnderline: true,
        className: classes.textFieldInput,
      }}
      InputLabelProps={{
        ...(props.InputLabelProps || {}),
        shrink: true,
        className: classes.textFieldLabel,
      }}
      autoComplete="off"
    />
  );
};

export default TextField;
