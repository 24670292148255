import { ComponentType, useEffect, useState } from "react";
import { ContainerProviderProps, createContainer } from "unstated-next";
import { Features } from "lib/types";
import getFeatures from "lib/api/getFeatures";
import usePrevious from "lib/hooks/use-previous";

export interface FeaturesState {
  currentFeatures: Features | undefined;
}

interface InitialState {
  projectID: string;
  buildingID: string;
  floorID: string;
  strategyID: string;
}

export const useFeaturesState = (
  initialState: InitialState | undefined
): FeaturesState => {
  const [currentFeatures, setCurrentFeatures] = useState<Features>();
  const prevState: InitialState | undefined = usePrevious(initialState);

  useEffect(() => {
    const setFeatures = async () => {
      if (initialState) {
        setCurrentFeatures(undefined);
        const { buildingID, floorID } = initialState;
        if (!buildingID || !floorID) return;
        const f = await getFeatures(buildingID, floorID);
        setCurrentFeatures(f);
        // temporarily expose a convenient way to count desks from the developer console.
        (window as any).countDesksOnFloor = () => f.desks?.length ?? 0;
      }
    };
    if (
      !prevState ||
      prevState?.floorID !== initialState?.floorID ||
      prevState?.buildingID !== initialState?.buildingID
    )
      setFeatures();
  }, [initialState, prevState]);

  return {
    currentFeatures,
  };
};

export const FeaturesController = createContainer(useFeaturesState);
export const FeaturesProvider: ComponentType<
  ContainerProviderProps<InitialState>
> = FeaturesController.Provider;
export const useFeaturesCtrl = () => FeaturesController.useContainer();
