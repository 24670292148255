import { Font } from "@outerlabs/ol-ui";
import React from "react";
import { Bar, BarChart, LabelList, YAxis } from "recharts";

interface Props {
  amenitiesData: any[];
  totalDensity: number;
}

// uses a white outline to fake in a gap between bars
// recharts currently does not support the use of classNames
const barStyle = { stroke: "#fff", strokeWidth: 3 };

const AmenitySubBarChart: React.FC<Props> = ({
  amenitiesData: data,
  totalDensity,
}) => {
  const renderNonZero = (props: any) => {
    const { x, y, width: barWidth, height: barHeight, value, id } = props;
    return value === 0 ? null : (
      <g id={id}>
        <text
          x={x + barWidth / 2 - 12}
          y={y + barHeight / 2}
          fill="#000"
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize={12}
          fontFamily={Font.Medium.fontFamily}
          fontWeight={Font.Medium.fontWeight}
          letterSpacing={1}
        >
          {value}
        </text>
        <text
          x={x + barWidth / 2 + 12}
          y={y + barHeight / 2}
          fill="#000"
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize={10}
          fontFamily={Font.Regular.fontFamily}
          fontWeight={Font.Regular.fontWeight}
          letterSpacing={1}
        >
          {`${+((value / totalDensity) * 100).toFixed(1)}%`}
        </text>
      </g>
    );
  };

  return data.length === 0 ? null : (
    <BarChart
      id="amenity-subbar"
      width={55}
      height={200}
      data={data}
      margin={{
        top: 40,
        bottom: 5,
        right: -18,
        left: -18,
      }}
    >
      <YAxis type="number" domain={[0, 32 || 0]} hide />
      <Bar
        key={"networkInfrastructure"}
        dataKey={"networkInfrastructure"}
        stackId="a"
        fill={"#C1B79C"}
        isAnimationActive={false}
        style={barStyle}
      >
        <LabelList
          dataKey={"networkInfrastructure"}
          position="center"
          content={renderNonZero}
          id={"networkInfrastructureLabel"}
        />
      </Bar>
      <Bar
        key={"healthAndPerformance"}
        dataKey={"healthAndPerformance"}
        stackId="a"
        fill={"#FFCA2C"}
        isAnimationActive={false}
        style={barStyle}
      >
        <LabelList
          dataKey={"healthAndPerformance"}
          position="center"
          content={renderNonZero}
          id={"healthAndPerformanceLabel"}
        />
      </Bar>
      <Bar
        key={"extendedAmenities"}
        dataKey={"extendedAmenities"}
        stackId="a"
        fill={"#B89130"}
        isAnimationActive={false}
        style={barStyle}
      >
        <LabelList
          dataKey={"extendedAmenities"}
          position="center"
          content={renderNonZero}
          id={"extendedAmenitiesLabel"}
        />
      </Bar>
      <Bar
        key={"transportation"}
        dataKey={"transportation"}
        stackId="a"
        fill={"#FEF3C7"}
        isAnimationActive={false}
        style={barStyle}
      >
        <LabelList
          dataKey={"transportation"}
          position="center"
          content={renderNonZero}
          id={"transportationLabel"}
        />
      </Bar>
      <Bar
        key={"food"}
        dataKey={"food"}
        stackId="a"
        fill={"#FFAF38"}
        isAnimationActive={false}
        style={barStyle}
      >
        <LabelList
          dataKey={"food"}
          position="center"
          content={renderNonZero}
          id={"foodLabel"}
        />
      </Bar>
      <Bar
        key={"workplaceSupport"}
        dataKey={"workplaceSupport"}
        stackId="a"
        fill={"#FFDCD4"}
        //onMouseOver={(e) => makeMouseOver(e, bar.tags)}
        isAnimationActive={false}
        style={barStyle}
      >
        <LabelList
          dataKey={"workplaceSupport"}
          position="center"
          content={renderNonZero}
          id={"workplaceSupportLabel"}
        />
      </Bar>
      <use href="#workplaceSupportLabel-0" />
      <use href="#foodLabel-0" />
      <use href="#healthAndPerformanceLabel-0" />
      <use href="#extendedAmenitiesLabel-0" />
      <use href="#transportationLabel-0" />
    </BarChart>
  );
};

export default AmenitySubBarChart;
