import { makeStyles, styled } from "@material-ui/core/styles";
import {
  Grid,
  Slider as MSlider,
  SliderProps as MSliderProps,
} from "@material-ui/core";
import React from "react";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { Tooltip } from "@outerlabs/ol-ui";
import { HelpguideHandle, HelpguideWrapperDiv } from "@outerlabs/helpguide";

const blue = "47,115,255";
const orange = "255,151,0";

export const enum Mode {
  Slider,
  Buttons,
}

const sliderStyles = makeStyles({
  rail: {
    background: `rgba(${orange},1)`,
  },
  track: {
    background: `rgba(${blue},1)`,
  },
  valueLabel: {
    background: "#5F6166",
    color: "#5F6166",
    fontSize: "8px",
    borderRadius: "4px",
    alignItems: "center",
    textAlign: "center",
    display: "flex",
    padding: "5px 8px",
    position: "relative",
    left: "0px",
    top: "-20px",
  },
  workstyleHeader: {
    fontFamily: "GoogleSansRegular",
    fontWeight: 400,
    color: "#5F6166",
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: 16,
    width: 170,
    marginBottom: "8px",
  },
});

export interface CommonProps {
  onUpdate(value: number | number[]): void;
  value: number;
}

export interface SliderModeProps extends CommonProps, SliderProps {
  mode: Mode.Slider;
}

export interface ButtonModeProps extends CommonProps, ButtonProps {
  mode?: Mode.Buttons;
}

export interface SliderProps extends CommonProps {
  left?: (value: number) => React.ReactNode;
  right?: (value: number) => React.ReactNode;
}

export interface ButtonProps extends CommonProps {
  count?: number;
  renderIcon?: (i: number) => React.ReactNode;
  renderTooltip?: (i: number) => React.ReactChild;
}

export type Props = SliderModeProps | ButtonModeProps;

const defaultLeft = (v: number) => `${Math.round(v * 100)}% Individual`;
const defaultRight = (v: number) => `${100 - Math.round(v * 100)}% Communal`;

export const Control: React.FC<Props> = (props) => {
  const sliderClasses = sliderStyles();
  switch (props.mode) {
    case Mode.Slider:
      return <Slider {...props} classes={sliderClasses} />;
    default:
      return <Buttons {...props} />;
  }
};

export const Slider: React.FC<SliderProps & Pick<MSliderProps, "classes">> = ({
  classes,
  onUpdate,
  value,
  left,
  right,
}) => {
  const renderLeft = left ?? defaultLeft;
  const renderRight = right ?? defaultRight;

  return (
    <Grid item={true} xs={12}>
      <div style={{ paddingTop: 4 }}>
        <MSlider
          classes={classes}
          value={value}
          min={0}
          max={1}
          step={0.001}
          onChange={(_, v) => onUpdate(v)}
        />
        <div style={{ display: "flex" }}>
          <div
            style={{
              color: `rgb(${blue})`,
              flexGrow: 1,
              fontSize: "0.8rem",
            }}
          >
            {renderLeft(value)}
          </div>
          <div
            style={{
              color: `rgb(${orange})`,
              textAlign: "right",
              fontSize: "0.8rem",
            }}
          >
            {renderRight(value)}
          </div>
        </div>
      </div>
    </Grid>
  );
};

const StyledGroup = styled(ToggleButtonGroup)({
  "& .MuiToggleButtonGroup-groupedHorizontal": {
    margin: "0.5rem",
  },
});

const MUIToggleButton = styled(ToggleButton)({
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    backgroundColor: "#E5EFFF",
  },
});

export const Buttons: React.FC<ButtonProps> = ({
  onUpdate,
  value,
  renderIcon,
  renderTooltip,
  count,
}) => {
  const buttonCount = Math.abs(count ?? 3);

  const innerRenderIcon = renderIcon ?? defaultRenderIcon;
  const innerRenderTooltip = renderTooltip ?? defaultRenderTooltip;

  const buttons = [];
  for (let i = 0; i < buttonCount; i++) {
    buttons.push(
      <Tooltip title={innerRenderTooltip(i)} arrow placement="bottom" key={i}>
        <MUIToggleButton
          value={i}
          style={{
            margin: "0px 4px",
            paddingTop: 18,
            width: 32,
            height: 32,
            border: 0,
            borderRadius: 4,
          }}
          onClick={() => {
            onUpdate(i * 0.5);
          }}
          selected={value / 0.5 === i}
          key={i}
        >
          {innerRenderIcon(i)}
        </MUIToggleButton>
      </Tooltip>
    );
  }

  return (
    <Grid item xs={12}>
      <HelpguideWrapperDiv>
        <div
          style={{
            fontFamily: "GoogleSansRegular",
            fontWeight: 400,
            color: "#5F6166",
            fontSize: "12px",
            lineHeight: "16px",
            letterSpacing: "0.4px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: 16,
            width: 185,
            marginBottom: "8px",
          }}
        >
          Preferred Working Style
          <HelpguideHandle isSmall tooltipKey="Preferred Working Style" />
        </div>
      </HelpguideWrapperDiv>
      <StyledGroup exclusive value={value}>
        {buttons}
      </StyledGroup>
    </Grid>
  );
};

const defaultRenderIcon = (idx: number) => <span>{SVGS[idx] ?? idx}</span>;

const defaultRenderTooltip = (idx: number) =>
  TOOLTIPS[idx] ?? "Preferred Working Style";

const SVGS = [
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 8C10.21 8 12 6.21 12 4C12 1.79 10.21 0 8 0C5.79 0 4 1.79 4 4C4 6.21 5.79 8 8 8ZM8 10C5.33 10 0 11.34 0 14V16H16V14C16 11.34 10.67 10 8 10Z"
      fill="#2F73FF"
    />
  </svg>,
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 11C9.66 11 10.99 9.66 10.99 8C10.99 6.34 9.66 5 8 5C6.34 5 5 6.34 5 8C5 9.66 6.34 11 8 11ZM8 13C5.67 13 1 14.17 1 16.5V18C1 18.55 1.45 19 2 19H14C14.55 19 15 18.55 15 18V16.5C15 14.17 10.33 13 8 13Z"
      fill="#2F73FF"
    />
    <path
      d="M18.99 8C18.99 9.66 17.66 11 16 11C14.34 11 13 9.66 13 8C13 6.34 14.34 5 16 5C17.66 5 18.99 6.34 18.99 8Z"
      fill="#FFAF38"
    />
    <path
      d="M15.03 13.05C15.38 13.02 15.71 13 16 13C18.33 13 23 14.17 23 16.5V18C23 18.55 22.55 19 22 19H16.82C16.93 18.69 17 18.35 17 18V16.5C17 15.03 16.21 13.92 15.07 13.09C15.06 13.08 15.05 13.06 15.03 13.05Z"
      fill="#FFAF38"
    />
  </svg>,
  <svg
    width="24"
    height="12"
    viewBox="0 0 24 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 12C0.716667 12 0.479167 11.9042 0.2875 11.7125C0.0958333 11.5208 0 11.2833 0 11V10.425C0 9.69167 0.366667 9.10417 1.1 8.6625C1.83333 8.22083 2.8 8 4 8C4.21667 8 4.42083 8.00833 4.6125 8.025C4.80417 8.04167 4.99167 8.06667 5.175 8.1C4.94167 8.43333 4.77083 8.79167 4.6625 9.175C4.55417 9.55833 4.5 9.96667 4.5 10.4V12H1ZM7 12C6.71667 12 6.47917 11.9042 6.2875 11.7125C6.09583 11.5208 6 11.2833 6 11V10.4C6 9.31667 6.55417 8.4375 7.6625 7.7625C8.77083 7.0875 10.2167 6.75 12 6.75C13.8 6.75 15.25 7.0875 16.35 7.7625C17.45 8.4375 18 9.31667 18 10.4V11C18 11.2833 17.9042 11.5208 17.7125 11.7125C17.5208 11.9042 17.2833 12 17 12H7ZM19.5 12V10.4C19.5 9.96667 19.4417 9.55833 19.325 9.175C19.2083 8.79167 19.0417 8.43333 18.825 8.1C19.0083 8.06667 19.1958 8.04167 19.3875 8.025C19.5792 8.00833 19.7833 8 20 8C21.2 8 22.1667 8.22083 22.9 8.6625C23.6333 9.10417 24 9.69167 24 10.425V11C24 11.2833 23.9042 11.5208 23.7125 11.7125C23.5208 11.9042 23.2833 12 23 12H19.5ZM4 7C3.45 7 2.97917 6.80417 2.5875 6.4125C2.19583 6.02083 2 5.55 2 5C2 4.45 2.19583 3.97917 2.5875 3.5875C2.97917 3.19583 3.45 3 4 3C4.55 3 5.02083 3.19583 5.4125 3.5875C5.80417 3.97917 6 4.45 6 5C6 5.55 5.80417 6.02083 5.4125 6.4125C5.02083 6.80417 4.55 7 4 7ZM20 7C19.45 7 18.9792 6.80417 18.5875 6.4125C18.1958 6.02083 18 5.55 18 5C18 4.45 18.1958 3.97917 18.5875 3.5875C18.9792 3.19583 19.45 3 20 3C20.55 3 21.0208 3.19583 21.4125 3.5875C21.8042 3.97917 22 4.45 22 5C22 5.55 21.8042 6.02083 21.4125 6.4125C21.0208 6.80417 20.55 7 20 7ZM12 6C11.1667 6 10.4583 5.70833 9.875 5.125C9.29167 4.54167 9 3.83333 9 3C9 2.16667 9.29167 1.45833 9.875 0.875C10.4583 0.291667 11.1667 0 12 0C12.8333 0 13.5417 0.291667 14.125 0.875C14.7083 1.45833 15 2.16667 15 3C15 3.83333 14.7083 4.54167 14.125 5.125C13.5417 5.70833 12.8333 6 12 6Z"
      fill="#FFAF38"
    />
  </svg>,
];

const TOOLTIPS = ["Mostly Individual", "50/50 Mixed", "Mostly Communal"];
