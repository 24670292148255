import axios from "axios";
import { setupCache } from "axios-cache-adapter";
import config from "config";
import { getAuthHeader } from "lib/auth";
import { Background } from "lib/types";

const cache = setupCache({
  maxAge: 30 * 60 * 1000,
});

export const getBackground = async (
  buildingID: string,
  floorID: string
): Promise<Background | undefined> => {
  try {
    const headers = await getAuthHeader();
    const api = axios.create({
      headers,
      adapter: cache.adapter,
    });

    const data = await api.get(
      `${config.baseUrl}/api/planning/buildings/${buildingID}/${floorID}/background`
    );

    return data.data;
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

export default getBackground;
