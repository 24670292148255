import { Block, BlockGetter } from "blocks/lib/types";
import { getRegionLengths } from "./utils";
import { v4 as uuid4 } from "uuid";
import { makeBlockInstance } from "../../blocks/lib/instance";
import { mat4, vec3 } from "gl-matrix";
import { getters } from "../../blocks/lib/constants";

export const generatePortfolioBlockInstances = ({
  block,
  region,
  blockRotation,
  getBlockById,
  matrix,
}: {
  block?: Block;
  region: number[][];
  blockRotation: number;
  regionRotation: number;
  matrix?: mat4;
  getBlockById: BlockGetter;
}): Block[] => {
  const [w, h] = getRegionLengths(region);
  if (!block) return [];
  const { sizeRange } = getters.getMetrics(block);
  const { flexibility } = getters.getLayout(block);
  const flex = flexibility || [0, 0];
  let cw = w;
  let ch = h;
  if (sizeRange[0][1] && sizeRange[1][1]) {
    cw = Math.min(w, sizeRange[0][1] + flex[0]);
    ch = Math.min(h, sizeRange[1][1] + flex[1]);
  }
  let m: mat4 = matrix
    ? matrix
    : mat4.fromTranslation(
        mat4.create(),
        vec3.fromValues(region[1][0] - (w - cw), region[1][1] + h - ch, 0)
      );
  if (blockRotation > 0) {
    m = matrix
      ? matrix
      : mat4.fromTranslation(
          mat4.create(),
          vec3.fromValues(region[1][0] - (h - ch), region[1][1] + w - cw, 0)
        );
  }
  const inst = makeBlockInstance(block, m, [cw, ch], getBlockById);
  inst.symbol = block.id;
  inst.id = uuid4();
  return [inst];
};

export default generatePortfolioBlockInstances;
