import React, { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { Mutation, Project, RendererSettings, StrategyMode } from "lib/types";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import Strategy from "components/controls/strategy";
import Pickers from "components/controls/pickers";
import {
  MMUserProfile,
  MMUserProfileMetadata,
  Team,
  useRendererCtrl,
  useSettingsCtrl,
  useStrategyCtrl,
} from "lib/containers";
import { encodeMetricId } from "lib/metrics/id";
import DeskToggle from "components/controls/deskToggle";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../components/sideBar/AccordionComponents";
import ProgramBarChart from "components/sideBar/barChart/ProgramBarChart";
import { getTotalBlockArea } from "lib/metrics/wpi-metric-helpers";
import { ProgramAreaContent } from "components/sideBar/ProgramAreaContent";
import SidebarMatchMakerCard from "./sidebar-matchmaker-card";
import SliderControls from "components/controls/StrategySliders";

export interface Props {
  project: Project;
  onChange(name: string, value: any): void;
  settings: RendererSettings | undefined;
}

const useStyles = makeStyles({
  sideBarHeaderTitle: {
    marginLeft: "15px",
    marginBottom: "15px",
    marginTop: "15px",
    color: "#555555",
    fontSize: "16px",
  },
  metricsContainer: { height: "365px" },
  headerContainer: {
    marginLeft: "17px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  accordionPanelTitleMoreOrLessContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
    height: 40,
    fontFamily: "GoogleSansRegular",
    fontSize: "15.5px",
    color: "#ADADAD",
  },
  accordionPanelIconContainer: {
    color: "#3B3B3B",
    paddingRight: "10px",
  },
  accordionPanelIcon: {
    color: "#ADADAD",
  },
  accordionPanelTitleMoreOrLessTitle: {
    width: "35px",
    color: "#2F73FF",
    fontWeight: "bold",
    fontFamily: "GoogleSansBold",
    fontSize: 14,
  },
  sideBarContent: {
    padding: 16,
  },
  sideBarHeaderContainer: {
    height: "220px",
  },
  accordianSummaryTitle: {
    fontSize: "16.57px",
    lineHeight: "28px",
    letterSpacing: "0.5px",
    color: "#1B1D21",
    fontFamily: "GoogleSansRegular",
  },

  accordianSummarySubtitle: {
    color: "#FFBAA3",
    marginLeft: 20,
  },

  container: {
    display: "flex",
    flexDirection: "column",
    padding: 12,
  },
  wrap: {
    position: "relative",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    overflowY: "auto",
    height: "100%",
  },

  fullWidthAccordion: {
    paddingLeft: 0,
    paddingRight: 0,
  },
});

const SidebarNoSelection: React.FC<Props> = ({
  project,
  settings,
  onChange,
}) => {
  const { buildingID, strategyID, floorID } = useParams<{
    [key: string]: string;
  }>();
  const classes = useStyles();
  const { selectedRegion, selectedRooms } = useRendererCtrl();
  const { saveFloor, updateBuildingMetrics } = useSettingsCtrl();
  const { resetStrategy } = useStrategyCtrl();
  const id = encodeMetricId(buildingID, strategyID, floorID);
  const metric = project.metrics[id];

  const handleSliderHover = (key: string, active: boolean) => {
    onChange("activeControl", active ? key : "");
  };

  const onMetricChange = useCallback(
    async (mutation: Mutation) => {
      if (metric.mode === StrategyMode.Building) {
        await updateBuildingMetrics(mutation, buildingID, strategyID, floorID);
      } else if (metric.mode === StrategyMode.Zone) {
        await saveFloor({
          mutation,
          buildingID,
          strategyID,
          floorID,
          settings,
        });
      }
    },
    [
      buildingID,
      floorID,
      metric.mode,
      settings,
      strategyID,
      saveFloor,
      //saveAllFloors,
      updateBuildingMetrics,
    ]
  );

  // only one panel is allowed to be expanded at any given time
  const [expandedPanel, setExpandedPanel] = useState("");
  const handlePanelChange =
    (panel: string) => (event: any, isExpanded: boolean) => {
      setExpandedPanel(isExpanded ? panel : "");
    };

  const updateScope = async (mutation: Mutation) => {
    await resetStrategy({ buildingID, strategyID, mutation, floorID });
  };

  const blocks = settings?.blocks;
  const totalBlockArea = getTotalBlockArea(blocks);

  if (selectedRegion !== undefined) {
    return null;
  }

  if (selectedRooms !== undefined && selectedRooms.length !== 0) {
    return null;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: 400,
        overflowX: "hidden",
      }}
    >
      <Accordion
        expanded={expandedPanel === "comparison"}
        onChange={handlePanelChange("comparison")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={classes.accordianSummaryTitle}
        >
          Comparison
          <div className={classes.accordianSummarySubtitle}>GIA/Person</div>
        </AccordionSummary>
        <AccordionDetails style={{ width: 400, overflowX: "hidden" }}>
          <ProgramBarChart blocks={blocks} />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expandedPanel === "program-area"}
        onChange={handlePanelChange("program-area")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={classes.accordianSummaryTitle}
        >
          Program Area
          <div className={classes.accordianSummarySubtitle}>
            {Math.round(totalBlockArea).toLocaleString()} SF
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.fullWidthAccordion}>
          <ProgramAreaContent blocks={blocks} />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expandedPanel === "matchmaker"}
        onChange={handlePanelChange("matchmaker")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={classes.accordianSummaryTitle}
        >
          MatchMaker Teams
        </AccordionSummary>
        <AccordionDetails>
          {settings?.blocks.map((block) => {
            return block.map((nestBlock) => {
              return nestBlock.props.instance?.teamInfo ? (
                <SidebarMatchMakerCard
                  team={nestBlock.props.instance.teamInfo.team as Team}
                  mmUserProfileMap={
                    nestBlock.props.instance.teamInfo
                      .matchmakerProfileMap as Record<
                      MMUserProfile,
                      MMUserProfileMetadata
                    >
                  }
                  editable={false}
                  defaultOpen={true}
                />
              ) : null;
            });
          })}
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expandedPanel === "reconfigure"}
        onChange={handlePanelChange("reconfigure")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={classes.accordianSummaryTitle}
        >
          Reconfigure
        </AccordionSummary>
        <AccordionDetails>
          <Strategy
            floorID={floorID}
            mode={metric.mode}
            onMetricChange={updateScope}
            disabled={false}
          />
          <DeskToggle
            disabled={false}
            onMetricChange={onMetricChange}
            desksOff={metric.desksOff}
          />
          <Pickers metric={metric} onMetricChange={onMetricChange} />
          <SliderControls
            handleSliderActive={handleSliderHover}
            onMetricChange={onMetricChange}
            deskSpacing={metric.deskSpacing}
            corridorWidth={metric.corridorWidth}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default React.memo(SidebarNoSelection);
