/**
 * This is NOT a container because those have nesting issues!
 */

// Framework for making a history stack, example code and test cases can be
// found here: https://rentry.co/b9w4p
import { Mutation, Project, RendererSettings } from "lib/types";
import { emptySettingsFactory } from "./settings";

type HistoryStackElement = {
  buildingID: string;
  strategyID: string;
  floorID: string;
  rendererSettings: RendererSettings;
  project: Project;
};

type MetricCbtype = (
  mutation: Mutation,
  buildingID: string,
  strategyID: string,
  floorID: string,
  settings?: RendererSettings
) => Promise<void>;
let metricsCb: MetricCbtype = async () => {};

const MAX_STACK_SIZE = 10;

export const registerMetricsCb = (cb: MetricCbtype) => (metricsCb = cb);

let historyStack: HistoryStackElement[] = [];
let historyPlacement = -1;

export const addToHistoryStack = (arg: HistoryStackElement) => {
  if (historyPlacement === MAX_STACK_SIZE - 1) {
    historyStack.shift();
    historyStack = historyStack.slice(0, historyPlacement + 1).concat(arg);
  } else {
    historyStack = historyStack.slice(0, historyPlacement + 1).concat(arg);
    historyPlacement = historyPlacement + 1;
  }
};

export const historyInitialize = (
  buildingID: string,
  strategyID: string,
  floorID: string,
  project: Project,
  settings?: RendererSettings
) => {
  if (settings) {
    historyStack = [
      { buildingID, strategyID, floorID, rendererSettings: settings, project },
    ];
  } else {
    historyStack = [
      {
        buildingID,
        strategyID,
        floorID,
        rendererSettings: emptySettingsFactory(),
        project,
      },
    ];
  }
  historyPlacement = 0;
};

export const getHistoryBackState = async () => {
  if (historyPlacement < 1) {
    console.warn("cannot go back in history");
    return null;
  }
  historyPlacement = historyPlacement - 1;
  const result = historyStack[historyPlacement];
  const { floorID, buildingID, strategyID, rendererSettings } = result;
  await metricsCb({}, buildingID, strategyID, floorID, rendererSettings);
  return historyStack[historyPlacement];
};

export const getHistoryForwardState = async () => {
  if (historyPlacement > historyStack.length - 2) {
    console.warn("cannot go forward in history");
    return null;
  }
  historyPlacement = historyPlacement + 1;
  const result = historyStack[historyPlacement];
  const { floorID, buildingID, strategyID, rendererSettings } = result;
  await metricsCb({}, buildingID, strategyID, floorID, rendererSettings);
  return historyStack[historyPlacement];
};

export const historyIsInitialized = () => {
  return historyPlacement > -1;
};

export const backEnabled = () => {
  return historyPlacement > 0;
};

export const forwardEnabled = () => {
  return historyPlacement < historyStack.length - 1;
};
