import React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Option, Metric } from "lib/types";
import { HelpguideHandle, HelpguideWrapperDiv } from "@outerlabs/helpguide";

import { legendColorCode } from "renderer/legend";

interface Props {
  onChange: (apiName: string, name: string) => void;
  name: string;
  label: string | React.ReactNode;
  options?: Option[];
  helpguideTooltip?: string;
  disabled?: boolean;
  metric: Metric;
  value: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      margin: "16px 0px 0px",
      width: "100%",
    },
    select: {
      minHeight: 32,
      minWidth: 196,
      width: "200px",
      border: 0,
      backgroundColor: "#EFF2F4",
      "& > fieldset": {
        border: 0,
      },
      "& > div": {
        padding: "8px 20px 8px 8px",
        fontFamily: "GoogleSansRegular",
        color: "#2B2020",
      },
      "& $listItemIcon": {
        display: "none !important",
      },
    },
    titleContainer: {
      fontSize: 12,
      position: "relative",
      width: 200,
      lineHeight: "18px",
      fontFamily: "GoogleSansRegular",
      color: "#555555",
    },
    titleContainerDisabled: {
      fontSize: 12,
      position: "relative",
      width: 200,
      lineHeight: "18px",
      fontFamily: "GoogleSansRegular",
      color: "#ADADAD",
    },
    legendIcon: {
      width: 10,
      height: 10,
      borderRadius: "50%",
    },
    listItemIcon: {
      width: 27,
      minWidth: 27,
    },
  })
);

// For changing all meeting rooms of a particular type at once.
const MultiPicker: React.FC<Props> = ({
  onChange,
  name,
  label,
  helpguideTooltip = "",
  disabled = false,
  value,
}) => {
  const classes = useStyles();
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const apiName = event.target.value as string;
    onChange(apiName, name);
  };

  return (
    <HelpguideWrapperDiv>
      <div className={classes.container} key={name}>
        <div
          className={
            disabled ? classes.titleContainerDisabled : classes.titleContainer
          }
        >
          {label}
          {helpguideTooltip && (
            <div style={{ position: "absolute", right: 0, top: 0 }}>
              {<HelpguideHandle tooltipKey={helpguideTooltip} isSmall />}
            </div>
          )}
        </div>
        <Select
          id={name}
          disabled={disabled}
          displayEmpty={true}
          variant="outlined"
          onChange={handleChange}
          className={classes.select}
          value={value}
        >
          <MenuItem key={"No Change"} value={"noChange"}>
            <ListItemIcon className={classes.listItemIcon} />
            No change
          </MenuItem>
          <MenuItem key={"Mixed"} value={"mixed"} style={{ display: "none" }}>
            <ListItemIcon className={classes.listItemIcon} />
            Mixed
          </MenuItem>
          <MenuItem key={"Collaboration"} value={"convertToCollaboration"}>
            <ListItemIcon className={classes.listItemIcon}>
              <div
                className={classes.legendIcon}
                style={{ background: legendColorCode.Collaboration }}
              />
            </ListItemIcon>
            Collaboration
          </MenuItem>
          <MenuItem key={"WorkStation"} value={"convertToWorkstations"}>
            <ListItemIcon className={classes.listItemIcon}>
              <div
                className={classes.legendIcon}
                style={{ background: legendColorCode.Focus }}
              />
            </ListItemIcon>
            Focus Team Space
          </MenuItem>
          <MenuItem key={"Disable"} value={"disable"}>
            <ListItemIcon className={classes.listItemIcon}>
              <div
                className={classes.legendIcon}
                style={{ background: legendColorCode.Disabled }}
              />
            </ListItemIcon>
            Disabled
          </MenuItem>
        </Select>
      </div>
    </HelpguideWrapperDiv>
  );
};

export default MultiPicker;
