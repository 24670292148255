import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import { DisplayTableRowMetric } from "lib/types";
import CellSet, {
  NewStrategyCell,
  HeaderChevronCellSet,
  VisualizeCell,
} from "./cells";

import { useProjectCtrl } from "../../lib/containers";
import { useParams } from "react-router-dom";

const useStyles = makeStyles(() =>
  createStyles({
    row: {
      width: "100%",
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      display: "flex",
      flexDirection: "row",
      cursor: "pointer",
      minWidth: 1440,
    },
    collapseCell: {
      padding: 0,
      width: "100%",
    },
    collapseRow: {
      width: "100%",
      backgroundColor: "#F6FAFE",
    },
  })
);

interface RowProps {
  rows: DisplayTableRowMetric[];
  updateComponent: number;
}

export function Row(props: {
  children: React.ReactNode;
  onClick?: () => void;
  "data-testid"?: string;
}) {
  const classes = useStyles();
  const { children, onClick, "data-testid": dataTestId } = props;
  return (
    <div className={classes.row} onClick={onClick} data-testid={dataTestId}>
      {children}
    </div>
  );
}

export default function RowSet(props: RowProps) {
  const { rows } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { project } = useProjectCtrl();
  const { id } = useParams<{ id: string }>();
  let primaryRow = 0;

  rows.forEach((row, i) => {
    if (project?.primaryStrategies[row.buildingID] === row.strategyID) {
      primaryRow = i;
    }
  });

  return (
    <React.Fragment>
      <Row
        key={`${rows[0].buildingID}-d`}
        onClick={() => {
          setOpen(!open);
        }}
        data-testid="strategies-table-building-row"
      >
        <HeaderChevronCellSet open={open} setOpen={setOpen} />
        <CellSet row={rows[primaryRow]} isHeader />
      </Row>

      <div className={classes.collapseRow}>
        <div className={classes.collapseCell}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {rows.map((row) => {
              const floorID =
                project && project.buildings[row.buildingID]
                  ? project.buildings[row.buildingID].AvailableFloors[0]
                  : "1";
              const redirectURL = `/${id}/building/${row.buildingID}/${row.strategyID}/${floorID}`;
              return (
                <Row
                  key={`${row.strategyID}-${floorID}`}
                  data-testid="strategies-table-strategy-row"
                >
                  <VisualizeCell />
                  <CellSet
                    editable
                    collapsedRow={true}
                    row={row}
                    redirectURL={redirectURL}
                    isPrimary={
                      project?.primaryStrategies[row.buildingID] ===
                      row.strategyID
                    }
                  />
                </Row>
              );
            })}

            <Row key={`${rows[0].buildingID}-c`} onClick={() => {}}>
              <NewStrategyCell buildingID={rows[0].buildingID} />
            </Row>
          </Collapse>
        </div>
      </div>
    </React.Fragment>
  );
}
