import * as React from "react";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import TextField from "./textfield";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 0,
    "& button": {
      marginRight: 8,
    },
    width: "max-width",
  },
}));

interface Option {
  inputValue?: string;
  value: string;
  title: string;
}

type AutocompleteProps = {
  label: string;
  options: Option[];
  onChange: (value: string) => void;
  value: string;
  alreadyExistingCustomValue?: string;
  disable?: boolean;
};
const filter = createFilterOptions<Option>();

const AutocompleteWrapper = ({
  label,
  options,
  onChange,
  value,
  alreadyExistingCustomValue,
  disable,
}: AutocompleteProps) => {
  const classes = useStyles();
  const [maybeCustomOption, setMaybeCustomOption] = React.useState<
    Option | undefined
  >(
    alreadyExistingCustomValue
      ? {
          value: alreadyExistingCustomValue,
          title: `${alreadyExistingCustomValue} $/GSF`,
        }
      : undefined
  );
  const active = (
    maybeCustomOption ? options.concat(maybeCustomOption) : options
  ).find((option) => option.value === value);
  return (
    <Autocomplete
      value={
        !active || value === ""
          ? { title: "", value: "", inputValue: "" }
          : active
      }
      classes={{ root: classes.root }}
      onChange={(event, newValue, reason) => {
        const changeAndRemember = (s: string) => {
          setMaybeCustomOption({
            value: s,
            title: `Custom - $${s}/GSF`,
          });
          onChange(s);
        };
        if (typeof newValue === "string") {
          changeAndRemember(newValue);
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          changeAndRemember(newValue.inputValue);
        } else if (newValue) {
          changeAndRemember(newValue.value);
        } else if (reason === "clear") {
          changeAndRemember("");
        }
      }}
      freeSolo={true}
      filterOptions={(_options, params) => {
        const filtered = filter(_options, params);
        // Suggest the creation of a new value
        if (
          params.inputValue !== "" &&
          !_options.find((el) => el.value === params.inputValue)
        ) {
          filtered.push({
            inputValue: params.inputValue,
            value: `Add "${params.inputValue}"`,
            title: `Add "${params.inputValue}"`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      //clearOnBlur
      handleHomeEndKeys
      options={options as Option[]}
      getOptionSelected={(option, _value) => option.value === _value.value}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        if (option.title) return option.title;
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.title;
      }}
      renderOption={(option) => option.title}
      renderInput={(params) => <TextField {...params} label={label} />}
      disabled={disable}
      //autoSelect={true}
    />
  );
};

export default AutocompleteWrapper;
