import React from "react";
import { mdiCursorDefaultOutline } from "@mdi/js";
import { makeStyles } from "@material-ui/core/styles";
import PanToolOutlined from "@material-ui/icons/PanToolOutlined";
import ZoomInOutlined from "@material-ui/icons/ZoomInOutlined";
import ZoomOutOutlined from "@material-ui/icons/ZoomOutOutlined";
import ZoomOutMapOutlinedIcon from "@material-ui/icons/ZoomOutMapOutlined";
import {
  useRendererCtrl,
  RendererMode,
  useSettingsCtrl,
  emptySettingsFactory,
} from "lib/containers";
import ToolbarIconButton from "components/toolbar/toolbar-icon-button";
import { Project, RendererSettings, StrategyMode } from "lib/types";
import { useParams } from "react-router-dom";
import Icon from "@mdi/react";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import { Divider, Menu, MenuItem } from "@material-ui/core";
import getFeatures from "lib/api/getFeatures";
import { encodeMetricId } from "lib/metrics/id";
import { getSettingsFile } from "lib/api/rendererSettings";
import { v4 as uuidv4 } from "uuid";
import { Block } from "blocks/lib/types";

export const ZOOM_CANVAS_STEP = 1.6;
export interface Props {
  project: Project;
  floors: string[];
  settings: RendererSettings | undefined;
  onChangeFloors(value: string): void;
}

const ToolbarNavButtons: React.FC<Props> = ({
  project,
  floors,
  settings,
  onChangeFloors,
}) => {
  const {
    setRendererMode,
    updateXform,
    resetXform,
    rendererMode,
    selectRegion,
    selectedRegion,
  } = useRendererCtrl();
  const { buildingID, strategyID, floorID } = useParams<{
    [key: string]: string;
  }>();
  const metricId = encodeMetricId(buildingID, strategyID, floorID);
  const { mode } = project.metrics[metricId];
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { saveFloor } = useSettingsCtrl();
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);
  const zoomBy = (step: number) => {
    updateXform((xform) => {
      const translation = { x: step * 14, y: -step * 12 };
      const scale = Math.min(Math.max(xform.scale - step * -0.01, -1), -0.1);
      return { scale, translation };
    });
  };
  const projectID = project.id;

  const onCopyToAnotherFloor = async (_floorID: string) => {
    let destinationFloorSettings: RendererSettings | undefined =
      await getSettingsFile(project.id, buildingID, _floorID, strategyID);

    //if there is no setting file - create one with an empty instances key
    if (!destinationFloorSettings) {
      destinationFloorSettings = emptySettingsFactory();
    }

    if (
      settings !== undefined &&
      selectedRegion !== undefined &&
      destinationFloorSettings !== undefined
    ) {
      const selectedInstances = selectedRegion.map(
        (regionIndex) => settings.blocks[regionIndex]
      );

      const destinationBlocks: Block[][] = [];

      // make a copy of all blocks from the origin floorplan and push to
      // array for new floorplan
      selectedInstances.forEach((instance) => {
        const instCopy = JSON.parse(JSON.stringify(instance));
        instCopy[0].id = uuidv4();
        destinationBlocks.push(instCopy);
      });

      let newDestinationFloorInstances;
      if (destinationFloorSettings && destinationFloorSettings.blocks) {
        newDestinationFloorInstances = [
          ...destinationFloorSettings.blocks,
          ...destinationBlocks,
        ];
      } else {
        newDestinationFloorInstances = [...destinationBlocks];
      }

      selectRegion(undefined);
      handleClose();
      // assign new blocks and block changes so blocks will persist on the new floor
      destinationFloorSettings.blocks = newDestinationFloorInstances;
      destinationFloorSettings.blockChanges = { delete: [] };

      await saveFloor({
        settings: destinationFloorSettings,
        buildingID,
        floorID: _floorID,
        strategyID,
        projectID,
      });
      await onChangeFloors(_floorID);
    }
  };

  const useStyles = makeStyles({
    icon: {
      width: "18px",
      height: "18px",
    },
    copyIcon: {
      transform: "rotate(90deg)",
      width: "20px",
      height: "20px",
    },
    divider: {
      width: "53%",
      marginTop: "15px",
      marginBottom: "10px",
    },
    cursorIcon: {
      position: "absolute",
      width: "20px",
      height: "20px",
    },
  });
  const classes = useStyles();
  const onZoomFit = async () => {
    selectRegion(undefined);
    setRendererMode(RendererMode.Pan);
    const features = await getFeatures(buildingID, floorID);
    if (!features) return;
    resetXform(features);
  };
  const onPanTool = () => {
    selectRegion(undefined);
    setRendererMode(RendererMode.Pan);
  };
  const onZoomIn = () => {
    selectRegion(undefined);
    zoomBy(-ZOOM_CANVAS_STEP);
    setRendererMode(RendererMode.Pan);
  };
  const onZoomOut = () => {
    selectRegion(undefined);
    zoomBy(ZOOM_CANVAS_STEP);
    setRendererMode(RendererMode.Pan);
  };
  const onSelectTool = () => {
    setRendererMode(RendererMode.Select);
  };
  const onDeleteRegion = () => {
    selectRegion(undefined);
    setRendererMode(RendererMode.Delete);
  };

  return (
    <React.Fragment>
      <ToolbarIconButton title="Zoom In" onClick={onZoomIn}>
        <ZoomInOutlined className={classes.icon} />
      </ToolbarIconButton>
      <ToolbarIconButton title="Zoom Out" onClick={onZoomOut}>
        <ZoomOutOutlined className={classes.icon} />
      </ToolbarIconButton>
      <ToolbarIconButton title="Zoom to Fit" onClick={onZoomFit}>
        <ZoomOutMapOutlinedIcon className={classes.icon} />
      </ToolbarIconButton>
      <ToolbarIconButton
        title="Pan"
        onClick={onPanTool}
        selected={rendererMode === RendererMode.Pan}
      >
        <PanToolOutlined className={classes.icon} />
      </ToolbarIconButton>

      <ToolbarIconButton
        title="Select Block"
        onClick={onSelectTool}
        disabled={mode !== StrategyMode.Zone}
        selected={
          rendererMode === RendererMode.Select ||
          rendererMode === RendererMode.Move
        }
      >
        <Icon
          path={mdiCursorDefaultOutline}
          size={"16px"}
          className={classes.cursorIcon}
        />
      </ToolbarIconButton>

      <Divider variant="middle" className={classes.divider} />

      <ToolbarIconButton
        title="Delete Region"
        onClick={onDeleteRegion}
        disabled={mode !== StrategyMode.Zone}
        selected={rendererMode === RendererMode.Delete}
      >
        <DeleteOutlinedIcon className={classes.icon} />
      </ToolbarIconButton>
      <ToolbarIconButton
        title="Copy to Another Level"
        onClick={handleMenuOpen}
        disabled={selectedRegion === undefined || floors.length === 1}
      >
        <CompareArrowsIcon className={classes.copyIcon} />
      </ToolbarIconButton>
      <Menu
        elevation={1}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            marginLeft: "-20px",
          },
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {floors
          .filter((floor) => floor !== floorID)
          .map((floor) => (
            <MenuItem key={floor} onClick={() => onCopyToAnotherFloor(floor)}>
              Floor {floor}
            </MenuItem>
          ))}
      </Menu>
    </React.Fragment>
  );
};

export default React.memo(ToolbarNavButtons);
