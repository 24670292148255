import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";

interface IconProps {
  title?: string;
  disabled?: boolean;
  onClick?: any;
  selected?: boolean;
}

const useStyles = makeStyles({
  icon: {
    width: 32,
    height: 32,
    color: "1px solid rgba(0,0,0,0.6)",
    margin: 2,
  },
  selected: {
    width: 32,
    height: 32,
    color: "1px solid rgba(0,0,0,0.6)",
    margin: 2,
    backgroundColor: "rgba(0,0,0,0.05)",
  },
});

const ToolbarIconButton: React.FC<IconProps> = ({
  children,
  title,
  disabled,
  onClick,
  selected,
}) => {
  const classes = useStyles();
  return title ? (
    <Tooltip title={title} placement="left">
      <span>
        <IconButton
          className={selected ? classes.selected : classes.icon}
          disabled={disabled}
          onClick={onClick}
        >
          {children}
        </IconButton>
      </span>
    </Tooltip>
  ) : (
    <IconButton
      className={selected ? classes.selected : classes.icon}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </IconButton>
  );
};

export default ToolbarIconButton;
