import React from "react";
import { Canvas } from "@outerlabs/canvas-reconciler";
import {
  ConferenceRooms,
  SelectedMeetingRoom,
  UserConvertedMeetingRooms,
} from "lib/types";
import { renderMeetingRoomFeature } from "lib/isp-canvas";
import { translateFeatureToValue } from "lib/isp-canvas/utils";
import { colors } from "lib/isp-canvas/constants";

interface Props {
  conferenceRooms?: ConferenceRooms;
  meetingSettings: { [key: string]: string | null };
  selectedRoom?: SelectedMeetingRoom;
  selectedRooms?: SelectedMeetingRoom[];
  userConvertedMeetingRooms?: UserConvertedMeetingRooms;
  meetingRoomMetricSelectedRoom: string | null;
}

const addTransparency = (color: string) => `${color}40`;
const meetingColor = (
  meetingSettings: { [key: string]: string | null },
  key: string
): string => {
  switch (meetingSettings[key]) {
    case "convert":
      return colors.teamColor;
    case "convertToCollaboration":
      return colors.collabColor;
    case "disable":
      return colors.disableColor;
    default:
      return colors.meetingColor;
  }
};

export const MeetingRooms: React.FC<Props> = ({
  selectedRooms,
  conferenceRooms,
  meetingSettings,
  meetingRoomMetricSelectedRoom,
}) => {
  const canvas = (
    <Canvas
      render={(ctx: CanvasRenderingContext2D) => {
        if (conferenceRooms) {
          Object.keys(conferenceRooms).forEach((key) => {
            let color = meetingColor(
              meetingSettings,
              translateFeatureToValue(key)
            );
            Object.values(conferenceRooms[key]).forEach((value, i) => {
              let selected;
              let strokeColor;
              const roomID = Object.keys(conferenceRooms[key])[i];
              const showCount =
                meetingSettings[roomID] === "convertToCollaboration" ||
                meetingSettings[roomID] === "convert";
              //program type is obtained differently for the group vs  individully converted rooms
              const program = meetingSettings[roomID]
                ? meetingSettings[roomID]
                : meetingSettings[key];
              if (
                selectedRooms !== undefined &&
                selectedRooms.some(
                  (room) => room.conferenceRoomId === String(roomID)
                )
              ) {
                const c = meetingColor(meetingSettings, roomID);
                color = addTransparency(c);
                selected = true;
                strokeColor = c;
              } else {
                color = meetingColor(meetingSettings, roomID);
                selected = false;
                strokeColor = color;
              }

              // If this is selected, lighten lower-level meeting room render,
              // so that the above level can be transparent above a white bg
              if (meetingRoomMetricSelectedRoom === roomID) color = "#ffffff";
              renderMeetingRoomFeature(
                ctx,
                value,
                color,
                key,
                strokeColor,
                showCount,
                program,
                selected
              );
            });
          });
        }
      }}
    />
  );

  return <>{canvas}</>;
};

export default MeetingRooms;
