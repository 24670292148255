import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import { useBlocksCtrl } from "lib/containers";
import { Block } from "../lib/types";
import Select from "./select";
import { FormControl, Grid, InputLabel, MenuItem } from "@material-ui/core";
import BlockList from "./block-list";
import fuzzysearch from "fuzzysearch";
import { getters } from "../lib/constants";
import { getCached, setCached } from "blocks/lib/util";

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  filters: {
    display: "flex",
  },
  list: {
    border: "1px solid #e8e8e8",
  },
}));

const sortOptions = [
  { title: "Alphabetical", value: "alpha" },
  { title: "Seats", value: "seats" },
  { title: "Workpoints", value: "workpoints" },
  { title: "Smallest", value: "small" },
  { title: "Largest", value: "large" },
];

const BlockLibrary = ({
  type: blockType = "asset",
  onClick,
}: {
  type: string;
  onClick: (id: string) => void;
}) => {
  const { blocks, blockLibraries, setFilteredLibraries } = useBlocksCtrl();
  const classes = useStyles();
  const [filteredBlocks, setFilteredBlocks] = React.useState<Block[]>([]);
  // const [type, setType] = React.useState<string[]>([]);
  const [sort, setSort] = React.useState<string>(
    getCached<string>("block-library--sort", "seats")
  );
  // const [sliderValue, setSliderValue] = React.useState<number>(0);
  const [search] = React.useState<string>("");
  const [library, setLibrary] = React.useState<string[]>(
    getCached<string[]>(`block-library--${blockType}-library`, [])
  );

  useEffect(() => {
    const lower = search.toLowerCase();
    let filtered = blocks.filter((block) => {
      // if (type.length && (!block.props.definition?.type || type.indexOf(block.props.definition.type) === -1)) return false;
      if (!block.role || blockType.indexOf(block.role) === -1) return false;
      if (
        library.length &&
        (!block.props.definition?.library ||
          library.indexOf(block.props.definition.library) === -1)
      )
        return false;
      if (
        search !== "" &&
        !fuzzysearch(lower, block.props.name?.toLowerCase() || "")
      )
        return false;
      return true;
    });
    if (sort === "alpha") {
      filtered = filtered.sort((a, b) => {
        const { name: aName } = getters.getDefinition(a);
        const { name: bName } = getters.getDefinition(b);
        return aName > bName ? 1 : aName < bName ? -1 : 0;
      });
    } else if (sort === "individual") {
      filtered = filtered.sort((a, b) => {
        const { types: ta } = getters.getMetrics(a);
        const { types: tb } = getters.getMetrics(b);
        const va = ta.focus ? ta.focus.area : 0;
        const vb = tb.focus ? tb.focus.area : 0;
        return va > vb ? -1 : va < vb ? 1 : 0;
      });
    } else if (sort === "communal") {
      filtered = filtered.sort((a, b) => {
        const { types: ta } = getters.getMetrics(a);
        const { types: tb } = getters.getMetrics(b);
        const va = ta.collaboration ? ta.collaboration.area : 0;
        const vb = tb.collaboration ? tb.collaboration.area : 0;
        return va > vb ? -1 : va < vb ? 1 : 0;
      });
    } else if (sort === "small") {
      filtered = filtered.sort((a, b) => {
        const { area: va } = getters.getMetrics(a);
        const { area: vb } = getters.getMetrics(b);
        return va > vb ? 1 : va < vb ? -1 : 0;
      });
    } else if (sort === "large") {
      filtered = filtered.sort((a, b) => {
        const { area: va } = getters.getMetrics(a);
        const { area: vb } = getters.getMetrics(b);
        return va > vb ? -1 : va < vb ? 1 : 0;
      });
    } else if (sort === "workpoints") {
      filtered = filtered.sort((a, b) => {
        const { workpoints: as } = getters.getMetrics(a);
        const { workpoints: bs } = getters.getMetrics(b);
        return as > bs ? -1 : as < bs ? 1 : 0;
      });
    } else if (sort === "seats") {
      filtered = filtered.sort((a, b) => {
        const { seats: as } = getters.getMetrics(a);
        const { seats: bs } = getters.getMetrics(b);
        return as > bs ? -1 : as < bs ? 1 : 0;
      });
    }
    setFilteredBlocks(filtered);
    // }, [blocks, type, blockType, library, search]);
  }, [blocks, blockType, library, search, sort]);

  useEffect(() => {
    setFilteredLibraries(library);
  }, [library, setFilteredLibraries]);

  const onMultipleChange =
    (fn: (value: string[]) => void) =>
    (event: React.ChangeEvent<{ value: unknown }>) =>
      fn(event.target.value as string[]);
  // const onTypeChange = onMultipleChange(setType);
  const onLibraryChange = onMultipleChange(
    setCached<string[]>(`library-${blockType}`, setLibrary)
  );

  const publishedLibraries = blockLibraries.map((option: any) => {
    if (option.state !== "unpublished") {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.title}
        </MenuItem>
      );
    } else return null;
  });

  return (
    <div className={classes.root}>
      <Grid
        container
        className={classes.filters}
        spacing={2}
        style={{ marginBottom: 12 }}
      >
        {/*<Grid item xs={6}>*/}
        {/*  <TextField*/}
        {/*    label={"Search"}*/}
        {/*    fullWidth={true}*/}
        {/*    onChange={(e) => {*/}
        {/*      setSearch(e.target.value);*/}
        {/*    }}*/}
        {/*  />*/}
        {/*</Grid>*/}
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel shrink={true} id="program-label">
              Library
            </InputLabel>
            <Select
              label="Library"
              placeholder={"Library"}
              multiple={true}
              onChange={onLibraryChange}
              value={library}
              displayEmpty={true}
              renderValue={
                ((value: string | string[]) =>
                  value?.length ? (
                    Array.isArray(value) ? (
                      <span>
                        {" "}
                        {value
                          .map(
                            (k) =>
                              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                              blockLibraries.find((el) => el.value === k)!.title
                          )
                          .join(", ")}
                      </span>
                    ) : (
                      <span>
                        {
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          blockLibraries.find((el) => el.value === value)!.title
                        }
                      </span>
                    )
                  ) : (
                    <span>All Libraries</span>
                  )) as any
              }
            >
              {publishedLibraries}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel shrink={true} id="program-label">
              Sort
            </InputLabel>
            {/*<Slider*/}
            {/*  min={0}*/}
            {/*  max={1}*/}
            {/*  step={0.001}*/}
            {/*  onChange={(e, v) => {*/}
            {/*    if (!Array.isArray(v)) setSliderValue(v);*/}
            {/*  }}*/}
            {/*/>*/}
            <Select
              label="Sort"
              placeholder={"Sort"}
              onChange={(e) =>
                setCached<string>("block-library--sort", (v) => setSort(v))(
                  e.target.value as string
                )
              }
              value={sort}
            >
              {sortOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {/*  <Grid item xs={6}>*/}
        {/*    <FormControl fullWidth>*/}
        {/*      <InputLabel shrink={true} id="program-label">*/}
        {/*        Program*/}
        {/*      </InputLabel>*/}
        {/*      <Select label="Program" placeholder={"Program"} multiple={true} onChange={onTypeChange} value={type}>*/}
        {/*        <MenuItem value={"focus"}>Focus</MenuItem>*/}
        {/*        <MenuItem value={"collaboration"}>Collaboration</MenuItem>*/}
        {/*      </Select>*/}
        {/*    </FormControl>*/}
        {/*  </Grid>*/}
      </Grid>
      <BlockList onClick={onClick} blocks={filteredBlocks} sort={sort} />
    </div>
  );
};

export default BlockLibrary;
