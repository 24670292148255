import React, { useEffect } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { makeStyles } from "@material-ui/core/styles";
import Sidebar from "../components/sidebar";
import Canvas from "../components/canvas";
import { useBlocksCtrl } from "lib/containers";
import HelpButton from "../../components/help-button";
import analytics from "../../lib/analytics";
import { useHistory } from "react-router-dom";
import { SideNavBar } from "@outerlabs/ol-ui";
import { defaultValues } from "../../lib/constants";
import Nav from "../components/nav";
import SidebarBlock from "../components/sidebar-block";
import SidebarMain from "../components/sidebar-main";
import { useGAPageView } from "../../lib/hooks/use-ga";

const useStyles = makeStyles(() => ({
  canvas: {
    width: "100%",
    height: "100%",
    background: "rgba(0,0,0,0.04)",
    position: "absolute",
    top: 0,
    left: 400 + 64,
  },
  helpContainer: {
    position: "absolute",
    top: "20px",
    right: "20px",
    zIndex: 999999,
  },
  container: {
    width: "100vw",
    height: "100vh",
  },
  sidebar: {
    display: "fixed",
    top: 0,
    bottom: 0,
    width: 400,
    height: "100%",
    backgroundColor: "white",
    overflow: "auto",
    borderRight: "1px solid #e8e8e8",
  },
}));

const BlockMaker: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  useEffect(() => analytics.blockMakerOpened(), []);
  useGAPageView("Block Edit");

  return (
    <div style={{ display: "flex" }}>
      <SideNavBar
        appIconAlt={"portfolioIcon"}
        appIconSrc={defaultValues.blockIconSrc}
        appIconClick={() => history.push(`/blocks`)}
      >
        <Nav navTab={"edit"} />
      </SideNavBar>
      <div className={classes.container}>
        <div className={classes.helpContainer}>
          <HelpButton
            onClick={() => {
              window.open(
                "https://sites.google.com/google.com/projectclay/clay-learning-center/portfolio",
                "_blank"
              );
            }}
            marginRight
          />
        </div>
        <div className={classes.sidebar}>
          <SidebarWrapper />
        </div>
        <div className={classes.canvas}>
          <AutoSizer>
            {({ height, width }) => {
              return <Canvas height={height} width={width - 400 - 65} />;
            }}
          </AutoSizer>
        </div>
      </div>
    </div>
  );
};

const SidebarWrapper = () => {
  const { activeBlock, activeSubBlock, setSubBlockSymbol, setActiveSubBlock } =
    useBlocksCtrl();
  useEffect(() => () => setActiveSubBlock(""), [setActiveSubBlock]);
  if (!activeBlock) return null;
  return (
    <Sidebar
      title={activeBlock.props.definition?.name || ""}
      subtitle={"Block Maker"}
    >
      {activeSubBlock && activeSubBlock !== "" && (
        <SidebarBlock
          onChangeBlock={setSubBlockSymbol}
          onSelect={setActiveSubBlock}
        />
      )}
      {activeSubBlock === "" && (
        <SidebarMain
          onChangeBlock={setSubBlockSymbol}
          onSelect={setActiveSubBlock}
        />
      )}
    </Sidebar>
  );
};

export default BlockMaker;
