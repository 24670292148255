import IconButton from "@material-ui/core/IconButton";
import React from "react";

const HelpButton: React.FC<{ onClick: () => void; marginRight?: boolean }> = ({
  onClick,
}) => {
  return (
    <IconButton
      size="medium"
      onClick={onClick}
      style={{
        background: "white",
        width: 32,
        height: 32,
        color: "rgba(0, 0, 0, 0.4)",
        boxShadow:
          "0 4px 6px -1px rgba(0,0,0,0.1),0 2px 4px -1px rgba(0,0,0,0.06)",
      }}
    >
      ?
    </IconButton>
  );
};

export default HelpButton;
