import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { useHistory, useParams } from "react-router-dom";
import AutoSizer from "react-virtualized-auto-sizer";
import { NavTabs, StrategyMode } from "lib/types";
import Container from "components/layout/container";
import Sidebar from "components/layout/sidebar";
import Loading from "components/loading";
import SidebarControls from "./sidebar";
import {
  BuzzGradient,
  LegendWrapper,
  MeetingRoomGradient,
  ProgramLegend,
  ActivitiesLegend,
} from "./legend";
import FooterButtons from "components/controls/footer-buttons";
import withRenderer from "lib/hoc/withRenderer";
import { setFloorConfiguration } from "lib/api/floorConfig";
import {
  BlockType,
  RendererMode,
  useBlockInstanceCtrl,
  useBlocksCtrl,
  useBuildingsCtrl,
  useISPTransformCtrl,
  useMatchMakerCtrl,
  useProjectCtrl,
  useRendererCtrl,
  useSettingsCtrl,
} from "lib/containers";
import Toolbar from "components/toolbar";
import { encodeMetricId } from "../lib/metrics/id";
import withBuildings from "../lib/hoc/withBuildings";
import ISPCanvasMiddle from "./isp-canvas-middle";
import ISPCanvasTop from "./isp-canvas-top";
import ISPCanvasBottom from "./isp-canvas-bottom";
import ISPCanvasInteractions from "./isp-canvas-interactions";
import withFeatures from "lib/hoc/withFeatures";
import withBackgrounds from "../lib/hoc/withBackgrounds";
import withBlockInstances from "lib/hoc/withBlockInstances";
import withStrategies from "lib/hoc/withStrategies";
import withSettings from "lib/hoc/withSettings";
import { defaultValues } from "lib/constants";
import { makeStyles } from "@material-ui/core/styles";
import { SideNavBar } from "@outerlabs/ol-ui/dist/components/Nav/SideNavBar";
import SideBarNavGroup from "components/sideNavBar/sideNavBarGroup";
import TopNavBar from "components/topNavBar/topNavBar";
import UserAvatar from "components/user-avatar";
import withAutoMagic from "../lib/hoc/withAutoMagic";
import SimpleSnackbar from "../components/controls/snackBar";
import withBlockLibraries from "../lib/hoc/withBlockLibraries";
import withBlocks from "../lib/hoc/withBlocks";
import withMatchMaker from "../lib/hoc/withMatchMaker";
import MatchMakerList from "./matchmaker-list";
import { getters } from "../blocks/lib/constants";
import { sortRegion } from "../lib/isp-canvas/utils";
import { Block, BlockMatchMakerTeamInfoForProps } from "../blocks/lib/types";
import { mat4, vec3 } from "gl-matrix";
import withBlockImages from "../lib/hoc/withBlockImages";
import { useDrop } from "react-dnd";
import { useGAPageView } from "../lib/hooks/use-ga";
import analytics from "../lib/analytics";
import { ISPCanvasShaders } from "./isp-canvas-shaders";
import { getCached, makeBlockThumbnails } from "../blocks/lib/util";

const useStyles = makeStyles({
  overlay: {
    zIndex: 101,
    width: "100%",
    height: "100%",
    background: "rgba(0,0,0,0.4)",
    position: "absolute",
  },
  viewPort: {
    overflow: "hidden",
    position: "absolute",
    height: "calc(100vh - 72px);",
    left: 450,
    top: 72,
    background: "rgba(0,0,0,0)",
    width: "calc(100vw - 520px)",
  },
  autoSizer: {
    height: "calc(100vh - 72px)",
    width: "calc(100vw - 360px)",
  },
  nav: { position: "absolute", top: "0", left: "0", zIndex: 102 },
});
function useCustomDrop(onDrop: (info: any, xy: number[]) => void) {
  const ref = React.useRef<Element>();
  const [, dropTarget] = useDrop<any, void, unknown>({
    accept: "block",
    drop(item, monitor) {
      const offset = monitor.getSourceClientOffset();
      if (offset && ref.current) {
        const dropTargetXy = ref.current.getBoundingClientRect();
        onDrop(item.data, [
          offset.x - dropTargetXy.left,
          offset.y - dropTargetXy.top,
        ]);
      }
    },
  });

  return (elem: any) => {
    ref.current = elem;
    dropTarget(ref);
  };
}

export const App: React.FC = () => {
  const [metricsLoaded, setMetricsLoaded] = useState<boolean>(false);
  const [availableFloors, setAvailableFloors] = useState<string[]>([]);
  const { buildingID, strategyID, floorID } = useParams<{
    [key: string]: string;
  }>();
  const { project } = useProjectCtrl();
  const {
    setStoreValue,
    setActiveControl,
    offsetX,
    offsetY,
    setFloorplan,
    loaded,
    floorLoaded,
    selectRegion,
    setRendererMode,
    rendererMode,
    selectedRegion,
    dxfDownloadInProgress,
    xform,
    width,
    height,
    setDaylightTexture,
    wpiActivitiesMode,
  } = useRendererCtrl();
  const { buildings } = useBuildingsCtrl();
  const { addInstance } = useBlockInstanceCtrl();
  const { getBlockById } = useBlocksCtrl();
  const { setTransformBlockInstance } = useISPTransformCtrl();
  const { matchMakerActive, matchMakerStep } = useMatchMakerCtrl();
  const history = useHistory();
  const classes = useStyles();
  const {
    saveFloor,
    currentSettings: settings,
    calculatedBuzz,
  } = useSettingsCtrl();
  useGAPageView("Building");
  const drop = useCustomDrop((info, xy) => {
    onCreateBlock(info.id, xy);
  });
  const metricId = encodeMetricId(buildingID, strategyID, floorID);
  // Cache block thumbnails in the background
  useEffect(() => {
    setTimeout(() => {
      const blocks = getCached<Block[]>("blocks", []);
      makeBlockThumbnails(
        blocks.filter((el: any) => el.role === "block"),
        (id) => blocks.find((b: Block) => b.id === id)
      );
    });
  }, []);
  useEffect(() => {
    const loadMetrics = async () => {
      const currBuilding = buildings[buildingID];
      if (project && currBuilding && !metricsLoaded) {
        setMetricsLoaded(true);
        setAvailableFloors(
          currBuilding.AvailableFloors.filter(
            (fc) => project.metrics[encodeMetricId(buildingID, strategyID, fc)]
          )
        );
      }
    };
    loadMetrics();
  }, [
    buildingID,
    metricsLoaded,
    project,
    strategyID,
    floorID,
    buildings,
    setAvailableFloors,
  ]);

  if (!project) return <Loading />;
  const { mode } = project.metrics[metricId];

  const onChange = async (name: string, value: any) => {
    if (name === "floorID") {
      selectRegion(undefined);
      setTransformBlockInstance([]);
      setRendererMode(RendererMode.Pan);
      history.push(
        `/${project.id}/building/${buildingID}/${strategyID}/${value}`
      );
      await setFloorplan({
        projectID: project.id,
        buildingID,
        strategyID,
        floorID: value,
      });
    }
    if (name === "activeControl" && value) setActiveControl(value);
    if (name === "activeControl" && !value) setActiveControl("");
  };

  const onChangeFloors = async (value: string) => {
    history.push(
      `/${project.id}/building/${buildingID}/${strategyID}/${value}`
    );
    if (
      rendererMode === RendererMode.BuzzMetric ||
      rendererMode === RendererMode.DrawCirculation ||
      rendererMode === RendererMode.DeleteCirculation ||
      rendererMode === RendererMode.EditCirculation
    ) {
      setRendererMode(RendererMode.BuzzMetric);
    } else {
      setRendererMode(RendererMode.Select);
    }
    setDaylightTexture("");
    await setFloorplan({
      projectID: project.id,
      buildingID,
      strategyID,
      floorID: value,
    });
  };

  // Runs when you drag a block
  const onCreateBlock = async (id: string, position: number[]) => {
    const block = getBlockById(id);
    if (!block) return;
    // TODO: pos needs to be converted to canvas coordinates

    const pos = [
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      (position[0] * xform!.globalScale - xform!.position.x) *
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        (1 / (-1 * xform!.scale)),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      (position[1] * xform!.globalScale - xform!.position.y) *
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        (1 / xform!.scale),
    ];

    block.matrix = mat4.mul(
      mat4.create(),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      block.matrix!,
      mat4.fromTranslation(mat4.create(), vec3.fromValues(pos[0], pos[1], 0))
    );
    const metrics = getters.getMetrics(block);
    const blockID = block.id;
    const m = 2;
    const region = sortRegion([
      [pos[0], pos[1]],
      [pos[0] + metrics.sizeRange[0][0] + m, pos[1]],
      [
        pos[0] + metrics.sizeRange[0][0] + m,
        pos[1] - metrics.sizeRange[1][0] - m,
      ],
      [pos[0], pos[1] - metrics.sizeRange[1][0] - m],
    ]);
    const b = await addInstance({
      blockType: BlockType.Focus,
      buildingID,
      floorID,
      strategyID,
      region,
      blockID,
    });
    analytics.blockAdded();

    setRendererMode(RendererMode.Select);
    // setTransformBlockInstance(b.blocks);
    // setRendererMode(RendererMode.Selected);
    // selectRegion([b.blocks.length - 1]);
    await saveFloor({
      buildingID,
      strategyID,
      floorID,
      settings: {
        ...settings,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        blockChanges: b!.blockChanges,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        blocks: b!.blocks,
      },
    });
  };

  // runs when matchmaker is being done
  const onCreateBlocks = async (
    blocks: Block[],
    matchmakerTeamInfos: BlockMatchMakerTeamInfoForProps[]
  ) => {
    const center = [
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ((width / 2) * xform!.globalScale - xform!.position.x) *
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        (1 / (-1 * xform!.scale)),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ((height / 2) * xform!.globalScale - xform!.position.y) *
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        (1 / xform!.scale),
    ];
    const itemsPerRow = Math.ceil(blocks.length / 2);
    let maxWidth = 0;
    let maxHeight = 0;
    blocks.forEach((block) => {
      const metrics = getters.getMetrics(block);
      const { size } = metrics;
      if (size[0] > maxWidth) maxWidth = size[0];
      if (size[1] > maxHeight) maxHeight = size[1];
    });
    const d = [
      [-((itemsPerRow * maxWidth) / 2), -maxHeight / 2],
      [-((itemsPerRow * maxWidth) / 2), maxHeight / 2],
    ];
    const res = await Promise.all(
      blocks.map(async (block, i) => {
        const row = Math.floor(i / itemsPerRow);
        const metrics = getters.getMetrics(block);
        const dx = [
          d[row][0] +
            center[0] +
            maxWidth * (i % itemsPerRow) +
            (maxWidth - metrics.size[0]) / 2,
          d[row][1] + center[1] + (maxHeight - metrics.size[1]) / 2,
        ];
        block.matrix = mat4.mul(
          mat4.create(),
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          block.matrix!,
          mat4.fromTranslation(mat4.create(), vec3.fromValues(dx[0], dx[1], 0))
        );
        const blockID = block.id;
        const region = sortRegion([
          [dx[0], dx[1]],
          [dx[0] + metrics.size[0], dx[1]],
          [dx[0] + metrics.size[0], dx[1] + metrics.size[1]],
          [dx[0], dx[1] + metrics.size[1]],
        ]);
        const b = await addInstance({
          blockType: BlockType.Focus,
          buildingID,
          floorID,
          strategyID,
          region,
          blockID,
          blockMutation: {
            props: {
              instance: {
                teamInfo: matchmakerTeamInfos[i],
                instanceId: uuid(),
              },
            },
          },
        });

        return b;
      })
    );
    const newBlocks: Block[][] = [];
    const selected: number[] = [];
    const diff = settings.blocks.length || 0;
    res.forEach((r, i) => {
      if (r) newBlocks.push(r.blocks[r.blocks.length - 1]);
      selected.push(i + diff);
    });

    const mergedBlocks = [...(settings.blocks || []), ...newBlocks];
    setTransformBlockInstance(newBlocks);

    await saveFloor({
      buildingID,
      strategyID,
      floorID,
      settings: { ...settings, blocks: mergedBlocks },
    });

    setRendererMode(RendererMode.Selected);
    selectRegion(selected);
    analytics.blockAdded();
  };

  // Default legend
  let legend: React.ReactNode = <ProgramLegend />;

  if (matchMakerActive) {
    legend = null;
  } else if (wpiActivitiesMode) {
    legend = <ActivitiesLegend />;
  } else if (rendererMode === RendererMode.BuzzMetric) {
    legend = (
      <LegendWrapper>
        <BuzzGradient />
      </LegendWrapper>
    );
  } else if (rendererMode === RendererMode.MeetingRoomMetric) {
    legend = (
      <LegendWrapper>
        <MeetingRoomGradient />
      </LegendWrapper>
    );
  }

  return (
    <Container>
      <TopNavBar UserAvatar={<UserAvatar />} divider />
      <div className={classes.nav}>
        <SideNavBar
          appIconAlt={"portfolioIcon"}
          appIconSrc={defaultValues.appIconSrc}
          appIconClick={() => history.push(`/`)}
        >
          <SideBarNavGroup
            navTab={
              selectedRegion === undefined ? NavTabs.Performance : NavTabs.Zone
            }
          />
        </SideNavBar>
      </div>
      <Sidebar>
        <SidebarControls
          settings={settings}
          project={project}
          onChange={onChange}
          tools={{
            offsetX,
            offsetY,
            onChange: (name: string, value: any) => {
              if (name === "offsetX") {
                setStoreValue("offsetX", +value);
              } else {
                setStoreValue("offsetY", +value);
              }
            },
            onSaveToolData: () => {
              setFloorConfiguration(buildingID, floorID, {
                offsetX,
                offsetY,
              });
            },
          }}
        />
        {legend}
      </Sidebar>
      {matchMakerActive && <div className={classes.overlay} />}
      {matchMakerActive && matchMakerStep === 1 && (
        <MatchMakerList onCreate={onCreateBlocks} />
      )}
      {loaded && floorLoaded && (
        <div className={classes.viewPort} ref={drop} data-testid="isp-canvases">
          <AutoSizer className={classes.autoSizer}>
            {({ height: newHeight, width: newWidth }) => {
              return (
                <>
                  <ISPCanvasShaders
                    settings={settings}
                    height={newHeight}
                    width={newWidth}
                  />
                  <ISPCanvasBottom
                    height={newHeight}
                    width={newWidth}
                    settings={settings}
                  />
                  <ISPCanvasMiddle height={newHeight} width={newWidth} />
                  <ISPCanvasTop
                    height={newHeight}
                    width={newWidth}
                    settings={settings}
                    calculatedBuzz={calculatedBuzz}
                  />
                  <ISPCanvasInteractions
                    height={newHeight}
                    width={newWidth}
                    settings={settings}
                  />
                </>
              );
            }}
          </AutoSizer>
        </div>
      )}
      {(!loaded || !floorLoaded) && (
        <div
          style={{
            padding: 48,
            backgroundColor: "rgba(0, 0, 0,)",
            height: "100%",
          }}
        >
          <Loading />
        </div>
      )}
      <FooterButtons disabled={mode !== StrategyMode.Zone} />
      <Toolbar
        project={project}
        onChangeFloors={onChangeFloors}
        floors={availableFloors}
        settings={settings}
      />
      <SimpleSnackbar open={dxfDownloadInProgress} />
    </Container>
  );
};

export default withBuildings(
  withBackgrounds(
    withFeatures(
      withRenderer(
        withSettings(
          withStrategies(
            withBlockLibraries(
              withBlocks(
                withBlockInstances(
                  withBlockImages(withAutoMagic(withMatchMaker(App)))
                )
              )
            )
          )
        )
      )
    )
  )
);
