/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Coordinate } from "lib/types";
import { addIntersectionAndNode, Graph, GraphNode, NodeType } from "./buzz";
import { distanceToLineWithIntersectionPoint } from "./remove-desks";
import {
  findShortestPath,
  parseGraph,
} from "@outerlabs/buzzsolver/graph/graph";
import {
  EdgeType,
  Graph as WalkabilityGraph,
  GraphNode as SolverGraphNode,
  GraphType,
  GraphEdge as WalkGraphEdge,
  NodeType as SolverNodeType,
  Point3,
} from "@outerlabs/buzzsolver";
import { v4 as uuid4 } from "uuid";

export const findDistanceToCirculation = (
  circulation: Graph,
  point: Coordinate
): {
  distance: number;
  line: string[];
  intersection: Coordinate;
  origin: Coordinate;
} => {
  let closeDistance = Infinity;
  const closestLine: string[] = ["", ""];
  const intersectionPoint: number[] = [0, 0];
  Object.values(circulation).forEach((node) => {
    node.connectsTo.forEach((connection) => {
      const newNode = circulation[connection];

      const { distance, intersection } = distanceToLineWithIntersectionPoint(
        [point.x, point.y],
        [
          [node.position.x, node.position.y],
          [newNode.position.x, newNode.position.y],
        ]
      );
      if (distance < closeDistance) {
        closestLine[0] = node.id;
        closestLine[1] = newNode.id;
        intersectionPoint[0] = intersection[0];
        intersectionPoint[1] = intersection[1];
        closeDistance = distance;
      }
    });
  });
  return {
    distance: closeDistance,
    line: closestLine,
    intersection: { x: intersectionPoint[0], y: intersectionPoint[1] },
    origin: { x: point.x, y: point.y },
  };
};

// Two centroid methods, func on like 65 is in use currently for walkability

// This method is more complicated, but I have more confidence in it for
// irregular polygons, which sometimes appear in these features. Will
// require ordered points, which is how our features are set up anyway
export const findCentroid = (shape: Coordinate[]): Coordinate => {
  const length = shape.length;
  // The centroid of a non-self-intersecting closed polygon defined by n
  // vertices (X0,Y0), (X1,Y1), ..., (Xn-1, Yn-1) is the point (Cx, Cy).
  // Steps for calculating the centroid of a polygon found
  // here: https://en.wikipedia.org/wiki/Centroid

  // To find the centroid, you need to first find the polygons signed area,
  // using the shoelace formula, then using the area, find Cx and Cy
  let area = 0,
    xVal = 0,
    yVal = 0;

  for (let i = 0; i < length - 1; i++) {
    area += shape[i].x * shape[i + 1].y - shape[i + 1].x * shape[i].y;
    xVal +=
      (shape[i].x + shape[i + 1].x) *
      (shape[i].x * shape[i + 1].y - shape[i + 1].x * shape[i].y);
    yVal +=
      (shape[i].y + shape[i + 1].y) *
      (shape[i].x * shape[i + 1].y - shape[i + 1].x * shape[i].y);
  }
  const shoelaceArea = area / 2;
  const Cx = (1 / (6 * shoelaceArea)) * xVal;
  const Cy = (1 / (6 * shoelaceArea)) * yVal;
  return { x: Cx, y: Cy };
};

// This method is simple and intuitive, and does not require ordered points, but fails
// on irregular shaped polygons (L shape in particular really messes with the
// calculation), which some features are.
export const findCentroidSimple = (shape: Coordinate[]): Coordinate => {
  const length = shape.length;
  let xVal = 0,
    yVal = 0;

  for (let i = 0; i < length; i++) {
    xVal += shape[i].x;
    yVal += shape[i].y;
  }
  return { x: xVal / length, y: yVal / length };
};

const mapNodeTypeToWalkabilityNodeType = (t: NodeType): SolverNodeType => {
  if (t === NodeType.Intersection) return SolverNodeType.Node;
  if (t === NodeType.Desk) return SolverNodeType.Source;

  return SolverNodeType.Sink;
};

const makeWalkabilityGraph = (graph: Graph): WalkabilityGraph => {
  const walkGraph: WalkabilityGraph = {
    id: "walkabilityGraph",
    nodes: [],
    edges: [],
    props: {
      type: GraphType.Nav,
    },
  };

  // make nodes
  Object.values(graph).forEach((n) => {
    const pos3: Point3 = [n.position.x, n.position.y, n.position.z];
    const walkNode: SolverGraphNode = {
      id: n.id,
      props: {
        type: mapNodeTypeToWalkabilityNodeType(n.type),
        tags: [],
        buzz: {
          weight: 0,
        },
      },
      point3: pos3,
    };

    if (walkNode.props.type === SolverNodeType.Sink) {
      walkNode.props.tags?.push(`buzz/${n.type}`);
    }

    walkGraph.nodes.push(walkNode);
  });

  const visitedNodes: { [key: string]: boolean } = {};

  Object.values(graph).forEach((n) => {
    visitedNodes[n.id] = true;

    n.connectsTo.forEach((nid) => {
      if (visitedNodes[nid]) return;

      const edge: WalkGraphEdge = {
        id: `${n.id}:${nid}`,
        props: {
          type: EdgeType.Pair,
        },
        to: [n.id, nid],
      };

      walkGraph.edges.push(edge);
    });
  });

  return walkGraph;
};

export const buildGraph = (
  deskMap: Record<
    string,
    { distance: number; line: string[]; intersection: Coordinate }
  >,
  circulation: Graph,
  featureMap: Record<
    string,
    {
      distance: number;
      line: string[];
      intersection: Coordinate;
    }
  >
): WalkabilityGraph => {
  // sets tempGraph to a deep copy of circulation to prevent
  // overwriting actual data.
  const tempGraph: Graph = {};
  Object.keys(circulation).forEach(
    (key) => (tempGraph[key] = { ...circulation[key] })
  );
  Object.values(deskMap).forEach((desk) => {
    const node: GraphNode = {
      id: uuid4(),
      position: {
        x: desk.intersection.x,
        y: desk.intersection.y,
        z: 0,
      },
      type: NodeType.Intersection,
      connectsTo: [],
    };
    const nodePointArr: number[] = [desk.intersection.x, desk.intersection.y];
    addIntersectionAndNode(tempGraph, node, desk.line, nodePointArr);
  });
  Object.values(featureMap).forEach((feature) => {
    const node: GraphNode = {
      id: uuid4(),
      position: {
        x: feature.intersection.x,
        y: feature.intersection.y,
        z: 0,
      },
      type: NodeType.Intersection,
      connectsTo: [],
    };
    const nodePointArr: number[] = [
      feature.intersection.x,
      feature.intersection.y,
    ];
    addIntersectionAndNode(tempGraph, node, feature.line, nodePointArr);
  });

  const formattedGraph: WalkabilityGraph = makeWalkabilityGraph(tempGraph);
  return formattedGraph;
};

export const calcCirculationPathDistance = (
  circulation: WalkabilityGraph,
  desk: {
    distance: number;
    line: string[];
    intersection: Coordinate;
  },
  feature: {
    distance: number;
    line: string[];
    intersection: Coordinate;
  }
) => {
  const tempParsed = parseGraph(circulation);
  const formattedStartNode = tempParsed.nodes.find((obj) => {
    return (
      obj.point3![0] === desk.intersection.x &&
      obj.point3![1] === desk.intersection.y
    );
  });
  const formattedEndNode = tempParsed.nodes.find((obj) => {
    return (
      obj.point3![0] === feature.intersection.x &&
      obj.point3![1] === feature.intersection.y
    );
  });
  const shortestPath = findShortestPath(
    tempParsed,
    formattedStartNode!,
    formattedEndNode!
  )[0];

  let runningDist = 0;
  if (shortestPath) {
    for (let i = 0; i < shortestPath!.length - 2; i++) {
      const p1 = shortestPath[i].point3;
      const p2 = shortestPath[i + 1].point3;
      const dist = Math.sqrt(
        Math.pow(p1![0] - p2![0], 2) + Math.pow(p1![1] - p2![1], 2)
      );
      runningDist += dist;
    }
  }
  // if (!runningDist) {
  //   console.log(tempParsed, formattedStartNode, formattedEndNode);
  //   console.log(
  //     findShortestPath(tempParsed, formattedStartNode!, formattedEndNode!)
  //   );
  // }
  return { runningDist, shortestPath };
};
