import React, { ComponentType, FC } from "react";
import { useParams } from "react-router-dom";
import { RendererProvider } from "lib/containers";

function withRenderer<Props>(Component: ComponentType<Props>): FC<Props> {
  const Consumer: React.FC<Props> = (props) => {
    return <Component {...{ ...props }} />;
  };

  return (props) => {
    const {
      buildingID,
      strategyID,
      floorID,
      id: projectID,
    } = useParams<{ [key: string]: string }>();
    return (
      <RendererProvider
        initialState={{ floorID, strategyID, buildingID, projectID }}
      >
        <Consumer {...{ ...props }} />
      </RendererProvider>
    );
  };
}

export default withRenderer;
