/* eslint-disable no-multi-assign */
import { LayerName, Layers, DXFFile } from "../types";

export const parseFilesLayers = (
  files: DXFFile[],
  layers: LayerName[]
): Layers => {
  const parsed: Layers = {};
  layers.forEach((layer) => {
    files.forEach((file) => {
      let layerName: string | undefined;
      if (file.layers.includes(layer.name)) layerName = layer.name;
      else if (layer.altName && file.layers.includes(layer.altName))
        layerName = layer.altName;

      const item = {
        file: file.name,
        layer: layerName,
      };

      let list = parsed[layer.type];
      if (!list) list = parsed[layer.type] = [];

      parsed[layer.type].push(item);
    });
  });
  return parsed;
};

export const missingLayers = (
  layers: string[],
  expected: LayerName[]
): string[] => {
  const missing = expected.filter(
    (e) =>
      !layers.includes(e.name) &&
      (e.altName ? !layers.includes(e.altName) : true) &&
      e.required
  );
  return missing.map((m) => m.name);
};
