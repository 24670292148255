/**
 * Eventually should/could get a larger set of metrics;
 * for now this basically just parses a json file.
 *
 * Might need to get min and max for all buildings (once we get more.)
 * Based on this requirement, it might be best to keep this data as JSON
 * files committed to git (as opposed to some service.)
 **/

import { getMeetingRoomGradientColor } from "lib/isp-canvas";
import meetingRoomMetrics from "lib/meeting-room-metrics.json";
import { ConferenceRooms, MeetingRoomMetric } from "lib/types";

// Constants / types
export enum MeetingRoomMetricsType {
  Capacity = "capacity",
  HoursBooked = "hoursBooked",
  BookingRate = "bookingRate",
  OccupancyRate = "occupancyRate",
  DistributionBucket = "distributionBucket",
}

export enum MeetingRoomMetricsLevels {
  All = "all",
  Current = "current",
}

export enum MeetingRoomSize {
  Huddle = "huddle",
  Small = "small",
  Medium = "medium",
  Large = "large",
  ExtraLarge = "extraLarge",
  Phone = "phone",
  Interview = "interview",
}
export const MeetingRoomSizes = [
  MeetingRoomSize.Huddle,
  MeetingRoomSize.Small,
  MeetingRoomSize.Medium,
  MeetingRoomSize.Large,
  MeetingRoomSize.ExtraLarge,
  MeetingRoomSize.Phone,
  MeetingRoomSize.Interview,
];
export const ALL_MEETING_ROOMS = "ALL_MEETING_ROOMS";
export type MeetingRoomSizeAndAllType = MeetingRoomSize | "ALL_MEETING_ROOMS";
export const MeetingRoomSizesAndAll: MeetingRoomSizeAndAllType[] = [
  ALL_MEETING_ROOMS,
  ...MeetingRoomSizes,
];

export const MeetingRoomSizeLabels: Record<MeetingRoomSizeAndAllType, string> =
  {
    [ALL_MEETING_ROOMS]: "All Meeting Rooms",
    [MeetingRoomSize.Huddle]: "Huddle",
    [MeetingRoomSize.Small]: "Small",
    [MeetingRoomSize.Medium]: "Medium",
    [MeetingRoomSize.Large]: "Large",
    [MeetingRoomSize.ExtraLarge]: "Extra Large",
    [MeetingRoomSize.Phone]: "Phone",
    [MeetingRoomSize.Interview]: "Interview",
  };
const meetingRoomMetricsArray = meetingRoomMetrics as MeetingRoomMetric[];

export const meetingRoomTypeLabelMap: Record<MeetingRoomMetricsType, string> = {
  capacity: "Capacity",
  bookingRate: "Booking Rate",
  hoursBooked: "Hours Booked",
  occupancyRate: "Occupancy Rate",
  distributionBucket: "Distribution Bucket",
};

// Util function for a highly specific "metric" based on which of these five
// strings is supplied.
export const numberForBucket = (bucket: string) => {
  switch (bucket) {
    case "Not booked":
      return 0;
    case "Underbooked (0-2 hrs)":
      return 0.25;
    case "Low (2-4 hrs)":
      return 0.5;
    case "Moderate (4-6 hrs)":
      return 0.75;
    case "High (6-8 hrs)":
      return 1;
    default:
      throw new Error("Improper booking bucket formatting:" + bucket);
  }
};

// Util function for stringifying our metrics values.
// Right now metrics are either percentages or numbers big enough that
// we can just round them :)
const meetFormat = (n: number, type: MeetingRoomMetricsType) => {
  if (Number.isNaN(n) || n === Infinity) return "";

  let value: string;
  switch (type) {
    case MeetingRoomMetricsType.BookingRate:
    case MeetingRoomMetricsType.OccupancyRate:
      value = Math.floor(n * 100) + "%";
      break;
    case MeetingRoomMetricsType.Capacity:
    case MeetingRoomMetricsType.HoursBooked:
    default:
      value = n.toFixed(0);
      break;
  }
  return value;
};

type MeetingRoomData = {
  data: Array<{ name: string; count: number }>; // Recharts formatted data
  roomIdToDataMap: Record<
    string,
    {
      color: string;
      value: string;
      preciseValue: number;
      size: MeetingRoomSize;
    }
  >;
};

// Meeting rooms rendered on the canvas have a shortened name for the buckets.
const distributionBucketToSimplifiedLabelMap: Record<string, string> = {
  "Not booked": "NB",
  "Underbooked (0-2 hrs)": "Underbooked",
  "Low (2-4 hrs)": "Low",
  "Moderate (4-6 hrs)": "Moderate",
  "High (6-8 hrs)": "High",
};

export const distributionBuckets = [
  "Not booked",
  "Underbooked (0-2 hrs)",
  "Low (2-4 hrs)",
  "Moderate (4-6 hrs)",
  "High (6-8 hrs)",
];

// Primary function of this file; takes in metric specifications and spits
// out data, useful to the UI and also the renderer.
export const getMeetingRoomData: (arg: {
  meetingRoomMetricsType: MeetingRoomMetricsType;
  meetingRoomMetricsSize: MeetingRoomSizeAndAllType;
  meetingRoomMetricsLevels: MeetingRoomMetricsLevels;
  conferenceRooms?: ConferenceRooms;
  buildingID: string;
  floorID?: string;
}) => MeetingRoomData = ({
  meetingRoomMetricsType,
  meetingRoomMetricsSize,
  meetingRoomMetricsLevels,
  conferenceRooms,
  buildingID,
  floorID,
}) => {
  const currentMetrics = meetingRoomMetricsArray
    // Filter down to the building you're currently looking at....
    // not very performant....
    // Probably should do this ahead of time
    .filter((mrm) => {
      return buildingID === mrm.building;
    })
    // Filter down to the room size selected
    .filter((mrm) => {
      if (meetingRoomMetricsSize === ALL_MEETING_ROOMS) return true;
      if (conferenceRooms) {
        const map = conferenceRooms[meetingRoomMetricsSize];
        if (map[mrm.id]) return true;
      }
      return false;
    })
    // Filter down to current floor if only showing current floor's rooms
    .filter((mrm) => {
      if (meetingRoomMetricsLevels === MeetingRoomMetricsLevels.All) {
        return true;
      }
      return mrm.floor.toString() === floorID;
    });

  // Find the size of all meeting rooms
  const sizeMap: Record<string, MeetingRoomSize> = {};

  currentMetrics.forEach((m) => {
    // just default to huddle for errors
    let size: MeetingRoomSize = MeetingRoomSize.Huddle;
    MeetingRoomSizes.forEach((siz) => {
      if (conferenceRooms?.[siz]?.[m.id]) size = siz;
    });
    sizeMap[m.id] = size;
  });

  // The Distribution Bucket metric from Google is sort of a "fake metric,"
  // it's just a string that doesn't seem tied to the other data they gave us.
  // This is the only metric that's not a number.
  // It's one of five predefined categories.
  if (meetingRoomMetricsType === MeetingRoomMetricsType.DistributionBucket) {
    const roomIdToDataMap: Record<
      string,
      {
        color: string;
        value: string;
        preciseValue: number;
        size: MeetingRoomSize;
      }
    > = {};
    const data = distributionBuckets.map((db) => ({ name: db, count: 0 }));

    currentMetrics.forEach((m) => {
      (data[data.map((d) => d.name).indexOf(m.distributionBucket)] as any)
        .count++;

      roomIdToDataMap[m.id] = {
        color: getMeetingRoomGradientColor(
          numberForBucket(m.distributionBucket)
        ),
        value: distributionBucketToSimplifiedLabelMap[m.distributionBucket],
        preciseValue: 0,
        size: sizeMap[m.id] || MeetingRoomSize.Huddle,
      };
    });

    return {
      data,
      roomIdToDataMap,
    };
  }

  // The remaining metrics are all numbers, so we need to find the max, min,
  // and create five buckets to sort them into.

  const allMetricNumbers = currentMetrics.map(
    (mrm) => mrm[meetingRoomMetricsType]
  ) as number[];

  // Find max and min of numbers for metric you're looking at
  const biggest = Math.max(...allMetricNumbers);
  const smallest = Math.min(...allMetricNumbers);

  // Create five equally spaced buckets based on max and min
  const bucketThresholds = [
    smallest,
    smallest + (biggest - smallest) * 0.2,
    smallest + (biggest - smallest) * 0.4,
    smallest + (biggest - smallest) * 0.6,
    smallest + (biggest - smallest) * 0.8,
  ];

  // Build up hashmap for renderer to quickly find a room's color and # value
  // (color is supplied in the next step, dealing with bucket thresholds)
  const roomIdToDataMap: Record<
    string,
    {
      color: string;
      value: string;
      preciseValue: number;
      size: MeetingRoomSize;
    }
  > = {};
  currentMetrics.forEach((m) => {
    const value = meetFormat(m[meetingRoomMetricsType], meetingRoomMetricsType);
    roomIdToDataMap[m.id] = {
      color: "",
      value,
      preciseValue: m[meetingRoomMetricsType],
      size: sizeMap[m.id] || MeetingRoomSize.Huddle,
    };
  });

  // Put each number into a bucket and prepare to put into recharts
  const data = bucketThresholds.map((bt, idx) => ({
    name: `${meetFormat(bt, meetingRoomMetricsType)}-${meetFormat(
      idx === bucketThresholds.length - 1 ? biggest : bucketThresholds[idx + 1],
      meetingRoomMetricsType
    )}`,
    count: 0,
  }));
  currentMetrics.forEach((item) => {
    let indexThresholdPassed = 0;
    for (let i = 0; i < bucketThresholds.length; i++) {
      if (i === bucketThresholds.length - 1) {
        indexThresholdPassed = i;
        break;
      } else if (item[meetingRoomMetricsType] < bucketThresholds[i + 1]) {
        indexThresholdPassed = i;
        break;
      }
    }
    // Increment totalling metric
    data[indexThresholdPassed].count++;
    // Provide color based on threshold to room
    roomIdToDataMap[item.id].color = getMeetingRoomGradientColor(
      indexThresholdPassed / 4
    );
  });

  return {
    roomIdToDataMap,
    data,
  };
};
