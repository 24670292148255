import React, { ComponentType, FC } from "react";
import { useParams } from "react-router-dom";

import { ProjectProvider, useProjectCtrl } from "lib/containers";
import { Project } from "lib/types";
import Loading from "components/loading";
import { encodeMetricId } from "lib/metrics/id";

interface Injected {
  project: Project;
  saveProject: (p: Project) => void;
}

function withProject<Props>(
  Component: ComponentType<Props & Injected>
): FC<Props> {
  const Consumer: React.FC<Props> = (props) => {
    const { project, saveProject } = useProjectCtrl();
    const { buildingID, strategyID, floorID } =
      useParams<{ [key: string]: string }>();
    if (buildingID && strategyID && floorID && project) {
      const id = encodeMetricId(buildingID, strategyID, floorID);
      const metric = project.metrics[id];
      if (!metric) return <Loading />;
    }
    if (!project) return <Loading />;

    return <Component {...{ ...props, project, saveProject }} />;
  };

  return (props) => {
    const { id, strategyID } = useParams<{ [key: string]: string }>();

    return (
      <ProjectProvider initialState={!strategyID ? id : undefined}>
        <Consumer {...props} />
      </ProjectProvider>
    );
  };
}

export default withProject;
