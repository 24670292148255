import { Point } from "@outerlabs/shapes-geometry";
import { polyline } from "./polyline";

export type Rectangle = {
  type: "rectangle";
  points: Point[];
};

export const rectangle = ({ points }: Rectangle, layerName: string): string => {
  const p = points;
  return polyline(
    {
      type: "polyline",
      points: [
        [p[0][0], p[0][1]],
        [p[1][0], p[0][1]],
        [p[1][0], p[1][1]],
        [p[0][0], p[1][1]],
        [p[0][0], p[0][1]],
      ],
    },
    layerName
  );
};
