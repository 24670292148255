import { Graph, GraphNode, NodeType } from "lib/metrics/buzz";
import { v4 as uuid4 } from "uuid";
import {
  distanceToLine,
  distanceToLineWithIntersectionPoint,
} from "lib/metrics/remove-desks";

export const HOVER_TOLERANCE = 50;
export const isMouseOverNode = (
  graph: Graph,
  mx: number,
  my: number
): GraphNode | undefined => {
  const node = Object.values(graph).filter((n) => {
    const {
      position: { x, y },
    } = n;
    return (
      x > mx - HOVER_TOLERANCE &&
      x < mx + HOVER_TOLERANCE &&
      y > my - HOVER_TOLERANCE &&
      y < my + HOVER_TOLERANCE
    );
  });
  return node[0];
};

export const isMouseOverIntersection = (
  graph: Graph,
  mx: number,
  my: number
): GraphNode | undefined => {
  const coords: { x: number | undefined; y: number | undefined } = {
    x: undefined,
    y: undefined,
  };
  const node = {
    id: uuid4(),
    position: {
      x: mx,
      y: my,
      z: 0,
    },
    type: NodeType.Intersection,
    connectsTo: [],
  };
  Object.values(graph).forEach((graphNode) => {
    const {
      position: { x, y },
    } = graphNode;
    graphNode.connectsTo.forEach((id) => {
      const n = graph[id];
      const { distance, intersection } = distanceToLineWithIntersectionPoint(
        [mx, my],
        [
          [x, y],
          [n.position.x, n.position.y],
        ]
      );
      if (distance < HOVER_TOLERANCE) {
        coords.x = intersection[0];
        coords.y = intersection[1];
      }
    });
  });
  if (coords.x) node.position.x = coords.x;
  if (coords.y) node.position.y = coords.y;
  return coords.x || coords.y ? node : undefined;
};

export const isMouseOverLine = (
  graph: Graph,
  mx: number,
  my: number
): GraphNode[] | undefined => {
  const nodes: GraphNode[] = [];
  Object.values(graph).forEach((node) => {
    const {
      position: { x, y },
    } = node;
    node.connectsTo.forEach((id) => {
      const n = graph[id];
      const distance = distanceToLine(
        [mx, my],
        [
          [x, y],
          [n.position.x, n.position.y],
        ]
      );
      if (distance < HOVER_TOLERANCE) {
        nodes.push(node);
        nodes.push(n);
      }
    });
  });
  return nodes.length > 0 ? nodes : undefined;
};
