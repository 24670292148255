import React, { ComponentType, FC } from "react";
import { StrategyProvider } from "../containers";

function withStrategies<Props>(Component: ComponentType<Props>): FC<Props> {
  return (props) => (
    <StrategyProvider>
      <Component {...props} />
    </StrategyProvider>
  );
}

export default withStrategies;
