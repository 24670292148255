import React from "react";
import Typography from "@material-ui/core/Typography";
import { Building } from "lib/types";
import { ParsedDXFFile } from "../types";

interface DXFImportProps {
  files: Record<string, ParsedDXFFile>;
  building: Building;
}

// StepConfirm is the last step in the import process. User confirms the upload
// along with building information.
const StepConfirm: React.FC<DXFImportProps> = ({ building }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ maxWidth: "394px" }}>
        <Typography
          variant="h5"
          style={{
            fontFamily: "GoogleSansRegular",
            fontWeight: "500",
            fontSize: "35px",
            lineHeight: "42px",
            color: "#5F6166",
          }}
        >
          You have imported {building.BuildingId} successfully!
        </Typography>
        <Typography
          style={{
            fontFamily: "GoogleSansRegular",
            fontWeight: "400",
            fontSize: "24px",
            lineHeight: "32px",
            color: "#919499",
          }}
          variant="h6"
        >
          This building has been added to your current Study & the list of
          buildings in Portfolio.
        </Typography>
      </div>
    </div>
  );
};

export default StepConfirm;
