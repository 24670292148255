import React, { ComponentType, FC } from "react";
import { TableProvider } from "../containers";

function withTable<Props>(Component: ComponentType<Props>): FC<Props> {
  return (props) => (
    <TableProvider>
      <Component {...props} />
    </TableProvider>
  );
}

export default withTable;
