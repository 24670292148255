import React from "react";
import IndividualMultiPicker from "components/controls/individual-multi-picker";
import {
  Metric,
  SelectedMeetingRoom,
  UserConvertedMeetingRooms,
  StrategyMode,
} from "lib/types";
import { useRendererCtrl } from "lib/containers";
import { HelpguideHandle, HelpguideWrapperDiv } from "@outerlabs/helpguide";

interface Props {
  onMetricChange(mutation: { [key: string]: any }): void;
  metric: Metric;
  disabled?: boolean;
}

export const buildMutation = (
  rooms: SelectedMeetingRoom[],
  metric: UserConvertedMeetingRooms,
  apiName: string
): UserConvertedMeetingRooms => {
  const newResult: UserConvertedMeetingRooms = {
    convertToCollaboration: [],
    convertToWorkstations: [],
    disable: [],
  };
  Object.keys(metric).forEach((aN: string) => {
    if (metric && metric[aN]) {
      const keptRooms = metric[aN].filter((i: SelectedMeetingRoom) => {
        const includesRooms = rooms.filter(
          (room) => room.conferenceRoomId === i.conferenceRoomId
        );
        return includesRooms.length === 0;
      });
      newResult[aN] = keptRooms;
    }
  });
  if (apiName !== "noChange") {
    return { ...newResult, [apiName]: newResult[apiName].concat(rooms) };
  } else {
    return { ...newResult };
  }
};

// UI for changing an individual meeting room type.
// Utilizes individual-multi-picker.tsx.
const IndividualPicker: React.FC<Props> = ({ metric, onMetricChange }) => {
  const { selectedRooms } = useRendererCtrl();
  const currentOption = () => {
    let currentOperation = "noChange";
    let isSelectionOfTheSameProgram;
    //looks at the room selection set by IDa to determine if selected rooms belong to the same program type - if they do belong to the program choose that program as a current option

    Object.keys(metric.userConvertedMeetingRooms as any).forEach(
      (aN: string) => {
        if (
          metric.userConvertedMeetingRooms !== undefined &&
          metric.userConvertedMeetingRooms[aN] !== undefined &&
          metric.userConvertedMeetingRooms !== null &&
          metric.userConvertedMeetingRooms[aN] !== null &&
          selectedRooms !== undefined
        ) {
          const convertedRoomIds = metric.userConvertedMeetingRooms[aN].map(
            (room) => room.conferenceRoomId
          );
          const selectedRoomIds = selectedRooms.map(
            (room) => room.conferenceRoomId
          );
          const slectionIsOfTheSameProgram = selectedRoomIds.every((room) =>
            convertedRoomIds.includes(room)
          );
          if (slectionIsOfTheSameProgram) {
            isSelectionOfTheSameProgram = true;
            return (currentOperation = aN);
          }
        }
      }
    );

    //if the selection set contains mixed options - choose mixed as a current option
    if (isSelectionOfTheSameProgram === undefined) {
      Object.keys(metric.userConvertedMeetingRooms as any).forEach(
        (aN: string) => {
          if (
            metric.userConvertedMeetingRooms !== undefined &&
            metric.userConvertedMeetingRooms[aN] !== undefined &&
            metric.userConvertedMeetingRooms !== null &&
            metric.userConvertedMeetingRooms[aN] !== null &&
            selectedRooms !== undefined
          ) {
            const convertedIds = metric.userConvertedMeetingRooms[aN].map(
              (room) => room.conferenceRoomId
            );
            const selectedIds = selectedRooms.map(
              (room) => room.conferenceRoomId
            );
            const includesPartial = selectedIds.some((room) =>
              convertedIds.includes(room)
            );
            if (includesPartial) {
              return (currentOperation = "mixed");
            }
          }
        }
      );
    }

    return currentOperation;
  };

  const handleMetricsChange = (apiName: string) => {
    let mutation: any;
    if (apiName === "mixed") return;
    if (
      selectedRooms !== undefined &&
      metric.userConvertedMeetingRooms !== undefined
    ) {
      mutation = buildMutation(
        selectedRooms,
        metric.userConvertedMeetingRooms,
        apiName
      );
    }

    if (onMetricChange) {
      onMetricChange({ userConvertedMeetingRooms: mutation });
    }
  };

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          position: "relative",
          top: 6,
        }}
      >
        <HelpguideWrapperDiv>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                position: "relative",
                color: "#555555",
                fontSize: 14,
                fontFamily: "GoogleSansRegular",
              }}
            >
              Convert to Type
            </div>
            <HelpguideHandle isSmall tooltipKey={"Convert to Type"} />
          </div>
        </HelpguideWrapperDiv>
      </div>
      <div>
        <IndividualMultiPicker
          metric={metric}
          disabled={metric.mode === StrategyMode.Building}
          onChange={handleMetricsChange}
          title={""}
          disabledOption={currentOption()}
          value={currentOption()}
        />
      </div>
    </React.Fragment>
  );
};

export default IndividualPicker;
