import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "../../components/TextField";
import { Building } from "lib/types";
import AddressSearch from "./AddressSearch";
import { Grid, InputAdornment } from "@material-ui/core";
import Compass from "./Compass";

export interface DXFImportProps {
  ready: boolean;
  onReady: (ready: boolean) => void;
  onChangeAddress: (l: { lat: number; lng: number }) => void;
  building: Building;
  updateBuilding: (building: Building) => void;
}

const useStyles = makeStyles({
  container: {
    display: "flex",
  },
  buildingName: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 8,
  },
  row: {
    margin: "-20px 0 -16px",
    display: "flex",
    flexDirection: "row",
    "& > span": {
      margin: "28px 8px 0 8px",
    },
  },
  form: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontSize: 12,
    color: "#5F6166",
  },
  subtitle: {
    fontSize: 12,
    color: "#919499",
  },
  orientation: {
    position: "relative",
    width: "100%",
  },
  compass: {
    position: "absolute",
    right: 12,
    top: 30,
  },
});

const BuildingDetails: React.FC<DXFImportProps> = ({
  building,
  updateBuilding,
  onChangeAddress,
  onReady,
  ready,
}) => {
  const classes = useStyles();
  const [form, setForm] = useState<Partial<Building>>({});

  useEffect(() => {
    if (!Object.keys(form).length && building) {
      setForm(building);
    }
  }, [building, form]);

  const handleBlur = () => {
    updateBuilding({
      AddressLine: form.AddressLine || "",
      BuildingId: form.BuildingId || "",
      Campus: form.Campus || "",
      City: form.City || "",
      CitySite: form.CitySite || "",
      Country: form.Country || "",
      Geo: form.Geo || "",
      Orientation: form.Orientation || 0,
      PostalCode: form.PostalCode || "",
      Region: form.Region || "",
      State: form.State || "",
      District: form.District || "",
      AvailableFloors: form.AvailableFloors || [],
      FirstFloorHeight: form.FirstFloorHeight || 0,
      TypicalFloorHeight: form.TypicalFloorHeight || 0,
      Coordinates: form.Coordinates || { Lat: 0, Lng: 0 },
    });
  };
  const updateTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const regex = /^[^*|":<>[\]{}`\\/()'!@#$%^&-]+$/;
    // if the string doesnt contain any forbidden characters or is empty,
    // update the title.
    if (!regex.test(value) && value !== "") {
      return;
    } else
      setForm((prev) => validate({ ...prev, [name]: value.toUpperCase() }));
  };

  const updateForm = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type } = e.target;
    setForm((prev) =>
      validate({ ...prev, [name]: type === "number" ? parseInt(value) : value })
    );
  };

  const updateFormHeight = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type } = e.target;
    setForm((prev) =>
      validate({
        ...prev,
        [name]: type === "number" ? parseFloat(value) : value,
      })
    );
  };

  const validate = (a: Partial<Building>): Partial<Building> => {
    if (
      !ready &&
      a.Country !== "" &&
      a.Campus !== "" &&
      a.City !== "" &&
      a.AddressLine !== ""
    ) {
      a.BuildingId = `${a.Country}-${a.City}-${a.Campus}`;
      onReady(true);
    } else if (
      (ready && a.Country === "") ||
      a.Campus === "" ||
      a.City === "" ||
      a.AddressLine === ""
    )
      onReady(false);
    return a;
  };

  return (
    <div className={classes.form} onBlur={handleBlur}>
      <div className={classes.buildingName}>
        <div className={classes.title}>Google Building Name</div>
        <div className={classes.row}>
          <TextField
            name="Country"
            placeholder={"XX"}
            value={form.Country || ""}
            onChange={updateTitle}
            style={{ width: 80 }}
            inputProps={{ maxLength: 2 }}
          />
          <span>-</span>
          <TextField
            name="City"
            placeholder={"XXX"}
            value={form.City || ""}
            onChange={updateTitle}
            style={{ width: 100 }}
            inputProps={{ maxLength: 3 }}
          />
          <span>-</span>
          <TextField
            name="Campus"
            placeholder={"XXXXXXXX"}
            value={form.Campus || ""}
            onChange={updateTitle}
          />
        </div>
        <div className={classes.subtitle}>Country - City - Street, #</div>
      </div>
      <AddressSearch
        label="Building Address"
        name="AddressLine"
        placeholder={"City, State, Country"}
        value={form.AddressLine || ""}
        onChange={(value, location) => {
          setForm((prev) => ({
            ...prev,
            AddressLine: value,
            Coordinates: { Lat: location.lat, Lng: location.lng },
          }));
          onChangeAddress(location);
        }}
      />
      <div className={classes.orientation}>
        <TextField
          label="Building Orientation"
          type="number"
          name="Orientation"
          placeholder="Degrees from North"
          onChange={updateForm}
        />
        <div className={classes.compass}>
          <Compass value={form.Orientation || 0} />
        </div>
      </div>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={6}>
          <TextField
            label="Floor to Floor height"
            name="FirstFloorHeight"
            placeholder={"First floor"}
            type="number"
            onChange={updateFormHeight}
            InputProps={{
              endAdornment: (
                <InputAdornment style={{ margin: 0 }} position="end">
                  ft
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item={true} xs={6}>
          <TextField
            name="TypicalFloorHeight"
            placeholder={"Typical floor"}
            type="number"
            onChange={updateFormHeight}
            InputProps={{
              endAdornment: (
                <InputAdornment style={{ margin: 0 }} position="end">
                  ft
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default BuildingDetails;
