import { makeStyles } from "@material-ui/core/styles";
import {
  TextField as MaterialTextField,
  TextFieldProps,
} from "@material-ui/core";
import React from "react";

const useTextFieldStyles = makeStyles(() => ({
  root: {},
}));

const TextField = (p: TextFieldProps) => {
  const classes = useTextFieldStyles();
  return (
    <MaterialTextField
      className={classes.root}
      {...p}
      InputLabelProps={Object.assign({ shrink: true }, p.InputLabelProps)}
      inputProps={Object.assign({ style: { padding: "8px" } }, p.inputProps)}
      InputProps={Object.assign(
        {
          disableUnderline: true,
          style: {
            background: "#F4F4F6",
            borderRadius: "4px",
            paddingLeft: "8px",
          },
        },
        p.InputProps
      )}
    >
      {p.children}
    </MaterialTextField>
  );
};

export default TextField;
