import { format } from "./utils";

export type Text = {
  type: "text";
  x: number;
  y: number;
  h: number;
  r: number;
  value: string;
};

export const text = ({ x, y, h, r, value }: Text): string => {
  return (
    format("0", "TEXT") +
    format("1", value.replace("^", "^ ")) +
    // a caret (^) will cause the DXF to fail, a ^ reduces the ASCII value of
    // the following char by 64 - thus breaking the file. IF NOT FOLLOWED BY SPACE
    // See p237 of Mastering AutoCAD  2000 Objects, D Rudolph
    format("10", x) +
    format("20", y) +
    format("30", 0) +
    format("40", h) +
    format("50", r)
  );
};
