import { mdiAutoFix, mdiLightbulbOnOutline } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import CreateIcon from "@material-ui/icons/Create";
import { Project, StrategyMode } from "lib/types";
import { encodeMetricId } from "lib/metrics/id";
import {
  RendererMode,
  useAutoMagicCtrl,
  useMatchMakerCtrl,
  useRendererCtrl,
  useSettingsCtrl,
} from "lib/containers";
import ToolbarIconButton from "components/toolbar/toolbar-icon-button";
import { ReactComponent as MatchMakerIcon } from "assets/icons/MatchMaker.svg";
import { Undo, Redo } from "@material-ui/icons";
import { Dialog } from "@outerlabs/ol-ui";

export interface Props {
  project: Project;
}

const RegionButtons: React.FC<Props> = ({ project }) => {
  const {
    id: projectID,
    buildingID,
    strategyID,
    floorID,
  } = useParams<{ [key: string]: string }>();
  const { setRendererMode, rendererMode, setShowBuzz, selectRegion } =
    useRendererCtrl();
  const {
    historyGoBack,
    historyGoForward,
    getHistoryBackEnabled,
    getHistoryForwardEnabled,
  } = useSettingsCtrl();
  const { autoMagicBuilding } = useAutoMagicCtrl();
  const { setMatchMakerActive } = useMatchMakerCtrl();
  const metricId = encodeMetricId(buildingID, strategyID, floorID);
  const { mode } = project.metrics[metricId];
  const [modalOpen, setModalOpen] = useState(false);
  const [autoMagicLoading, setAutoMagicLoading] = useState(false);

  const historyBackEnabled = getHistoryBackEnabled();
  const historyForwardEnabled = getHistoryForwardEnabled();

  const onAutoMagicClick = async () => {
    setModalOpen(true);
  };

  const onMatchMakerClick = async () => {
    setRendererMode(RendererMode.Select);
    setMatchMakerActive(true);
  };

  const onAutoMagicProceed = async () => {
    setAutoMagicLoading(true);
    await autoMagicBuilding(projectID, buildingID, strategyID);
    setAutoMagicLoading(false);
    setModalOpen(false);
  };

  const closeModal = () => {
    if (!autoMagicLoading) {
      setModalOpen(false);
    }
  };

  const onDrawRegion = () => {
    setRendererMode(RendererMode.Draw);
  };

  const onAnalyticsClick = async () => {
    setRendererMode(RendererMode.BuzzMetric);
    setShowBuzz(true);
  };

  const onHistoryBack = () => {
    selectRegion(undefined);
    historyGoBack({ projectID, buildingID, strategyID, floorID });
    setRendererMode(RendererMode.Select);
  };

  const onHistoryForward = () => {
    selectRegion(undefined);
    historyGoForward({ projectID, buildingID, strategyID, floorID });
    setRendererMode(RendererMode.Select);
  };

  return (
    <React.Fragment>
      <ToolbarIconButton
        title="Draw"
        onClick={onDrawRegion}
        disabled={mode !== StrategyMode.Zone}
        selected={rendererMode === RendererMode.Draw}
      >
        <CreateIcon />
      </ToolbarIconButton>
      {/*<ToolbarIconButton title="Plan AutoMagically" onClick={onAutoMagicClick} disabled={!canAutoMagic} selected={false}>*/}
      <ToolbarIconButton
        title="MatchMaker"
        onClick={onMatchMakerClick}
        selected={false}
      >
        <MatchMakerIcon className="clay-portfolio::-MuiSvgIcon-root" />
      </ToolbarIconButton>
      <ToolbarIconButton
        title="Plan AutoMagically"
        onClick={onAutoMagicClick}
        selected={false}
      >
        <Icon path={mdiAutoFix} className="clay-portfolio::-MuiSvgIcon-root" />{" "}
        {/*class is available at run time*/}
      </ToolbarIconButton>
      <ToolbarIconButton
        title="Analytics"
        onClick={onAnalyticsClick}
        disabled={false}
        selected={[
          RendererMode.BuzzMetric,
          RendererMode.DeleteCirculation,
          RendererMode.EditCirculation,
          RendererMode.DrawCirculation,
          RendererMode.DaylightMetric,
          RendererMode.WalkabilityMetric,
        ].includes(rendererMode)}
      >
        <Icon
          path={mdiLightbulbOnOutline}
          className="clay-portfolio::-MuiSvgIcon-root"
        />{" "}
        {/*class is available at run time*/}
      </ToolbarIconButton>
      <ToolbarIconButton
        title="Undo"
        onClick={onHistoryBack}
        disabled={!historyBackEnabled}
      >
        <Undo />
      </ToolbarIconButton>
      <ToolbarIconButton
        title="Redo"
        onClick={onHistoryForward}
        disabled={!historyForwardEnabled}
      >
        <Redo />
      </ToolbarIconButton>

      {/*<ToolbarIconButton title="Erase Seats" onClick={onDrawRegion(BlockType.EraseSeats)} selected={false}>*/}
      {/*  <Icon path={mdiEraser} className={classes.icon} style={{ backgroundColor: "#cccccc" }} />*/}
      {/*</ToolbarIconButton>*/}
      <Dialog.Sm
        open={modalOpen}
        title="AutoMagic"
        onSave={onAutoMagicProceed}
        onClose={closeModal}
        loading={autoMagicLoading}
        submitButtonText="Proceed"
      >
        This action will replan the entire building with regions of focus desks.
        You can make manual edits to the regions afterwards.
      </Dialog.Sm>
    </React.Fragment>
  );
};

export default RegionButtons;
