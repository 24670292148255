/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Box,
  Button as MaterialButton,
  IconButton,
  IconButtonProps,
  InputLabel,
  MenuItem,
  Modal,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { HelpguideHandle, HelpguideWrapperDiv } from "@outerlabs/helpguide";
import {
  useFeaturesCtrl,
  useProjectCtrl,
  useRendererCtrl,
} from "lib/containers";
import React, { useEffect, useState } from "react";
import { Coordinate, RendererSettings, RoomBounds } from "lib/types";
import {
  buildGraph,
  calcCirculationPathDistance,
  findCentroidSimple,
  findDistanceToCirculation,
} from "lib/metrics/walkability";
import WalkabilityBarChart from "./walkabilityBarChart";
import { Check, Settings, Person } from "@material-ui/icons";
import Select from "blocks/components/select";
import { Button } from "@outerlabs/ol-ui";
import { getDesksFromBlocks } from "blocks/lib/util";
import { GraphNode } from "@outerlabs/shapes-core";
import { useParams } from "react-router-dom";
import { getSettingsFile } from "lib/api/rendererSettings";
import getFeatures from "lib/api/getFeatures";
import getFloorCirculation from "lib/api/floorCirculation";
import { encodeMetricId } from "lib/metrics/id";
import { calcDesks } from "lib/metrics/remove-desks";
import Tooltip from "components/tooltip";

const useWalkabilityStyles = makeStyles({
  walkabilityDiv: {
    display: "flex",
    width: "100%",
    maxHeight: "100%",
    flexDirection: "column",
  },
  walkabilityDetails: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
  },
  walkabilityDropdown: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  selector: {
    width: 192,
    backgroundColor: "#F4F4F6",
    "& > div": {
      padding: "8px 20px 8px 8px",
      fontFamily: "GoogleSansRegular",
      color: "#2B2020",
    },
  },
  metricOutput: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#4D5055",
    fontSize: 14,
    letterSpacing: 0.5,
    fontFamily: "GoogleSansRegular",
    fontStyle: "normal",
    fontWeight: 400,
  },
  metricContainer: {
    padding: "12px, 0, 12px, 0",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    margin: "24px 0 24px 0",
    minHeight: 280,
  },
  walkabilityScore: {
    color: "#4D5055",
    width: 129,
    height: 24,
    fontFamily: "GoogleSansRegular",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16.5748px",
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    letterSpacing: "0.15px",
  },
  walkabilityHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    height: 32,
  },
  toggle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  toggleText: {
    fontSize: "12px",
    fontWeight: 400,
  },
  buttonActive: {
    background: "#2F73FF",
    borderRadius: "18px",
    color: "white",
    padding: "8px",
    textTransform: "none",
    fontSize: "12px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    gap: "8px",
    width: "100px",
  },
  button: {
    background: "#F0F5FF",
    borderRadius: "18px",
    color: "black",
    padding: "8px",
    textTransform: "none",
    fontSize: "12px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    gap: "8px",
    width: "100px",
  },
  buttonBox: {
    borderBottom: "2px #C1C4C9 solid",
    display: "flex",
    justifyContent: "space-around",
    alignContent: "left",
    paddingTop: "4px",
    paddingBottom: "12px",
  },
  menu: {
    alignContent: "center",
    alignItems: "center",
    width: "250px",
    background: "white",
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    position: "relative",
    left: 465,
    top: 300,
  },
  menuHeader: {
    display: "flex",
    margin: "0px",
    padding: "16px 12px 16px 16px",
    color: "#919499",
    textTransform: "uppercase",
    fontWeight: 700,
    fontSize: "12px",
    lineHeight: "16px",
    flexDirection: "row",
    alignItems: "flex-start",
    gap: "10px",
    letterSpacing: "1.5px",
  },
  headerButtons: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
    justifyItems: "center",
  },
  calcDiv: {
    position: "relative",
    width: "40%",
    left: 227,
  },
  outputHeader: {
    margin: 0,
    color: "#4D5055",
    fontSize: 14,
    fontFamily: "GoogleSansRegular",
    fontStyle: "normal",
    fontWeight: 400,
  },
});

const svgIcon = (color: string) => (
  <svg width="22" height="22" viewBox="0 0 29 29" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 4.04492H6.78873C3.18828 4.04492 0.269531 6.96367 0.269531 10.5641V22.1715C0.269531 25.7719 3.18828 28.6907 6.78873 28.6907H18.3961C21.9965 28.6907 24.9153 25.7719 24.9153 22.1715V14H22.6743V22.1715C22.6743 24.5343 20.7589 26.4497 18.3961 26.4497H6.78873C4.42593 26.4497 2.51051 24.5343 2.51051 22.1715V10.5641C2.51051 8.20133 4.42593 6.2859 6.78873 6.2859H18V4.04492Z"
      fill={color}
    />
    <path
      d="M20.0703 16.8182L23.8885 13L27.0703 16.8182"
      stroke={color}
      strokeWidth="2.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="15"
      cy="5.40039"
      r="4"
      fill="white"
      stroke={color}
      strokeWidth="2.3"
    />
  </svg>
);

const useBlockButtonStyles = makeStyles({
  button: {
    borderRadius: 14,
    padding: 8,
    fontSize: 14,
  },
  selected: {
    backgroundColor: "rgba(0, 0, 0, 0.04)",
  },
});

interface BlockButtonProps extends IconButtonProps {
  selected?: boolean;
}

const BlockButton: React.FC<BlockButtonProps> = ({
  selected,
  children,
  ...props
}) => {
  const classes = useBlockButtonStyles();
  return (
    <IconButton
      className={`${classes.button} ${selected && classes.selected}`}
      {...props}
    >
      {children}
    </IconButton>
  );
};

const stringit: (arg: Coordinate[]) => string = (arg) => {
  return JSON.stringify(arg).slice(0, 20);
};

interface Props {
  revertCirc: () => void;
}

// The amount of time to wait before running walkability to let the dom settle.
const DELAY_BEFORE_SETTLED = 50;

const WalkabilityData: React.FC<Props> = ({ revertCirc }) => {
  const classes = useWalkabilityStyles();
  const {
    walkabilityMetric,
    setWalkabilityMetric,
    walkabilityOutput,
    setWalkabilityOutput,
    setWalkabilityBuildingOutput,
    setSelectedWalkability,
  } = useRendererCtrl();
  const { project } = useProjectCtrl();
  const { currentFeatures } = useFeaturesCtrl();
  const {
    id: projectID,
    buildingID,
    strategyID,
    floorID,
  } = useParams<{ [key: string]: string }>();
  const [focusBuilding, setFocusBuilding] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [isHC, setIsHC] = useState<boolean>(true);
  const [metricOutput, setMetricOutput] = useState<boolean>(false);
  const [buildingFeaturesState, setBuildingFeaturesState] = useState<
    any | undefined
  >();
  const [buildingSettingsState, setBuildingSettingsState] = useState<
    any | undefined
  >();
  const [calculated, setCalculated] = useState<boolean>(false);

  // runs on page load to take snapshot of building settings and features
  useEffect(() => {
    captureBuildingMetrics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // This is to capture all settings and features from a building
  const captureBuildingMetrics = async () => {
    const buildingSettings: { [key: string]: RendererSettings | any } = {};
    const buildingFeatures: { [key: string]: any } = {};

    if (project && project.buildings) {
      const currBuilding = buildingID;
      let buildingFloors: string[];
      // iterate through all the project.buildings to find the building you're
      // actually in
      await Promise.all(
        Object.keys(project.buildings).map(async (building) => {
          // if match found (should always hit this if statement once, would
          // indicate a data issue otherwise)
          if (currBuilding === building) {
            buildingFloors = project.buildings[building].AvailableFloors;

            // iterate over buildingFloors to get settings & features for each floor
            await Promise.all(
              buildingFloors.map(async (floor) => {
                const currFloorSettings = await getSettingsFile(
                  projectID,
                  buildingID,
                  floor,
                  strategyID
                );
                const currFloorFeatures = await getFeatures(buildingID, floor);
                const metricID = encodeMetricId(buildingID, strategyID, floor);
                const metric = project!.metrics[metricID];
                const userConvertedMeetingRooms =
                  metric.userConvertedMeetingRooms;
                if (userConvertedMeetingRooms) {
                  if (
                    userConvertedMeetingRooms.convertToCollaboration.length !==
                    0
                  ) {
                    userConvertedMeetingRooms.convertToCollaboration.forEach(
                      (room) => {
                        const roomType = room.conferenceRoomType;
                        const roomID = room.conferenceRoomId;
                        delete currFloorFeatures.conferenceRooms[roomType][
                          roomID
                        ];
                      }
                    );
                  }
                  if (
                    userConvertedMeetingRooms.convertToCollaboration.length !==
                    0
                  ) {
                    userConvertedMeetingRooms.convertToWorkstations.forEach(
                      (room) => {
                        const roomType = room.conferenceRoomType;
                        const roomID = room.conferenceRoomId;
                        delete currFloorFeatures.conferenceRooms[roomType][
                          roomID
                        ];
                      }
                    );
                  }
                  if (userConvertedMeetingRooms.disable.length !== 0) {
                    userConvertedMeetingRooms.disable.forEach((room) => {
                      const roomType = room.conferenceRoomType;
                      const roomID = room.conferenceRoomId;
                      delete currFloorFeatures.conferenceRooms[roomType][
                        roomID
                      ];
                    });
                  }
                }

                // if the floor hasn't been visited, pull the circulation and
                // force place in an empty settings object
                if (currFloorSettings) {
                  buildingSettings[floor] = currFloorSettings;
                } else {
                  const circ = await getFloorCirculation(buildingID, floor);
                  buildingSettings[floor] = {
                    blocks: [],
                    blockChanges: { put: [], delete: [] },
                    desks: {
                      disabledCirculationDesks: [],
                      disabledDistanceDesks: [],
                      removedDesks: [],
                      keptCenters: [],
                      disabledCenters: [],
                    },
                    circulation: circ,
                  };
                }
                // if features are not present something has gone wrong, but I
                // added handling regardless
                if (currFloorFeatures) {
                  buildingFeatures[floor] = currFloorFeatures;
                } else {
                  buildingFeatures[floor] = {};
                }
              })
            );
          }
        })
      );
    }
    //set local state variables to track building features and settings
    setBuildingFeaturesState(buildingFeatures);
    setBuildingSettingsState(buildingSettings);
  };

  const calcWalkDistsCall = (building: boolean) => {
    if (building) {
      // if focus on bulding
      // get data from each floor of building
      // set to a buildingSettings and featureSelect(building, walkabilityMetric)
      const allBuildingOutput: {
        seatCoords: Coordinate;
        distance: number;
        feature: {
          distance: number;
          line: string[];
          intersection: Coordinate;
          origin: Coordinate;
        };
        path: Coordinate[];
      }[] = [];
      let activeFloorOutput: {
        seatCoords: Coordinate;
        distance: number;
        feature: {
          distance: number;
          line: string[];
          intersection: Coordinate;
          origin: Coordinate;
        };
        path: Coordinate[];
      }[];
      Object.keys(buildingSettingsState).forEach((floor) => {
        if (walkabilityMetric) {
          const currFloorStuff = calcWalkabilityDists(
            buildingSettingsState[floor],
            featureSelect(walkabilityMetric, buildingFeaturesState[floor]),
            floor
          );
          if (floor === floorID) {
            activeFloorOutput = currFloorStuff;
          }
          allBuildingOutput.push(...currFloorStuff);
          setWalkabilityBuildingOutput(activeFloorOutput);
        }
      });
      // sort allBuildingOutput here to find best/worst desk in building
      const sorted = allBuildingOutput.sort((a: any, b: any) =>
        a.distance > b.distance ? 1 : -1
      );
      setWalkabilityOutput(sorted);
      setMetricOutput(true);
      setDisableButton(true);
      setSelectedWalkability("");
      return;
    }
    setWalkabilityBuildingOutput([]);
    if (walkabilityMetric) {
      const arr = calcWalkabilityDists(
        buildingSettingsState[floorID],
        featureSelect(walkabilityMetric, buildingFeaturesState[floorID]),
        floorID
      );
      setWalkabilityOutput(arr);
      setMetricOutput(true);
    }
    setSelectedWalkability("");
  };

  //The commented out ammenity types are for future expanded functionality, they don't need to be removed
  const walkabilityMetrics: { label: string; value: string }[] = [
    // { label: "Microkitchen", value: "microKitchen" },
    // { label: "Connecting Stair", value: "connectingStair" },
    // { label: "Restroom", value: "restroom" },
    // { label: "Phone Booth", value: "phoneBooth" },
    // { label: "Huddle", value: "huddle" },
    { label: "Small Meeting Room", value: "smallMeetingRoom" },
    { label: "Medium Meeting Room", value: "mediumMeetingRoom" },
    { label: "Large Meeting Room", value: "largeMeetingRoom" },
    { label: "All Amenities", value: "allAmenities" },
  ];

  const featureSelect = (metric: string, inputFeature: any) => {
    let feature;
    switch (metric) {
      case "microKitchen":
        feature = inputFeature?.pointsOfInterest.food.microKitchen;
        break;
      case "connectingStair":
        feature = inputFeature?.pointsOfInterest.verticalPenetration.stairway;
        break;
      case "restroom":
        feature =
          inputFeature?.pointsOfInterest.buildingInfrastructure.restrooms;
        break;
      case "phoneBooth":
        feature = inputFeature?.conferenceRooms.phone;
        break;
      case "huddle":
        feature = inputFeature?.conferenceRooms.huddle;
        break;
      case "smallMeetingRoom":
        feature = inputFeature?.conferenceRooms.small;
        break;
      case "mediumMeetingRoom":
        feature = inputFeature?.conferenceRooms.medium;
        break;
      case "largeMeetingRoom":
        feature = inputFeature?.conferenceRooms.large;
        break;
      case "allAmenities":
        //Once walkability grows to include other features, we'll need to
        // make this capture the other feature types
        feature = {
          ...inputFeature?.conferenceRooms.small,
          ...inputFeature?.conferenceRooms.medium,
          ...inputFeature?.conferenceRooms.large,
        };
        break;
      default:
        console.error("Invalid metric type", metric);
        break;
    }
    return feature;
  };

  const onMetricChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setWalkabilityMetric(event.target.value as string);
    setDisableButton(false);
  };

  const calcWalkabilityDists = (
    // this is a currentSettings object
    settings: RendererSettings,
    features: RoomBounds | undefined,
    floor: string
  ) => {
    // assign empty array of objects to hold results before setting walkabilityOutput
    const tempArr: {
      seatCoords: Coordinate;
      distance: number;
      feature: {
        distance: number;
        line: string[];
        intersection: Coordinate;
        origin: Coordinate;
      };
      path: Coordinate[];
    }[] = [];

    //TODO_Look into this section, not pulling in desks properly
    // break up this ternary into something more logical
    const metricID = encodeMetricId(buildingID, strategyID, floor);
    const metric = project!.metrics[metricID];

    const { keptCenters } = calcDesks(
      metric,
      buildingFeaturesState[floor],
      settings.blocks
    );

    let desks = keptCenters;

    if (settings.blocks) {
      desks = getDesksFromBlocks(settings.blocks).concat(keptCenters);
    }

    const circulation = settings.circulation;

    if (circulation && desks && features) {
      // calculate and store distance of feature center to nearest circulation line
      const featureToCirculationMap: Record<
        string,
        {
          distance: number;
          line: string[];
          intersection: Coordinate;
          origin: Coordinate;
        }
      > = {};

      Object.values(features).forEach((feature) => {
        const centerPoint: Coordinate = findCentroidSimple(feature);
        featureToCirculationMap[stringit(feature)] = findDistanceToCirculation(
          circulation,
          centerPoint
        );
      });

      // calculate and store distance to nearest circulation line from desk
      const deskMap: Record<
        string,
        {
          distance: number;
          line: string[];
          intersection: Coordinate;
          origin: Coordinate;
        }
      > = {};

      desks.forEach((desk) => {
        deskMap[JSON.stringify(desk)] = findDistanceToCirculation(
          circulation,
          desk
        );
      });

      const formattedGraph = buildGraph(
        deskMap,
        circulation,
        featureToCirculationMap
      );

      Object.values(deskMap).forEach((desk) => {
        let minFeatureDistance = Infinity;
        let currentClosestFeature: {
          distance: number;
          line: string[];
          intersection: Coordinate;
          origin: Coordinate;
        } = featureToCirculationMap[0];
        let bestPath: GraphNode[] | undefined = [];
        Object.values(featureToCirculationMap).forEach((feature) => {
          // for each feature, find the total distance (distanceA + circulation length + distanceB). Compare this distance to
          // featureDistance. If this value is less than featureDistance, set
          // feature distance to totalDistance, and note the feature that is
          // closest.
          const {
            runningDist: circulationDistance,
            shortestPath: circulationPath,
          } = calcCirculationPathDistance(formattedGraph, desk, feature);
          const runningFeatureDistance =
            desk.distance + circulationDistance + feature.distance;
          // if your total distance (calculated, related to desk and feature) is
          // less than minFeatureDistance (you've found a closer feature)
          if (runningFeatureDistance < minFeatureDistance) {
            minFeatureDistance = runningFeatureDistance;
            currentClosestFeature = feature;
            bestPath = circulationPath;
          }
        });
        // calculate the full path to render on floorplan
        const pathPoints = [];
        pathPoints.push({ x: desk.origin.x, y: desk.origin.y });
        if (bestPath) {
          bestPath.forEach((node: GraphNode) => {
            pathPoints.push({ x: node.point3?.[0], y: node.point3?.[1] });
          });
        }
        pathPoints.push({
          x: currentClosestFeature!.origin.x,
          y: currentClosestFeature!.origin.y,
        });

        let finalDistance = 0;

        // distance along path
        for (let i = 0; i < pathPoints.length - 2; i++) {
          const xDeltaSquare = Math.pow(
            pathPoints[i + 1].x - pathPoints[i].x,
            2
          );
          const yDeltaSquare = Math.pow(
            pathPoints[i + 1].y - pathPoints[i].y,
            2
          );
          const currDistance = Math.sqrt(xDeltaSquare + yDeltaSquare);
          finalDistance += currDistance;
        }

        tempArr.push({
          seatCoords: desk.origin,
          distance: finalDistance / 12,
          feature: currentClosestFeature,
          path: pathPoints,
        });
      });
    }
    //Sort walkability output for better use in rendering
    //TODO_LM - Potentially unecessary here because also ran elsewhere to
    // handle building level metrics, but currently this is used in floor
    // calculations
    const sorted = tempArr.sort((a: any, b: any) =>
      a.distance > b.distance ? 1 : -1
    );
    return sorted;
  };

  const formatWalkabilityOutput = (formatType: string) => {
    if (walkabilityOutput) {
      const average = walkabilityOutput.reduce((acc: any, obj: any) => {
        return acc + obj.distance;
      }, 0);
      const length = walkabilityOutput.length;
      if (formatType === "avg") {
        return Math.round(average / length / 5) * 5 + " ft";
      } else if (formatType === "max") {
        return (
          Math.round(walkabilityOutput[length - 1].distance / 5) * 5 + " ft"
        );
      } else if (formatType === "min") {
        return Math.round(walkabilityOutput[0].distance / 5) * 5 + " ft";
      } else if (formatType === "score") {
        if (average / length > 250) return 0 + " %";
        else if (average < 25) return 100 + " %";
        else return Math.round(((250 - average / length) / 250) * 100) + " %";
      }
    }
  };

  const onClickFunc = () => {
    setOpenModal(true);
  };

  const BuildingFloorModal = (
    <Modal
      keepMounted={true}
      open={openModal}
      onClose={() => {
        setOpenModal(false);
      }}
      disableBackdropClick={false}
      closeAfterTransition={false}
      BackdropProps={{
        timeout: 500,
        invisible: true,
      }}
    >
      <Box className={classes.menu}>
        <div className={classes.menuHeader}>Feature Metrics</div>
        <Box className={classes.buttonBox}>
          <MaterialButton
            onClick={() => {
              setFocusBuilding(true);
              setDisableButton(false);
            }}
            className={
              focusBuilding === true ? classes.buttonActive : classes.button
            }
            size={"small"}
          >
            <Check /> Building
          </MaterialButton>
          <MaterialButton
            onClick={() => {
              setFocusBuilding(false);
              setDisableButton(false);
            }}
            className={
              focusBuilding !== true ? classes.buttonActive : classes.button
            }
            size={"small"}
          >
            <Check />
            Floor
          </MaterialButton>
        </Box>
      </Box>
    </Modal>
  );

  const itemDisable = (option: string, button: string) => {
    if (!focusBuilding && button === "menu") {
      // if you're only looking at a single floor, use featureSelect to verify
      // that the desired feature is on your current floor
      return Object.keys(featureSelect(option, currentFeatures)!).length === 0;
    } else if (option !== "") {
      // if you're looking at the whole building, use featureSelect to verify
      // that the desired feature is on your each floor, if any floors fail,
      // this option is disabled. Also handles toggle state of the settings
      // icon, if other floors don't have your selected metric, you
      // can't do a building study
      let optionDisable = false;
      if (buildingFeaturesState) {
        Object.keys(buildingFeaturesState).forEach((floor) => {
          if (
            Object.keys(featureSelect(option, buildingFeaturesState[floor])!)
              .length === 0
          ) {
            optionDisable = true;
          }
        });
        return optionDisable;
      }
    }
  };

  const BuildingFloorToggle = (
    <div className={classes.toggle}>
      <div className={classes.toggleText}>
        {focusBuilding === false ? "Floor" : "Building"}
      </div>
      <IconButton
        onClick={onClickFunc}
        disabled={itemDisable((walkabilityMetric as string) || "", "settings")}
      >
        <Settings />
      </IconButton>
      {BuildingFloorModal}
    </div>
  );

  const ReturnToCirc = (
    <BlockButton
      onClick={() => {
        revertCirc();
      }}
      selected={false}
    >
      {svgIcon("#555555")}
    </BlockButton>
  );

  const shouldDisableButton = !walkabilityMetric || disableButton;

  const calculateButton = (
    <div className={classes.calcDiv}>
      <Button.Sm.Sqr.Primary
        variant="contained"
        color="primary"
        onClick={() => {
          setDisableButton(true);
          setTimeout(() => {
            calcWalkDistsCall(focusBuilding);
            if (!calculated) setCalculated(true);
          }, DELAY_BEFORE_SETTLED);
        }}
        style={{ width: "100%", boxShadow: "none" }}
        disabled={shouldDisableButton}
      >
        {calculated ? "Recalculate" : "Calculate"}
      </Button.Sm.Sqr.Primary>
    </div>
  );

  const calculateButtonMaybeWithTooltip = shouldDisableButton ? (
    calculateButton
  ) : (
    <Tooltip
      title="Please note, calculation may take time to process"
      arrow
      placement="left"
    >
      {calculateButton}
    </Tooltip>
  );

  return (
    <div className={classes.walkabilityDiv}>
      <div className={classes.walkabilityDetails}>
        <div className={classes.walkabilityHeader}>
          <HelpguideWrapperDiv>
            <div style={{ display: "flex" }}>
              <Typography className={classes.walkabilityScore} variant="h3">
                Walkability Score
              </Typography>
              <HelpguideHandle isSmall tooltipKey="Walkability" />
            </div>
          </HelpguideWrapperDiv>
          <div className={classes.headerButtons}>
            {BuildingFloorToggle}
            {ReturnToCirc}
          </div>
        </div>
        <div
          style={{
            margin: "8px 0 16px 0",
            minHeight: 24,
            color: "#5F6166",
            minWidth: 34,
            fontWeight: 400,
            fontSize: 16,
            letterSpacing: 0.5,
            fontFamily: "GoogleSansRegular",
            lineHeight: "24px",
            fontStyle: "normal",
          }}
        >
          {walkabilityOutput && walkabilityOutput.length !== 0 ? (
            <text>{formatWalkabilityOutput("score")}</text>
          ) : (
            <text>Please Calculate</text>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>
            <InputLabel
              shrink={true}
              id="program-label"
              style={{ paddingBottom: 4.2 }}
            >
              Walkability By Destination
            </InputLabel>
            <Select
              id="walkSelector"
              label="Metric"
              placeholder="Metric"
              className={classes.selector}
              value={walkabilityMetric}
              onChange={onMetricChange}
              displayEmpty={true}
              defaultValue={"allAmenities"}
            >
              {walkabilityMetrics.map(
                (option: { label: string; value: string }) => {
                  return (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                      disabled={itemDisable(option.value, "menu")}
                    >
                      {option.label}
                    </MenuItem>
                  );
                }
              )}
            </Select>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              position: "relative",
              bottom: -8,
            }}
          >
            <Button.Sm.Sqr.Outlined
              variant={"contained"}
              onClick={() => setIsHC(false)}
              style={{
                height: 32.28,
                marginRight: 4,
                minWidth: 29,
                maxWidth: 29,
                color: isHC ? "#5F6166" : "#2F73FF",
                backgroundColor: isHC ? "#F4F4F6" : "#E5EFFF",
                border: isHC ? "#F4F4F6" : "#E5EFFF",
                boxShadow: "none",
              }}
              fullWidth={false}
            >
              %
            </Button.Sm.Sqr.Outlined>
            <Button.Sm.Sqr.Outlined
              variant={"contained"}
              onClick={() => setIsHC(true)}
              style={{
                height: 32.28,
                marginLeft: 4,
                width: 62,
                color: isHC ? "#2F73FF" : "#5F6166",
                backgroundColor: isHC ? "#E5EFFF" : "#F4F4F6",
                border: isHC ? "#E5EFFF" : "#F4F4F6",
                boxShadow: "none",
              }}
              fullWidth={false}
            >
              <div style={{ display: "flex" }}>
                <Person /> <span>HC</span>
              </div>
            </Button.Sm.Sqr.Outlined>
          </div>
        </div>
      </div>
      <div className={classes.metricContainer}>
        <div style={{ paddingTop: 32 }}>
          {walkabilityOutput && walkabilityOutput.length !== 0 ? (
            <WalkabilityBarChart dataArr={walkabilityOutput} isHC={isHC} />
          ) : null}
        </div>
        {walkabilityOutput && walkabilityOutput.length !== 0 && metricOutput ? (
          <>
            <div style={{ margin: "12px 0 12px 0" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: 4,
                }}
              >
                <p className={classes.outputHeader}>Destination Distances</p>
                <p className={classes.outputHeader}>Distance</p>
              </div>
              <div className={classes.metricOutput}>
                <div>Shortest</div>
                <div>
                  {walkabilityOutput ? formatWalkabilityOutput("min") : "error"}
                </div>
              </div>
              <div className={classes.metricOutput}>
                <div>Average</div>
                <div>
                  {walkabilityOutput ? formatWalkabilityOutput("avg") : "error"}
                </div>
              </div>
              <div className={classes.metricOutput}>
                <div>Longest</div>
                <div>
                  {walkabilityOutput ? formatWalkabilityOutput("max") : "error"}
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
      <div>{calculateButtonMaybeWithTooltip}</div>
    </div>
  );
};

export default WalkabilityData;
