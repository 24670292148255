import React, { useEffect, useState } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import IconClose from "@material-ui/icons/Close";
import { Button } from "@material-ui/core";
import config from "config";

const slides: SlideProps[] = [
  {
    title: "Welcome to Portfolio!",
    subtitle:
      "Portfolio is an application within the Clay ecosystem that allows you to evaluate and visualize planning changes in real time. With this tool you will be able to try out different operational and design strategies. Here is a quick walkthrough.",
    images: ["/welcome/welcome.png"],
    labels: [" "],
  },
  {
    title: "Replan Team Space",
    subtitle:
      "By selecting the relevant library of elements, you are able to replan full areas of your floor plan using for example the Future Workplace space concepts following the latest guidance.",
    images: ["/welcome/replan-2.jpg", "/welcome/replan-1.jpg"],
    labels: ["No strategy applied", "Replanned with blocks"],
  },
  {
    title: "Convert Program",
    subtitle:
      "You are able to convert meeting room or amenity program areas into other program types such as workstations or collaboration space and see the impact on your metrics.",
    images: ["/welcome/convert-1.jpg", "/welcome/convert-2.jpg"],
    labels: ["No strategy applied", "Converted to workstations"],
  },
  {
    title: "Disable Program",
    subtitle:
      "You are able to disable meeting room or amenity program areas and see the impact on your metrics.",
    images: [
      "/welcome/disable-program-1.jpg",
      "/welcome/disable-program-2.jpg",
    ],
    labels: ["No strategy applied", "Disabled meeting rooms"],
  },
  {
    title: "Analyze Layouts",
    subtitle:
      "For any new layout you are able to evaluate quantitative metrics such as density, headcount and meeting rooms counts and qualitative metrics such as buzz to predict and visualize the most heavily trafficked routes.",
    images: ["/welcome/analyze-layout.jpg"],
    labels: [],
  },
  {
    title: "Workstation Spacing",
    subtitle:
      "For the purpose of studying de-densifying without any space layout changes you can test disabling of workstations until the spacing between all seats is greater than a specified value.",
    images: ["/welcome/seat-spacing-1.jpg", "/welcome/seat-spacing-2.jpg"],
    labels: ["No strategy applied", "Min. seat spacing > X'"],
  },
  {
    title: "Circulation Buffer",
    subtitle:
      "For the purpose of studying de-densifying without any space layout changes you can test disabling of workstations within a specified distance from a circulation path.",
    images: ["/welcome/buffer-1.jpg", "/welcome/buffer-2.jpg"],
    labels: ["No buffer", "With buffer"],
  },
];

interface SlideProps {
  title: string;
  subtitle: string;
  images: string[];
  labels: string[];
}

const Slide: React.FC<SlideProps> = ({ title, subtitle, images, labels }) => {
  const [image, setImage] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setImage((i) => {
        return (i + 1) % images.length;
      });
    }, 1500);
    return () => clearInterval(interval);
  }, [images.length]);
  return (
    <div
      style={{
        padding: 20,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        height: 600,
      }}
    >
      <Typography
        variant="h4"
        gutterBottom={true}
        style={{ textAlign: "center", fontSize: "2.2rem", marginBottom: 20 }}
      >
        {title}
      </Typography>
      <Typography
        variant="subtitle1"
        gutterBottom={true}
        style={{ textAlign: "center", height: 200, fontSize: "1rem" }}
      >
        {subtitle}
      </Typography>
      <TransitionGroup>
        <CSSTransition
          key={image}
          timeout={0}
          addEndListener={(node, done) =>
            node.addEventListener("transitionend", done, false)
          }
          classNames="fade"
        >
          <div
            style={{
              position: "absolute",
              top: 300,
              left: 0,
              width: "100%",
              height: "320px",
              textAlign: "center",
            }}
          >
            <img
              alt=""
              key={image}
              src={`${config.storageUrl}${images[image]}`}
              style={{
                maxWidth: "400px",
                height: "290px",
              }}
            />
            <Typography variant="subtitle1">{labels[image]}</Typography>
          </div>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

const Welcome: React.FC<{
  page: number;
  open: boolean;
  onClose: () => void;
}> = ({ page = 0, open = false, onClose }) => {
  const [activePage, setActivePage] = useState(page);
  const totalPages = 7;
  const slide = slides[activePage];
  const close = () => {
    setTimeout(() => setActivePage(0), 200);
    onClose();
  };
  return (
    <Dialog
      open={open}
      PaperProps={{ style: { padding: "60px 20px 20px", maxWidth: 480 } }}
      onClose={close}
    >
      <IconClose
        onClick={close}
        style={{ position: "absolute", top: 20, right: 20, cursor: "pointer" }}
        data-testid="close-welcome-modal-button"
      />
      <Slide key={activePage} {...slide} />
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ width: 100 }}>
          {activePage > 0 && (
            <Button
              size="large"
              onClick={() => setActivePage(Math.max(activePage - 1, 0))}
            >
              {activePage > 0 ? "Previous" : " "}
            </Button>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexGrow: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ display: "flex" }}>
            {new Array(totalPages).fill(0).map((_, i) => (
              <div
                key={i}
                style={{
                  margin: "0 4px",
                  width: 10,
                  height: 10,
                  borderRadius: 10,
                  background: i === activePage ? "#4384f7" : "#e8e8e8",
                  cursor: "pointer",
                }}
                onClick={() => setActivePage(i)}
              />
            ))}
          </div>
        </div>
        <div style={{ width: 100 }}>
          <Button
            color="primary"
            variant="contained"
            size="large"
            style={{ float: "right" }}
            onClick={() => {
              if (activePage === totalPages - 1) onClose();
              setActivePage(Math.min(activePage + 1, totalPages - 1));
            }}
          >
            {activePage === totalPages - 1 ? "Done" : "Next"}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default Welcome;
