import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";

export const legendColorCode: Record<string, string> = {
  Focus: "#2F73FF",
  Collaboration: "#FF9700",
  "Team Space": "#CDE8F6",
  Meeting: "#7087BB",
  "Shared Focus": "#CFA7F4",
  Disabled: "#CCCCCC",
  disable: "#CCCCCC",
  convertToCollaboration: "#FF9700",
  convertToWorkstations: "#2F73FF",
  noChange: "#7087BB",
  Amenity: "#FEBE10",
  BusinessSupport: "#E76873",
  Events: "#41C08B",
  Circulation: "#DCDCDC",
  Core: "#808080",
};

const useStyles = makeStyles(() => ({
  fixBottom: {
    top: "auto",
    bottom: 0,
    boxShadow: "none",
  },
  legendContainer: {
    height: "44px",
    borderTop: "0.85px solid #D5D8DC",
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    justify: "center",
    padding: "16px",
    paddingBottom: "11px",
    paddingTop: "12px",
  },
  legendItem: {
    color: "#5F6166",
    fontSize: 10,
    display: "block",
  },
  legendBar: {
    height: 3,
  },
}));

const LegendItem = ({ color, label }: { color: string; label: string }) => {
  const classes = useStyles();
  return (
    <Grid container item className={classes.legendItem}>
      <Grid item xs={12}>
        <div className={classes.legendBar} style={{ background: color }} />
      </Grid>
      <Grid item xs={12}>
        {label}
      </Grid>
    </Grid>
  );
};

export const ProgramLegend = () => {
  return (
    <LegendWrapper>
      <Grid container spacing={1} wrap="nowrap">
        <LegendItem color={legendColorCode["Team Space"]} label="Team" />
        <LegendItem color={legendColorCode.Meeting} label="Meeting" />
        <LegendItem color={legendColorCode.Amenity} label="Amenity" />
        <LegendItem color={legendColorCode.BusinessSupport} label="CBS" />
        <LegendItem color={legendColorCode.Events} label="Events" />
        <LegendItem color={legendColorCode.Core} label="Core" />
        <LegendItem color={legendColorCode.Circulation} label="Circulation" />
      </Grid>
    </LegendWrapper>
  );
};

export const ActivitiesLegend = () => {
  return (
    <LegendWrapper>
      <Grid container spacing={1} wrap="nowrap">
        <LegendItem color="#2F73FF" label="Individual" />
        <LegendItem color="#FFAF38" label="Communal" />
        <LegendItem color="#CDE8F6" label="Desks" />
      </Grid>
    </LegendWrapper>
  );
};

export const LegendWrapper: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <AppBar position="sticky" color="transparent" className={classes.fixBottom}>
      <div className={classes.legendContainer}>{children}</div>
    </AppBar>
  );
};

const useBuzzStyles = makeStyles({
  actions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "12px 0",
  },
  traffic: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: 5,
  },
  gradient: {
    width: "100%",
    height: "6px",
    background:
      "linear-gradient(270deg, #F3F058 0%, #D67262 49.21%, #402598 100%)",
    borderRadius: "81px",
  },
  meetGradient: {
    width: "100%",
    height: "6px",
    background:
      "linear-gradient(90deg, #F4998E 0%, #F5DE98 49.21%, #90CB86 100%)",
    borderRadius: "81px",
  },
  labels: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.25px",
    color: "#ADADAD",
  },
  fixer: {
    width: "100%",
    padding: "8px 16px",
  },
});

export const MeetingRoomGradient = () => {
  const classes = useBuzzStyles();

  return (
    <div className={classes.fixer}>
      <div className={classes.traffic}>
        <div className={classes.labels}>Low</div>
        <div className={classes.labels}>High</div>
      </div>
      <div className={classes.traffic}>
        <div className={classes.meetGradient} />
      </div>
    </div>
  );
};

export const BuzzGradient = () => {
  const classes = useBuzzStyles();

  return (
    <div className={classes.fixer}>
      <div className={classes.traffic}>
        <div className={classes.labels}>Light Traffic</div>
        <div className={classes.labels}>High Traffic</div>
      </div>
      <div className={classes.traffic}>
        <div className={classes.gradient} />
      </div>
    </div>
  );
};
