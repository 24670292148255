import React, { useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import { DisplayTableRowMetric } from "lib/types";
import Header from "./head";
import RowSet from "./rows";

import Loading from "components/loading";
import { useProjectCtrl } from "lib/containers";
import { reduceTableMetrics } from "lib/metrics/table-metrics";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  const i = a[orderBy];
  const j = b[orderBy];

  // if comparison strings, disregard case
  if (typeof i === "string" && typeof j === "string") {
    if (j.toLowerCase() < i.toLowerCase()) {
      return -1;
    } else if (j.toLowerCase() > i.toLowerCase()) {
      return 1;
    }
    return 0;
  }

  // otherwise, compare normally
  if (j < i) {
    return -1;
  } else if (j > i) {
    return 1;
  }
  return 0;
}

export type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  // eslint-disable-next-line no-unused-vars
  a: { [key in Key]: number | string },
  // eslint-disable-next-line no-unused-vars
  b: { [key in Key]: number | string }
) => number {
  if (orderBy === "buildingID") {
    orderBy = "displayName" as Key;
  }
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(
  array: DisplayTableRowMetric[][],
  comparator: (a: any, b: any) => number,
  pinnedStrategies: { [buildingID: string]: string }
) {
  // Make a copy of the array (sort() sorts in place! :) )
  const sortedBuildings = array.map((el) => el);

  // search for which strategies are pinned by comparing against
  // each building's pinned strategy
  sortedBuildings.sort((a, b) => {
    // make sure there is a pinned strategy i guess
    let primaryA = -1;
    let primaryB = -1;

    const thePinnedStrategyIdA = pinnedStrategies[a[0].buildingID];
    for (let i = 0; i < a.length; i++) {
      if (a[i].strategyID === thePinnedStrategyIdA) {
        // this is the pinned strategy!
        primaryA = i;
      }
    }

    const thePinnedStrategyIdB = pinnedStrategies[b[0].buildingID];
    for (let i = 0; i < b.length; i++) {
      if (b[i].strategyID === thePinnedStrategyIdB) {
        primaryB = i;
      }
    }

    // there should always be a pinned strategy, so these *should* never be -1
    if (primaryA !== -1 && primaryB !== -1) {
      return comparator(a[primaryA], b[primaryB]);
    }

    return 0;
  });

  // Also sort the strategies of each building with the same comparator
  sortedBuildings.forEach((strategies) => {
    strategies.sort((a, b) => comparator(a, b));
  });

  return sortedBuildings;
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      height: "100%",
      width: "100%",
      marginBottom: 100,
    },
    body: {
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",
      maxHeight: 800,
    },
    scrolling: {
      overflow: "auto",
    },
  })
);

export default function PortfolioTable() {
  const classes = useStyles();
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] =
    useState<keyof DisplayTableRowMetric>("buildingID");
  const { project } = useProjectCtrl();
  const [updateComponent, setUpdateComponent] = useState<number>(0);

  const handleUpdate = () => {
    setUpdateComponent(updateComponent + 1);
  };

  if (!project) return <Loading />;
  const primaryStrategies = project?.primaryStrategies;
  const rows = reduceTableMetrics(project);
  const ordered = stableSort(
    rows,
    getComparator(order, orderBy),
    primaryStrategies
  );

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof DisplayTableRowMetric
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <div className={classes.container}>
      <div className={classes.scrolling}>
        <Header
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          handleUpdate={handleUpdate}
        />
        {ordered.map((o) => (
          <RowSet
            key={`${o[0].buildingID}-a`}
            rows={o}
            updateComponent={updateComponent}
          />
        ))}
      </div>
    </div>
  );
}
