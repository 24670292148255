import { Block, BlockMetrics } from "blocks/lib/types";
import { Graph } from "./metrics/buzz";

export interface RendererSettings {
  blocks: Block[][];
  blockChanges?: {
    // legacy support, do not remove
    /** @deprecated Do not use */
    put?: string[];
    delete?: string[];
  };
  desks?: {
    disabledCirculationDesks: Coordinate[];
    disabledDistanceDesks: Coordinate[];
    removedDesks: Coordinate[];
    keptCenters: Coordinate[];
    disabledCenters: Coordinate[];
  };
  circulation?: Graph | null;
}

export type PortfolioBlockInstance = Block;

/**
 * There are two ways to plan the Program: Custom and Whole Building.
 *
 * Whole Building makes the program the same for each level. It also disables
 * a bunch of functionality.
 *
 * If you switch which strategy mode you are in, you will lose basically all
 * of your edits so far.
 */
export enum StrategyMode {
  Building,
  Zone,
}
export interface XForm {
  width: number;
  height: number;
  scale: number;
  globalScale: number;
  position: Coordinate;
}

export interface Option {
  name: string;
  value: string;
}

export interface Building {
  AddressLine: string;
  BuildingId: string;
  Campus: string;
  City: string;
  CitySite: string;
  Country: string;
  Geo: string;
  Orientation: number;
  PostalCode: string;
  Region: string;
  State: string;
  District: string;
  CanSpacePlan?: boolean;
  HasData?: boolean;
  AvailableFloors: string[];
  FloorHeight?: BuildingFloorHeight;
  FirstFloorHeight?: number;
  TypicalFloorHeight?: number;
  Coordinates: { Lat: number; Lng: number };
}

export interface BuildingFloorHeight {
  Ft: number;
  In: number;
}

export interface Buildings {
  [key: string]: Building;
}

// Metrics key buildingID:strategyID:floorID
// i.e. US-MTV-LAND:d2e63d66-4f6c-43b9-a5d1-3c6dc3b5c392:5

export interface Project {
  metrics: Metrics;
  buildings: Buildings;
  buildingIds: string[];
  primaryStrategies: { [buildingID: string]: string };
  name?: string;
  dateLastOpened: Date;
  id: string;
  deleted?: boolean;
  users: string[];
}

export interface Summary {
  totalFloorArea: number;
  density: number;
  baselineDensity: number;
  deskToDoor: number;
  baselineDeskToDoor: number;
  seatCount: number;
  baselineSeatCount: number;
  efficiencyRatio: number;
  assignableSeats: number;
}
export interface Metrics {
  [key: string]: Metric;
}

export interface SelectedMeetingRoom {
  conferenceRoomId: string;
  conferenceRoomType: string;
}

export interface Metric {
  buildingID: string;
  strategyID: string;
  displayName: string;
  totalFloorArea: number;
  efficiencyRatio: number;
  baselineHeadCount: number;
  baselineSeatCount: number;
  seatCount: number;
  potentialSeats: number;
  convertedSeats: number;
  convertedMeetingRooms: number;
  floor: string;
  baselineConferenceRoomsCount: number;
  baselineConferenceRoomsCapacity: number;
  baselineConferenceRoomsSeatsPerHC: number;
  baselinePhoneCount: number;
  conferenceRoomSeats: number;
  conferenceRoomSeatsPerHC: number;
  extraLarge: ConferenceRoom;
  large: ConferenceRoom;
  medium: ConferenceRoom;
  small: ConferenceRoom;
  huddle: ConferenceRoom;
  phone: ConferenceRoom;
  collaborationZones: number;
  collaborationZonesArea: number;
  collaborationSeats: number;
  dedicatedSeats: number;
  flexSeats: number;
  mode: StrategyMode;
  deskSpacing: number;
  desksOff: boolean;
  corridorWidth: number;
  userConvertedMeetingRooms?: UserConvertedMeetingRooms;
  /** @deprecated Do not use */
  phoneBooths?: number;
  /** @deprecated Do not use */
  regions?: number[][];
  /** @deprecated Do not use */
  blockSettings?: any;
  workpoints: number;
  totalCost: number;
  costUnit: number;
  costGSF: number;
  costHC: number;
  nooksCount: number;
  nooksAssignable: number;
  deskCount: number;
  phonesCount: number;
  assignableSeats: number;
  floorscopeDesks: number;
  doors: number;
  assetHC: number;
}

// TODO this probably should just be Partial<Metric>... its only function
// is to update metric, or at least, thats how it seems...

export interface FloorMetricUpdate {
  seatCount: number;
  conferenceRoomSeats: number;
  conferenceRoomSeatsPerHC: number;
  potentialSeats: number;
  baselineSeatCount: number;
  convertedSeats: number;
  convertedMeetingRooms: number;
  collaborationSeats: number;
  collaborationZones: number;
  collaborationZonesArea: number;
  dedicatedSeats: number;
  workpoints: number;
  flexSeats: number;
  totalCost: number;
  costUnit: number;
  costGSF: number;
  costHC: number;
  nooksCount: number;
  nooksAssignable: number;
  deskCount: number;
  floorscopeDesks: number;
  assignableSeats: number;
  phonesCount: number;
  doors: number;
  assetHC: number;
}

export interface DisplayTableRowMetric extends TableRowMetric {
  displayFloorArea: string;
  displayERatio: string;
  displayUsableFloorArea: string;
  displayBaselineDensity: number;
  displayDensity: number;
  displayBaselineUseableDensity: number;
  displayUseableDensity: number;
  displayConferenceSeats: number;
  displayBaselineConferenceCapacity: number;
  displayConferenceSeatsPerHC: number;
  displayBaselineConferenceSeatsPerHC: number;
  displayDeskToDoor: number | string;
  displayDeskToDoorWithNooks: number | string;
  displayBaselineDeskToDoor: number | string;
  displayTotalCost: string;
  displayCostHC: string;
  displayCostUnit: string;
  displayCostGSF: string;
}

export interface TableRowMetric {
  projectID: string;
  buildingID: string;
  displayName: string;
  strategyID: string;
  totalFloorArea: number;
  usableFloorArea: number;
  workpoints: number;
  efficiencyRatio: number;
  baselineHeadCount: number;
  baselineSeatCount: number;
  seatCount: number;
  potentialSeats: number;
  convertedSeats: number;
  floorCount: number;
  baselineConferenceRoomsCount: number;
  baselineConferenceRoomsCapacity: number;
  baselinePhoneCount: number;
  conferenceRoomSeats: number;
  convertedMeetingRooms: number;
  baselineDensity: number;
  density: number;
  baselineUseableDensity: number;
  useableDensity: number;
  baselineConferenceRoomsSeatsPerHC: number;
  conferenceRoomSeatsPerHC: number;
  deskToDoor: number;
  deskToDoorWithNooks: number;
  baselineDeskToDoor: number;
  totalCost: number;
  costUnit: number;
  costGSF: number;
  costHC: number;
  assignableSeats: number;
  doors: number;
  nooksCount: number;
  phones: number;
  phonesCount: number;
  assetHC: number;
}

export interface DisplayGroupMetric extends BlockMetrics {
  focusAreaSF: string;
  collabAreaSF: string;
  gsfPerTotalFocus: string;
  gsfPerTotalCollab: string;
  totalSeats: number;
  totalArea: string;
}

export interface DisplayRendererMetric extends RendererMetric {
  avgCollabArea: string;
  collabTotal: string;
  density: number;
  deskToDoor: number | string;
  conferenceRoomsCount: number;
}
export interface RendererMetric {
  seatCount: number;
  workpoints: number;
  convertedSeats: number;
  collaborationZonesArea: number;
  collaborationZones: number;
  totalFloorArea: number;
  convertedMeetingRooms: number;
  collaborationSeats: number;
  collaborationActivities: [];
  focusActivities: [];
  baselineConferenceRoomsCount: number;
  baselineSeatCount: number;
  baselineHeadCount: number;
  totalCost: number;
  costUnit: number;
  costGSF: number;
  costHC: number;
  assignableSeats: number;
  efficiencyRatio: number;
  nooksCount: number;
  nooksAssignable: number;
  // phonesCount is all phonebooths from within blocks
  phonesCount: number;
  phone: number;
  doors: number;
  assetHC: number;
}

export interface MeetingRoomMetric {
  building: string;
  floor: number;
  resource: string;
  id: string;
  shortId: string;
  capacity: number;
  hoursBooked: number;
  bookingRate: number;
  occupancyRate: number;
  distributionBucket: string;
}

export interface ConferenceRoom {
  count: number;
  capacity: number;
  area: number;
}

export type Point = number[];

export interface UserConvertedMeetingRooms {
  convertToCollaboration: SelectedMeetingRoom[];
  convertToWorkstations: SelectedMeetingRoom[];
  disable: SelectedMeetingRoom[];
  [key: string]: SelectedMeetingRoom[];
}

export type SelectedRooms = SelectedMeetingRoom[];

// lowest type of geometry. in the case of instances it may have an angle, arcs have angle + radius...
export interface Coordinate {
  x: number;
  y: number;
  z?: number;
  angle?: number;
  endAngle?: number;
  radius?: number;
  zExt?: number;
  flags?: number;
  bulge?: number;
  // TODO(JV): refactor Portfolio geometry with 'shapes' library
  // for ellipse
  radiusX?: number;
  radiusY?: number;
  rotation?: number;
  scaleX?: number;
  scaleY?: number;
}

export interface Insert {
  coords?: Coordinate[];
}

export type Data = { [k: string]: any };

export type ConferenceRooms = {
  extraLarge: RoomBounds;
  huddle: RoomBounds;
  interview: RoomBounds;
  large: RoomBounds;
  medium: RoomBounds;
  phone: RoomBounds;
  small: RoomBounds;
  [key: string]: RoomBounds;
};

export type RoomBounds = {
  [name: string]: Coordinate[];
};

export type Features = {
  columns?: Coordinate[];
  circulation: Coordinate[][];
  conferenceRooms: ConferenceRooms;
  desks: Coordinate[][];
  walls: Coordinate[][];
  wallsExterior: Coordinate[][];
  wallsPartial: Coordinate[][];
  skylights: Coordinate[][];
  glazing: Coordinate[][];
  glazingExterior: Coordinate[][];
  primaryCirculation: number[][][];
  uniqueDesks: Coordinate[];
  floorplate: Coordinate[][];
  pointsOfInterest: PointsOfInterest;
};

export interface PointsOfInterest {
  amenities: Amenities;
  buildingInfrastructure: BuildingInfrastructure;
  businessSupport: BusinessSupport;
  events: Events;
  food: Food;
  nonMeasuredSpace: NonMeasuredSpace;
  verticalPenetration: VerticalPenetration;
  workspace: Workspace;
}

export interface Amenities {
  bikeParkingRack: RoomBounds;
  bikeParkingRoom: RoomBounds;
  bikeRepair: RoomBounds;
  creativeRoom: RoomBounds;
  fitnessGym: RoomBounds;
  fitnessOther: RoomBounds;
  fitnessStudio: RoomBounds;
  gameRoom: RoomBounds;
  healthExamRoom: RoomBounds;
  healthLab: RoomBounds;
  healthRehab: RoomBounds;
  laundryRoom: RoomBounds;
  makerspace: RoomBounds;
  massageRoom: RoomBounds;
  meditationRoom: RoomBounds;
  musicRoom: RoomBounds;
  napSpace: RoomBounds;
  salonAesthetic: RoomBounds;
}

export interface BuildingInfrastructure {
  restrooms: RoomBounds;
}

export interface BusinessSupport {
  collaboration: RoomBounds;
  copyAndPrint: RoomBounds;
  focusRoom: RoomBounds;
  lobby: RoomBounds;
  lockers: RoomBounds;
  mailArea: RoomBounds;
  monitorRoom: RoomBounds;
  mothersRoom: RoomBounds;
  packageRoom: RoomBounds;
  parentsRoom: RoomBounds;
  reception: RoomBounds;
  securityAndBadging: RoomBounds;
  shippingAndReceiving: RoomBounds;
  storage: RoomBounds;
  techstop: RoomBounds;
}

export interface Events {
  audioVideoEquipmentLarge: RoomBounds;
  audioAndVideoEquipment: RoomBounds;
  auditorium: RoomBounds;
  coatCheck: RoomBounds;
  controlRoom: RoomBounds;
  editingSuite: RoomBounds;
  eventProductionSpace: RoomBounds;
  eventStudio: RoomBounds;
  greenRoom: RoomBounds;
  preFunction: RoomBounds;
  techTalk: RoomBounds;
  trainingRoom: RoomBounds;
}

export interface Food {
  breakSpace: RoomBounds;
  hub: RoomBounds;
  hydrationStation: RoomBounds;
  microKitchen: RoomBounds;
  seating: RoomBounds;
}

export interface NonMeasuredSpace {
  nonEnclosedRoof: RoomBounds;
  outdoorSpace: RoomBounds;
}

export interface VerticalPenetration {
  atrium: RoomBounds;
  elevator: RoomBounds;
  stairway: RoomBounds;
}

export interface Workspace {
  equipmentStation: RoomBounds;
  officeHuddleCombo: RoomBounds;
  officeLarge: RoomBounds;
  officeMedium: RoomBounds;
  officeSmall: RoomBounds;
}

export interface Layer {
  name: string;
  style: LayerStyle;
  data: Data;
}

export interface LayerStyle {
  color: string;
  fill?: boolean;
  weight?: number;
  offset?: Point;
}

export type Mutation = Partial<Metric> & Partial<TableRowMetric>;

export interface Background {
  elements: Drawable[];
  inserts: { [key: string]: Drawable[] };
  bounds: Bounds;
}

export interface Drawable {
  type: ElementType;
  insertName: string;
  coordinates: Coordinate[];
}

export interface Bounds {
  topLeft: Coordinate;
  bottomRight: Coordinate;
  centroid: Coordinate;
}

export enum ElementType {
  Line = "LINE",
  Insert = "INSERT",
  LWPolyline = "LWPOLYLINE",
  Arc = "ARC",
  Circle = "CIRCLE",
  Ellipse = "ELLIPSE",
  Spline = "SPLINE",
  MTExt = "MTEXT",
  Point = "POINT",
}

export enum NavTabs {
  Zone = "Zone",
  Performance = "Performance",
  Strategy = "Strategy",
  Home = "Home",
}
