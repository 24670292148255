import { SvgIcon, Tooltip } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import Flip from "@material-ui/icons/Flip";
import {
  getBoundingBox,
  getRegionLengths,
  rotateCenter,
  toRegion,
} from "lib/isp-canvas/utils";
import { PortfolioBlockInstance } from "lib/types";
import React, { useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  useBlockInstanceCtrl,
  useISPTransformCtrl,
  useRendererCtrl,
  useSettingsCtrl,
} from "../lib/containers";
import { mat4, quat, vec3 } from "gl-matrix";

const CopyIcon = () => {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 0 24 24"
        width="24"
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
      </svg>
    </SvgIcon>
  );
};

const MenuButton: React.FC<{ title: string; onClick: () => void }> = ({
  title,
  children,
  onClick,
}) => {
  const classes = useStyles();
  return (
    <Tooltip title={title} placement={"right"}>
      <IconButton className={classes.button} onClick={onClick}>
        {children}
      </IconButton>
    </Tooltip>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    position: "absolute",
    zIndex: 1000,
    alignItems: "start",
  },
  background: {
    background: "white",
    borderRadius: "4px",
    boxShadow: "0 0 8px 0 rgba(0,0,0,0.5)",
    display: "flex",
    flexDirection: "column",
  },
  button: {
    borderRadius: 8,
  },
  options: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    minHeight: "360px",
    maxHeight: "400px",
    overflowY: "auto",
    padding: 8,
  },
  optionGroup: {
    display: "flex",
    flexWrap: "wrap",
  },
  option: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
    margin: "4px",
    width: 120,
    borderRadius: 6,
    "&:hover": {
      boxShadow: "0 0 0 1px rgba(0,0,0,0.3)",
    },
  },
  optionSelected: {
    boxShadow: "0 0 0 1px rgba(0,0,0,0.5)",
  },
  image: {
    height: 80,
    width: 80,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: 8,
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },
  },
  optionTitle: {
    width: 100,
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "center",
    fontSize: "10pt",
  },
}));

interface Props {
  instances: PortfolioBlockInstance[][];
  onDelete: (r: number[]) => Promise<void>;
}

const Menu: React.FC<Props> = ({ instances, onDelete }) => {
  const classes = useStyles();
  const { xform, selectedRegion, selectRegion } = useRendererCtrl();
  const { transformBlockInstance, setTransformBlockInstance } =
    useISPTransformCtrl();
  const { buildingID, strategyID, floorID } = useParams<{
    [key: string]: string;
  }>();
  const { duplicateInstance, updateInstance } = useBlockInstanceCtrl();
  const { saveFloor } = useSettingsCtrl();

  const handleDeleteRegion = useCallback(async () => {
    if (selectedRegion !== undefined) {
      onDelete(selectedRegion);
    }
  }, [onDelete, selectedRegion]);

  if (!xform || selectedRegion === undefined || instances.length === 0)
    return null;
  const instance: PortfolioBlockInstance[] = instances[selectedRegion[0]];
  if (!instance) return null;

  const displayMode = "flex";
  const boundingBox = getBoundingBox(
    transformBlockInstance ? transformBlockInstance.map((i) => i[0]) : instance
  );
  const topRight = [
    (boundingBox[3][0] / xform.globalScale) * -xform.scale +
      xform.position.x / xform.globalScale,
    (boundingBox[3][1] / xform.globalScale) * xform.scale +
      xform.position.y / xform.globalScale,
  ];
  const onDuplicate = async () => {
    if (selectedRegion !== undefined) {
      const selected = selectedRegion.map((idx) => instances[idx]);
      const ni = await duplicateInstance({
        buildingID,
        floorID,
        strategyID,
        instances: selected,
      });
      if (ni) {
        const selectedIdx: number[] = [];
        for (let i = 0; i < ni.length; i++) {
          selectedIdx.push(instances.length + i);
        }
        selectRegion(selectedIdx);
        setTransformBlockInstance(ni);
      }
    }
  };

  const onFlip = (horizontal: boolean) => async () => {
    const i = instance[0];
    const [width, height] = getRegionLengths(toRegion(i));
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const q = mat4.getRotation(quat.create(), i.matrix!);
    let angle =
      quat.getAxisAngle(vec3.fromValues(0, 0, 1), q) * (180 / Math.PI);
    if (q[2] < 0) angle = -angle;
    const newAngle = horizontal
      ? (360 - angle) % 360
      : (450 - (angle - 90)) % 360;
    const ni = {
      ...i,
      matrix: rotateCenter(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        i.matrix!,
        width,
        height,
        (newAngle - angle) * (Math.PI / 180)
      ),
      props: {
        ...i.props,
        layout: {
          ...i.props.layout,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          mirrorX: !i.props.layout!.mirrorX,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          rotation: i.props.layout!.rotation! + 90,
        },
      },
    } as PortfolioBlockInstance;
    const settings = await updateInstance({
      buildingID,
      strategyID,
      floorID,
      regionIdx: selectedRegion[0],
      instance: [ni],
      resize: false,
    });
    if (settings) setTransformBlockInstance([[ni]]);
    await saveFloor({ buildingID, strategyID, floorID, settings });
  };

  return (
    <div
      className={classes.container}
      style={{
        top: topRight[1] + "px",
        left: topRight[0] + 30 + "px",
        display: displayMode,
      }}
    >
      <div className={classes.background}>
        {selectedRegion && selectedRegion.length === 1 && (
          <MenuButton title={"Flip Horizontal"} onClick={onFlip(true)}>
            <Flip />
          </MenuButton>
        )}
        {selectedRegion && selectedRegion.length === 1 && (
          <MenuButton title={"Flip Vertical"} onClick={onFlip(false)}>
            <span style={{ transform: "rotate(90deg) translateY(2px)" }}>
              <Flip />
            </span>
          </MenuButton>
        )}
        <MenuButton title={"Duplicate"} onClick={onDuplicate}>
          <CopyIcon />
        </MenuButton>
        <MenuButton title={"Delete"} onClick={handleDeleteRegion}>
          <DeleteOutlinedIcon />
        </MenuButton>
      </div>
    </div>
  );
};

export default Menu;
