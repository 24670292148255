import React, { ComponentType, FC } from "react";
import { BlocksProvider } from "../containers";

function withBlocks<Props>(Component: ComponentType<Props>): FC<Props> {
  return (props) => (
    <BlocksProvider>
      <Component {...props} />
    </BlocksProvider>
  );
}

export default withBlocks;
