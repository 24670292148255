import React from "react";
import { Canvas } from "@outerlabs/canvas-reconciler";
import { renderBuzz } from "lib/isp-canvas";
import { RendererSettings } from "lib/types";
import { EdgeValue, Graph } from "lib/metrics/buzz";

interface Props {
  settings?: RendererSettings;
  defaultCirculation?: Graph;
  calculatedBuzz: EdgeValue[];
}

export const Buzz: React.FC<Props> = ({
  settings,
  defaultCirculation,
  calculatedBuzz,
}) => {
  const canvas = (
    <Canvas
      render={(ctx: CanvasRenderingContext2D) => {
        if (settings && settings.circulation) {
          renderBuzz(ctx, settings.circulation, calculatedBuzz);
        } else if (defaultCirculation) {
          renderBuzz(ctx, defaultCirculation, calculatedBuzz);
        }
      }}
    />
  );

  return <>{canvas}</>;
};

export default React.memo(Buzz);
