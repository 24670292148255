import {
  calculateFeatureArea,
  findFeatureCentroid,
  offsetPolylines,
} from "lib/isp-canvas/utils";
import { FeatureTypeToSeatCount } from "lib/metrics/calculate-potential-seats";
import { defaultValues } from "lib/constants";
import { colors } from "./constants";
import { ConferenceRooms, Coordinate, RoomBounds } from "../types";
import { constructLines, EdgeValue, Graph } from "lib/metrics/buzz";
import {
  ALL_MEETING_ROOMS,
  getMeetingRoomData,
  MeetingRoomMetricsLevels,
  MeetingRoomMetricsType,
  MeetingRoomSizeAndAllType,
  MeetingRoomSizes,
} from "../containers/meeting-room-metrics-manager";
import { daylightUtils } from "@outerlabs/metric-solver";
import { PIXEL_RATIO } from "blocks/lib/util";
import { Colors, Font } from "@outerlabs/ol-ui";

export const clear = (ctx: CanvasRenderingContext2D) => {
  // clear canvas
  ctx.save();
  ctx.setTransform(1, 0, 0, 1, 0, 0);
  ctx.clearRect(0, 0, 10000, 10000);
  ctx.restore();
};

// render buzz without rendering circulation lines (replacing previous renderBuzz)
export const renderBuzz = (
  ctx: CanvasRenderingContext2D,
  graph: Graph,
  calculatedBuzz: EdgeValue[]
) => {
  calculatedBuzz.forEach((b) => {
    const l: number[][] = [
      [b.from.x, b.from.y],
      [b.to.x, b.to.y],
    ];
    renderLine(ctx, l, getBuzzGradientColor(b.value), 40);
  });
};

// render circulation pathways, independent of buzz to allow for improved layering
export const renderCirculationPath = (
  ctx: CanvasRenderingContext2D,
  graph: Graph,
  isWalkability: boolean
) => {
  const lines = constructLines(graph);
  if (isWalkability) {
    lines.forEach((coords: number[][]) => {
      renderLine(ctx, coords, "#C1C4C9", 10);
    });
  } else
    lines.forEach((coords: number[][]) => {
      renderLine(ctx, coords, "#000000", 10);
    });
};

// const round = (v: number) => {
//   if (v > 1) return Math.round(v * 10) / 10;
//   else return Math.round(v * 100) / 100;
// };

export const renderMeetingRoomMetrics = (
  ctx: CanvasRenderingContext2D,
  rooms: ConferenceRooms,
  type: MeetingRoomMetricsType,
  levels: MeetingRoomMetricsLevels,
  level: string,
  size: MeetingRoomSizeAndAllType,
  meetingRoomMetricSelectedRoom: string | null
) => {
  MeetingRoomSizes.forEach((sizeInternal) => {
    Object.keys(rooms[sizeInternal]).forEach((rid) => {
      if (size !== ALL_MEETING_ROOMS && size !== sizeInternal) return;
      // if the room has data
      const { roomIdToDataMap } = getMeetingRoomData({
        conferenceRooms: rooms,
        meetingRoomMetricsLevels: levels,
        floorID: level,
        meetingRoomMetricsType: type,
        meetingRoomMetricsSize: size,
        buildingID: "US-MTV-40",
      });
      if (roomIdToDataMap[rid] && rooms[sizeInternal]?.[rid]) {
        // render colored background based on the normalized metric
        const { color, value } = roomIdToDataMap[rid];
        renderMeetingRoomMetric(
          ctx,
          rooms[sizeInternal][rid],
          color,
          value,
          "#000000",
          meetingRoomMetricSelectedRoom === rid
        );
      } else if (rooms[sizeInternal]?.[rid]) {
        // render gray background
        renderMeetingRoomMetric(
          ctx,
          rooms[sizeInternal][rid],
          "rgba(180,180,180,1)"
        );
      }
    });
  });
};

// draw a gray polygon over each meeting room block
// for rendering the plan when in Activities mode
export const wpiActivitiesRendering = (
  ctx: CanvasRenderingContext2D,
  rooms: ConferenceRooms
) => {
  MeetingRoomSizes.forEach((sizeInternal) => {
    Object.keys(rooms[sizeInternal]).forEach((rid) => {
      renderPolyline(
        ctx,
        rooms[sizeInternal][rid],
        Colors.Light3,
        true,
        Colors.Light3,
        8
      );
    });
  });
};

const gradientHelper =
  (startRgba: Array<number>, endRgba: Array<number>) => (v: number) => {
    if (startRgba.length !== 4 || endRgba.length !== 4) {
      throw new Error("gradient not correctly set up");
    }

    const [r, g, b, a] = startRgba.map((start, idx) => {
      // lerp between start and end vals
      const result = start + (endRgba[idx] - start) * v;
      if (idx !== 3) return Math.floor(result);
      return result;
    });
    const finalResult = `rgba(${r},${g},${b},${a})`;
    return finalResult;
  };

// Three color gradient helper for buzz
// 0 = dark purple, 0.5 = red, 1 = yellow
const getBuzzGradientColor = (v: number) => {
  // For some reason, v is flipped...
  const x = 1 - v;
  if (x < 0.5) {
    // yellow to red...
    return gradientHelper([243, 240, 88, 0.7], [214, 114, 98, 0.7])(x * 2);
  }

  // red to dark purple
  return gradientHelper([214, 114, 98, 0.7], [64, 37, 152, 0.7])((x - 0.5) * 2);
};

// Three color gradient helper for meeting rooms.
// 0 = red, 0.5 = yellow, 1 = green
export const getMeetingRoomGradientColor = (v: number) => {
  if (v < 0.5) {
    // red to yellow...
    return gradientHelper([244, 153, 142, 1], [245, 222, 152, 1])(v * 2);
  }

  // yellow to green
  return gradientHelper([245, 222, 152, 1], [144, 203, 134, 1])((v - 0.5) * 2);
};

export const renderLine = (
  ctx: CanvasRenderingContext2D,
  coords: number[][],
  color: string,
  width: number
) => {
  ctx.save();
  ctx.strokeStyle = color;
  ctx.lineWidth = width;
  ctx.beginPath();
  ctx.moveTo(coords[0][0], coords[0][1]);
  ctx.lineTo(coords[1][0], coords[1][1]);
  ctx.stroke();
  ctx.restore();
};

export const renderSite = (
  ctx: CanvasRenderingContext2D,
  floorplate: Coordinate[][]
) => {
  ctx.save();
  ctx.shadowBlur = 20;
  ctx.shadowColor = "rgba(0,0,0,0.2)";
  floorplate.forEach((coords: Coordinate[]) => {
    renderPolyline(ctx, coords, "#000000", false, "#FFFFFF");
  });
  ctx.restore();
};

export const renderShaders = (
  ctx: CanvasRenderingContext2D,
  imageCache: string,
  sizes: any
) => {
  const { TEXTURE_SCALE } = daylightUtils;
  const img = new Image();
  const sw = sizes.pixels.width;
  const sh = sizes.pixels.height;
  const dx = sizes.coordinates.bbMin.x;
  const dy = -sizes.coordinates.bbMax.y;
  const dw = sizes.pixels.width * PIXEL_RATIO * TEXTURE_SCALE;
  const dh = sizes.pixels.height * PIXEL_RATIO * TEXTURE_SCALE;

  ctx.scale(1, -1);

  img.crossOrigin = "anonymous";
  img.src = imageCache;
  img.onload = () => {
    ctx?.drawImage(img, 0, 0, sw, sh, dx, dy, dw, dh);
  };
};

export const renderFeatureCirculation = (
  ctx: CanvasRenderingContext2D,
  features: Coordinate[][]
) => {
  features.forEach((el) => {
    renderPolyline(ctx, el, "rgba(0,0,0,0.15)", true, "rgba(0,0,0,0.15)");
  });
};

export const renderOffsetFeatureCirculation = (
  ctx: CanvasRenderingContext2D,
  features: Coordinate[][],
  offset: number
) => {
  if (!features.length) return;
  offsetPolylines(features, offset).forEach((s) =>
    renderPolyline(ctx, s, "rgba(0,0,0,0.15)", true, "rgba(0,0,0,0.15)")
  );
};

export const renderPolyline = (
  ctx: CanvasRenderingContext2D,
  pl: Coordinate[],
  color?: string,
  filled?: boolean,
  fillColor?: string,
  lineWidth?: number,
  wrap?: boolean
) => {
  if (pl.length === 0 || !pl?.[0]) {
    // NOTE: Feature processor injects empty coordinates that breaks the line
    // segment. need to debug on the backend and figure out why this is
    // happening.
    return;
  }

  ctx.fillStyle = fillColor || "#FFFFFF";
  ctx.strokeStyle = color || "rgba(0,0,0,1)";
  ctx.lineWidth = lineWidth || 1;
  ctx.beginPath();

  ctx.moveTo(pl[0].x, pl[0].y);
  for (let i = 1; i < pl.length; i++) {
    const c = pl[i];
    ctx.lineTo(c.x, c.y);
  }
  if (filled || wrap) {
    ctx.lineTo(pl[0].x, pl[0].y);
  }
  if (filled) {
    ctx.fill();
    ctx.stroke();
  } else ctx.stroke();
};

export const renderMeetingRoomFeature = (
  ctx: CanvasRenderingContext2D,
  feature: Coordinate[],
  color: string,
  type: string,
  strokeColor?: string,
  showCount?: boolean,
  program?: string | null,
  selected?: boolean
) => {
  if (selected) {
    renderPolyline(ctx, feature, strokeColor, true, color, 10);
  } else {
    renderPolyline(ctx, feature, color, true, color, 10);
  }

  if (showCount) {
    const count =
      program !== "convertToCollaboration"
        ? FeatureTypeToSeatCount[type]
        : Math.max(
            Math.floor(
              calculateFeatureArea(feature) / defaultValues.collabSqFtPerPerson
            ),
            1
          );

    renderFeatureTag(ctx, feature, count.toString());
  }
};

export const renderMeetingRoomMetric = (
  ctx: CanvasRenderingContext2D,
  feature: Coordinate[],
  color: string,
  label?: string,
  textColor = "#000000",
  stroke?: boolean
) => {
  const lightened = color.slice(0, color.length - 2) + "0.6)";
  renderPolyline(
    ctx,
    feature,
    stroke ? "#EC4DE4" : "#ffffff",
    true,
    stroke ? lightened : color,
    8
  );
  if (label) {
    renderFeatureTag(ctx, feature, label, textColor);
  }
};

export const renderFeatureTag = (
  ctx: CanvasRenderingContext2D,
  feature: Coordinate[],
  tag: string,
  color = "#000000"
) => {
  const center = findFeatureCentroid(feature);
  ctx.save();
  ctx.font = `48px ${Font.Bold.fontFamily}`;
  ctx.fillStyle = color;
  ctx.textAlign = "center";
  ctx.textBaseline = "middle";
  ctx.scale(1, -1);
  ctx.fillText(tag, center.x, -center.y);
  ctx.restore();
};

export const roundedRectangle = (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  radius: number,
  fill = false,
  stroke = true
) => {
  ctx.beginPath();
  ctx.moveTo(x + radius, y);
  ctx.lineTo(x + width - radius, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
  ctx.lineTo(x + width, y + height - radius);
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
  ctx.lineTo(x + radius, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
  ctx.lineTo(x, y + radius);
  ctx.quadraticCurveTo(x, y, x + radius, y);
  ctx.closePath();
  if (fill) ctx.fill();
  if (stroke) ctx.stroke();
};

export const renderDesks = (
  ctx: CanvasRenderingContext2D,
  keptCenters: Coordinate[],
  disabledCenters: Coordinate[],
  boundary: Coordinate[][],
  size: number
) => {
  // render desk boundary
  ctx.setLineDash([4, 4]);
  boundary.forEach((c: Coordinate[]) => {
    renderPolyline(ctx, c, "#cccccc", false, undefined, 0.5, true);
  });
  ctx.setLineDash([0, 0]);

  ctx.fillStyle = colors.teamColor;
  ctx.strokeStyle = colors.teamBorderColor;
  ctx.lineWidth = 4;

  // render desks
  if (keptCenters && keptCenters.length) {
    keptCenters.forEach((c: Coordinate) => {
      ctx.beginPath();
      ctx.ellipse(c.x, c.y, size / 2, size / 2, 0, 0, 2 * Math.PI);
      ctx.stroke();
      ctx.fill();
    });
  }

  // render disabled desks
  ctx.fillStyle = colors.disableColor;
  disabledCenters.forEach((c: Coordinate) => {
    roundedRectangle(
      ctx,
      c.x - size / 2,
      c.y - size / 2,
      size,
      size,
      14,
      true,
      false
    );
  });
};

// render walkability functions
const setWPColor = (distance: number) => {
  if (distance <= 25) {
    return "#ECDCFF";
  } else if (25 < distance && distance <= 100) {
    return "#C597FF";
  } else if (100 < distance && distance <= 175) {
    return "#9E52FF";
  } else if (175 < distance && distance <= 249) {
    return "#770DFF";
  } else if (249 < distance) {
    return "#4F09AA";
  }
  return "#000000";
};

const setBorderColor = (selected: string, distance: number) => {
  switch (selected) {
    case "≤ 25ft":
      return distance <= 25;
    case "26 - 100ft":
      return 25 < distance && distance <= 100;
    case "101 - 174ft":
      return 100 < distance && distance <= 175;
    case "175 - 249ft":
      return 175 < distance && distance <= 249;
    case "≥ 250ft":
      return 249 < distance;
    default:
      return false;
  }
};

export const renderSelectedWalkPoints = (
  ctx: CanvasRenderingContext2D,
  walkPoints: {
    seatCoords: Coordinate;
    distance: number;
    feature: RoomBounds | undefined;
  }[],
  size: number,
  selected: string
) => {
  if (walkPoints) {
    walkPoints.forEach(
      (
        wp: {
          seatCoords: Coordinate;
          distance: number;
          feature: RoomBounds | undefined;
        },
        index: number
      ) => {
        const color = setWPColor(wp.distance);
        if (selected !== "") {
          const isSelected: boolean = setBorderColor(selected, wp.distance);
          ctx.fillStyle = color;
          ctx.strokeStyle = isSelected ? "#EC4DE4" : color;
          ctx.lineWidth = 8;

          roundedRectangle(
            ctx,
            wp.seatCoords.x - size / 2,
            wp.seatCoords.y - size / 2,
            size,
            size,
            14,
            true,
            true
          );
        } else {
          ctx.fillStyle = color;
          // if (metric === "allAmenities") {
          //   ctx.strokeStyle = color;
          //   ctx.lineWidth = 8;
          // } else {
          ctx.strokeStyle =
            index === 0 || index === walkPoints.length - 1 ? "#EC4DE4" : color;
          ctx.lineWidth = 8;
          // }

          roundedRectangle(
            ctx,
            wp.seatCoords.x - size / 2,
            wp.seatCoords.y - size / 2,
            size,
            size,
            14,
            true,
            true
          );
        }
      }
    );
  }
};

export const renderWalkCirculationPath = (
  ctx: CanvasRenderingContext2D,
  output: {
    seatCoords: Coordinate;
    distance: number;
    feature: RoomBounds | undefined;
    path: Coordinate[];
  }[]
) => {
  if (output.length !== 0) {
    const startPath = output[0].path;
    const lastPath = output[output.length - 1].path;

    renderPolyline(ctx, lastPath, "#000000", false, "#000000", 8);
    renderPolyline(ctx, startPath, "#000000", false, "#000000", 8);
  }
};
