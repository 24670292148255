import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "../../components/TextField";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

export interface Option {
  inputValue?: string;
  value: string;
  title: string;
}

type AutocompleteProps = {
  label: string;
  placeholder: string;
  options: Option[];
  onChange?: (value: Option) => void;
  onInput?: (value: string) => void;
  value: string;
};

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 0,
    "& button": {
      marginRight: 8,
    },
  },
}));

const AutocompleteWrapper = ({
  label,
  placeholder,
  options,
  onChange,
  onInput,
  value,
}: AutocompleteProps) => {
  const classes = useStyles();
  const active = options.find((option) =>
    value.toLowerCase().match(option.title.toLowerCase())
  );
  return (
    <Autocomplete
      value={
        !active || value === ""
          ? { title: value, value: "", inputValue: "" }
          : active
      }
      classes={{ root: classes.root }}
      onInput={(e: any) => {
        if (onInput) onInput(e.target.value);
      }}
      onChange={(event, newValue, reason) => {
        if (!onChange) return;
        if (typeof newValue === "string") {
          onChange({ title: newValue, value: "" });
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          onChange({ title: newValue.inputValue, value: newValue.inputValue });
        } else if (newValue) {
          onChange(newValue);
        } else if (reason === "clear") {
          onChange({ title: "", value: "" });
        }
      }}
      filterOptions={(_options) => _options}
      clearOnBlur
      handleHomeEndKeys
      options={options as Option[]}
      getOptionSelected={(option, _value) => option.value === _value.value}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        if (option.title) return option.title;
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.title;
      }}
      renderOption={(option) => option.title}
      renderInput={(params) => {
        return (
          <TextField {...params} label={label} placeholder={placeholder} />
        );
      }}
    />
  );
};

export default AutocompleteWrapper;
