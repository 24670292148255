import { Arc, arc } from "./arc";
import { Circle, circle } from "./circle";
import { insert, Insert } from "./insert";
import { line, Line } from "./line";
import { polyline, Polyline } from "./polyline";
import { solid, Solid } from "./solid";
import { text, Text } from "./text";
import { ellipse, Ellipse } from "./ellipse";
import { roundedRectangle, RoundedRectangle } from "./roundedRectangle";
import { rectangle, Rectangle } from "./rectangle";
import { path, Path } from "./path";

export type Shape =
  | Arc
  | Circle
  | Insert
  | Line
  | Polyline
  | Solid
  | Text
  | Ellipse
  | Rectangle
  | RoundedRectangle
  | Path;

export const shape = (s: Shape, name: string): string => {
  let res = "";
  switch (s.type) {
    case "arc":
      res += arc(s);
      break;
    case "ellipse":
      res += ellipse(s, name);
      break;
    case "circle":
      res += circle(s);
      break;
    case "insert":
      res += insert(s);
      break;
    case "line":
      res += line(s);
      break;
    case "polyline":
      res += polyline(s, name);
      break;
    case "solid":
      res += solid(s);
      break;
    case "path":
      res += path(s, name);
      break;
    case "rectangle":
      res += rectangle(s, name);
      break;
    case "roundedRectangle":
      res += roundedRectangle(s, name);
      break;
    case "text":
      res += text(s);
      break;
    default:
      break;
  }
  return res;
};
