import React, { useEffect, useRef, useState } from "react";
import { Project } from "lib/types";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";

const CustomButton = withStyles({
  root: {
    minWidth: "28px",
    minHeight: "28px",
    padding: "6px 0",
    color: "#718096",
    fontWeight: "bold",
    fontSize: "14px",
  },
})(Button);

export interface Props {
  project: Project;
  buildingId: string;
  floorID: string;
  floors: string[];
  onChange(value: string): void;
}

const useStyles = makeStyles({
  wrapper: {
    display: "flex",
    justifyContent: "flex-start",
    right: "6px",
    height: "40px",
    width: "65px",
    zIndex: 50,
  },
  menuTrigger: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginLeft: "6px",
  },
  floorIndicator: {
    fontFamily: "GoogleSansMedium",
    color: "#55555570",
    fontSize: "16px",
  },
});

const FloorSelector: React.FC<Props> = ({ onChange, floorID, floors }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const numberFloors = floors.filter((f) => !isNaN(parseInt(f, 10)));
  const letterFloors = floors.filter((f) => isNaN(parseInt(f, 10)));
  const classes = useStyles();
  const prevOpen = useRef(open);

  numberFloors.sort((a, b) => {
    return parseInt(b, 10) - parseInt(a, 10);
  });

  letterFloors.sort((a, b) => {
    if (a > b) return -1;
    if (a < b) return 1;
    return 0;
  });

  const sortedFloors = [...numberFloors, ...letterFloors];
  const handleToggle = () => {
    setOpen((_prevOpen) => !_prevOpen);
  };

  const handleClose = (
    event: React.MouseEvent<EventTarget>,
    index: number | null
  ) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    if (index !== null) {
      onChange(sortedFloors[index]);
    }
    setOpen(false);
  };

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      anchorRef.current!.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const floorItems = sortedFloors.map((c: string, i: number) => (
    <MenuItem
      key={c}
      value={c}
      selected={c === floorID}
      onClick={(event) => handleClose(event, i)}
      data-testid="floor-selector-option"
    >
      {!isNaN(parseInt(c, 10)) && "L"}
      {c}
    </MenuItem>
  ));

  return (
    <div className={classes.wrapper}>
      <div className={classes.menuTrigger}>
        <CustomButton
          ref={anchorRef}
          onClick={handleToggle}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          data-testid="floor-selector-button"
        >
          <ArrowLeftIcon />
        </CustomButton>
        <div className={classes.floorIndicator} style={{}}>
          {!isNaN(parseInt(floorID, 10)) && "L"}
          {floorID}
        </div>
      </div>

      <Popper
        style={{ zIndex: 200 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="left"
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: "left-start",
            }}
          >
            <Paper>
              <ClickAwayListener
                onClickAway={(event) => handleClose(event, null)}
              >
                <MenuList autoFocusItem={open} id="menu-list-grow">
                  {floorItems}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default React.memo(FloorSelector);
