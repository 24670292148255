import React, { useState, useEffect } from "react";
import Container from "components/layout/container";
import Table from "./table";
import Header from "components/summary/header";
import ProjectTitle from "./project-title";
import { makeSummary } from "lib/metrics/table-summary";
import Add from "./add";
import Welcome from "components/welcome";
import UserAvatar from "components/user-avatar";
import { NavTabs } from "lib/types";
import Loading from "components/loading";
import { makeStyles } from "@material-ui/core/styles";
import withStrategies from "lib/hoc/withStrategies";
import withTable from "lib/hoc/withTable";
import withBuildings from "lib/hoc/withBuildings";
import withSettings from "lib/hoc/withSettings";
import { defaultValues } from "lib/constants";
import { SideNavBar } from "@outerlabs/ol-ui/dist/components/Nav/SideNavBar";
import SideBarNavGroup from "components/sideNavBar/sideNavBarGroup";
import TopNavBar from "components/topNavBar/topNavBar";
import { useHistory } from "react-router-dom";
import EmptyState from "components/emptyState";
import withAutoMagic from "../lib/hoc/withAutoMagic";
import withBlockInstances from "lib/hoc/withBlockInstances";
import { useProjectCtrl, useTableCtrl } from "lib/containers";
import ShareModal from "./share-modal";
import { useGAPageView } from "../lib/hooks/use-ga";

const useStyles = makeStyles({
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginRight: "40px",
    marginBottom: "24px",
    marginTop: "100px",
  },
  emptyStateContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  header: { position: "absolute", top: "0", left: "0", zIndex: 100 },
});

const Strategies: React.FC = () => {
  const [showWelcome, setShowWelcome] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const classes = useStyles();
  const { project, saveProject } = useProjectCtrl();
  const history = useHistory();
  const { isPercent } = useTableCtrl();
  useGAPageView("Study");

  // show welcome modal if we haven't shown it before
  useEffect(() => {
    const welcome = !window.localStorage.getItem("welcome");
    if (welcome) {
      setShowWelcome(true);
      window.localStorage.setItem("welcome", "true");
    }
  }, []);

  // share a project with a list of users, these will become co-owners of the project
  const onAcceptSharing = async (addressList: string[]) => {
    if (!project) return;
    if (!project.users) project.users = [];

    const set = new Set<string>();
    project.users.forEach((u) => set.add(u));

    const deduped = addressList.filter((v) => !set.has(v));
    project.users = [...project.users, ...deduped];

    await saveProject(project);
    setShowModal(false);
  };

  if (!project) return <Loading />;
  const isEmpty = Object.keys(project.buildings).length === 0;

  return (
    <Container overflowY>
      <TopNavBar
        ProjectTitle={<ProjectTitle onBlur={saveProject} project={project} />}
        UserAvatar={<UserAvatar />}
        subTitle={"STUDY"}
        onShare={() => setShowModal(true)}
      />
      <div className={classes.header}>
        <SideNavBar
          appIconAlt={"portfolioIcon"}
          appIconSrc={defaultValues.appIconSrc}
          appIconClick={() => history.push(`/`)}
        >
          <SideBarNavGroup navTab={NavTabs.Strategy} />
        </SideNavBar>
      </div>
      {isEmpty ? (
        <div className={classes.emptyStateContainer}>
          <EmptyState
            text={"This study is empty."}
            subText={
              "Add buildings using the button on the bottom right corner."
            }
          />
        </div>
      ) : (
        <>
          <div className={classes.headerContainer}>
            {project.metrics ? (
              <Header
                isTableView
                summary={makeSummary(project)}
                isPercent={isPercent}
              />
            ) : (
              <Loading />
            )}
          </div>
          <Table />
        </>
      )}
      <Add />
      <Welcome
        open={showWelcome}
        page={0}
        onClose={() => setShowWelcome(false)}
      />
      <ShareModal
        onAccept={onAcceptSharing}
        open={showModal}
        handleClose={() => setShowModal(false)}
      />
    </Container>
  );
};

export default withTable(
  withBuildings(
    withSettings(withStrategies(withBlockInstances(withAutoMagic(Strategies))))
  )
);
