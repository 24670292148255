/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { dividerLengths, dividerWidths, getters } from "./constants";
import {
  Button,
  Transform,
  StyleProps,
  Block,
  BlockGetter,
  HitBox,
  MetricsProps,
} from "./types";
import {
  Geometry,
  Point,
  getBoundingBox,
  boxToSize,
  Box,
} from "@outerlabs/shapes-geometry";
import { mat4, quat, vec3 } from "gl-matrix";
import { colorByType, highlightByType } from "./util";
import { getBlockColor } from "./blocks";
import { lightenHex } from "../../lib/color";
import { Colors } from "@outerlabs/ol-ui";
const orange = "255,151,0";

// Clear the entire canvas
export const clearCanvas = (
  ctx: CanvasRenderingContext2D,
  width: number,
  height: number
) => {
  ctx.clearRect(0, 0, width, height);
};

// Clear the canvas region for the block
export const clearBlock = (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number
) => {
  ctx.clearRect(x, y, width, height);
};

// Highlight a button, used on hover
export const highlightButton = (
  ctx: CanvasRenderingContext2D,
  button: Button
) => {
  renderButton(ctx, button, "#eaeaea");
};

// Render a button to the canvas
export const renderButton = (
  ctx: CanvasRenderingContext2D,
  button: Button,
  fill: string
) => {
  ctx.save();
  ctx.fillStyle = fill;
  ctx.strokeStyle = "#808080";
  ctx.lineWidth = 1;
  ctx.setLineDash([6, 6]);
  ctx.strokeRect(button.x, button.y, button.w, button.h);
  ctx.fillRect(button.x, button.y, button.w, button.h);
  ctx.setLineDash([]);
  ctx.lineWidth = 1.5;
  ctx.beginPath();
  ctx.moveTo(button.x + button.w / 2 - 7, button.y + button.h / 2);
  ctx.lineTo(button.x + button.w / 2 + 7, button.y + button.h / 2);
  ctx.moveTo(button.x + button.w / 2, button.y + button.h / 2 - 7);
  ctx.lineTo(button.x + button.w / 2, button.y + button.h / 2 + 7);
  ctx.stroke();
  ctx.restore();
};

// Render an outline around a group.
//If highlight is true, it will use that group's highlight color, else the group's default color.
export const outlineBlock = (
  ctx: CanvasRenderingContext2D,
  block: Block,
  isHighlight: boolean,
  transform: Transform
) => {
  const box = getBoundingBox(block.geometry);
  const { type } = getters.getDefinition(block);
  const [w, h] = boxToSize(box);
  const [x, y] = box[0];
  const { scale, tl } = transform;
  const tz = { x: x * scale, y: y * scale, w: w * scale, h: h * scale };
  const color = isHighlight ? highlightByType(type) : colorByType(type);
  ctx.save();
  ctx.strokeStyle = color;
  ctx.lineWidth = 3;
  ctx.strokeRect(tl.x + tz.x, tl.y + tz.y, tz.w, tz.h);
  ctx.restore();
};

// Render buttons
export const renderButtons = (
  ctx: CanvasRenderingContext2D,
  buttons: Button[]
) => {
  buttons.forEach((b) => renderButton(ctx, b, "#FFFFFF"));
};

// Push a rotation transform to the canvas context. Used for rotating assets.
export const setRotationAroundCenter = (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  degrees: number,
  w: number,
  h: number
) => {
  ctx.translate(x + w / 2, y + h / 2);
  ctx.rotate((degrees * Math.PI) / 180.0);
  ctx.translate(-x - w / 2, -y - h / 2);
};

// Pop the rotation transform from the canvas context. Used for resetting the transform above.
export const resetRotationAroundCenter = (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  degrees: number,
  w: number,
  h: number
) => {
  ctx.translate(x + w / 2, y + h / 2);
  ctx.rotate(-(degrees * Math.PI) / 180.0);
  ctx.translate(-x - w / 2, -y - h / 2);
};

// Render a rectangular shadow, based on position and dimensions
export const renderShadow = (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  w: number,
  h: number
) => {
  ctx.save();
  ctx.shadowColor = "#999999";
  ctx.shadowBlur = 12;
  ctx.fillStyle = "white";
  ctx.fillRect(x, y, w, h);
  ctx.shadowColor = "transparent";
  ctx.restore();
};

export const renderHitBoxes = (
  ctx: CanvasRenderingContext2D,
  boxes: HitBox[],
  xform: mat4
) => {
  boxes.forEach((box) => {
    const m = mat4.mul(mat4.create(), box.mat, xform);
    ctx.save();
    applyMatrix(ctx, m);
    renderRectangle(
      ctx,
      box.box,
      undefined,
      `rgba(0,${100 + box.depth * 50},${255 - box.depth * 50},1)`
    );
    ctx.restore();
  });
};

// Render dividers between blocks (walls, partitions, glazing, lockers, etc)
// "Skip" is a boolean that skips multiplying the scale/rotation/position
// matrix by its parent context. It should be true for a top-level block without
// any parents.
export const renderDividers = (
  ctx: CanvasRenderingContext2D,
  instance: Block,
  mat: mat4,
  skip?: boolean
) => {
  ctx.fillStyle = "#ffffff";
  ctx.lineWidth = 1;
  const planters = [
    "planter10x30",
    "planter10x60",
    "planter20x99",
    "planter20x132",
  ];
  const sides = instance.props.sides;
  if (sides && sides.dividers) {
    sides.dividers.forEach((s, i) => {
      const side = s!;
      if (side.type !== "none") {
        ctx.save();
        const m = skip ? mat : mat4.mul(mat4.create(), mat, instance.matrix!);
        applyMatrix(ctx, m);
        const dividerWidth = dividerWidths[side.type!];
        const dividerLength = dividerLengths[side.type!];
        const size = instance.props.metrics?.size || [0, 0];
        const fullLength = size[i % 2]!;
        let length = fullLength;
        let offset = 0;
        if (side.mode === "auto") {
          offset = side.start || 0;
          length = length - offset - (side.end || 0);
        } else {
          length = side.size || 12;
          offset =
            side.align === "min"
              ? 0
              : side.align === "max"
              ? fullLength - length
              : (fullLength - length) / 2;
        }
        const direction = i % 2 === 1;
        ctx.strokeStyle = "#000000";
        const pos: [number, number] =
          i === 0
            ? [offset, 0]
            : i === 1
            ? [size[0]! - dividerWidth, offset]
            : i === 2
            ? [offset, size[1]! - dividerWidth]
            : [0, offset];
        if (planters.indexOf(side.type!) >= 0) {
          renderSegmentedDividerPlanters(
            ctx,
            length,
            dividerWidth,
            dividerLength,
            direction,
            pos
          );
        } else {
          renderSegmentedDivider(
            ctx,
            length,
            dividerWidth,
            dividerLength,
            direction,
            pos
          );
        }
        ctx.restore();
      }
    });
  }
};

export const formatInches = (n: number) => {
  const inches = Math.round(n);
  const feet = Math.floor(inches / 12);
  const remainder = inches % 12;
  return `${feet}' ${remainder}"`;
};

// Render dimensions, where points are [topLeft, topRight, leftTop, leftBottom] - first couple for top dimension, second couple for left dimension
export const renderDimensions = (
  ctx: CanvasRenderingContext2D,
  points: Point[],
  scale = 1,
  tickSize = 6
) => {
  ctx.save();
  const [topLeft, topRight, leftTop, leftBottom] = points;
  const topDimension = formatInches((topRight[0] - topLeft[0]) / scale);
  const leftDimension = formatInches((leftBottom[1] - leftTop[1]) / scale);

  ctx.fillStyle = "#999999";
  ctx.strokeStyle = "#bababa";
  ctx.font = "16px Courier";
  const topLabel = topDimension + "";
  const topLabelSize = ctx.measureText(topLabel);
  const leftLabel = leftDimension + "";
  const leftLabelSize = ctx.measureText(leftLabel);
  // top center dimension
  ctx.fillText(
    topLabel,
    (topRight[0] - topLeft[0]) / 2 + topLeft[0] - topLabelSize.width / 2,
    topLeft[1] - 20
  );
  // left center dimension
  ctx.fillText(
    leftLabel,
    leftTop[0] - leftLabelSize.width - 20,
    (leftBottom[1] - leftTop[1]) / 2 + leftTop[1]
  );

  // top horizontal line
  renderLine(ctx, topLeft, topRight);
  // top left tick
  renderLine(
    ctx,
    [topLeft[0], topLeft[1] - tickSize],
    [topLeft[0], topLeft[1] + tickSize]
  );
  // top right tick
  renderLine(
    ctx,
    [topRight[0], topRight[1] - tickSize],
    [topRight[0], topRight[1] + tickSize]
  );
  // left vertical line
  renderLine(ctx, leftTop, leftBottom);
  // left top tick
  renderLine(
    ctx,
    [leftTop[0] - tickSize, leftTop[1]],
    [leftTop[0] + tickSize, leftTop[1]]
  );
  // left bottom tick
  renderLine(
    ctx,
    [leftBottom[0] - tickSize, leftBottom[1]],
    [leftBottom[0] + tickSize, leftBottom[1]]
  );
  // TODO: for some reason the last line is black, so this hack is a noop
  renderLine(ctx, [0, 0], [0, 0]);

  ctx.restore();
};

// Render dimensions of a child block within a container
export const renderBlockDimensions = (
  ctx: CanvasRenderingContext2D,
  container: Block,
  child: Block,
  containerMat: mat4,
  childMat: mat4
) => {
  ctx.save();
  const offset = 60;
  const [xSize, ySize] = child.props.metrics!.size!;
  const childScale = mat4.getScaling(vec3.create(), childMat);
  const childTranslation = mat4.getTranslation(vec3.create(), childMat);
  const containerTranslation = mat4.getTranslation(vec3.create(), containerMat);
  const topLeft: Point = [
    childTranslation[0],
    containerTranslation[1] - offset,
  ];
  const topRight: Point = [
    childTranslation[0] + xSize! * childScale[0],
    containerTranslation[1] - offset,
  ];
  const leftTop: Point = [
    containerTranslation[0] - offset,
    childTranslation[1],
  ];
  const leftBottom: Point = [
    containerTranslation[0] - offset,
    childTranslation[1] + ySize! * childScale[1],
  ];
  renderDimensions(
    ctx,
    [topLeft, topRight, leftTop, leftBottom],
    childScale[0]
  );
};

export const renderLine = (
  ctx: CanvasRenderingContext2D,
  start: Point,
  end: Point
) => {
  ctx.beginPath();
  ctx.moveTo(start[0], start[1]);
  ctx.lineTo(end[0], end[1]);
  ctx.stroke();
};

// Render a generalized divider made up of sequential rectangles
export const renderSegmentedDivider = (
  ctx: CanvasRenderingContext2D,
  length: number,
  thickness: number,
  segmentLength: number,
  vertical = false,
  pos: [number, number] = [0, 0]
) => {
  const numSegments = Math.floor(length / segmentLength);
  const remainder = length - numSegments * segmentLength;
  ctx.beginPath();
  if (vertical) {
    for (let i = 0; i < numSegments; i++) {
      ctx.rect(pos[0], pos[1], thickness, segmentLength);
      pos[1] += segmentLength;
    }
    ctx.rect(pos[0], pos[1], thickness, remainder);
  } else {
    for (let i = 0; i < numSegments; i++) {
      ctx.rect(pos[0], pos[1], segmentLength, thickness);
      pos[0] += segmentLength;
    }
    ctx.rect(pos[0], pos[1], remainder, thickness);
  }
  ctx.fill();
  ctx.stroke();
};

// create off-screen canvas for planter pattern
const canvasPattern = document.createElement("canvas");
const S = 12;
const n = 6;
canvasPattern.width = S;
canvasPattern.height = S;
const pattern = canvasPattern.getContext("2d")!;
pattern.lineWidth = 1;
pattern.fillStyle = "#ffffff";
pattern.fillRect(0, 0, 1000, 1000);
pattern.strokeStyle = "#009900";
// draw pattern to off-screen context
pattern.beginPath();
for (let i = 0; i < n; i++) {
  pattern.moveTo(0, S * 2 * (i / n) - S);
  pattern.lineTo(S, S * 2 * (i / n));
}
pattern.strokeStyle = "#88ff88";
for (let i = 0; i < n; i++) {
  pattern.moveTo(S, S * 2 * (i / n) - S);
  pattern.lineTo(0, S * 2 * (i / n));
}
pattern.stroke();

export const renderPlanter = (
  ctx: CanvasRenderingContext2D,
  p: CanvasPattern,
  x: number,
  y: number,
  w: number,
  h: number
) => {
  const a = 3;
  ctx.fillStyle = "#ffffff";
  ctx.beginPath();
  ctx.rect(x, y, w, h);
  ctx.fill();
  ctx.stroke();
  ctx.closePath();
  ctx.fillStyle = p;
  ctx.beginPath();
  ctx.rect(x + a, y + a, w - a * 2, h - a * 2);
  ctx.fill();
  ctx.stroke();
  ctx.closePath();
};

// Render a divider made up of planters
export const renderSegmentedDividerPlanters = (
  ctx: CanvasRenderingContext2D,
  length: number,
  thickness: number,
  segmentLength: number,
  vertical = false,
  pos: [number, number] = [0, 0]
) => {
  const numSegments = Math.floor(length / segmentLength);
  const remainder = length - numSegments * segmentLength;
  const _pattern = ctx.createPattern(canvasPattern, "repeat")!;
  if (vertical) {
    for (let i = 0; i < numSegments; i++) {
      renderPlanter(ctx, _pattern, pos[0], pos[1], thickness, segmentLength);
      pos[1] += segmentLength;
    }
    renderPlanter(ctx, _pattern, pos[0], pos[1], thickness, remainder);
  } else {
    for (let i = 0; i < numSegments; i++) {
      renderPlanter(ctx, _pattern, pos[0], pos[1], segmentLength, thickness);
      pos[0] += segmentLength;
    }
    renderPlanter(ctx, _pattern, pos[0], pos[1], remainder, thickness);
  }
};

export const offsetBox = (box: Box, offset: number): Box => {
  return [
    [box[0][0] + offset, box[0][1] + offset],
    [box[1][0] - offset, box[1][1] - offset],
  ];
};

const renderBlockOutline = (
  ctx: CanvasRenderingContext2D,
  instance: Block,
  mat: mat4,
  color: string
) => {
  ctx.save();
  applyMatrix(ctx, mat4.mul(mat4.create(), mat, instance.matrix!));
  ctx.lineWidth = 2;
  renderRectangle(ctx, instance.geometry.box!, color);
  ctx.restore();
};

export const renderSubBlockInstance = async (
  ctx: CanvasRenderingContext2D,
  instance: Block,
  m: mat4,
  getBlockById: BlockGetter,
  renderGrid = false,
  level = 0,
  active?: string
) => {
  const mm = mat4.mul(mat4.create(), m, instance.matrix!);
  instance.children.forEach((child) => {
    if (child.role === "asset-instance") {
      renderAsset(ctx, child, getBlockById, mm, false);
    } else {
      renderSubBlockInstance(
        ctx,
        child,
        mm,
        getBlockById,
        renderGrid,
        level + 1,
        active
      );
    }
  });
  if (renderGrid) {
    renderBlockOutline(ctx, instance, m, `rgba(${orange},0.5)`);
  }
};

export const isCollab = (metrics: MetricsProps): boolean => {
  return (
    metrics.types.collaboration &&
    (!metrics.types.focus ||
      metrics.types.focus.area < metrics.types.collaboration.area)
  );
};

// fills in the "interior" of asset line work with white
export const renderAssetBackground = async (
  ctx: CanvasRenderingContext2D,
  instance: Block,
  m: mat4,
  color: string
) => {
  const mm = mat4.mul(mat4.create(), m, instance.matrix!);
  renderGeometry(ctx, instance.geometry, { fill: true, fillStyle: color }, mm);
};

export const renderSubBlockBackgrounds = (
  ctx: CanvasRenderingContext2D,
  instance: Block,
  m: mat4,
  getBlockById: BlockGetter,
  renderGrid = false,
  level = 0,
  active?: string,
  wpiActivitiesMode?: boolean
) => {
  const mm = mat4.mul(mat4.create(), m, instance.matrix!);

  if (wpiActivitiesMode) {
    renderGeometry(
      ctx,
      instance.geometry,
      { fill: true, fillStyle: Colors.Light3 },
      mm
    );
  } else {
    renderGeometry(
      ctx,
      instance.geometry,
      { fill: true, fillStyle: getBlockColor(instance, 5, 0) },
      mm
    );
  }

  instance.children.forEach((child) => {
    if (child.role === "asset-instance") {
      if (wpiActivitiesMode) {
        // When the activities tab is selected, render the backgrounds of
        // blocks surrounding individual assets based on the asset's activity.

        let wpiOverride: 0 | 1 | 2 = 0; // default, if 0, dont override

        // If this is the lowest level block, may need to peek into its assets
        // to determine its color.
        const activity = child.props.definition?.activity;
        if (activity === "individual") wpiOverride = 1;
        if (activity === "communal") wpiOverride = 2;
        if (wpiOverride > 0) {
          renderGeometry(
            ctx,
            instance.geometry,
            { fill: true, fillStyle: getBlockColor(instance, 0, wpiOverride) },
            mm
          );
        }
      }
    } else {
      renderSubBlockBackgrounds(
        ctx,
        child,
        mm,
        getBlockById,
        renderGrid,
        level + 1,
        active,
        wpiActivitiesMode
      );
    }
  });
};

// Same logic as renderSubBlockBackground, the function above
export const renderSubBlockActiveBackground = async (
  ctx: CanvasRenderingContext2D,
  instance: Block,
  m: mat4,
  getBlockById: BlockGetter,
  renderGrid = false,
  level = 0,
  active?: string,
  wpiActivitiesMode?: boolean
) => {
  const mm = mat4.mul(mat4.create(), m, instance.matrix!);

  const isActive = active === instance.id;
  if (isActive) {
    renderGeometry(
      ctx,
      instance.geometry,
      { fill: true, fillStyle: getBlockColor(instance, 10, 0) },
      mm
    );
  }

  instance.children.forEach((child) => {
    if (child.role === "asset-instance") {
      if (wpiActivitiesMode) {
        let wpiOverride: 0 | 1 | 2 = 0;
        const activity = child.props.definition?.activity;
        if (activity === "individual") wpiOverride = 1;
        if (activity === "communal") wpiOverride = 2;
        if (wpiOverride > 0) {
          renderGeometry(
            ctx,
            instance.geometry,
            { fill: true, fillStyle: getBlockColor(instance, 5, wpiOverride) },
            mm
          );
        }
      } else {
        renderSubBlockActiveBackground(
          ctx,
          child,
          mm,
          getBlockById,
          renderGrid,
          level + 1,
          isActive ? child.id : active,
          wpiActivitiesMode
        );
      }
    }
  });
};

// Render a block
// this function is called whenever the cursor clicks on the canvas
// in addition to rendering blocks on the canvas, this function also gets called
// to render the available blocks in the block libraries when switching into the
// draw mode, and is used to render block thumbnails in MM and the block libraries
export const renderBlockInstance = (
  ctx: CanvasRenderingContext2D,
  instance: Block,
  mat: mat4,
  getBlockById: BlockGetter,
  skip = false,
  renderGrid = false,
  active?: string,
  renderBackground?: boolean,
  wpiActivitiesMode?: boolean
) => {
  let m = skip ? mat : mat4.mul(mat4.create(), mat, instance.matrix!);
  if (ctx) {
    const metrics = getters.getMetrics(instance);
    const layout = getters.getLayout(instance);
    const pos = mat4.getTranslation(vec3.create(), m);
    const q = mat4.getRotation(quat.create(), m);
    let angle = quat.getAxisAngle(vec3.fromValues(0, 0, 1), q);
    if (q[2] < 0) angle = -angle;
    let mx = mat4.create();
    if (layout.mirrorX && layout.mirrorY) {
      mx = mat4.fromRotationTranslationScale(
        mat4.create(),
        quat.fromEuler(quat.create(), 0, 0, 180),
        vec3.fromValues(metrics.size[0], metrics.size[1], 0),
        vec3.fromValues(1, 1, 1)
      );
    } else if (layout.mirrorY) {
      ctx.save();
      ctx.scale(1, -1);
      ctx.translate(-(2 * metrics.size[1]) * Math.sin(angle), -pos[1] * 2);
      mx = mat4.fromRotationTranslationScale(
        mat4.create(),
        quat.fromEuler(quat.create(), 0, 0, -angle * 2 * (180 / Math.PI)),
        vec3.fromValues(0, -metrics.size[1], 0),
        vec3.fromValues(1, 1, 1)
      );
    } else if (layout.mirrorX) {
      ctx.save();
      ctx.scale(-1, 1);
      ctx.translate(-pos[0] * 2, metrics.size[0] * 2 * Math.sin(angle));
      mx = mat4.fromRotationTranslationScale(
        mat4.create(),
        quat.fromEuler(quat.create(), 0, 0, -angle * 2 * (180 / Math.PI)),
        vec3.fromValues(-metrics.size[0], 0, 0),
        vec3.fromValues(1, 1, 1)
      );
    }
    m = mat4.mul(mat4.create(), m, mx);
    if (!instance.children.length) {
      if (instance.role === "asset-instance" || instance.role === "asset")
        renderAsset(ctx, instance, getBlockById, m, !!renderBackground);
    } else {
      if (renderBackground) {
        instance.children.forEach((child) => {
          if (child.role !== "asset-instance" && child.role !== "asset") {
            renderSubBlockBackgrounds(
              ctx!,
              child,
              m,
              getBlockById,
              renderGrid,
              0,
              active,
              wpiActivitiesMode
            );
          }
          renderSubBlockBackgrounds(
            ctx!,
            child,
            m,
            getBlockById,
            renderGrid,
            0,
            active,
            wpiActivitiesMode
          );
        });
        instance.children.forEach((child) => {
          if (child.role !== "asset-instance") {
            renderSubBlockActiveBackground(
              ctx!,
              child,
              m,
              getBlockById,
              renderGrid,
              0,
              active,
              wpiActivitiesMode
            );
          }
        });
      }
      instance.children.forEach((child) => {
        if (
          (child.role === "asset-instance" || child.role === "asset") &&
          !wpiActivitiesMode
        ) {
          renderAsset(ctx!, child, getBlockById, m, false);
        } else {
          renderSubBlockInstance(
            ctx!,
            child,
            m,
            getBlockById,
            renderGrid,
            0,
            active
          );
        }
      });
      renderDividers(ctx, instance, m, true);
      instance.children.forEach((child) => {
        if (child.role !== "asset-instance" && child.role !== "asset")
          renderBlockDividers(ctx!, child, m);
      });
    }
    if (layout.mirrorX && !layout.mirrorY) ctx.restore();
    if (layout.mirrorY && !layout.mirrorX) ctx.restore();
  }
};

export const renderBlockDividers = async (
  ctx: CanvasRenderingContext2D,
  instance: Block,
  m: mat4
) => {
  const mm = mat4.mul(mat4.create(), m, instance.matrix!);
  renderDividers(ctx, instance, m);
  instance.children.forEach((child) => {
    if (child.role !== "asset-instance") renderBlockDividers(ctx, child, mm);
  });
};

// Render a line, based on start and end points
export const drawLine = (
  ctx: CanvasRenderingContext2D,
  start: Point,
  end: Point
): void => {
  ctx.save();
  ctx.beginPath();
  ctx.moveTo(start[0], start[1]);
  ctx.lineTo(end[0], end[1]);
  ctx.stroke();
  ctx.restore();
};

// Render a rectangle from a list of 2 [x,y] coordinates and a color
export const renderRectangle = (
  ctx: CanvasRenderingContext2D,
  points: number[][],
  strokeColor?: string,
  fillColor?: string
) => {
  if (fillColor) {
    ctx.fillStyle = fillColor;
    ctx.fillRect(
      points[0][0],
      points[0][1],
      points[1][0] - points[0][0],
      points[1][1] - points[0][1]
    );
  }
  if (strokeColor) {
    ctx.strokeStyle = strokeColor;
    ctx.strokeRect(
      points[0][0],
      points[0][1],
      points[1][0] - points[0][0],
      points[1][1] - points[0][1]
    );
  }
};

// Render a polygon from a list of [x,y] coordinates and a color
export const renderPolyline = (
  ctx: CanvasRenderingContext2D,
  points: number[][],
  strokeColor?: string,
  fillColor?: string
) => {
  if (points.length <= 1) return;
  if (fillColor) {
    ctx.fillStyle = fillColor;
    ctx.beginPath();
    ctx.moveTo(points[0][0], points[0][1]);
    for (let i = 1; i < points.length; i++) {
      ctx.lineTo(points[i][0], points[i][1]);
    }
    ctx.fill();
  }
  if (strokeColor) {
    ctx.strokeStyle = strokeColor;
    ctx.beginPath();
    ctx.moveTo(points[0][0], points[0][1]);
    for (let i = 1; i < points.length; i++) {
      ctx.lineTo(points[i][0], points[i][1]);
    }
    ctx.stroke();
  }
};

// Render a rectangle from a list of 2 [x,y] coordinates and a color
export const renderPaths = (
  ctx: CanvasRenderingContext2D,
  geometry: string[],
  style: StyleProps,
  matrix: mat4
) => {
  ctx.save();
  applyMatrix(ctx, matrix);
  ctx.strokeStyle = "#000000";
  ctx.fillStyle = "#ffffff";
  ctx.lineWidth = 1;
  if (style.lineDash) {
    ctx.setLineDash(style.lineDash);
  }
  if (style.fill && style.fillStyle) {
    ctx.fillStyle = style.fillStyle;
  }
  if (style.stroke && style.strokeStyle) {
    ctx.strokeStyle = style.strokeStyle;
  }
  geometry.forEach((path) => {
    const p = new Path2D(path);
    if (style.fill) ctx.fill(p);
    if (style.stroke || !style.fill) ctx.stroke(p);
  });
  ctx.restore();
};

export const renderGeometry = (
  ctx: CanvasRenderingContext2D,
  geometry: Geometry,
  style: Partial<StyleProps>,
  matrix: mat4
) => {
  ctx.save();
  applyMatrix(ctx, matrix);
  if (style.fill && style.fillStyle) {
    ctx.fillStyle = style.fillStyle;
  }
  if (style.stroke && style.strokeStyle) {
    ctx.strokeStyle = style.strokeStyle;
  }
  ctx.lineWidth = style.lineWidth || 1;
  let points: Point[] | undefined;
  if (style.lineDash) ctx.setLineDash(style.lineDash);
  switch (geometry.type) {
    case "box":
      points = geometry.box;
      if (points) {
        ctx.beginPath();
        if (style.radius) {
          roundRectangle(
            ctx,
            0,
            0,
            points[1][0] - points[0][0],
            points[1][1] - points[0][1],
            style.radius,
            !!style.fill,
            !!style.stroke
          );
        } else {
          ctx.rect(
            0,
            0,
            points[1][0] - points[0][0],
            points[1][1] - points[0][1]
          );
        }
        ctx.closePath();
        if (style.fill) ctx.fill();
        if (style.stroke || !style.fill) ctx.stroke();
      }
      break;
    case "ellipse":
      points = geometry.box;
      if (points) {
        ctx.beginPath();
        const rx = Math.abs(points[1][0] - points[0][0]) / 2;
        const ry = Math.abs(points[1][1] - points[0][1]) / 2;
        ctx.ellipse(0 + rx, 0 + ry, rx, ry, 0, 0, Math.PI * 2);
        ctx.closePath();
        if (style.fill) ctx.fill();
        if (style.stroke || !style.fill) ctx.stroke();
      }
      break;
    case "polygon":
      const paths = geometry.polygon;
      if (paths)
        paths.forEach((path) => {
          for (let i = 0; i < path.length - 1; i++) {
            ctx.beginPath();
            ctx.moveTo(path[i][0], path[0][1]);
            ctx.lineTo(path[i + 1][0], path[i + 1][1]);
            if (style.fill) ctx.fill();
            if (style.stroke || !style.fill) ctx.stroke();
          }
        });
      break;
    default:
      break;
  }
  ctx.restore();
};

function roundRectangle(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  radius: [number, number, number, number],
  fill = false,
  stroke = true
) {
  ctx.beginPath();
  ctx.moveTo(x + radius[0], y);
  ctx.lineTo(x + width - radius[1], y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius[1]);
  ctx.lineTo(x + width, y + height - radius[2]);
  ctx.quadraticCurveTo(
    x + width,
    y + height,
    x + width - radius[2],
    y + height
  );
  ctx.lineTo(x + radius[3], y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius[3]);
  ctx.lineTo(x, y + radius[0]);
  ctx.quadraticCurveTo(x, y, x + radius[0], y);
  ctx.closePath();
  if (fill) ctx.fill();
  if (stroke) ctx.stroke();
}

export const applyMatrix = (ctx: CanvasRenderingContext2D, m: mat4) => {
  const s = mat4.getScaling(vec3.create(), m);
  const q = mat4.getRotation(quat.create(), m);
  let angle = quat.getAxisAngle(vec3.fromValues(0, 0, 1), q);
  if (q[2] < 0) angle = -angle;
  ctx.scale(s[0], s[1]);
  ctx.translate(m[12] / s[0], m[13] / s[1]);
  ctx.rotate(angle);
};

export const renderAsset = (
  ctx: CanvasRenderingContext2D,
  assetInst: Block,
  getBlockById: BlockGetter,
  mat: mat4,
  renderBackground: boolean
) => {
  if (assetInst.props.definition?.status === "disabled") return;
  const m = mat4.mul(mat4.create(), mat || mat4.create(), assetInst.matrix!);
  const definition = getters.getDefinition(assetInst);
  if (renderBackground && definition.fillColor !== "") {
    renderAssetBackground(
      ctx,
      assetInst,
      m,
      lightenHex(definition.fillColor, 5)
    );
  }
  if (assetInst.children.length) {
    assetInst.children.forEach((child) => {
      renderAsset(ctx, child, getBlockById, m, false);
    });
  } else if (assetInst.symbol) {
    const base = getBlockById(assetInst.symbol);
    if (base) renderAsset(ctx, base, getBlockById, m, false);
  } else {
    const style = getters.getStyle(assetInst);
    if (assetInst.props.paths?.[0])
      renderPaths(ctx, assetInst.props.paths as any, style, m);
    else renderGeometry(ctx, assetInst.geometry, style, m);
  }
};
