import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { portfolioLayers } from "./lib/constants";
import { Tooltip } from "@outerlabs/ol-ui";

const useStyles = makeStyles({
  layer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  layerList: {
    maxHeight: 400,
    overflowY: "scroll",
  },
  item: {
    padding: "8px 16px",
  },
});

const BuildingLayers = () => {
  const classes = useStyles({});
  const [selectedTab, setTab] = useState<string>("required");

  function handleChange(event: React.ChangeEvent<any>, newValue: string) {
    setTab(newValue);
  }

  return (
    <div className={classes.layer}>
      <div>
        <Typography variant="body2">DXF File Layers</Typography>
        <Typography variant="body2">
          The list below indicates the layers and their naming that need to be
          included in your dxf file prior to uploading.{" "}
        </Typography>
      </div>
      <div>
        <Tabs
          value={selectedTab}
          textColor="primary"
          indicatorColor="primary"
          onChange={handleChange}
        >
          <Tab value="required" label="Required" />
          <Tab value="recommended" label="Recommended" />
        </Tabs>
        <div hidden={selectedTab !== "required"}>
          <List className={classes.layerList}>
            {portfolioLayers
              .filter((v) => v.required)
              .map((v) => (
                <ListItem key={v.name} className={classes.item}>
                  <Tooltip title={v.display} placement={"right"}>
                    <Typography variant={"subtitle1"}>{v.name}</Typography>
                  </Tooltip>
                </ListItem>
              ))}
          </List>
        </div>
        <div hidden={selectedTab !== "recommended"}>
          <List className={classes.layerList}>
            {portfolioLayers
              .filter((v) => !v.required)
              .map((v) => (
                <ListItem key={v.name} className={classes.item}>
                  <Tooltip title={v.display} placement={"right"}>
                    <Typography variant={"subtitle1"}>{v.name}</Typography>
                  </Tooltip>
                </ListItem>
              ))}
          </List>
        </div>
      </div>
    </div>
  );
};

export default BuildingLayers;
