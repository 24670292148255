import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles({
  compass: {
    position: "relative",
    height: 16,
    width: 16,
    borderRadius: 16,
    border: "2px solid #5F6166",
    "&::after": {
      content: "''",
      width: 2,
      height: 8,
      background: "#5F6166",
      zIndex: 1000,
      position: "absolute",
      left: 5,
    },
  },
});

const Compass: React.FC<{ value: number }> = ({ value }) => {
  const classes = useStyles();
  return (
    <div
      className={classes.compass}
      style={{ transform: `rotate(${value}deg)` }}
    />
  );
};

export default Compass;
