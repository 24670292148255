import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import WalkabilityCirculation from "./walkabilityCirculation";
import WalkabilityData from "./walkabilityData";
import { Button } from "@material-ui/core";
import { RendererMode, useRendererCtrl, useSettingsCtrl } from "lib/containers";
import { ErrorOutline } from "@material-ui/icons";

const useWalkabilityStyles = makeStyles({
  stepButtons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  stepButton: {
    width: "45%",
  },
  warningModal: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    background: "white",
    borderRadius: 4,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    marginBottom: 24,
    padding: 4,
  },
  warningText: {
    width: 281,
    fontFamily: "GoogleSansRegular",
    fontWeight: 400,
    fontSize: 14,
    letterSpacing: 0.25,
    color: "#5F6166",
  },
});

const Walkability: React.FC = () => {
  const classes = useWalkabilityStyles();
  const { currentSettings } = useSettingsCtrl();
  const { setRendererMode, setWalkabilityOutput } = useRendererCtrl();

  const [walkabilityStep, setWalkabilityStep] = React.useState<number>(0);
  const [openModal, setOpenModal] = React.useState<boolean>(false);

  const circulationCheck = () => {
    if (currentSettings.circulation) {
      setWalkabilityStep(1);
      setRendererMode(RendererMode.WalkabilityOutput);
    }
  };

  const exportModalOpen = () => {
    setOpenModal(true);
  };

  useEffect(() => {
    circulationCheck();
    setWalkabilityOutput([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const revertToCirculation = () => {
    setWalkabilityOutput([]);
    setWalkabilityStep(0);
    setRendererMode(RendererMode.WalkabilityMetric);
    return;
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "calc(100% - 192px)",
          paddingBottom: 12,
          paddingRight: 12,
          paddingLeft: 12,
          justifyContent: "space-between",
        }}
      >
        {walkabilityStep === 0 && (
          <WalkabilityCirculation
            setOpenModal={exportModalOpen}
            openModal={openModal}
          />
        )}
        {walkabilityStep === 1 && (
          <WalkabilityData revertCirc={revertToCirculation} />
        )}
        {walkabilityStep === 0 && (
          <>
            <div>
              {openModal && (
                <div className={classes.warningModal}>
                  <div
                    style={{
                      color: "#FFAF38",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                      width: 40,
                    }}
                  >
                    <ErrorOutline />
                  </div>
                  <div className={classes.warningText}>
                    Note: Any changes made to circulation paths will be updated
                    across all other analytics tool features
                  </div>
                </div>
              )}
              <div className={classes.stepButtons}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setRendererMode(RendererMode.Select);
                  }}
                  className={classes.stepButton}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setRendererMode(RendererMode.WalkabilityOutput);
                    setWalkabilityStep(1);
                  }}
                  className={classes.stepButton}
                >
                  Save
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Walkability;
