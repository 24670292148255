import React from "react";
import { Canvas } from "@outerlabs/canvas-reconciler";
import { Coordinate, PortfolioBlockInstance } from "lib/types";
import { renderPortfolioBlockInstance } from "lib/isp-canvas";
import { Block, BlockGetter } from "../../blocks/lib/types";

interface Props {
  instances: PortfolioBlockInstance[][];
  selected?: Block[][];
  getBlockById: BlockGetter;
  columns?: Coordinate[];
  wpiActivitiesMode: boolean;
}

export const Blocks: React.FC<Props> = ({
  instances,
  getBlockById,
  wpiActivitiesMode,
  selected,
}) => {
  const isActiveCheck = (instance: Block[]) => {
    let selectedBlockID = "";

    selected?.forEach((block) => {
      if (block[0].id === instance[0].id) {
        selectedBlockID = block[0].id;
      }
    });

    return selectedBlockID;
  };

  const canvas = (
    <Canvas
      render={async (ctx: CanvasRenderingContext2D) => {
        if (instances.length > 0) {
          await Promise.all(
            instances.map(async (_instances) => {
              await renderPortfolioBlockInstance({
                ctx,
                instances: _instances,
                getBlockById,
                flipVertical: false,
                flipHorizontal: false,
                wpiActivitiesMode,
                selected: isActiveCheck(_instances),
              });
            })
          );
        }
      }}
    />
  );

  return <>{canvas}</>;
};

export default React.memo(Blocks);
