import axios from "axios";
import { setupCache } from "axios-cache-adapter";
import config from "config";
import { getAuthHeader } from "lib/auth";
import {
  getPrimaryCirculation,
  getUniqueWorkstations,
} from "lib/metrics/remove-desks";
import { Coordinate, Features } from "../types";

const cache = setupCache({
  maxAge: 30 * 60 * 1000,
});

export interface FeaturesExt extends Features {
  uniqueDesks: Coordinate[];
  primaryCirculation: number[][][];
}

export const getFeatures = async (
  buildingID: string,
  floorID: string
): Promise<FeaturesExt> => {
  try {
    const headers = await getAuthHeader();
    const api = axios.create({
      headers,
      adapter: cache.adapter,
    });

    const data = await api.get(
      `${config.baseUrl}/api/planning/buildings/${buildingID}/${floorID}/features`
    );
    const features = data.data as Features;
    const uniqueDesks = getUniqueWorkstations(features);
    const primaryCirculation = getPrimaryCirculation(features);
    if (!features.walls) features.walls = [];
    if (!features.wallsExterior) features.wallsExterior = [];
    if (!features.wallsPartial) features.wallsPartial = [];
    if (!features.glazing) features.glazing = [];
    if (!features.glazingExterior) features.glazingExterior = [];
    if (!features.circulation) features.circulation = [];
    if (!features.primaryCirculation) features.primaryCirculation = [];
    if (!features.columns) features.columns = [];
    if (!features.skylights) features.skylights = [];

    // if there's no exterior glazing layer, assume the entire facade is glazed
    // TODO: remove once we can ensure that all buildings have exterior glazing
    if (features.glazingExterior.length === 0) {
      for (let i = 0; i < features.floorplate.length; i++) {
        for (let j = 0; j < features.floorplate[i].length; j++) {
          features.glazingExterior.push([
            features.floorplate[i][j],
            features.floorplate[i][(j + 1) % features.floorplate[i].length],
          ]);
        }
      }
    }

    return { ...features, uniqueDesks, primaryCirculation };
  } catch (e) {
    console.error(
      "Failed to get features. buildingId: ",
      buildingID,
      "floorId: ",
      floorID,
      "error:",
      e
    );

    // TODO(Dan): Returning this object is a landmine and we should throw instead.
    // A lot of downstream code expects this function to return a Features object,
    // and some of that code will crash if it isn't provided one. However, this behavior is load bearing.
    // There are other bugs causing the above API call to fail and we need to carry on in order to keep
    // the app functioning at all.
    return { uniqueDesks: [], primaryCirculation: [] } as any;
  }
};

export default getFeatures;
