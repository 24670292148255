import React from "react";
import { Canvas } from "@outerlabs/canvas-reconciler";
import { Coordinate } from "lib/types";
import { renderDesks } from "lib/isp-canvas";
import { defaultValues } from "lib/constants";
interface Props {
  boundaries: Coordinate[][];
  keptCenters: Coordinate[];
  disabledCenters: Coordinate[];
}

export const Desks: React.FC<Props> = ({
  keptCenters,
  disabledCenters,
  boundaries,
}) => {
  const canvas = (
    <Canvas
      render={(ctx: CanvasRenderingContext2D) => {
        renderDesks(
          ctx,
          keptCenters || [],
          disabledCenters || [],
          boundaries,
          defaultValues.deskWidth
        );
      }}
    />
  );

  return <>{canvas}</>;
};

export default React.memo(Desks);
