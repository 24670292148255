import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Slider from "./slider";
import analytics from "../../lib/analytics";

interface SliderControlsProps {
  handleSliderActive: (key: string, active: boolean) => void;
  onMetricChange: (mutation: { [key: string]: any }) => void;
  deskSpacing: number;
  corridorWidth: number;
}

const useStyles = makeStyles(() => ({
  container: {
    padding: "16px 0",
  },
}));

const SliderControls: React.FC<SliderControlsProps> = ({
  handleSliderActive,
  deskSpacing,
  corridorWidth,
  onMetricChange,
}) => {
  const classes = useStyles();

  const handleSliderCommit = async (
    key: string,
    event: any,
    newValue: number | number[]
  ) => {
    analytics.projectSlidersAdjusted();
    onMetricChange({ [key]: newValue });
  };

  return (
    <div className={classes.container}>
      <div
        onMouseEnter={() => handleSliderActive("seatSpacing", true)}
        onMouseLeave={() => handleSliderActive("seatSpacing", false)}
      >
        <Slider
          title="Minimum Seat Spacing"
          min={36}
          max={144}
          value={Math.max(deskSpacing, 36)}
          onChangeCommitted={handleSliderCommit}
          apiName="deskSpacing"
          helpguideTooltip="Minimum Seat Spacing"
        />
      </div>
      <div
        onMouseEnter={() => handleSliderActive("circulationBuffer", true)}
        onMouseLeave={() => handleSliderActive("circulationBuffer", false)}
      >
        <Slider
          title="Circulation Buffer"
          min={0}
          max={6 * 12}
          value={corridorWidth}
          onChangeCommitted={handleSliderCommit}
          apiName="corridorWidth"
          helpguideTooltip="Circulation Buffer"
        />
      </div>
    </div>
  );
};

export default SliderControls;
