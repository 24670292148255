import { program } from "../../blocks/lib/constants";
import { Block } from "../../blocks/lib/types";

// circulation and core are currently not included within portfolio
const functionalCategories = [
  "team",
  "meeting",
  "events",
  "businessSupport",
  "amenity",
];

// get all the valid L2 space types
const teamL2s = Object.keys(program.team.children.team.children);
const meetingL2s = Object.keys(program.meeting.children.meeting.children);
const eventsL2s = Object.keys(program.events.children.events.children);
const cbsL2s = Object.keys(
  program.businessSupport.children.businessSupport.children
);
// amenities require special handling - get all valid L1s & l2s for amenities
const amenityL1s: string[] = [];
for (const L1 in program.amenity.children) {
  amenityL1s.push(L1);
}

const amenityL2sNested: { [k: string]: any } = {};
amenityL1s.forEach((L1) => {
  amenityL2sNested[L1] = Object.keys(program.amenity.children[L1].children);
});

const amenityL2s: string[] = [];
for (const L1 in amenityL2sNested) {
  amenityL2sNested[L1].forEach((L2: string) => {
    amenityL2s.push(L2);
  });
}

// returns a number that is the sum of the seatcount for all blocks on the floor
const getBlockSeatcount = (blocks: Block[][] | undefined) => {
  let totalHeadcount = 0;
  if (blocks?.[0]?.length) {
    blocks.forEach((block) => {
      const headcount = block[0].props.metrics?.seats;
      if (headcount !== undefined) {
        totalHeadcount += headcount;
      }
    });
  }
  return totalHeadcount;
};

// returns an object with the total area of each program category of all the
// blocks on the floor. each category is its own individual object, containing
// a `total`, and the area of any space types and/or subcategories in that
// category. if a specific space type or subcategory does not exist on the floor,
// it will not exist in the object
//
// eg:
//  {
//    amenity: {
//      workplaceSupport: #,
//      food: #,
//      ...
//      total: #
//    },
//    businessSupport: {..., total: #}
//    events: {..., total: #}
//    meeting: {..., total: #}
//    team: {
//      desk: #,
//      nook: #,
//      focusStudio: #,
//      total: #
//    }
//  }
export const getBlockAreas = (blocks: Block[][] | undefined) => {
  // create objects & initialize with default values of 0
  const categoryAreas: { [k: string]: { [k: string]: any } } = {};
  functionalCategories.forEach((category) => {
    categoryAreas[category] = { total: 0 };
  });
  const amenityAreas: { [k: string]: { [k: string]: number } } = {};
  amenityL1s.forEach((L1) => {
    amenityAreas[L1] = { total: 0 };
  });

  if (blocks?.[0]?.length) {
    // extract only the relevant bits of each block
    const simplifiedBlocks = blocks.map((block) => {
      return block ? block[0].props.metrics?.types : undefined;
    });

    // count up area for all blocks on the floorplan
    simplifiedBlocks.forEach((block) => {
      for (const asset in block) {
        let assetArea = block[asset]?.area;
        const assetHC = block[asset]?.seats || 0;
        // assetArea note: square footage arising from edge conditions
        // (eg planter) is not be captured here, even though it is reflected
        // in the area of the block as a whole

        if (assetArea !== undefined && assetHC !== undefined) {
          assetArea = assetArea / 144;
          if (teamL2s.includes(asset)) {
            categoryAreas.team.total += assetArea;
            if (categoryAreas.team[asset]) {
              assetArea += categoryAreas.team[asset];
            }
            categoryAreas.team = { ...categoryAreas.team, [asset]: assetArea };
          } else if (meetingL2s.includes(asset)) {
            categoryAreas.meeting.total += assetArea;
            if (categoryAreas.meeting[asset]) {
              assetArea += categoryAreas.meeting[asset];
            }
            categoryAreas.meeting = {
              ...categoryAreas.meeting,
              [asset]: assetArea,
            };
          } else if (eventsL2s.includes(asset)) {
            categoryAreas.events.total += assetArea;
            if (categoryAreas.events[asset]) {
              assetArea += categoryAreas.events[asset];
            }
            categoryAreas.events = {
              ...categoryAreas.events,
              [asset]: assetArea,
            };
          } else if (cbsL2s.includes(asset)) {
            categoryAreas.businessSupport.total += assetArea;
            categoryAreas.businessSupport = {
              ...categoryAreas.businessSupport,
              [asset]: assetArea,
            };
          } else if (amenityL2s.includes(asset)) {
            categoryAreas.amenity.total += assetArea;
            sortAndAddAmenitySubArea(asset, block[asset], amenityAreas);
          }
        }
      }
    });

    categoryAreas.amenity = { ...amenityAreas, ...categoryAreas.amenity };
  }
  return categoryAreas;
};

// returns a number that is the total area of all the blocks on the floor plan
export const getTotalBlockArea = (blocks: Block[][] | undefined) => {
  let totalArea = 0;
  const blockAreas = getBlockAreas(blocks);

  for (const category in blockAreas) {
    totalArea += blockAreas[category].total;
  }
  return totalArea;
};

// returns the "density" (google definition: GIA/person) per program category
export const getDensities = (blocks: Block[][] | undefined) => {
  const categoryDensities: { [k: string]: number } = {};
  const categoryAreas = getBlockAreas(blocks);
  const seatcount = getBlockSeatcount(blocks);

  for (const category in categoryAreas) {
    const density = categoryAreas[category].total / seatcount;

    if (!isNaN(density) && Math.floor(density) !== Infinity) {
      categoryDensities[category] = Math.floor(density);
    } else {
      categoryDensities[category] = 0;
    }
  }

  return categoryDensities;
};

export const getAmenityDensities = (blocks: Block[][] | undefined) => {
  const amenityDensities: { [k: string]: number } = {};
  const amenityAreas = getBlockAreas(blocks).amenity;
  const seatcount = getBlockSeatcount(blocks);

  for (const L1 in amenityAreas) {
    if (L1 !== "total") {
      const density = amenityAreas[L1].total / seatcount;

      if (!isNaN(density)) {
        amenityDensities[L1] = Math.round(density);
      } else {
        amenityDensities[L1] = 0;
      }
    }
  }

  return amenityDensities;
};

// updates the passed in `amenitySubAreas` object with the area of the `asset`
// depending on which L1 it belongs to
const sortAndAddAmenitySubArea = (
  assetName: string,
  asset: any,
  amenitySubAreas: { [k: string]: { [k: string]: number } }
) => {
  const area = asset.area / 144;
  for (const L1 in amenityL2sNested) {
    if (amenityL2sNested[L1].includes(assetName)) {
      amenitySubAreas[L1].total += area;
      if (amenitySubAreas[L1][assetName]) {
        amenitySubAreas[L1][assetName] += area;
      }
      amenitySubAreas[L1] = { ...amenitySubAreas[L1], [assetName]: area };
    }
  }
};

export const sumDensities = (densities: any) => {
  let sum = 0;
  for (const density in densities) {
    if (!isNaN(densities[density])) {
      sum += densities[density];
    }
  }
  return sum;
};

// returns the display name of a program (at at level), given its value
export const getProgramDisplayName = (value: string) => {
  let displayName = "";

  const displayNameHelper = (o: any): any => {
    if (o[value]) {
      displayName = o[value].name;
    } else {
      for (const key in o) {
        if (o[key].children) {
          displayNameHelper(o[key].children);
        }
      }
    }
  };

  displayNameHelper(program);
  return displayName;
};

// returns the color for any program
// works for program at any level
// (ie "amenity", "workplaceSupport", and "lobby" will all return colors)
export const getProgramColor = (value: string) => {
  let color = "#FFF";
  let parentColor = "#FFF";

  const programColorHelper = (o: any): any => {
    if (o[value]) {
      if (o[value].color) {
        color = o[value].color;
      } else {
        color = parentColor;
      }
    } else {
      for (const key in o) {
        if (o[key].color) {
          parentColor = o[key].color;
        }
        if (o[key].children) {
          programColorHelper(o[key].children);
        }
      }
    }
  };

  programColorHelper(program);
  return color;
};

// for one or more blocks, get metrics for its assets that have activity
// type `communal`.
// returns an object with the following:
// {
//   seats: #
//   headcount: #
//   workpoints: #
//   area: #
//   density: #
// }
export const getCommunalMetrics = (blocks: Block[][]) => {
  const communalMetrics = {
    seats: 0,
    headcount: 0,
    workpoints: 0,
    area: 0,
    density: 0,
  };

  if (blocks?.[0]?.length) {
    blocks.forEach((block) => {
      getActivityMetrics("communal", block[0], communalMetrics);
    });
  }

  if (communalMetrics.area !== 0 && communalMetrics.headcount !== 0) {
    communalMetrics.density = communalMetrics.area / communalMetrics.headcount;
  }

  return communalMetrics;
};

//same as `getCommunalMetrics`, but for blocks' `individual` assets
export const getIndividualMetrics = (blocks: Block[][]) => {
  const individualMetrics = {
    seats: 0,
    headcount: 0,
    workpoints: 0,
    area: 0,
    density: 0,
  };

  if (blocks?.[0]?.length) {
    blocks.forEach((block) => {
      getActivityMetrics("individual", block[0], individualMetrics);
    });
  }

  if (individualMetrics.area !== 0 && individualMetrics.headcount !== 0) {
    individualMetrics.density =
      individualMetrics.area / individualMetrics.headcount;
  }

  return individualMetrics;
};

// helper function to support `getCommunalMetrics()` and `getIndividualMetrics()`
// takes in a single block and either "individual" or "communal", and returns
// the metrics of any assets within the block if it has the appropriate
// activity type
const getActivityMetrics = (
  activityType: string,
  block: Block,
  metrics: Record<string, any>
) => {
  // recursively drill down until we reach each asset, adding that asset's
  // metrics to the cumulative metrics if it matches activityType
  const blockDiver = (b: Block) => {
    if (b) {
      // end case 1: we have reached an asset
      if (
        b.role === "asset-instance" ||
        b.role === "asset" ||
        ((b.role === "block-instance" || b.role === "block") &&
          b.children.length === 0)
      ) {
        if (b.props.definition && b.props.metrics) {
          if (b.props.definition.activity === activityType) {
            metrics.seats += b.props.metrics.seats || 0;
            metrics.headcount += b.props.metrics.headcount || 0;
            metrics.workpoints += b.props.metrics.workpoints || 0;
            metrics.area += (b.props.metrics.area || 0) / 144;
          }
        }
        // end case 2:
        // we have reached a case where a `block-instance` holds homogenous
        // assets within itself, and the `block-instance` is a container used
        // to allow for more space surrounding the asset in order to fill up
        // to the larger boundary of the block.
        // in this case, we need to use this larger area, rather than the
        // sum of the individual asset areas
      } else if (
        (b.role === "block-instance" || b.role === "block") &&
        b.children.length !== 0 &&
        b.children[0].children.length === 0 &&
        b.symbol === b.children[0].symbol // assumed
      ) {
        if (b.children[0].props.definition?.activity === activityType) {
          let sumChildArea = 0;
          b.children.forEach((child) => {
            sumChildArea += child.props.metrics?.area || 0;
          });

          sumChildArea = Math.round(sumChildArea);

          if (b.props.metrics?.area) {
            if (b.props.metrics.area >= sumChildArea) {
              metrics.seats += b.props.metrics.seats || 0;
              metrics.headcount += b.props.metrics.headcount || 0;
              metrics.workpoints += b.props.metrics.workpoints || 0;
              metrics.area += b.props.metrics.area / 144;
            }
          }
        }
        // otherwise, continue with the recursion
      } else if (
        (b.role === "block-instance" || b.role === "block") &&
        b.children
      ) {
        b.children.forEach((child) => {
          blockDiver(child);
        });
      }
    }
  };

  if (block) {
    // individual and community assets only exist on teamspace assets,
    // so check that this block has teamspace assets at all
    const assetTypes = Object.keys(
      (block.props.metrics?.types as Record<string, any>) || {}
    );
    let hasTeamSpaceAsset = false;
    assetTypes.forEach((asset) => {
      if (teamL2s.includes(asset)) {
        hasTeamSpaceAsset = true;
      }
    });

    if (hasTeamSpaceAsset) {
      blockDiver(block);
    }
  }

  return metrics;
};
