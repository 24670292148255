import { IconButton, IconButtonProps, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { HelpguideHandle, HelpguideWrapperDiv } from "@outerlabs/helpguide";
import { RendererMode, useRendererCtrl } from "lib/containers";
import React, { useState } from "react";

const drawIcon = (color: string) => (
  <svg width="29" height="29" viewBox="0 0 29 29" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.882 5.4C11.882 3.82599 13.158 2.55 14.732 2.55C16.306 2.55 17.582 3.82599 17.582 5.4C17.582 6.97401 16.306 8.25 14.732 8.25C13.158 8.25 11.882 6.97401 11.882 5.4ZM9.65794 6.2859H6.5192C4.1564 6.2859 2.24098 8.20133 2.24098 10.5641V22.1715C2.24098 24.5343 4.1564 26.4497 6.5192 26.4497H18.1266C20.4894 26.4497 22.4048 24.5343 22.4048 22.1715H24.6458C24.6458 25.7719 21.727 28.6907 18.1266 28.6907H6.5192C2.91875 28.6907 0 25.7719 0 22.1715V10.5641C0 6.96367 2.91875 4.04492 6.5192 4.04492H9.76217C10.357 1.85806 12.3567 0.25 14.732 0.25C17.5763 0.25 19.882 2.55573 19.882 5.4C19.882 8.24427 17.5763 10.55 14.732 10.55C12.1898 10.55 10.0779 8.70803 9.65794 6.2859ZM23.4341 10.8224C24.0692 10.8224 24.5841 11.3372 24.5841 11.9724V14.4325H27.3315C27.9666 14.4325 28.4815 14.9474 28.4815 15.5825C28.4815 16.2176 27.9666 16.7325 27.3315 16.7325H24.5841V19.4799C24.5841 20.115 24.0692 20.6299 23.4341 20.6299C22.7989 20.6299 22.2841 20.115 22.2841 19.4799V16.7325H19.8239C19.1888 16.7325 18.6739 16.2176 18.6739 15.5825C18.6739 14.9474 19.1888 14.4325 19.8239 14.4325H22.2841V11.9724C22.2841 11.3372 22.7989 10.8224 23.4341 10.8224Z"
      fill={color}
    />
  </svg>
);

const deleteIcon = (color: string) => (
  <svg width="29" height="29" viewBox="0 0 29 29" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.882 5.4C11.882 3.82599 13.158 2.55 14.732 2.55C16.306 2.55 17.582 3.82599 17.582 5.4C17.582 6.97401 16.306 8.25 14.732 8.25C13.158 8.25 11.882 6.97401 11.882 5.4ZM9.65794 6.2859H6.5192C4.1564 6.2859 2.24098 8.20133 2.24098 10.5641V22.1715C2.24098 24.5343 4.1564 26.4497 6.5192 26.4497H18.1266C20.4894 26.4497 22.4048 24.5343 22.4048 22.1715V20H24.6458V22.1715C24.6458 25.7719 21.727 28.6907 18.1266 28.6907H6.5192C2.91875 28.6907 0 25.7719 0 22.1715V10.5641C0 6.96367 2.91875 4.04492 6.5192 4.04492H9.76217C10.357 1.85806 12.3567 0.25 14.732 0.25C17.5763 0.25 19.882 2.55573 19.882 5.4C19.882 8.24427 17.5763 10.55 14.732 10.55C12.1898 10.55 10.0779 8.70803 9.65794 6.2859ZM26.8718 12.1868C27.3209 12.6359 27.3209 13.3641 26.8718 13.8132L25.1322 15.5528L27.0749 17.4955C27.524 17.9446 27.524 18.6727 27.0749 19.1218C26.6258 19.5709 25.8977 19.5709 25.4486 19.1218L23.5059 17.1791L21.5632 19.1218C21.1141 19.5709 20.3859 19.5709 19.9368 19.1218C19.4877 18.6727 19.4877 17.9446 19.9368 17.4955L21.8795 15.5528L20.14 13.8132C19.6908 13.3641 19.6908 12.6359 20.14 12.1868C20.5891 11.7377 21.3172 11.7377 21.7663 12.1868L23.5059 13.9264L25.2455 12.1868C25.6946 11.7377 26.4227 11.7377 26.8718 12.1868Z"
      fill={color}
    />
  </svg>
);

const editIcon = (color: string) => (
  <svg width="29" height="29" viewBox="0 0 29 29" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.42011 3.58543H7.00003C6.37325 3.58543 5.7657 3.66781 5.18763 3.82233L5.575 5.27144L5.96235 6.72055C6.28998 6.63297 6.63715 6.58541 7.00003 6.58541H8.42011V3.58543ZM20.3013 20.3246V20.6905C20.3013 21.0534 20.2537 21.4005 20.1662 21.7282L21.6153 22.1155L23.0644 22.5029C23.2189 21.9248 23.3013 21.3173 23.3013 20.6905V20.3246H20.3013ZM5.575 26.0044L5.18763 27.4535C5.76571 27.608 6.37325 27.6904 7.00003 27.6904H8.55024V26.1904V24.6904H7.00003C6.63715 24.6904 6.28999 24.6428 5.96236 24.5553L5.575 26.0044ZM4.25067 25.4553L3.49946 26.7536C2.43719 26.139 1.55143 25.2532 0.936803 24.1911L2.23515 23.4398L3.5335 22.6886C3.88533 23.2967 4.39379 23.8052 5.00188 24.157L4.25067 25.4553ZM1.68603 22.1155L0.2369 22.5029C0.0823787 21.9248 0 21.3173 0 20.6905V19.0063H1.50001H3.00001V20.6905C3.00001 21.0534 3.04757 21.4005 3.13515 21.7282L1.68603 22.1155ZM1.50001 17.3221H0V13.9537H1.50001H3.00001V17.3221H1.50001ZM1.50001 12.2695H0V10.5853C0 9.9586 0.0823786 9.35106 0.236899 8.773L1.68603 9.16035L3.13515 9.54771C3.04757 9.87534 3.00001 10.2225 3.00001 10.5853V12.2695H1.50001ZM2.23515 7.83605L0.936803 7.08485C1.55143 6.02258 2.43719 5.13683 3.49946 4.52222L4.25067 5.82055L5.00188 7.11889C4.39379 7.47071 3.88533 7.97917 3.5335 8.58726L2.23515 7.83605ZM21.0662 23.4398L22.3645 24.1911C21.7499 25.2532 20.8641 26.139 19.8019 26.7536L19.0507 25.4553L18.2995 24.157C18.9075 23.8052 19.416 23.2967 19.7678 22.6886L21.0662 23.4398ZM17.7263 26.0044L18.1137 27.4535C17.5356 27.608 16.9281 27.6904 16.3013 27.6904H14.7511V26.1904V24.6904H16.3013C16.6642 24.6904 17.0114 24.6428 17.339 24.5553L17.7263 26.0044ZM13.2009 26.1904V27.6904H10.1004V26.1904V24.6904H13.2009V26.1904Z"
      fill={color}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.0244 8.3953C14.6313 8.3953 16.0487 7.02815 16.0487 5.19764C16.0487 3.36714 14.6313 1.99999 13.0244 1.99999C11.4175 1.99999 10 3.36714 10 5.19764C10 7.02815 11.4175 8.3953 13.0244 8.3953ZM13.0244 10.3952C15.7993 10.3952 18.0487 8.06822 18.0487 5.19764C18.0487 2.32707 15.7993 0 13.0244 0C10.2495 0 8.00004 2.32707 8.00004 5.19764C8.00004 8.06822 10.2495 10.3952 13.0244 10.3952Z"
      fill={color}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.1065 18.7908C23.7133 18.7908 25.1308 17.4236 25.1308 15.5931C25.1308 13.7626 23.7133 12.3954 22.1065 12.3954C20.4996 12.3954 19.0821 13.7626 19.0821 15.5931C19.0821 17.4236 20.4996 18.7908 22.1065 18.7908ZM22.1065 20.7908C24.8813 20.7908 27.1309 18.4637 27.1309 15.5931C27.1309 12.7225 24.8813 10.3954 22.1065 10.3954C19.3316 10.3954 17.0821 12.7225 17.0821 15.5931C17.0821 18.4637 19.3316 20.7908 22.1065 20.7908Z"
      fill={color}
    />
  </svg>
);

const useBlockButtonStyles = makeStyles({
  button: {
    borderRadius: 14,
    padding: 8,
    fontSize: 14,
  },
  selected: {
    backgroundColor: "rgba(0, 0, 0, 0.04)",
  },
});

interface BlockButtonProps extends IconButtonProps {
  selected?: boolean;
}

const BlockButton: React.FC<BlockButtonProps> = ({
  selected,
  children,
  ...props
}) => {
  const classes = useBlockButtonStyles();
  return (
    <IconButton
      className={`${classes.button} ${selected && classes.selected}`}
      {...props}
    >
      {children}
    </IconButton>
  );
};

const useWalkabilityStyles = makeStyles({
  buttons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "60%",
    marginLeft: "auto",
    marginRight: "auto",
    paddingTop: 24,
  },
  button: {},
  buzz: {
    paddingTop: 12,
    fontSize: 16,
    color: "#5F6166",
  },
  buttonLabel: {
    paddingLeft: 10,
  },
});

interface Props {
  setOpenModal: () => void;
  openModal: boolean;
}

const WalkabilityCirculation: React.FC<Props> = ({ setOpenModal }) => {
  const classes = useWalkabilityStyles();

  const { rendererMode, setRendererMode } = useRendererCtrl();
  const [addTooltip, setAddTooltip] = useState<boolean>(false);
  const [deleteTooltip, setDeleteTooltip] = useState<boolean>(false);
  const [editTooltip, setEditTooltip] = useState<boolean>(false);

  const onDrawWalkCirculation = async () => {
    setOpenModal();
    setRendererMode(RendererMode.DrawWalkabilityCirculation);
  };

  const onDeleteWalkCirculation = async () => {
    setOpenModal();
    setRendererMode(RendererMode.DeleteWalkabilityCirculation);
  };

  const onEditWalkCirculation = async () => {
    setOpenModal();
    setRendererMode(RendererMode.EditWalkabilityCirculation);
  };

  const tooltipTriggers = (buttonAction: string) => {
    switch (buttonAction) {
      case "addOpen": {
        setAddTooltip(true);
        break;
      }
      case "addClose": {
        setAddTooltip(false);
        break;
      }
      case "deleteOpen": {
        setDeleteTooltip(true);
        break;
      }
      case "deleteClose": {
        setDeleteTooltip(false);
        break;
      }
      case "editOpen": {
        setEditTooltip(true);
        break;
      }
      case "editClose": {
        setEditTooltip(false);
        break;
      }
    }
  };

  return (
    <HelpguideWrapperDiv>
      <div className={classes.buzz}>
        Please draw all primary circulation paths
        <HelpguideHandle isSmall tooltipKey="Buzz" />
        <div className={classes.buttons}>
          <div className={classes.button}>
            <BlockButton
              onClick={onDrawWalkCirculation}
              selected={
                rendererMode === RendererMode.DrawWalkabilityCirculation
              }
              onMouseEnter={() => tooltipTriggers("addOpen")}
              onMouseLeave={() => tooltipTriggers("addClose")}
            >
              {drawIcon("#555555")}
            </BlockButton>
            <Tooltip title={"Add Path"} open={addTooltip} arrow>
              <div />
            </Tooltip>
          </div>
          <div>
            <BlockButton
              onClick={onDeleteWalkCirculation}
              selected={
                rendererMode === RendererMode.DeleteWalkabilityCirculation
              }
              onMouseEnter={() => tooltipTriggers("deleteOpen")}
              onMouseLeave={() => tooltipTriggers("deleteClose")}
            >
              {deleteIcon("#555555")}
            </BlockButton>
            <Tooltip title={"Delete Path"} open={deleteTooltip} arrow>
              <div />
            </Tooltip>
          </div>
          <div>
            <BlockButton
              onClick={onEditWalkCirculation}
              selected={
                rendererMode === RendererMode.EditWalkabilityCirculation
              }
              onMouseEnter={() => tooltipTriggers("editOpen")}
              onMouseLeave={() => tooltipTriggers("editClose")}
            >
              {editIcon("#555555")}
            </BlockButton>
            <Tooltip title={"Edit Path"} open={editTooltip} arrow>
              <div />
            </Tooltip>
          </div>
        </div>
      </div>
    </HelpguideWrapperDiv>
  );
};

export default WalkabilityCirculation;
