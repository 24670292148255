import { makeStyles } from "@material-ui/core/styles";
import { Block, BlockMatchMakerTeamInfoForProps } from "../blocks/lib/types";
import {
  Team,
  useBlockImagesCtrl,
  useBlocksCtrl,
  useMatchMakerCtrl,
} from "../lib/containers";
import React, { useEffect, useRef, useState } from "react";
import { Fab, MenuItem } from "@material-ui/core";
import { ReactComponent as MatchMakerIcon } from "../assets/icons/MatchMaker2.svg";
import BlockCard from "../blocks/components/block-card";
import IconClose from "@material-ui/icons/Close";
import Select from "../blocks/components/select";
import { ErrorOutline } from "@material-ui/icons";

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
  },
  filters: {
    display: "flex",
  },
  list: {
    position: "absolute",
    zIndex: 120,
    width: "calc(100% - 464px)",
    left: 464,
    height: "100%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    background: "#f4f4f6",
  },
  item: {},
  selected: {
    border: "1px solid",
  },
  image: {
    marginBottom: 20,
    height: 160,
    width: 160,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 16,
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },
  },
  select: {
    width: 240,
    background: "white !important",
  },
}));

type Props = {
  onCreate: (b: Block[], infos: BlockMatchMakerTeamInfoForProps[]) => void;
};

const MatchMakerList: React.FC<Props> = ({ onCreate }) => {
  const classes = useStyles();
  const {
    setMatchMakerStep,
    setMatchMakerActive,
    matchMakerLibrary,
    setMatchMakerLibrary,
    matchMakerResults,
    matchMakerTeams,
    matchMakerSelectedResults,
    setMatchMakerSelectedResults,
    mmUserProfileMap,
  } = useMatchMakerCtrl();
  const { blockLibraries } = useBlocksCtrl();
  const onClickRow = (i: number) => (j: number) => {
    const copy = [...matchMakerSelectedResults];
    copy[i] = j;
    setMatchMakerSelectedResults(copy);
  };
  const finalize = () => {
    const mmTeamInfos: BlockMatchMakerTeamInfoForProps[] = matchMakerTeams.map(
      (team, idx) => {
        return {
          team,
          matchmakerProfileMap: mmUserProfileMap,
          teamIdx: idx,
        };
      }
    );
    const results = matchMakerSelectedResults.map((i, j) => {
      return matchMakerResults[j][i];
    });
    setMatchMakerActive(false);
    setMatchMakerStep(0);
    onCreate(results, mmTeamInfos);
  };
  const onClose = () => {
    setMatchMakerActive(false);
    setMatchMakerStep(0);
  };
  const onMultipleChange =
    (fn: (value: string[]) => void) =>
    (event: React.ChangeEvent<{ value: unknown }>) =>
      fn(event.target.value as string[]);
  const onLibraryChange = onMultipleChange(setMatchMakerLibrary);

  const calcAllTeamsPercent = () => {
    let total = 0;
    const length = matchMakerResults.length;
    // eslint-disable-next-line array-callback-return
    matchMakerResults.map((blocks, i: number) => {
      if (matchMakerTeams?.[i]?.userProfilePercentages) {
        const teamCount =
          matchMakerTeams[i].userProfilePercentages.Frequent +
          matchMakerTeams[i].userProfilePercentages.Infrequent +
          matchMakerTeams[i].userProfilePercentages.Occasional;
        total += teamCount;
      }
    });
    return total / length;
  };

  return (
    <>
      <IconClose
        onClick={onClose}
        style={{
          position: "absolute",
          top: 32,
          right: 32,
          cursor: "pointer",
          zIndex: 1000,
        }}
      />
      <div className={classes.list}>
        <div style={{ margin: "48px 0 24px 160px" }}>
          <div
            style={{
              fontSize: "0.9rem",
              fontFamily: "GoogleSansMedium",
              color: "#5F6166",
              marginBottom: 8,
            }}
          >
            TOP MATCHES
          </div>
          <Select
            label="Library"
            placeholder={"Library"}
            renderValue={
              ((value: string | string[]) =>
                value?.length ? (
                  Array.isArray(value) ? (
                    <span>
                      {value
                        .map(
                          (k) =>
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            blockLibraries.find((el) => el.value === k)!.title
                        )
                        .join(", ")}
                    </span>
                  ) : (
                    <span>
                      {
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        blockLibraries.find((el) => el.value === value)!.title
                      }
                    </span>
                  )
                ) : (
                  <span>All Libraries</span>
                )) as any
            }
            displayEmpty={true}
            multiple={true}
            onChange={onLibraryChange}
            value={matchMakerLibrary}
            className={classes.select}
          >
            {blockLibraries
              .filter(({ state }) => {
                return state !== "unpublished" && state !== "deleted";
              })
              .map((option: { title: string; value: string }) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.title}
                </MenuItem>
              ))}
          </Select>
        </div>
        {matchMakerResults.length > 0 &&
          matchMakerResults.map((blocks, i: number) =>
            matchMakerTeams[i] ? (
              <MatchMakerRow
                key={i}
                blocks={blocks}
                onClick={onClickRow(i)}
                selected={matchMakerSelectedResults[i]}
                team={matchMakerTeams[i]}
                bg={"#f4f4f6"}
                i={i}
              />
            ) : null
          )}
      </div>
      <div
        style={{ position: "absolute", right: 48, bottom: 48, zIndex: 1000 }}
      >
        <Fab
          variant="extended"
          color="primary"
          onClick={finalize}
          disabled={calcAllTeamsPercent() !== 100 ? true : false}
        >
          <MatchMakerIcon style={{ marginRight: 8 }} />
          Done
        </Fab>
      </div>
    </>
  );
};

const styleTeamError = (team: string) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ color: "#FFAF38", marginLeft: 18 }}>
        <ErrorOutline />
      </div>
      <div>{team}</div>
    </div>
  );
};

export const MatchMakerRow = ({
  blocks,
  team,
  onClick,
  bg,
  selected,
  i,
}: {
  blocks: Block[];
  onClick: (i: number) => void;
  team: Team;
  bg: string;
  selected: number;
  i: number;
}) => {
  const { getBlockById } = useBlocksCtrl();
  const [images, setImages] = useState<{ [k: string]: string }>({});
  const { getBlockImage } = useBlockImagesCtrl();
  const cache = useRef<{ [k: string]: { [k: string]: string } }>({});

  let totalPercent = 0;
  if (team.userProfilePercentages) {
    totalPercent =
      team.userProfilePercentages.Frequent +
      team.userProfilePercentages.Infrequent +
      team.userProfilePercentages.Occasional;
  }

  useEffect(() => {
    const imageMap: { [k: string]: string } = {};
    const renderBlocks = async () => {
      // This just sets images on the block row, just references
      // what's in the passed in blocks array
      await Promise.all(
        blocks.map(async (block) => {
          if (
            cache.current[block.id] &&
            cache.current[block.id][team.headcount]
          ) {
            imageMap[block.id] = cache.current[block.id][team.headcount];
            return cache.current[block.id][team.headcount];
          }
          const image = await getBlockImage(block);
          imageMap[block.id] = image;
          if (!cache.current[block.id]) cache.current[block.id] = {};
          cache.current[block.id][team.headcount] = image;
          return image;
        })
      );
      setImages(imageMap);
    };
    renderBlocks();
  }, [blocks, getBlockById, team, getBlockImage, totalPercent]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        background: bg,
        alignItems: "center",
        borderTop: i === 0 ? "0px" : "1px solid #D5D8DC",
        padding: "12px 0",
      }}
    >
      <div
        style={{ margin: "20px 0 0 32px", fontSize: "1.1rem", minWidth: 120 }}
      >
        {totalPercent === 100 ? team.teamName : styleTeamError(team.teamName)}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          background: bg,
          alignItems: "center",
          overflow: "auto",
          opacity: totalPercent === 100 ? 1 : 0.5,
        }}
      >
        {blocks.map((block, idx) =>
          block ? (
            <div>
              <BlockCard
                key={block.id}
                block={block}
                image={images[block.id]}
                onClick={() => (totalPercent === 100 ? onClick(idx) : null)}
                size={"large"}
                active={totalPercent === 100 ? idx === selected : false}
                showHeadcount={true}
                showSize={false}
              />
            </div>
          ) : (
            <div>
              <div>No result</div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default MatchMakerList;
