import {
  calculateFeatureArea,
  translateFeatureToValue,
} from "lib/isp-canvas/utils";
import { Features, Metric } from "lib/types";
import calculatePotentialSeats, {
  RoomTypeToSeatCount,
} from "./calculate-potential-seats";
import { defaultValues } from "lib/constants";

export const addIndividualRooms = (metric: Metric) => {
  let capacity = 0;
  let count = 0;
  let workstations = 0;
  if (metric.userConvertedMeetingRooms === undefined)
    return { count: 0, workstations: 0, capacity: 0 };
  Object.keys(metric.userConvertedMeetingRooms).forEach((aN: string) => {
    if (
      metric.userConvertedMeetingRooms &&
      metric.userConvertedMeetingRooms[aN]
    ) {
      if (aN === "convertToWorkstations") {
        Object.values(metric.userConvertedMeetingRooms[aN]).forEach((room) => {
          const key = translateFeatureToValue(
            room.conferenceRoomType
          ) as keyof Metric;
          count = count + 1;
          workstations =
            workstations +
            calculatePotentialSeats(room.conferenceRoomType, metric, 1);
          capacity =
            capacity + metric[key].capacity !== undefined
              ? metric[key].capacity
              : metric[key];
        });
      }
    }
  });
  return { count, workstations, capacity };
};
export const subtractIndividualRooms = (metric: Metric) => {
  let capacity = 0;
  let count = 0;
  const workstations = 0;
  if (metric.userConvertedMeetingRooms === undefined)
    return { count: 0, workstations: 0, capacity: 0 };
  Object.keys(metric.userConvertedMeetingRooms).forEach((aN: string) => {
    if (
      metric.userConvertedMeetingRooms &&
      metric.userConvertedMeetingRooms[aN]
    ) {
      if (aN !== "convertToWorkstations") {
        Object.values(metric.userConvertedMeetingRooms[aN]).forEach((room) => {
          const key = translateFeatureToValue(room.conferenceRoomType);
          count = count - 1;
          switch (key) {
            case "phoneBooths":
              capacity -= RoomTypeToSeatCount.phoneBooth;
              break;
            case "huddle":
              capacity -= RoomTypeToSeatCount.huddle;
              break;
            case "small":
              capacity -= RoomTypeToSeatCount.smallMeetingRoom;
              break;
            case "medium":
              capacity -= RoomTypeToSeatCount.mediumMeetingRoom;
              break;
            case "large":
              capacity -= RoomTypeToSeatCount.largeMeetingRoom;
              break;
            case "extraLarge":
              capacity -= RoomTypeToSeatCount.extraLargeMeetingRoom;
              break;
          }
        });
      }
    }
  });

  return { count, workstations, capacity };
};
export const addIndividualCollaborationRooms = (
  metric: Metric,
  features: Features
) => {
  let collabCount = 0;
  let collabArea = 0.0;
  let collabZones = 0;
  let count = 0;
  // get the area for the rooms contained in the userConverted - convertToCollaboration array
  if (metric.userConvertedMeetingRooms !== undefined) {
    Object.keys(metric.userConvertedMeetingRooms).forEach((aN: string) => {
      if (
        metric.userConvertedMeetingRooms &&
        metric.userConvertedMeetingRooms[aN]
      ) {
        if (aN === "convertToCollaboration") {
          Object.values(metric.userConvertedMeetingRooms[aN]).forEach(
            (room) => {
              const roomID = room.conferenceRoomId;
              const roomType = room.conferenceRoomType;
              Object.keys(features.conferenceRooms[roomType]).forEach((key) => {
                if (key === roomID) {
                  const roomCoordinates =
                    features.conferenceRooms[roomType][roomID];
                  const area = calculateFeatureArea(roomCoordinates);
                  collabArea += area;
                  collabCount += Math.max(
                    Math.floor(area / defaultValues.collabSqFtPerPerson),
                    1
                  );
                  collabZones += 1;
                  count = count + 1;
                }
              });
            }
          );
        }
      }
    });
  }
  return { collabArea, collabCount, collabZones, count };
};
export const subtractIndividualCollaborationRooms = (
  metric: Metric,
  features: Features
) => {
  let collabCount = 0;
  let collabArea = 0.0;
  let collabZones = 0;
  // subtract the area contained in convert to workstation and disable arrays from the collaboration area if the group is changed to convertToCollaboration
  if (metric.userConvertedMeetingRooms !== undefined) {
    Object.keys(metric.userConvertedMeetingRooms).forEach((aN: string) => {
      if (
        metric.userConvertedMeetingRooms &&
        metric.userConvertedMeetingRooms[aN]
      ) {
        if (aN !== "convertToCollaboration") {
          Object.values(metric.userConvertedMeetingRooms[aN]).forEach(
            (room) => {
              const roomID = room.conferenceRoomId;
              const roomType = room.conferenceRoomType;
              Object.keys(features.conferenceRooms[roomType]).forEach((key) => {
                if (key === roomID) {
                  const roomCoordinates =
                    features.conferenceRooms[roomType][roomID];
                  const area = calculateFeatureArea(roomCoordinates);
                  collabArea -= area;
                  collabCount -= Math.max(
                    Math.floor(area / defaultValues.collabSqFtPerPerson),
                    1
                  );
                  collabZones -= 1;
                }
              });
            }
          );
        }
      }
    });
  }
  return { collabArea, collabCount, collabZones };
};
