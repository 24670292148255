import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useBlockLibrariesCtrl, useBlocksCtrl } from "lib/containers";
import analytics from "../../lib/analytics";
import BlockTable from "../components/block-table";
import TopNavBar from "components/topNavBar/topNavBar";
import UserAvatar from "components/user-avatar";
import { useHistory } from "react-router-dom";
import Upload from "../components/upload";
import { Block, Library } from "../lib/types";
import { SideNavBar } from "@outerlabs/ol-ui";
import { defaultValues } from "../../lib/constants";
import Nav from "../components/nav";
import { parse } from "svgson";
import { emptyDefinitionProp } from "../lib/constants";
import { svgToBlock } from "../lib/util";
import { useGAPageView } from "../../lib/hooks/use-ga";

const useStyles = makeStyles(() => ({
  container: {
    width: "calc(100vw - 64px)",
    height: "100%",
    overflow: "auto",
    marginLeft: 64,
    paddingTop: 80,
    paddingBottom: 60,
  },
  actions: {
    height: 80,
    display: "flex",
    justifyContent: "end",
    padding: 24,
  },
  fab: {
    position: "absolute",
    bottom: 32,
    right: 32,
  },
}));

const AssetList: React.FC = () => {
  const classes = useStyles();
  const { libraries } = useBlockLibrariesCtrl();
  const {
    blocks,
    createBlock,
    createOrUpdateBlock,
    getBlockById,
    activateBlock,
  } = useBlocksCtrl();
  const history = useHistory();
  const [blockLibraries, setBlockLibraries] = React.useState<{
    [k: string]: Block[];
  }>({});
  useGAPageView("Asset List");
  useEffect(() => analytics.blockMakerOpened(), []);
  useEffect(() => activateBlock(undefined), [activateBlock]);
  useEffect(() => {
    const libs: { [k: string]: Block[] } = { Uncategorized: [] };
    blocks
      .filter((b) => b.role === "asset")
      .forEach((block) => {
        if (block.props.definition && block.props.definition.library) {
          if (libs[block.props.definition.library]) {
            libs[block.props.definition.library].push(block);
          } else {
            libs.Uncategorized.push(block);
          }
        } else {
          libs.Uncategorized.push(block);
        }
      });
    setBlockLibraries(libs);
  }, [blocks, getBlockById]);

  const handleUploadSVG = async (name: string, data: string) => {
    const parsed = await parse(data);
    const block = svgToBlock(parsed);
    block.props.definition = { ...emptyDefinitionProp, name };
    createBlock(block);
  };

  const handleUploadJSON = async (name: string, data: string) => {
    try {
      const parsed: { data: { [k: string]: Block } } = JSON.parse(data);
      Object.keys(parsed.data).forEach((key) => {
        createOrUpdateBlock(parsed.data[key]);
      });
    } catch (e) {
      console.error("error uploading JSON", e);
    }
  };

  const handleKeyPress = React.useCallback(
    (event) => {
      if (event.key === "S" && event.shiftKey) {
        const data: { [k: string]: Block } = {};
        blocks.forEach((block) => (data[block.id] = block));
        const dataStr =
          "data:text/json;charset=utf-8," +
          encodeURIComponent(JSON.stringify({ data }, null, 2));
        const el = document.createElement("a");
        el.setAttribute("href", dataStr);
        el.setAttribute("download", "export.json");
        document.body.appendChild(el); // required for firefox
        el.click();
        el.remove();
      }
    },
    [blocks]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <>
      <div style={{ position: "absolute", top: 0, zIndex: 200 }}>
        <SideNavBar
          appIconAlt={"portfolioIcon"}
          appIconSrc={defaultValues.blockIconSrc}
          appIconClick={() => history.push(`/blocks`)}
        >
          <Nav navTab={"assets"} />
        </SideNavBar>
      </div>
      <TopNavBar
        ProjectTitle={"Assets"}
        UserAvatar={<UserAvatar />}
        subTitle={"LIBRARIES"}
        onShare={() => {}}
      />
      <div className={classes.container}>
        {libraries
          .sort((a: Library, b: Library) =>
            a.title > b.title ? 1 : a.title < b.title ? -1 : 0
          )
          .map(
            (library: Library) =>
              blockLibraries[library.id] &&
              blockLibraries[library.id].length > 0 && (
                <BlockTable
                  key={library.id}
                  blocks={
                    blockLibraries[library.id] ? blockLibraries[library.id] : []
                  }
                  library={library}
                />
              )
          )}
        {blockLibraries.Uncategorized?.length > 0 && (
          <BlockTable
            key="uncategorized"
            blocks={blockLibraries.Uncategorized}
            title={"Uncategorized"}
            libraryType={"asset"}
          />
        )}

        <div className={classes.fab}>
          <Upload
            id="import-asset"
            label="Import"
            color="default"
            onChange={handleUploadJSON}
            accept={".json"}
          />
          <Upload
            id="create-asset"
            label="Create Asset"
            color="primary"
            onChange={handleUploadSVG}
            accept={".svg"}
          />
        </div>
      </div>
    </>
  );
};

export default AssetList;
