import React from "react";
import Title from "components/title";
import { makeStyles } from "@material-ui/core/styles";

interface Props {
  onBlur: (mutation: { displayName: string }) => void;
  displayName: string;
  buildingID: string;
}

const useStyles = makeStyles({
  subtitleContainer: {
    color: "gray",
    fontFamily: "GoogleSansRegular",
    fontSize: " 14.5px",
    lineHeight: "16px",
    letterSpacing: "1.25px",
    paddingTop: "7px",
  },
});

export const StrategyTitle: React.FC<Props> = ({
  onBlur,
  displayName,
  buildingID,
}) => {
  const classes = useStyles();
  const handleBlur = (value: string) => {
    onBlur({ displayName: value });
  };

  return (
    <React.Fragment>
      <Title title={displayName || ""} onBlur={handleBlur} />
      <div className={classes.subtitleContainer}>{buildingID}</div>
    </React.Fragment>
  );
};

export default StrategyTitle;
