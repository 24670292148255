import React, { useState } from "react";
import { useParams } from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { RendererSettings } from "lib/types";
import {
  MMUserProfile,
  MMUserProfileMetadata,
  Team,
  useBlockInstanceCtrl,
  useRendererCtrl,
} from "lib/containers";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../components/sideBar/AccordionComponents";
import BlockLibrary from "../blocks/components/block-library";
import { toRegion } from "../lib/isp-canvas/utils";
import { BlockComponentsSettings } from "../blocks/components/block-components";
import { ProgramAreaContent } from "components/sideBar/ProgramAreaContent";
import { makeStyles } from "@material-ui/core";
import { emptyMetricsProp, getters } from "blocks/lib/constants";
import { mergeChildMetrics } from "blocks/lib/blocks";
import SidebarMatchMakerCard from "./sidebar-matchmaker-card";

const useStyles = makeStyles({
  text: {
    margin: "12px 16px",
    color: "#1B1D21", //Text/Dark
    fontSize: 14,
    display: "flex",
    justifyContent: "space-between",
  },
  accordianSummaryTitle: {
    fontSize: "16.57px",
    lineHeight: "28px",
    letterSpacing: "0.5px",
    color: "#1B1D21",
    fontFamily: "GoogleSansRegular",
  },
});

export interface Props {
  settings: RendererSettings | undefined;
}

const SidebarRegions: React.FC<Props> = ({ settings }) => {
  const classes = useStyles();
  const { buildingID, strategyID, floorID } = useParams<{
    [key: string]: string;
  }>();
  const [expanded, setExpanded] = useState("");
  const { wpiActivitiesMode, selectedRegion } = useRendererCtrl();
  const { updateSelectedBlock } = useBlockInstanceCtrl();

  const onChangeBlock = (id: string) => {
    const instance =
      selectedRegion !== undefined && settings && settings.blocks
        ? settings.blocks[selectedRegion[0]]
        : undefined;
    const region =
      selectedRegion !== undefined && instance
        ? toRegion(instance[0])
        : undefined;
    if (selectedRegion === undefined || !region) return;
    updateSelectedBlock({
      blockID: id,
      idx: selectedRegion[0],
      buildingID,
      floorID,
      strategyID,
    });
  };

  const handleExpand = (name: string) => {
    setExpanded(expanded === name ? "" : name);
  };

  if (selectedRegion === undefined || !settings) {
    return null;
  }

  const instances = selectedRegion.map(
    (regionIndex) => settings.blocks[regionIndex]
  );

  const baseMetrics = { ...emptyMetricsProp };
  let metrics = baseMetrics;
  selectedRegion.forEach((r) => {
    const block = settings.blocks ? settings.blocks[r] : undefined;
    if (block) {
      const mets = getters.getMetrics(block[0]);
      metrics = mergeChildMetrics([metrics, mets]);
    }
  });

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {!wpiActivitiesMode && (
        <div style={{ paddingTop: 10 }}>
          <ProgramAreaContent blocks={instances} />
          <div className={classes.text}>
            Total Seats <span>{metrics.seats}</span>
          </div>
          <div className={classes.text}>
            Total Workpoints <span>{metrics.workpoints}</span>
          </div>
        </div>
      )}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className={classes.accordianSummaryTitle}
        >
          MatchMaker Teams
        </AccordionSummary>
        <AccordionDetails>
          {selectedRegion?.length && settings?.blocks?.[0]?.length !== 0
            ? selectedRegion.map((selectedIdx) => {
                if (!settings?.blocks?.[selectedIdx]?.length) {
                  return null;
                }
                return settings?.blocks[selectedIdx].map((nestBlock) => {
                  return nestBlock.props.instance?.teamInfo ? (
                    <SidebarMatchMakerCard
                      team={nestBlock.props.instance.teamInfo.team as Team}
                      mmUserProfileMap={
                        nestBlock.props.instance.teamInfo
                          .matchmakerProfileMap as Record<
                          MMUserProfile,
                          MMUserProfileMetadata
                        >
                      }
                      editable={false}
                      defaultOpen={selectedRegion.length === 1 ? true : false}
                    />
                  ) : null;
                });
              })
            : null}
        </AccordionDetails>
      </Accordion>
      <Accordion
        defaultExpanded={false}
        expanded={expanded === "components"}
        onChange={() => handleExpand("components")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className={classes.accordianSummaryTitle}
        >
          Components
        </AccordionSummary>
        <AccordionDetails>
          <BlockComponentsSettings settings={settings} />
        </AccordionDetails>
      </Accordion>
      <Accordion
        key="library"
        defaultExpanded={selectedRegion !== undefined ? true : false}
        expanded={expanded === "library"}
        onChange={() => handleExpand("library")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className={classes.accordianSummaryTitle}
        >
          Block Library
        </AccordionSummary>
        <AccordionDetails>
          <BlockLibrary type="block" onClick={onChangeBlock} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default React.memo(SidebarRegions);
