import React, { useCallback, useEffect, useRef } from "react";
import {
  clearCanvas,
  renderShadow,
  renderBlockInstance,
  renderDimensions,
  renderBlockDimensions,
} from "../lib/canvas";
import { computeMetrics, getBlockInstances } from "../lib/blocks";
import { Block } from "../lib/types";
import { makeBlockInstance } from "../lib/instance";
import { useBlocksCtrl } from "../../lib/containers";
import { Point } from "@outerlabs/shapes-geometry";
import { mat4, quat, vec3 } from "gl-matrix";

interface Props {
  block: Block | undefined;
  size: Point;
  width: number;
  height: number;
}

const PIXEL_RATIO = (() => {
  const ctx = document.createElement("canvas").getContext("2d") as any;
  const dpr = window.devicePixelRatio || 1;
  const bsr =
    ctx.webkitBackingStorePixelRatio ||
    ctx.mozBackingStorePixelRatio ||
    ctx.msBackingStorePixelRatio ||
    ctx.oBackingStorePixelRatio ||
    ctx.backingStorePixelRatio ||
    1;
  return dpr / bsr;
})();

const createHiDPICanvas = function (
  can: HTMLCanvasElement,
  w: number,
  h: number,
  ratio?: number
) {
  if (!ratio) ratio = PIXEL_RATIO;
  can.width = w * ratio;
  can.height = h * ratio;
  can.style.width = w + "px";
  can.style.height = h + "px";
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  can.getContext("2d")!.setTransform(ratio, 0, 0, ratio, 0, 0);
  return can;
};

const CanvasView: React.FC<Props> = ({ block, size, width, height }) => {
  const { getBlockById } = useBlocksCtrl();
  const canvas = useRef<HTMLCanvasElement>(null);
  const previewRotation = 0;

  const mat = mat4.fromRotationTranslationScale(
    mat4.create(),
    quat.fromValues(0, 0, previewRotation, 1),
    vec3.fromValues(0, 0, 0),
    vec3.fromValues(1, 1, 1)
  );

  let instance: Block | undefined;
  if (block) {
    computeMetrics(block, getBlockById);
    instance = makeBlockInstance(block, mat, size, getBlockById);
    const blockMap: { [k: string]: Block } = {};
    const bi = getBlockInstances(instance);
    bi.map((_block) => (blockMap[_block.id] = _block));
  }
  const xform = mat4.fromRotationTranslationScale(
    mat4.create(),
    quat.fromValues(0, 0, previewRotation, 1),
    vec3.fromValues(width / 2 - size[0] / 2, height / 2 - size[1] / 2, 0),
    vec3.fromValues(1, 1, 1)
  );

  const render = useCallback(
    (_instance?: Block, s?: [number, number], m?: mat4) => {
      const mm = m ? m : xform;
      const ss = s ? s : size;
      const el = canvas.current;
      if (!el) return;
      const ctx = el.getContext("2d");
      if (!ctx) return;
      clearCanvas(ctx, width, height);
      ctx.fillStyle = "#F9FBFF";
      ctx.fillRect(0, 0, 10000, 10000);
      const x = width / 2 - ss[0] / 2;
      const y = height / 2 - ss[1] / 2;
      renderShadow(ctx, x, y, ss[0], ss[1]);
      // instance && renderBlockDimensions(ctx, instance, instance, xform, xform);
      const offset = 60;
      if (_instance) {
        renderBlockDimensions(ctx, _instance, _instance, xform, xform);
      } else {
        renderDimensions(ctx, [
          [x, y - offset],
          [x + ss[0], y - offset],
          [x - offset, y],
          [x - offset, y + ss[1]],
        ]);
      }
      if (_instance) {
        renderBlockInstance(ctx, _instance, mm, getBlockById, false, false);
      }
    },
    [getBlockById, height, size, width, xform]
  );

  render(instance, size, xform);

  useEffect(() => {
    const el = canvas.current;
    if (el) createHiDPICanvas(el, width, height);
  }, [width, height]);

  useEffect(() => {
    render(instance, size, xform);
  }, [instance, size, xform, render]);

  return (
    <canvas
      ref={canvas}
      width={width}
      height={height}
      tabIndex={999} // allows the canvas to get focus
    />
  );
};

export default CanvasView;
