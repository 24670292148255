import React, { ComponentType, FC } from "react";

import { ProjectsProvider, useProjectsCtrl } from "lib/containers";
import { Project } from "lib/types";

interface Injected {
  projects: { [key: string]: Project } | undefined;
  isLoading: boolean;
  createProject: () => Promise<string>;
}

function withProjects<Props>(
  Component: ComponentType<Props & Injected>
): FC<Props> {
  const Consumer: React.FC<Props> = (props) => {
    const { projects, createProject, isLoading } = useProjectsCtrl();
    return <Component {...{ ...props, projects, createProject, isLoading }} />;
  };

  return (props) => {
    return (
      <ProjectsProvider>
        <Consumer {...props} />
      </ProjectsProvider>
    );
  };
}

export default withProjects;
