import { Font } from "@outerlabs/ol-ui";
import { amenityHalftoneColors } from "blocks/lib/constants";
import React from "react";
import { Bar, BarChart, LabelList } from "recharts";

interface Props {
  projectAmenityData: any[];
  totalDensity: number;
}

const AmenityTypicalSubBarChart: React.FC<Props> = ({
  projectAmenityData,
  totalDensity,
}) => {
  const data = [
    {
      name: "Amenities, Typical Project",
      workplaceSupport: 14,
      food: 13,
      transportation: 2,
      healthAndPerformance: 1,
      extendedAmenities: 1,
      networkInfrastructure: 1,
      smallGap: 1,
      bigGap: 2,
    },
  ];

  const smallGapBar = (
    <Bar
      key={"smallGap"}
      dataKey={"smallGap"}
      stackId="a"
      fill={"#FFF"}
      fillOpacity={"0"}
      isAnimationActive={false}
    />
  );

  const bigGapBar = (
    <Bar
      key={"bigGap"}
      dataKey={"bigGap"}
      stackId="a"
      fill={"#FFF"}
      fillOpacity={"0"}
      isAnimationActive={false}
    />
  );

  const renderNonZero = (dataKey: string) => (props: any) => {
    const { x, y, width: barWidth, height: barHeight, value } = props;
    return value === 0 ? null : (
      <g>
        <text
          x={x + barWidth / 2 - 12}
          y={y + barHeight / 2}
          fill={projectAmenityData[0][dataKey] === 0 ? "#919499" : "#000"}
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize={12}
          fontFamily={Font.Medium.fontFamily}
          fontWeight={Font.Medium.fontWeight}
          letterSpacing={1}
        >
          {value.toFixed(0)}
        </text>
        <text
          x={x + barWidth / 2 + 12}
          y={y + barHeight / 2}
          fill={projectAmenityData[0][dataKey] === 0 ? "#919499" : "#000"}
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize={10}
          fontFamily={Font.Regular.fontFamily}
          fontWeight={Font.Regular.fontWeight}
          letterSpacing={1}
        >
          {`${+((value / totalDensity) * 100).toFixed(1)}%`}
        </text>
      </g>
    );
  };

  return data.length === 0 ? null : (
    <BarChart
      width={55}
      height={200}
      data={data}
      margin={{
        top: 40,
        bottom: 5,
        right: -18,
        left: -18,
      }}
      stackOffset="expand" //keeps the bar chart at a constant height
    >
      <Bar
        key={"networkInfrastructure"}
        dataKey={"networkInfrastructure"}
        stackId="a"
        fill={
          projectAmenityData[0].networkInfrastructure === 0
            ? amenityHalftoneColors.networkInfrastructureHalftone
            : "#C1B79C"
        }
        isAnimationActive={false}
      >
        <LabelList
          dataKey={"networkInfrastructure"}
          position="center"
          content={renderNonZero("networkInfrastructure")}
        />
      </Bar>
      {bigGapBar}
      <Bar
        key={"healthAndPerformance"}
        dataKey={"healthAndPerformance"}
        stackId="a"
        fill={
          projectAmenityData[0].healthAndPerformance === 0
            ? amenityHalftoneColors.healthAndPerformanceHalftone
            : "#FFCA2C"
        }
        isAnimationActive={false}
      >
        <LabelList
          dataKey={"healthAndPerformance"}
          position="center"
          content={renderNonZero("healthAndPerformance")}
        />
      </Bar>
      {bigGapBar}
      <Bar
        key={"extendedAmenities"}
        dataKey={"extendedAmenities"}
        stackId="a"
        fill={
          projectAmenityData[0].extendedAmenities === 0
            ? amenityHalftoneColors.extendedAmenitiesHalftone
            : "#B89130"
        }
        isAnimationActive={false}
      >
        <LabelList
          dataKey={"extendedAmenities"}
          position="center"
          content={renderNonZero("extendedAmenities")}
        />
      </Bar>
      {bigGapBar}
      <Bar
        key={"transportation"}
        dataKey={"transportation"}
        stackId="a"
        fill={
          projectAmenityData[0].transportation === 0
            ? amenityHalftoneColors.transportationHalftone
            : "#FEF3C7"
        }
        isAnimationActive={false}
      >
        <LabelList
          dataKey={"transportation"}
          position="center"
          content={renderNonZero("transportation")}
        />
      </Bar>
      {smallGapBar}
      <Bar
        key={"food"}
        dataKey={"food"}
        stackId="a"
        fill={
          projectAmenityData[0].food === 0
            ? amenityHalftoneColors.foodHalftone
            : "#FFAF38"
        }
        isAnimationActive={false}
      >
        <LabelList
          dataKey={"food"}
          position="center"
          content={renderNonZero("food")}
        />
      </Bar>
      {smallGapBar}
      <Bar
        key={"workplaceSupport"}
        dataKey={"workplaceSupport"}
        stackId="a"
        fill={
          projectAmenityData[0].workplaceSupport === 0
            ? amenityHalftoneColors.workplaceSupportHalftone
            : "#FFDCD4"
        }
        isAnimationActive={false}
      >
        <LabelList
          dataKey={"workplaceSupport"}
          position="center"
          content={renderNonZero("workplaceSupport")}
        />
      </Bar>
    </BarChart>
  );
};

export default AmenityTypicalSubBarChart;
