import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import { useBlocksCtrl } from "lib/containers";
import { Block } from "../lib/types";
import BlockItem from "./block-item";
import { makeBlockThumbnails } from "../lib/util";
import { findBlock } from "../lib/blocks";
import BlockCard from "./block-card";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
  },
  filters: {
    display: "flex",
  },
  cards: {
    flexGrow: 1,
  },
  items: {
    height: "100%",
    overflow: "auto",
    background: "#ffffff",
  },
}));

const BlockList = ({
  blocks,
  onClick,
  sort,
}: {
  blocks: Block[];
  onClick: (id: string) => void;
  sort?: string;
}) => {
  const cards = true;
  const { activeBlock, activeSubBlock, getBlockById } = useBlocksCtrl();
  const classes = useStyles();
  const [images, setImages] = React.useState<{ [k: string]: string }>({});
  let active: Block | undefined = undefined;
  if (activeSubBlock !== "" && activeBlock)
    active = findBlock(activeBlock, activeSubBlock);
  useEffect(() => {
    const renderBlocks = async () => {
      const _images = await makeBlockThumbnails(blocks, getBlockById);
      const imageMap: { [k: string]: string } = {};
      blocks.forEach((b, i) => (imageMap[b.id] = _images[i]));
      setImages(imageMap);
    };
    renderBlocks();
  }, [blocks, getBlockById]);
  const Item = cards ? BlockCard : BlockItem;

  return cards ? (
    <div className={classes.cards}>
      <Grid container={true} spacing={2}>
        {blocks.length > 0 &&
          blocks.map((block) => (
            <Grid xs={4} item={true}>
              <Item
                key={block.id}
                block={block}
                image={images[block.id]}
                onClick={() => onClick(block.id)}
                active={active && active.symbol === block.id}
                showSeats={sort === "seats"}
                showWorkpoints={sort === "workpoints"}
                showArea={sort === "small" || sort === "large"}
              />
            </Grid>
          ))}
      </Grid>
    </div>
  ) : (
    <div className={`${cards ? classes.cards : classes.items}`}>
      {blocks.length > 0 &&
        blocks.map((block) => (
          <Item
            key={block.id}
            block={block}
            image={images[block.id]}
            onClick={() => onClick(block.id)}
            active={active && active.symbol === block.id}
          />
        ))}
    </div>
  );
};

export default React.memo(BlockList);
