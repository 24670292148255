import { Tooltip, withStyles } from "@material-ui/core";

export default withStyles({
  tooltip: {
    fontSize: "12px",
    color: "white",
    backgroundColor: "rgb(114, 114, 114)",
    width: 150,
    wordWrap: "normal",
  },
})(Tooltip);
