import { makeStyles } from "@material-ui/core/styles";
import { Select as MaterialSelect, SelectProps } from "@material-ui/core";
import React from "react";

const useSelectStyles = makeStyles(() => ({
  root: {
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  input: {
    "& svg": {
      marginRight: 8,
    },
    background: "#F4F4F6",
    borderRadius: "4px",
    paddingLeft: "8px",
  },
}));

const Select = (p: SelectProps) => {
  const classes = useSelectStyles();
  return (
    <MaterialSelect
      inputProps={{ shrink: "true" }}
      disableUnderline={true}
      {...p}
      classes={{ root: classes.root }}
      className={`${classes.input} ${p.className}`}
    >
      {p.children}
    </MaterialSelect>
  );
};

export default Select;
