import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useBlockLibrariesCtrl, useBlocksCtrl } from "lib/containers";
import analytics from "../../lib/analytics";
import BlockTable from "../components/block-table";
import TopNavBar from "components/topNavBar/topNavBar";
import UserAvatar from "components/user-avatar";
import { useHistory } from "react-router-dom";
import { Block, Library } from "../lib/types";
import { Fab } from "@material-ui/core";
import { SideNavBar } from "@outerlabs/ol-ui";
import { defaultValues } from "../../lib/constants";
import Nav from "../components/nav";
import { Add } from "@material-ui/icons";
import { useGAPageView } from "../../lib/hooks/use-ga";

const useStyles = makeStyles(() => ({
  container: {
    width: "calc(100vw - 64px)",
    height: "100%",
    overflow: "auto",
    marginLeft: 64,
    paddingTop: 80,
    paddingBottom: 60,
  },
  actions: {
    height: 80,
    display: "flex",
    justifyContent: "end",
    padding: 24,
  },
  fab: {
    position: "absolute",
    bottom: 32,
    right: 32,
  },
}));

const BlockListContent: React.FC = () => {
  const classes = useStyles();
  const { libraries } = useBlockLibrariesCtrl();
  const { blocks, newBlock, getBlockById, activateBlock } = useBlocksCtrl();
  const [blockLibraries, setBlockLibraries] = React.useState<{
    [k: string]: Block[];
  }>({});
  useGAPageView("Block List");
  useEffect(() => activateBlock(undefined), [activateBlock]);
  useEffect(() => {
    const libs: { [k: string]: Block[] } = { Uncategorized: [] };
    libraries.map((lib: Library) => (libs[lib.id] = []));
    blocks
      .filter((b) => b.role === "block")
      .forEach((block) => {
        if (block.props.definition && block.props.definition.library) {
          if (libs[block.props.definition.library]) {
            libs[block.props.definition.library].push(block);
          } else {
            libs.Uncategorized.push(block);
          }
        } else {
          libs.Uncategorized.push(block);
        }
      });
    setBlockLibraries(libs);
  }, [libraries, blocks, getBlockById]);
  if (Object.keys(blockLibraries).length === 0) return null;
  return (
    <div className={classes.container}>
      {libraries
        .sort((a: Library, b: Library) =>
          a.title > b.title ? 1 : a.title < b.title ? -1 : 0
        )
        .map(
          (library: Library) =>
            blockLibraries[library.id] &&
            blockLibraries[library.id].length > 0 && (
              <BlockTable
                key={library.id}
                blocks={
                  blockLibraries[library.id] ? blockLibraries[library.id] : []
                }
                library={library}
              />
            )
        )}
      {blockLibraries.Uncategorized?.length > 0 && (
        <BlockTable
          key="uncategorized"
          blocks={blockLibraries.Uncategorized}
          title={"Uncategorized"}
          libraryType={"block"}
        />
      )}
      <Fab
        variant="extended"
        color="primary"
        onClick={() => newBlock()}
        className={classes.fab}
      >
        <Add />
        <div style={{ marginLeft: 8 }}>Create Block</div>
      </Fab>
    </div>
  );
};

const BlockList: React.FC = () => {
  const history = useHistory();
  useEffect(() => analytics.blockMakerOpened(), []);
  return (
    <>
      <div style={{ position: "absolute", top: 0, zIndex: 200 }}>
        <SideNavBar
          appIconAlt={"portfolioIcon"}
          appIconSrc={defaultValues.blockIconSrc}
          appIconClick={() => history.push(`/blocks`)}
        >
          <Nav navTab={"blocks"} />
        </SideNavBar>
      </div>
      <TopNavBar
        ProjectTitle={"Blocks"}
        UserAvatar={<UserAvatar />}
        subTitle={"LIBRARIES"}
        onShare={() => {}}
      />
      <BlockListContent />
    </>
  );
};

export default BlockList;
