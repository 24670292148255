import { polygonizeEllipse } from "./utils";
import { polyline } from "./polyline";

export type Ellipse = {
  type: "ellipse";
  x: number;
  y: number;
  rx: number;
  ry: number;
};

export const ellipse = (
  { x, y, rx, ry }: Ellipse,
  layerName: string
): string => {
  return polyline(
    {
      type: "polyline",
      points: polygonizeEllipse(x, y, rx, ry, 40),
    },
    layerName
  );
};
