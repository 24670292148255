import { Block } from "blocks/lib/types";

export const getDesks = (blocks: Block[][]) => {
  let desks = 0;

  blocks.forEach((arr) =>
    arr.forEach((b) => {
      const key = Object.keys(b.props.metrics?.names || {}).filter((name) =>
        name.match(/desk/i)
      )?.[0];
      if (key) {
        desks += b.props?.metrics?.names?.[key] || 0;
      }
    })
  );
  return desks;
};
