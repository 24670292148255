import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    marginTop: "12px",
    marginBottom: "12px",
  },
  after: {
    width: 0,
    borderRight: "1px solid rgba(0,0,0,0.2)",
  },
  group: {
    height: "100%",
    display: "flex",
    flexFlow: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    alignContent: "center",
  },
});

const Group: React.FC = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.group}>{children}</div>
    </div>
  );
};

export default Group;
