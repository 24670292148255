import { createContainer } from "unstated-next";
import { useEffect, useState } from "react";
import axios from "axios";
import { getAuthHeader } from "lib/auth";
import config from "config";
import { Library } from "blocks/lib/types";
import { v4 as uuid } from "uuid";

export interface UseBlockLibrariesType {
  libraries: Library[];
  createLibrary: (title: string) => Promise<Library>;
  saveLibrary: (data: Library) => Promise<Library>;
  deleteLibrary: (id: string) => Promise<void>;
  publishLibrary: (id: string) => Promise<Library>;
  unpublishLibrary: (id: string) => Promise<Library>;
  approveLibrary: (id: string) => Promise<Library>;
}

const useBlockLibrariesContainer = (): UseBlockLibrariesType => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [libraries, setBlockLibraries] = useState<Library[]>([]);

  useEffect(() => {
    const getBlockLibraries = async (): Promise<void> => {
      const headers = await getAuthHeader();
      const { data } = await axios.get(config.baseUrl + `/api/libraries/`, {
        headers,
      });

      setBlockLibraries(data as Library[]);
      setLoaded(true);
    };
    if (!loaded) getBlockLibraries();
  }, [libraries, loaded]);

  const createLibrary = async (title: string): Promise<Library> => {
    const headers = await getAuthHeader();
    try {
      const data = {
        id: uuid(),
        title,
        state: "unpublished",
      } as Partial<Library>;
      const res = await axios.post(config.baseUrl + `/api/libraries/`, data, {
        headers,
      });
      setBlockLibraries([...libraries, res.data]);
      return res.data;
    } catch (e) {
      console.error(e);
      return Promise.reject();
    }
  };

  const saveLibrary = async (t: Library): Promise<Library> => {
    const headers = await getAuthHeader();
    try {
      const res = await axios.put(
        config.baseUrl + `/api/libraries/${t.id}/`,
        t,
        { headers }
      );
      const idx = libraries.findIndex((lib) => lib.id === t.id);
      const updated = [...libraries];
      updated.splice(idx, 1, t);
      setBlockLibraries(updated);
      return res.data;
    } catch (e) {
      console.error(e);
      return Promise.reject();
    }
  };

  const deleteLibrary = async (id: string): Promise<void> => {
    const headers = await getAuthHeader();
    try {
      const idx = libraries.findIndex((lib) => lib.id === id);
      const updated = [...libraries];
      updated.splice(idx, 1);
      setBlockLibraries(updated);
      await axios.delete(config.baseUrl + `/api/libraries/${id}/`, {
        headers,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const publishLibrary = async (id: string): Promise<Library> => {
    const lib = libraries.find((l) => l.id === id);
    if (lib) return saveLibrary({ ...lib, state: "published" });
    else return Promise.reject();
  };

  const unpublishLibrary = async (id: string): Promise<Library> => {
    const lib = libraries.find((l) => l.id === id);
    if (lib) return saveLibrary({ ...lib, state: "unpublished" });
    else return Promise.reject();
  };

  const approveLibrary = async (id: string): Promise<Library> => {
    const lib = libraries.find((l) => l.id === id);
    if (lib) return saveLibrary({ ...lib, state: "approved" });
    else return Promise.reject();
  };

  return {
    libraries,
    createLibrary,
    saveLibrary,
    deleteLibrary,
    publishLibrary,
    unpublishLibrary,
    approveLibrary,
  };
};

export const BlockLibrariesController = createContainer(
  useBlockLibrariesContainer
);
export const BlockLibrariesProvider = BlockLibrariesController.Provider;
export const useBlockLibrariesCtrl = () =>
  BlockLibrariesController.useContainer();
