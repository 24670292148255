import { Metric } from "lib/types";
import { roomTypes } from "./constants";

export const metricDisable = (disable: string[], metric: Metric) => {
  const update = { capacity: 0, count: 0 };
  disable?.forEach((key: string) => {
    if (roomTypes.includes(key)) {
      const metricKey = key as keyof Metric;
      const capacity =
        metric[metricKey].capacity !== undefined
          ? metric[metricKey].capacity
          : metric[metricKey];
      update.capacity -= capacity;
      update.count +=
        metric[metricKey].count !== undefined
          ? metric[metricKey].count
          : metric[metricKey];
    }
  });
  return update;
};
