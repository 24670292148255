import {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
  useCallback,
} from "react";
import { createContainer } from "unstated-next";
import { Project as ProjectType } from "../types";
import axios from "axios";
import { getAuthHeader } from "lib/auth";
import config from "config";
import { useHistory } from "react-router-dom";

const emptyProject: ProjectType = {
  primaryStrategies: {},
  metrics: {},
  buildings: {},
  buildingIds: [],
  name: "Untitled Study",
  dateLastOpened: new Date(),
  id: "",
  users: [],
};

export interface ProjectsStore {
  projects: { [key: string]: ProjectType } | undefined;
  isLoading: boolean;
}

export interface UseProjectsType extends ProjectsStore {
  createProject(): Promise<string>;
  deleteProject(id: string): void;
  setStore: Dispatch<SetStateAction<ProjectsStore>>;
}

function useProjects(): UseProjectsType {
  const [store, setStore] = useState<ProjectsStore>({
    projects: undefined,
    isLoading: false,
  });
  const history = useHistory();

  useEffect(() => {
    const fetchProjects = async (): Promise<void> => {
      try {
        setStore({ ...store, isLoading: true });
        const headers = await getAuthHeader();
        const { data } = await axios.get(
          config.baseUrl + "/api/planning/projects",
          {
            headers,
          }
        );
        const projects: { [key: string]: ProjectType } = {};
        data.forEach((d: ProjectType) => {
          projects[d.id] = d;
        });
        setStore({ ...store, projects, isLoading: false });
      } catch (error: any) {
        setStore({ ...store, isLoading: false });
        if (error && error.response && error.response.status === 401) {
          history.replace("/login");
        }
        console.error("Error: ", error);
      }
    };
    if (!store.isLoading && store.projects === undefined) fetchProjects();
  }, [history, store]);

  const createProject = useCallback(async () => {
    try {
      const headers = await getAuthHeader();
      const { data } = await axios.post(
        config.baseUrl + "/api/planning/projects",
        emptyProject,
        {
          headers,
        }
      );
      setStore({
        ...store,
        projects: {
          ...store.projects,
          [data.id]: data,
        },
      });
      return data.id;
    } catch (error: any) {
      console.error("Error: ", error);
      return undefined;
    }
  }, [store]);

  const deleteProject = useCallback(
    async (id: string) => {
      if (store.projects && store.projects[id]) {
        try {
          const project = { ...store.projects[id], deleted: true };
          const headers = await getAuthHeader();
          await axios.put(
            config.baseUrl + `/api/planning/projects/${project.id}`,
            project,
            {
              headers,
            }
          );
          setStore({
            ...store,
            projects: {
              ...store.projects,
              [project.id]: project,
            },
          });
        } catch (error: any) {
          console.error("Error: ", error);
        }
      }
    },
    [store]
  );

  return {
    ...store,
    deleteProject,
    createProject,
    setStore: setStore,
  };
}

const ProjectsController = createContainer<UseProjectsType>(useProjects);

export const ProjectsProvider = ProjectsController.Provider;

export const useProjectsCtrl = () => ProjectsController.useContainer();
