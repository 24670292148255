import {
  colors,
  dividerWidths,
  emptyMetricsProp,
  program,
  getters,
} from "./constants";
import {
  Block,
  BlockGetter,
  ShapeProps,
  Counts,
  Range,
  TypeMetric,
  MetricsProps,
  BlockResult,
} from "./types";
import { cloneBlock, defaultByType, minMaxByType } from "./util";
import { getBoundingBox, boxToSize } from "@outerlabs/shapes-geometry";
import { mat4, quat, vec3 } from "gl-matrix";
import { v4 as uuid } from "uuid";
import { calculateBlockSeats } from "./metrics";
import { ExtProps } from "@outerlabs/shapes-core";
import { lightenHex } from "../../lib/color";
import { makeBlockInstance } from "./instance";
const EPSILON = 0.0001;

// Given an array of blocks and a rectangle width and height, returns an array of ids of blocks that fit
export const fitBlocksToRectangle = (
  width: number,
  height: number,
  blocks: Block[]
): string[] => {
  const fits: string[] = [];
  blocks.forEach((t) => {
    if (checkBlockRectangle(width, height, t)) fits.push(t.id);
  });
  return fits;
};

// Checks if a block can fit in a rectangle of width and height, returns true or false
export const checkBlockRectangle2 = (
  width: number,
  height: number,
  block?: Block,
  tolerance: number[] = [0, 0]
): boolean => {
  if (!block) return true;
  const [[wMin, wMax], [hMin, hMax]] = getBlockMinMaxDimensions(block);
  // TODO(JV): canTile
  // if (block.canTile && width >= wMin && height >= hMin) return true;
  if (!tolerance) tolerance = [0, 0];

  if (
    wMin - tolerance[0] <= width &&
    width <= wMax + tolerance[0] &&
    hMin - tolerance[1] <= height &&
    height <= hMax + tolerance[1]
  ) {
    return true;
  } else if (
    wMin - tolerance[1] <= height &&
    height <= wMax + tolerance[1] &&
    hMin - tolerance[0] <= width &&
    width <= hMax + tolerance[0]
  ) {
    return true;
  }
  return false;
};

export const checkBlockRectangle = (
  width: number,
  height: number,
  block?: Block,
  tolerance: number[] = [0, 0]
): BlockResult | false => {
  if (!block) return false;
  const [[wMin, wMax], [hMin, hMax]] = getBlockMinMaxDimensions(block);
  if (!tolerance || (tolerance[0] === 0 && tolerance[1] === 0))
    tolerance = [EPSILON, EPSILON];
  if (wMin <= width && hMin <= height) {
    if (width <= wMax && height <= hMax) {
      return { id: block.id, score: 1, rotated: false };
    } else if (width <= wMax + tolerance[0]) {
      return { id: block.id, score: hMax / height, rotated: false };
    } else if (height <= hMax + tolerance[1]) {
      return { id: block.id, score: wMax / width, rotated: false };
    } else {
      return {
        id: block.id,
        score:
          ((hMax + tolerance[1]) * (wMax + tolerance[0])) / (width * height),
        rotated: false,
      };
    }
  }
  return false;
};

// returns the minimum and maximum dimensions of a block
export const getBlockMinMaxDimensions = (t: Block): number[][] => {
  const { sizeRange } = getters.getMetrics(t);
  const horizontalBoundary = sizeRange
    ? [sizeRange[0][0] - EPSILON, sizeRange[0][1] + EPSILON]
    : [0, 0];
  const verticalBoundary = sizeRange
    ? [sizeRange[1][0] - EPSILON, sizeRange[1][1] + EPSILON]
    : [0, 0];
  return [horizontalBoundary, verticalBoundary];
};

// Rengenerate min/max width/height group sizes, used whenever the group is resized
export const updateChildMinMax = (
  block: Block,
  index: number,
  hc: number[],
  getBlockById: BlockGetter
): Block => {
  const child = block.children[index];
  const layout = getters.getLayout(block);
  if (layout.mode === "flex" && layout.flex && layout.flex.repeat) {
    layout.flex.repeat = [hc[0], hc[1]];
    block.children[index] = {
      ...child,
      props: {
        ...child.props,
        layout: { ...layout, flex: { ...layout.flex } },
      },
    };
  }
  block = computeMetrics(block, getBlockById);
  return block;
};

// Rotate a group's asset
export const rotateChildAsset = (
  children: Block[],
  index: number,
  amount = 90
): Block[] => {
  const layout = getters.getLayout(children[index]);
  if (layout.mode === "flex" && layout.flex && layout.flex.repeat) {
    layout.rotation = ((layout.rotation || 0) + amount + 360) % 360;
    children[index].props.layout = layout;
  }
  return children;
};

// collects and compiles all metrices in a block
export const collectMetrics = (
  block: Block,
  getBlockById: BlockGetter
): Block => {
  if (block.role === "asset") return block;
  if (block.children && block.children.length > 0) {
    const metrics = getters.getMetrics(block);
    let baseMetrics = { ...emptyMetricsProp };
    block.children.forEach((child) => {
      collectMetrics(child, getBlockById);
      const childMetrics = getters.getMetrics(child);
      child.props.metrics = childMetrics;
      baseMetrics = mergeMetrics(baseMetrics, childMetrics);
    });
    block.props.metrics = {
      ...baseMetrics,
      size: metrics.size,
      sizeRange: metrics.sizeRange,
      costUnit: metrics.costUnit,
      costGSF: metrics.costGSF,
      costHC: metrics.costHC,
      totalCost: metrics.totalCost,
    };
  } else {
    let ref = block;
    const asset = getBlockById(block.symbol);
    if (asset) {
      ref = asset;
    }
    const { type } = getters.getDefinition(ref);
    const metrics = getters.getMetrics(ref);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    block.props.metrics!.types![type]!.areaRange = metrics.areaRange;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    block.props.metrics!.types![type]!.seatsRange = metrics.seatsRange;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    block.props.metrics!.types![type]!.costUnit = metrics.costUnit;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    block.props.metrics!.types![type]!.costGSF = metrics.costGSF;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    block.props.metrics!.types![type]!.costHC = metrics.costHC;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    block.props.metrics!.types![type]!.doors = metrics.doors;
  }
  return block;
};

export const computeSeats = (
  block: Block,
  getBlockById: BlockGetter
): Block => {
  const parentMetrics = getters.getMetrics(block);
  const parentLayout = getters.getLayout(block);
  // for each branch and child, get largest size that fits within smallest parent size
  if (block.children && block.children.length > 0) {
    block.children.forEach((child) => {
      const childMetrics = getters.getMetrics(child);
      const childLayout = getters.getLayout(child);
      const symbol = getBlockById(child.symbol);
      const size: Range = [
        childMetrics.sizeRange[0][0],
        childMetrics.sizeRange[1][0],
      ];
      child.props.metrics = childMetrics;
      if (child.role !== "block") {
        const flex = childLayout.flex;
        if (flex) {
          const repeat = flex && flex.repeat ? flex.repeat : [1, 1];
          const direction =
            flex && flex.mode === "horizontal" ? "horizontal" : "vertical";
          const margin = childLayout.padding
            ? childLayout.padding
            : [0, 0, 0, 0];
          const spacing = flex && flex.spacing ? flex.spacing : 0;
          const rep =
            direction === "horizontal"
              ? { max: [repeat[1], 1], min: [repeat[0], 1] }
              : { max: [1, repeat[1]], min: [1, repeat[0]] };
          const rotation = childLayout.rotation || 0;
          const { horizontalSeats, verticalSeats } = calculateBlockSeats(
            child,
            rotation,
            rep,
            margin,
            spacing,
            size[0],
            size[1]
          );
          flex.repeat =
            flex.mode === "horizontal"
              ? [horizontalSeats, repeat[1]]
              : [verticalSeats, repeat[1]];
          childLayout.flex = flex;
          childMetrics.seatsRange = [
            flex.mode === "horizontal" ? horizontalSeats : verticalSeats,
            childMetrics.seatsRange[1],
          ];
          if (symbol) {
            const symbolMetrics = getters.getMetrics(symbol);
            childMetrics.headcountRange = [
              childMetrics.seatsRange[0] * symbolMetrics.headcount,
              childMetrics.seatsRange[1] * symbolMetrics.headcount,
            ];
            childMetrics.headcount = childMetrics.headcountRange[0];
            childMetrics.workpointsRange = [
              childMetrics.seatsRange[0] * symbolMetrics.workpoints,
              childMetrics.seatsRange[1] * symbolMetrics.workpoints,
            ];
            childMetrics.workpoints = childMetrics.workpointsRange[0];
          }

          const parentDirection = parentLayout.flex?.mode || "horizontal";
          if (parentDirection === "horizontal") {
            childMetrics.sizeRange[1][0] = parentMetrics.sizeRange[1][0];
            childMetrics.sizeRange[1][1] = parentMetrics.sizeRange[1][1];
          } else {
            childMetrics.sizeRange[0][0] = parentMetrics.sizeRange[0][0];
            childMetrics.sizeRange[0][1] = parentMetrics.sizeRange[0][1];
          }
        }
      } else {
        // let flex = childLayout.flex;
        // let direction = flex && flex.mode === "horizontal" ? "horizontal" : "vertical";
        // if (flex) {
        //   if (direction === "horizontal") {
        //     // size = [parentMetrics.sizeRange[0][0], childMetrics.sizeRange[1][0]];
        //     childMetrics.sizeRange[0][0] = parentMetrics.sizeRange[0][0];
        //     childMetrics.sizeRange[0][1] = parentMetrics.sizeRange[0][1];
        //   } else {
        //     childMetrics.sizeRange[1][0] = parentMetrics.sizeRange[1][0];
        //     childMetrics.sizeRange[1][1] = parentMetrics.sizeRange[1][1];
        //     // size = [childMetrics.sizeRange[0][0], parentMetrics.sizeRange[1][0]];
        //   }
        // }
      }
      // childMetrics.sizeRange[0][0] = size[0];
      // childMetrics.sizeRange[1][0] = size[1];
      childMetrics.areaRange[0] =
        childMetrics.sizeRange[0][0] * childMetrics.sizeRange[1][0];
      childMetrics.areaRange[1] =
        childMetrics.sizeRange[0][1] * childMetrics.sizeRange[1][1];
      childMetrics.area = childMetrics.areaRange[0];
      // let offset: number[] = r > 0 ? layout.offset.slice(r).concat(layout.offset.slice(0, r)) : layout.offset;
      // const uOffset = offset[1] + offset[3];
      // const vOffset = offset[2] + offset[0];
      // let uSize = assetMetrics.size[0] + uOffset;
      // let vSize = assetMetrics.size[1] + vOffset;
      // if (direction === "horizontal") {
      //   // Get number of assets in x and y
      //   xRes = w - horizontalSeats * uSize - (horizontalSeats - 1) * spacing - margin[1] - margin[3];
      //   yRes = h - vSize - margin[0] - margin[2];
      // } else {
      //
      // }

      computeSeats(child, getBlockById);
    });
  }
  return block;
};

export const computeMetrics = (
  block: Block,
  getBlockById: BlockGetter
): Block => {
  const overrides: ExtProps<ShapeProps> = Object.assign({}, block.props);
  // df compute size ranges
  let b = computeSizes(block, getBlockById);
  // bf recompute min values (size, seats, areas)
  b = computeSeats(b, getBlockById);
  // df reduce/collect
  b = collectMetrics(b, getBlockById);
  // apply any overrides at the block level,
  // as opposed to aggregated from the assets  within the block
  b = applyOverrides(b, overrides);
  b = calcInstanceData(b, getBlockById);
  return b;
};

// applies overrides to blocks where data is lacking
export const applyOverrides = (
  block: Block,
  overrides: ExtProps<ShapeProps>
): Block => {
  const { metrics } = overrides;
  const { area, headcount, headcountRange, sizeRange, types } =
    getters.getMetrics(block);
  // apply block overrides
  if (metrics && block) {
    let assetCount = 0;
    Object.values(types || {}).forEach((type) => {
      Object.values(type.names || {}).forEach((count) => {
        assetCount += count;
      });
    });
    // override cost
    const costUnit = metrics.costUnit || 0;
    const costGSF = metrics.costGSF || 0;
    // LM-TODO - Improve handling for totalCost and totalCostRange
    const totalCost =
      costGSF !== 0 ? costGSF * (area / 144) : costUnit * assetCount;
    const costHC = totalCost / (headcount || 1); // avoid divide by 0
    const totalCostRange = [
      costGSF * ((sizeRange[0][0] * sizeRange[1][0]) / 144),
      costGSF * ((sizeRange[0][1] * sizeRange[1][1]) / 144),
    ];
    const costHCRange = [
      totalCostRange[0] / headcountRange[0],
      totalCostRange[1] / headcountRange[1],
    ];

    Object.assign(block.props.metrics as any, {
      costUnit,
      costGSF,
      costHC,
      totalCost,
      costHCRange,
      totalCostRange,
    });
  }
  return block;
};

// these are calcs that require instancing the block to generate min and max asset
// counts
const calcInstanceData = (block: Block, getBlockById: BlockGetter) => {
  if (
    block &&
    block.props &&
    block.props.metrics &&
    block.props.metrics.sizeRange
  ) {
    const mat = mat4.fromRotationTranslationScale(
      mat4.create(),
      quat.fromValues(0, 0, 0, 1),
      vec3.fromValues(0, 0, 0),
      vec3.fromValues(1, 1, 1)
    );
    // sizeRange[0] is range of size in x direction
    // sizeRange[1] is range of size in y direction
    const min: [number, number] = [
      block.props.metrics.sizeRange[0]?.[0] || 0,
      block.props.metrics.sizeRange[1]?.[0] || 0,
    ];
    const max: [number, number] = [
      block.props.metrics.sizeRange[0]?.[1] || 0,
      block.props.metrics.sizeRange[1]?.[1] || 0,
    ];

    const small = makeBlockInstance(block, mat, min, getBlockById);
    const large = makeBlockInstance(block, mat, max, getBlockById);
    block.props.metrics.doorsRange = [
      small.props.metrics?.doors,
      large.props.metrics?.doors,
    ];
    // handling for when a block is costed per unit as opposed to GSF
    if (
      small &&
      small.props &&
      small.props.metrics &&
      small.props.metrics.names &&
      large &&
      large.props &&
      large.props.metrics &&
      large.props.metrics.names &&
      block.props.metrics.costUnit !== 0
    ) {
      let smallAssetCount = 0;
      let largeAssetCount = 0;
      const unitCost = block.props.metrics.costUnit || 0;
      Object.values(small.props.metrics.names || {}).forEach((count) => {
        smallAssetCount += count || 0;
      });
      Object.values(large.props.metrics.names || {}).forEach((count) => {
        largeAssetCount += count || 0;
      });
      block.props.metrics.totalCostRange = [
        smallAssetCount * unitCost,
        largeAssetCount * unitCost,
      ];
      if (
        block.props.metrics.headcountRange?.[0] !== 0 &&
        block.props.metrics.headcountRange?.[1] !== 0
      ) {
        block.props.metrics.costHCRange = [
          (block.props.metrics.totalCostRange[0] || 0) /
            (block.props.metrics.headcountRange?.[0] || 1),
          (block.props.metrics.totalCostRange[1] || 0) /
            (block.props.metrics.headcountRange?.[1] || 1),
        ];
      } else block.props.metrics.costHCRange = [0, 0];
    }
  }
  return block;
};

// Generate min/max width/height size ranges for all groups in a block
export const computeSizes = (
  block: Block,
  getBlockById: BlockGetter
): Block => {
  const layout = getters.getLayout(block);
  const sides = getters.getSides(block);
  const dividers = sides.dividers;
  let metrics = getters.getMetrics(block);
  if (block.children && block.children.length > 0) {
    let range: [[number, number], [number, number]] = [
      [0, 0],
      [0, 0],
    ];
    const spacing = layout.flex?.spacing || 0;
    let seatsRange: [number, number] = [metrics.seats, metrics.seats];

    if (layout.mode !== "flex") {
      range = [
        [metrics.size[0], metrics.size[0]],
        [metrics.size[1], metrics.size[1]],
      ];
      // compute child metrics, area range per program type
      block.children.forEach((child) => computeSizes(child, getBlockById));
    } else {
      seatsRange = layout.flex?.repeat
        ? [layout.flex.repeat[0], layout.flex.repeat[1]]
        : seatsRange;
      let baseMetrics = { ...emptyMetricsProp };
      block.children.forEach((child, i) => {
        computeSizes(child, getBlockById);
        const childMetrics = getters.getMetrics(child);
        const { sizeRange: childSizeRange, seatsRange: childSeatsRange } =
          childMetrics;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        seatsRange[0] += childSeatsRange[0]!;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        seatsRange[1] += childSeatsRange[1]!;
        if (layout.mode === "flex" && childSizeRange) {
          if (layout.flex?.mode === "vertical") {
            // TODO(JV): not accounting for object's rotation
            range[0][0] = Math.max(childSizeRange[0][0], range[0][0]);
            range[0][1] = Math.max(childSizeRange[0][1], range[0][1]);
            range[1][0] += childSizeRange[1][0] + (i === 0 ? 0 : spacing);
            range[1][1] += childSizeRange[1][1] + (i === 0 ? 0 : spacing);
          } else {
            range[0][0] += childSizeRange[0][0] + (i === 0 ? 0 : spacing);
            range[0][1] += childSizeRange[0][1] + (i === 0 ? 0 : spacing);
            range[1][0] = Math.max(childSizeRange[1][0], range[1][0]);
            range[1][1] = Math.max(childSizeRange[1][1], range[1][1]);
          }
        }
        baseMetrics = mergeMetrics(baseMetrics, childMetrics);
      });
      metrics = {
        ...baseMetrics,
        size: metrics.size,
        sizeRange: metrics.sizeRange,
        costUnit: metrics.costUnit,
        costGSF: metrics.costGSF,
        costHC: metrics.costHC,
        totalCost: metrics.totalCost,
        costHCRange: metrics.costHCRange,
        totalCostRange: metrics.totalCostRange,
      };
      const padding = layout.padding || [0, 0, 0, 0];
      range[0][0] += padding[1] + padding[3];
      range[0][1] += padding[1] + padding[3];
      range[1][0] += padding[0] + padding[2];
      range[1][1] += padding[0] + padding[2];
      // Add any walls or partitions to the overall size
      if (dividers) {
        range[0][0] +=
          (dividerWidths[dividers[1].type] || 0) +
          (dividerWidths[dividers[3].type] || 0);
        range[0][1] +=
          (dividerWidths[dividers[1].type] || 0) +
          (dividerWidths[dividers[3].type] || 0);
        range[1][0] +=
          (dividerWidths[dividers[0].type] || 0) +
          (dividerWidths[dividers[2].type] || 0);
        range[1][1] +=
          (dividerWidths[dividers[0].type] || 0) +
          (dividerWidths[dividers[2].type] || 0);
      }
    }
    block.props.metrics = { ...metrics, sizeRange: range };
    return block;
  }
  let sizeRange: [Range, Range];
  let ref = block;
  const asset = getBlockById(block.symbol);
  let size: [number, number] = [0, 0];
  if (asset) {
    ref = asset;
    const [minWidth, maxWidth, minHeight, maxHeight] = minMaxByType(
      block,
      asset
    );
    sizeRange = [
      [minWidth, maxWidth],
      [minHeight, maxHeight],
    ];
  } else {
    size = boxToSize(getBoundingBox(block.geometry));
    sizeRange = [
      [size[0], size[0]],
      [size[1], size[1]],
    ];
  }
  const { seats, headcount, workpoints, doors } = getters.getMetrics(ref);
  const { name, type, tags } = getters.getDefinition(ref);
  if (dividers) {
    sizeRange[0][0] +=
      (dividerWidths[dividers[1].type] || 0) +
      (dividerWidths[dividers[3].type] || 0);
    sizeRange[0][1] +=
      (dividerWidths[dividers[1].type] || 0) +
      (dividerWidths[dividers[3].type] || 0);
    sizeRange[1][0] +=
      (dividerWidths[dividers[0].type] || 0) +
      (dividerWidths[dividers[2].type] || 0);
    sizeRange[1][1] +=
      (dividerWidths[dividers[0].type] || 0) +
      (dividerWidths[dividers[2].type] || 0);
  }
  let seatsRange: [number, number] = [seats, seats];
  let workpointsRange: [number, number] = [0, 0];
  let headcountRange: [number, number] = [0, 0];
  const minArea = sizeRange[0][0] * sizeRange[1][0];
  const maxArea = sizeRange[0][1] * sizeRange[1][1];
  const areaRange: Range = [minArea, maxArea];
  const tagMap: Counts = {};
  tags.forEach((tag) => (tagMap[tag] = 1));
  let repeat = 1;
  if (layout.flex?.repeat) {
    repeat = layout.flex.repeat[0];
    seatsRange = [seats * layout.flex.repeat[0], seats * layout.flex.repeat[1]];
    headcountRange = [
      headcount * layout.flex.repeat[0],
      headcount * layout.flex.repeat[1],
    ];
    workpointsRange = [
      workpoints * layout.flex.repeat[0],
      workpoints * layout.flex.repeat[1],
    ];
  }

  block.props.metrics = {
    area: minArea,
    areaRange,
    seatsRange,
    headcountRange,
    workpointsRange,
    headcount: headcountRange[0],
    workpoints: workpointsRange[0],
    seats: seatsRange[0],
    tags: tagMap,
    names: { [name]: repeat },
    types: {
      [type]: {
        area: minArea,
        areaRange,
        seats: seatsRange[0],
        headcountRange,
        workpointsRange,
        headcount: headcountRange[0],
        workpoints: workpointsRange[0],
        seatsRange,
        tags: tagMap,
        names: { [name]: repeat },
      },
    },
    sizeRange,
    size,
    costHCRange: metrics.costHCRange,
    costHC: metrics.costHCRange[0] * headcountRange[0],
    costUnit: metrics.costUnit,
    costGSF: metrics.costGSF,
    totalCost: metrics.totalCostRange[0] * minArea,
    totalCostRange: metrics.totalCostRange,
    doors: doors,
  };
  return block;
};

export const mergeChildMetrics = (metrics: MetricsProps[]): MetricsProps => {
  const acc = metrics.reduce(mergeMetrics, { ...emptyMetricsProp });
  acc.costUnit /= metrics.length;
  acc.costGSF /= metrics.length;
  acc.costHC /= metrics.length;
  acc.costHCRange[0] /= metrics.length;
  acc.costHCRange[1] /= metrics.length;
  return acc;
};

// merge metrics from typeMetrics
export const mergeTypeMetrics = (a: TypeMetric, b: TypeMetric): TypeMetric => {
  const tags: Counts = {};
  Object.keys(a.tags || {}).forEach(
    (k) => (tags[k] = tags[k] ? tags[k] + a.tags[k] : a.tags[k])
  );
  Object.keys(b.tags || {}).forEach(
    (k) => (tags[k] = tags[k] ? tags[k] + b.tags[k] : b.tags[k])
  );
  const names: Counts = {};
  Object.keys(a.names || {}).forEach(
    (k) => (names[k] = names[k] ? names[k] + a.names[k] : a.names[k])
  );
  Object.keys(b.names || {}).forEach(
    (k) => (names[k] = names[k] ? names[k] + b.names[k] : b.names[k])
  );
  const costUnit = (a.costUnit || 0) + (b.costUnit || 0);
  const costGSF = (a.costGSF || 0) + (b.costGSF || 0);
  const areaRange: Range = [
    (a.areaRange ? a.areaRange[0] : 0) + (b.areaRange ? b.areaRange[0] : 0),
    (a.areaRange ? a.areaRange[1] : 0) + (b.areaRange ? b.areaRange[1] : 0),
  ];
  const headcountRange: Range = [
    (a.headcountRange ? a.headcountRange[0] : 0) +
      (b.headcountRange ? b.headcountRange[0] : 0),
    (a.headcountRange ? a.headcountRange[1] : 0) +
      (b.headcountRange ? b.headcountRange[1] : 0),
  ];
  return {
    area: (a.area || 0) + (b.area || 0),
    seats: (a.seats || 0) + (b.seats || 0),
    workpoints: (a.workpoints || 0) + (b.workpoints || 0),
    headcount: (a.headcount || 0) + (b.headcount || 0),
    tags,
    names,
    areaRange,
    seatsRange: [
      (a.seatsRange ? a.seatsRange[0] : 0) +
        (b.seatsRange ? b.seatsRange[0] : 0),
      (a.seatsRange ? a.seatsRange[1] : 0) +
        (b.seatsRange ? b.seatsRange[1] : 0),
    ],
    headcountRange,
    workpointsRange: [
      (a.workpointsRange ? a.workpointsRange[0] : 0) +
        (b.workpointsRange ? b.workpointsRange[0] : 0),
      (a.workpointsRange ? a.workpointsRange[1] : 0) +
        (b.workpointsRange ? b.workpointsRange[1] : 0),
    ],
    costUnit,
    costGSF,
    // LM-TODO: Improve handling here
    totalCostRange: [costGSF * areaRange[0], costGSF * areaRange[1]],
    costHCRange: [costGSF * headcountRange[1], costGSF * headcountRange[0]],
    totalCost: (a.totalCost || 0) + (b.totalCost || 0),
    costHC: (a.costHC || 0) + (b.costHC || 0),
    sharingRatio: ((a.sharingRatio || 0) + (b.sharingRatio || 0)) / 2,
    doors: (a.doors || 0) + (b.doors || 0),
  };
};

// merge while calculating on blocks from MetricsProps
export const mergeMetrics = (
  a: MetricsProps,
  b: MetricsProps
): MetricsProps => {
  const tags: Counts = {};
  if (a.tags)
    Object.keys(a.tags).forEach(
      (k) => (tags[k] = tags[k] ? tags[k] + a.tags[k] : a.tags[k])
    );
  if (b.tags)
    Object.keys(b.tags).forEach(
      (k) => (tags[k] = tags[k] ? tags[k] + b.tags[k] : b.tags[k])
    );

  const names: Counts = {};
  if (a.names)
    Object.keys(a.names).forEach(
      (k) => (names[k] = names[k] ? names[k] + a.names[k] : a.names[k])
    );
  if (b.names)
    Object.keys(b.names).forEach(
      (k) => (names[k] = names[k] ? names[k] + b.names[k] : b.names[k])
    );

  const types: { [k: string]: TypeMetric } = {};
  if (a.types)
    Object.keys(a.types).map((aType) => (types[aType] = a.types[aType]));
  if (b.types)
    Object.keys(b.types).map(
      (bType) =>
        (types[bType] = types[bType]
          ? mergeTypeMetrics(types[bType], b.types[bType])
          : b.types[bType])
    );

  return {
    size: [a.size[0] + b.size[0], a.size[1] + b.size[1]],
    area: (a.area || 0) + (b.area || 0),
    seats: (a.seats || 0) + (b.seats || 0),
    workpoints: (a.workpoints || 0) + (b.workpoints || 0),
    headcount: (a.headcount || 0) + (b.headcount || 0),
    areaRange: [
      (a.areaRange ? a.areaRange[0] : 0) + (b.areaRange ? b.areaRange[0] : 0),
      (a.areaRange ? a.areaRange[1] : 0) + (b.areaRange ? b.areaRange[1] : 0),
    ],
    seatsRange: [
      (a.seatsRange ? a.seatsRange[0] : 0) +
        (b.seatsRange ? b.seatsRange[0] : 0),
      (a.seatsRange ? a.seatsRange[1] : 0) +
        (b.seatsRange ? b.seatsRange[1] : 0),
    ],
    headcountRange: [
      (a.headcountRange ? a.headcountRange[0] : 0) +
        (b.headcountRange ? b.headcountRange[0] : 0),
      (a.headcountRange ? a.headcountRange[1] : 0) +
        (b.headcountRange ? b.headcountRange[1] : 0),
    ],
    workpointsRange: [
      (a.workpointsRange ? a.workpointsRange[0] : 0) +
        (b.workpointsRange ? b.workpointsRange[0] : 0),
      (a.workpointsRange ? a.workpointsRange[1] : 0) +
        (b.workpointsRange ? b.workpointsRange[1] : 0),
    ],
    sizeRange:
      a.sizeRange && b.sizeRange
        ? [
            [
              a.sizeRange[0][0] + b.sizeRange[0][0],
              a.sizeRange[0][1] + b.sizeRange[0][1],
            ],
            [
              a.sizeRange[1][0] + b.sizeRange[1][0],
              a.sizeRange[1][1] + b.sizeRange[1][1],
            ],
          ]
        : [
            [0, 0],
            [0, 0],
          ],
    names,
    tags,
    types,
    costUnit: (a.costUnit || 0) + (b.costUnit || 0),
    costGSF: (a.costGSF || 0) + (b.costGSF || 0),
    costHCRange: [
      (a.costHCRange[0] || 0) + (b.costHCRange[0] || 0),
      (a.costHCRange[1] || 0) + (b.costHCRange[1] || 0),
    ],
    totalCostRange: [
      (a.totalCostRange[0] || 0) + (b.totalCostRange[0] || 0),
      (a.totalCostRange[1] || 0) + (b.totalCostRange[1] || 0),
    ],
    costHC: (a.costHC || 0) + (b.costHC || 0),
    totalCost: (a.totalCost || 0) + (b.totalCost || 0),
    sharingRatio: (a.sharingRatio || 0) + (b.sharingRatio || 0) / 2,
    doors: (a.doors || 0) + (b.doors || 0),
    doorsRange: [
      (a.doorsRange[0] || 0) + (b.doorsRange[0] || 0),
      (a.doorsRange[1] || 0) + (b.doorsRange[1] || 0),
    ],
    assetHC: (a.assetHC || 0) + (b.assetHC || 0),
  };
};

export const findBlock = (block: Block, id: string): Block | undefined => {
  if (block.id === id) return block;
  else if (block.children.length > 0) {
    for (let i = 0; i < block.children.length; i++) {
      const found = findBlock(block.children[i], id);
      if (found) return found;
    }
    return undefined;
  }
};

export const getBlockInstances = (block: Block): Block[] => {
  return filterBlocks(
    block,
    (b) =>
      b.role === "block-instance" &&
      (b.children.length === 0 ||
        b.children.every((c) => c.role === "asset-instance"))
  );
};

export const applyBlocks = (
  block: Block,
  fn: (b: Block, m: mat4, depth: number) => void,
  mat?: mat4,
  depth = 0
): void => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const m = mat4.mul(mat4.create(), mat || mat4.create(), block.matrix!);
  fn(block, m, depth);
  if (block.children.length > 0)
    block.children.forEach((c) => applyBlocks(c, fn, m, depth + 1));
};

export const filterBlocks = (
  block: Block,
  fn: (b: Block) => boolean
): Block[] => {
  const blocks: Block[] = [];
  applyBlocks(block, (b) => {
    if (fn(b)) blocks.push(b);
  });
  return blocks;
};

export const applyBlockId = (
  block: Block,
  id: string,
  fn: (b: Block, m: mat4) => void,
  mat?: mat4
): void => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const m = mat4.mul(mat4.create(), mat || mat4.create(), block.matrix!);
  if (block.id === id) fn(block, m);
  else if (block.children.length > 0)
    block.children.forEach((c) => applyBlockId(c, id, fn, m));
};

export const addSplit = (block: Block, idx: number, flip = false): Block => {
  const layout = getters.getLayout(block);
  let newBlock = defaultByType("focus", "horizontal", 0);
  if (block.symbol) {
    newBlock = cloneBlock(block);
  }
  newBlock.id = uuid();
  if (layout.mode === "flex" && layout.flex) {
    const split = cloneBlock(block);
    split.id = uuid();
    delete newBlock.props.sides?.dividers;
    delete block.props.sides?.dividers;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    newBlock.props.layout!.padding = [0, 0, 0, 0];
    block.children =
      (idx % 2 === 0 && layout.flex.mode === "vertical") ||
      (idx % 2 === 0 && layout.flex.mode === "horizontal")
        ? [newBlock, split]
        : [split, newBlock];
    if (flip)
      layout.flex.mode =
        layout.flex.mode === "vertical" ? "horizontal" : "vertical";
    layout.padding = [0, 0, 0, 0];
    layout.flex.spacing = 0;
    block.symbol = "";
    block.role = "block";
  }
  block.props.layout = layout;
  return newBlock;
};

export const getAncestors = (
  block: Block,
  id: string,
  branch: Block[] = []
): Block[] => {
  if (!block.children.length) return [];
  for (let i = 0; i < block.children.length; i++) {
    if (block.children[i].id === id)
      return [...branch, block, block.children[i]];
    else {
      const parent = getAncestors(block.children[i], id, [...branch, block]);
      if (parent.length > 0) return parent;
    }
  }
  return [block];
};

export const getParentBlock = (block: Block, id: string): Block | undefined => {
  if (!block.children.length) return;
  for (let i = 0; i < block.children.length; i++) {
    if (block.children[i].id === id) return block;
    else {
      const parent = getParentBlock(block.children[i], id);
      if (parent) return parent;
    }
  }
  return undefined;
};

export const deleteBlock = (block: Block, id: string): Block => {
  let parent = getParentBlock(block, id);
  if (parent) {
    const childIdx = parent.children.findIndex((child) => child.id === id);
    if (childIdx >= 0) {
      parent.children.splice(childIdx, 1);
    }
    // if parent container no longer has children, remove it. recursive
    if (parent.children.length === 1) {
      const _id = parent.id;
      const gp = getParentBlock(block, _id);
      if (gp) {
        const parentIdx = gp.children.findIndex((child) => child.id === _id);
        if (parentIdx >= 0) {
          const child = parent.children[0];
          gp.children.splice(parentIdx, 1, child);
        }
      } else {
        // let c = block.children[0];
        // block.props.layout = c.props.layout;
        // block.props.metrics = c.props.metrics;
        // block.props.sides = c.props.sides;
        // block.props.constraints = c.props.constraints;
        // // block.props.definition = c.props.definition;
        // block.symbol = c.symbol;
        // block.children = c.children;
      }
    }
    while (parent.children.length === 0) {
      const _id = parent.id;
      const gp = getParentBlock(block, _id);
      if (!gp) break;
      const _childIdx = gp.children.findIndex((child) => child.id === _id);
      if (_childIdx >= 0) {
        gp.children.splice(_childIdx, 1);
      }
      parent = gp;
    }
  }
  return block;
};

export const updateBlock = (
  block: Block,
  id: string,
  _props: Partial<Block>
): Block => {
  applyBlockId(block, id, (match) => Object.assign(match, _props));
  return block;
};

export const updateBlockProps = (
  block: Block,
  id: string,
  _props: Partial<ShapeProps>
): Block => {
  applyBlockId(block, id, (match) => Object.assign(match.props, _props));
  return block;
};

export const getOverrideColor = (b: Block, type: string): string | false => {
  const overrideColors: string[] = [];
  applyBlocks(b, (block) => {
    const definition = block.props.definition;
    if (
      definition != null &&
      definition.type === type &&
      definition.fillColor &&
      definition.fillColor !== ""
    ) {
      overrideColors.push(definition.fillColor);
    }
  });
  return overrideColors.length > 0 ? overrideColors[0] : false;
};

// wpiOverride sets the background color of teamspace blocks when Porfolio is
// in "Activity" mode
// wpioverride: 0 = default, don't override.
// wpioverride: 1 = focus/individual
// wpioverride: 2 = communal
export const getBlockColor = (
  block: Block,
  mult: number,
  wpiOverride: 0 | 1 | 2
): string => {
  if (wpiOverride > 0) {
    return wpiOverride === 1 ? "#8FB7EC" : "#E4B87D";
  }

  const metrics = getters.getMetrics(block);
  // find the type with the greatest area
  const type = Object.keys(metrics.types).sort((a, b) => {
    const av = metrics.types[a]?.area || 0;
    const bv = metrics.types[b]?.area || 0;
    return av > bv ? 1 : av < bv ? -1 : 0;
  })[0];
  // get base color for that type
  const value = Object.values(program).find((category) => {
    return Object.values(category.children).find((subcategory) => {
      return subcategory.children[type] != null;
    });
  });
  let color = "#ffffff";
  if (value) {
    color = value?.fillColor || colors.teamColor;
    // check overrides
    const override = getOverrideColor(block, type);
    if (override) color = override;
  } else {
    const isCollab =
      metrics.types.collaboration &&
      (!metrics.types.focus ||
        metrics.types.focus.area < metrics.types.collaboration.area);
    color = isCollab ? colors.collabColor : colors.teamColor;
  }

  return lightenHex(color, mult);
};
