import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Loading from "components/loading";
import Home from "./home";
import Login from "./login";
import { isAuthTokenValid } from "lib/auth";
import App from "./app";
import AssetList from "./blocks/pages/asset-list";
import BlockList from "./blocks/pages/block-list";
import BlockEdit from "./blocks/pages/block-edit";
import BlockSandbox from "./blocks/pages/block-sandbox";
import withBlocks from "./lib/hoc/withBlocks";
import withBlockLibraries from "./lib/hoc/withBlockLibraries";

const PrivateRoute = ({
  component: Component,
  ...rest
}: {
  component: any;
  exact?: boolean;
  path: string;
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthTokenValid() ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

const Routes: React.FC = () => {
  return (
    <Router>
      <React.Suspense fallback={<Loading />}>
        <Switch>
          <Route exact path="/login/oauth/:token" component={Login} />
          <Route exact path="/login" component={Login} />
          <PrivateRoute component={WrappedBlockRoutes} path="/blocks/:id" />
          <PrivateRoute component={WrappedBlockRoutes} path="/blocks" />
          <PrivateRoute component={WrappedBlockRoutes} path="/assets" />
          <PrivateRoute component={WrappedBlockRoutes} path="/sandbox" />
          <PrivateRoute component={App} path="/:id" />
          <PrivateRoute exact path="/" component={Home} />
        </Switch>
      </React.Suspense>
    </Router>
  );
};

const BlockRoutes: React.FC<{ match: { path: string } }> = () => {
  return (
    <>
      <PrivateRoute exact path={`/blocks/:id`} component={BlockEdit} />
      <PrivateRoute exact path={`/blocks`} component={BlockList} />
      <PrivateRoute exact path="/assets" component={AssetList} />
      <PrivateRoute exact path="/sandbox" component={BlockSandbox} />
    </>
  );
};

const WrappedBlockRoutes = withBlockLibraries(withBlocks(BlockRoutes));

export default Routes;
