import {
  FloorMetricUpdate,
  Metric,
  RendererMetric,
  StrategyMode,
  TableRowMetric,
} from "lib/types";

// define starting values for all metric types to be used in project calculations
export const emptyMetric: Metric = {
  buildingID: "",
  strategyID: "",
  mode: StrategyMode.Zone,
  totalFloorArea: 0,
  efficiencyRatio: 0.85,
  baselineHeadCount: 0,
  baselineSeatCount: 0,
  seatCount: 0,
  potentialSeats: 0,
  convertedSeats: 0,
  deskSpacing: 0,
  desksOff: false,
  floor: "",
  baselineConferenceRoomsCount: 0,
  baselineConferenceRoomsCapacity: 0,
  baselineConferenceRoomsSeatsPerHC: 0,
  baselinePhoneCount: 0,
  extraLarge: {
    count: 0,
    capacity: 0,
    area: 0,
  },
  large: {
    count: 0,
    capacity: 0,
    area: 0,
  },
  medium: {
    count: 0,
    capacity: 0,
    area: 0,
  },
  small: {
    count: 0,
    capacity: 0,
    area: 0,
  },
  huddle: {
    count: 0,
    capacity: 0,
    area: 0,
  },
  phone: {
    count: 0,
    capacity: 0,
    area: 0,
  },
  conferenceRoomSeats: 0,
  conferenceRoomSeatsPerHC: 0,
  collaborationZones: 0,
  collaborationZonesArea: 0,
  convertedMeetingRooms: 0,
  collaborationSeats: 0,
  corridorWidth: 0,
  displayName: "",
  dedicatedSeats: 0,
  flexSeats: 0,
  userConvertedMeetingRooms: {
    convertToCollaboration: [],
    convertToWorkstations: [],
    disable: [],
  },
  workpoints: 0,
  totalCost: 0,
  costUnit: 0,
  costGSF: 0,
  costHC: 0,
  nooksCount: 0,
  nooksAssignable: 0,
  deskCount: 0,
  phonesCount: 0,
  assignableSeats: 0,
  floorscopeDesks: 0,
  doors: 0,
  assetHC: 0,
};

export const emptySummary = {
  totalFloorArea: 0,
  seatCount: 0,
  baselineSeatCount: 0,
  convertedMeetingRooms: 0,
  conferenceRoomsBaseline: 0,
  baselineConferenceRoomsCount: 0,
  efficiencyRatio: 0,
  assignableSeats: 0,
};

export const emptyTableMetric: TableRowMetric = {
  projectID: "",
  buildingID: "",
  strategyID: "",
  totalFloorArea: 0,
  usableFloorArea: 0,
  efficiencyRatio: 0.85,
  baselineHeadCount: 0,
  baselineSeatCount: 0,
  seatCount: 0,
  baselineDensity: 0,
  density: 0,
  useableDensity: 0,
  baselineUseableDensity: 0,
  potentialSeats: 0,
  convertedSeats: 0,
  floorCount: 0,
  baselineDeskToDoor: 0,
  baselineConferenceRoomsCount: 0,
  baselineConferenceRoomsCapacity: 0,
  baselineConferenceRoomsSeatsPerHC: 0,
  baselinePhoneCount: 0,
  conferenceRoomSeats: 0,
  conferenceRoomSeatsPerHC: 0,
  deskToDoor: 0,
  deskToDoorWithNooks: 0,
  convertedMeetingRooms: 0,
  displayName: "",
  workpoints: 0,
  totalCost: 0,
  costUnit: 0,
  costGSF: 0,
  costHC: 0,
  assignableSeats: 0,
  nooksCount: 0,
  doors: 0,
  phones: 0,
  phonesCount: 0,
  assetHC: 0,
};

export const emptyRendererMetric: RendererMetric = {
  seatCount: 0,
  convertedSeats: 0,
  collaborationZonesArea: 0,
  collaborationZones: 0,
  totalFloorArea: 0,
  convertedMeetingRooms: 0,
  collaborationSeats: 0,
  collaborationActivities: [],
  focusActivities: [],
  baselineConferenceRoomsCount: 0,
  baselineSeatCount: 0,
  baselineHeadCount: 0,
  workpoints: 0,
  totalCost: 0,
  costHC: 0,
  costUnit: 0,
  costGSF: 0,
  assignableSeats: 0,
  efficiencyRatio: 0,
  nooksCount: 0,
  nooksAssignable: 0,
  phonesCount: 0,
  phone: 0,
  doors: 0,
  assetHC: 0,
};

export const emptyFloorMetricUpdate: FloorMetricUpdate = {
  seatCount: 0,
  conferenceRoomSeats: 0,
  conferenceRoomSeatsPerHC: 0,
  potentialSeats: 0,
  baselineSeatCount: 0,
  convertedSeats: 0,
  convertedMeetingRooms: 0,
  collaborationSeats: 0,
  collaborationZones: 0,
  collaborationZonesArea: 0,
  dedicatedSeats: 0,
  flexSeats: 0,
  workpoints: 0,
  totalCost: 0,
  costHC: 0,
  costUnit: 0,
  costGSF: 0,
  nooksCount: 0,
  nooksAssignable: 0,
  deskCount: 0,
  floorscopeDesks: 0,
  assignableSeats: 0,
  phonesCount: 0,
  doors: 0,
  assetHC: 0,
};

export const roomTypes = [
  "extraLarge",
  "large",
  "medium",
  "small",
  "huddle",
  "phone",
];
