import axios from "axios";
import config from "config";
import { getAuthHeader } from "../auth";
import { createContainer } from "unstated-next";
import { useEffect, useState } from "react";
import { Background, Building, Buildings, Features, Metric } from "../types";

export interface UseBuildingsType {
  buildings: Buildings;
  saveBuilding(b: NewBuildingRequest): void;
  asArray(): Building[];
}

export type NewBuildingRequest = {
  building: Building;
  metrics: Metric;
  floors: Record<
    string,
    {
      metrics: Metric;
      features: Features;
      background: Background;
      regions: number[][][];
    }
  >;
};

const useBuildingsContainer = (): UseBuildingsType => {
  const [buildings, setBuildings] = useState<Buildings>({});

  useEffect(() => {
    const getBuildings = async (): Promise<void> => {
      const headers = await getAuthHeader();
      const { data } = await axios.get(
        config.baseUrl + `/api/planning/buildings`,
        {
          headers,
        }
      );

      const newBuildings = data as Buildings;
      setBuildings(newBuildings);
    };

    if (!Object.values(buildings).length) getBuildings();
  }, [buildings, setBuildings]);

  const saveBuilding = async (b: NewBuildingRequest) => {
    try {
      const headers = await getAuthHeader();
      return await axios
        .post(config.baseUrl + `/api/planning/buildings`, b, {
          headers,
        })
        .catch(() => {
          console.error("Failed to save building");
        });
    } catch (error: any) {
      console.error("Error: ", error);
    }
  };

  return {
    buildings,
    saveBuilding,
    asArray: () => Object.values(buildings),
  };
};

export const BuildingsController = createContainer(useBuildingsContainer);
export const BuildingsProvider = BuildingsController.Provider;
export const useBuildingsCtrl = () => BuildingsController.useContainer();
