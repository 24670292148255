import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  full: {
    width: "100vw",
    height: "100vh",
  },
});
interface Props {
  children: React.ReactNode;
  overflowY?: boolean;
}

const Container: React.FC<Props> = ({ children, overflowY }) => {
  const classes = useStyles();
  return (
    <div
      className={classes.full}
      style={overflowY ? { overflowY: "auto" } : {}}
    >
      {children}
    </div>
  );
};

export default Container;
