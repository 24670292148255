import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, createStyles } from "@material-ui/core";
import { New, SideNavBar } from "@outerlabs/ol-ui";
import Container from "components/layout/container";
import Card from "components/card";
import { Project as ProjectType, NavTabs } from "lib/types";
import UserAvatar from "components/user-avatar";
import withProjects from "lib/hoc/withProjects";
import SideBarNavGroup from "components/sideNavBar/sideNavBarGroup";
import TopNavBar from "components/topNavBar/topNavBar";
import { defaultValues } from "lib/constants";
import EmptyState from "components/emptyState";
import Loading from "components/loading";
import { useGAPageView } from "../lib/hooks/use-ga";

const useStyles = makeStyles(() =>
  createStyles({
    newButtonText: {
      fontFamily: "GoogleSansRegular",
      fontSize: "16px",
      lineHeight: "28px",
      letterSpacing: "0.5px",
      color: "#3B3B3B",
    },
    emptyStateContainer: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    sidebar: { position: "absolute", top: 0, left: 0, zIndex: 100 },
    cards: {
      paddingTop: 140,
      paddingLeft: 68,
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      zIndex: 0,
    },
    newButton: { position: "absolute", bottom: 36, right: 36, zIndex: 100 },
  })
);

interface Props {
  projects: { [key: string]: ProjectType };
  isLoading: boolean;
  createProject: () => Promise<string>;
}

const Home: React.FC<Props> = ({ projects, createProject, isLoading }) => {
  const { push } = useHistory();
  const classes = useStyles();
  useGAPageView("Studies");

  const createPortfolio = async () => {
    const id = await createProject();
    if (id) push(`/${id}/strategies`);
  };

  const cardsDateLastOpened =
    projects &&
    Object.values(projects)
      .filter((p: ProjectType) => !p.deleted)
      //filter the projects that were created before the dateLastOpened was added to the project
      .filter(
        (p: ProjectType) => String(p.dateLastOpened) !== "0001-01-01T00:00:00Z"
      )
      .sort((a, b) =>
        a.dateLastOpened > b.dateLastOpened
          ? -1
          : b.dateLastOpened > a.dateLastOpened
          ? 1
          : 0
      );
  const cardsNoDateLastOpened =
    projects &&
    Object.values(projects).filter(
      (p: ProjectType) => String(p.dateLastOpened) === "0001-01-01T00:00:00Z"
    );
  const cards = projects && [...cardsDateLastOpened, ...cardsNoDateLastOpened];

  if (isLoading) return <Loading />;

  return (
    <Container overflowY>
      <TopNavBar
        ProjectTitle={"TestFit"}
        subTitle={"MY STUDIES"}
        UserAvatar={<UserAvatar />}
      />
      <div className={classes.sidebar}>
        <SideNavBar
          appIconAlt={"portfolioIcon"}
          appIconSrc={defaultValues.appIconSrc}
          appIconClick={() => push(`/`)}
        >
          <SideBarNavGroup navTab={NavTabs.Home} />
        </SideNavBar>
      </div>
      <div className={classes.cards}>
        {cards && cards.length > 0
          ? cards.map((a: ProjectType) => (
              <Card key={a.id} {...a} project={a} />
            ))
          : null}
      </div>
      {cards && cards.length === 0 && (
        <div className={classes.emptyStateContainer}>
          <EmptyState
            text={"This study is empty."}
            subText={
              "Add buildings using the button on the bottom right corner."
            }
          />
        </div>
      )}
      <div className={classes.newButton}>
        <New onClick={createPortfolio} data-testid="new-study-button">
          <div className={classes.newButtonText}>New Study</div>
        </New>
      </div>
    </Container>
  );
};

export default withProjects(Home);
