import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Fab from "@material-ui/core/Fab";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import Dialog from "@material-ui/core/Dialog";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete, {
  AutocompleteCloseReason,
} from "@material-ui/lab/Autocomplete";
import { Building } from "lib/types";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Button from "@material-ui/core/Button";
import { FilterOptionsState } from "@material-ui/lab";
import { useBuildingsCtrl, useProjectCtrl } from "lib/containers";

const useStyles = makeStyles(() =>
  createStyles({
    inputBase: {
      padding: 16,
      paddingLeft: 20,
      width: "100%",
      borderBottom: "1px solid #ced4da",
      fullWidth: true,
      color: "#ADADAD",
      "& input": {
        borderRadius: 4,
        padding: 8,
        fontSize: 16,
        fontFamily: "GoogleSansRegular",
      },
    },
    rootImport: {
      margin: "6px",
      fontFamily: "GoogleSansRegular",
      textTransform: "capitalize",
      fontSize: "14px",
      width: "238px",
      height: "36px",
    },
    rootCancel: {
      margin: "6px",
      fontFamily: "GoogleSansBold",
      textTransform: "capitalize",
      color: "#5F6166",
      fontSize: "14px",
      width: "96px",
      height: "36px",
    },
    rootAdd: {
      margin: "6px",
      fontFamily: "GoogleSansBold",
      textTransform: "capitalize",
      color: "white",
      fontSize: "14px",
      backgroundColor: "#2F73FF",
      width: "96px",
      height: "36px",
    },
    paper: {
      boxShadow: "none",
      margin: 0,
      fontSize: 12,
      height: "270px",
    },
    listbox: {
      height: "270px",
      paddingBottom: "50px",
    },
    noData: {
      marginLeft: "6px",
      color: `rgba(229, 62, 62, 1)`,
    },
    addBuildingsButton: {
      backgroundColor: "#2F73FF",
      height: "48px",
    },
    groupLabel: {
      fontFamily: "GoogleSansBold",
      fontSize: "12px",
      lineHeight: "14px",
      letterSpacing: "2px",
      textTransform: "uppercase",
      marginTop: "20px",
      marginBottom: "20px",
    },
    addBuildings: {
      boxShadow: "none",
      backgroundColor: "#2F73FF",
      height: "48px",
    },
    addBuildingsText: {
      position: "relative",
      right: "5px",
      textTransform: "capitalize",
      fontFamily: "GoogleSansRegular",
      fontSize: "16px",
      fontWeight: "normal",
      lineHeight: "29px",
      letterSpacing: "0.5px",
    },
    addSymbol: { marginRight: 17 },
    addBuildingsDialog: {
      position: "absolute",
      bottom: "0px",
      right: "20px",
      boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.25)",
      height: 440,
      overflowY: "hidden",
    },
    icon: {
      color: "#ADADAD",
      width: 24,
      height: 24,
    },
    dialogTitle: {
      marginTop: "20px",
      marginLeft: "20px",
      fontFamily: "GoogleSansRegular",
      fontSize: "20px",
      lineHeight: "23px",
      color: "#555555",
    },
    listItem: {
      marginLeft: "18px",
      fontFamily: "GoogleSansRegular",
      fontSize: "16px",
      color: "#878787",
    },
    container: {
      position: "fixed",
      bottom: 36,
      right: 36,
      marginRight: 12,
    },
    bottom: {
      padding: 6,
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      borderTop: "1px solid #ced4da",
      bottom: 0,
      position: "absolute",
      width: "100%",
      zIndex: 10000,
      backgroundColor: "white",
    },
  })
);

const Add: React.FC = () => {
  const history = useHistory();
  const { project, addBuildings } = useProjectCtrl();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [inputValue, setInputValue] = useState<string>("");
  const { asArray } = useBuildingsCtrl();
  const open = Boolean(anchorEl);
  const id = open ? "popover" : undefined;
  const options: Building[] = asArray();

  const mapBuildings = () => {
    if (project)
      return project.buildingIds.map((pid) => project.buildings[pid]);
    return [];
  };

  const [value, setValue] = useState<Building[]>(mapBuildings);

  // open building picker when clicking on Add button
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setValue(mapBuildings);
  };

  // close building picker
  const handleClose = (
    event: React.ChangeEvent<any>,
    reason: AutocompleteCloseReason
  ) => {
    if (reason === "toggleInput") {
      return;
    }
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
    setInputValue("");
  };

  // cancel adding buildings
  const handleCancel = () => {
    setValue(mapBuildings);
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  // add buildings to the project and save it
  const handleSave = async () => {
    if (!project) return;
    await addBuildings(value);
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  const customFilter = (
    bldgOptions: Building[],
    { inputValue: iValue }: FilterOptionsState<Building>
  ): Building[] => {
    if (!iValue) return bldgOptions;
    return bldgOptions.filter((b) =>
      b.BuildingId.toLowerCase().includes(iValue.toLowerCase())
    );
  };

  return (
    <div className={classes.container}>
      <Fab
        className={classes.addBuildingsButton}
        color="primary"
        aria-label="add"
        variant="extended"
        onClick={(event) => {
          handleClick(event);
        }}
        data-testid="add-buildings-button"
      >
        <AddIcon className={classes.addSymbol} />
        <div className={classes.addBuildingsText}>Buildings</div>
      </Fab>
      <Dialog
        classes={{ paper: classes.addBuildingsDialog }}
        hideBackdrop={true}
        id={id}
        open={open}
      >
        <div className={classes.dialogTitle}>Add Buildings to Study</div>
        <div className="h-autocomplete" style={{ height: "365px" }}>
          <Autocomplete
            key="autocomplete-key"
            onClose={handleClose}
            open
            multiple
            inputValue={inputValue}
            id="buildings"
            filterOptions={customFilter}
            getOptionSelected={(option, val) =>
              option.BuildingId === val.BuildingId
            }
            options={options.sort((a, b) => -b.City.localeCompare(a.City))}
            groupBy={(option: Building) => option.City}
            getOptionLabel={(option: Building) => option.BuildingId}
            style={{ width: 350 }}
            disableCloseOnSelect
            value={value}
            classes={{
              paper: classes.paper,
              listbox: classes.listbox,
              groupLabel: classes.groupLabel,
            }}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            onInputChange={(event: React.ChangeEvent<any>) => {
              if (
                event &&
                event.target.value != null &&
                (typeof event.target.value === "string" ||
                  event.target.value instanceof String)
              ) {
                setInputValue(event.target.value);
              }
            }}
            disablePortal
            noOptionsText="No buildings"
            ListboxProps={{
              "data-testid": "add-building-options",
            }}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                {selected ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                <div
                  className={classes.listItem}
                  data-testid="add-building-option-name"
                >
                  {option.BuildingId}
                </div>
                {(!option.HasData || !option.AvailableFloors) && (
                  <div
                    className={classes.noData}
                    data-testid="no-building-data-warning"
                  >
                    {" "}
                    Building Data not available
                  </div>
                )}
              </React.Fragment>
            )}
            renderInput={(params) => {
              return (
                <InputBase
                  key="autocomplete-input-key"
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  autoFocus
                  className={classes.inputBase}
                  startAdornment={<SearchIcon className={classes.icon} />}
                />
              );
            }}
          />
        </div>
        <div className={classes.bottom}>
          <Button
            startIcon={<AddIcon />}
            className={classes.rootImport}
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => history.push("strategies/import")}
          >
            New Building
          </Button>
          <Button
            className={classes.rootCancel}
            size="small"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            className={classes.rootAdd}
            variant="contained"
            size="small"
            color="primary"
            onClick={handleSave}
            data-testid="confirm-add-buildings-button"
          >
            Add
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default Add;
