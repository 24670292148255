import { Model, ModelUnits } from "@outerlabs/isp/lib/scenegraph/model/types";
import { Background, ElementType, Features } from "../../lib/types";

export type ParsedDXFFile = {
  name: string;
  rawFile: File;
  level?: string;
  parsed?: DXFFile;
  error?: DXFParseError;
};

export interface DXFParseError {
  file: string;
  message: string;
  stack: string;
}

export interface DXFFile {
  name: string;
  level: string;
  layers: string[];
  dxf: DXFContent;
  size: [number, number];
  units: ModelUnits;
  features: Features;
  background: Background;
  regions: number[][][];
}

export type DXFContent = {
  entities: Entity[];
  header: {
    extMax: Point;
    extMin: Point;
    insUnits: "inches";
  };
};

export interface DXFResult {
  files: DXFFile[];
  errors: DXFParseError[];
}

export interface Layer {
  file: string;
  layer?: string;
}

export interface Layers {
  [key: string]: Layer[];
}

export interface LayerName {
  name: string;
  altName?: string;
  display: string;
  type: LayerType;
  required: boolean;
}

export enum LayerType {
  columns = "columns",
  grid = "grid",
  floorArea = "floorArea",
  stairs = "stairs",
  glazing = "glazing",
  walls = "walls",
  rooms = "rooms",
  space = "space",
  background = "background",
  skylights = "skylights",
  desk = "desk",
}

export interface LayerErrors {
  [type: string]: {
    [index: string]: string[];
  };
}

export interface Scenegraphs {
  [key: string]: Model;
}

type Point = { x: number; y: number; z: number };

type EntityBase = {
  layer: string;
};

type EntityPoint = EntityBase & {
  type: ElementType.Point;
  x: number;
  y: number;
  z: number;
};

type EntityLine = EntityBase & {
  type: ElementType.Line;
  start: Point;
  end: Point;
};

type EntityArc = EntityBase & {
  type: ElementType.Arc;
  endAngle: number;
  r: number;
  startAngle: number;
  x: number;
  y: number;
  z: number;
};

type EntityPolyline = EntityBase & {
  type: ElementType.LWPolyline;
  closed: boolean;
  vertices: Point[];
};

type EntityEllipse = EntityBase & {
  type: ElementType.Ellipse;
  axisRatio: number;
  endAngle: number;
  extrusionX: number;
  extrusionY: number;
  extrusionZ: number;
  majorX: number;
  majorY: number;
  majorZ: number;
  startAngle: number;
  x: number;
  y: number;
  z: number;
};
export type Entity =
  | EntityLine
  | EntityArc
  | EntityEllipse
  | EntityPolyline
  | EntityPoint;
