import React, { useState } from "react";
import { useParams } from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { Mutation, Project, RendererSettings, StrategyMode } from "lib/types";
import IndividualPicker from "components/controls/individualPicker";
import { useRendererCtrl, useSettingsCtrl } from "lib/containers";
import { encodeMetricId } from "lib/metrics/id";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../components/sideBar/AccordionComponents";

export interface Props {
  project: Project;
  settings: RendererSettings | undefined;
}

const SidebarSelectedRooms: React.FC<Props> = ({ project, settings }) => {
  const { buildingID, strategyID, floorID } =
    useParams<{ [key: string]: string }>();
  const [expanded, setExpanded] = useState("rooms");
  const { selectedRooms } = useRendererCtrl();
  const { saveFloor, updateBuildingMetrics } = useSettingsCtrl();
  const id = encodeMetricId(buildingID, strategyID, floorID);
  const metric = project.metrics[id];

  const onMetricChange = async (mutation: Mutation) => {
    if (metric.mode === StrategyMode.Building) {
      await updateBuildingMetrics(mutation, buildingID, strategyID);
    } else if (metric.mode === StrategyMode.Zone) {
      await saveFloor({ mutation, buildingID, strategyID, floorID, settings });
    }
  };

  if (selectedRooms === undefined || selectedRooms?.length === 0) {
    return null;
  }

  return (
    <div
      style={{ display: "flex", flexDirection: "column", overflowX: "hidden" }}
    >
      <Accordion
        defaultExpanded={true}
        expanded={expanded === "rooms"}
        onChange={() =>
          expanded === "rooms" ? setExpanded("") : setExpanded("rooms")
        }
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          Reconfigure
        </AccordionSummary>
        <AccordionDetails>
          <IndividualPicker metric={metric} onMetricChange={onMetricChange} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default React.memo(SidebarSelectedRooms);
