import React, { CSSProperties, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../components/sideBar/AccordionComponents";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ErrorOutline } from "@material-ui/icons";
import { useBlocksCtrl } from "../lib/containers";
import {
  Team,
  filterMatchMaker,
  MMUserProfile,
  useMatchMakerCtrl,
  MMUserProfileMetadata,
  defaultMMUserProfileMetadataMap,
  MMUserProfiles,
  MMSpotTypes,
  MMSpotType,
  MMAssetSharingRatio,
} from "../lib/containers";
import {
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Button as MUIButton,
  Modal,
  Box,
} from "@material-ui/core";
import { Control } from "./matchmaker-slider";
import TextField from "../blocks/components/textfield";
import NumberField from "../blocks/components/number-field";
import { Block } from "../blocks/lib/types";
import {
  CheckBoxOutlineBlank,
  CheckBoxOutlined,
  Close,
  KeyboardArrowDown,
  MoreVert,
} from "@material-ui/icons";
import ZoneHeader from "../components/sideBar/MatchMakerHeader";
import {
  DialogTitle,
  DialogActions,
  DialogContent,
  Type,
  Button,
  Colors,
} from "@outerlabs/ol-ui";
import { SIDEBAR_WIDTH } from "components/layout/sidebar";
import Autocomplete from "blocks/components/autocomplete";
import SidebarMatchMakerCard from "./sidebar-matchmaker-card";

const blue = "47,115,255";
const orange = "255,151,0";

const showUpRateOptions = [
  { title: "100% (3+ Days / week)", value: "3" },
  { title: "23-50% (1-2 Days / week)", value: "2" },
  { title: "23% (Less than 1 day / week)", value: "1" },
];

const sharingRatioOptions: { title: string; value: MMAssetSharingRatio }[] = [
  { title: "5:1", value: "5:1" },
  { title: "2:1", value: "2:1" },
  { title: "1:1", value: "1:1" },
  { title: "Unassigned", value: "Unassigned" },
];

const possibleErrorFactory: (numbers: number[]) => React.ReactNode = (
  numbers
) => {
  const totalPercent = numbers.reduce((prev, curr) => prev + curr, 0);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        height: 32,
        width: 333,
        alignItems: "center",
        fontFamily: "GoogleSansRegular",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "20px",
        background: totalPercent === 100 ? "" : "#FFF8E1",
        marginTop: 8,
        padding: 8,
        color: "#5F6166",
      }}
    >
      {totalPercent !== 100 ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div style={{ color: "#FFAF38", height: 20.56, marginRight: 10 }}>
            <ErrorOutline />
          </div>
          <div style={{ height: 20.56 }}> Must equal 100%</div>
        </div>
      ) : (
        <div></div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: 84,
          alignItems: "center",
          padding: "6px 8px 6px 8px",
        }}
      >
        {totalPercent} <p>%</p>
      </div>
    </div>
  );
};

const possibleErrorFactorySecondary: (
  numbers: number[],
  headcount: number
) => React.ReactNode = (numbers, headcount) => {
  const totalPercent = numbers.reduce((prev, curr) => prev + curr, 0);
  const totalHC = numbers.reduce(
    (prev, curr) => prev + Math.ceil((curr * headcount) / 100),
    0
  );
  return (
    <Grid
      container={true}
      alignItems={"center"}
      style={{
        background: totalPercent === 100 ? "" : "#FFF8E1",
        marginTop: 8,
      }}
    >
      <Grid item={true} xs={6}>
        {totalPercent !== 100 ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-around",
              fontFamily: "GoogleSansRegular",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "14px",
              color: "#919499",
              textAlign: "center",
              margin: "2px 0 2px 0",
            }}
          >
            <div style={{ color: "#FFAF38" }}>
              <ErrorOutline />
            </div>
            <div>Must equal 100%</div>
          </div>
        ) : (
          <div></div>
        )}
      </Grid>
      <Grid item={true} xs={3}>
        <div
          style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            paddingLeft: 14,
            fontFamily: "GoogleSansRegular",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "14px",
            color: "#919499",
            verticalAlign: "center",
            margin: "2px 0 2px 0",
            height: 23.56,
          }}
        >
          {totalHC} ppl
        </div>
      </Grid>
      <Grid item={true} xs={3}>
        <div
          style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            fontFamily: "GoogleSansRegular",
            fontSize: "14px",
            paddingLeft: 18,
            fontWeight: 400,
            lineHeight: "14px",
            color: "#1B1D21",
            margin: "2px 0 2px 0",
            height: 23.56,
          }}
        >
          {totalPercent} %
        </div>
      </Grid>
    </Grid>
  );
};

const sumInputs: (numbers: number[]) => React.ReactNode = (numbers) => {
  return numbers.reduce((prev, curr) => prev + curr, 0);
};

const useStyles = makeStyles({
  root: {
    position: "relative",
  },
  team: {
    width: "100%",
    borderRadius: 4,
    boxShadow: "0 2px 8px 2px rgba(0,0,0,0.1)",
    padding: "12px 12px",
    margin: "8px -8px 8px 0",
  },
  content: {
    position: "absolute",
    left: 400,
    zIndex: 10000,
    height: "100%",
    width: "100%",
    background: "white",
  },
  next: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontFamily: "GoogleSansRegular",
    color: `rgb(${blue})`,
    textTransform: "capitalize",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "16px",
    marginLeft: "auto",
    marginRight: 0,
    marginTop: 24,
    cursor: "pointer",
    "& svg": {
      transform: "translateY(5px)",
      marginLeft: 4,
    },
  },
  menu: {
    display: "flex",
    flexDirection: "column",
    width: "320px",
    height: "304px",
    background: "white",
    boxShadow:
      "0 1px 3px 1 rgba(0, 0, 0, 0.05), 0 8px 24px 2px rgba(0, 0, 0, 0.05)",
    position: "relative",
    left: 560,
    top: 323,
    borderRadius: 8,
    fontFamily: "GoogleSansRegular",
    padding: "8px 0 24px 0",
    justifyContent: "space-between",
  },
  menuHeader: {
    display: "flex",
    justifyContent: "space-between",
    padding: "8px 24px 0 24px",
    width: "100%",
    height: "56px",
    color: "#5F6166",
  },
  menuText: {
    height: 132,
    width: "100%",
    padding: "0 24px",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.5px",
    color: "#555555",
    gap: 8,
  },
  menuButtons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    height: 36,
    padding: "0px 24px 0px 24px",
  },
});

type Props = {
  // onCreate: (b: Block[]) => void;
};

const SidebarMatchMaker: React.FC<Props> = () => {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [groupSize, setGroupSize] = useState(40);
  const [numTeams, setNumTeams] = useState(4);
  const [ratio, setRatio] = useState(0.5);
  const [goBackModal, setGoBackModal] = useState<boolean>(false);

  const { blocks, getBlockById } = useBlocksCtrl();
  const {
    matchMakerStep,
    matchMakerTeams,
    matchMakerNumTeams,
    matchMakerResults,
    matchMakerSelectedResults,
    matchMakerLibrary,
    mmUserProfileMap,
    setMatchMakerStep,
    setMatchMakerNumTeams,
    setMatchMakerResults,
    setMatchMakerTeams,
    setMatchMakerActive,
    setMatchMakerSelectedResults,
    setMMUserProfileMap,
  } = useMatchMakerCtrl();

  // Factory that updates a given user profile.
  const profileMetadataChangeFactory =
    (up: MMUserProfile) => (mutation: Partial<MMUserProfileMetadata>) => {
      setMMUserProfileMap({
        ...mmUserProfileMap,
        [up]: {
          ...mmUserProfileMap[up],
          ...mutation,
        },
      });
    };

  // Dialog which shows up if you click the three dots 'more' button
  // for a user profile.
  const [userProfileDialogOpen, setUserProfileDialogOpen] =
    useState<MMUserProfile | null>(null);

  const leftStyle: CSSProperties = { width: 160, margin: "0px 8px 0 0 " };
  const rightStyle: CSSProperties = { width: "90%", margin: 0 };
  const wrapStyle = { display: "flex", alignItems: "center", marginTop: 4 };
  const labelStyle = { fontSize: "10px", color: Colors.Text.Medium, margin: 0 };

  let possibleUserProfileDialog = null;
  if (userProfileDialogOpen) {
    const prof = mmUserProfileMap[userProfileDialogOpen];
    const updateThisProf = profileMetadataChangeFactory(userProfileDialogOpen);
    possibleUserProfileDialog = (
      <Paper
        style={{
          position: "fixed",
          left: `calc(50vw + ${SIDEBAR_WIDTH / 2}px)`,
          top: "50vh",
          transform: "translate(-50%, -50%)",
          width: 493,
          maxWidth: 493,
        }}
      >
        <IconButton
          style={{ position: "absolute", top: 12, right: 12 }}
          onClick={() => {
            setUserProfileDialogOpen(null); // close modal
          }}
        >
          <Close />
        </IconButton>
        <DialogTitle.Sm>Edit User Profile</DialogTitle.Sm>

        <DialogContent.Sm>
          <TextField
            fullWidth={true}
            label={"Name"}
            onChange={(e) => updateThisProf({ name: e.target.value })}
            value={prof.name}
          />
          <div style={{ marginTop: 8 }} />
          <Autocomplete
            value={prof.showUpRate.toString()}
            onChange={(v) => updateThisProf({ showUpRate: parseInt(v) })}
            options={showUpRateOptions}
            label={"Show Up Rate"}
          />

          {/* Waiting on design and CS for the three checkbox ui. */}
          <div style={{ marginTop: 8 }} />
          <div style={wrapStyle}>
            <div style={leftStyle}>
              <Type.Caption style={labelStyle}>
                Assigned Asset Type
              </Type.Caption>
            </div>
            <div style={rightStyle}>
              <Type.Caption style={labelStyle}>
                Enter Asset Sharing Ratio
              </Type.Caption>
            </div>
          </div>
          {MMSpotTypes.map((spotType: MMSpotType) => {
            const val = prof.assetSharingRatios[spotType];
            return (
              <div key={spotType + "mmcheckbox"} style={wrapStyle}>
                <div
                  style={{
                    fontWeight: 400,
                    fontSize: "14px",
                    color: "#1B1D21",
                    ...leftStyle,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span
                    onClick={() =>
                      updateThisProf({
                        assetSharingRatios: {
                          ...prof.assetSharingRatios,
                          [spotType]:
                            val === "Unassigned" ? "1:1" : "Unassigned",
                        },
                      })
                    }
                    style={{ cursor: "pointer", marginTop: 4 }}
                  >
                    {val === "Unassigned" ? (
                      <CheckBoxOutlineBlank />
                    ) : (
                      <CheckBoxOutlined />
                    )}
                  </span>
                  <span style={{ marginLeft: 8 }}>{spotType}</span>
                </div>
                <div style={rightStyle}>
                  <Autocomplete
                    value={val?.toString()}
                    options={sharingRatioOptions}
                    onChange={(e) =>
                      updateThisProf({
                        assetSharingRatios: {
                          ...prof.assetSharingRatios,
                          [spotType]: e,
                        } as any,
                      })
                    }
                    label={""}
                  />
                </div>
              </div>
            );
          })}
        </DialogContent.Sm>

        <DialogActions.Sm>
          <Button.Sm.Sqr.Ghosted
            onClick={() => {
              updateThisProf(
                defaultMMUserProfileMetadataMap[userProfileDialogOpen]
              );
            }}
          >
            Restore Default
          </Button.Sm.Sqr.Ghosted>
          <Button.Sm.Sqr.Primary
            onClick={() => {
              setUserProfileDialogOpen(null); // close modal
            }}
          >
            Save
          </Button.Sm.Sqr.Primary>
        </DialogActions.Sm>
      </Paper>
    );
  }

  const possibleErrorMain = possibleErrorFactory(
    MMUserProfiles.map((prof) => mmUserProfileMap[prof].percentage)
  );

  useEffect(() => {
    let allTeamsAre100 = true;
    for (let i = 0; i < matchMakerNumTeams; i++) {
      if (matchMakerTeams?.[i]?.userProfilePercentages) {
        const teamCount =
          matchMakerTeams[i].userProfilePercentages.Frequent +
          matchMakerTeams[i].userProfilePercentages.Infrequent +
          matchMakerTeams[i].userProfilePercentages.Occasional;
        if (teamCount >= 101 || teamCount <= 99) {
          allTeamsAre100 = false;
        }
      }
    }
    if (allTeamsAre100) {
      const mmResults = filterMatchMaker(
        blocks,
        matchMakerTeams,
        matchMakerLibrary,
        getBlockById,
        mmUserProfileMap
      );
      setMatchMakerResults(mmResults);
    }
  }, [
    matchMakerTeams,
    blocks,
    getBlockById,
    setMatchMakerResults,
    matchMakerLibrary,
    mmUserProfileMap,
    matchMakerNumTeams,
  ]);

  const reGenerateTeams = () => {
    setMatchMakerNumTeams(numTeams);
    const teams: Team[] = [];
    let total = groupSize;
    const selected: number[] = [];
    for (let i = 0; i < numTeams; i++) {
      const n = Math.min(Math.ceil(groupSize / numTeams), total);
      // should only do this the first time you continue......
      teams.push({
        ratio,
        headcount: n,
        days: 5,
        userProfilePercentages: {
          Frequent: mmUserProfileMap[MMUserProfile.Frequent].percentage,
          Occasional: mmUserProfileMap[MMUserProfile.Occasional].percentage,
          Infrequent: mmUserProfileMap[MMUserProfile.Infrequent].percentage,
        },
        teamName: `Team ${i + 1}`,
      });
      selected.push(0);
      total -= n;
    }
    setMatchMakerTeams(teams);
    setMatchMakerSelectedResults(selected);
  };

  const onContinue = () => {
    if (matchMakerStep !== 0) return;
    setGoBackModal(false);
    setMatchMakerStep(1);
    reGenerateTeams();
  };
  const updateRow = (i: number, data: Partial<Team>) => {
    const copy = [...matchMakerTeams];
    copy[i] = { ...copy[i], ...data };
    setMatchMakerTeams(copy);
  };

  const goBackWarnModal = (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={goBackModal}
        onClose={() => {
          setGoBackModal(false);
        }}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Box className={classes.menu}>
          <div className={classes.menuHeader}>
            <div
              style={{
                fontSize: "20px",
                fontWeight: 500,
                lineHeight: "24px",
                letterSpacing: "0.15px",
              }}
            >
              Attention
            </div>
            <ErrorOutline />
          </div>
          <div className={classes.menuText}>
            This action will reset the Team settings back to the Group defaults
            selected.
          </div>
          <div className={classes.menuButtons}>
            <Button.Sm.Sqr.Ghosted
              onClick={() => setGoBackModal(false)}
              style={{ margin: 4, color: "#5F6166" }}
            >
              Cancel
            </Button.Sm.Sqr.Ghosted>
            <Button.Sm.Sqr.Primary
              onClick={() => {
                setGoBackModal(false);
                setMatchMakerStep(0);
              }}
              style={{ margin: 4 }}
            >
              Proceed
            </Button.Sm.Sqr.Primary>
          </div>
        </Box>
      </Modal>
    </div>
  );

  return (
    <div style={{ width: 400, overflowX: "hidden" }}>
      {matchMakerStep === 1 && (
        <>
          {goBackWarnModal}
          <Accordion expanded={true}>
            <AccordionSummary
              aria-controls="panel2a-content"
              id="panel2a-header"
              style={{ border: 0 }}
            >
              Performance
            </AccordionSummary>
            <AccordionDetails>
              <ZoneHeader
                blocks={
                  matchMakerSelectedResults
                    .map((j, i) => {
                      if (matchMakerResults[i]) return matchMakerResults[i][j];
                      return undefined;
                    })
                    .filter((el) => el) as Block[]
                }
                teams={matchMakerTeams}
              />
            </AccordionDetails>
          </Accordion>
        </>
      )}
      <Accordion
        expanded={matchMakerStep === 0}
        style={{
          flexGrow: matchMakerStep === 0 ? 1 : 0,
          height: matchMakerStep === 1 ? "auto" : "100%",
          maxWidth: 400,
          overflowY: "hidden",
        }}
        onClick={() => {
          if (!goBackModal && matchMakerStep !== 0) setGoBackModal(true);
        }}
      >
        <AccordionSummary
          expandIcon={matchMakerStep === 1 ? <ExpandMoreIcon /> : undefined}
          style={{ borderTop: matchMakerStep === 1 ? "1px solid #E4E4E4" : 0 }}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          MatchMaker Group
          {matchMakerStep === 0 && (
            <IconButton
              style={{ position: "absolute", top: 0, right: 12 }}
              onClick={() => {
                setMatchMakerActive(false); // close modal
              }}
            >
              <Close />
            </IconButton>
          )}
        </AccordionSummary>

        <AccordionDetails>
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={12}>
              <TextField
                fullWidth={true}
                label={"Group Name"}
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
            </Grid>
            <Grid item={true} xs={8}>
              <NumberField
                label={"Group Size"}
                value={groupSize}
                onChange={(v) => setGroupSize(v)}
                positive={true}
                min={1}
                max={100}
              />
            </Grid>
            <Grid item={true} xs={4}>
              <NumberField
                label={"Number of Teams"}
                value={numTeams}
                onChange={(v) => setNumTeams(v)}
                positive={true}
                min={1}
                max={10}
              />
            </Grid>

            <Grid item={true} xs={12}>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    color: `rgb(${blue})`,
                    flexGrow: 1,
                    fontFamily: "GoogleSansMedium",
                    fontSize: "0.9rem",
                  }}
                ></div>
                <div
                  style={{
                    color: `rgb(${orange})`,
                    textAlign: "right",
                    fontFamily: "GoogleSansMedium",
                    fontSize: "0.9rem",
                  }}
                ></div>
              </div>
              <Control
                onUpdate={(v) => {
                  if (!Array.isArray(v)) {
                    setRatio(v);
                  }
                }}
                value={ratio}
              />
            </Grid>
          </Grid>

          <Type.Body2 style={{ margin: "24px 0 10px", color: "#1B1D21" }}>
            User Profile Ratio
          </Type.Body2>

          {MMUserProfiles.map((up) => {
            const profile = mmUserProfileMap[up];
            const handleShowUpChange = (arg: { percentage: number }) => {
              profileMetadataChangeFactory(up)(arg);
              reGenerateTeams();
            };
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 8,
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    fontFamily: "GoogleSansRegular",
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "20px",
                    letterSpacing: ".25px",
                  }}
                >
                  {profile.name}
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ width: 93, color: "#1B1D21" }}>
                    <NumberField
                      value={profile.percentage}
                      endAdornment={
                        <InputAdornment position="start">{` %`}</InputAdornment>
                      }
                      onChange={(v) => handleShowUpChange({ percentage: v })}
                      positive={true}
                      min={0}
                      max={100}
                    />
                  </div>
                  <div style={{ marginLeft: 8 }}>
                    <IconButton
                      size="small"
                      onClick={() => setUserProfileDialogOpen(up)}
                    >
                      <MoreVert />
                    </IconButton>
                  </div>
                </div>
              </div>
            );
          })}
          {possibleUserProfileDialog}
          {possibleErrorMain}

          {/* if profiles added together > 100, show error */}
          <MUIButton
            className={classes.next}
            onClick={onContinue}
            disabled={
              sumInputs(
                MMUserProfiles.map((prof) => mmUserProfileMap[prof].percentage)
              ) !== 100
            }
          >
            <div
              style={{
                height: 24,
                textAlign: "center",
                verticalAlign: "middle",
                lineHeight: "24px",
              }}
            >
              Next <KeyboardArrowDown />
            </div>
          </MUIButton>
        </AccordionDetails>
      </Accordion>

      {matchMakerStep === 1 && (
        <Accordion
          expanded={matchMakerStep === 1}
          onChange={onContinue}
          style={{
            flexGrow: matchMakerStep === 1 ? 0 : 1,
            height: "100%",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            Team Makeup
          </AccordionSummary>

          <AccordionDetails>
            {matchMakerTeams.map((team, i) => {
              const newPossibleError = possibleErrorFactorySecondary(
                MMUserProfiles.map((up) => team.userProfilePercentages[up]),
                team.headcount
              );
              const onPercentChange = (up: MMUserProfile) => (v: number) => {
                const newTeam = { ...team };
                const newTeams = [...matchMakerTeams];
                newTeam.userProfilePercentages[up] = v;
                newTeams[newTeams.indexOf(team)] = newTeam;
                if (setMatchMakerTeams) setMatchMakerTeams(newTeams);
              };
              return (
                <SidebarMatchMakerCard
                  newPossibleError={newPossibleError}
                  team={team}
                  updateRow={updateRow}
                  mmUserProfileMap={mmUserProfileMap}
                  i={i}
                  onPercentChange={onPercentChange}
                  editable
                  defaultOpen={true}
                />
              );
            })}
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  );
};

export default SidebarMatchMaker;
