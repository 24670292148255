import { format } from "./utils";
import { shape, Shape } from "./shape";

export type Block = {
  type: "block";
  name: string;
  shapes: Shape[];
};

export const convertBlock = (b: Block): string => {
  // prettier-ignore
  return (
    format("0", "BLOCK") +
    format("2", b.name) +
    format("10", 0) +
    format("20", 0) +
    format("30", 0) +
    format("70", 32) +
    convertBlockShapes(b) +
    format("0", "ENDBLK")
  );
};

export const convertBlockShapes = ({ shapes }: Block): string => {
  return shapes
    .map((s) => {
      const out = shape(s, "0");
      if (out === "") return "";
      return out + format("8", 0);
    })
    .join("");
};
