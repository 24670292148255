import React, { useCallback, useState } from "react";
import { XForm } from "lib/types";
import { getMxMy } from "lib/isp-canvas/utils";
import { Canvas } from "@outerlabs/canvas-reconciler";
import { Graph, GraphNode, removeLineFromGraph } from "lib/metrics/buzz";
import { colors } from "lib/isp-canvas/constants";
import { isMouseOverLine } from "lib/isp-canvas/circulation";
import { renderLine } from "lib/isp-canvas/featureRenderer";

interface Props {
  active: boolean;
  xform: XForm;
  handleRemoveCirculation(graph: Graph): void;
  graph: Graph;
}

export const DeleteCirculation: React.FC<Props> = ({
  xform,
  active,
  handleRemoveCirculation,
  children,
  graph,
}) => {
  const [hover, setHover] = useState<GraphNode[] | undefined>();
  const activeCursor = active ? <cursor cursor="default" /> : null;

  const onClick = useCallback(
    async (e: any) => {
      const { offsetX, offsetY } = e;
      const [mx, my] = getMxMy(offsetX, offsetY, xform);
      const nodes = isMouseOverLine(graph, mx, my);
      if (nodes) removeLineFromGraph(graph, nodes[0], nodes[1]);
      handleRemoveCirculation(graph);
      setHover(undefined);
    },
    [xform, graph, handleRemoveCirculation]
  );

  const onMouseMove: any = useCallback(
    async (e: MouseEvent) => {
      const { offsetX, offsetY } = e;
      const [mx, my] = getMxMy(offsetX, offsetY, xform);
      const nodes = isMouseOverLine(graph, mx, my);
      if (nodes) setHover(nodes);
      else setHover(undefined);

      e.preventDefault();
    },
    [graph, xform]
  );

  const props: any = { type: "DrawInteraction" };
  const eventHandler = active ? (
    <div {...props} onMouseMove={onMouseMove} onMouseDown={onClick} />
  ) : null;
  const canvas = active ? (
    <Canvas
      render={(ctx: CanvasRenderingContext2D) => {
        if (hover) {
          const coords = [
            [hover[0].position.x, hover[0].position.y],
            [hover[1].position.x, hover[1].position.y],
          ];
          renderLine(ctx, coords, colors.selectedRegionColor, 25);
        }
      }}
    />
  ) : null;

  return (
    <>
      {activeCursor}
      {eventHandler}
      {canvas}
      {children}
    </>
  );
};

export default React.memo(DeleteCirculation);
