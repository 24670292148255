import React from "react";
import { RendererSettings } from "lib/types";
import MetricsRow from "./metricsRow";
import MetricsRowHeader from "./metricsRowHeader";
import { useRendererCtrl } from "lib/containers";
import { emptyMetricsProp, getters } from "../../blocks/lib/constants";
import { mergeChildMetrics } from "../../blocks/lib/blocks";
import { formatInt } from "@outerlabs/isp/lib/scenegraph";
import { Block } from "blocks/lib/types";
import {
  getCommunalMetrics,
  getIndividualMetrics,
} from "lib/metrics/wpi-metric-helpers";

interface ZoneMetricsTableProps {
  settings?: RendererSettings;
}

const ZoneMetricsTable: React.FC<ZoneMetricsTableProps> = ({ settings }) => {
  const { selectedRegion } = useRendererCtrl();
  if (selectedRegion === undefined || !settings) return null;

  // get metrics for all selected blocks
  const baseMetrics = { ...emptyMetricsProp };
  let metrics = baseMetrics;
  selectedRegion.forEach((r) => {
    const block = settings.blocks ? settings.blocks[r] : undefined;
    if (block) {
      const mets = getters.getMetrics(block[0]);
      metrics = mergeChildMetrics([metrics, mets]);
    }
  });

  // gets all selected blocks
  const selectedBlocks: Block[][] = [];
  if (selectedRegion && settings && settings.blocks) {
    selectedRegion?.forEach((i) => {
      selectedBlocks.push(settings.blocks[i]);
    });
  }

  const individual = getIndividualMetrics(selectedBlocks);
  const communal = getCommunalMetrics(selectedBlocks);

  return (
    <>
      <MetricsRowHeader firstColumn={"Communal"} secondColumn={"Individual"} />
      <MetricsRow
        helpguideTooltip="Total Seats"
        description={"Total Seats"}
        secondColumn={individual.seats}
        firstColumn={communal.seats}
      />
      <MetricsRow
        helpguideTooltip="Total Headcount"
        description={"Total Workpoints"}
        secondColumn={individual.workpoints}
        firstColumn={communal.workpoints}
      />
      <MetricsRow
        helpguideTooltip="Total Headcount"
        description={"Total Headcount"}
        secondColumn={individual.headcount}
        firstColumn={communal.headcount}
      />
      <MetricsRow
        helpguideTooltip="Area SF"
        description={"Area SF"}
        secondColumn={formatInt(individual.area) + " sf"}
        firstColumn={formatInt(communal.area) + " sf"}
      />
      <MetricsRow
        helpguideTooltip="GSF / Total Seats"
        description={"GSF/Total Seats"}
        secondColumn={formatInt(individual.density) + " SF/HC"}
        firstColumn={formatInt(communal.density) + " SF/HC"}
      />
    </>
  );
};

export default React.memo(ZoneMetricsTable);
