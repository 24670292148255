import React, { ComponentType, FC } from "react";
import { NavBarProvider } from "../containers";

function withNavBarState<Props>(Component: ComponentType<Props>): FC<Props> {
  return (props) => (
    <NavBarProvider>
      <Component {...props} />
    </NavBarProvider>
  );
}

export default withNavBarState;
