import {
  resetRotationAroundCenter,
  setRotationAroundCenter,
} from "./regionRenderer";
import { colors } from "./constants";
import { getRegionLengths } from "./utils";

export const renderAnchors = (
  ctx: CanvasRenderingContext2D,
  region: number[][],
  rotation: number,
  scale: number
) => {
  const [w] = getRegionLengths(region);
  renderAnchor(ctx, region[0][0], region[0][1], region, rotation, scale);
  renderAnchor(ctx, region[1][0], region[1][1], region, rotation, scale);
  renderAnchor(ctx, region[2][0], region[2][1], region, rotation, scale);
  renderAnchor(ctx, region[3][0], region[3][1], region, rotation, scale);
  // rotate anchor
  renderAnchor(
    ctx,
    region[0][0] + w / 2,
    region[0][1] + 100,
    region,
    rotation,
    scale,
    true
  );
};

export const renderAnchor = (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  region: number[][],
  rotation: number,
  scale: number,
  isRotationAnchor?: boolean
) => {
  const r = Math.abs(4 / scale);
  ctx.save();
  if (rotation && rotation !== 0)
    setRotationAroundCenter(ctx, region[1][0], region[1][1], -rotation, 0, 0);
  if (isRotationAnchor) {
    const [w] = getRegionLengths(region);
    ctx.beginPath();
    ctx.strokeStyle = colors.selectedRegionColor;
    ctx.lineWidth = Math.abs(3 / scale);
    ctx.moveTo(x, y);
    ctx.lineTo(region[0][0] + w / 2, region[0][1]);
    ctx.stroke();
  }
  ctx.lineWidth = Math.abs(1 / scale);
  ctx.strokeStyle = colors.selectedRegionColor;
  ctx.fillStyle = "#ffffff";
  ctx.fillRect(x - r, y - r, r * 2, r * 2);
  ctx.strokeRect(x - r, y - r, r * 2, r * 2);
  if (rotation !== 0)
    if (rotation && rotation !== 0)
      resetRotationAroundCenter(
        ctx,
        region[1][0],
        region[1][1],
        -rotation,
        0,
        0
      );
  ctx.restore();
};
