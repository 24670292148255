import React, { ComponentType, FC } from "react";
import { useParams } from "react-router-dom";
import { SettingsProvider } from "lib/containers";

function withSettings<Props>(Component: ComponentType<Props>): FC<Props> {
  const Consumer: React.FC<Props> = (props) => {
    return <Component {...props} />;
  };

  return (props) => {
    const {
      buildingID,
      strategyID,
      floorID,
      id: projectID,
    } = useParams<{ [key: string]: string }>();
    return (
      <SettingsProvider
        initialState={{ floorID, strategyID, buildingID, projectID }}
      >
        <Consumer {...props} />
      </SettingsProvider>
    );
  };
}

export default withSettings;
