import { Metric } from "lib/types";

export enum RoomTypeToSeatCount {
  phoneBooth = 1,
  huddle = 2,
  smallMeetingRoom = 3,
  mediumMeetingRoom = 4,
  largeMeetingRoom = 4,
  extraLargeMeetingRoom = 6,
}

export const FeatureTypeToSeatCount: Record<string, number> = {
  phone: 1,
  huddle: 2,
  small: 3,
  medium: 4,
  large: 4,
  extraLarge: 6,
  interview: 1,
};

export const calculatePotentialSeats = (
  type: string,
  metric: Metric,
  count?: number
): number => {
  // if there is a count use that to get the seat count instead of the default
  switch (type) {
    case "phone":
    case "phoneBooths":
      return count
        ? count
        : metric.phone
        ? metric.phone.count
        : metric.phoneBooths
        ? metric.phoneBooths
        : 0;
    case "huddle":
      return count
        ? count * RoomTypeToSeatCount.huddle
        : metric.huddle.count * RoomTypeToSeatCount.huddle;
    case "small":
      return count
        ? count * RoomTypeToSeatCount.smallMeetingRoom
        : metric.small.count * RoomTypeToSeatCount.smallMeetingRoom;
    case "medium":
      return count
        ? count * RoomTypeToSeatCount.mediumMeetingRoom
        : metric.medium.count * RoomTypeToSeatCount.mediumMeetingRoom;
    case "large":
      return count
        ? count * RoomTypeToSeatCount.largeMeetingRoom
        : metric.large.count * RoomTypeToSeatCount.largeMeetingRoom;
    case "extraLarge":
      return count
        ? count * RoomTypeToSeatCount.extraLargeMeetingRoom
        : metric.extraLarge.count * RoomTypeToSeatCount.extraLargeMeetingRoom;
    case "potentialSeats":
      return (
        (metric.phone?.count || 0) +
        (metric.huddle?.count || 0) * RoomTypeToSeatCount.huddle +
        (metric.small?.count || 0) * RoomTypeToSeatCount.smallMeetingRoom +
        (metric.medium?.count || 0) * RoomTypeToSeatCount.mediumMeetingRoom +
        (metric.large?.count || 0) * RoomTypeToSeatCount.largeMeetingRoom +
        (metric.extraLarge?.count || 0) *
          RoomTypeToSeatCount.extraLargeMeetingRoom
      );
    default:
      return 0;
  }
};

export default calculatePotentialSeats;
