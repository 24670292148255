import React from "react";
import { NavGroup, NavButton } from "@outerlabs/ol-ui";
import ViewModuleOutlinedIcon from "@material-ui/icons/ViewModuleOutlined";
import WorkOutlineOutlinedIcon from "@material-ui/icons/WorkOutlineOutlined";
import BusinessOutlinedIcon from "@material-ui/icons/BusinessOutlined";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import { useHistory, useParams } from "react-router-dom";
import { NavTabs } from "lib/types";

interface Props {
  navTab: NavTabs;
}

const SideBarNavGroup: React.FC<Props> = ({ navTab }) => {
  const history = useHistory();
  const { id } = useParams<{ [key: string]: string }>();

  return (
    <NavGroup>
      <NavButton
        tooltipTitle="Home"
        selected={navTab === NavTabs.Home}
        onClick={() => {
          history.push(`/`);
        }}
      >
        <ViewModuleOutlinedIcon />
      </NavButton>
      <NavButton
        tooltipTitle="Strategies"
        disabled={navTab === NavTabs.Home}
        selected={navTab === NavTabs.Strategy}
        onClick={() => {
          history.push(`/${id}/strategies`);
        }}
      >
        <WorkOutlineOutlinedIcon />
      </NavButton>
      <NavButton
        disabled={
          navTab === NavTabs.Home ||
          navTab === NavTabs.Strategy ||
          navTab === NavTabs.Zone
        }
        tooltipTitle="Performance"
        selected={navTab === NavTabs.Performance}
      >
        <BusinessOutlinedIcon />
      </NavButton>
      <NavButton
        selected={navTab === NavTabs.Zone}
        disabled={navTab !== NavTabs.Zone}
        tooltipTitle="Zone"
      >
        <DashboardOutlinedIcon />
      </NavButton>
    </NavGroup>
  );
};

export default SideBarNavGroup;
