import React, { useCallback, useState } from "react";
import { XForm } from "lib/types";
import { getMxMy } from "lib/isp-canvas/utils";
import { Canvas } from "@outerlabs/canvas-reconciler";
import {
  Graph,
  GraphNode,
  removeLineFromGraph,
  addLineToGraph,
  NodeType,
} from "lib/metrics/buzz";
import { colors } from "lib/isp-canvas/constants";
import { isMouseOverLine, isMouseOverNode } from "lib/isp-canvas/circulation";
import { v4 as uuid4 } from "uuid";
import { renderLine } from "lib/isp-canvas/featureRenderer";

interface Props {
  active: boolean;
  xform: XForm;
  handleEditCirculation(graph: Graph): void;
  graph: Graph;
}

export const EditCirculation: React.FC<Props> = ({
  xform,
  active,
  children,
  graph,
  handleEditCirculation,
}) => {
  const [focus, setFocus] = useState<GraphNode | false>();
  const [lineFocus, setLineFocus] = useState<GraphNode[] | false>();
  const [targetNode, setTargetNode] = useState<GraphNode | false>(false);
  const [start, setStart] = useState<GraphNode | false>(false);
  const [lineStart, setLineStart] = useState<GraphNode[] | false>(false);
  const [lineUpdate, setLineUpdate] = useState<GraphNode[] | false>(false);
  const [translation, setTranslation] = useState<number[] | false>(false);
  const activeCursor = active ? <cursor cursor="default" /> : null;

  // function to check if your node is on your currently selected line
  const lineCheck = (line: GraphNode[], endNode: GraphNode) => {
    let bool = false;
    line.forEach((node) => {
      if (endNode.id === node.id) {
        bool = true;
      }
    });

    return bool;
  };

  const onClick = useCallback(
    async (e: any) => {
      const { offsetX, offsetY } = e;
      const [mx, my] = getMxMy(offsetX, offsetY, xform); //gets position of your cursor
      const endNode = isMouseOverNode(graph, mx, my); // gets node you've clicked, if over a node
      const nodes = isMouseOverLine(graph, mx, my); // gets details of line clicked, if over a line
      let node: GraphNode | undefined = undefined; // type handling, solve issues with passing undefined variables around
      let line: GraphNode[] | undefined = undefined; // type handling, solve issues with passing undefined variables around
      let originNode: GraphNode | undefined = undefined; // type handling, solve issues with passing undefined variables around

      if (endNode) node = endNode; // type handling, solve issues with passing undefined variables around
      if (nodes) line = nodes; // type handling, solve issues with passing undefined variables around

      if (endNode && lineStart) {
        // identify other node on the selected line
        lineStart.find((_node) => {
          // find the node that we will be drawing from
          if (_node.id !== endNode.id) {
            originNode = _node;
            setStart(originNode);
            return true;
          } else return false;
        });
      }

      // first click handler (selected the line you want to somehow edit)
      if (line && lineStart === false) {
        // if you're over a line when you click but haven't selected anything yet
        setLineStart(line); // select the line as the one you are going to modify
      }

      // second click handler (selected a node on the line)
      if (lineStart && node && lineCheck(lineStart, node)) {
        // if a line has been selected, and a the node you click is on the line
        setTargetNode(node); // set targetNode as the node
      }

      // third click handler (clicked a spot to drop your updated node)
      if (targetNode && start) {
        // if you have selected a node
        removeLineFromGraph(graph, targetNode, start); // remove the line you're editing
        let newNode: GraphNode;
        if (translation) {
          newNode = {
            id: uuid4(),
            position: {
              x: translation[0],
              y: translation[1],
              z: 0,
            },
            type: NodeType.Intersection,
            connectsTo: [],
          };
          addLineToGraph(graph, start, newNode);
        }
        handleEditCirculation(graph);
        setFocus(false);
        setLineStart(false);
        setLineFocus(false);
        setTranslation(false);
        setTargetNode(false);
        // second click handler (you actually do want to move this line)
      } else if (lineStart && line && !node) {
        setLineUpdate(lineStart); // lineUpdate is used to track the line's new placement
        removeLineFromGraph(graph, line[0], line[1]); // delete the existing line from the table
        handleEditCirculation(graph); // update the graph in settings
        setLineFocus(false);
        setLineStart(false);
      }
      // third click (you've moved the whole line to a new spot)
      if (lineUpdate) {
        const lineLengthX = lineUpdate[1].position.x - lineUpdate[0].position.x; // calculate the new line X delta
        const lineLengthY = lineUpdate[1].position.y - lineUpdate[0].position.y; // calculate the new line Y delta
        if (translation) {
          const coords = [
            [
              translation[0] + lineLengthX / 2,
              translation[1] + lineLengthY / 2,
            ],
            [
              translation[0] - lineLengthX / 2,
              translation[1] - lineLengthY / 2,
            ],
          ];
          const newNodeA = {
            id: uuid4(),
            position: {
              x: coords[0][0],
              y: coords[0][1],
              z: 0,
            },
            type: NodeType.Intersection,
            connectsTo: [],
          };
          const newNodeB = {
            id: uuid4(),
            position: {
              x: coords[1][0],
              y: coords[1][1],
              z: 0,
            },
            type: NodeType.Intersection,
            connectsTo: [],
          };
          addLineToGraph(graph, newNodeA, newNodeB);
          handleEditCirculation(graph);
        } else {
          addLineToGraph(graph, lineUpdate[0], lineUpdate[1]);
          handleEditCirculation(graph);
        }
        setLineUpdate(false);
        setTranslation(false);
      }
    },
    [
      xform,
      graph,
      start,
      lineStart,
      translation,
      targetNode,
      lineUpdate,
      handleEditCirculation,
    ]
  );

  const onMouseMove: any = useCallback(
    async (e: MouseEvent) => {
      const { offsetX, offsetY } = e; // gets offset of cursor from the mouse move event
      const [mx, my] = getMxMy(offsetX, offsetY, xform); // converts event coordinates to [mx, my] values, which are useable in other functions
      const endNode = isMouseOverNode(graph, mx, my); // detect for hover coloring, are you over a node?
      const nodes = isMouseOverLine(graph, mx, my); // detect for hover coloring, are you over a line?

      // control focus line render
      if (nodes && lineStart === false) {
        // if you are holding your mouse over a line, without having selected a line already
        setLineFocus(nodes); // set the hover state to the line
      } else if (lineStart !== false) {
        // if you've selected a line already
        setLineFocus(lineStart); // line is highlighted as being selected
      } else {
        // you didn't click a line, and you're not hovering over anything
        setLineFocus(false);
      }

      // control focus point render
      if (lineStart !== false && endNode && lineCheck(lineStart, endNode)) {
        // if you've selected a line and are over a node
        setFocus(endNode); // set the hover state to the node
      } else if (targetNode) {
        setFocus(targetNode);
      } else {
        // if you get off the node
        setFocus(false); // bye bye node
      }

      if (targetNode || lineUpdate) {
        // if currently moving a point or a line, start tracking translation
        setTranslation([mx, my]);
      }

      e.preventDefault();
    },
    [graph, xform, lineStart, targetNode, lineUpdate]
  );

  const props: any = { type: "DrawInteraction" };
  const eventHandler = active ? (
    <div {...props} onMouseMove={onMouseMove} onMouseDown={onClick} />
  ) : null;
  const canvas = active ? (
    <Canvas
      render={(ctx: CanvasRenderingContext2D) => {
        // what happens while moving a point
        if (targetNode && start && translation) {
          ctx.save();
          ctx.beginPath();
          ctx.lineWidth = 20;
          ctx.strokeStyle = `rgb(0,230,0)`;
          ctx.moveTo(start.position.x, start.position.y);
          ctx.lineTo(translation[0], translation[1]);
          ctx.stroke();
          ctx.restore();
        }
        // what happens while moving a line
        if (lineUpdate) {
          const lineLengthX =
            lineUpdate[1].position.x - lineUpdate[0].position.x;
          const lineLengthY =
            lineUpdate[1].position.y - lineUpdate[0].position.y;
          if (!translation) {
            const realCoords = [
              [lineUpdate[0].position.x, lineUpdate[0].position.y],
              [lineUpdate[1].position.x, lineUpdate[1].position.y],
            ];
            renderLine(ctx, realCoords, `rgb(0,230,0)`, 25);
          } else if (translation) {
            const coords = [
              [
                translation[0] + lineLengthX / 2,
                translation[1] + lineLengthY / 2,
              ],
              [
                translation[0] - lineLengthX / 2,
                translation[1] - lineLengthY / 2,
              ],
            ];
            renderLine(ctx, coords, `rgb(0,230,0)`, 25);
          }
        }

        // what happens while over a node
        if (focus) {
          const r = 40;
          ctx.save();
          ctx.fillStyle = colors.selectedRegionColor;
          ctx.fillRect(
            focus.position.x - r / 2,
            focus.position.y - r / 2,
            r,
            r
          );
          ctx.restore();
        }
        // what happens while over a line
        if (lineFocus && lineUpdate === false) {
          const coords = [
            [lineFocus[0].position.x, lineFocus[0].position.y],
            [lineFocus[1].position.x, lineFocus[1].position.y],
          ];
          renderLine(ctx, coords, colors.selectedRegionColor, 25);
        }
      }}
    />
  ) : null;

  return (
    <>
      {activeCursor}
      {eventHandler}
      {canvas}
      {children}
    </>
  );
};

export default React.memo(EditCirculation);
