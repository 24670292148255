import { Background, Drawable, ElementType } from "lib/types";
import { degToRadian } from "lib/isp-canvas/utils";

const backgroundRenderer = (
  ctx: CanvasRenderingContext2D,
  bg: Background,
  offsetX: number,
  offsetY: number
) => {
  ctx.save();
  ctx.lineWidth = 2;
  ctx.strokeStyle = `rgba(0,0,0,1)`;
  bg.elements.forEach((ele) =>
    renderDrawable(ctx, ele, bg.inserts, offsetX, offsetY)
  );
  ctx.restore();
};

const renderDrawable = (
  ctx: CanvasRenderingContext2D,
  d: Drawable,
  inserts: { [key: string]: Drawable[] },
  offsetX: number,
  offsetY: number
) => {
  switch (d.type) {
    case ElementType.Line:
    case ElementType.LWPolyline:
      renderLine(ctx, d, offsetX, offsetY);
      break;
    case ElementType.Insert:
      renderInsert(ctx, d, inserts, offsetX, offsetY);
      break;
    case ElementType.Arc:
      renderArc(ctx, d, offsetX, offsetY);
      break;
    case ElementType.Circle:
      renderCircle(ctx, d, offsetX, offsetY);
      break;
    case ElementType.Ellipse:
      renderEllipse(ctx, d, offsetX, offsetY);
      break;
    case ElementType.Spline:
      renderSpline(ctx, d, offsetX, offsetY);
      break;
  }
};

const renderLine = (
  ctx: CanvasRenderingContext2D,
  d: Drawable,
  offsetX: number,
  offsetY: number
) => {
  if (!d.coordinates?.[0]) {
    // NOTE: Entity processor returns empty coordinates for points
    return;
  }
  ctx.beginPath();
  if (d.coordinates[0].bulge) {
    if (d.coordinates.length < 4) {
      // find the average of all points
      const avgX =
        d.coordinates.reduce((acc, cur) => acc + cur.x, 0) /
        d.coordinates.length;
      const avgY =
        d.coordinates.reduce((acc, cur) => acc + cur.y, 0) /
        d.coordinates.length;
      // find the average distance between the center and the points. works better than picking a point.
      const radius =
        d.coordinates.reduce(
          (acc, cur) =>
            acc +
            Math.sqrt(Math.pow(cur.x - avgX, 2) + Math.pow(cur.y - avgY, 2)),
          0
        ) / d.coordinates.length;
      ctx.ellipse(
        avgX + offsetX,
        avgY + offsetY,
        radius,
        radius,
        0,
        0,
        2 * Math.PI
      );
      ctx.stroke();
      return;
    }
  }
  ctx.moveTo(d.coordinates[0].x + offsetX, d.coordinates[0].y + offsetY);
  for (let i = 1; i < d.coordinates.length; i++) {
    // TODO(JV): account for bulge
    ctx.lineTo(d.coordinates[i].x + offsetX, d.coordinates[i].y + offsetY);
  }
  ctx.stroke();
};

const renderSpline = (
  ctx: CanvasRenderingContext2D,
  d: Drawable,
  offsetX: number,
  offsetY: number
) => {
  if (!d.coordinates?.[0]) {
    // NOTE: Entity processor returns empty coordinates for points
    return;
  }
  ctx.beginPath();
  ctx.lineWidth = 3;
  ctx.moveTo(d.coordinates[0].x + offsetX, d.coordinates[0].y + offsetY);
  d.coordinates.forEach((c) => ctx.lineTo(c.x + offsetX, c.y + offsetY));
  ctx.lineWidth = 1;
  ctx.stroke();
};

const renderArc = (
  ctx: CanvasRenderingContext2D,
  d: Drawable,
  offsetX: number,
  offsetY: number
) => {
  if (!d.coordinates.length) return;
  const { x, y, angle, endAngle, radius } = d.coordinates[0];

  ctx.beginPath();
  ctx.arc(
    x + offsetX,
    y + offsetY,
    or0(radius),
    degToRadian(or0(angle)),
    degToRadian(or0(endAngle)),
    false
  );
  ctx.stroke();
};

const renderCircle = (
  ctx: CanvasRenderingContext2D,
  d: Drawable,
  offsetX: number,
  offsetY: number
) => {
  if (!d.coordinates.length) return;
  const { x, y, radius } = d.coordinates[0];

  ctx.beginPath();
  ctx.arc(x + offsetX, y + offsetY, Math.max(0, or0(radius)), 0, 2 * Math.PI);
  ctx.stroke();
};

const renderEllipse = (
  ctx: CanvasRenderingContext2D,
  d: Drawable,
  offsetX: number,
  offsetY: number
) => {
  if (!d.coordinates.length) return;
  const { x, y, radiusX, radiusY, angle, endAngle, rotation } =
    d.coordinates[0];

  ctx.beginPath();
  ctx.ellipse(
    x + offsetX,
    y + offsetY,
    radiusX || 0,
    radiusY || 0,
    rotation || 0,
    angle || 0,
    endAngle || 0
  );
  ctx.stroke();
};

const or0 = (n: number | undefined): number => n || 0;

const renderInsert = (
  ctx: CanvasRenderingContext2D,
  d: Drawable,
  inserts: { [key: string]: Drawable[] },
  offsetX: number,
  offsetY: number
) => {
  if (d.insertName.includes("Mullion")) return;
  if (d.insertName.includes("A-FLOR-SPCL-E")) return;
  if (d.insertName.includes("A-FLOR-FIXT-E:kohlerverticycl")) return;

  if (!d.coordinates[0]) {
    console.error("d", d.insertName);
    return;
  }

  const { x, y, angle, scaleX, scaleY } = d.coordinates[0];
  const elements = inserts[d.insertName];

  if (!elements) {
    return;
  }

  ctx.save();
  ctx.translate(x + offsetX, y + offsetY);
  ctx.rotate(degToRadian(or0(angle)));
  if (scaleX !== 0 || scaleY !== 0) {
    ctx.scale(scaleX || 1, scaleY || 1);
    if (scaleX && scaleY && scaleX !== 0 && scaleY !== 0) {
      const currWidth = ctx.lineWidth;
      const lineWidthX = currWidth * scaleX;
      const lineWidthY = currWidth * scaleY;
      const avgLineWidth = (lineWidthX + lineWidthY) / 2;
      ctx.lineWidth = ctx.lineWidth / avgLineWidth;
    }
  }
  elements.forEach((ele) => renderDrawable(ctx, ele, inserts, 0, 0));

  ctx.restore();
};

export default backgroundRenderer;
