import React, { ComponentType, FC } from "react";
import { BlockInstanceProvider, ISPTransformProvider } from "lib/containers";

function withBlockInstances<Props>(Component: ComponentType<Props>): FC<Props> {
  const Consumer: React.FC<Props> = (props) => {
    return <Component {...props} />;
  };

  return (props) => {
    return (
      <ISPTransformProvider>
        <BlockInstanceProvider>
          <Consumer {...props} />
        </BlockInstanceProvider>
      </ISPTransformProvider>
    );
  };
}

export default withBlockInstances;
