import React, { ChangeEvent } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import { deleteBlock, findBlock, updateBlockProps } from "../lib/blocks";
import {
  Button,
  FormControl,
  FormLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Slider,
} from "@material-ui/core";
import { useBlocksCtrl } from "lib/containers";
import Grid from "@material-ui/core/Grid";
import { emptyDivider, emptyMetricsProp, getters } from "../lib/constants";
import NumberField from "./number-field";
import { Accordion, AccordionSummary, AccordionDetails } from "./accordion";
import BlockLibrary from "./block-library";
import { formatToInt } from "../../lib/number";

export interface Props {
  onChangeBlock: (id: string) => void;
  onSelect: (id: string) => void;
}

const useStyles = makeStyles(() => ({
  label: {
    padding: "12px 0 2px 0",
    color: "rgba(0,0,0,0.6)",
    fontSize: 14,
  },
  container1: {
    padding: "12px 0 4px 16px",
  },
  container2: {
    display: "flex",
    flexDirection: "row",
  },
  container3: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 12,
  },
  text: {
    paddingBottom: 18,
  },
  row: {
    marginBottom: 12,
  },
  input: {
    background: "#F4F4F6",
    borderRadius: "4px",
    paddingLeft: "8px",
  },
  subtitle: {
    fontSize: "14px",
    marginBottom: "6px",
    color: "#444",
  },
  details: {
    borderTop: "1px solid #D5D8DC",
    display: "flex",
    flexDirection: "column",
    padding: "8px 24px 24px",
    overflow: "hidden",
  },
  detailsTitle: {
    margin: "8px 0 16px 0",
  },
  miniKPI: {
    display: "flex",
  },
  miniKPITitle: {
    fontSize: "0.9rem",
    flexGrow: 1,
  },
  miniKPIValue: {
    fontSize: "0.9rem",
    color: "rgba(0,0,0,0.6)",
  },
}));

const verticalAlignmentOptions = [
  { value: "min", label: "Top" },
  { value: "center", label: "Center" },
  { value: "max", label: "Bottom" },
];

const horizontalAlignmentOptions = [
  { value: "min", label: "Left" },
  { value: "center", label: "Center" },
  { value: "max", label: "Right" },
];

const rotationOptions = [
  { value: 0, label: "None" },
  { value: 90, label: "90 deg" },
  { value: 180, label: "180 deg" },
  { value: 270, label: "270 deg" },
  { value: 1, label: "Custom" },
];

const directionOptions = [
  { value: "horizontal", label: "Horizontal" },
  { value: "vertical", label: "Vertical" },
];

const sideOptions = [
  { value: "none", label: "None" },
  { value: "wall", label: 'Wall 6"' },
  { value: "wall8", label: 'Wall 8"' },
  { value: "wall10", label: 'Wall 10"' },
  { value: "wall12", label: 'Wall 12"' },
  { value: "partition", label: 'Partition 4"' },
  { value: "partition6", label: 'Partition 6"' },
  { value: "partition8", label: 'Partition 8"' },
  { value: "partition10", label: 'Partition 10"' },
  { value: "planter10x30", label: "Planter Narrow Small" },
  { value: "planter10x60", label: "Planter Narrow Large" },
  { value: "planter20x99", label: "Planter Small" },
  { value: "planter20x132", label: "Planter Large" },
  { value: "glazing", label: "Glazing" },
  { value: "lockers", label: 'Lockers 12"' },
];

const sideModeOptions = [
  { value: "auto", label: "Stretch" },
  { value: "fixed", label: "Fixed" },
];

const sideAlignOptions = [
  { value: "min", label: "Start" },
  { value: "center", label: "Center" },
  { value: "max", label: "End" },
];

const SidebarBlockControls: React.FC<Props> = ({ onChangeBlock, onSelect }) => {
  const [expanded, setExpanded] = React.useState<string | false>("settings");
  const onAccordionChange =
    (panel: string) => (event: ChangeEvent<any>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  const classes = useStyles();
  const {
    activeBlock: block,
    saveBlock,
    activeSubBlock,
    getBlockById,
  } = useBlocksCtrl();

  if (!block) return null;
  const root = block.children[0];
  if (!root) return null;
  const activeBlock = findBlock(block, activeSubBlock);
  let repeat = [1, 1];
  let spacing = 0;
  let primaryAlign = "min";
  let crossAlign = "min";
  let mode = "horizontal";
  let rotation = 0;
  let padding = [0, 0, 0, 0];
  let dividers = [
    { ...emptyDivider },
    { ...emptyDivider },
    { ...emptyDivider },
    { ...emptyDivider },
  ];
  let grow = 0;
  if (activeBlock) {
    const activeBlockLayout = getters.getLayout(activeBlock);
    const activeBlockSides = getters.getSides(activeBlock);
    const activeBlockConstraints = getters.getConstraints(activeBlock);
    repeat = activeBlockLayout.flex?.repeat || repeat;
    spacing = activeBlockLayout.flex?.spacing || spacing;
    primaryAlign = activeBlockLayout.flex?.primaryAlign || primaryAlign;
    crossAlign = activeBlockLayout.flex?.crossAlign || crossAlign;
    mode = activeBlockLayout.flex?.mode || mode;
    padding = activeBlockLayout.padding || padding;
    rotation = activeBlockLayout.rotation || rotation;
    dividers = activeBlockSides.dividers || dividers;
    grow = activeBlockConstraints.grow || 0;
  }

  const onSpacingChange = (value: number) => {
    const found = findBlock(block, activeSubBlock);
    if (found) {
      const layout = getters.getLayout(found);
      const id = activeSubBlock;
      if (found.props.layout) {
        const copy = {
          layout: { ...layout, flex: { ...layout.flex, spacing: value } },
        };
        const newBlock = updateBlockProps(block, id, copy);
        saveBlock(newBlock);
      }
    }
  };
  const onDelete = () => {
    const deleted = deleteBlock(block, activeSubBlock);
    onSelect("");
    saveBlock(deleted);
  };

  const updateBlockLayout = (name: string) => (value: any) => {
    const found = findBlock(block, activeSubBlock);
    if (found) {
      const layout = getters.getLayout(found);
      const id = activeSubBlock;
      if (found.props.layout) {
        const copy = { layout: { ...layout, [name]: value } };
        const newBlock = updateBlockProps(block, id, copy);
        saveBlock(newBlock);
      }
    }
  };
  const updateBlockFlex = (name: string) => (value: any) => {
    const found = findBlock(block, activeSubBlock);
    if (found) {
      const layout = getters.getLayout(found);
      const id = activeSubBlock;
      if (found.props.layout) {
        const copy = {
          layout: { ...layout, flex: { ...layout.flex, [name]: value } },
        };
        const newBlock = updateBlockProps(block, id, copy);
        saveBlock(newBlock);
      }
    }
  };
  const updateBlockConstraints = (name: string) => (value: any) => {
    const found = findBlock(block, activeSubBlock);
    if (found) {
      const constraints = getters.getConstraints(found);
      const id = activeSubBlock;
      const copy = { constraints: { ...constraints, [name]: value } };
      const newBlock = updateBlockProps(block, id, copy);
      saveBlock(newBlock);
    }
  };

  const updateBlockDivider = (name: string) => (side: number, value: any) => {
    const found = findBlock(block, activeSubBlock);
    if (found) {
      const sides = getters.getSides(found);
      const id = activeSubBlock;
      const _dividers = sides.dividers
        ? [...sides.dividers]
        : [
            { ...emptyDivider },
            { ...emptyDivider },
            { ...emptyDivider },
            { ...emptyDivider },
          ];
      _dividers[side] = { ..._dividers[side], [name]: value };
      const copy = { sides: { ...sides, dividers: _dividers } };
      const newBlock = updateBlockProps(block, id, copy as any);
      saveBlock(newBlock);
    }
  };
  const onGrowChange = updateBlockConstraints("grow");
  const onDividerTypeChange = updateBlockDivider("type");
  const onDividerModeChange = updateBlockDivider("mode");
  const onDividerStartChange = updateBlockDivider("start");
  const onDividerEndChange = updateBlockDivider("end");
  const onDividerSizeChange = updateBlockDivider("size");
  const onDividerAlignChange = updateBlockDivider("align");
  const onPrimaryAlignChange = updateBlockFlex("primaryAlign");
  const onCrossAlignChange = updateBlockFlex("crossAlign");
  const onRepeatChange = updateBlockFlex("repeat");
  const onRotationChange = updateBlockLayout("rotation");
  const onModeChange = updateBlockFlex("mode");
  const selectedSubBlock = findBlock(block, activeSubBlock);
  const onPaddingChange = (side: number, value: number) => {
    const found = findBlock(block, activeSubBlock);
    if (found) {
      const layout = getters.getLayout(found);
      const id = activeSubBlock;
      if (found.props.layout) {
        const _padding: [number, number, number, number] = layout.padding
          ? ([...layout.padding] as [number, number, number, number])
          : [0, 0, 0, 0];
        _padding[side] = value;
        const copy = { layout: { ...layout, padding: _padding } };
        const newBlock = updateBlockProps(block, id, copy);
        saveBlock(newBlock);
      }
    }
  };

  const isLeafBlock =
    selectedSubBlock && selectedSubBlock.children.length === 0;
  let name = "";
  let metrics = { ...emptyMetricsProp };
  let seats = "0";
  let density = "0 SF/HC";
  let area = "0 SF";
  let workpoints = "0";
  let assignedSeats = "0";
  let type = "focus";
  if (isLeafBlock) {
    const ref = getBlockById(selectedSubBlock?.symbol);
    if (selectedSubBlock) {
      metrics = getters.getMetrics(selectedSubBlock);
      seats =
        metrics.seatsRange[0] === metrics.seatsRange[1]
          ? metrics.seatsRange[0] + ""
          : metrics.seatsRange.join(" - ");
      workpoints =
        metrics.workpointsRange[0] === metrics.workpointsRange[1]
          ? metrics.workpointsRange[0] + ""
          : metrics.workpointsRange.join(" - ");
      assignedSeats =
        metrics.headcountRange[0] === metrics.headcountRange[1]
          ? metrics.headcountRange[0] + ""
          : metrics.headcountRange.join(" - ");
      area =
        (metrics.areaRange[0] === metrics.areaRange[1]
          ? formatToInt(metrics.areaRange[0] / 144) + ""
          : metrics.areaRange.map((el) => formatToInt(el / 144)).join(" - ")) +
        " sf";
      const densityValue =
        metrics.headcountRange[0] === 0
          ? [0, 0]
          : [
              metrics.areaRange[0] / 144 / metrics.headcountRange[0],
              metrics.areaRange[1] / 144 / metrics.headcountRange[1],
            ];
      density =
        (densityValue[0] === densityValue[1]
          ? formatToInt(densityValue[0])
          : densityValue.map((el) => formatToInt(el)).join(" - ")) + " SF/HC";
    }
    if (ref) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      name = ref.props.definition!.name || "";
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      type = ref.props.definition!.type || "";
    }
  }
  const dontDelete = selectedSubBlock?.id === block.id;

  return (
    <>
      {/*{block.role === "block" && <BlockMetrics />}*/}
      {isLeafBlock && (
        <div className={classes.details}>
          <div className={classes.detailsTitle}>{name}</div>

          <Grid container={true} spacing={4} justifyContent="space-between">
            <Grid item={true} xs={6}>
              <div className={classes.miniKPI}>
                <div className={classes.miniKPITitle}>Type</div>
                <div className={classes.miniKPIValue}>{type}</div>
              </div>
              <div className={classes.miniKPI}>
                <div className={classes.miniKPITitle}>Area</div>
                <div className={classes.miniKPIValue}>{area}</div>
              </div>
              <div className={classes.miniKPI}>
                <div className={classes.miniKPITitle}>Density</div>
                <div className={classes.miniKPIValue}>{density}</div>
              </div>
            </Grid>
            <Grid item={true} xs={6}>
              <div className={classes.miniKPI}>
                <div className={classes.miniKPITitle}>Seats</div>
                <div className={classes.miniKPIValue}>{seats}</div>
              </div>
              <div className={classes.miniKPI}>
                <div className={classes.miniKPITitle}>Workpoints</div>
                <div className={classes.miniKPIValue}>{workpoints}</div>
              </div>
              <div className={classes.miniKPI}>
                <div className={classes.miniKPITitle}>Assigned Seats</div>
                <div className={classes.miniKPIValue}>{assignedSeats}</div>
              </div>
            </Grid>
          </Grid>
        </div>
      )}
      <Accordion
        expanded={expanded === "settings"}
        onChange={onAccordionChange("settings")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          Settings
        </AccordionSummary>
        <AccordionDetails>
          {isLeafBlock && (
            <>
              <div className={classes.subtitle}>Repeat and spacing</div>
              <Grid container={true} spacing={2} className={classes.row}>
                <Grid item={true} xs={3}>
                  <NumberField
                    label={"Min Num"}
                    value={repeat[0]}
                    onChange={(v) => onRepeatChange([v, repeat[1]])}
                    positive={true}
                    min={1}
                    max={10}
                  />
                </Grid>
                <Grid item={true} xs={3}>
                  <NumberField
                    label={"Max Num"}
                    value={repeat[1]}
                    onChange={(v) => onRepeatChange([repeat[0], v])}
                    positive={true}
                    min={1}
                    max={10}
                  />
                </Grid>
                <Grid item={true} xs={3}>
                  <NumberField
                    label={"Spacing"}
                    value={spacing}
                    onChange={onSpacingChange}
                    positive={true}
                    endAdornment={
                      <InputAdornment position={"end"}>in</InputAdornment>
                    }
                  />
                </Grid>
                <Grid item={true} xs={3}>
                  <NumberField
                    label={"Grow"}
                    value={grow}
                    onChange={onGrowChange}
                    positive={true}
                    min={0}
                    max={5}
                  />
                </Grid>
              </Grid>
            </>
          )}

          <div className={classes.subtitle}>Direction and alignment</div>
          <Grid container={true} spacing={2} className={classes.row}>
            <Grid item={true} xs={3}>
              <FormControl fullWidth={true}>
                <InputLabel>Direction</InputLabel>
                <Select
                  disableUnderline={true}
                  value={mode}
                  label="Direction"
                  onChange={(e) => onModeChange(e.target.value)}
                  className={classes.input}
                >
                  {directionOptions.map((el) => (
                    <MenuItem key={el.value} value={el.value}>
                      {el.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item={true} xs={3}>
              <FormControl fullWidth={true}>
                <InputLabel>Horizontal</InputLabel>
                <Select
                  disableUnderline={true}
                  className={classes.input}
                  value={mode === "horizontal" ? primaryAlign : crossAlign}
                  label="Horizontal Alignment"
                  onChange={(e) =>
                    mode === "horizontal"
                      ? onPrimaryAlignChange(e.target.value)
                      : onCrossAlignChange(e.target.value)
                  }
                >
                  {horizontalAlignmentOptions.map((el) => (
                    <MenuItem key={el.value} value={el.value}>
                      {el.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item={true} xs={3}>
              <FormControl fullWidth={true}>
                <InputLabel>Vertical</InputLabel>
                <Select
                  disableUnderline={true}
                  className={classes.input}
                  value={mode === "vertical" ? primaryAlign : crossAlign}
                  label="Vertical Alignment"
                  onChange={(e) =>
                    mode === "vertical"
                      ? onPrimaryAlignChange(e.target.value)
                      : onCrossAlignChange(e.target.value)
                  }
                >
                  {verticalAlignmentOptions.map((el) => (
                    <MenuItem key={el.value} value={el.value}>
                      {el.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item={true} xs={3}>
              {isLeafBlock ? (
                <>
                  <FormControl fullWidth={true}>
                    <InputLabel>Rotation</InputLabel>
                    <Select
                      disableUnderline={true}
                      value={rotation}
                      label="Rotation"
                      onChange={(e) => onRotationChange(e.target.value)}
                      className={classes.input}
                    >
                      {rotationOptions.map((el) => (
                        <MenuItem key={el.value} value={el.value}>
                          {el.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              ) : (
                <NumberField
                  label={"Grow"}
                  value={grow}
                  onChange={onGrowChange}
                  positive={true}
                  min={0}
                  max={5}
                />
              )}
            </Grid>
            {rotation % 90 !== 0 && (
              <Grid item={true} xs={12}>
                {isLeafBlock && (
                  <FormControl fullWidth={true}>
                    <div
                      style={{
                        position: "absolute",
                        right: 0,
                        color: "rgba(0, 0, 0, 0.54)",
                        fontSize: "0.9rem",
                      }}
                    >
                      {rotation} deg
                    </div>
                    <FormLabel>Rotation</FormLabel>
                    <Slider
                      value={rotation}
                      min={0}
                      max={359}
                      step={1}
                      onChange={(e, v) =>
                        !Array.isArray(v) &&
                        onRotationChange(v % 90 === 0 ? v + 1 : v)
                      }
                    />
                  </FormControl>
                )}
              </Grid>
            )}
          </Grid>
          <div className={classes.subtitle}>Offset</div>
          <Grid container={true} spacing={2}>
            {["Top", "Right", "Bottom", "Left"].map((side, i) => (
              <Grid key={i} item={true} xs={3}>
                <NumberField
                  label={side}
                  value={padding[i]}
                  onChange={(v) => onPaddingChange(i, Math.max(v || 0, 0))}
                  endAdornment={
                    <InputAdornment position={"end"}>in</InputAdornment>
                  }
                />
              </Grid>
            ))}
          </Grid>
          {!dontDelete && (
            <div
              style={{ display: "flex", justifyContent: "end", marginTop: 24 }}
            >
              <Button onClick={onDelete}>Delete</Button>
            </div>
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "sides"}
        onChange={onAccordionChange("sides")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          Edge Conditions
        </AccordionSummary>
        <AccordionDetails>
          {["top", "right", "bottom", "left"].map((side, i) => (
            <div key={i}>
              <div className={classes.subtitle}>
                {side.charAt(0).toUpperCase() + side.slice(1)}
              </div>
              <Grid container={true} spacing={2} className={classes.row}>
                <Grid item={true} xs={12}>
                  <FormControl fullWidth={true}>
                    <InputLabel>Type</InputLabel>
                    <Select
                      disableUnderline={true}
                      className={classes.input}
                      value={dividers[i].type}
                      label={side}
                      onChange={(e) =>
                        onDividerTypeChange(i, e.target.value as string)
                      }
                    >
                      {sideOptions.map((el) => (
                        <MenuItem key={el.value} value={el.value}>
                          {el.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {dividers[i].type !== "none" && (
                  <>
                    <Grid item={true} xs={4}>
                      <FormControl fullWidth={true}>
                        <InputLabel>Size</InputLabel>
                        <Select
                          disableUnderline={true}
                          className={classes.input}
                          value={dividers[i].mode}
                          label={"Type"}
                          onChange={(e) =>
                            onDividerModeChange(i, e.target.value as string)
                          }
                        >
                          {sideModeOptions.map((el) => (
                            <MenuItem key={el.value} value={el.value}>
                              {el.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    {dividers[i].mode === "auto" ? (
                      <>
                        <Grid item={true} xs={4}>
                          <NumberField
                            label={"Start"}
                            value={dividers[i].start || 0}
                            onChange={(v) => onDividerStartChange(i, v)}
                            positive={true}
                            min={0}
                            max={120}
                            endAdornment={
                              <InputAdornment position={"end"}>
                                in
                              </InputAdornment>
                            }
                          />
                        </Grid>
                        <Grid item={true} xs={4}>
                          <NumberField
                            label={"End"}
                            value={dividers[i].end || 0}
                            onChange={(v) => onDividerEndChange(i, v)}
                            positive={true}
                            min={0}
                            max={120}
                            endAdornment={
                              <InputAdornment position={"end"}>
                                in
                              </InputAdornment>
                            }
                          />
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item={true} xs={4}>
                          <FormControl fullWidth={true}>
                            <InputLabel>Align</InputLabel>
                            <Select
                              value={dividers[i].align}
                              label={"Align"}
                              disableUnderline={true}
                              className={classes.input}
                              onChange={(e) =>
                                onDividerAlignChange(
                                  i,
                                  e.target.value as string
                                )
                              }
                            >
                              {sideAlignOptions.map((el) => (
                                <MenuItem key={el.value} value={el.value}>
                                  {el.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item={true} xs={4}>
                          <NumberField
                            label={"Size"}
                            value={dividers[i].size || 0}
                            onChange={(v) => onDividerSizeChange(i, v)}
                            positive={true}
                            min={0}
                            max={360}
                            endAdornment={
                              <InputAdornment position={"end"}>
                                in
                              </InputAdornment>
                            }
                          />
                        </Grid>
                      </>
                    )}
                  </>
                )}
              </Grid>
            </div>
          ))}
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "library"}
        onChange={onAccordionChange("library")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          Library
        </AccordionSummary>
        <AccordionDetails>
          <div>
            {expanded === "library" && (
              <BlockLibrary type="asset" onClick={onChangeBlock} />
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default SidebarBlockControls;
