import React, { useCallback, useState } from "react";
import { XForm } from "lib/types";
import { RendererMode } from "../../lib/containers";

interface Props {
  minZoom?: number;
  updateXform(
    fn: (xform: XForm) => {
      scale?: number;
      translation?: { x: number; y: number };
    }
  ): void;
  setRendererMode(mode: RendererMode): void;
  rendererMode: RendererMode;
  active?: boolean;
}

export const Pan: React.FC<Props> = ({
  children,
  updateXform,
  minZoom = -0.2,
  active,
  setRendererMode,
  rendererMode,
}) => {
  const [grabbing, setGrabbing] = useState<boolean>(false);
  const [hovering, setHovering] = useState<boolean>(false);

  const activeCursor = grabbing ? (
    <cursor cursor={"grabbing"} />
  ) : (
    <cursor cursor={"grab"} />
  );

  const applyMove = useCallback(
    (movementX: number, movementY: number) => {
      updateXform(() => ({ translation: { x: movementX, y: movementY } }));
    },
    [updateXform]
  );

  const onGrab = useCallback(
    (e: any) => {
      const hovered = !!active;
      const grabbed = isGrab(e, active);
      if (hovered !== hovering) setHovering(hovering);
      if (grabbed !== grabbing) setGrabbing(grabbed);
      if (grabbed) setRendererMode(RendererMode.Pan);
      else if (rendererMode === RendererMode.Pan) {
        // e.which is used to clarify where a mouse event is fired from,
        // e.which === 3 indicates that this came from a right click event
        if (e.which === 3) setRendererMode(RendererMode.Select);
      }
    },
    [active, grabbing, hovering, setRendererMode, rendererMode]
  );

  const onMouseMove: any = useCallback(
    (e: MouseEvent) => {
      const { movementX, movementY } = e;
      const grabbed = isGrab(e, active);

      if (grabbed) {
        applyMove(movementX, movementY);
        e.preventDefault();
      }
    },
    [active, applyMove]
  );

  const applyZoom = useCallback(
    (e: WheelEvent) => {
      const maxZoom = -3;
      updateXform((xform) => {
        const d = Math.abs(e.deltaY);
        const mx =
          (e.offsetX * xform.globalScale - xform.position.x) / xform.scale;
        const my =
          (e.offsetY * xform.globalScale - xform.position.y) / xform.scale;
        const scale = Math.min(
          Math.max(xform.scale + (e.deltaY < 1 ? d : -d) * 0.001, maxZoom),
          minZoom
        );
        return {
          scale,
          translation: {
            x: mx * (xform.scale - scale),
            y: my * (xform.scale - scale),
          },
        };
      });
    },
    [updateXform, minZoom]
  );

  const onWheel: any = useCallback(
    (e: WheelEvent) => {
      const { deltaX, deltaY, deltaMode, shiftKey } = e;

      const factor = deltaMode ? 4 : 1;
      const speed = factor * 1.5;

      if (shiftKey) applyMove(-deltaX * speed, -deltaY * speed);
      else applyZoom(e);

      e.preventDefault();
    },
    [applyMove, applyZoom]
  );

  const props: any = { type: "DrawInteraction" };
  const eventHandler = (
    <div
      {...props}
      onMouseMove={onMouseMove}
      onWheel={onWheel}
      onMouseDown={onGrab}
      onContextMenu={(e) => e.preventDefault()}
      onMouseUp={onGrab}
    />
  );

  return (
    <>
      {activeCursor}
      {eventHandler}
      {children}
    </>
  );
};

export default Pan;

// Check if an event is grabbing
export const isGrab = (e: MouseEvent, active = false): boolean => {
  const { buttons } = e;
  return !!(active && buttons & 1) || buttons === 2;
};
