import React, { SyntheticEvent, useRef } from "react";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import { MenuItem, makeStyles } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";

export interface Props {
  onOpen?: (e: SyntheticEvent) => void;
  onClose?: (e: SyntheticEvent) => void;
  open?: boolean;
  "data-testid"?: string;
}

export interface ItemProps {
  onClick?: (e: SyntheticEvent) => void;
  "data-testid"?: string;
}
const useStyles = makeStyles({
  button: { width: 32, height: 32 },
  icon: { width: 20, height: 20 },
});

export const Item: React.FC<ItemProps> = React.forwardRef<
  HTMLLIElement,
  ItemProps
>(({ onClick, children, "data-testid": dataTestId }, ref) => (
  <MenuItem ref={ref} onClick={onClick} data-testid={dataTestId}>
    {children}
  </MenuItem>
));

const MoreButton: React.FC<Props> = ({
  onOpen,
  onClose,
  open,
  children,
  "data-testid": dataTestId,
}) => {
  const ref = useRef<HTMLButtonElement>(null);
  const classes = useStyles();

  return (
    <>
      <IconButton
        ref={ref}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={onOpen}
        className={classes.button}
        data-testid={dataTestId}
      >
        <MoreVertIcon className={classes.icon} />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={ref.current}
        keepMounted
        open={!!open}
        onClose={onClose}
      >
        {children}
      </Menu>
    </>
  );
};

export default MoreButton;
