import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import { createContainer } from "unstated-next";

import { HeadCell, allHeaders, emptyCellsArr } from "./head";
import { useTableCtrl } from "lib/containers";
import PortfolioTable from "./table";

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      width: "100%",
      paddingLeft: "64px",
    },
    switch: {
      justifyContent: "flex-end",
      fontSize: 14,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      paddingRight: 8,
      width: "100%",
      marginBottom: "48px",
      marginRight: "180px",
    },
  })
);

// define a cross file state system of editable, create the button element to open the menu
export function StartEdit(editableState = false) {
  const [editable, setEditable] = React.useState<boolean>(editableState);
  const changeEditable = () => setEditable(!editable);
  return { editable, changeEditable };
}

// Create state element to track what columns are available to the user
export function ModifyColumns(startingArr = allHeaders) {
  const [headers, setHeaders] = React.useState<HeadCell[]>(startingArr);
  const [spaces, setSpaces] = React.useState<number[]>(emptyCellsArr);

  const updateHeader = (newHeaders: HeadCell[]) => {
    setHeaders(newHeaders);
  };

  return { headers, spaces, setHeaders, setSpaces, updateHeader };
}

// Create an editing and columns container
export const Editing = createContainer(StartEdit);
export const Columns = createContainer(ModifyColumns);

export default function EnhancedTable() {
  const classes = useStyles();
  const { isPercent, toggleIsPercent } = useTableCtrl();

  return (
    <div className={classes.wrapper}>
      <div className={classes.switch}>
        <Switch
          checked={isPercent}
          onChange={toggleIsPercent}
          color="primary"
        />
        %
      </div>
      <Editing.Provider>
        <Columns.Provider>
          <PortfolioTable />
        </Columns.Provider>
      </Editing.Provider>
    </div>
  );
}
