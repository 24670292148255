import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    justifyContent: "flex-end",
    color: "#3B3B3B",
    fontFamily: "GoogleSansRegular",
    fontSize: "15.5px",
    lineHeight: "20px",
    letterSpacing: "0.25px",
    marginBottom: "20px",
    paddingRight: "10px",
  },
  headingContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "200px",
  },
  headingFirst: {
    textAlign: "end",
    width: "100px",
  },
  headingSecond: {
    textAlign: "end",
    width: "75px",
  },
}));
interface HeaderProps {
  firstColumn: string;
  secondColumn: string;
}

const MetricsRowHeader: React.FC<HeaderProps> = ({
  firstColumn,
  secondColumn,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.headingContainer}>
        <div className={classes.headingFirst}>{firstColumn}</div>
        <div className={classes.headingSecond}>{secondColumn}</div>
      </div>
    </div>
  );
};

export default MetricsRowHeader;
