import { useState, ComponentType } from "react";
import { Point } from "@outerlabs/shapes-geometry";
import { ContainerProviderProps, createContainer } from "unstated-next";
import {
  Block,
  BlockGetter,
  BlockMatchMakerTeamInfoForProps,
  MetricsProps,
} from "../../blocks/lib/types";
import {
  deepCopy,
  getters,
  makeEmptyMetricsProp,
} from "../../blocks/lib/constants";
import { makeBlockInstance } from "blocks/lib/instance";
import { mat4 } from "gl-matrix";
import { getNooks } from "lib/metrics/nook-calculations";
import { getDesks } from "lib/metrics/desk-calculations";
import { cloneBlock } from "blocks/lib/util";
import {
  getIndividualMetrics,
  getCommunalMetrics,
} from "lib/metrics/wpi-metric-helpers";

/**
 * Matchmaker consts and types
 */
export enum MMUserProfile {
  Frequent = "Frequent",
  Occasional = "Occasional",
  Infrequent = "Infrequent",
}
const { Frequent, Occasional, Infrequent } = MMUserProfile;
export const MMUserProfiles = [Frequent, Occasional, Infrequent];
export enum MMSpotType {
  Nook = "Nook",
  Desk = "Desk",
  Workpoint = "Workpoint",
}
export const MMSpotTypes = [
  MMSpotType.Nook,
  MMSpotType.Desk,
  MMSpotType.Workpoint,
];

export type MMAssetSharingRatio = "1:1" | "2:1" | "5:1" | "Unassigned";

export type MMUserProfileMetadata = {
  name: string;
  percentage: number;
  showUpRate: number;
  assetSharingRatios: MMAssignedAssetInfo;
};

// All of these are X:1 ratios, so a value of 2 represents 2:1;
// If null, they represent unassigned ratios
export type MMAssignedAssetInfo = Record<MMSpotType, MMAssetSharingRatio>;

export const defaultMMUserProfileMetadataMap: Record<
  MMUserProfile,
  MMUserProfileMetadata
> = {
  Frequent: {
    name: Frequent.toString(),
    percentage: 50,
    showUpRate: 3,
    assetSharingRatios: {
      Nook: "1:1",
      Desk: "1:1",
      Workpoint: "Unassigned",
    },
  },
  Occasional: {
    name: Occasional.toString(),
    percentage: 35,
    showUpRate: 2,
    assetSharingRatios: {
      Nook: "Unassigned",
      Desk: "2:1",
      Workpoint: "Unassigned",
    },
  },
  Infrequent: {
    name: Infrequent.toString(),
    percentage: 15,
    showUpRate: 1,
    assetSharingRatios: {
      Nook: "Unassigned",
      Desk: "Unassigned",
      Workpoint: "5:1",
    },
  },
};

/**
 * Matchmaker utils
 */

// fit uses two WPI helper functions to find the total amount of individual and
// communal space within the blocks, and generates the ratio of individual v
// total. That is used to compare to the desired ratio that comes from the
// team composition specified earlier
const focusRatioSort = (
  block: Block,
  focus: number,
  getBlockById: BlockGetter
) => {
  const { sizeRange } = getters.getMetrics(block);
  const instanced = makeBlockInstance(
    block,
    mat4.create(),
    [sizeRange[0][0], sizeRange[1][1]],
    getBlockById
  );

  const individualMets = getIndividualMetrics([[instanced]]) || 0;
  const communalMets = getCommunalMetrics([[instanced]]) || 0;

  return Math.abs(
    focus - individualMets.area / (communalMets.area + individualMets.area || 1)
  );
};

// Helper for filterMatchMaker

const computeRegion = (
  block: Block,
  getBlockById: BlockGetter,
  previewSize: number,
  sizeRange: [Range, Range]
) => {
  const previewRotation = 0;
  const range: Point[] = (
    sizeRange
      ? sizeRange
      : [
          [0, 100],
          [0, 100],
        ]
  ) as Point[];
  const baseSize: Point = [
    range[0][0] * (1 - previewSize) + range[0][1] * previewSize,
    range[1][0] * (1 - previewSize) + range[1][1] * previewSize,
  ];
  const isHorizontal = (previewRotation / 90) % 2 === 0;
  const size: Point = isHorizontal ? baseSize : [baseSize[1], baseSize[0]];
  const region = [
    [0, size[1]],
    [0, 0],
    [size[0], 0],
    [size[0], size[1]],
  ];
  const bi = makeBlockInstance(block, mat4.create(), size, getBlockById);
  const metrics = getters.getMetrics(bi);
  return {
    region,
    instance: bi,
    headcount: metrics.headcount,
  };
};

const getSeatingFromTeam = (rat: MMAssetSharingRatio) => {
  switch (rat) {
    case "1:1":
      return 1;
    case "2:1":
      return 0.5;
    case "5:1":
      return 0.2;
    case "Unassigned":
      return 0;
  }
};

// Possible permutations of nook, desk, and workpoint to go through.
// For example, if the user has default settings on, which are:
// Frequent: 5 people, 1:1 nook, 1:1 desk, no workpoints
// Occasional: 4 people, 2:1 desks
// Infrequent: 2 people, 5:1 workpoints
// ...then we create two scenarios:
// [ 0 nooks, 5 + 2 desks, 1 workpoint,
//   5 nooks, 2 desks, 1 workpoint ].
// If the user goes ham with the checkboxes there are maximum 3^3 scenarios.
const threeByThreeSeatPermutations = [
  // Permutation in which frequent, occasional and infrequent users all use nooks:
  [MMSpotType.Nook, MMSpotType.Nook, MMSpotType.Nook],
  // Permutation in which frequent and occasional users use nooks
  // and infrequent use desks:
  [MMSpotType.Nook, MMSpotType.Nook, MMSpotType.Desk],
  // ... etc.
  [MMSpotType.Nook, MMSpotType.Nook, MMSpotType.Workpoint],
  [MMSpotType.Nook, MMSpotType.Desk, MMSpotType.Nook],
  [MMSpotType.Nook, MMSpotType.Desk, MMSpotType.Desk],
  [MMSpotType.Nook, MMSpotType.Desk, MMSpotType.Workpoint],
  [MMSpotType.Nook, MMSpotType.Workpoint, MMSpotType.Workpoint],
  [MMSpotType.Nook, MMSpotType.Workpoint, MMSpotType.Nook],
  [MMSpotType.Nook, MMSpotType.Workpoint, MMSpotType.Desk],

  [MMSpotType.Desk, MMSpotType.Nook, MMSpotType.Nook],
  [MMSpotType.Desk, MMSpotType.Nook, MMSpotType.Desk],
  [MMSpotType.Desk, MMSpotType.Nook, MMSpotType.Workpoint],
  [MMSpotType.Desk, MMSpotType.Desk, MMSpotType.Nook],
  [MMSpotType.Desk, MMSpotType.Desk, MMSpotType.Desk],
  [MMSpotType.Desk, MMSpotType.Desk, MMSpotType.Workpoint],
  [MMSpotType.Desk, MMSpotType.Workpoint, MMSpotType.Workpoint],
  [MMSpotType.Desk, MMSpotType.Workpoint, MMSpotType.Nook],
  [MMSpotType.Desk, MMSpotType.Workpoint, MMSpotType.Desk],

  [MMSpotType.Workpoint, MMSpotType.Nook, MMSpotType.Nook],
  [MMSpotType.Workpoint, MMSpotType.Nook, MMSpotType.Desk],
  [MMSpotType.Workpoint, MMSpotType.Nook, MMSpotType.Workpoint],
  [MMSpotType.Workpoint, MMSpotType.Desk, MMSpotType.Nook],
  [MMSpotType.Workpoint, MMSpotType.Desk, MMSpotType.Desk],
  [MMSpotType.Workpoint, MMSpotType.Desk, MMSpotType.Workpoint],
  [MMSpotType.Workpoint, MMSpotType.Workpoint, MMSpotType.Workpoint],
  [MMSpotType.Workpoint, MMSpotType.Workpoint, MMSpotType.Nook],
  [MMSpotType.Workpoint, MMSpotType.Workpoint, MMSpotType.Desk],
];

export const filterMatchMaker = (
  blocks: Block[],
  teams: Team[],
  libraries: string[],
  getBlockById: BlockGetter,
  mmUserProfileMap: Record<MMUserProfile, MMUserProfileMetadata>
): Block[][] => {
  // TODO: cache
  const metrics = blocks.map((block) => ({
    block,
    metrics: getters.getMetrics(block),
  }));

  const matches = teams.map((team, teamIdx) => {
    // Calculate POSSIBLE desired nooks, desks and workpoints based on:
    // 1. User profile settings defined in the 'matchmaker group' step (step 1)
    // 2. This specific team's freq/occasional/infreq %'s
    //
    // Note: ALWAYS ROUND UP. When doing hand calcs, architects round up too.
    //
    // If a user profile, for example "frequent," can have desks OR nooks,
    // we make two scenarios.
    // Three teams * maximum three types of desks/nooks/workpoints means
    // 27 possible permutations. example calcs: https://rentry.co/brxzpc
    const permutations = threeByThreeSeatPermutations.filter((perm) => {
      // At this step, we eliminate a majority of scenarios
      // unless user has gone ham on checkboxes and we need to do all 27
      for (let i = 0; i < perm.length; i++) {
        const profile: MMUserProfile = MMUserProfiles[i];
        if (
          mmUserProfileMap[profile].assetSharingRatios[perm[i]] === "Unassigned"
        ) {
          return false;
        }
      }
      return true;
    });

    // Using default settings, permutations should only have two values so far:
    // [['Nook', 'Desk', 'Workpoint'],
    //  ['Desk', 'Desk', 'Workpoint']]

    // Now calculate all nooks desks and workpoints for each block once
    const scenarios: Array<{
      desiredNooks: number;
      desiredDesks: number;
      desiredWorkpoints: number;
      neededHC: number;
    }> = permutations
      .filter((p) => p)
      .map((permutation) => {
        const [desiredNooks, desiredDesks, desiredWorkpoints] = MMSpotTypes.map(
          // Iterate through all spot types to get nooks, desks and workpoints
          (spotType) => {
            // first spot type will be nooks, for example.

            let totalDesiredOfSpotType = 0;

            for (let i = 0; i < permutation.length; i++) {
              // i === index of profile (freq, occ, infreq);
              const profile = MMUserProfiles[i];

              // Check that the profile is assigned to this spot type in this
              // perm
              if (spotType !== permutation[i]) continue;

              const percentage = team.userProfilePercentages[profile];

              // Multiply by people in the team. Round up with Math.ceil
              const seatingForSpotType = getSeatingFromTeam(
                mmUserProfileMap[profile].assetSharingRatios[spotType]
              );

              const localHC = team.headcount;

              const amountToAdd = Math.ceil(
                percentage * seatingForSpotType * localHC * 0.009999
              );
              totalDesiredOfSpotType += amountToAdd;
            }

            return totalDesiredOfSpotType;
          }
        );

        const neededHC = desiredDesks + desiredNooks + desiredWorkpoints;
        return {
          desiredNooks,
          desiredDesks,
          desiredWorkpoints,
          neededHC,
        };
      });

    // Using default settings, scenarios should have two entries:
    // [ {desiredNooks: 6, desiredDesks: 3, desiredWorkpoints: 1},
    //   {desiredNooks: 0, desiredDesks: 9, desiredWorkpoints: 1} ]

    const headcountRangeFudge = 2;
    return scenarios
      .map((scenario) => {
        const { desiredNooks, desiredDesks, desiredWorkpoints, neededHC } =
          scenario;
        return (
          metrics
            // filter uncategorized blocks
            .filter(({ block }) => {
              return block.props.definition?.library;
            })
            // filter out libraries not selected
            .filter(({ block }) => {
              const { library } = getters.getDefinition(block);
              if (
                libraries.length &&
                (!library || !libraries.includes(library))
              ) {
                return false;
              }
              return true;
            })
            // extract block
            .map(({ block }) => block)
            // size up the block to get required headcount
            .map((block: Block) => {
              const { sizeRange, headcountRange } = getters.getMetrics(block);
              let previewSize =
                headcountRange[0] === headcountRange[1]
                  ? 1
                  : (neededHC - headcountRange[0]) /
                    (headcountRange[1] - headcountRange[0]);
              let res = computeRegion(
                block,
                getBlockById,
                previewSize,
                sizeRange as any
              );
              while (res.headcount < neededHC && previewSize < 1) {
                previewSize = Math.min(previewSize + 0.1, 1);
                res = computeRegion(
                  block,
                  getBlockById,
                  previewSize,
                  sizeRange as any
                );
              }

              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              block.props.metrics!.size = res.instance.props.metrics!.size;
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              block.props.metrics!.headcount = res.headcount;
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              block.props.metrics!.seats = res.instance.props.metrics?.seats;
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              block.props.metrics!.names = res.instance.props.metrics?.names;

              return block;
            })
            // filter out blocks without desired nooks/desks/wps for scenario
            .filter((block) => {
              const desks = getDesks([[block]]);
              const nooks = getNooks([[block]]);
              const workpoints = block.props.metrics?.workpoints || 0;
              const result =
                workpoints >= desiredWorkpoints &&
                nooks >= desiredNooks &&
                desks >= desiredDesks;
              return result;
            })
            // filter down again, getting rid of blocks that didn't meet the headcount
            .filter((block) => {
              const result =
                (block.props.metrics?.headcount || 0) >=
                  neededHC - headcountRangeFudge &&
                (block.props.metrics?.headcount || 0) <=
                  neededHC + headcountRangeFudge;
              return result;
            })
            // Last step is to clone the block to avoid pointer issues and
            // add team information to the block to access later.
            .map((block) => {
              const mmTeamInfo: BlockMatchMakerTeamInfoForProps = {
                team,
                matchmakerProfileMap: mmUserProfileMap,
                teamIdx,
              };
              const clone = cloneBlock(block);
              if (!clone.props.definition) {
                clone.props.definition = {};
              }

              clone.props.definition.matchmakerTeamInfo = mmTeamInfo;
              return clone;
              // if (!block.props.definition) {
              //   block.props.definition = {};
              // }
              // block.props.definition.matchmakerTeamInfo = mmTeamInfo;
              // return block;
            })
        );
      })
      .flat()
      .sort((a, b) => {
        const fa = focusRatioSort(a, team.ratio, getBlockById);
        const fb = focusRatioSort(b, team.ratio, getBlockById);
        return team.ratio === 0.5 ? fa - fb : fb - fa;
      });
  });
  return matches;
};

/**
 * Matchmaker container
 */

interface MatchMakerState {
  matchMakerLibrary: string[];
  setMatchMakerLibrary: (s: string[]) => void;
  matchMakerStep: number;
  setMatchMakerStep: (n: number) => void;
  matchMakerActive: boolean;
  setMatchMakerActive: (b: boolean) => void;
  matchMakerNumTeams: number;
  setMatchMakerNumTeams: (n: number) => void;
  matchMakerResults: Block[][];
  setMatchMakerResults: (b: Block[][]) => void;
  matchMakerTeams: Team[];
  setMatchMakerTeams: (t: Team[] | ((t: Team[]) => Team[])) => void;
  matchMakerSelectedResults: number[];
  setMatchMakerMetrics: (m: MetricsProps) => void;
  matchMakerMetrics: MetricsProps;
  setMatchMakerSelectedResults: (n: number[]) => void;
  mmUserProfileMap: Record<MMUserProfile, MMUserProfileMetadata>;
  setMMUserProfileMap: (
    r: Record<MMUserProfile, MMUserProfileMetadata>
  ) => void;
}

export type Team = {
  headcount: number;
  days: number;
  ratio: number;
  userProfilePercentages: Record<MMUserProfile, number>;
  teamName: string;
};
export type MatchMaker = {
  teams: Team[];
  density: number;
};

export const useMatchMakerState = (): MatchMakerState => {
  const [matchMakerStep, setMatchMakerStep] = useState<number>(0);
  const [matchMakerLibrary, setMatchMakerLibrary] = useState<string[]>([]);
  const [matchMakerActive, setMatchMakerActive] = useState<boolean>(false);
  const [matchMakerNumTeams, setMatchMakerNumTeams] = useState<number>(2);
  const [matchMakerTeams, setMatchMakerTeams] = useState<Team[]>([]);
  const [matchMakerResults, setMatchMakerResults] = useState<Block[][]>([]);
  const [matchMakerMetrics, setMatchMakerMetrics] = useState<MetricsProps>(
    makeEmptyMetricsProp()
  );
  const [matchMakerSelectedResults, setMatchMakerSelectedResults] = useState<
    number[]
  >([]);

  const [mmUserProfileMap, setMMUserProfileMap] = useState<
    Record<MMUserProfile, MMUserProfileMetadata>
  >(deepCopy(defaultMMUserProfileMetadataMap));

  return {
    matchMakerLibrary,
    setMatchMakerLibrary,
    matchMakerStep,
    matchMakerActive,
    matchMakerNumTeams,
    matchMakerTeams,
    matchMakerResults,
    matchMakerMetrics,
    matchMakerSelectedResults,
    mmUserProfileMap,
    setMatchMakerStep,
    setMatchMakerActive,
    setMatchMakerNumTeams,
    setMatchMakerTeams,
    setMatchMakerResults,
    setMatchMakerMetrics,
    setMatchMakerSelectedResults,
    setMMUserProfileMap,
  };
};

export const MatchMakerController = createContainer(useMatchMakerState);
export const MatchMakerProvider: ComponentType<ContainerProviderProps> =
  MatchMakerController.Provider;
export const useMatchMakerCtrl = () => MatchMakerController.useContainer();
