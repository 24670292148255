import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { portfolioLayers } from "../lib/constants";
import { ParsedDXFFile } from "../types";
import { ListItemIcon } from "@material-ui/core";
import IconWarning from "@material-ui/icons/Warning";
import IconError from "@material-ui/icons/Error";
import IconCheck from "@material-ui/icons/CheckCircle";
import TextField from "../../components/TextField";

interface DXFImportProps {
  files: Record<string, ParsedDXFFile>;
  updateAll: (next: Record<string, ParsedDXFFile>) => void;
  removeFile: (name: string) => void;
  ready: boolean;
  setReady: (b: boolean) => void;
}

const useStyles = makeStyles({
  container: {
    display: "flex",
    flex: 1,
  },
  fileList: {
    width: "100%",
    maxHeight: "370px",
    overflow: "scroll",
    marginBottom: 32,
  },
  item: {
    borderBottom: "1px solid #e8e8e8",
    paddingBottom: 24,
    marginBottom: 24,
  },
  floor: {
    display: "flex",
  },
  remove: {
    marginLeft: 20,
  },
});

const FileList: React.FC<DXFImportProps> = ({
  files,
  removeFile,
  updateAll,
  ready,
  setReady,
}) => {
  const classes = useStyles({});
  const [levels, setLevels] = useState<Record<string, number>>({});

  const checkFiles = React.useCallback(() => {
    const values = Object.values(files);
    return values.length > 0 && !values.some((file) => file.error);
  }, [files]);
  const checkLevels = React.useCallback(
    (_levels: Record<string, number>) => {
      return (
        Object.keys(_levels).every(
          (k) => Number(_levels[k]) === _levels[k] && _levels[k] > 0
        ) && Object.keys(_levels).length === Object.keys(files).length
      );
    },
    [files]
  );

  useEffect(() => {
    if (Object.keys(files).length) {
      setLevels(
        Object.values(files).reduce(
          (acc, curr) => ({ ...acc, [curr.name]: curr.level }),
          {}
        )
      );
    } else setReady(false);
  }, [files, setReady]);

  useEffect(() => {
    if (!checkFiles()) setReady(false);
    else if (!checkLevels(levels)) setReady(false);
    else setReady(true);
  }, [checkFiles, checkLevels, levels, setReady]);

  const handleSetLevel = (name: string, level: number) => {
    setLevels((prev) => {
      const newLevels = { ...prev, [name]: level };
      if (checkLevels(newLevels) && checkFiles() && !ready) setReady(true);
      return newLevels;
    });
  };

  const handleSyncLevels = () => {
    const next = Object.assign(files);
    for (const k of Object.keys(levels)) {
      if (next[k].parsed) {
        next[k].parsed.level = levels[k];
      }
    }
    updateAll(next);
  };

  return (
    <List className={classes.fileList}>
      {Object.values(files).map((f) => {
        const missingRecommended =
          !f?.error &&
          f?.parsed &&
          f.parsed?.layers?.length !== portfolioLayers.length;
        const secondaryText = f?.error
          ? f.error.message
          : missingRecommended
          ? "Consider including recommended layers"
          : "";
        const errorColor = "#FF9153";
        const warningColor = "#FFAF38";
        const successColor = "#41C08B";
        const secondaryColor = f?.error ? errorColor : "inherit";
        return (
          <ListItem key={f.name} className={classes.item}>
            <ListItemIcon style={{ minWidth: 36 }}>
              {f?.error ? (
                <IconError style={{ color: errorColor }} />
              ) : missingRecommended ? (
                <IconWarning style={{ color: warningColor }} />
              ) : (
                <IconCheck style={{ color: successColor }} />
              )}
            </ListItemIcon>
            <ListItemText
              primary={f.name}
              secondary={
                <span style={{ color: secondaryColor }}>{secondaryText}</span>
              }
            />
            <ListItemSecondaryAction>
              <div className={classes.floor}>
                <TextField
                  label={"Level #"}
                  type="number"
                  value={levels[f.name] || ""}
                  onChange={(e) =>
                    handleSetLevel(f.name, parseInt(e.target.value))
                  }
                  disabled={f?.error != null}
                  onBlur={handleSyncLevels}
                />
                <IconButton
                  className={classes.remove}
                  onClick={() => {
                    removeFile(f.name);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
};

export default FileList;
