import React from "react";
import MultiPicker from "components/controls/multi-picker";
import {
  Metric,
  Features,
  UserConvertedMeetingRooms,
  StrategyMode,
  ConferenceRooms,
  SelectedMeetingRoom,
  RoomBounds,
} from "lib/types";
import { options as programOptions } from "lib/constants";
import { useFeaturesCtrl, useRendererCtrl } from "lib/containers";

import { legendColorCode } from "renderer/legend";
import { buildMutation } from "./individualPicker";

interface Props {
  onMetricChange(mutation: { [key: string]: any }): void;
  metric: Metric;
  disabled?: boolean;
}

export const removeOption = (
  optionName: string,
  metric: Metric,
  currentFeatures: Features,
  combinedConferenceRooms: ConferenceRooms
) => {
  const apiNames = [
    "convertToCollaboration",
    "convertToWorkstations",
    "disable",
  ];
  let roomIDs: string[];
  if (metric.mode === StrategyMode.Zone) {
    roomIDs = Object.keys(currentFeatures.conferenceRooms[optionName]);
  } else {
    roomIDs = Object.keys(combinedConferenceRooms[optionName]);
  }

  const newResult: { [key: string]: string[] } = {};
  apiNames.forEach((aN: string) => {
    const removeTypeAndIDs = metric[aN as keyof Metric]
      .filter((i: string) => {
        return i !== optionName;
      })
      .filter((el: string) => !roomIDs.includes(el));
    newResult[aN] = removeTypeAndIDs;
  });
  return newResult;
};

export const addOption = (
  apiName: string,
  optionName: string,
  metric: Metric,
  currentFeatures: Features,
  combinedConferenceRooms: ConferenceRooms
) => {
  const apiNameKey = apiName as keyof Metric;
  if (metric.mode === StrategyMode.Zone) {
    metric[apiNameKey].push(optionName);
    const roomIDs = Object.keys(currentFeatures.conferenceRooms[optionName]);

    metric[apiNameKey].push(...roomIDs);

    return { [apiNameKey]: metric[apiNameKey] };
  } else {
    metric[apiNameKey].push(optionName);
    const roomIDs = Object.keys(combinedConferenceRooms[optionName]);

    metric[apiNameKey].push(...roomIDs);

    return { [apiNameKey]: metric[apiNameKey] };
  }
};

const PickerLabel: React.FC<{ label: string; color: string }> = ({
  label,
  color,
}) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          width: 10,
          height: 10,
          background: color,
          borderRadius: "50%",
          marginRight: 12,
        }}
      />
      <span>{label}</span>
    </div>
  );
};

const Pickers: React.FC<Props> = ({ metric, onMetricChange }) => {
  const { currentFeatures } = useFeaturesCtrl();
  const { combinedConferenceRooms } = useRendererCtrl();

  const handleMetricsChange = (apiName: string, title: string) => {
    let selectMetric = "";
    const roomsArr: { conferenceRoomId: string; conferenceRoomType: string }[] =
      [];
    let userConverted;
    switch (title) {
      case "Small Conf Rooms": {
        selectMetric = "small";
        break;
      }
      case "Med Conf Rooms": {
        selectMetric = "medium";
        break;
      }
      case "Lg Conf Rooms": {
        selectMetric = "large";
        break;
      }
      case "Huddle": {
        selectMetric = "huddle";
        break;
      }
      case "Phone Booths": {
        selectMetric = "phone";
        break;
      }
    }

    if (
      selectMetric !== "" &&
      currentFeatures &&
      currentFeatures.conferenceRooms &&
      currentFeatures.conferenceRooms[selectMetric]
    ) {
      Object.keys(currentFeatures.conferenceRooms[selectMetric]).forEach(
        (room) => {
          roomsArr.push({
            conferenceRoomId: room,
            conferenceRoomType: selectMetric,
          });
        }
      );
    }

    if (
      roomsArr.length !== 0 &&
      metric.userConvertedMeetingRooms !== undefined
    ) {
      const temp = buildMutation(
        roomsArr,
        metric.userConvertedMeetingRooms,
        apiName
      );
      userConverted = temp;
    }

    if (apiName === "mixed") {
      return;
    }
    const option = programOptions.find((o) => o.name === title);
    if (!option) return;
    let mutation: any;

    if (
      currentFeatures !== undefined &&
      combinedConferenceRooms !== undefined
    ) {
      const optionName = option.value;
      mutation = removeOption(
        optionName,
        metric,
        currentFeatures,
        combinedConferenceRooms
      );
      if (apiName !== "noChange") {
        const addMutation = addOption(
          apiName,
          optionName,
          metric,
          currentFeatures,
          combinedConferenceRooms
        );
        mutation = { ...mutation, ...addMutation };
      }

      if (
        metric.userConvertedMeetingRooms !== undefined &&
        metric.userConvertedMeetingRooms.convertToCollaboration &&
        metric.userConvertedMeetingRooms.convertToWorkstations &&
        metric.userConvertedMeetingRooms.disable
      ) {
        const userMutation: UserConvertedMeetingRooms = {
          convertToCollaboration: [],
          convertToWorkstations: [],
          disable: [],
        };
        Object.keys(metric.userConvertedMeetingRooms).forEach((aN) => {
          if (!metric.userConvertedMeetingRooms) return;
          userMutation[aN] = metric.userConvertedMeetingRooms[aN].filter(
            (room) => room.conferenceRoomType !== option.value
          );
        });
        mutation = { ...mutation, userConvertedMeetingRooms: userMutation };
      }

      if (onMetricChange) {
        onMetricChange({
          ...mutation,
          userConvertedMeetingRooms: userConverted,
        });
      }
    }
  };

  const checkConversions = (
    convertedRooms: SelectedMeetingRoom[],
    allRoomsArr: RoomBounds
  ) => {
    let foundCount = 0;
    const convertedRoomsArr = convertedRooms.map((i) => i.conferenceRoomId);
    Object.keys(allRoomsArr).forEach((room) => {
      if (convertedRoomsArr.find((el) => el === room)) foundCount++;
    });
    return foundCount === Object.keys(allRoomsArr).length;
  };

  const currentOption = (title: string, _metric: Metric) => {
    const currentType = programOptions.find((o) => o.name === title);
    let currentOperation = "noChange";
    if (currentType && _metric && currentFeatures) {
      const allRooms = currentFeatures.conferenceRooms[currentType.value];

      if (
        _metric.userConvertedMeetingRooms !== undefined &&
        _metric.userConvertedMeetingRooms.convertToCollaboration &&
        _metric.userConvertedMeetingRooms.convertToWorkstations &&
        _metric.userConvertedMeetingRooms.disable
      ) {
        Object.keys(_metric.userConvertedMeetingRooms).forEach((key) => {
          if (key !== currentOperation && key !== "noChange") {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            Object.values(_metric.userConvertedMeetingRooms![key]).forEach(
              (room) => {
                // _metric.userConvertedMeetingRooms![key] is an array of object consisting
                // of conferenceRoomId and conferenceRoomType
                if (room.conferenceRoomType === currentType.value) {
                  // if this hits, then the picker is not noChange anymore
                  currentOperation = "mixed";
                  if (
                    checkConversions(
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      _metric.userConvertedMeetingRooms!.convertToCollaboration,
                      allRooms
                    )
                  ) {
                    currentOperation = "convertToCollaboration";
                  }
                  if (
                    checkConversions(
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      _metric.userConvertedMeetingRooms!.convertToWorkstations,
                      allRooms
                    )
                  ) {
                    currentOperation = "convertToWorkstations";
                  }
                  if (
                    checkConversions(
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      _metric.userConvertedMeetingRooms!.disable,
                      allRooms
                    )
                  ) {
                    currentOperation = "disable";
                  }
                }
              }
            );
          }
        });
      }
    }

    return currentOperation;
  };

  const isDisabled = (title: string) => {
    const currentType = programOptions.find((o) => o.name === title);
    let currentTypeFeatures = {};

    if (
      currentFeatures &&
      currentFeatures.conferenceRooms &&
      currentType &&
      currentFeatures?.conferenceRooms[currentType.value]
    ) {
      currentTypeFeatures = currentFeatures.conferenceRooms[currentType.value];
    }
    let isEmpty;
    if (currentTypeFeatures !== undefined) {
      isEmpty = Object.keys(currentTypeFeatures).length === 0;
    }
    return isEmpty;
  };

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          position: "relative",
          top: 6,
        }}
      >
        <div
          style={{
            position: "relative",
            right: 74,
            color: "#555555",
            fontSize: 14,
            fontFamily: "GoogleSansRegular",
          }}
        >
          Convert to Type
        </div>
      </div>
      <div>
        <MultiPicker
          metric={metric}
          onChange={handleMetricsChange}
          name="Small Conf Rooms"
          label={
            <PickerLabel
              label="Small Conf Rooms"
              color={legendColorCode[currentOption("Small Conf Rooms", metric)]}
            />
          }
          helpguideTooltip="Small Conf Rooms"
          value={currentOption("Small Conf Rooms", metric)}
          disabled={isDisabled("Small Conf Rooms")}
        />
        <MultiPicker
          metric={metric}
          onChange={handleMetricsChange}
          name="Med Conf Rooms"
          label={
            <PickerLabel
              label="Med Conf Room"
              color={legendColorCode[currentOption("Med Conf Rooms", metric)]}
            />
          }
          helpguideTooltip="Med Conf Rooms"
          value={currentOption("Med Conf Rooms", metric)}
          disabled={isDisabled("Med Conf Rooms")}
        />
        <MultiPicker
          metric={metric}
          onChange={handleMetricsChange}
          name="Lg Conf Rooms"
          label={
            <PickerLabel
              label="Lg Conf Room"
              color={legendColorCode[currentOption("Lg Conf Rooms", metric)]}
            />
          }
          helpguideTooltip="Lg Conf Rooms"
          value={currentOption("Lg Conf Rooms", metric)}
          disabled={isDisabled("Lg Conf Rooms")}
        />
        <MultiPicker
          metric={metric}
          onChange={handleMetricsChange}
          name="Huddle"
          label={
            <PickerLabel
              label="Huddle"
              color={legendColorCode[currentOption("Huddle", metric)]}
            />
          }
          helpguideTooltip="Huddle"
          value={currentOption("Huddle", metric)}
          disabled={isDisabled("Huddle")}
        />
        <MultiPicker
          metric={metric}
          onChange={handleMetricsChange}
          name="Phone Booths"
          label={
            <PickerLabel
              label="Phone Booths"
              color={legendColorCode[currentOption("Phone Booths", metric)]}
            />
          }
          helpguideTooltip="Phone Booths"
          value={currentOption("Phone Booths", metric)}
          disabled={isDisabled("Phone Booths")}
        />
      </div>
    </React.Fragment>
  );
};

export default Pickers;
