import { MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { HelpguideHandle, HelpguideWrapperDiv } from "@outerlabs/helpguide";
import { useFeaturesCtrl, useRendererCtrl } from "lib/containers";
import {
  getMeetingRoomData,
  MeetingRoomSize,
  MeetingRoomSizeLabels,
  MeetingRoomSizesAndAll,
  meetingRoomTypeLabelMap,
} from "lib/containers/meeting-room-metrics-manager";
import React, { useState } from "react";
import Select from "../../blocks/components/select";
import { BarChart, Bar, Cell, XAxis, ResponsiveContainer } from "recharts";

import { getMeetingRoomGradientColor } from "lib/isp-canvas/featureRenderer";
import { useParams } from "react-router-dom";
import { Colors, Font, Type } from "@outerlabs/ol-ui";

const useMeetingRoomMetricsStyles = makeStyles({
  controls: {
    padding: "0px 0 12px",
  },
  control: {
    flexGrow: 1,
    // paddingRight: "8px",
    paddingBottom: 8,
  },
  actions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "12px 0",
  },
  traffic: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: 5,
  },
  metric: {
    paddingTop: 12,
    fontSize: 16,
    color: "#5F6166",
  },
  labels: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.25px",
    color: "#ADADAD",
  },
  select: {
    minHeight: 32,
    width: "100%",
    border: 0,
    backgroundColor: "#EFF2F4",
    "& > fieldset": {
      border: 0,
    },
    "& > div": {
      padding: "8px 20px 8px 8px",
      fontFamily: "GoogleSansRegular",
      color: "#2B2020",
    },
    "& $listItemIcon": {
      display: "none !important",
    },
  },
  titleContainer: {
    fontSize: 12,
    position: "relative",
    // width: 200,
    lineHeight: "18px",
    fontFamily: "GoogleSansRegular",
    color: "#555555",
  },
});

const CustomizedLabel = (props: any) => {
  return (
    <text
      y={props.y + 20}
      x={props.x}
      textAnchor="middle"
      fontFamily={Font.Regular.fontFamily}
      fill={Colors.Dark1}
      fontSize={12}
    >
      {props.payload.value}
    </text>
  );
};

// const CustomizedTooltip = (props: any) => {
//   const { meetingRoomData, payload } = props;
//   if (!(meetingRoomData && payload?.length)) return null;

//   // recharts is horrible
//   const displayText = meetingRoomData[idx].name;meetingRoomData={data}>
//   return (
//     <>
//       <div
//         style={{
//           backgroundColor: Colors.Dark3,
//           padding: 8,
//           borderRadius: 4,
//           color: "white",
//           fontFamily: Font.Regular.fontFamily,
//         }}
//       >
//         {displayText}
//       </div>
//     </>
//   );
// };

const tooltipHeight = 24;
const tooltipSpacing = 10;

const MeetingRoomMetrics: React.FC = () => {
  const classes = useMeetingRoomMetricsStyles();
  const {
    meetingRoomMetricsType,
    setMeetingRoomMetricsType,
    meetingRoomMetricsSize,
    setMeetingRoomMetricsSize,
    meetingRoomMetricsLevels,
    setMeetingRoomMetricsLevels,
    meetingRoomMetricSelectedRoom,
  } = useRendererCtrl();
  const { currentFeatures } = useFeaturesCtrl();

  const [hoveredBar, setHoveredBar] = useState(-1);

  // Later, get this from somewhere...
  const { floorID } = useParams<{ [key: string]: string }>();

  const { data, roomIdToDataMap } = getMeetingRoomData({
    floorID,
    buildingID: "US-MTV-40",
    meetingRoomMetricsLevels,
    meetingRoomMetricsSize,
    meetingRoomMetricsType,
    conferenceRooms: currentFeatures?.conferenceRooms,
  });

  const currentRoom =
    meetingRoomMetricSelectedRoom === null
      ? null
      : roomIdToDataMap[meetingRoomMetricSelectedRoom];

  // Have to redeclare to get meeting room data in closure/scope
  const BarWithTooltip = (props: any) => {
    const { x, y, fill, height, width, payload, ...rest } = props;
    // recharts is horrible
    const idx = data.map((mrd: any) => mrd.name).indexOf(payload.name);
    const displayText = data[idx].name;

    return (
      <>
        {/* triggers hover */}
        <rect width={width} height={110} x={x} y={0} fill="#ffffff" />

        {idx === hoveredBar && (
          <>
            <rect
              x={x}
              y={y - tooltipSpacing - tooltipHeight}
              height={tooltipHeight}
              width={width}
              fill={Colors.Dark3}
              rx={4}
              ry={4}
              {...rest}
            />
            {/* <g
          x={x + width / 2}
          y={y - tooltipSpacing - tooltipHeight / 2 - 40}
          width={5}
          height={5}
        >
          <rect
            x={x + width / 2}
            y={y - tooltipSpacing - tooltipHeight / 2 - 40}
            width={5}
            height={5}
            transform="rotate(45)"
            style={{ transformOrigin: "center" }}
            fill={Colors.Dark3}
          /> */}
            {/* </g> */}
            {/* <g
          x={x + width / 2}
          y={y - tooltipHeight}
          height={tooltipHeight}
          width={width}
          fill="hotpink"
        >
          <rect
            width={5}
            height={5}
            x={x + width / 2}
            y={y - tooltipHeight}
            transform="rotate(45)"
            fill={Colors.Dark3}
          />
        </g> */}
            <text
              fontSize={10.5}
              x={x + width / 2}
              y={y - tooltipSpacing - tooltipHeight / 2 + 4}
              textAnchor="middle"
              height={tooltipHeight}
              width={width}
              fill="#ffffff"
              fontFamily={Font.Regular.fontFamily}
            >
              {displayText}
            </text>
          </>
        )}
        <rect {...props} stroke="none" />
      </>
    );
  };

  return (
    <HelpguideWrapperDiv>
      <div className={classes.metric}>
        <HelpguideHandle isSmall tooltipKey="MeetingRoomMetrics" />
        <div className={classes.controls}>
          <div className={classes.control}>
            <div className={classes.titleContainer}>Meeting Room Type</div>
            <Select
              className={classes.select}
              onChange={(e) => {
                setMeetingRoomMetricsSize(e.target.value as MeetingRoomSize);
              }}
              value={meetingRoomMetricsSize}
            >
              {MeetingRoomSizesAndAll.map((mrs) => (
                <MenuItem value={mrs}>{MeetingRoomSizeLabels[mrs]}</MenuItem>
              ))}
            </Select>
          </div>

          <div className={classes.control}>
            <div className={classes.titleContainer}>Floors</div>
            <Select
              className={classes.select}
              onChange={(e) => {
                setMeetingRoomMetricsLevels(e.target.value as string);
              }}
              value={meetingRoomMetricsLevels}
            >
              <MenuItem value={"all"}>All Floors</MenuItem>
              <MenuItem value={"current"}>Current Floor</MenuItem>
            </Select>
          </div>

          <div className={classes.control}>
            <div className={classes.titleContainer}>Metric Type</div>
            <Select
              displayEmpty={true}
              className={classes.select}
              onChange={(e) => {
                setMeetingRoomMetricsType(e.target.value as string);
              }}
              value={meetingRoomMetricsType}
            >
              <MenuItem value={"capacity"}>Capacity</MenuItem>
              <MenuItem value={"bookingRate"}>Booking Rate</MenuItem>
              <MenuItem value={"hoursBooked"}>Hours Booked</MenuItem>
              <MenuItem value={"occupancyRate"}>Occupancy Rate</MenuItem>
              <MenuItem value={"distributionBucket"}>
                Distribution Bucket
              </MenuItem>
            </Select>
          </div>
        </div>

        {meetingRoomMetricSelectedRoom && currentRoom ? (
          <div>
            <div style={{ paddingBottom: 16, paddingTop: 36 }}>
              <Type.GroupHeading style={{ color: Colors.Text.Medium }}>
                {`${MeetingRoomSizeLabels[currentRoom.size]} Meeting Room`}
              </Type.GroupHeading>
            </div>
            <div>
              <Type.Subtitle1 style={{ color: Colors.Text.Light }}>
                {meetingRoomTypeLabelMap[meetingRoomMetricsType]}
              </Type.Subtitle1>
            </div>
            <div>
              <Type.H4 style={{ color: Colors.Text.Dark, lineHeight: 0 }}>
                {currentRoom.value}
              </Type.H4>
            </div>
          </div>
        ) : (
          <>
            <HelpguideWrapperDiv style={{ padding: "12px 0 12px" }}>
              <Type.Body2 style={{ color: Colors.Dark1 }}>
                Occupancy Rate
              </Type.Body2>
              <HelpguideHandle tooltipKey="Occupancy Rate" />
            </HelpguideWrapperDiv>
            <ResponsiveContainer width="100%" height={150}>
              <BarChart
                width={150}
                height={40}
                data={data}
                margin={{ top: tooltipHeight + tooltipSpacing }}
                onMouseLeave={() => setHoveredBar(-1)}
              >
                {/* <Tooltip
                  cursor={{ fill: "#fff" }} // remove default hovering grey bg
                  filterNull={false}
                  content={<CustomizedTooltip meetingRoomData={data} />}
                /> */}
                <XAxis
                  axisLine={false}
                  dataKey="count"
                  tick={<CustomizedLabel />}
                  tickSize={0}
                />
                <Bar
                  dataKey="count"
                  shape={<BarWithTooltip />}
                  onMouseEnter={(e, i) => setHoveredBar(i)}
                  onMouseLeave={() => setHoveredBar(-1)}
                >
                  {data.map((entry, index) => {
                    return (
                      <Cell
                        fill={getMeetingRoomGradientColor(index / 4)}
                        key={`cell-${index}`}
                      />
                    );
                  })}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </>
        )}
      </div>
    </HelpguideWrapperDiv>
  );
};

export default MeetingRoomMetrics;
