import { encode, format } from "./utils";
import { convertLineType, LineType } from "./lineType";
import { convertLayer, convertLayerShapes, Layer } from "./layer";
import { Shape } from "./shape";
import { Block, convertBlock } from "./block";
import { convertHeader, Header, Unit } from "./header";

export default class Drawing {
  comment = "";

  lineTypes: LineType[] = [];

  header: Header = { units: Unit.UnitInches };

  layers: { [k: string]: Layer } = {};

  blocks: { [k: string]: Block } = {};

  toDXFString(): string {
    let res = "";
    // if there is a comment
    if (this.comment !== "") {
      res += "999\n" + this.comment + "\n";
    }

    res += convertHeader(this.header);

    // tables
    res += format("0", "SECTION");
    res += format("2", "TABLES");

    // lineTypes
    res += format("0", "TABLE");
    res += format("2", "LTYPE");
    this.lineTypes.forEach((l) => (res += convertLineType(l)));
    res += format("0", "ENDTAB");

    // layers
    res += format("0", "TABLE");
    res += format("2", "LAYER");
    // this.layers.forEach((l) => (res += convertLayer(l)));
    Object.values(this.layers).forEach((l) => (res += convertLayer(l)));
    res += format("0", "ENDTAB");

    res += format("0", "ENDSEC");

    // blocks
    res += format("0", "SECTION");
    res += format("2", "BLOCKS");

    Object.values(this.blocks).forEach((b) => {
      for (let i = b.shapes.length - 1; i >= 0; i--) {
        const s = b.shapes[i];
        if (s.type === "insert") {
          if (!this.blocks[s.ref]) {
            b.shapes.splice(i, 1);
          }
        }
      }
    });
    Object.values(this.blocks).forEach((l) => (res += convertBlock(l)));
    res += format("0", "ENDSEC");

    // entities
    res += format("0", "SECTION");
    res += format("2", "ENTITIES");

    Object.values(this.layers).forEach((l) => (res += convertLayerShapes(l)));

    res += format("0", "ENDSEC");
    res += format("0", "EOF");

    return res;
  }

  addLineType(name: string, description: string, elements: number[]) {
    this.lineTypes.push({ type: "lineType", name, description, elements });
  }

  addLayer(
    name: string,
    color: number,
    lineTypeName: string,
    lineWeight: number,
    locked = false
  ) {
    this.layers[name] = {
      type: "layer",
      name,
      color,
      lineTypeName,
      lineWeight,
      locked,
      shapes: [],
    };
  }

  addBlock(name: string) {
    this.blocks[encode(name)] = {
      type: "block",
      name: encode(name),
      shapes: [],
    };
  }

  addLayerShape(layer: string, shape: Shape) {
    if (this.layers[layer]) this.layers[layer].shapes.push(shape);
  }

  addBlockShape(block: string, shape: Shape) {
    const id = encode(block);
    if (this.blocks[id]) this.blocks[id].shapes.push(shape);
  }
}
