import { useState, ComponentType } from "react";
import { ContainerProviderProps, createContainer } from "unstated-next";
import { NavTabs } from "lib/types";

interface NavBarState {
  currentlySelected: NavTabs;
  updateNavBarState(currentlySelected: NavTabs): void;
}

export const useNavBarState = (): NavBarState => {
  const [currentlySelected, updateNavBarState] = useState<NavTabs>(
    NavTabs.Home
  );

  return {
    updateNavBarState,
    currentlySelected,
  };
};

export const NavBarController = createContainer(useNavBarState);
export const NavBarProvider: ComponentType<ContainerProviderProps> =
  NavBarController.Provider;
export const useNavBarCtrl = () => NavBarController.useContainer();
