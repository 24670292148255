import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Colors } from "@outerlabs/ol-ui";

export interface Props {
  color: string;
  programCategory: string;
  area: number;
  children?: any;
  depth: number;
}

const useStyles = makeStyles(() => ({
  programAccordion: {
    "&:before": {
      display: "none",
    },
    backgroundColor: "rgba(0,0,0,0)",
    "&.Mui-expanded": {
      margin: "0!important",
    },
    width: "100%",
  },

  accordionSummary: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    paddingLeft: 16,
    paddingRight: 16,
    backgroundColor: "rgba(0,0,0,0)",
    "&.Mui-expanded": {
      minHeight: "auto!important",
      margin: "0!important",
    },
    fontSize: 14,
    //border: "solid blue 1px",
  },

  text: {
    paddingLeft: 21,
    flexGrow: 2,
    //border: "solid pink 1px",
  },

  accordionDetails: {
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
    flexDirection: "column",
    backgroundColor: "#F4F4F6",
  },

  summaryContent: {
    "&.Mui-expanded": {
      margin: "12px 0",
    },
  },
}));

// `swatch` is from Clay
const swatch = (color: string) => (
  <div
    style={{
      backgroundColor: color,
      height: 20,
      width: 20,
      borderRadius: 4,
    }}
  />
);

export const ProgramAreaAccordian: React.FC<Props & AccordionProps> = ({
  color,
  programCategory,
  area,
  children,
  expanded,
  onChange,
  depth,
}) => {
  const classes = useStyles();
  const displayArea = Math.floor(area).toLocaleString("us-EN") + " SF";
  let textColor = Colors.Dark6;
  if (depth === 1) textColor = Colors.Dark3;
  if (depth === 2) textColor = Colors.Dark1;

  return (
    <Accordion
      elevation={0}
      classes={{ root: classes.programAccordion }}
      expanded={expanded}
      onChange={onChange}
    >
      <AccordionSummary
        classes={{
          root: classes.accordionSummary,
          content: classes.summaryContent,
        }}
      >
        {swatch(color)}
        <div className={classes.text} style={{ color: textColor }}>
          {programCategory}
        </div>
        <div style={{ color: textColor }}>{displayArea}</div>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.accordionDetails }}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};
