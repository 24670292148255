import { format } from "./utils";

export type Solid = {
  type: "solid";
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  x3: number;
  y3: number;
  x4: number;
  y4: number;
};

export const solid = ({ x1, x2, x3, x4, y1, y2, y3, y4 }: Solid): string => {
  return (
    format("0", "SOLID") +
    format("10", x1) +
    format("20", y1) +
    format("30", 0) +
    format("11", x2) +
    format("21", y2) +
    format("31", 0) +
    format("12", x3) +
    format("22", y3) +
    format("32", 0) +
    format("13", x4) +
    format("23", y4) +
    format("33", 0)
  );
};
