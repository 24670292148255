import React, { ComponentType, FC } from "react";
import { BuildingsProvider } from "../containers";

function withBuildings<Props>(Component: ComponentType<Props>): FC<Props> {
  return (props) => (
    <BuildingsProvider>
      <Component {...props} />
    </BuildingsProvider>
  );
}

export default withBuildings;
