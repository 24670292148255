import { Coordinate, Data, Drawable } from "../types";
import { rotatePointAround } from "./utils";
import { Point } from "@outerlabs/shapes-geometry";
import { Block } from "../../blocks/lib/types";

export const polygonContains = (
  point: Coordinate,
  region: number[][]
): boolean => {
  const { x, y } = point;
  let inside = false;
  const len = region.length;
  for (let i = 0, j = len - 1; i < len; j = i++) {
    const ri = region[i];
    const rj = region[j];

    if (!ri || !rj) {
      continue;
    }

    const xi = ri[0];
    const yi = ri[1];
    const xj = rj[0];
    const yj = rj[1];
    const yiGTy = yi > y;
    const yjGTy = yj > y;
    const intersect =
      yiGTy !== yjGTy && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }
  return inside;
};

export const blockContains = (point: Point, block: Block): boolean => {
  const [x, y] = point;
  const { type, box, polygon } = block.geometry;
  if (type === "box" && box) {
    return (
      point[0] >= box[0][0] &&
      point[0] <= box[1][0] &&
      point[1] >= box[1][0] &&
      point[1] <= box[1][1]
    );
  } else if (type === "polygon" && polygon) {
    const points = polygon[0];
    let inside = false;
    const len = points.length;
    for (let i = 0, j = len - 1; i < len; j = i++) {
      const ri = points[i];
      const rj = points[j];
      if (!ri || !rj) {
        continue;
      }
      const xi = ri[0];
      const yi = ri[1];
      const xj = rj[0];
      const yj = rj[1];
      const yiGTy = yi > y;
      const yjGTy = yj > y;
      const intersect =
        yiGTy !== yjGTy && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
      if (intersect) inside = !inside;
    }
    return inside;
  }
  return false;
};

export const shouldRender = (
  d: Drawable,
  offsetX: number,
  offsetY: number,
  triGross: number[][]
): boolean => {
  if (!triGross.length) {
    return true;
  }

  return d.coordinates.reduce<boolean>((acc, p) => {
    if (acc) return acc;

    return polygonContains({ x: p.x + offsetX, y: p.y + offsetY }, triGross);
  }, false);
};

export const asRegion = (d: Data): number[][] => {
  const rootEnts = d.ENTITIES;
  if (!rootEnts) return [];

  const childEnts = rootEnts.ENTITIES;
  if (!childEnts) return [];

  const region: number[][] = [];
  childEnts.forEach((ent: any) => {
    ent.coords.forEach((c: Coordinate) => {
      region.push([c.x, c.y]);
    });
  });

  return region;
};

export const rotateDrawable = (
  d: Drawable,
  cx: number,
  cy: number,
  angle: number
): Drawable => {
  const rotated: Drawable = {
    ...d,
    coordinates: [],
  };

  rotated.coordinates = d.coordinates.map((c) => {
    const [rx, ry] = rotatePointAround(c.x, c.y, cx, cy, angle);
    return { x: rx, y: ry };
  });

  return rotated;
};
