import React from "react";

import { Project } from "lib/types";
import Title from "components/title";

interface Props {
  onBlur: (value: Project) => void;
  project: Project;
}

export const ProjectTitle: React.FC<Props> = ({ onBlur, project }) => {
  const handleBlur = (value: string) => {
    onBlur({ ...project, name: value });
  };

  return <Title title={project.name || ""} onBlur={handleBlur} />;
};

export default ProjectTitle;
