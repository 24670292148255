import { format } from "./utils";

export type Line = {
  type: "line";
  x1: number;
  y1: number;
  x2: number;
  y2: number;
};

export const line = ({ x1, x2, y1, y2 }: Line): string => {
  // prettier-ignore
  return (
    format("0", "LINE") +
    format("10", x1) +
    format("20", y1) +
    format("30", 0) +
    format("11", x2) +
    format("21", y2) +
    format("31", 0)
  )
};
