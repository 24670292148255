import React from "react";
import ReactDOM from "react-dom";
import "./styles/index.css";
import Routes from "./routes";
import * as serviceWorker from "./serviceWorker";
import {
  createGenerateClassName,
  createTheme,
  StylesProvider,
} from "@material-ui/core/styles";
// later versions of this break icons
// eslint-disable-next-line import/no-extraneous-dependencies
import { ThemeProvider } from "@material-ui/styles";
import { HelpguideProvider, HelpguideApp } from "@outerlabs/helpguide";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const theme = createTheme({
  palette: {
    primary: {
      main: "#4384f7",
    },
  },
  typography: {
    fontSize: 12,
    fontFamily: "GoogleSans,Roboto,sans-serif",
  },
  overrides: {
    MuiIcon: {
      root: {
        color: "#828282",
      },
    },
  },
});

window.React = React;

// Each app should have its own namespaced <styleprovider> component.
// For more info check out the helpguide readme:
// https://gitlab.com/outerlabs/helpguide#some-of-my-makestyles-classes-are-conflicting
const genClassName = createGenerateClassName({
  seed: "clay-portfolio::",
  productionPrefix: "clay-portfolio::",
});

ReactDOM.render(
  <React.StrictMode>
    <HelpguideProvider app={HelpguideApp.Portfolio}>
      <StylesProvider injectFirst generateClassName={genClassName}>
        <DndProvider backend={HTML5Backend}>
          <ThemeProvider theme={theme}>
            <Routes />
          </ThemeProvider>
        </DndProvider>
      </StylesProvider>
    </HelpguideProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
