import React, { useCallback, useMemo, useState } from "react";
import { Canvas } from "@outerlabs/canvas-reconciler";
import {
  renderAnchors,
  renderPortfolioBlockInstance,
  renderRegionBackground,
  renderRegionOutline,
} from "lib/isp-canvas";
import {
  getMxMy,
  snap,
  calcDeltas,
  getAnchorDirection,
  getRegionCenter,
  getRegionLengths,
  getResizeData,
  resizeRegionFromCenter,
  selectCursor,
  toRegion,
  toRotation,
  getBoundingBox,
  rotateCenter,
} from "lib/isp-canvas/utils";
import { PortfolioBlockInstance, XForm } from "lib/types";
import { RendererMode } from "lib/containers";
import generatePortfolioBlockInstances from "lib/isp-canvas/generate-block-instance";
import { Block, BlockGetter } from "blocks/lib/types";
import { mat4, vec3 } from "gl-matrix";
import { Point } from "@outerlabs/shapes-geometry";
import { cloneBlock } from "../../blocks/lib/util";
import { getters } from "../../blocks/lib/constants";

interface Props {
  xform: XForm;
  active: boolean;
  handleUpdateInstance({
    instance,
    resize,
  }: {
    instance: PortfolioBlockInstance[][];
    resize: boolean;
  }): void;
  blocks: Block[];
  getBlockById: BlockGetter;
  setRendererMode(mode: RendererMode): void;
  transformBlockInstance?: PortfolioBlockInstance[][];
  setTransformBlockInstance(instance: PortfolioBlockInstance[][]): void;
  instances?: PortfolioBlockInstance[][];
  wpiActivitiesMode: boolean;
}

export const Transform: React.FC<Props> = ({
  xform,
  active,
  handleUpdateInstance,
  blocks,
  getBlockById,
  setRendererMode,
  transformBlockInstance,
  setTransformBlockInstance,
  instances,
  wpiActivitiesMode,
}) => {
  const [cursor, setCursor] = useState<string | false>(false);
  const [anchor, setAnchor] = useState<string | false>(false);
  const [moving, setMoving] = useState<boolean>(false);
  const [copy, setCopy] = useState<Block | undefined>();
  const [matrices, setMatrices] = useState<mat4[]>([]);
  const [start, setStart] = useState<number[] | undefined>(undefined);
  const rotation: number[] = useMemo(
    () =>
      transformBlockInstance
        ? transformBlockInstance.map((inst) => toRotation(inst[0]?.matrix))
        : [0],
    [transformBlockInstance]
  );

  const blockInstances: PortfolioBlockInstance[][] | undefined =
    transformBlockInstance ? transformBlockInstance : instances;
  const bounds =
    blockInstances && blockInstances.length > 1
      ? getBoundingBox(blockInstances.map((bi) => bi[0]))
      : instances && instances.length > 0
      ? toRegion(instances[0][0])
      : false;

  const setAnchorAndCursor = useCallback(
    (mx: number, my: number, mouseMoving?: boolean) => {
      if (!instances || !bounds) return;
      const a = getAnchorDirection(
        mx,
        my,
        bounds,
        instances.length === 1 ? rotation[0] : 0
      );
      const c = selectCursor(a, rotation[0]);
      if (!mouseMoving) {
        setAnchor(a);
        if (a === "drag") setMoving(true);
      }
      setCursor(c);
    },
    [bounds, instances, rotation]
  );

  const handleTransformations = useCallback(
    ({
      offsetX,
      offsetY,
      e,
    }: {
      offsetX: number;
      offsetY: number;
      movementX: number;
      movementY: number;
      e: MouseEvent;
    }): PortfolioBlockInstance[][] | undefined => {
      const [mx, my] = getMxMy(offsetX, offsetY, xform);
      if (!instances || !blockInstances) return;
      let result = blockInstances;
      const { shiftKey } = e;
      const block = blockInstances[0][0];
      if (
        anchor &&
        anchor !== "rotate" &&
        !moving &&
        start &&
        instances.length === 1
      ) {
        // resizing - disabled for multiselect
        const [sx, sy] = snap([mx, my]);
        const localBlock = blocks.find((t) => t.id === block.symbol);
        if (!localBlock || !copy) return;
        const metrics = getters.getMetrics(localBlock);
        let { flexibility } = getters.getLayout(localBlock);
        if (!flexibility) flexibility = [0, 0];
        const _rotation = toRotation(block.matrix);
        let { deltaW, deltaH } = calcDeltas(
          sx,
          sy,
          start[0],
          start[1],
          _rotation
        );
        const [centerX, centerY] = getRegionCenter(toRegion(copy));
        const [width, height] = getRegionLengths(toRegion(copy));
        const rect = { width, height, centerX, centerY, rotation: _rotation };
        if (anchor.includes("w")) {
          deltaW = Math.max(
            Math.min(deltaW, width - metrics.sizeRange[0][0]),
            width - metrics.sizeRange[0][1] - flexibility[0]
          );
        } else if (anchor.includes("e")) {
          deltaW = Math.min(
            Math.max(deltaW, -(width - metrics.sizeRange[0][0])),
            metrics.sizeRange[0][1] - width + flexibility[0]
          );
        }
        if (anchor.includes("n")) {
          deltaH = Math.max(
            Math.min(deltaH, height - metrics.sizeRange[1][0]),
            height - metrics.sizeRange[1][1] - flexibility[1]
          );
        } else if (anchor.includes("s")) {
          deltaH = Math.min(
            Math.max(deltaH, -(height - metrics.sizeRange[1][0])),
            metrics.sizeRange[1][1] - height + flexibility[1]
          );
        }
        const resizeData = getResizeData(anchor, rect, deltaW, deltaH);
        const nr = resizeRegionFromCenter(resizeData);
        const tx = mat4.fromTranslation(
          mat4.create(),
          vec3.fromValues(resizeData.ox, resizeData.oy, 0)
        );
        if (nr) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const matrix = mat4.mul(mat4.create(), copy.matrix!, tx);
          result = [
            generatePortfolioBlockInstances({
              block: localBlock,
              matrix: matrix,
              region: nr,
              regionRotation: _rotation,
              blockRotation: _rotation,
              getBlockById,
            }),
          ];
          result[0][0].props.layout = block.props.layout;
        }
      } else if (anchor && anchor === "drag" && moving) {
        // dragging -- enabled for multi-select
        const [sx, sy] = snap([mx, my]);
        if (!start) return;
        const dx = sx - start[0];
        const dy = sy - start[1];
        result = blockInstances.map((inst, i) => {
          inst[0].matrix = mat4.mul(
            mat4.create(),
            mat4.fromTranslation(mat4.create(), vec3.fromValues(dx, dy, 0)),
            matrices[i]
          );
          return inst;
        });
      } else if (
        anchor &&
        anchor === "rotate" &&
        !moving &&
        start &&
        instances.length === 1
      ) {
        // rotating - disabled for multi-select
        const {
          matrix,
          geometry: { box },
        } = instances[0][0];
        if (box && matrix) {
          const b = [
            vec3.fromValues(box[0][0], box[1][1], 0),
            vec3.fromValues(box[0][0], box[0][1], 0),
            vec3.fromValues(box[1][0], box[0][1], 0),
            vec3.fromValues(box[1][0], box[1][1], 0),
          ];
          const rot = toRotation(matrix);
          const [width, height] = getRegionLengths(toRegion(instances[0][0]));
          const t: Point[] = b
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            .map((p) => vec3.transformMat4(vec3.create(), p, matrix!))
            .map((p) => [p[0], p[1]]);
          const c: Point = [
            (t[0][0] + t[1][0] + t[2][0] + t[3][0]) / 4,
            (t[0][1] + t[1][1] + t[2][1] + t[3][1]) / 4,
          ];
          const r: Point = [mx - c[0], my - c[1]];
          const mag = Math.sqrt(r[0] * r[0] + r[1] * r[1]);
          const norm: Point = [r[0] * mag, r[1] * mag];
          const a = Math.atan2(norm[1], norm[0]);
          let deg = (180 * a) / Math.PI;
          deg += rot;
          deg = (360 + Math.round(deg - 90)) % 360;
          if (!shiftKey) {
            deg = Math.round(deg / 15) * 15;
            deg += rot - Math.round(rot / 15) * 15;
          }
          result = [
            result[0].map((_r) => ({
              ..._r,
              matrix: rotateCenter(
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                matrix!,
                width,
                height,
                deg * (Math.PI / 180)
              ),
            })),
          ];
        }
      }
      return result;
    },
    [
      anchor,
      moving,
      start,
      blocks,
      xform,
      matrices,
      blockInstances,
      instances,
      getBlockById,
      copy,
    ]
  );

  const onMouseDown = useCallback(
    (e: any) => {
      const { offsetX, offsetY } = e;
      const [mx, my] = getMxMy(offsetX, offsetY, xform);
      setAnchorAndCursor(mx, my);
      setStart([mx, my]);
      setRendererMode(RendererMode.Transforming);
      if (!instances) return;
      setCopy(cloneBlock(instances[0][0]));
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      setMatrices(instances.map((inst) => inst[0].matrix!));
      e.stopPropagation();
      e.preventDefault();
    },
    [xform, setAnchorAndCursor, setRendererMode, instances]
  );

  const onRelease = useCallback(
    async (e: any) => {
      // this is hit first, before anything else runs. resetSelect runs after this off-click is completed
      setAnchor(false);
      setCursor(false);
      setMoving(false);
      setStart(undefined);

      // this always runs if you select a block and then do anything else
      if (transformBlockInstance) {
        if (transformBlockInstance.length === 1) {
          await handleUpdateInstance({
            instance: transformBlockInstance,
            resize: true,
          });
        } else
          handleUpdateInstance({
            instance: transformBlockInstance,
            resize: false,
          });
      }

      e.preventDefault();
      e.stopPropagation();
    },
    [handleUpdateInstance, transformBlockInstance]
  );

  const onMouseMove: any = useCallback(
    (e: MouseEvent) => {
      const { offsetX, offsetY, movementX, movementY } = e;
      if (!anchor || !cursor) {
        const [mx, my] = getMxMy(offsetX, offsetY, xform);
        setAnchorAndCursor(mx, my, true);
      }

      if (!start) return;
      const ni = handleTransformations({
        offsetX,
        offsetY,
        movementX,
        movementY,
        e,
      });
      if (ni) {
        setTransformBlockInstance(ni);
      }
      e.preventDefault();
      e.stopPropagation();
    },
    [
      anchor,
      cursor,
      start,
      handleTransformations,
      setTransformBlockInstance,
      xform,
      setAnchorAndCursor,
    ]
  );

  const eventHandler = active ? (
    <div
      onMouseMove={onMouseMove}
      onMouseDown={onMouseDown}
      onMouseUp={onRelease}
    />
  ) : null;

  const activeCursor = cursor && active ? <cursor cursor={cursor} /> : null;
  const canvas = active ? (
    <Canvas
      render={async (ctx: CanvasRenderingContext2D) => {
        if (blockInstances) {
          Promise.all(
            blockInstances.map(async (inst) => {
              await renderPortfolioBlockInstance({
                ctx,
                instances: inst,
                getBlockById,
                wpiActivitiesMode,
              });
              renderRegionOutline(
                ctx,
                toRegion(inst[0]),
                xform.scale,
                toRotation(inst[0].matrix)
              );
              if (blockInstances.length === 1)
                renderAnchors(
                  ctx,
                  toRegion(inst[0]),
                  toRotation(inst[0].matrix),
                  xform.scale / xform.globalScale
                );
            })
          );

          if (bounds && blockInstances.length > 1) {
            renderRegionBackground(ctx, bounds, xform.scale);
          }
        }
      }}
    />
  ) : null;

  return (
    <>
      {eventHandler}
      {activeCursor}
      {canvas}
    </>
  );
};

export default Transform;
