import React, { ComponentType, FC } from "react";
import { MatchMakerProvider } from "../containers";

function withMatchMaker<Props>(Component: ComponentType<Props>): FC<Props> {
  return (props) => (
    <MatchMakerProvider>
      <Component {...props} />
    </MatchMakerProvider>
  );
}

export default withMatchMaker;
