import React from "react";
import { NavButton, NavGroup } from "@outerlabs/ol-ui";
import { Settings } from "@material-ui/icons";
import { useHistory, useParams } from "react-router-dom";

interface Props {
  navTab: "blocks" | "assets" | "edit" | "sandbox";
}

const Nav: React.FC<Props> = ({ navTab }) => {
  const history = useHistory();
  const { id } = useParams<{ [key: string]: string }>();

  return (
    <NavGroup>
      <NavButton
        tooltipTitle="Assets"
        selected={navTab === "assets"}
        onClick={() => {
          history.push(`/assets`);
        }}
      >
        <IconAssets />
      </NavButton>
      <NavButton
        tooltipTitle={"Blocks"}
        selected={navTab === "blocks"}
        onClick={() => {
          history.push(`/blocks`);
        }}
      >
        <IconBlocks />
      </NavButton>
      <NavButton
        tooltipTitle={navTab !== "edit" ? "" : "Block Edit"}
        disabled={navTab !== "edit"}
        selected={navTab === "edit"}
        onClick={() => {
          history.push(`/blocks/${id}`);
        }}
      >
        <Settings />
      </NavButton>
    </NavGroup>
  );
};

const IconAssets = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="1" y="9" width="8" height="8" stroke="#BCBCBC" strokeWidth="2" />
    <rect x="9" y="1" width="8" height="8" stroke="#BCBCBC" strokeWidth="2" />
    <rect x="9" y="9" width="8" height="8" stroke="#BCBCBC" strokeWidth="2" />
    <rect x="1" y="1" width="8" height="8" stroke="#555555" strokeWidth="2" />
  </svg>
);

const IconBlocks = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="1" y="9" width="8" height="8" stroke="#555555" strokeWidth="2" />
    <rect x="9" y="1" width="8" height="8" stroke="#555555" strokeWidth="2" />
    <rect x="9" y="9" width="8" height="8" stroke="#555555" strokeWidth="2" />
    <rect x="1" y="1" width="8" height="8" stroke="#555555" strokeWidth="2" />
  </svg>
);

export default Nav;
