import { Point, polygonizeCurve } from "./utils";
import { polyline } from "./polyline";

export type RoundedRectangle = {
  type: "roundedRectangle";
  points: Point[];
  radius: [number, number, number, number];
};

export const roundedRectangle = (
  { points, radius }: RoundedRectangle,
  layerName: string
): string => {
  const x = 0;
  const y = 0;
  const w = points[1][0] - points[0][0];
  const h = points[1][1] - points[0][1];
  return polyline(
    {
      type: "polyline",
      points: [
        [x + radius[0], y],
        [x + w - radius[1], y],
        ...polygonizeCurve(
          [
            [x + w - radius[1], y],
            [x + w, y],
            [x + w, y + radius[1]],
          ],
          6
        ),
        [x + w, y + h - radius[2]],
        ...polygonizeCurve(
          [
            [x + w, y + h - radius[2]],
            [x + w, y + h],
            [x + w - radius[2], y + h],
          ],
          6
        ),
        [x + radius[3], y + h],
        ...polygonizeCurve(
          [
            [x + radius[3], y + h],
            [x, y + h],
            [x, y + h - radius[3]],
          ],
          6
        ),
        [x, y + radius[0]],
        ...polygonizeCurve(
          [
            [x, y + radius[0]],
            [x, y],
            [x + radius[0], y],
          ],
          6
        ),
      ],
    },
    layerName
  );
};
