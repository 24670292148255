import calculatePotentialSeats from "lib/metrics/calculate-potential-seats";
import { Features, FloorMetricUpdate, Metric, StrategyMode } from "lib/types";
import {
  calculateCurrentDesks,
  disableCirculationDesks,
  removeRegionDesks,
  updateDistanceDesks,
} from "lib/metrics/remove-desks";
import { emptyFloorMetricUpdate } from "./constants";
import { metricDisable } from "./metricDisable";
import { metricConvertToWorkStation } from "./metricConvertToWorkstation";
import {
  addIndividualCollaborationRooms,
  addIndividualRooms,
  subtractIndividualCollaborationRooms,
  subtractIndividualRooms,
} from "./metricIndividualRoomOperations";
import { metricConvertToCollaboration } from "./metricConvertCollaboration";
import { Block } from "../../blocks/lib/types";
import { getters } from "../../blocks/lib/constants";
import { mergeChildMetrics } from "../../blocks/lib/blocks";
import { getNookAssignedSeats, getNooks } from "./nook-calculations";
import { getDesks } from "./desk-calculations";
import { getBooths } from "./phonebooth-calculations";

export const calculateFloorMetrics = ({
  metric,
  blocks,
  features,
}: {
  metric: Metric;
  blocks?: Block[][];
  features: Features;
}): Metric => {
  const { corridorWidth, deskSpacing, desksOff } = metric;
  const { uniqueDesks, primaryCirculation } = features;
  let blockDoors = 0;

  blocks?.forEach((block) => {
    blockDoors += block[0].props.metrics?.doors || 0;
  });

  const disabledCirculationDesks = disableCirculationDesks(
    uniqueDesks,
    corridorWidth,
    primaryCirculation
  );
  const disabledDistanceDesks = updateDistanceDesks(uniqueDesks, deskSpacing);

  // either remove all desks or check if any blocks are covering existing desks
  const removedDesks = desksOff
    ? uniqueDesks
    : blocks
    ? removeRegionDesks(uniqueDesks, blocks)
    : [];

  const { disabledCenters, keptCenters } = calculateCurrentDesks({
    desks: uniqueDesks,
    removedDesks,
    disabledCirculationDesks,
    disabledDistanceDesks,
  });

  const removed = disabledCenters.length + removedDesks.length;
  const kept = keptCenters.length;

  const update: FloorMetricUpdate = {
    ...emptyFloorMetricUpdate,
    conferenceRoomSeats: metric.baselineConferenceRoomsCapacity,
    conferenceRoomSeatsPerHC: metric.baselineConferenceRoomsSeatsPerHC,
    potentialSeats: calculatePotentialSeats("potentialSeats", metric),
    baselineSeatCount: metric.baselineSeatCount || kept + removed,
  };

  update.seatCount = update.baselineSeatCount - removed;
  const { convertToWorkstations, disable, convertToCollaboration } =
    metric?.userConvertedMeetingRooms || {};
  const [c, d, ctc] = [
    convertToWorkstations,
    disable,
    convertToCollaboration,
  ].map((group) => group?.map((i) => i.conferenceRoomId) || []);
  const convertToWorkstationsCount = metricConvertToWorkStation(c, metric);
  update.seatCount += convertToWorkstationsCount.workstations;
  update.convertedSeats += convertToWorkstationsCount.workstations;
  update.convertedMeetingRooms += convertToWorkstationsCount.count;
  update.conferenceRoomSeats += convertToWorkstationsCount.capacity;

  const disableCount = metricDisable(d, metric);
  update.conferenceRoomSeats += disableCount.capacity;
  update.convertedMeetingRooms += disableCount.count;

  const metricAddCount = addIndividualRooms(metric);
  update.seatCount += metricAddCount.workstations;
  update.convertedSeats += metricAddCount.workstations;
  update.convertedMeetingRooms += metricAddCount.count;
  update.conferenceRoomSeats += metricAddCount.capacity;

  const metricSubtractCount = subtractIndividualRooms(metric);
  update.seatCount += metricSubtractCount.workstations;
  update.convertedSeats += metricSubtractCount.workstations;
  update.convertedMeetingRooms -= metricSubtractCount.count;
  update.conferenceRoomSeats += metricSubtractCount.capacity;

  const convertToCollaborationCounts = metricConvertToCollaboration(
    features,
    ctc
  );
  update.convertedMeetingRooms += convertToCollaborationCounts.collabZones;
  update.collaborationSeats += convertToCollaborationCounts.collabCount;
  update.collaborationZones += convertToCollaborationCounts.collabZones;
  update.collaborationZonesArea += convertToCollaborationCounts.collabArea;

  const addCollaboration = addIndividualCollaborationRooms(metric, features);
  update.collaborationSeats += addCollaboration.collabCount;
  update.collaborationZones += addCollaboration.collabZones;
  update.collaborationZonesArea += addCollaboration.collabArea;

  if (convertToCollaboration?.length !== 0) {
    const subtractCollaboration = subtractIndividualCollaborationRooms(
      metric,
      features
    );
    update.convertedMeetingRooms += subtractCollaboration.collabZones;
    update.collaborationSeats += subtractCollaboration.collabCount;
    update.collaborationZones += subtractCollaboration.collabZones;
    update.collaborationZonesArea += subtractCollaboration.collabArea;
  }

  if (metric.mode === StrategyMode.Zone && blocks) {
    const blockMetrics = mergeChildMetrics(
      blocks
        .filter((el) => el.length > 0)
        .map((block) => {
          return getters.getMetrics(block[0]);
        })
    );

    const collab = blockMetrics.types.collaboration || {
      area: 0,
      seats: 0,
      tags: {},
      names: {},
    };
    update.totalCost = blockMetrics.totalCost;
    update.costHC = blockMetrics.costHC;
    update.costUnit = blockMetrics.costUnit;
    update.costGSF = blockMetrics.costGSF;
    update.seatCount += blockMetrics.seats;
    update.workpoints += blockMetrics.workpoints;
    update.dedicatedSeats += blockMetrics.seats;
    update.flexSeats += blockMetrics.seats;
    update.collaborationZones += Object.values(collab.names).reduce(
      (a, b) => a + b,
      0
    );
    update.collaborationZonesArea += collab.area / 144;
    update.collaborationSeats += collab.seats;
    // total count of all nooks
    update.nooksCount = getNooks(blocks) || 0;
    // total count of all assignableSeats in nooks
    update.nooksAssignable = getNookAssignedSeats(blocks) || 0;
    update.deskCount = getDesks(blocks) || 0;
    update.phonesCount = getBooths(blocks) || 0;
    update.assetHC += blockMetrics.headcount;
  }
  update.floorscopeDesks = update.baselineSeatCount - removed;
  update.assignableSeats =
    (update.floorscopeDesks || 0) +
    (update.nooksAssignable || 0) +
    (update.deskCount || 0);

  // calculate dependant metrics
  update.conferenceRoomSeatsPerHC =
    update.conferenceRoomSeats / update.seatCount;

  update.doors +=
    blockDoors +
    (metric.small ? metric.small.count : 0) +
    (metric.medium ? metric.medium.count : 0) +
    (metric.large ? metric.large.count : 0) +
    (metric.extraLarge ? metric.extraLarge.count : 0) +
    (metric.huddle ? metric.huddle.count : 0) +
    (metric.phone ? metric.phone.count : 0) +
    metric.phonesCount -
    metric.convertedMeetingRooms;

  return { ...metric, ...update };
};
