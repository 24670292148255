import React, { useState } from "react";
import { makeStyles, Tooltip } from "@material-ui/core";
import { Hint, RadialChart, RadialChartPoint } from "react-vis";
import { colors } from "lib/isp-canvas/constants";
import { emptyMetricsProp, getters } from "../../blocks/lib/constants";
import { formatToInt } from "../../lib/number";
import { mergeChildMetrics } from "../../blocks/lib/blocks";
import { Block } from "../../blocks/lib/types";
import { useRendererCtrl, useSettingsCtrl } from "lib/containers";
import {
  getBlockAreas,
  getCommunalMetrics,
  getIndividualMetrics,
  getProgramColor,
  getProgramDisplayName,
} from "lib/metrics/wpi-metric-helpers";

const useStyles = makeStyles({
  zoneHeaderContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    gap: 20,
    alignItems: "flex-start",
    height: "200px",
  },
  selectedCount: {
    fontStyle: "italic",
    fontSize: "12px",
    position: "relative",
    top: -28,
    color: "#919499",
  },
  radialPercentageIndicator: {
    width: "115px",
    height: "115px",
    borderRadius: "50%",
    border: "10px solid #FFBD02",
    position: "relative",
    top: "25px",
  },
  radialChartContainer: {
    position: "relative",
    top: "25px",
  },
  metricInsidePiechartContainer: {
    position: "relative",
    bottom: "65px",
    left: "0px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: 60,
  },
  metricValue: {
    fontFamily: "GoogleSansRegular",
    fontSize: 20,
    lineHeight: 1,
    color: "#555555",
  },
  metricTitle: {
    fontFamily: "GoogleSansRegular",
    fontSize: 12,
    color: "#919499",
  },
  rightContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: 200,
  },
  leftContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    width: 90,
  },
  summaryValue: {
    marginBottom: 12,
  },
  zoneSummaryTitle: {
    fontFamily: "GoogleSansRegular",
    fontSize: "16.57px",
    lineHeight: "24px",
    color: "#878787",
    whiteSpace: "pre-line",
  },
  zoneSummaryNumber: {
    fontFamily: "GoogleSansRegular",
    fontSize: "32px",
    lineHeight: "37.5px",
    color: "#555555",
  },
  zoneSummarySubtitle: {
    fontFamily: "GoogleSansRegular",
    color: "#878787",
    fontSize: 14,
    lineHeight: "20px",
  },
  pieChartLegenContainer: {
    marginTop: "10px",
    width: "150px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  singleLegendContainer: {
    width: "150px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  colorCircle: {
    width: "9px",
    height: "9px",
    borderRadius: "50%",
  },
  legendName: {
    paddingLeft: "10px",
  },
  tooltip: {
    maxWidth: "80px",
    textAlign: "center",
    whiteSpace: "pre-line",
  },
});

interface Props {
  blocks: Block[];
}

const ZoneHeaderFocus: React.FC<Props> = ({ blocks }) => {
  const { wpiActivitiesMode, selectedRegion } = useRendererCtrl();
  const { currentSettings } = useSettingsCtrl();
  const classes = useStyles();
  const baseMetrics = { ...emptyMetricsProp };
  const [tooltipVal, setTooltipVal] = useState<RadialChartPoint>();
  let tooltipContent = "";

  let metrics = baseMetrics;

  blocks.forEach((block) => {
    metrics = mergeChildMetrics([metrics, getters.getMetrics(block)]);
  });

  let data: Record<string, any>[] = [];
  let collabArea: number;
  let focusArea: number;

  if (selectedRegion) {
    const selectedInstances = selectedRegion.map(
      (regionIndex) => currentSettings.blocks[regionIndex]
    );
    const blockAreas = getBlockAreas(selectedInstances);

    // load data for radial chart for all selected blocks
    for (const programType in blockAreas) {
      if (blockAreas[programType].total !== 0) {
        data.push({
          angle: blockAreas[programType].total,
          color: getProgramColor(programType),
          label: getProgramDisplayName(programType),
        });
      }
    }

    // if selected blocks only has one type of program, and that program has
    // more than one L1 category, overwrite data to show the breakdown of that
    // program into its L1 categories
    if (data.length === 1) {
      // get the program type that has area
      const nonZeroProgram = Object.keys(blockAreas).find(
        (key) => blockAreas[key].total !== 0
      );
      if (nonZeroProgram) {
        const data1 = [];
        for (const L1 in blockAreas[nonZeroProgram]) {
          if (L1 !== "total") {
            data1.push({
              angle: blockAreas[nonZeroProgram][L1].total,
              color: getProgramColor(L1),
              label: getProgramDisplayName(L1),
            });
          }
        }

        // data1[0].angle can be undefined in a case where selected blocks only
        // have a single program type, but that program type does not breakdown
        // into more than one L1.
        // for example, {meeting: {meetingRoom: 250, total: 250}}
        if (data1[0].angle !== undefined) {
          data = data1;
        }
      }
    }

    if (wpiActivitiesMode) {
      const communalData = getCommunalMetrics(selectedInstances);
      const individualData = getIndividualMetrics(selectedInstances);

      if (communalData.area !== 0 || individualData.area !== 0) {
        data = [];

        if (communalData.area !== 0) {
          data.push({
            angle: communalData.area,
            color: colors.collabColor,
            label: "Communal",
          });
          collabArea = communalData.area;
        }

        if (individualData.area !== 0) {
          data.push({
            angle: individualData.area,
            color: colors.focusColor,
            label: "Individual",
          });
          focusArea = individualData.area;
        }
      }
    }
    // manage tooltips
    if (tooltipVal && tooltipVal?.label) {
      tooltipContent = tooltipVal.label;

      let area = 0;
      let totalArea = 0;
      data.forEach((section) => {
        if (section.color === tooltipVal.color) {
          area = section.angle;
        }
        totalArea += section.angle;
      });

      area = Math.round((area / totalArea) * 100);

      tooltipContent += "\n" + area.toString() + "%";
    }
  }

  const formatActivitiesArea = (
    activitiesCollabArea: number,
    activitiesFocusArea: number
  ) => {
    const totalArea = (activitiesCollabArea | 0) + (activitiesFocusArea | 0);
    const collabPercent = Math.round(
      ((activitiesCollabArea | 0) / totalArea) * 100
    );
    const focusPercent = Math.round(
      ((activitiesFocusArea | 0) / totalArea) * 100
    );

    return `${focusPercent}/${collabPercent}`;
  };

  return (
    <div>
      <div className={classes.zoneHeaderContainer}>
        <div className={classes.leftContainer}>
          <div className={classes.radialChartContainer}>
            <RadialChart
              data={data}
              width={130}
              height={130}
              radius={60}
              innerRadius={50}
              padAngle={0.05}
              colorType="literal"
              onValueMouseOver={(v) => {
                setTooltipVal(v);
              }}
              onValueMouseOut={() => setTooltipVal(undefined)}
            >
              {tooltipVal !== undefined && !wpiActivitiesMode && (
                <Hint value={tooltipVal}>
                  {/* Mui tooltip used here as a shortcut to styling */}
                  <Tooltip
                    title={tooltipContent}
                    open={true}
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <div />
                  </Tooltip>
                </Hint>
              )}
            </RadialChart>
          </div>
          <div className={classes.metricInsidePiechartContainer}>
            {wpiActivitiesMode && (
              <>
                <div className={classes.metricValue}>
                  {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
                  {formatActivitiesArea(collabArea!, focusArea!)}
                </div>
                <div className={classes.metricTitle}>
                  <span>
                    Individual/
                    <br />
                    Communal
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
        <div className={classes.rightContainer}>
          <div className={classes.column}>
            <div className={classes.zoneSummaryTitle}>{"\n"}Density</div>
            <div className={classes.summaryValue}>
              <div className={classes.zoneSummaryNumber}>
                {metrics.headcount > 0
                  ? formatToInt(metrics.area / 144 / metrics.headcount)
                  : 0}
                <div className={classes.zoneSummarySubtitle}>SF/HC</div>
              </div>
            </div>
            <div className={classes.zoneSummaryTitle}>Capacity</div>
            <div className={classes.summaryValue}>
              <div className={classes.zoneSummaryNumber}>{metrics.seats}</div>
              <div className={classes.zoneSummarySubtitle}>People</div>
            </div>
          </div>
          {!wpiActivitiesMode ? (
            <div className={classes.column}>
              <div className={classes.zoneSummaryTitle}>Total HC Supported</div>
              <div className={classes.summaryValue}>
                <div className={classes.zoneSummaryNumber}>
                  {metrics.seats}
                  <div className={classes.zoneSummarySubtitle}>People</div>
                </div>
              </div>
            </div>
          ) : (
            <div className={classes.column}>
              <div className={classes.zoneSummaryTitle}>{"\n"}Area</div>
              <div className={classes.summaryValue}>
                <div className={classes.zoneSummaryNumber}>
                  {formatToInt(metrics.area / 144)}
                  <div className={classes.zoneSummarySubtitle}>SF</div>
                </div>
              </div>
              <div className={classes.zoneSummaryTitle}>Workpoints</div>
              <div className={classes.summaryValue}>
                <div className={classes.zoneSummaryNumber}>
                  {metrics.workpoints}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(ZoneHeaderFocus);
