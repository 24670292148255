import { format } from "./utils";

export type Circle = {
  type: "circle";
  x: number;
  y: number;
  r: number;
};

export const circle = ({ x, y, r }: Circle): string => {
  // prettier-ignore
  return (
    format("0", "CIRCLE") +
    format("10", x) +
    format("20", y) +
    format("30", 0) +
    format("40", r)
  )
};
