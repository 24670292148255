import { ComponentType, useEffect, useState } from "react";
import { ContainerProviderProps, createContainer } from "unstated-next";
import { Background } from "lib/types";
import getBackground from "lib/api/getBackground";
import usePrevious from "lib/hooks/use-previous";
import { getAuthHeader } from "../auth";
import axios from "axios";
import config from "../../config";

export interface BackgroundState {
  currentBackground: Background | undefined;
  saveBackground: (
    buildingId: string,
    level: string,
    background: Background
  ) => void;
}

interface InitialState {
  projectID: string;
  buildingID: string;
  floorID: string;
  strategyID: string;
}

const useBackgroundState = (
  initialState: InitialState | undefined
): BackgroundState => {
  const [currentBackground, setCurrentBackground] = useState<Background>();
  const prevState: InitialState | undefined = usePrevious(initialState);

  useEffect(() => {
    const setBackground = async () => {
      if (initialState) {
        setCurrentBackground(undefined);
        const { buildingID, floorID } = initialState;
        if (!buildingID || !floorID) return;
        const b = await getBackground(buildingID, floorID);
        setCurrentBackground(b);
      }
    };
    if (
      !prevState ||
      prevState?.floorID !== initialState?.floorID ||
      prevState?.buildingID !== initialState?.buildingID
    )
      setBackground();
  }, [initialState, prevState]);

  const saveBackground = async (
    buildingId: string,
    level: string,
    b: Background
  ) => {
    try {
      const headers = await getAuthHeader();
      axios
        .put(
          config.baseUrl +
            `/api/planning/buildings/${buildingId}/${level}/background`,
          b,
          {
            headers,
          }
        )
        .catch(() => {
          console.error("Failed to save building");
        });
    } catch (error: any) {
      console.error("Error: ", error);
    }
  };
  return {
    currentBackground,
    saveBackground,
  };
};

export const BackgroundController = createContainer(useBackgroundState);
export const BackgroundProvider: ComponentType<
  ContainerProviderProps<InitialState>
> = BackgroundController.Provider;
export const useBackgroundCtrl = () => BackgroundController.useContainer();
