import { Metric, Metrics, StrategyMode } from "../types";
import { getFloorMetric } from "lib/api/getFloorMetric";
import { encodeMetricId } from "lib/metrics/id";
import calculatePotentialSeats from "lib/metrics/calculate-potential-seats";
import { emptyMetric } from "./constants";

// fetchNewFloorMetrics is called each time StrategyMode is switched

const fetchNewFloorMetrics = async (
  floorCode: string[],
  buildingId: string,
  strategyID: string
): Promise<Metrics | undefined> => {
  try {
    const metrics: Metrics = {};
    const backends: (Metric | undefined)[] = await Promise.all(
      floorCode.map((fc) => {
        return getFloorMetric(buildingId, fc);
      })
    );
    backends.forEach((backend, i) => {
      if (backend) {
        const metric = {
          ...emptyMetric,
          ...backend,
          strategyID,
          mode: StrategyMode.Zone,
          displayName: `${buildingId}-${strategyID.slice(-4).toUpperCase()}`,
          efficiencyRatio: 0.85,
          seatCount: backend.baselineSeatCount,
          conferenceRoomSeats: backend.baselineConferenceRoomsCapacity,
          conferenceRoomSeatsPerHC: backend.baselineConferenceRoomsSeatsPerHC,
          convertToCollaboration: [],
          convertToWorkstations: [],
          disable: [],
        };
        metric.buildingID = buildingId;
        metric.potentialSeats = calculatePotentialSeats(
          "potentialSeats",
          metric
        );
        const fc = floorCode[i];
        const id = encodeMetricId(buildingId, strategyID, fc);
        metrics[id] = metric;
      }
    });
    return metrics;
  } catch (error: any) {
    console.error("error", error);
    return undefined;
  }
};

export default fetchNewFloorMetrics;
