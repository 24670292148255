import { Background, Coordinate, Data, ElementType, XForm } from "../types";
import pb from "polybooljs";
import { checkBlockRectangle } from "blocks/lib/blocks";
import { Block, BlockResult } from "blocks/lib/types";
import {
  asRegion,
  rotateDrawable,
  shouldRender,
} from "lib/isp-canvas/geometry";
import { mat4, quat, vec3 } from "gl-matrix";
import { getters } from "blocks/lib/constants";

export const filterBackground = (
  bg: Background | undefined,
  triGross: Data,
  offsetX: number,
  offsetY: number
): Background | undefined => {
  if (!bg) return bg;
  const triRegion = asRegion(triGross);

  const filteredBackground: Background = {
    elements: [],
    inserts: bg.inserts,
    bounds: bg.bounds,
  };

  bg.elements.forEach((e) => {
    if (e.type === ElementType.Insert) {
      const insert = bg.inserts[e.insertName];
      if (insert && insert.length) {
        const shouldRenderInsert = insert.reduce((acc, d) => {
          if (acc) return acc;
          const rotated = rotateDrawable(d, 0, 0, e.coordinates[0].angle || 0);
          return shouldRender(
            rotated,
            offsetX + e.coordinates[0].x,
            offsetY + e.coordinates[0].y,
            triRegion
          );
        }, false);

        // at least one drawable is in bounds
        if (shouldRenderInsert) filteredBackground.elements.push(e);
      }

      return;
    }
    if (shouldRender(e, offsetX, offsetY, triRegion)) {
      filteredBackground.elements.push(e);
    }
  });

  return filteredBackground;
};

export const generateXform = (
  xform: XForm,
  features: Coordinate[][],
  width: number,
  height: number
): XForm => {
  // make a bounding box of the perimeter
  const bbMin: Coordinate = { x: Infinity, y: Infinity };
  const bbMax: Coordinate = { x: -Infinity, y: -Infinity };
  if (features?.length) {
    features.forEach((poly: any) => {
      poly.forEach((c: Coordinate) => {
        if (c.x < bbMin.x) bbMin.x = c.x;
        if (c.x > bbMax.x) bbMax.x = c.x;
        if (c.y < bbMin.y) bbMin.y = c.y;
        if (c.y > bbMax.y) bbMax.y = c.y;
      });
    });
  }

  // get bounding box dimensions
  const w = bbMax.x - bbMin.x;
  const h = bbMax.y - bbMin.y;

  xform.width = width;
  xform.height = height;
  // scale to fit the screen
  xform.scale = -Math.min(xform.width / w, xform.height / h) * 0.8;
  // place it in the center
  xform.position = {
    x: bbMin.x * xform.scale + xform.width / 2 + (w * xform.scale) / 2,
    y: (-bbMin.y - h) * xform.scale + xform.height / 2 + (h * xform.scale) / 2,
  };

  return xform;
};

export const calculateFeatureArea = (f: Coordinate[]): number => {
  if (!f.length) return 0;
  const points = forceWinding(
    f.map<number[]>((c) => [c.x, c.y]),
    false
  );
  const left = points.reduce((acc, curr, idx) => {
    const nextIdx = idx === points.length - 1 ? 0 : idx + 1;
    const x = curr[0];
    const y = points[nextIdx][1];
    acc += x * y;
    return acc;
  }, 0.0);

  const right = points.reduce((acc, curr, idx) => {
    const nextIdx = idx === points.length - 1 ? 0 : idx + 1;
    const x = points[nextIdx][0];
    const y = curr[1];
    acc += x * y;
    return acc;
  }, 0.0);

  return (left - right) / 2 / 144;
};

export const getLength = (p: Coordinate) => {
  return Math.sqrt(p.x * p.x + p.y * p.y);
};

export const checkWinding = (region: number[][]) => {
  // https://en.wikipedia.org/wiki/Shoelace_formula
  let winding = 0;
  let lastX = region[region.length - 1][0];
  let lastY = region[region.length - 1][1];
  for (let i = 0; i < region.length; i++) {
    const currX = region[i][0];
    const currY = region[i][1];
    winding += currY * lastX - currX * lastY;
    lastX = currX;
    lastY = currY;
  }
  // clockwise = true
  return winding < 0;
};

export const forceWinding = (region: number[][], clockwise: boolean) => {
  const copy = [...region];
  if (checkWinding(region) !== clockwise) copy.reverse();
  return copy;
};

export const normalize = (p: Coordinate): Coordinate => {
  const l = getLength(p);
  return { x: p.x / l, y: p.y / l };
};

export const offsetPolylines = (
  pl: Coordinate[][],
  n: number
): Coordinate[][] => {
  try {
    const polygons = pl.map((el) => {
      return {
        inverted: false,
        regions: el ? [el.map((c) => [c.x, c.y])] : [[]],
      };
    });

    let base = polygons[0];
    for (let i = 1; i < polygons.length; i++) {
      base = pb.union(base, polygons[i]);
    }

    // TODO: fix filtering of hole regions
    return base.regions
      .filter((r) => r.length > 6)
      .map((r) => {
        r = forceWinding(r, true);
        const l = r.length;
        const offsetCoordinates = [];

        for (let i = 0; i < l; i++) {
          const curr = { x: r[i][0], y: r[i][1] };
          const prev = { x: r[(i - 1 + l) % l][0], y: r[(i - 1 + l) % l][1] };
          const next = { x: r[(i + 1) % l][0], y: r[(i + 1) % l][1] };
          const v1 = normalize({ x: curr.x - prev.x, y: curr.y - prev.y });
          const v2 = normalize({ x: next.x - curr.x, y: next.y - curr.y });

          const k = v1.x * v2.y - v1.y * v2.x;

          if (k < 0) {
            //the angle is greater than pi, invert outgoing,
            v2.x *= -1;
            v2.y *= -1;
          } else {
            //the angle is less than pi, invert incoming,
            v1.x *= -1;
            v1.y *= -1;
          }

          const normal = normalize({ x: v1.x + v2.x, y: v1.y + v2.y });
          const angle = Math.atan2(normal.y, normal.x);
          let cos = Math.abs(Math.cos(angle));
          if (cos < 0.5 || cos > 1) cos = 1;
          const h = n * (1 / cos);
          offsetCoordinates.push({
            x: curr.x + normal.x * h,
            y: curr.y + normal.y * h,
          });
        }

        return offsetCoordinates;
      });
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const findFeatureCentroid = (f: Coordinate[]): Coordinate => {
  let minX = Infinity;
  let minY = Infinity;
  let maxX = -Infinity;
  let maxY = -Infinity;
  if (f) {
    f.forEach((c: Coordinate) => {
      if (c.x < minX) minX = c.x;
      if (c.x > maxX) maxX = c.x;
      if (c.y < minY) minY = c.y;
      if (c.y > maxY) maxY = c.y;
    });
  }
  return { x: (maxX + minX) / 2, y: (maxY + minY) / 2 };
};

export const getAnchorDirection = (
  x: number,
  y: number,
  region: number[][],
  rotation: number
): string | false => {
  const [w, h] = getRegionLengths(region);
  const [cx, cy] = getRegionCenter(region);
  // rotate point to check against unrotated region
  const [rx, ry] = rotatePointAround(x, y, cx - w / 2, cy - h / 2, rotation);
  const radius = 30;
  if (
    Math.sqrt(
      (rx - (region[0][0] + w / 2)) * (rx - (region[0][0] + w / 2)) +
        (ry - region[0][1]) * (ry - region[0][1])
    ) < radius
  )
    return "n";
  else if (
    Math.sqrt(
      (rx - (region[0][0] + w / 2)) * (rx - (region[0][0] + w / 2)) +
        (ry - region[2][1]) * (ry - region[2][1])
    ) < radius
  )
    return "s";
  else if (
    Math.sqrt(
      (rx - region[0][0]) * (rx - region[0][0]) +
        (ry - (region[2][1] + h / 2)) * (ry - (region[2][1] + h / 2))
    ) < radius
  )
    return "w";
  else if (
    Math.sqrt(
      (rx - region[2][0]) * (rx - region[2][0]) +
        (ry - (region[2][1] + h / 2)) * (ry - (region[2][1] + h / 2))
    ) < radius
  )
    return "e";
  else if (
    Math.sqrt(
      (rx - region[0][0]) * (rx - region[0][0]) +
        (ry - region[0][1]) * (ry - region[0][1])
    ) < radius
  )
    return "nw";
  else if (
    Math.sqrt(
      (rx - region[2][0]) * (rx - region[2][0]) +
        (ry - region[2][1]) * (ry - region[2][1])
    ) < radius
  )
    return "se";
  else if (
    Math.sqrt(
      (rx - region[1][0]) * (rx - region[1][0]) +
        (ry - region[1][1]) * (ry - region[1][1])
    ) < radius
  )
    return "sw";
  else if (
    Math.sqrt(
      (rx - region[3][0]) * (rx - region[3][0]) +
        (ry - region[3][1]) * (ry - region[3][1])
    ) < radius
  )
    return "ne";
  else if (isInsideRegion({ x, y }, region, rotation)) return "drag";
  else if (
    Math.sqrt(
      (rx - (region[0][0] + w / 2)) * (rx - (region[0][0] + w / 2)) +
        (ry - (region[0][1] + 100)) * (ry - (region[0][1] + 100))
    ) < radius
  )
    return "rotate";
  else return false;
};

export const rotateCenter = (m: mat4, w: number, h: number, angle: number) => {
  return mat4.mul(
    mat4.create(),
    m,
    mat4.fromRotationTranslationScaleOrigin(
      mat4.create(),
      quat.setAxisAngle(quat.create(), vec3.fromValues(0, 0, 1), angle),
      vec3.fromValues(0, 0, 0),
      vec3.fromValues(1, 1, 1),
      vec3.fromValues(w / 2, h / 2, 0)
    )
  );
};

export const selectCursor = (
  anchor: string | false,
  rotation?: number
): string | false => {
  if (anchor === "drag" || anchor === "rotate") return "move";
  if (
    rotation !== undefined &&
    (rotation >= 345 || rotation <= 15 || (rotation >= 165 && rotation <= 195))
  ) {
    switch (anchor) {
      case "n":
      case "s":
        return "ns-resize";
      case "e":
      case "w":
        return "ew-resize";
      case "nw":
      case "se":
        return "nwse-resize";
      case "ne":
      case "sw":
        return "nesw-resize";
    }
  }
  if (
    rotation &&
    ((rotation >= 75 && rotation <= 105) ||
      (rotation >= 255 && rotation <= 285))
  ) {
    switch (anchor) {
      case "n":
      case "s":
        return "ew-resize";
      case "e":
      case "w":
        return "ns-resize";
      case "nw":
      case "se":
        return "nesw-resize";
      case "ne":
      case "sw":
        return "nwse-resize";
    }
  }
  if (
    rotation &&
    ((rotation >= 285 && rotation <= 345) ||
      (rotation >= 105 && rotation <= 165))
  ) {
    switch (anchor) {
      case "n":
      case "s":
        return "nwse-resize";
      case "e":
      case "w":
        return "nesw-resize";
      case "nw":
      case "se":
        return "ew-resize";
      case "ne":
      case "sw":
        return "ns-resize";
    }
  }
  if (
    rotation &&
    ((rotation >= 15 && rotation <= 75) || (rotation >= 195 && rotation <= 255))
  ) {
    switch (anchor) {
      case "n":
      case "s":
        return "nesw-resize";
      case "e":
      case "w":
        return "nwse-resize";
      case "nw":
      case "se":
        return "ns-resize";
      case "ne":
      case "sw":
        return "ew-resize";
    }
  }

  return false;
};

export const resizeRegionFromCenter = ({
  centerX,
  centerY,
  width,
  height,
}: {
  centerX: number;
  centerY: number;
  width: number;
  height: number;
}): number[][] => {
  const nr = [
    [centerX - width / 2, centerY + height / 2],
    [centerX + width / 2, centerY + height / 2],
    [centerX + width / 2, centerY - height / 2],
    [centerX - width / 2, centerY - height / 2],
  ];
  return sortRegion(nr);
};

export const getAngle = (
  { x: x1, y: y1 }: Coordinate,
  { x: x2, y: y2 }: Coordinate
): number => {
  const dot = x1 * x2 + y1 * y2;
  const det = x1 * y2 - y1 * x2;
  const angle = (Math.atan2(det, dot) / Math.PI) * 180;
  return angle;
};

export const calcAngle = (
  cx: number,
  cy: number,
  x1: number,
  y1: number,
  x2: number,
  y2: number
): number => {
  const ab = Math.sqrt(Math.pow(cx - x1, 2) + Math.pow(cy - y1, 2));
  const ac = Math.sqrt(Math.pow(cx - x2, 2) + Math.pow(cy - y2, 2));
  const bc = Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2));
  const rad = Math.acos(
    (Math.pow(ab, 2) + Math.pow(ac, 2) - Math.pow(bc, 2)) / (2 * ab * ac)
  );
  return (rad * 180) / Math.PI;
};

export const rotatePointAround = (
  x: number,
  y: number,
  centerX: number,
  centerY: number,
  angle: number
): number[] => {
  angle = (angle * Math.PI) / 180.0;
  return [
    Math.cos(angle) * (x - centerX) - Math.sin(angle) * (y - centerY) + centerX,
    Math.sin(angle) * (x - centerX) + Math.cos(angle) * (y - centerY) + centerY,
  ];
};

export const getBoundingBox = (blocks: Block[]): number[][] => {
  let min = [Infinity, Infinity];
  let max = [-Infinity, -Infinity];

  blocks.forEach((block) => {
    const {
      matrix,
      geometry: { box },
    } = block;
    if (!box) return;
    const b = [
      vec3.fromValues(box[0][0], box[1][1], 0),
      vec3.fromValues(box[0][0], box[0][1], 0),
      vec3.fromValues(box[1][0], box[0][1], 0),
      vec3.fromValues(box[1][0], box[1][1], 0),
    ];
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    b.map((p) => vec3.transformMat4(vec3.create(), p, matrix!))
      .map((p) => [p[0], p[1]])
      .forEach((p) => {
        min = [p[0] < min[0] ? p[0] : min[0], p[1] < min[1] ? p[1] : min[1]];
        max = [p[0] > max[0] ? p[0] : max[0], p[1] > max[1] ? p[1] : max[1]];
      });
  });
  const box = [min, [min[0], max[1]], max, [max[0], min[1]]];
  return sortRegion(box);
};

export const rotatedRegionCoords = (
  region: number[][],
  rotation: number
): number[][] => {
  const [cx, cy] = getRegionCenter(region);
  const [w, h] = getRegionLengths(region);
  return region.map((r) =>
    rotatePointAround(r[0], r[1], cx - w / 2, cy - h / 2, rotation)
  );
};

export const toRotation = (mat: mat4 | undefined): number => {
  if (!mat) return 0;
  const q = mat4.getRotation(quat.create(), mat);
  let angle = quat.getAxisAngle(vec3.fromValues(0, 0, 1), q);
  if (q[2] < 0) angle = -angle;
  const rotation = (360 - angle * (180 / Math.PI)) % 360;
  return rotation;
};

export const decomposeMat = (m: mat4) => {
  const q = mat4.getRotation(quat.create(), m);
  let angle = quat.getAxisAngle(vec3.fromValues(0, 0, 1), q);
  if (q[2] < 0) angle = -angle;
  return { position: [m[12], m[13]], rotation: angle };
};

export const toRegion = (block: Block, dontmove = false): number[][] => {
  if (!block) {
    throw new Error("no block");
  }
  let pos = vec3.create();
  if (block.matrix && !dontmove) {
    pos = mat4.getTranslation(vec3.create(), block.matrix);
  }
  const { type, box } = block.geometry;
  if (type === "box" && box) {
    return [
      [box[0][0] + pos[0], box[1][1] + pos[1]],
      [box[0][0] + pos[0], box[0][1] + pos[1]],
      [box[1][0] + pos[0], box[0][1] + pos[1]],
      [box[1][0] + pos[0], box[1][1] + pos[1]],
    ];
  }
  return [
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
  ];
};

export const getRegionCenter = (region: number[][]): number[] => {
  const [w, h] = getRegionLengths(region);
  return [region[3][0] - w / 2, region[0][1] - h / 2];
};

export const getRegionLengths = (region: number[][]): number[] => {
  return [
    Math.abs(region[3][0] - region[0][0]),
    Math.abs(region[0][1] - region[1][1]),
  ];
};

export const degToRadian = (deg: number): number => (deg * Math.PI) / 180;
export const radianToDeg = (rad: number): number => (rad * 180) / Math.PI;
export const cos = (deg: number): number => Math.cos(degToRadian(deg));
export const sin = (deg: number): number => Math.sin(degToRadian(deg));

export const calcDeltas = (
  mx: number,
  my: number,
  startX: number,
  startY: number,
  rotation: number
): { deltaW: number; deltaH: number } => {
  const deltaX = mx - startX;
  const deltaY = -(my - startY);
  const alpha = Math.atan2(deltaY, deltaX);
  const deltaL = getLength({ x: deltaX, y: deltaY });
  const beta = alpha - degToRadian(rotation);
  const deltaW = deltaL * Math.cos(beta);
  const deltaH = deltaL * Math.sin(beta);
  return { deltaW, deltaH };
};

// thanks https://github.com/mockingbot/react-resizable-rotatable-draggable/blob/master/src/utils.js
export const getResizeData = (
  anchor: string,
  rect: {
    width: number;
    height: number;
    centerX: number;
    centerY: number;
    rotation: number;
  },
  deltaW: number,
  deltaH: number
): {
  centerX: number;
  centerY: number;
  width: number;
  height: number;
  ox: number;
  oy: number;
} => {
  const { rotation } = rect;
  let { width, height, centerX, centerY } = rect;
  const widthFlag = width < 0 ? -1 : 1;
  const heightFlag = height < 0 ? -1 : 1;
  let ox = 0;
  let oy = 0;
  switch (anchor) {
    case "e": {
      width = width + deltaW;
      centerX += (deltaW / 2) * cos(rotation);
      centerY -= (deltaW / 2) * sin(rotation);
      break;
    }
    case "ne": {
      deltaH = -deltaH;
      width = width + deltaW;
      height = height + deltaH;
      centerX += (deltaW / 2) * cos(rotation) + (deltaH / 2) * sin(rotation);
      centerY -= (deltaW / 2) * sin(rotation) - (deltaH / 2) * cos(rotation);
      break;
    }
    case "se": {
      width = width + deltaW;
      height = height + deltaH;
      centerX += (deltaW / 2) * cos(rotation) - (deltaH / 2) * sin(rotation);
      centerY -= (deltaW / 2) * sin(rotation) + (deltaH / 2) * cos(rotation);
      oy -= deltaH;
      break;
    }
    case "s": {
      height = height + deltaH;
      centerX -= (deltaH / 2) * sin(rotation);
      centerY -= (deltaH / 2) * cos(rotation);
      oy -= deltaH;
      break;
    }
    case "sw": {
      deltaW = -deltaW;
      width = width + deltaW;
      height = height + deltaH;
      centerX -= (deltaW / 2) * cos(rotation) + (deltaH / 2) * sin(rotation);
      centerY += (deltaW / 2) * sin(rotation) - (deltaH / 2) * cos(rotation);
      ox -= deltaW;
      oy -= deltaH;
      break;
    }
    case "w": {
      deltaW = -deltaW;
      width = width + deltaW;
      centerX -= (deltaW / 2) * cos(rotation);
      centerY += (deltaW / 2) * sin(rotation);
      ox -= deltaW;
      break;
    }
    case "nw": {
      deltaW = -deltaW;
      deltaH = -deltaH;
      width = width + deltaW;
      height = height + deltaH;
      centerX -= (deltaW / 2) * cos(rotation) - (deltaH / 2) * sin(rotation);
      centerY += (deltaW / 2) * sin(rotation) + (deltaH / 2) * cos(rotation);
      ox -= deltaW;
      break;
    }
    case "n": {
      deltaH = -deltaH;
      height = height + deltaH;
      centerX += (deltaH / 2) * sin(rotation);
      centerY += (deltaH / 2) * cos(rotation);
      break;
    }
  }

  return {
    ox,
    oy,
    centerX: Math.round(centerX),
    centerY: Math.round(centerY),
    width: Math.round(width * widthFlag),
    height: Math.round(height * heightFlag),
  };
};

export const getMxMy = (x: number, y: number, xform: XForm): number[] => {
  const mx =
    (x * xform.globalScale - xform.position.x) * (1 / (-1 * xform.scale));
  const my = (y * xform.globalScale - xform.position.y) * (1 / xform.scale);
  return [mx, my];
};

const SNAP_SIZE = 6;
export const snap = (p: number[]): number[] => {
  return [
    Math.round(p[0] / SNAP_SIZE) * SNAP_SIZE,
    Math.round(p[1] / SNAP_SIZE) * SNAP_SIZE,
  ];
};

export const sortRegion = (region: number[][]): number[][] => {
  const xMin = Math.min(region[0][0], region[1][0], region[2][0], region[3][0]);
  const xMax = Math.max(region[0][0], region[1][0], region[2][0], region[3][0]);
  const yMin = Math.min(region[0][1], region[1][1], region[2][1], region[3][1]);
  const yMax = Math.max(region[0][1], region[1][1], region[2][1], region[3][1]);
  return [
    [xMin, yMax],
    [xMin, yMin],
    [xMax, yMin],
    [xMax, yMax],
  ];
};

export const isInsideRegion = (
  pos: Coordinate,
  region: number[][],
  rotation: number
): boolean => {
  const [w, h] = getRegionLengths(region);
  const [cx, cy] = getRegionCenter(region);
  const [x, y] = rotatePointAround(
    pos.x,
    pos.y,
    cx - w / 2,
    cy - h / 2,
    rotation
  );
  return (
    x > region[0][0] && x < region[3][0] && y < region[0][1] && y > region[1][1]
  );
};

export const translateFeatureToValue = (key: string) => {
  switch (key) {
    case "phone":
      return "phoneBooths";
    case "small":
      return "small";
    case "medium":
      return "medium";
    case "large":
      return "large";
    case "huddle":
      return "huddle";
    case "extraLarge":
      return "extraLarge";
    default:
      return "";
  }
};

export const fitBlocksToRegion = (
  w: number,
  h: number,
  options: Block[]
): BlockResult[] => {
  const results: BlockResult[] = [];
  options.forEach((t) => {
    const l = getters.getLayout(t);
    const fits = checkBlockRectangle(w, h, t, l.flexibility);
    const fitsRotated = checkBlockRectangle(h, w, t, l.flexibility);
    if (fits) results.push(fits);
    if (fitsRotated) results.push({ ...fitsRotated, rotated: true });
  });
  return results.sort((a, b) =>
    a.score > b.score ? -1 : a.score < b.score ? 1 : 0
  );
};
