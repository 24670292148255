import React, { ComponentType, FC } from "react";
import { BlockLibrariesProvider } from "../containers";

function withBlockLibraries<Props>(Component: ComponentType<Props>): FC<Props> {
  return (props) => (
    <BlockLibrariesProvider>
      <Component {...props} />
    </BlockLibrariesProvider>
  );
}

export default withBlockLibraries;
