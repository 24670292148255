import { Metric, SelectedMeetingRoom } from "lib/types";

const makeRendererProgram = (
  metric: Metric
): { [key: string]: string | null } => {
  const values: { [key: string]: string } = {};
  if (metric.userConvertedMeetingRooms)
    Object.keys(metric.userConvertedMeetingRooms).forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      if (metric.userConvertedMeetingRooms![key])
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        metric.userConvertedMeetingRooms![key].forEach(
          (item: SelectedMeetingRoom) => {
            if (key === "convertToWorkstations")
              values[item.conferenceRoomId] = "convert";
            else values[item.conferenceRoomId] = key;
          }
        );
    });
  return values;
};

export default makeRendererProgram;
