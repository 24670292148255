import { ComponentType, useState } from "react";
import { ContainerProviderProps, createContainer } from "unstated-next";

export interface UseTableState {
  isPercent: boolean;
  toggleIsPercent(): void;
  isResetting: boolean;
  toggleIsResetting(): void;
}

export const useTableState = (): UseTableState => {
  const [isPercent, setIsPercent] = useState<boolean>(false);
  const [isResetting, setIsResetting] = useState<boolean>(false);
  const toggleIsPercent = () => {
    setIsPercent(!isPercent);
  };

  const toggleIsResetting = () => {
    setIsResetting(!isResetting);
  };

  return {
    isPercent,
    toggleIsPercent,
    isResetting,
    toggleIsResetting,
  };
};

export const TableController = createContainer(useTableState);
export const TableProvider: ComponentType<ContainerProviderProps> =
  TableController.Provider;
export const useTableCtrl = () => TableController.useContainer();
