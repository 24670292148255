import { Button, IconButton, IconButtonProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { HelpguideHandle, HelpguideWrapperDiv } from "@outerlabs/helpguide";
import getFloorCirculation from "lib/api/floorCirculation";
import {
  emptySettingsFactory,
  RendererMode,
  useFeaturesCtrl,
  useRendererCtrl,
  useSettingsCtrl,
} from "lib/containers";
import { calculateBuzzGraph, Graph } from "lib/metrics/buzz";
import React, { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import analytics from "../../lib/analytics";

const svgIcon = (color: string) => (
  <svg width="29" height="29" viewBox="0 0 29 29" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 4.04492H6.78873C3.18828 4.04492 0.269531 6.96367 0.269531 10.5641V22.1715C0.269531 25.7719 3.18828 28.6907 6.78873 28.6907H18.3961C21.9965 28.6907 24.9153 25.7719 24.9153 22.1715V14H22.6743V22.1715C22.6743 24.5343 20.7589 26.4497 18.3961 26.4497H6.78873C4.42593 26.4497 2.51051 24.5343 2.51051 22.1715V10.5641C2.51051 8.20133 4.42593 6.2859 6.78873 6.2859H18V4.04492Z"
      fill={color}
    />
    <path
      d="M20.0703 16.8182L23.8885 13L27.0703 16.8182"
      stroke={color}
      strokeWidth="2.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="15"
      cy="5.40039"
      r="4"
      fill="white"
      stroke={color}
      strokeWidth="2.3"
    />
  </svg>
);

const useBlockButtonStyles = makeStyles({
  button: {
    borderRadius: 14,
    padding: 8,
    fontSize: 14,
  },
  selected: {
    backgroundColor: "rgba(0, 0, 0, 0.04)",
  },
});

interface BlockButtonProps extends IconButtonProps {
  selected?: boolean;
}

const BlockButton: React.FC<BlockButtonProps> = ({
  selected,
  children,
  ...props
}) => {
  const classes = useBlockButtonStyles();
  return (
    <IconButton
      className={`${classes.button} ${selected && classes.selected}`}
      {...props}
    >
      {children}
    </IconButton>
  );
};

const useBuzzStyles = makeStyles({
  buttons: {
    padding: "12px 0",
    display: "flex",
    flexDirection: "row",
  },
  actions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "12px 0",
  },
  button: {
    paddingRight: 24,
  },
  buzz: {
    paddingTop: 12,
    fontSize: 16,
    color: "#5F6166",
  },
  calculate: {
    marginBottom: 12,
  },
  buttonLabel: {
    paddingLeft: 10,
  },
});

const Buzz: React.FC = () => {
  const classes = useBuzzStyles();
  const {
    buildingID,
    floorID,
    strategyID,
    id: projectID,
  } = useParams<{ [key: string]: string }>();
  const { currentSettings, saveFloor, setCalculatedBuzz, calculatedBuzz } =
    useSettingsCtrl();
  const { currentFeatures } = useFeaturesCtrl();
  const { setRendererMode, rendererMode, setShowBuzz } = useRendererCtrl();

  // this logic is used to assign a defaultCirculation to be used in the
  // handling of the Reset Cirulation button
  const [defaultCirculation, setDefaultCirculation] =
    React.useState<Graph | null>(null);

  useEffect(() => {
    const defaultCheck = async () => {
      const possibleDefaultCirc = await getFloorCirculation(
        buildingID,
        floorID
      );
      if (possibleDefaultCirc) setDefaultCirculation(possibleDefaultCirc);
    };
    defaultCheck();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleKeyboardEvent = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      setRendererMode(RendererMode.BuzzMetric);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyboardEvent);
    return () => {
      document.removeEventListener("keydown", handleKeyboardEvent);
    };
  });

  const onDrawCirculation = async () => {
    setRendererMode(RendererMode.DrawCirculation);
    setShowBuzz(true);
  };

  const onDeleteCirculation = async () => {
    setRendererMode(RendererMode.DeleteCirculation);
    setShowBuzz(true);
  };

  const onEditCirculation = async () => {
    setRendererMode(RendererMode.EditCirculation);
    setShowBuzz(true);
  };

  const calculateBuzz = async () => {
    if (!currentFeatures) return;
    const defaultCirc = await getFloorCirculation(buildingID, floorID);
    let circulation: Graph;
    // This is used to parse what calculates buzz, the default or the drawn
    if (defaultCirc !== undefined && !currentSettings.circulation) {
      // if there is a default and no drawn, use default
      circulation = defaultCirc;
    } else if (currentSettings.circulation) {
      // if there is a drawn, regardless of if there is a default circulation or not
      // circulation is from the drawn
      circulation = currentSettings.circulation;
    } else {
      // if neither default or drawn circulation exists, give an empty array
      circulation = {};
    }

    const edgeValues = await calculateBuzzGraph(circulation, currentFeatures);
    setCalculatedBuzz(edgeValues);
    analytics.buzzViewed();
  };

  const resetCirculation = useCallback(async () => {
    setCalculatedBuzz([]);
    const defaultCirc = await getFloorCirculation(buildingID, floorID);
    const circulation = defaultCirc !== undefined ? defaultCirc : {};
    await saveFloor({
      settings: currentSettings
        ? { ...currentSettings, circulation: circulation }
        : emptySettingsFactory(),
      buildingID,
      floorID,
      strategyID,
      projectID,
    });
  }, [
    setCalculatedBuzz,
    saveFloor,
    currentSettings,
    buildingID,
    floorID,
    strategyID,
    projectID,
  ]);

  return (
    <HelpguideWrapperDiv>
      <div className={classes.buzz}>
        Please draw all primary circulation paths:
        <HelpguideHandle isSmall tooltipKey="Buzz" />
        <div className={classes.buttons}>
          <div className={classes.button}>
            <BlockButton
              onClick={onDrawCirculation}
              selected={rendererMode === RendererMode.DrawCirculation}
            >
              {svgIcon("#555555")}{" "}
              <span className={classes.buttonLabel}>Add Path</span>
            </BlockButton>
          </div>
          <div>
            <BlockButton
              onClick={onDeleteCirculation}
              selected={rendererMode === RendererMode.DeleteCirculation}
            >
              {svgIcon("#555555")}{" "}
              <span className={classes.buttonLabel}>Remove Path</span>
            </BlockButton>
          </div>
          <div>
            <BlockButton
              onClick={onEditCirculation}
              selected={rendererMode === RendererMode.EditCirculation}
            >
              {svgIcon("#D4D4D4")}{" "}
              <span className={classes.buttonLabel}>Edit Path</span>
            </BlockButton>
          </div>
        </div>
        <div className={classes.actions}>
          <div className={classes.calculate}>
            <Button
              variant="contained"
              onClick={resetCirculation}
              disabled={
                !(
                  currentSettings &&
                  currentSettings.circulation &&
                  Object.keys(currentSettings.circulation).length !== 0 &&
                  !(
                    JSON.stringify(currentSettings.circulation) ===
                    JSON.stringify(defaultCirculation)
                  )
                )
              }
            >
              Reset Circulation
            </Button>
          </div>
          <div className={classes.calculate}>
            <Button
              variant="contained"
              color="primary"
              onClick={calculateBuzz}
              disabled={
                !(
                  currentSettings &&
                  currentSettings.circulation &&
                  Object.keys(currentSettings.circulation).length !== 0
                ) && calculatedBuzz.length === 0
              }
            >
              Calculate Buzz
            </Button>
          </div>
        </div>
      </div>
    </HelpguideWrapperDiv>
  );
};

export default Buzz;
