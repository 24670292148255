import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import GoogleMapReact from "google-map-react";
import { Building } from "lib/types";
import BuildingDetails from "../components/BuildingDetails";
import { mapStyle } from "../mapConstants";

export interface DXFImportProps {
  ready: boolean;
  setReady: (b: boolean) => void;
  building: Building;
  updateBuilding: (building: Building) => void;
}

const useStyles = makeStyles({
  container: {
    flex: 1,
    display: "flex",
  },
  form: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  leftSide: {
    display: "flex",
    flex: 1,
    marginRight: 16,
  },
  rightSide: {
    display: "flex",
    flex: 3,
  },
  head: {
    transform: "translateY(-40px)",
    color: "#5F6166",
  },
  heading: {
    fontFamily: "Google Sans",
    fontSize: 20,
    fontWeight: 500,
  },
  subheading: {
    fontFamily: "Google Sans",
    fontSize: 14,
  },
});

const BuildingMap: React.FC<{ location: { lat: number; lng: number } }> = ({
  location,
}) => {
  const classes = useStyles();
  const [hasDraggedOrZoomed, setHasDraggedOrZoomed] = useState<boolean>(false);

  const onDragEnd = () => setHasDraggedOrZoomed(true);

  const onZoomEnd = () => setHasDraggedOrZoomed(true);

  const onChange = () => hasDraggedOrZoomed && setHasDraggedOrZoomed(false);

  return (
    <div className={classes.form}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_MAP_API_KEY as string,
        }}
        defaultCenter={location}
        center={location}
        defaultZoom={6}
        onChange={onChange}
        onDragEnd={onDragEnd}
        onZoomAnimationEnd={onZoomEnd}
        options={{
          styles: mapStyle,
          clickableIcons: false,
        }}
      >
        <Marker lat={location.lat} lng={location.lng} />
      </GoogleMapReact>
    </div>
  );
};

const Marker: React.FC<{ lat: number; lng: number }> = () => (
  <div
    style={{
      width: 10,
      height: 10,
      borderRadius: 10,
      backgroundColor: "#4384f7",
    }}
  />
);

const defaultLatLng = {
  lat: 43.4133299,
  lng: -122.1163721,
};
// StepSetup is the first step in the import process. It is used to setup a new
// building  in Portfolio. User enter information such as Building name,
// address, orientation, and number of levels.
const StepSetup: React.FC<DXFImportProps> = ({
  building,
  updateBuilding,
  ready,
  setReady,
}) => {
  const classes = useStyles();
  const [address, setAddress] = React.useState(defaultLatLng);

  return (
    <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
      <div className={classes.head}>
        <Typography variant="h6" className={classes.heading}>
          Set up a New Building in Portfolio
        </Typography>
        <Typography variant="body2" className={classes.subheading}>
          Please enter building data.
        </Typography>
      </div>
      <div className={classes.container}>
        <div className={classes.leftSide}>
          <BuildingDetails
            building={building}
            updateBuilding={updateBuilding}
            onChangeAddress={setAddress}
            onReady={setReady}
            ready={ready}
          />
        </div>
        <div className={classes.rightSide}>
          <BuildingMap location={address} />
        </div>
      </div>
    </div>
  );
};

export default StepSetup;
