import { Block } from "blocks/lib/types";

export const formatToInt = (v: number, suffix?: string): string => {
  if (isNaN(v)) v = 0;
  return `${addComma(parseInt(v.toFixed(0)))}${suffix ? ` ${suffix}` : ``}`;
};

export const formatPct = (num: number, denom: number): string => {
  if (num === 0 && denom === 0) return "0%";
  if (isNaN(num) || isNaN(denom)) return "0%";
  return `${Math.round((num / denom) * 100).toFixed(0)}%`;
};

export function round(n: number, digits = 0): number {
  const mul = 10 ** digits;
  return Math.round(n * mul) / mul;
}

export const addComma = (n: number): string => {
  return `${Math.round(n)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const truncateNumber = (n: number): string => {
  let rounded = Math.round(n);
  let numStr = "";

  if (n === Infinity) {
    return "Inf";
  }
  if (rounded < 10000) {
    rounded /= 1000;
    numStr = `${round(rounded, 1)}K`;
  }
  if (rounded >= 10000 && rounded < 1000000) {
    // greater than 1K, less than 1M
    rounded /= 1000;
    numStr = `${round(rounded, 0)}K`;
  }
  if (rounded >= 1000000 && rounded < 1000000000) {
    // greater than 1M, less than 1B
    rounded /= 1000000;
    numStr = `${round(rounded, 0)}M`;
  }
  if (rounded >= 1000000000) {
    rounded /= 1000000000;
    numStr = `${round(rounded, 0)}B`;
  }

  return numStr;
};

export const truncateHeaderNumber = (n: number): string => {
  let rounded = Math.round(n);
  let numStr = "";

  if (n === Infinity) {
    return "∞";
  }
  if (rounded < 1000) {
    numStr = `${rounded}`;
  }
  if (rounded >= 1000 && rounded < 10000) {
    rounded /= 1000;
    numStr = `${round(rounded, 1)}K`;
  }
  if (rounded >= 10000 && rounded < 1000000) {
    // greater than 1K, less than 1M
    rounded /= 1000;
    numStr = `${round(rounded, 0)}K`;
  }
  if (rounded >= 1000000 && rounded < 1000000000) {
    // greater than 1M, less than 1B
    rounded /= 1000000;
    numStr = `${round(rounded, 0)}M`;
  }
  if (rounded >= 1000000000) {
    rounded /= 1000000000;
    numStr = `${round(rounded, 0)}B`;
  }

  return numStr;
};

export const convertToFt = (n: number): string => {
  return `${Math.floor(n / 12)}' ${n % 12}"`;
};

export function formatPercentage(c: number, digits: number): string {
  const percentage = round(c, digits) * 100;
  const roundedPercentage = round(percentage, digits);
  return `${roundedPercentage}%`;
}

export const toPercent = (n: number) =>
  Math.max(0, Math.min(100, Math.round(n * 100)));
export const fromPercent = (n: number) => Math.max(0, Math.min(1, n / 100));

// clean up a given metric
// if metric is a number, round to a provided decimal place
// if metric is not a number, such as when dividing by zero,
// display "-" to indicate that the metric is not applicable
export const formatMetric = (metric: any, decimalPlaces?: number) => {
  if (!decimalPlaces) decimalPlaces = 0;
  let prettyMetric = "-";
  if (typeof metric !== "number") return prettyMetric;
  if (!isNaN(metric) && metric !== Infinity && metric !== -Infinity) {
    prettyMetric = metric.toFixed(decimalPlaces);
  }
  return prettyMetric;
};

export const formatBudget = (costRange: number[]) => {
  if (costRange[0] === costRange[1] && costRange[0] === Infinity) return `$0`;
  else
    return costRange[0] === costRange[1]
      ? `$${truncateNumber(costRange[0])}`
      : `$${truncateNumber(costRange[0])} - $${truncateNumber(costRange[1])}`;
};

export const formatCostHC = (costRange: number[]) => {
  if (
    costRange[0] === costRange[1] &&
    (costRange[0] === Infinity || costRange[0] === 0)
  )
    return `N/A`;
  else
    return costRange[0] === costRange[1]
      ? `$${truncateNumber(costRange[0])}`
      : `$${truncateNumber(costRange[0])} - $${truncateNumber(costRange[1])}`;
};

export const formatTotalBudget = (
  instance: Block | undefined,
  costGSF: number,
  costUnit: number
) => {
  if (costGSF !== 0) {
    if (
      instance &&
      instance.props &&
      instance.props.metrics &&
      instance.props.metrics.area
    ) {
      return `$${truncateNumber(
        (instance?.props?.metrics?.area / 144) * costGSF
      )}`;
    } else return "$0";
  } else if (costUnit !== 0) {
    if (
      instance &&
      instance.props &&
      instance.props.metrics &&
      instance.props.metrics.names
    ) {
      let count = 0;
      Object.values(instance.props.metrics.names).forEach((assetCount) => {
        count += assetCount || 0;
      });
      return `$${truncateNumber(count * costUnit)}`;
    } else return "$0";
  } else return "$0";
};

export const formatPercentOutput = (decimal: number, percent: string) => {
  if (decimal > 0 && percent === "0%") {
    return "< 1%";
  } else if (decimal < 0 && percent === "0%") {
    return "< 1%";
  } else return percent;
};
