import {
  Block,
  BlockMetrics,
  Conditions,
  Constraints,
  DefinitionProps,
  Divider,
  MetadataProps,
  SideProps,
  TypeMetric,
  ConstraintProps,
  LayoutProps,
  MetricsProps,
  ShapeProps,
  StyleProps,
} from "./types";
import { GeometryType } from "@outerlabs/shapes-geometry";
import { makeGetters } from "@outerlabs/shapes-core";
import { Option } from "../../lib/types";

const teamColor = "#CDE8F6"; // teamspace fill color
const circColor = "#F6D9B1";
const mixedUseCircColor = "#E8D5FE";
const collabColor = "#EDB976";
const mixedUseColor = "#D3B1FC";

const agileColor = "#FBE170";
const teamColorHightlight = "#0078FF";
const circColorHighlight = "#FF8E00";
const collabColorHightlight = "#FF8C00";

// program halftone colors
//note: "full"tone version of these colors defined in `legendColorCode` in `legend.tsx`
const teamHalftone = "#E6F3FA";
const meetingHalftone = "#B7C3DD";
const eventsHalftone = "#A9E2CA";
const cbsHalftone = "#F3B0B6";
const amenityHalftone = "#FEE08F";
const circulationHalftone = "#EDEDED";
const coreHalftone = "#BFBFBF";

const workplaceSupportHalftone = "#FFEDE9";
const foodHalftone = "#FFD79B";
const transportationHalftone = "#FEF9E3";
const healthAndPerformanceHalftone = "#FFE495";
const extendedAmenitiesHalftone = "#DBC897";
const networkInfrastructureHalftone = "#E0DBCD";

export const colors = {
  collabColorHightlight,
  circColorHighlight,
  teamColorHightlight,
  agileColor,
  circColor,
  teamColor,
  collabColor,
  mixedUseCircColor,
  mixedUseColor,
};

export const halftoneColors = {
  teamHalftone,
  meetingHalftone,
  eventsHalftone,
  cbsHalftone,
  amenityHalftone,
  circulationHalftone,
  coreHalftone,
};

export const amenityHalftoneColors = {
  workplaceSupportHalftone,
  foodHalftone,
  transportationHalftone,
  healthAndPerformanceHalftone,
  extendedAmenitiesHalftone,
  networkInfrastructureHalftone,
};

export const doorSize = 36;
export const circulationWidthMin = 36;
export const blockTolerance = 12 * 8;

export const emptyMetadataProp: MetadataProps = {
  createdAt: "",
  updatedAt: "",
  authorEmail: "",
  authorName: "",
};

// define constants for calculations
export const makeEmptyMetricsProp = (): MetricsProps => ({
  size: [0, 0],
  seats: 0,
  workpoints: 0,
  headcount: 0,
  areaRange: [0, 0],
  area: 0,
  names: {},
  tags: {},
  types: {},
  seatsRange: [0, 0],
  workpointsRange: [0, 0],
  headcountRange: [0, 0],
  sizeRange: [
    [0, 0],
    [0, 0],
  ],
  costUnit: 0,
  costGSF: 0,
  costHC: 0,
  totalCost: 0,
  costHCRange: [0, 0],
  totalCostRange: [0, 0],
  sharingRatio: 1,
  doors: 0,
  doorsRange: [0, 0],
  assetHC: 0,
});

export const makeEmptyTypeMetric = (): TypeMetric => {
  return {
    area: 0,
    seats: 0,
    workpoints: 0,
    headcount: 0,
    areaRange: [0, 0],
    seatsRange: [0, 0],
    workpointsRange: [0, 0],
    headcountRange: [0, 0],
    tags: {},
    names: {},
    costUnit: 0,
    costGSF: 0,
    totalCost: 0,
    totalCostRange: [0, 0],
    costHC: 0,
    costHCRange: [0, 0],
    sharingRatio: 1,
    doors: 0,
  };
};

export const emptyMetricsProp: MetricsProps = {
  size: [0, 0],
  seats: 0,
  workpoints: 0,
  headcount: 0,
  areaRange: [0, 0],
  area: 0,
  names: {},
  tags: {},
  types: {},
  seatsRange: [0, 0],
  workpointsRange: [0, 0],
  headcountRange: [0, 0],
  sizeRange: [
    [0, 0],
    [0, 0],
  ],
  costUnit: 0,
  costGSF: 0,
  totalCostRange: [0, 0],
  costHCRange: [0, 0],
  totalCost: 0,
  costHC: 0,
  sharingRatio: 1,
  doors: 0,
  doorsRange: [0, 0],
  assetHC: 0,
};

export const emptyBlockMetrics: BlockMetrics = {
  area: 0,
  seats: 0,
  tags: {},
  names: {},
  types: {},
  costUnit: 0,
  costGSF: 0,
  totalCost: [0, 0],
  costHC: [0, 0],
  doors: 0,
  doorsRange: [0, 0],
};

export const emptyConstraint: Constraints = {
  facade: 0,
  openness: 0,
  vibrancy: 0,
};
export const emptyCondition: Conditions = { open: 0 };
export const emptyDivider: Divider = {
  type: "none",
  size: 0,
  mode: "auto",
  align: "stretch",
  offset: 0,
  start: 0,
  end: 0,
};
export const emptySidesProp: SideProps = {
  constraints: [
    emptyConstraint,
    emptyConstraint,
    emptyConstraint,
    emptyConstraint,
  ],
  conditions: [emptyCondition, emptyCondition, emptyCondition, emptyCondition],
  dividers: [emptyDivider, emptyDivider, emptyDivider, emptyDivider],
};

export const emptyLayoutProp: LayoutProps = {
  mode: "none",
  flex: {
    mode: "horizontal",
    primarySizing: "auto",
    crossSizing: "auto",
    primaryAlign: "min",
    crossAlign: "min",
    spacing: 0,
    repeat: [0, 0],
  },
  grid: {
    horizontalSpacing: [0],
    verticalSpacing: [0],
    horizontalMirror: false,
    verticalMirror: false,
    repeat: [
      [0, 0],
      [0, 0],
    ],
  },
  padding: [0, 0, 0, 0],
  offset: [0, 0, 0, 0],
  rotation: 0,
  mirrorX: false,
  mirrorY: false,
};

export const emptyConstraintsProp: ConstraintProps = {
  align: "inherit",
  grow: 0,
  vertical: "min",
  horizontal: "min",
};

export const emptyStyleProp: StyleProps = {
  fill: null,
  fillStyle: "#000000",
  stroke: null,
  strokeStyle: "#000000",
  lineWidth: 1,
  lineDash: [],
  text: false,
  textStyle: null,
  textOutlineStyle: null,
  textOutlineWidth: null,
  textAlign: null,
  fontSize: null,
  vertices: null,
  edges: null,
  order: null,
  radius: [0, 0, 0, 0],
};

export const emptyDefinitionProp: DefinitionProps = {
  category: "",
  subcategory: "",
  type: "",
  subtype: "",
  name: "",
  description: "",
  fillColor: "",
  library: "",
  url: "",
  tags: [],
  status: "active",
  activity: "",
};

export const emptyProps: ShapeProps = {
  name: "",
  metrics: emptyMetricsProp,
  layout: emptyLayoutProp,
  constraints: emptyConstraintsProp,
  style: emptyStyleProp,
  definition: emptyDefinitionProp,
};

export const emptyBlock: Block = {
  id: "",
  role: "block",
  children: [],
  geometry: { type: "rectangle" as GeometryType },
  matrix: undefined,
  symbol: "",
  props: emptyProps,
};

const isObject = (o: any): boolean => {
  return typeof o === "object" && !Array.isArray(o) && o !== null;
};

export const deepCopy = (a: any): any => {
  if (isObject(a)) {
    const o: any = {};
    Object.keys(a).map((k) => (o[k] = deepCopy(a[k])));
    return o;
  } else return a;
};

const deepAssign = (a: any, b: any): any => {
  Object.keys(b).forEach((k) => {
    if (isObject(b[k])) {
      if (a[k]) a[k] = deepAssign(a[k], b[k]);
      else a[k] = { ...b[k] };
    } else a[k] = b[k];
  });
  return;
};

export const makeBlock = (props: Partial<ShapeProps>): Block => ({
  ...emptyBlock,
  props: deepAssign(deepCopy(emptyProps), props),
});

export const getters = makeGetters({
  sides: emptySidesProp,
  layout: emptyLayoutProp,
  constraints: emptyConstraintsProp,
  style: emptyStyleProp,
  definition: emptyDefinitionProp,
  metrics: emptyMetricsProp,
  metadata: emptyMetadataProp,
});

export const dividerWidths: { [k: string]: number } = {
  wall: 6,
  wall8: 8,
  wall10: 10,
  wall12: 12,
  partition: 4,
  partition6: 6,
  partition8: 8,
  partition10: 10,
  planter10x30: 10,
  planter10x60: 10,
  planter20x99: 20,
  planter20x132: 20,
  lockers: 21,
  glazing: 2,
  none: 0,
};

export const dividerLengths: { [k: string]: number } = {
  wall: 100000,
  wall8: 100000,
  wall10: 100000,
  wall12: 100000,
  partition: 36,
  partition6: 36,
  partition8: 36,
  partition10: 36,
  planter10x30: 30,
  planter10x60: 60,
  planter20x99: 99,
  planter20x132: 132,
  lockers: 17,
  glazing: 36,
  none: 0,
};

// google playbook: https://docs.google.com/presentation/d/1RaeNGylr-zbx97iyral5UYBYTO2kBqGFZMxBsNUqLuo/present?slide=id.g1267afdd1c2_0_719
// google website (rews access): https://sites.google.com/google.com/clear-and-present-data/get-started-with-wpi/wpi-initiative-specifics?authuser=0

export const program: {
  [k: string]: Option & {
    color: string;
    fillColor?: string;
    children: {
      [k: string]: Option & {
        color: string;
        fillColor?: string;
        children: { [k: string]: Option };
      };
    };
  };
} = {
  team: {
    value: "team",
    name: "Team Space",
    color: "#CDE8F6",
    fillColor: "#CDE8F6",
    children: {
      team: {
        value: "team",
        name: "Team Space",
        color: "#CDE8F6",
        fillColor: "#CDE8F6",
        children: {
          desk: { value: "desk", name: "Desk" },
          nook: { value: "nook", name: "Nook" },
          focusStudio: { value: "focusStudio", name: "Focus Studio" },
          office: { value: "office", name: "Office" },
          openCollaboration: {
            value: "openCollaboration",
            name: "Open Collaboration",
          },
          teamRoom: { value: "teamRoom", name: "Team Room" },
          teamCirculation: {
            value: "teamCirculation",
            name: "Team Circulation",
          },
          telenook: { value: "telenook", name: "Telenook" },
        },
      },
    },
  },
  meeting: {
    value: "meeting",
    name: "Meeting Space",
    color: "#7087BB",
    fillColor: "#5F78B3",
    children: {
      meeting: {
        value: "meeting",
        name: "Meeting Space",
        color: "#7087BB",
        fillColor: "#5F78B3",
        children: {
          meetingRoom: { value: "meetingRoom", name: "Meeting Room" },
          multiPurpose: { value: "multiPurpose", name: "Multi-Purpose" },
          techTalk: { value: "techTalk", name: "Tech Talk" },
          trainingRoom: { value: "trainingRoom", name: "Training Room" },
        },
      },
    },
  },
  events: {
    value: "events",
    name: "Events",
    color: "#41C08B",
    fillColor: "#A9E2CA",
    children: {
      events: {
        value: "events",
        name: "Events",
        color: "#41C08B",
        fillColor: "#A9E2CA",
        children: {
          auditorium: { value: "auditorium", name: "Auditorium" },
          eventStudio: { value: "eventStudio", name: "Event Studio" },
          avEquipment: { value: "avEquipment", name: "AV Equipment" },
          avEquipmentLg: { value: "avEquipmentLg", name: "AV Equipment LG" },
          coatCheck: { value: "coatCheck", name: "Coat Check" },
          controlRoom: { value: "controlRoom", name: "Control Room" },
          editingSuite: { value: "editingSuite", name: "Editing Suite" },
          greenRoom: { value: "greenRoom", name: "Green Room" },
          preFunction: { value: "preFunction", name: "Pre-Function" },
          digitalStudio: { value: "digitalStudio", name: "Digital Studio" },
          eventsBOH: { value: "eventsBOH", name: "Events BOH" },
        },
      },
    },
  },
  businessSupport: {
    value: "businessSupport",
    name: "Customized Business Support",
    color: "#E76873",
    fillColor: "#F3B0B6",
    children: {
      businessSupport: {
        value: "businessSupport",
        name: "Customized Business Support",
        color: "#E76873",
        fillColor: "#F3B0B6",
        children: {
          benchLab: { value: "benchLab", name: "Bench Lab" },
          chamberLab: { value: "chamberLab", name: "Chamber Lab" },
          uxLab: { value: "uxLab", name: "UX Lab" },
          mediaLab: { value: "mediaLab", name: "Media Lab" },
          modelShop: { value: "modelShop", name: "Model Shop" },
          rackSystemsLab: { value: "rackSystemsLab", name: "Rack Systems Lab" },
          studio: { value: "studio", name: "Studio" },
          cbsStorage: { value: "cbsStorage", name: "CBS Storage" },
          otherCustomizedBusinessSupport: {
            value: "otherCustomizedBusinessSupport",
            name: "Other Customized Business Support",
          },
          executiveSuite: { value: "executiveSuite", name: "Executive Suite" },
          executiveBriefingCenter: {
            value: "executiveBriefingCenter",
            name: "Executive Briefing Center",
          },
          partnerPlex: { value: "partnerPlex", name: "Partner Plex" },
        },
      },
    },
  },
  amenity: {
    value: "amenity",
    name: "Amenities and Support",
    color: "#FEBE10",
    fillColor: "#FEE08F",
    children: {
      workplaceSupport: {
        value: "workplaceSupport",
        name: "Workplace Support",
        color: "#FFDCD4",
        children: {
          lobby: { value: "lobby", name: "Lobby" },
          reception: { value: "reception", name: "Reception" },
          lounge: { value: "lounge", name: "Lounge" },
          focusRoom: { value: "focusRoom", name: "Focus Room" },
          securityAndBadging: {
            value: "securityAndBadging",
            name: "Security and Badging",
          },
          copyAndPrint: { value: "copyAndPrint", name: "Copy and Print" },
          lockers: { value: "lockers", name: "Lockers" },
          restroom: { value: "restroom", name: "Restroom" },
          showerRoom: { value: "showerRoom", name: "Shower Room" },
          monitorRoom: { value: "monitorRoom", name: "Monitor Room" },
          mailArea: { value: "mailArea", name: "Mail Area" },
          packageRoom: { value: "packageRoom", name: "Package Room" },
          shippingAndReceiving: {
            value: "shippingAndReceiving",
            name: "Shipping and Receiving",
          },
          lactationRoom: { value: "lactationRoom", name: "Lactation Room" },
          parentsRoom: { value: "parentsRoom", name: "Parents Room" },
          storage: { value: "storage", name: "Storage" },
          techStop: { value: "techStop", name: "Tech Stop" },
          napSpace: { value: "napSpace", name: "Nap Space" },
          interviewRoom: { value: "interviewRoom", name: "Interview Room" },
        },
      },
      food: {
        value: "food",
        name: "Food",
        color: "#FFAF38",
        children: {
          mk: { value: "mk", name: "MK" },
          hydrationStation: {
            value: "hydrationStation",
            name: "Hydration Station",
          },
          cafeSeating: { value: "cafeSeating", name: "Cafe Seating" },
          cafeServery: { value: "cafeServery", name: "Cafe Servery" },
          cafeBackOfHouse: { value: "cafeBackOfHouse", name: "Cafe BOH" },
          hubSeating: { value: "hubSeating", name: "Hub Seating" },
          hubServery: { value: "hubServery", name: "Hub Servery" },
          hubBackOfHouse: { value: "hubBackOfHouse", name: "Hub BOH" },
          coffeeCorner: { value: "coffeeCorner", name: "Coffee Corner" },
          teachingKitchen: {
            value: "teachingKitchen",
            name: "Teaching Kitchen",
          },
          flexServices: { value: "flexServices", name: "Flex Services" },
          cpu: { value: "cpu", name: "CPU" },
          centralKitchen: { value: "centralKitchen", name: "Central Kitchen" },
          cateringKitchen: {
            value: "cateringKitchen",
            name: "Catering Kitchen",
          },
          cateringPantry: { value: "cateringPantry", name: "Catering Pantry" },
          breakSpace: { value: "breakSpace", name: "Break Space" },
        },
      },
      transportation: {
        value: "transportation",
        name: "Transportation",
        color: "#FEF3C7",
        children: {
          dailyBikeParking: {
            value: "dailyBikeParking",
            name: "Daily Bike Parking",
          },
          bikeRepair: { value: "bikeRepair", name: "Bike Repair" },
        },
      },
      health: {
        value: "health",
        name: "Health and Performance",
        color: "#FFCA2C",
        children: {
          fitnessCenter: { value: "fitnessCenter", name: "Fitness Center" },
          groupFitnessStudio: {
            value: "groupFitnessStudio",
            name: "Group Fitness Studio",
          },
          meditationRoom: { value: "meditationRoom", name: "Meditation Room" },
          massageRoom: { value: "massageRoom", name: "Massage Room" },
          recoverySpace: { value: "recoverySpace", name: "Recovery Space" },
          fitnessLockerRoom: {
            value: "fitnessLockerRoom",
            name: "Fitness Locker Room",
          },
          indoorRecreation: {
            value: "indoorRecreation",
            name: "Indoor Recreation",
          },
          fitnessBackOfHouse: {
            value: "fitnessBackOfHouse",
            name: "Fitness BOH",
          },
        },
      },
      extendedAmenities: {
        value: "extendedAmenities",
        name: "Extended Amenities",
        color: "#B89130",
        children: {
          musicRoom: { value: "musicRoom", name: "Music Room" },
          makerSpace: { value: "makerSpace", name: "Maker Space" },
          artsRoom: { value: "artsRoom", name: "Arts Room" },
          healthLab: { value: "healthLab", name: "Health Lab" },
          healthExamRoom: { value: "healthExamRoom", name: "Health Exam Room" },
          healthRehab: { value: "healthRehab", name: "Health Rehab" },
          counselingRoom: { value: "counselingRoom", name: "Counseling Room" },
          laundryRoom: { value: "laundryRoom", name: "Laundry Room" },
          dryCleaning: { value: "dryCleaning", name: "Dry Cleaning" },
          salonAesethetic: {
            value: "salonAesethetic",
            name: "Salon Aesethetic",
          },
          hospitalityStayProgram: {
            value: "hospitalityStayProgram",
            name: "Hospitality Stay Program",
          },
          googleRetail: { value: "googleRetail", name: "Google Retail" },
          gameRoom: { value: "gameRoom", name: "Game Room" },
        },
      },
    },
  },
};
