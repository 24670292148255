import { colors } from "./constants";

export const setRotationAroundCenter = (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  degrees: number,
  w: number,
  h: number
) => {
  ctx.translate(x + w / 2, y + h / 2);
  ctx.rotate((degrees * Math.PI) / 180.0);
  ctx.translate(-x - w / 2, -y - h / 2);
};

export const resetRotationAroundCenter = (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  degrees: number,
  w: number,
  h: number
) => {
  ctx.translate(x + w / 2, y + h / 2);
  ctx.rotate(-(degrees * Math.PI) / 180.0);
  ctx.translate(-x - w / 2, -y - h / 2);
};

export const renderRegionOutline = (
  ctx: CanvasRenderingContext2D,
  region: number[][],
  scale: number,
  rotation: number
) => {
  const w = Math.abs(2 / scale);
  ctx.lineWidth = w;
  ctx.strokeStyle = colors.selectedRegionColor;
  ctx.fillStyle = colors.selectedRegionColorWithOpacity;
  ctx.save();
  ctx.beginPath();
  if (rotation && rotation > 0)
    setRotationAroundCenter(ctx, region[1][0], region[1][1], -rotation, 0, 0);
  ctx.moveTo(region[0][0] - w / 2, region[0][1] + w / 2);
  ctx.lineTo(region[1][0] - w / 2, region[1][1] - w / 2);
  ctx.lineTo(region[2][0] + w / 2, region[2][1] - w / 2);
  ctx.lineTo(region[3][0] + w / 2, region[3][1] + w / 2);
  ctx.lineTo(region[0][0] - w / 2, region[0][1] + w / 2);
  if (rotation && rotation > 0)
    resetRotationAroundCenter(ctx, region[1][0], region[1][1], -rotation, 0, 0);
  ctx.stroke();
  ctx.fill();
  ctx.restore();
};

export const renderRegionBackground = (
  ctx: CanvasRenderingContext2D,
  region: number[][],
  scale: number
) => {
  const w = Math.abs(2 / scale);
  ctx.fillStyle = "#FF35EB1A";
  ctx.save();
  ctx.beginPath();
  ctx.moveTo(region[0][0] - w / 2, region[0][1] + w / 2);
  ctx.lineTo(region[1][0] - w / 2, region[1][1] - w / 2);
  ctx.lineTo(region[2][0] + w / 2, region[2][1] - w / 2);
  ctx.lineTo(region[3][0] + w / 2, region[3][1] + w / 2);
  ctx.lineTo(region[0][0] - w / 2, region[0][1] + w / 2);
  ctx.fill();
  ctx.restore();
};
