import React from "react";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import ToolbarIconButton from "./toolbar-icon-button";
import { getAuthToken } from "lib/storage/session";
import { makeStyles } from "@material-ui/core/styles";
import {
  useBlocksCtrl,
  useBuildingsCtrl,
  useRendererCtrl,
} from "lib/containers";
import { dxfExport } from "../../lib/dxfExport";

export interface Props {
  buildingID: string;
  floorID: string;
  projectID: string;
  strategyID: string;
}

const FileButtons: React.FC<Props> = ({
  buildingID,
  projectID,
  strategyID,
}) => {
  const auth = getAuthToken();
  const { getBlockById } = useBlocksCtrl();
  const buildings = useBuildingsCtrl();
  const useStyles = makeStyles({ icon: { width: "20px", height: "20px" } });
  const { setDxfDownloadInProgress, dxfDownloadInProgress } = useRendererCtrl();
  const classes = useStyles();

  const dxfSave = async () => {
    setDxfDownloadInProgress(true);
    await dxfExport({
      buildings,
      buildingID,
      strategyID,
      projectID,
      getBlockById,
    });
    setDxfDownloadInProgress(false);
  };

  return (
    <React.Fragment>
      {auth && (
        <ToolbarIconButton
          disabled={dxfDownloadInProgress}
          onClick={dxfSave}
          title="Save Current Floorplan as DXF"
        >
          <SaveAltIcon className={classes.icon} />
        </ToolbarIconButton>
      )}
    </React.Fragment>
  );
};

export default React.memo(FileButtons);
