import React, { useCallback, useState } from "react";
import { XForm } from "lib/types";
import { getMxMy, snap } from "lib/isp-canvas/utils";
import { Canvas } from "@outerlabs/canvas-reconciler";
import { addLineToGraph, Graph, GraphNode, NodeType } from "lib/metrics/buzz";
import { v4 as uuid4 } from "uuid";
import { colors } from "lib/isp-canvas/constants";
import {
  isMouseOverNode,
  isMouseOverIntersection,
} from "lib/isp-canvas/circulation";

interface Props {
  active: boolean;
  xform: XForm;
  handleAddCirculation(graph: Graph): void;
  graph: Graph;
}

const calcOrthagonalPoints = (
  start: GraphNode,
  smx: number,
  smy: number
): number[] => {
  const prevX = start.position.x;
  const prevY = start.position.y;
  const rise = Math.abs(smy - prevY);
  const run = Math.abs(smx - prevX);
  if (rise / run > 1) return [prevX, smy];
  else return [smx, prevY];
};

export const DrawCirculation: React.FC<Props> = ({
  xform,
  active,
  handleAddCirculation,
  children,
  graph,
}) => {
  const [start, setStart] = useState<GraphNode | false>(false);
  const [hover, setHover] = useState<GraphNode | false>(false);
  const [translation, setTranslation] = useState<number[] | false>(false);
  const activeCursor = active ? <cursor cursor="crosshair" /> : null;

  const onClick = useCallback(
    (e: any) => {
      if (e.detail > 1) return;
      const { offsetX, offsetY, shiftKey } = e;
      const [mx, my] = getMxMy(offsetX, offsetY, xform);
      const [smx, smy] = snap([mx, my]);
      const endNode = isMouseOverNode(graph, mx, my);
      const intersetionNode = isMouseOverIntersection(graph, mx, my);
      let node: GraphNode;

      if (endNode) node = endNode;
      else if (intersetionNode) node = intersetionNode;
      else if (shiftKey && start) {
        const pts = calcOrthagonalPoints(start, smx, smy);
        node = {
          id: uuid4(),
          position: {
            x: pts[0],
            y: pts[1],
            z: 0,
          },
          type: NodeType.Intersection,
          connectsTo: [],
        };
      } else
        node = {
          id: uuid4(),
          position: {
            x: smx,
            y: smy,
            z: 0,
          },
          type: NodeType.Intersection,
          connectsTo: [],
        };

      if (!start) setStart(node);
      else {
        addLineToGraph(graph, start, node);
        handleAddCirculation(graph);
        setStart(false);
        setHover(false);
        setTranslation(false);
      }
    },
    [graph, handleAddCirculation, start, xform]
  );

  const onMouseMove: any = useCallback(
    async (e: MouseEvent) => {
      const { offsetX, offsetY, shiftKey } = e;
      const [mx, my] = getMxMy(offsetX, offsetY, xform);
      const endNode = isMouseOverNode(graph, mx, my);
      const intersetionNode = isMouseOverIntersection(graph, mx, my);
      if (endNode) setHover(endNode);
      else if (intersetionNode) setHover(intersetionNode);
      else setHover(false);
      const [smx, smy] = snap([mx, my]);
      if (!shiftKey || !start) setTranslation([smx, smy]);
      if (shiftKey && start) {
        const pts = calcOrthagonalPoints(start, smx, smy);
        setTranslation(pts);
      }
      e.preventDefault();
    },
    [graph, start, xform]
  );

  const props: any = { type: "DrawInteraction" };
  const eventHandler = active ? (
    <div {...props} onMouseMove={onMouseMove} onClick={onClick} />
  ) : null;
  const canvas = active ? (
    <Canvas
      render={(ctx: CanvasRenderingContext2D) => {
        if (start && translation) {
          ctx.save();
          ctx.beginPath();
          ctx.lineWidth = 20;
          ctx.strokeStyle = `rgb(0,230,0)`;
          ctx.moveTo(start.position.x, start.position.y);
          ctx.lineTo(translation[0], translation[1]);
          ctx.stroke();
          ctx.restore();
        }
        if (hover) {
          const r = 40;
          ctx.save();
          ctx.fillStyle = colors.selectedRegionColor;
          ctx.fillRect(
            hover.position.x - r / 2,
            hover.position.y - r / 2,
            r,
            r
          );
          ctx.restore();
        }
      }}
    />
  ) : null;

  return (
    <>
      {activeCursor}
      {eventHandler}
      {canvas}
      {children}
    </>
  );
};

export default React.memo(DrawCirculation);
