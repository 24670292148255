import React from "react";
import { Canvas } from "@outerlabs/canvas-reconciler";
import { XForm } from "lib/types";
import { clear } from "lib/isp-canvas";

interface Props {
  xform: XForm | undefined;
}

export const Xform: React.FC<Props> = ({ children, xform }) => {
  return (
    <Canvas
      wrap={(ctx, recurse) => {
        if (xform) {
          clear(ctx);
          ctx.setTransform(
            -1 * xform.scale,
            0,
            0,
            xform.scale,
            xform.position.x,
            xform.position.y
          );
        }
        recurse();
      }}
    >
      {children}
    </Canvas>
  );
};

export default Xform;
