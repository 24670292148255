/* eslint-disable @typescript-eslint/no-use-before-define, no-await-in-loop, no-restricted-syntax */
import { Model, FileScan } from "@outerlabs/isp/lib/scenegraph/model/types";
import { ImportOptions } from "@outerlabs/isp/lib/editor/types";
import {
  scanDXF,
  loadDXFJSON,
} from "@outerlabs/isp/lib/scenegraph/formats/dxf";
import {
  DXFParseError,
  DXFFile,
  LayerName,
  ParsedDXFFile,
  Entity,
  DXFContent,
} from "../types";
import { missingLayers } from "./layers";
import { portfolioLayers } from "./constants";
import {
  Background,
  Building,
  Coordinate,
  Drawable,
  ElementType,
  Features,
  Metrics,
  Metric,
  RoomBounds,
} from "../../lib/types";
import { emptyMetric } from "../../lib/metrics/constants";
import { Coord } from "../../blocks/lib/types";
import { FeatureTypeToSeatCount } from "../../lib/metrics/calculate-potential-seats";
import { distanceBetweenPoints } from "../../lib/metrics/remove-desks";
import { radianToDeg } from "../../lib/isp-canvas/utils";

type ScanDXFWorker = (
  data: string,
  options?: ImportOptions
) => Promise<FileScan> | FileScan;
export const MAX_FILE_SIZE = 1024 * 1024 * 1024; // 1GB

export const DXF_IMPORT_OPTIONS = {
  splitBlocks: false,
  splitLayers: true,
  simplify: true,
  cadColors: false,
  merge: false,
  filterLayers: {},
} as ImportOptions;

// Select DXF files using browser dialog (async)
export const selectDXFFiles = () => null;
// fileSelect({
// maxFileSize: MAX_FILE_SIZE,
// maxFilesSize: MAX_FILE_SIZE,
// multiple: true,
// accept: ".dxf,application/dxf,application/octet-stream",
// });

// Parse DXF files (injectable scanDXF for parallelization)
export const makeParseDXFFiles =
  (scan: ScanDXFWorker) =>
  async (inputFiles: File[], expectedLayers: LayerName[] = portfolioLayers) => {
    const errors = [] as DXFParseError[];
    const files = [] as DXFFile[];

    await Promise.all(
      Array.from(inputFiles).map(async (file: File) => {
        // Detect name and level
        const { name, level } = parseDXFFileName(file.name);

        // Read file contents
        const data = await new Response(file).text();

        // Try to parse DXF data
        try {
          const {
            parsed: dxf,
            layers: ls,
            size,
            scale: { units },
          } = await scan(data);

          const layers = ls.map((l) => l.name);
          if (!layers.length) throw new Error("No layers found");

          const missing = missingLayers(layers, expectedLayers);
          if (missing.length > 0)
            throw new Error(
              `Missing ${
                missing.length === 1 ? "layer" : "layers"
              } ${missing.map((m) => ` ${m}`)}`
            );
          const { features, background, regions } = parseDXFGeometry(
            name,
            level,
            dxf
          );
          files.push({
            name,
            level,
            layers,
            size,
            units,
            dxf,
            features,
            background,
            regions,
          });
        } catch (e: unknown) {
          if (e instanceof Error) {
            errors.push({
              file: file.name as string,
              message: e.message as string,
              stack: e.stack as string,
            });
          }
        }
      })
    );

    sortDXFFiles(files);

    return { files, errors };
  };

// Default implementation
export const parseDXFFiles = makeParseDXFFiles(scanDXF);

// Normalize some special floors to numeric indices so they sort correctly
export const normalizeLevelSort = (level: string): number => {
  // Ground / Lobby
  if (level === "L") return 0;
  if (level === "G") return 0;
  // Mezzanine
  if (level === "M") return 1;
  // Basement (not numbered)
  if (level === "B") return -1;
  // Roof
  if (level === "R") return 3;

  // Numbered basement
  const basement = level.match(/B([0-9]+)/);
  if (basement) return -basement[1] - 1;

  // Other level
  return 2;
};

// Sort files by parsed level, or by filename if missing
export const sortDXFFiles = (files: DXFFile[]) =>
  files.sort((a: DXFFile, b: DXFFile) => {
    const { level: al, name: an } = a;
    const { level: bl, name: bn } = b;
    // By level, numeric compare
    if (al != null && bl != null) {
      // Compare special level codes as numbers
      const ac = normalizeLevelSort(al);
      const bc = normalizeLevelSort(bl);
      if (ac - bc) return ac - bc;
      // Compare rest using natural sort
      return al.localeCompare(bl, undefined, { numeric: true });
    }
    // Level-less item goes first
    if (al != null) return 1;
    if (bl != null) return -1;
    // By name
    return an.localeCompare(bn, undefined, { numeric: true });
  });

// Parse DXF filename
export const parseDXFFileName = (fileName: string) => {
  // Remove file extension
  const name = fileName.replace(/\.[^.]+$/, "");
  const lowerCaseName = name.toLowerCase();

  if (lowerCaseName.includes("roof") || lowerCaseName.match(/(?:[\s_-]+|^)r$/i))
    return { name, level: "R" };
  if (lowerCaseName.includes("lobby")) return { name, level: "L" };

  // Look for 'Level/Floor/FL/Basement #'
  let matchL = lowerCaseName.match(
    /(level|floor|fl|basement)[\s_-]*([a-z]?)([0-9]*)/i
  );
  if (matchL) {
    if (matchL[1] === "basement" || matchL[2] === "b")
      return { name, level: `B${matchL[3].replace(/^0+/, "")}` };
    if (matchL[2] === "g" || matchL[2] === "m")
      return { name, level: matchL[2].toUpperCase() };
    if (matchL[2] === "l" || matchL[3] === null) return { name, level: "L" };
    return { name, level: matchL[3].replace(/^0+/, "") };
  }
  // Look for 'L# or B#'
  matchL = lowerCaseName.match(/(?:[\s_-]+|^)([l|b])([0-9]+)/i);
  if (matchL) {
    if (matchL[1] === "b") return { name, level: `B${matchL[2]}` };
    return { name, level: matchL[2] };
  }

  return { name, level: "" };
};

// Convert DXF into layered scene graph model
//
// Model contains:
//   - Layer 1
//   - Layer 2
//   - ...
export const dxfToModel = (file: DXFFile): Model => {
  const model = loadDXFJSON(file.dxf, file.name, DXF_IMPORT_OPTIONS);
  if (!model) throw new Error("Could not convert DXF data to scene graph");
  return model;
};

export const makeEmptyFeatures = (): Features => ({
  columns: [],
  circulation: [],
  walls: [],
  wallsExterior: [],
  wallsPartial: [],
  glazing: [],
  glazingExterior: [],
  skylights: [],
  conferenceRooms: {
    extraLarge: {},
    huddle: {},
    interview: {},
    large: {},
    medium: {},
    phone: {},
    small: {},
  },
  desks: [],
  primaryCirculation: [],
  uniqueDesks: [],
  floorplate: [],
  pointsOfInterest: {
    amenities: {
      bikeParkingRack: {},
      bikeParkingRoom: {},
      bikeRepair: {},
      creativeRoom: {},
      fitnessGym: {},
      fitnessOther: {},
      fitnessStudio: {},
      gameRoom: {},
      healthExamRoom: {},
      healthLab: {},
      healthRehab: {},
      laundryRoom: {},
      makerspace: {},
      massageRoom: {},
      meditationRoom: {},
      musicRoom: {},
      napSpace: {},
      salonAesthetic: {},
    },
    buildingInfrastructure: {
      restrooms: {},
    },
    businessSupport: {
      collaboration: {},
      copyAndPrint: {},
      focusRoom: {},
      lobby: {},
      lockers: {},
      mailArea: {},
      monitorRoom: {},
      mothersRoom: {},
      packageRoom: {},
      parentsRoom: {},
      reception: {},
      securityAndBadging: {},
      shippingAndReceiving: {},
      storage: {},
      techstop: {},
    },
    events: {
      audioAndVideoEquipment: {},
      audioVideoEquipmentLarge: {},
      auditorium: {},
      coatCheck: {},
      controlRoom: {},
      editingSuite: {},
      eventProductionSpace: {},
      eventStudio: {},
      greenRoom: {},
      preFunction: {},
      techTalk: {},
      trainingRoom: {},
    },
    food: {
      breakSpace: {},
      hub: {},
      hydrationStation: {},
      microKitchen: {},
      seating: {},
    },
    nonMeasuredSpace: {
      nonEnclosedRoof: {},
      outdoorSpace: {},
    },
    verticalPenetration: {
      atrium: {},
      elevator: {},
      stairway: {},
    },
    workspace: {
      equipmentStation: {},
      officeHuddleCombo: {},
      officeLarge: {},
      officeMedium: {},
      officeSmall: {},
    },
  },
});

const parseDXFEntity = (e: Entity): Drawable => {
  let angle,
    endAngle = 0;
  switch (e.type) {
    case ElementType.Arc:
      angle = radianToDeg(e.startAngle);
      endAngle = radianToDeg(e.endAngle);
      return {
        type: ElementType.Arc,
        insertName: "",
        coordinates: [{ x: e.x, y: e.y, radius: e.r, angle, endAngle }],
      };
    case ElementType.Line:
      return {
        type: ElementType.Line,
        insertName: "",
        coordinates: [
          { x: e.start.x, y: e.start.y },
          { x: e.end.x, y: e.end.y },
        ],
      };
    case ElementType.Ellipse:
      const radiusX = distanceBetweenPoints([e.x, e.y], [e.majorX, e.majorY]);
      const radiusY = radiusX * e.axisRatio;
      const rotation = Math.atan2(e.majorY - e.y, e.majorX - e.x);
      angle = radianToDeg(e.startAngle % Math.PI);
      endAngle = radianToDeg(e.endAngle % Math.PI);
      return {
        type: ElementType.Ellipse,
        insertName: "",
        coordinates: [
          { x: e.x, y: e.y, radiusX, radiusY, angle, endAngle, rotation },
        ],
      };
    case ElementType.LWPolyline:
      return {
        type: ElementType.LWPolyline,
        insertName: "",
        coordinates: e.vertices,
      };

    case ElementType.Point:
      return {
        type: ElementType.Point,
        insertName: "",
        coordinates: [{ x: e.x, y: e.y, z: e.z }],
      };
  }
};

export const makeEmptyBackground = (): Background => {
  return {
    elements: [],
    inserts: {},
    bounds: {
      topLeft: { x: 0, y: 0 },
      bottomRight: { x: 0, y: 0 },
      centroid: { x: 0, y: 0 },
    },
  };
};

const average = (points: Coordinate[]): Coordinate => {
  const avg = points.reduce(
    (a, b) => {
      return { x: a.x + b.x, y: a.y + b.y };
    },
    { x: 0, y: 0 }
  );
  avg.x /= points.length;
  avg.y /= points.length;
  return avg;
};

export const parseDXFGeometry = (
  name: string,
  level: string,
  dxf: DXFContent
) => {
  const features: Features = makeEmptyFeatures();
  const background: Background = makeEmptyBackground();
  const regions: number[][][] = [];
  const entitiesByLayer: { [k: string]: Entity[] } = {};

  dxf.entities.forEach((e) => {
    if (entitiesByLayer[e.layer]) {
      entitiesByLayer[e.layer].push(e);
    } else {
      entitiesByLayer[e.layer] = [e];
    }
  });

  Object.keys(entitiesByLayer).forEach((k) => {
    const entities = entitiesByLayer[k];
    const parsed = entities.map(parseDXFEntity).filter((p) => p != null);
    if (k.match("BACKGROUND")) {
      background.elements.push(...parsed);
    } else if (k.match("WALL")) {
      background.elements.push(...parsed);
      // needed for daylight
      if (k.match("A-WALL-E")) {
        features.walls = parsed.map((p) => p.coordinates);
      }
    } else if (k.match("GLAZ")) {
      background.elements.push(...parsed);
      // needed for daylight
      if (k.match("A-GLAZ-EXTR-E")) {
        features.glazingExterior = parsed.map((p) => p.coordinates);
      }
    } else if (k.match("FURN")) {
      background.elements.push(...parsed);
    } else if (k.match("STRS")) {
      background.elements.push(...parsed);
    } else if (k.match("MTG")) {
      const rooms: RoomBounds = {};
      let size = "small";
      if (k.match("MEDIUM")) {
        size = "medium";
      } else if (k.match("LARGE")) {
        size = "large";
      } else if (k.match("XL")) {
        size = "extraLarge";
      } else if (k.match("HUDDLE")) {
        size = "huddle";
      } else if (k.match("PHONEBOOTHS")) {
        size = "phone";
      } else if (k.match("PODS")) {
        size = "interview";
      }
      parsed.forEach((p, i) => {
        rooms[`${name}-${level || 1}-${size}-${i}`] = p.coordinates;
      });
      features.conferenceRooms[size] = rooms;
    } else if (k.match("DESKS")) {
      features.desks = parsed.map((p) => p.coordinates);
    } else if (k.match("COLS")) {
      features.columns = parsed.map((p) =>
        p.coordinates.length > 1 ? average(p.coordinates) : p.coordinates[0]
      );
    } else if (k.match("FLOOR")) {
      features.floorplate = parsed.map((p) => p.coordinates);
      // needed for automagic
    } else if (k.match("TEAMSPACE")) {
      parsed.forEach((obj) => {
        const region: number[][] = [];
        obj.coordinates.forEach((coord) => {
          region.push([coord.x, coord.y]);
        });
        regions.push(region);
      });
    } else if (k.match("SKLG")) {
      features.skylights = parsed.map((p) => p.coordinates);
    }
  });
  return { features, background, regions };
};

const polylineArea = (v: Coord[]): number => {
  let total = 0;
  for (let i = 0, l = v.length; i < l; i++) {
    const addX = v[i].x;
    const addY = v[i === v.length - 1 ? 0 : i + 1].y;
    const subX = v[i === v.length - 1 ? 0 : i + 1].x;
    const subY = v[i].y;
    total += addX * addY * 0.5;
    total -= subX * subY * 0.5;
  }
  return Math.abs(total) / 144;
};

type BuildingMetrics = { building: Metric; floors: Metrics };

export const computeBuildingMetrics = (
  b: Building,
  files: Record<string, ParsedDXFFile>
) => {
  const out: BuildingMetrics = { building: { ...emptyMetric }, floors: {} };
  Object.values(files).forEach((file) => {
    if (file.parsed) {
      const { level, features } = file.parsed;
      out.floors[level] = { ...emptyMetric };

      Object.keys(features.conferenceRooms).forEach((roomType) => {
        Object.values(features.conferenceRooms[roomType]).forEach((room) => {
          const area = polylineArea(room);
          const roomSeats = FeatureTypeToSeatCount[roomType];
          out.floors[level].conferenceRoomSeats += roomSeats;
          out.floors[level].baselineConferenceRoomsCapacity += roomSeats;
          out.floors[level][roomType as keyof Metric].capacity += roomSeats;
          out.floors[level].baselineConferenceRoomsCount += 1;
          out.floors[level][roomType as keyof Metric].count += 1;
          out.floors[level][roomType as keyof Metric].area += area;
        });
      });
      out.floors[level].totalFloorArea = features.floorplate.reduce(
        (a, b2) => a + polylineArea(b2),
        0
      );

      // calc some floor level metrics
      out.building.buildingID = b.BuildingId;
      out.floors[level].buildingID = b.BuildingId;
      out.floors[level].seatCount = features.desks.length;
      out.floors[level].assignableSeats = features.desks.length;
      out.floors[level].baselineSeatCount = features.desks.length;
      out.floors[level].assignableSeats = features.desks.length;
      out.floors[level].baselineHeadCount = features.desks.length;
      out.floors[level].baselinePhoneCount = Object.values(
        features.conferenceRooms.phone
      ).length;

      // roll up to the building level
      out.building.displayName = out.floors[level].displayName;
      out.building.strategyID = out.floors[level].strategyID;
      out.building.totalFloorArea += out.floors[level].totalFloorArea;
      out.building.efficiencyRatio = out.floors[level].efficiencyRatio;
      out.building.baselineHeadCount += out.floors[level].baselineHeadCount;
      out.building.baselineSeatCount += out.floors[level].baselineSeatCount;
      out.building.baselinePhoneCount += out.floors[level].baselinePhoneCount;
      out.building.seatCount += out.floors[level].seatCount;
      out.building.assignableSeats += out.floors[level].assignableSeats;
      out.building.potentialSeats += out.floors[level].potentialSeats;
      out.building.convertedSeats += out.floors[level].convertedSeats;
      out.building.workpoints += out.floors[level].workpoints || 0;
      out.building.totalCost += out.floors[level].totalCost;
      out.building.costGSF += out.floors[level].costGSF;
      out.building.costHC += out.floors[level].costHC;
      out.building.phonesCount += out.floors[level].phonesCount;
      out.building.nooksCount += out.floors[level].nooksCount;
    }
  });
  return out;
};
