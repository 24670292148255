import React from "react";
import { makeStyles } from "@material-ui/core";
import config from "config";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "800px",
    height: "474px",
  },
  containerMessage: { position: "relative", right: "220px", top: "30px" },
  messageText: { fontSize: "32px", color: "#555555", fontWeight: "normal" },
  messageSubText: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "23px",
    letterSpacing: "0.25px",
    color: "#ADADAD",
    width: "368px",
  },
  artWorContainer: { height: "365px" },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
});

interface Props {
  text?: string;
  subText?: string;
}

const EmptyState: React.FC<Props> = ({ text, subText }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.containerMessage}>
        <div className={classes.messageText}>{text}</div>
        <div className={classes.messageSubText}>{subText}</div>
      </div>
      <div className={classes.artWorContainer}>
        <img
          alt=""
          key={"emptyState"}
          src={`${config.storageUrl}/images/EmptyStateArtwork.svg`}
        />
      </div>
    </div>
  );
};

export default EmptyState;
