import React from "react";
import { colors, emptyMetricsProp, emptyStyleProp, getters } from "./constants";
import {
  Button,
  Coord,
  Block,
  Position,
  Rect,
  Transform,
  Dimensions,
  Direction,
  HitBox,
  BlockGetter,
} from "./types";
import {
  Box,
  boxToSize,
  Edge,
  GeometryType,
  Point,
} from "@outerlabs/shapes-geometry";
import { makeShape } from "@outerlabs/shapes-core";
import { mat4, quat, vec3 } from "gl-matrix";
import { applyBlocks } from "./blocks";
import { createBlockImage } from "../../lib/isp-canvas";
import { INode } from "svgson";
import { Coordinate } from "lib/types";

export const lerp = (a: number, b: number, t: number) => a * (1 - t) + b * t;

// make points array for a blockInstance
export const makePoints = (
  rectangle: Dimensions,
  tl?: number[]
): number[][] => {
  const [x, y] = tl ? tl : [0, 0];
  return [
    [x, y],
    [x + rectangle.w, y + rectangle.h],
  ];
};

// Get a group's color by group type
export const colorByType = (type: string): string => {
  switch (type) {
    case "focus":
      return colors.teamColor;
    case "collaboration":
      return colors.collabColor;
    default:
      return "rgba(0,0,0,1)";
  }
};

// Get a group's highlight color by group type, used on hover
export const highlightByType = (type: string): string => {
  switch (type) {
    case "focus":
      return colors.teamColorHightlight;
    case "collaboration":
      return colors.collabColorHightlight;
    default:
      return "rgba(0,0,0,1)";
  }
};

// Get a group's orientation (row, col) from it's relative position on the block (top, left, bottom, right)
export const orientationFromPosition = (p?: Position): Direction => {
  switch (p) {
    case Position.Bottom:
    case Position.Top:
      return "horizontal";
    default:
      return "vertical";
  }
};

// Get the mouse position
export const getMousePos = (
  canvas: HTMLCanvasElement,
  event: React.MouseEvent<HTMLElement>
): Coord => {
  const rect = canvas.getBoundingClientRect();
  return {
    x: event.clientX - rect.left,
    y: event.clientY - rect.top,
  };
};

// Check if a coordinate is inside of a rectangle, with an optional transform
export const isInside = (
  pos: Coord,
  rect: Rect,
  transform?: Transform
): boolean => {
  if (transform) {
    const { scale, tl } = transform;
    const tz = {
      x: rect.x * scale + tl.x,
      y: rect.y * scale + tl.y,
      h: rect.h * scale,
      w: rect.w * scale,
    };
    return (
      pos.x > tz.x && pos.x < tz.x + tz.w && pos.y < tz.y + tz.h && pos.y > tz.y
    );
  } else {
    return (
      pos.x > rect.x &&
      pos.x < rect.x + rect.w &&
      pos.y < rect.y + rect.h &&
      pos.y > rect.y
    );
  }
};

export const isInsideMat = (
  v: [number, number],
  box: [[number, number], [number, number]],
  mat: mat4
): boolean => {
  const p0 = vec3.transformMat4(
    vec3.create(),
    vec3.fromValues(box[0][0], box[0][1], 0),
    mat
  );
  const p1 = vec3.transformMat4(
    vec3.create(),
    vec3.fromValues(box[1][0], box[1][1], 0),
    mat
  );
  let x = [p0[0], p1[0]];
  if (x[0] > x[1]) x = [x[1], x[0]];
  let y = [p0[1], p1[1]];
  if (y[0] > y[1]) y = [y[1], y[0]];
  return v[0] > x[0] && v[0] < x[1] && v[1] > y[0] && v[1] < y[1];
};

// Get the default blocks for a type (collaboration, focus), given an orientation (row, col)
export const defaultByType = (
  type: string,
  orientation: Direction,
  rotation = 0
): Block => {
  switch (type) {
    case "collaboration":
      return makeShape({
        role: "block",
        matrix: mat4.create(),
        props: {
          name: "4",
          layout: {
            mode: "flex",
            flex: { mode: orientation, repeat: [1, 1], rotation },
          },
          definition: { type: "collaboration", subtype: "desk", name: "4" },
        },
      });
    case "focus":
    default:
      return makeShape({
        role: "block",
        symbol: "Asset-Office-Small-1",
        matrix: mat4.create(),
        props: {
          name: "large-8ft",
          layout: {
            mode: "flex",
            flex: { mode: orientation, repeat: [1, 1], rotation },
          },
          definition: { type: "focus", subtype: "desk", name: "large-8ft" },
        },
      });
  }
};

const rotate = (angle: number, point: number[]): number[] => {
  const cos = Math.cos(angle);
  const sin = Math.sin(angle);
  return [cos * point[0] + sin * point[1], cos * point[1] - sin * point[0]];
};

// Get min/max bounds for a asset given a block region and a asset definition
export const minMaxByType = (block: Block, asset: Block) => {
  const blockLayout = getters.getLayout(block);
  const { size } = getters.getMetrics(asset);
  let m = [0, 0];
  let repeat = [
    [1, 1],
    [1, 1],
  ];
  let rotation = 0;
  const spacing = blockLayout.flex?.spacing || 0;
  if (blockLayout.mode === "flex") {
    const margin = blockLayout.padding || [0, 0, 0, 0];
    m = [margin[1] + margin[3], margin[0] + margin[2]];
    rotation = blockLayout.rotation || 0;
    if (blockLayout.flex?.mode !== "none") {
      const r = blockLayout.flex?.repeat || [1, 1];
      repeat =
        !blockLayout.flex || blockLayout.flex?.mode === "horizontal"
          ? [r, [1, 1]]
          : [[1, 1], r];
    }
  }

  const box = [
    [0, 0],
    [size[0], 0],
    [size[0], size[1]],
    [0, size[1]],
  ];
  const angle = rotation * (Math.PI / 180);
  let xMax = -Infinity;
  let yMax = -Infinity;
  let xMin = Infinity;
  let yMin = Infinity;
  box
    .map((point) => rotate(angle, point))
    .forEach((point) => {
      if (point[0] < xMin) xMin = point[0];
      if (point[1] < yMin) yMin = point[1];
      if (point[0] > xMax) xMax = point[0];
      if (point[1] > yMax) yMax = point[1];
    });
  // prettier-ignore
  const sin = Math.abs(Math.sin(angle))
  const cos = Math.abs(Math.cos(angle));
  const s = [size[0] * cos + size[1] * sin, size[1] * cos + size[0] * sin];
  const [u, v] = [0, 1];

  const pm = blockLayout.offset || [0, 0, 0, 0];
  const mu = pm[u + 1] + pm[(u + 3) % 4];
  const mv = pm[v + 1] + pm[(v + 3) % 4];
  if (repeat) {
    const min0 = repeat[0][0];
    const max0 = repeat[0][1];
    const min1 = repeat[1][0];
    const max1 = repeat[1][1];
    return [
      min0 * s[u] + (min0 - 1) * (mu + spacing) + m[0],
      max0 * s[u] + (max0 - 1) * (mu + spacing) + m[0],
      min1 * s[v] + (min1 - 1) * (mv + spacing) + m[1],
      max1 * s[v] + (max1 - 1) * (mv + spacing) + m[1],
    ];
  } else {
    return [s[u] + m[u], s[u] + m[u], s[v] + m[v], s[v] + m[v]];
  }
};

// Make a button from an array (width/height) and it's center position
export const makeButtons = (size: Point, center: Coord): Button[] => {
  const [w, h] = size;
  const depth = 40;
  const s = 30;
  const buttons: Button[] = [
    {
      x: center.x - w / 2,
      y: center.y - h / 2 - depth,
      w,
      h: s,
      p: Position.Top,
    },
    {
      x: center.x - w / 2,
      y: center.y + h / 2 + depth - s,
      w,
      h: s,
      p: Position.Bottom,
    },
    {
      x: center.x - w / 2 - depth,
      y: center.y - h / 2,
      w: s,
      h,
      p: Position.Left,
    },
    {
      x: center.x + w / 2 + depth - s,
      y: center.y - h / 2,
      w: s,
      h,
      p: Position.Right,
    },
  ];
  return buttons;
};

// Get the dimensions of a bounding box [[left, top], [right, bottom]]
export const getInstanceDims = (points: number[][]): number[] => {
  const width = Math.abs(points[1][0] - points[0][0]);
  const height = Math.abs(points[1][1] - points[0][1]);
  return [width, height];
};

const makeHitBox = (
  b: Box,
  width: number,
  side: "top" | "right" | "bottom" | "left",
  offset = 0,
  double = false
): Pick<HitBox, "box" | "line"> => {
  let line: Edge;
  switch (side) {
    case "left":
      line = [
        [b[0][0], b[0][1]],
        [b[0][0], b[1][1]],
      ];
      return {
        line,
        box: [
          [line[0][0] - (double ? width : 0), line[0][1]],
          [line[1][0] + width, line[1][1]],
        ],
      };
    case "right":
      line = [
        [b[1][0] + offset, b[0][1]],
        [b[1][0] + offset, b[1][1]],
      ];
      return {
        line,
        box: [
          [line[0][0] - width, line[0][1]],
          [line[1][0] + (double ? width : 0), line[1][1]],
        ],
      };
    case "top":
      line = [
        [b[0][0], b[0][1]],
        [b[1][0], b[0][1]],
      ];
      return {
        line,
        box: [
          [line[0][0], line[0][1] - (double ? width : 0)],
          [line[1][0], line[1][1] + width],
        ],
      };
    case "bottom":
      line = [
        [b[0][0], b[1][1] + offset],
        [b[1][0], b[1][1] + offset],
      ];
      return {
        line,
        box: [
          [line[0][0], line[0][1] - width],
          [line[1][0], line[1][1] + (double ? width : 0)],
        ],
      };
  }
};

export const makeHitBoxes = (block: Block): HitBox[] => {
  const boxes: HitBox[] = [];
  const mult = 6;
  const base = 8;
  const flex = block.props.layout?.flex;
  const b = block.geometry.box;
  if (b) {
    const data: Omit<HitBox, "box" | "line"> = {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      mat: block.matrix!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      blockMat: block.matrix!,
      block,
      depth: 0,
      idx: 0,
      outer: true,
    };
    if (flex?.mode === "vertical") {
      boxes.push({ ...data, idx: 0, ...makeHitBox(b, base, "left") });
      boxes.push({ ...data, idx: 1, ...makeHitBox(b, base, "right") });
    } else {
      boxes.push({ ...data, idx: 0, ...makeHitBox(b, base, "top") });
      boxes.push({ ...data, idx: 1, ...makeHitBox(b, base, "bottom") });
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-shadow
  applyBlocks(block, (b, m, depth) => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const flex = b.props.layout?.flex;
    if (b.role === "block-instance" && b.children.length > 0 && flex) {
      const isVertical = flex.mode === "vertical";
      const w = base + mult * depth;

      // start
      if (b.geometry.box) {
        boxes.push({
          ...makeHitBox(b.geometry.box, w, isVertical ? "top" : "left"),
          ...{ mat: m, blockMat: m, block: b, depth, idx: 0, outer: false },
        });
      }

      // middle
      if (b.children.every((child) => child.role === "block-instance")) {
        let o = 0;
        if (flex.spacing) {
          o += flex.spacing / 2;
        }
        for (let i = 0; i < b.children.length - 1; i++) {
          const child = b.children[i];
          if (child.geometry.box) {
            boxes.push({
              ...makeHitBox(
                child.geometry.box,
                w / 2,
                isVertical ? "bottom" : "right",
                o,
                true
              ),
              ...{
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                mat: mat4.mul(mat4.create(), child.matrix!, m),
                blockMat: m,
                block: b,
                depth,
                idx: i + 1,
                outer: false,
              },
            });
          }
        }
      }

      // end
      if (b.geometry.box) {
        boxes.push({
          ...makeHitBox(b.geometry.box, w, isVertical ? "bottom" : "right"),
          ...{
            mat: m,
            blockMat: m,
            block: b,
            depth,
            idx: b.children.length,
            outer: false,
          },
        });
      }

      // sides
      if (b.geometry.box) {
        boxes.push({
          ...makeHitBox(b.geometry.box, w, isVertical ? "left" : "top"),
          ...{ mat: m, blockMat: m, block: b, depth, idx: 0, outer: true },
        });
        boxes.push({
          ...makeHitBox(b.geometry.box, w, isVertical ? "right" : "bottom"),
          ...{ mat: m, blockMat: m, block: b, depth, idx: 1, outer: true },
        });
      }
    }
  });

  return boxes.reverse();
};

export const makeBlockThumbnailMatchmaker = async (
  block: Block,
  getBlockById: BlockGetter,
  size: number[]
): Promise<string> => {
  return createBlockImage({
    region: [
      [0, size[1]],
      [0, 0],
      [size[0], 0],
      [size[0], size[1]],
    ],
    block,
    getBlockById,
    wpiActivitiesMode: true,
  });
};

export const makeBlockThumbnail = async (
  block: Block,
  getBlockById: BlockGetter,
  previewSize = 0.6
): Promise<string> => {
  const { sizeRange } = getters.getMetrics(block);
  const previewRotation = 0;
  const range: Point[] = sizeRange
    ? sizeRange
    : [
        [0, 100],
        [0, 100],
      ];
  const baseSize: Point = [
    range[0][0] * (1 - previewSize) + range[0][1] * previewSize,
    range[1][0] * (1 - previewSize) + range[1][1] * previewSize,
  ];
  const isHorizontal = (previewRotation / 90) % 2 === 0;
  const size: Point = isHorizontal ? baseSize : [baseSize[1], baseSize[0]];
  const region = [
    [0, size[1]],
    [0, 0],
    [size[0], 0],
    [size[0], size[1]],
  ];
  const image = await createBlockImage({
    region,
    block,
    getBlockById,
    wpiActivitiesMode: false,
  });
  return image;
};

// Cache block thumbnails
const CachedThumbnails: { [k: string]: string } = {};

export const makeBlockThumbnails = async (
  blocks: Block[],
  getBlockById: BlockGetter,
  previewSize = 0.6
): Promise<string[]> => {
  await Promise.all(
    blocks.map(async (block) => {
      if (!CachedThumbnails[block.id]) {
        CachedThumbnails[block.id] = await makeBlockThumbnail(
          block,
          getBlockById,
          previewSize
        );
      }
    })
  );
  return blocks.map((block) => CachedThumbnails[block.id]);
};

// Export all blocks and assets from localstorage
export const exportBlocks = () => {
  const values: { [k: string]: Block } = {};
  for (const key in localStorage) {
    const s = localStorage.getItem(key);
    if (s) values[key] = JSON.parse(s);
  }
  const dataStr =
    "data:text/json;charset=utf-8," +
    encodeURIComponent(JSON.stringify(values, null, 2));
  const el = document.createElement("a");
  if (el) {
    el.setAttribute("href", dataStr);
    el.setAttribute("download", "export.json");
    document.body.appendChild(el); // required for firefox
    el.click();
    el.remove();
  }
};

type Candidate = {
  ratioA: number;
  ratioB: number;
  width: number;
  score: number;
};

export const pack = (blocks: Candidate[], width: number): Candidate[][] => {
  // create a lookup table
  // lookup[i] is going to store maximum value
  // with knapsack capacity i.
  const lookup = new Array(width + 1).fill(0);
  const blockMap: Candidate[][] = new Array(width + 1).fill([]);
  const results: { score: number; blocks: Candidate[] }[][] = new Array(
    width + 1
  ).fill([]);
  const n = blocks.length;
  // fill lookup[] using above recursive formula
  for (let i = 0; i <= width; i++) {
    for (let j = 0; j < n; j++) {
      if (blocks[j].width <= i) {
        const l0 = lookup[i];
        const l1 = lookup[i - blocks[j].width] + blocks[j].score;
        if (l1 > l0) {
          lookup[i] = l1;
          blockMap[i] = [...blockMap[i - blocks[j].width], blocks[j]];
        }
        results[i].push({
          score: l1,
          blocks: [...blockMap[i - blocks[j].width], blocks[j]],
        });
      }
    }
  }

  return results[width]
    .sort((a, b) => (a.score > b.score ? -1 : a.score < b.score ? 1 : 0))
    .map((res) => res.blocks);
};

export const convertArrayToMatrix = (o: any) => {
  if (o instanceof Object) {
    if (o.matrix) {
      o.matrix = mat4.fromValues.apply(null, o.matrix);
    }
    Object.keys(o).map((k) => (o[k] = convertMatrixToArray(o[k])));
  }
  return o;
};

export const convertMatrixToArray = (o: any) => {
  if (o instanceof Object) {
    if (o.matrix) {
      o.matrix = Object.keys(o.matrix).map((k) => o.matrix[k]);
    }
    Object.keys(o).map((k) => (o[k] = convertMatrixToArray(o[k])));
  }
  return o;
};

export const cloneBlock = (block: Block): Block => {
  return convertArrayToMatrix(
    JSON.parse(JSON.stringify(convertMatrixToArray(block)))
  );
};

export const handleFile = (
  e: any,
  cb: (name: string, data: string) => void
) => {
  const files: File[] = Array.from(e.target.files);
  console.warn("e", e, files);
  files.forEach((file: File) => {
    const reader = new FileReader();

    reader.onabort = () => console.error("file reading was aborted");
    reader.onerror = () => console.error("file reading has failed");
    reader.onload = () => {
      const binaryStr = reader.result;
      const t = new TextDecoder("utf-8");
      let s = "";
      if (typeof binaryStr === "string") {
        s = binaryStr;
      } else if (binaryStr) {
        s = t.decode(new Uint8Array(binaryStr));
      }
      cb(file.name.split(".")[0], s);
    };
    reader.readAsArrayBuffer(file);
  });
};

export const svgToBlock = (el: INode, w?: number, h?: number): Block => {
  const width = parseInt(el.attributes.width) || w || 0;
  const height = parseInt(el.attributes.height) || h || 0;
  const children = el.children.map((child) => svgToBlock(child, width, height));
  // TODO: Group
  const x = parseInt(el.attributes.x) || 0;
  const y = parseInt(el.attributes.y) || 0;
  const isRoot = el.name === "svg";
  const shape = makeShape({
    role: isRoot ? "asset" : "",
    matrix: mat4.fromRotationTranslationScale(
      mat4.create(),
      quat.fromEuler(quat.create(), 0, 0, 0),
      vec3.fromValues(x, y, 0),
      vec3.fromValues(1, 1, 1)
    ),
    children,
    geometry: {
      type: "box" as GeometryType,
      box: [
        [0, 0],
        [width, height],
      ],
    },
    props: {
      metrics: {
        ...emptyMetricsProp,
        size: [width, height],
        sizeRange: [
          [width, width],
          [height, height],
        ],
      },
    },
  });
  shape.props.style = { ...emptyStyleProp };
  if (el.attributes.fill && el.attributes.fill !== "none") {
    shape.props.style.fill = true;
    shape.props.style.fillStyle = el.attributes.fill;
  }
  if (el.attributes.stroke && el.attributes.stroke !== "none") {
    shape.props.style.stroke = true;
    shape.props.style.strokeStyle = el.attributes.stroke;
  }
  if (el.attributes["stroke-width"]) {
    shape.props.style.lineWidth = parseFloat(el.attributes["stroke-width"]);
  }
  if (el.attributes["fill-opacity"]) {
    shape.props.style.fillOpacity = parseFloat(el.attributes["fill-opacity"]);
  }
  if (el.attributes["stroke-dasharray"]) {
    shape.props.style.lineDash = el.attributes["stroke-dasharray"]
      .split(" ")
      .map((_el) => parseFloat(_el));
  }
  if (isRoot) {
    delete shape.props.style;
    shape.symbol = shape.id;
  }

  function deltaTransformPoint(
    matrix: { a: number; b: number; c: number; d: number },
    point: { x: number; y: number }
  ) {
    const dx = point.x * matrix.a + point.y * matrix.c + 0;
    const dy = point.x * matrix.b + point.y * matrix.d + 0;
    return { x: dx, y: dy };
  }

  function decomposeMatrix(matrix: {
    a: number;
    b: number;
    c: number;
    d: number;
    e: number;
    f: number;
  }) {
    // @see https://gist.github.com/2052247

    // calculate delta transform point
    const px = deltaTransformPoint(matrix, { x: 0, y: 1 });
    const py = deltaTransformPoint(matrix, { x: 1, y: 0 });

    // calculate skew
    const skewX = (180 / Math.PI) * Math.atan2(px.y, px.x) - 90;
    const skewY = (180 / Math.PI) * Math.atan2(py.y, py.x);

    return {
      translateX: matrix.e,
      translateY: matrix.f,
      scaleX: Math.sqrt(matrix.a * matrix.a + matrix.b * matrix.b),
      scaleY: Math.sqrt(matrix.c * matrix.c + matrix.d * matrix.d),
      skewX: skewX,
      skewY: skewY,
      rotation: skewX, // rotation is the same as skew x
    };
  }
  if (el.attributes.transform) {
    let rotation = quat.fromEuler(quat.create(), 0, 0, 0);
    let translation = vec3.fromValues(0, 0, 0);
    let scale = vec3.fromValues(1, 1, 1);
    const t = parseTransform(el.attributes.transform);
    if (t.rotate) {
      rotation = quat.fromEuler(quat.create(), 0, 0, parseFloat(t.rotate[0]));
      if (t.rotate.length === 3) {
        translation = vec3.fromValues(
          parseFloat(t.rotate[1]),
          parseFloat(t.rotate[2]),
          0
        );
      }
    } else if (t.matrix) {
      const m = t.matrix.map(parseFloat);
      const dec = decomposeMatrix({
        a: m[0],
        b: m[1],
        c: m[2],
        d: m[3],
        e: m[4],
        f: m[5],
      });
      rotation = quat.fromEuler(quat.create(), 0, 0, dec.rotation);
      translation = vec3.fromValues(dec.translateX, dec.translateY, 0);
      // TODO: this is wrong
      scale = vec3.fromValues(dec.scaleX, dec.scaleY, 1);
      const mm = mat4.fromValues(
        m[0],
        m[1],
        0,
        0,
        m[2],
        m[3],
        0,
        0,
        0,
        0,
        1,
        0,
        m[4],
        m[5],
        0,
        1
      );
      console.error("mm", dec, mm);
    }
    shape.matrix = mat4.fromRotationTranslationScale(
      mat4.create(),
      rotation,
      translation,
      scale
    );
    console.error("mmm", shape.matrix);
  }
  switch (el.name) {
    case "circle":
      shape.geometry.type = "ellipse" as GeometryType;
      const center: Point = [
        parseFloat(el.attributes.cx),
        parseFloat(el.attributes.cy),
      ];
      const radius = parseFloat(el.attributes.r);
      if (el.attributes.transform) {
        const m = mat4.fromTranslation(
          mat4.create(),
          vec3.fromValues(-radius, -radius, 0)
        );
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        shape.matrix = mat4.mul(mat4.create(), shape.matrix!, m);
      } else {
        const m = mat4.fromTranslation(
          mat4.create(),
          vec3.fromValues(center[0] - radius, center[1] - radius, 0)
        );
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        shape.matrix = mat4.mul(mat4.create(), shape.matrix!, m);
      }
      shape.geometry.box = [
        [center[0] - radius, center[1] - radius],
        [center[0] + radius, center[1] + radius],
      ];
      break;
    case "rect":
      const { rx } = el.attributes;
      if (rx) {
        const r = parseFloat(rx);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        shape.props.style!.radius = [r, r, r, r];
      }
      break;
    case "line":
      shape.geometry = {
        type: "polygon" as GeometryType,
        polygon: [
          [
            [parseFloat(el.attributes.x1), parseFloat(el.attributes.y1)],
            [parseFloat(el.attributes.x2), parseFloat(el.attributes.y2)],
          ],
        ],
      };
      break;
    case "path":
      shape.props.paths = [el.attributes.d];
      break;
    case "svg":
    case "default":
      break;
  }
  return shape;
};

export const parseTransform = (a: string) => {
  const b: { [k: string]: string[] } = {};
  const maybeMatches = a.match(/(\w+)\(([^,)]+),?([^)]+)?\)/gi);
  for (const i in maybeMatches) {
    const c: any = a[i as any].match(/[\w.-]+/g);
    b[c.shift() as string] = c;
  }
  return b;
};

export const PIXEL_RATIO = (() => {
  const ctx = document.createElement("canvas").getContext("2d") as any;
  const dpr = window.devicePixelRatio || 1;
  const bsr =
    ctx.webkitBackingStorePixelRatio ||
    ctx.mozBackingStorePixelRatio ||
    ctx.msBackingStorePixelRatio ||
    ctx.oBackingStorePixelRatio ||
    ctx.backingStorePixelRatio ||
    1;
  return dpr / bsr;
})();

export const createHiDPICanvas = function (
  can: HTMLCanvasElement,
  w: number,
  h: number,
  ratio?: number
) {
  if (!ratio) ratio = PIXEL_RATIO;
  can.width = w * ratio;
  can.height = h * ratio;
  can.style.width = w + "px";
  can.style.height = h + "px";
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  can.getContext("2d")!.setTransform(ratio, 0, 0, ratio, 0, 0);
  return can;
};

export const getTransformedBox = (block: Block): Point[] => {
  const { box } = block.geometry;
  if (!box) return [];
  const b = [
    vec3.fromValues(box[0][0], box[1][1], 0),
    vec3.fromValues(box[0][0], box[0][1], 0),
    vec3.fromValues(box[1][0], box[0][1], 0),
    vec3.fromValues(box[1][0], box[1][1], 0),
  ];
  return (
    b
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      .map((p) => vec3.transformMat4(vec3.create(), p, block.matrix!))
      .map((p) => [p[0], p[1]])
  );
};

export const pointInPolygon = (point: Point, vs: Point[]) => {
  // ray-casting algorithm based on
  // https://wrf.ecse.rpi.edu/Research/Short_Notes/pnpoly.html/pnpoly.html
  const x = point[0];
  const y = point[1];
  let inside = false;
  for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
    const xi = vs[i][0];
    const yi = vs[i][1];
    const xj = vs[j][0];
    const yj = vs[j][1];
    // prettier-ignore
    const intersect = (yi > y) !== (yj > y) && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }
  return inside;
};

export const pointInBox = (point: Point, box: Box) => {
  return (
    point[0] > box[0][0] &&
    point[0] < box[1][0] &&
    point[1] > box[0][1] &&
    point[1] < box[1][1]
  );
};

export const pointInBlock = (point: Point, block: Block, mat: mat4) => {
  if (!block.matrix || !block.geometry.box) return false;
  const p = vec3.transformMat4(
    vec3.create(),
    vec3.fromValues(point[0], point[1], 0),
    mat
  );
  return pointInBox([p[0], p[1]], block.geometry.box);
};

export const pointInBlockRecursive = (
  point: Point,
  block: Block,
  root: Block
): Block | false => {
  if (!block.matrix || !block.geometry.box) return false;
  if (pointInBox([point[0], point[1]], block.geometry.box)) {
    if (block.children.length > 0) {
      for (let i = 0; i < block.children.length; i++) {
        const child = block.children[i];
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const cm = mat4.invert(mat4.create(), child.matrix!);
        const p =
          child.role === "asset-instance"
            ? point
            : vec3.transformMat4(
                vec3.create(),
                vec3.fromValues(point[0], point[1], 0),
                cm
              );
        const res = pointInBlockRecursive([p[0], p[1]], child, root);
        if (res) return res;
      }
    }
    return block;
  }
  return false;
};

export const checkRange = (
  value: number,
  range: [number, number],
  tolerance = 0
) => {
  return value >= range[0] - tolerance && value <= range[1] + tolerance;
};

export const checkSizeRange = (
  size: [number, number],
  range: [[number, number], [number, number]],
  tolerance = 0
) => {
  return (
    size[0] >= range[0][0] - tolerance &&
    size[0] <= range[0][1] + tolerance &&
    size[1] >= range[1][0] - tolerance &&
    size[1] <= range[1][1] + tolerance
  );
};

export function setCached<T>(
  name: string,
  cb?: (value: T) => void
): (v: T) => void {
  return (value: T) => {
    window.localStorage.setItem(name, JSON.stringify(value));
    if (cb) cb(value);
  };
}

export function getCached<T>(name: string, defaultValue: T): T {
  const res = window.localStorage.getItem(name);
  return res ? (JSON.parse(res) as T) : defaultValue;
}

// Given a rectangular region and a Block, this should find the optimal way to tile the Block as many times as possible.
// We start by finding aligning the Block's long axis perpendicular to the region's long axis to see if it fits, and if so
// we figure out how many times it can be tiled along the region's long axis. Since Blocks can have varying size, we keep
// the Block's short axis as small as possible so we can maximize the number of Blocks (and consequently headcount).
export const subdivideRegion = (
  blocks: Block[],
  region: number[][]
): { region: number[][]; blockId: string }[] => {
  // for now pick the first block. in the future we may use multiple candidates.
  const block = blocks[0];
  const { sizeRange } = getters.getMetrics(block);
  let blockLongMin,
    blockLongMax,
    blockShortMin,
    regionLong,
    regionShort = 0;

  // find short and long sides for the block
  if (sizeRange[0][1] > sizeRange[1][1]) {
    [blockShortMin] = sizeRange[1];
    [blockLongMin, blockLongMax] = sizeRange[0];
  } else {
    [blockShortMin] = sizeRange[0];
    [blockLongMin, blockLongMax] = sizeRange[1];
  }

  // find short and long sides for the region
  const rx = Math.abs(region[0][0] - region[2][0]);
  const ry = Math.abs(region[0][1] - region[2][1]);

  // eslint can't destructure arrays, looks like a bug
  // eslint-disable-next-line prefer-const
  [regionShort, regionLong] = rx > ry ? [ry, rx] : [rx, ry];

  // number of regions
  const n = Math.floor(regionLong / blockShortMin);
  // keep the short dimension as small as possible
  const blockShort = blockShortMin;
  const blockLong = Math.max(blockLongMin, Math.min(blockLongMax, regionShort));

  // create subregions
  const sub: { region: number[][]; blockId: string }[] = [];
  // if the block doesn't fit, return empty
  if (rx < blockShort || ry < blockShort) return sub;
  if (rx > ry) {
    // horizontal region
    for (let i = 0; i < n; i++) {
      sub.push({
        blockId: block.id,
        region: [
          [region[0][0] + i * blockShort, region[1][1] + blockLong],
          [region[0][0] + i * blockShort, region[1][1]],
          [region[0][0] + (i + 1) * blockShort, region[1][1]],
          [region[0][0] + (i + 1) * blockShort, region[1][1] + blockLong],
        ],
      });
    }
  } else {
    // vertical region
    for (let i = 0; i < n; i++) {
      sub.push({
        blockId: block.id,
        region: [
          [region[0][0], region[1][1] + (i + 1) * blockShort],
          [region[0][0], region[1][1] + i * blockShort],
          [region[0][0] + blockLong, region[1][1] + i * blockShort],
          [region[0][0] + blockLong, region[1][1] + (i + 1) * blockShort],
        ],
      });
    }
  }
  return sub;
};

export const getDesksFromBlocks = (blocks: Block[][]): Coordinate[] => {
  let points: Coordinate[] = [];
  blocks.forEach((group) => {
    group.forEach((block) => {
      let m = block.matrix || mat4.create();
      const metrics = getters.getMetrics(block);
      const layout = getters.getLayout(block);
      const q = mat4.getRotation(quat.create(), m);
      let angle = quat.getAxisAngle(vec3.fromValues(0, 0, 1), q);
      if (q[2] < 0) angle = -angle;
      let mx = mat4.create();
      if (layout.mirrorX && layout.mirrorY) {
        mx = mat4.fromRotationTranslationScale(
          mat4.create(),
          quat.fromEuler(quat.create(), 0, 0, 180),
          vec3.fromValues(metrics.size[0], metrics.size[1], 0),
          vec3.fromValues(1, 1, 1)
        );
      } else if (layout.mirrorY) {
        mx = mat4.fromRotationTranslationScale(
          mat4.create(),
          quat.fromEuler(quat.create(), 0, 0, 1),
          vec3.fromValues(0, metrics.size[1], 0),
          vec3.fromValues(1, -1, 1)
        );
      } else if (layout.mirrorX) {
        mx = mat4.fromRotationTranslationScale(
          mat4.create(),
          quat.fromEuler(quat.create(), 0, 0, -angle * 2 * (180 / Math.PI)),
          vec3.fromValues(-metrics.size[0], 0, 0),
          vec3.fromValues(1, 1, 1)
        );
      }
      m = mat4.mul(mat4.create(), m, mx);
      points = points.concat(getDesksFromBlock(block, m));
    });
  });
  return points;
};

export const getDesksFromBlock = (block: Block, dm?: mat4): Coordinate[] => {
  const matrix = dm ? dm : mat4.create();
  if (block.children.length > 0) {
    return block.children.flatMap((child) => {
      const nm = mat4.multiply(
        mat4.create(),
        matrix,
        child.matrix || mat4.create()
      );
      return getDesksFromBlock(child, nm);
    });
  }
  if (
    block.geometry.box &&
    block.props.definition?.name &&
    block.props.definition.name.match(/desk/i)
  ) {
    const size = boxToSize(block.geometry.box);
    const t = vec3.transformMat4(
      vec3.create(),
      vec3.fromValues(size[0] / 2, size[1] / 2, 0),
      matrix
    );
    return [{ x: t[0], y: t[1] }];
  }
  return [];
};

// https://stackoverflow.com/questions/27936772/
export const isObject = (item: any) => {
  return item && typeof item === "object" && !Array.isArray(item);
};

export const mergeDeep = (target: any, ...sources: any[]): any => {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
};
