import { getters } from "./constants";
import { Block, BlockMetrics } from "./types";
const EPSILON = 0.0001;

export const calculateBlockSeats = (
  focusAsset: Block,
  rotate: number,
  repeat: { max: number[]; min: number[] },
  margin: number[],
  spacing: number,
  zw: number,
  zh: number
) => {
  zw += EPSILON;
  zh += EPSILON;
  const { offset = [0, 0, 0, 0] } = getters.getLayout(focusAsset);
  let { size } = getters.getMetrics(focusAsset);
  if (size[0] === 0 && size[1] === 0) size = [zw, zh];

  const xRepeatMin = repeat.min[0];
  const xRepeatMax = repeat.max[0];

  const yRepeatMin = repeat.min[1];
  const yRepeatMax = repeat.max[1];

  const su = size[0] + offset[1] + offset[3];
  const sv = size[1] + offset[2] + offset[0];

  const angle = (rotate || 0) * (Math.PI / 180);
  const sin = Math.abs(Math.sin(angle));
  const cos = Math.abs(Math.cos(angle));
  const uSize = su * cos + sv * sin;
  const vSize = sv * cos + su * sin;

  const min = xRepeatMin;
  const max = xRepeatMax;
  const hSize = zw - margin[1] - margin[3];
  let hs = Math.max(Math.min(Math.floor(hSize / uSize), max), min);
  if (hs * uSize + (hs - 1) * spacing > hSize && hs > min) hs -= 1;

  const ymin = yRepeatMin;
  const ymax = yRepeatMax;
  let vs = Math.max(
    Math.min(Math.floor((zh - margin[0] - margin[2]) / vSize), ymax),
    ymin
  );
  while (vs * vSize + spacing * (vs - 1) > zh) {
    vs--;
  }
  return { verticalSeats: vs, horizontalSeats: hs };
};

const calculateSubBlockInstanceMetrics = (
  instance: Block,
  metrics: BlockMetrics,
  mixedUse: boolean
): BlockMetrics => {
  if (instance.children.length > 0) {
    instance.children.forEach((child) => {
      calculateSubBlockInstanceMetrics(child, metrics, mixedUse);
    });
  } else {
    const { size, seats } = getters.getMetrics(instance);
    const { type, tags, name } = getters.getDefinition(instance);
    if (!metrics.types[type]) {
      metrics.types[type] = { seats: 0, area: 0, tags: {}, names: {} };
    }
    metrics.types[type].seats += seats;
    if (size) metrics.types[type].area += size[0] * size[1];
    if (!metrics.names[name]) metrics.names[name] = 1;
    else metrics.names[name]++;
    tags.forEach((tag) => {
      if (!metrics.types[type].tags[tag]) metrics.types[type].tags[tag] = 1;
      else metrics.types[type].tags[tag]++;
    });
  }
  return metrics;
};

export const calculateBlockInstanceMetrics = (
  instance: Block
): BlockMetrics => {
  const blockDefinition = getters.getDefinition(instance);
  const { size, seats } = getters.getMetrics(instance);
  const values = {
    area: size[0] * size[1],
    size,
    seats,
    types: {},
    names: {},
    tags: {},
    costUnit: 0,
    costGSF: 0,
    totalCost: [],
    costHC: [],
    doors: 0,
    doorsRange: [0, 0] as [number, number],
  };
  const mixedUse = blockDefinition.type === "mixed";
  instance.children.forEach((z) =>
    calculateSubBlockInstanceMetrics(z, values, mixedUse)
  );
  return values;
};
