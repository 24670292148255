import axios from "axios";
import { setupCache } from "axios-cache-adapter";
import config from "config";
import { getAuthHeader } from "lib/auth";

const cache = setupCache({
  maxAge: 30 * 60 * 1000,
});

export const getDataFile = async (
  buildingID: string,
  floorID: string,
  fileName: string
): Promise<any> => {
  try {
    const headers = await getAuthHeader();
    const api = axios.create({
      headers,
      adapter: cache.adapter,
    });

    const data = await api.get(
      `${config.baseUrl}/api/planning/buildings/${buildingID}/${floorID}/${fileName}`
    );

    return data;
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

export const putDataFile = async (
  buildingID: string,
  floorID: string,
  fileName: string,
  content: any
): Promise<void> => {
  try {
    const headers = await getAuthHeader();
    const api = axios.create({
      headers,
    });

    await api.put(
      `${config.baseUrl}/api/planning/buildings/${buildingID}/${floorID}/${fileName}`,
      content
    );

    return;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
