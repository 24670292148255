import React, { useEffect, useRef } from "react";
import { CanvasReconciler } from "@outerlabs/canvas-reconciler";
import {
  RendererMode,
  useAutoMagicCtrl,
  useBlocksCtrl,
  useFeaturesCtrl,
  useRendererCtrl,
} from "lib/containers";
import { Blocks, Buzz, Xform } from "components/isp-canvas";
import { RendererSettings } from "lib/types";
import { EdgeValue } from "../lib/metrics/buzz";
import { createHiDPICanvas } from "../blocks/lib/util";
import { MeetingRoomMetrics } from "../components/meeting-room-metrics";
import { useParams } from "react-router-dom";
import { Regions } from "../components/isp-canvas/regions";
import { Polylines } from "../components/isp-canvas/polylines";
import CirculationPath from "components/isp-canvas/circulationPath";
import WalkabilityOutput from "components/isp-canvas/walkabilityOutput";

interface Props {
  width: number;
  height: number;
  settings: RendererSettings | undefined;
  calculatedBuzz: EdgeValue[];
}

export const ISPCanvasTop: React.FC<Props> = ({
  width,
  height,
  settings,
  calculatedBuzz,
}) => {
  const topCanvasRef = useRef<HTMLCanvasElement | null>();
  const {
    xform,
    selectedRegion,
    rendererMode,
    meetingRoomMetricsType,
    meetingRoomMetricsSize,
    meetingRoomMetricsLevels,
    defaultCirculation,
    wpiActivitiesMode,
    meetingRoomMetricSelectedRoom,
    walkabilityOutput,
    walkabilityBuildingOutput,
    selectedWalkability,
    walkabilityMetric,
  } = useRendererCtrl();
  const { regions } = useAutoMagicCtrl();
  const { currentFeatures } = useFeaturesCtrl();
  const { floorID } = useParams<{ [key: string]: string }>();
  const { getBlockById } = useBlocksCtrl();
  const instances = settings && settings.blocks ? settings.blocks : [];
  const debugRegions = false;
  const debugFeatures = false;
  useEffect(() => {
    const el = topCanvasRef.current;
    if (el) createHiDPICanvas(el, width, height);
  }, [width, height]);

  // Use setTimeout so that HTML render completes immediately
  setTimeout(() => {
    if (topCanvasRef.current && xform) {
      const canvas = topCanvasRef.current;
      CanvasReconciler.render(
        <>
          <Xform xform={xform}>
            <Blocks
              wpiActivitiesMode={wpiActivitiesMode}
              selected={
                instances && selectedRegion !== undefined
                  ? instances.filter((inst, i) => selectedRegion.includes(i))
                  : undefined
              }
              instances={instances}
              getBlockById={getBlockById}
              columns={currentFeatures?.columns}
            />
            {debugRegions && <Regions floor={floorID} regions={regions} />}

            {debugFeatures && currentFeatures && currentFeatures.walls && (
              <Polylines
                geometry={currentFeatures.walls}
                color="#000000"
                thickness={1}
              />
            )}
            {debugFeatures &&
              currentFeatures &&
              currentFeatures.wallsExterior && (
                <Polylines
                  geometry={currentFeatures.wallsExterior}
                  color="#000000"
                  thickness={1}
                />
              )}
            {debugFeatures &&
              currentFeatures &&
              currentFeatures.wallsPartial && (
                <Polylines
                  geometry={currentFeatures.wallsPartial}
                  color="#000000"
                  thickness={1}
                />
              )}
            {debugFeatures && currentFeatures && currentFeatures.glazing && (
              <Polylines
                geometry={currentFeatures.glazing}
                color="#0000000"
                thickness={2}
              />
            )}
            {debugFeatures &&
              currentFeatures &&
              currentFeatures.glazingExterior && (
                <Polylines
                  geometry={currentFeatures.glazingExterior}
                  color="#000000"
                  thickness={2}
                />
              )}
            {/* <Columns columns={currentFeatures?.columns} /> */}
            {/* Allow circulation to render in draw, delete, edit and analytics mode on the renderer */}
            {(rendererMode === RendererMode.BuzzMetric ||
              rendererMode === RendererMode.DrawCirculation ||
              rendererMode === RendererMode.DeleteCirculation ||
              rendererMode === RendererMode.EditCirculation) && (
              <>
                <Buzz
                  settings={settings}
                  defaultCirculation={defaultCirculation}
                  calculatedBuzz={calculatedBuzz}
                />
                <CirculationPath
                  settings={settings}
                  defaultCirculation={defaultCirculation}
                  calculatedBuzz={calculatedBuzz}
                />
              </>
            )}
            {(rendererMode === RendererMode.DrawWalkabilityCirculation ||
              rendererMode === RendererMode.DeleteWalkabilityCirculation ||
              rendererMode === RendererMode.EditWalkabilityCirculation ||
              rendererMode === RendererMode.WalkabilityMetric) && (
              <>
                <CirculationPath
                  settings={settings}
                  defaultCirculation={defaultCirculation}
                  calculatedBuzz={calculatedBuzz}
                />
              </>
            )}
            {rendererMode === RendererMode.WalkabilityOutput && (
              <>
                <WalkabilityOutput
                  settings={settings}
                  defaultCirculation={defaultCirculation}
                  walkabilityOutput={
                    walkabilityBuildingOutput &&
                    walkabilityBuildingOutput.length !== 0
                      ? walkabilityBuildingOutput
                      : walkabilityOutput
                  }
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  selected={selectedWalkability!}
                  metric={walkabilityMetric}
                />
              </>
            )}
            {rendererMode === RendererMode.MeetingRoomMetric &&
              currentFeatures && (
                <MeetingRoomMetrics
                  settings={settings}
                  features={currentFeatures}
                  type={meetingRoomMetricsType}
                  size={meetingRoomMetricsSize}
                  levels={meetingRoomMetricsLevels}
                  level={floorID}
                  meetingRoomMetricSelectedRoom={meetingRoomMetricSelectedRoom}
                />
              )}
          </Xform>
        </>,
        canvas
      );
    }
  }, 0);

  return (
    <>
      <canvas
        width={width}
        height={height}
        style={{ position: "absolute", top: 0 }}
        ref={(el) => (topCanvasRef.current = el)}
      />
    </>
  );
};

export default React.memo(ISPCanvasTop);
