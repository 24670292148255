import axios from "axios";
import { setupCache } from "axios-cache-adapter";
import config from "config";
import { getAuthHeader } from "lib/auth";
import { NodeType } from "lib/metrics/buzz";

const cache = setupCache({
  maxAge: 30 * 60 * 1000,
});

export interface BuzzSettings {
  trips: BuzzTrip[];
}

export interface BuzzTrip {
  type: NodeType;
  weight: number;
}

export const getBuzzSettings = async (): Promise<BuzzSettings | undefined> => {
  try {
    const headers = await getAuthHeader();
    const api = axios.create({
      headers,
      adapter: cache.adapter,
    });

    const data = await api.get(`${config.baseUrl}/api/planning/settings/buzz`);
    return data.data as BuzzSettings;
  } catch (e) {
    console.error(e);
    return undefined;
  }
};
