import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "./AccordionComponents";
import { getUserDetails } from "lib/auth";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Button, TextField } from "@material-ui/core";

export interface ToolsProps {
  offsetX: number;
  offsetY: number;
  onChange(name: string, value: any): void;
  onSaveToolData(): void;
}

const ToolsPanel: React.FC<ToolsProps> = ({
  offsetX,
  offsetY,
  onSaveToolData,
  onChange,
}) => {
  const claims = getUserDetails();
  if (!claims || !claims.groups.includes("tools")) return <div />;

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
      >
        Tools
      </AccordionSummary>
      <AccordionDetails>
        <TextField
          style={{ marginBottom: "0.75em" }}
          id="outlined-number"
          label="Offset X"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={offsetX}
          onChange={(e) => onChange("offsetX", e.target.value)}
        />
        <TextField
          style={{ marginBottom: "0.75em" }}
          id="outlined-number"
          label="Offset Y"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={offsetY}
          onChange={(e) => onChange("offsetY", e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => onSaveToolData()}
        >
          Save Settings
        </Button>
      </AccordionDetails>
    </Accordion>
  );
};

export default React.memo(ToolsPanel);
