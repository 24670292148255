import React from "react";
import { Route } from "react-router-dom";

import Strategies from "./strategies";
import Renderer from "./renderer";
import Importer from "./import";
import withProject from "lib/hoc/withProject";
import withBlocks from "lib/hoc/withBlocks";
import withBlockLibraries from "lib/hoc/withBlockLibraries";
import withNavBarState from "lib/hoc/withNavBarState";

const App: React.FC<{ match: { path: string } }> = ({ match }) => {
  return (
    <>
      <Route
        exact
        path={`${match.path}/strategies/import`}
        component={Importer}
      />
      <Route exact path={`${match.path}/strategies`} component={Strategies} />
      <Route
        component={Renderer}
        exact
        path={`${match.path}/building/:buildingID/:strategyID/:floorID`}
      />
    </>
  );
};

export default withNavBarState(
  withBlockLibraries(withBlocks(withProject(App)))
);
