import axios from "axios";
import config from "config";
import { getAuthHeader } from "lib/auth";

const fireAndForget = async (url: string): Promise<void> => {
  const headers = await getAuthHeader();
  const api = axios.create({
    headers,
  });

  await api.get(url);
};

const postAndForget = async (url: string, data: any): Promise<void> => {
  const headers = await getAuthHeader();
  const api = axios.create({
    headers,
  });

  await api.post(url, data);
};

const buzzViewed = () => {
  fireAndForget(`${config.baseUrl}/api/metrics/buzz-viewed`).catch((e) =>
    console.error(e)
  );
};

const plannedAutomagically = () => {
  fireAndForget(`${config.baseUrl}/api/metrics/planned-automagically`).catch(
    (e) => console.error(e)
  );
};

const strategyAdded = () => {
  fireAndForget(`${config.baseUrl}/api/metrics/strategy-added`).catch((e) =>
    console.error(e)
  );
};

const strategyDuplicated = () => {
  fireAndForget(`${config.baseUrl}/api/metrics/strategy-duplicated`).catch(
    (e) => console.error(e)
  );
};

const projectSlidersAdjusted = () => {
  fireAndForget(`${config.baseUrl}/api/metrics/project-sliders-adjusted`).catch(
    (e) => console.error(e)
  );
};

const buildingModeToggled = () => {
  fireAndForget(`${config.baseUrl}/api/metrics/building-mode-toggled`).catch(
    (e) => console.error(e)
  );
};

const blockAdded = () => {
  fireAndForget(`${config.baseUrl}/api/metrics/block-added`).catch((e) =>
    console.error(e)
  );
};

const blockMakerOpened = () => {
  fireAndForget(`${config.baseUrl}/api/metrics/block-maker-opened`).catch((e) =>
    console.error(e)
  );
};

const buildingsAdded = (count: number) => {
  postAndForget(`${config.baseUrl}/api/metrics/buildings-added`, {
    count,
  }).catch((e) => console.error(e));
};

const analytics = {
  buzzViewed,
  plannedAutomagically,
  strategyAdded,
  strategyDuplicated,
  projectSlidersAdjusted,
  buildingModeToggled,
  blockAdded,
  blockMakerOpened,
  buildingsAdded,
};

export default analytics;
