import React, { useState } from "react";
import { Coordinate, RoomBounds } from "lib/types";
import { BarChart, Bar, ResponsiveContainer, Cell } from "recharts";
import { Colors, Font } from "@outerlabs/ol-ui";
import { makeStyles } from "@material-ui/core/styles";
import { useRendererCtrl } from "lib/containers";

interface Props {
  dataArr: {
    seatCoords: Coordinate;
    distance: number;
    feature: RoomBounds | undefined;
  }[];
  isHC: boolean;
}
const useWalkabilityStyles = makeStyles({
  chartLabelContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  chartLabel: {
    font: "Google Sans",
    fontSize: "10px",
    fontWeight: 400,
    lineHeight: "16px",
    letterSpacing: "0.4000000059604645px",
    color: "#5F6166",
  },
});

const tooltipHeight = 24;
const tooltipSpacing = 10;

const WalkabilityBarChart: React.FC<Props> = ({ dataArr, isHC }) => {
  const classes = useWalkabilityStyles();
  const { selectedWalkability, setSelectedWalkability } = useRendererCtrl();
  const [hoveredBar, setHoveredBar] = useState(-1);

  const deskGrouping: {
    best: Props[];
    great: Props[];
    good: Props[];
    bad: Props[];
    worst: Props[];
  } = {
    best: [],
    great: [],
    good: [],
    bad: [],
    worst: [],
  };

  Object.values(dataArr).forEach((dataPoint: any) => {
    if (dataPoint.distance <= 25) {
      deskGrouping.best.push(dataPoint);
    } else if (25 < dataPoint.distance && dataPoint.distance <= 100) {
      deskGrouping.great.push(dataPoint);
    } else if (100 < dataPoint.distance && dataPoint.distance <= 174) {
      deskGrouping.good.push(dataPoint);
    } else if (175 < dataPoint.distance && dataPoint.distance <= 249) {
      deskGrouping.bad.push(dataPoint);
    } else if (250 < dataPoint.distance && dataPoint.distance) {
      deskGrouping.worst.push(dataPoint);
    }
  });

  const data = [
    {
      count: deskGrouping.best.length,
      percent: Math.round((deskGrouping.best.length / dataArr.length) * 100),
      fill: "#ECDCFF",
      ttl: "≤ 25ft",
    },
    {
      count: deskGrouping.great.length,
      percent: Math.round((deskGrouping.great.length / dataArr.length) * 100),
      fill: "#C597FF",
      ttl: "26 - 100ft",
    },
    {
      count: deskGrouping.good.length,
      percent: Math.round((deskGrouping.good.length / dataArr.length) * 100),
      fill: "#9E52FF",
      ttl: "101 - 174ft",
    },
    {
      count: deskGrouping.bad.length,
      percent: Math.round((deskGrouping.bad.length / dataArr.length) * 100),
      fill: "#770DFF",
      ttl: "175 - 249ft",
    },
    {
      count: deskGrouping.worst.length,
      percent: Math.round((deskGrouping.worst.length / dataArr.length) * 100),
      fill: "#4F09AA",
      ttl: "≥ 250ft",
    },
  ];

  const TooltipBar = (props: any) => {
    const { x, y, fill, height, width, payload, ...rest } = props;
    const idx = data.map((mrd: any) => mrd.ttl).indexOf(payload.ttl);
    const displayText = data[idx].ttl;

    return (
      <>
        {/* triggers hover */}
        <text
          x={x + width / 2}
          y={y - 4}
          textAnchor="middle"
          color="#919499"
          fontSize={12}
          fontWeight={400}
          fontFamily="GoogleSansRegular"
        >
          {data[idx].count > 1
            ? isHC
              ? data[idx].count
              : data[idx].percent + "%"
            : null}
        </text>

        {idx === hoveredBar && (
          <>
            <rect
              x={x}
              y={y - tooltipSpacing - tooltipHeight - 5}
              height={tooltipHeight}
              width={width}
              fill={Colors.Dark3}
              rx={4}
              ry={4}
              {...rest}
            />
            <text
              fontSize={10.5}
              x={x + width / 2}
              y={y - tooltipSpacing - tooltipHeight / 2 + 4 - 5}
              textAnchor="middle"
              height={tooltipHeight}
              width={width}
              fill="#ffffff"
              fontFamily={Font.Regular.fontFamily}
            >
              {displayText}
            </text>
          </>
        )}
        <rect {...props} stroke="none" />
      </>
    );
  };

  return (
    <>
      <ResponsiveContainer width="100%" height={100}>
        <BarChart
          width={250}
          height={50}
          data={data}
          margin={{ top: tooltipHeight + tooltipSpacing + 10, bottom: 0 }}
          onMouseLeave={() => setHoveredBar(-1)}
        >
          <Bar
            dataKey={"count"}
            isAnimationActive={false}
            shape={<TooltipBar />}
            onMouseEnter={(e: any, i: number) => {
              setSelectedWalkability(
                selectedWalkability === e.ttl ? "" : e.ttl
              );
              setHoveredBar(i);
            }}
            onMouseLeave={() => {
              setSelectedWalkability("");
              setHoveredBar(-1);
            }}
          >
            {data.map((entry, index) => {
              return <Cell fill={data[index].fill} key={`cell-${index}`} />;
            })}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      <div className={classes.chartLabelContainer}>
        <p className={classes.chartLabel}>Good Walkability</p>
        <p className={classes.chartLabel}>Poor Walkability</p>
      </div>
    </>
  );
};

export default WalkabilityBarChart;
