import { PortfolioBlockInstance } from "lib/types";
import { ComponentType, useState } from "react";
import { ContainerProviderProps, createContainer } from "unstated-next";

export interface UseISPTransformState {
  transformBlockInstance?: PortfolioBlockInstance[][];
  setTransformBlockInstance(instance: PortfolioBlockInstance[][]): void;
}

export const useISPTransformState = (): UseISPTransformState => {
  const [transformBlockInstance, setTransformBlockInstance] = useState<
    PortfolioBlockInstance[][]
  >([]);

  return {
    transformBlockInstance,
    setTransformBlockInstance,
  };
};

export const ISPTransformController = createContainer(useISPTransformState);
export const ISPTransformProvider: ComponentType<ContainerProviderProps> =
  ISPTransformController.Provider;
export const useISPTransformCtrl = () => ISPTransformController.useContainer();
