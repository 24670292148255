// convert HSV to RGB
// H = [0,360], S = [0,100], V = [0,100]
// R = [0,255], G = [0,255], B = [0,255]
export const HSVToRGB = ([h, s, v]: number[]) => {
  const f = (n: number, k = (n + h / 60) % 6) =>
    v - v * s * Math.max(Math.min(k, 4 - k, 1), 0);
  return [f(5), f(3), f(1)];
};

// convert RGB to HSV
// R = [0,255], G = [0,255], B = [0,255]
// H = [0,360], S = [0,100], V = [0,100]
export function RGBToHSV([r, g, b]: number[]) {
  const v = Math.max(r, g, b),
    c = v - Math.min(r, g, b);
  const h =
    c && (v === r ? (g - b) / c : v === g ? 2 + (b - r) / c : 4 + (r - g) / c);
  return [60 * (h < 0 ? h + 6 : h), v && c / v, v];
}

const componentToHex = (c: number) => {
  const hex = Math.round(c).toString(16);
  return hex.length === 1 ? "0" + hex : hex;
};

// convert RGB to Hex
// R = [0,255], G = [0,255], B = [0,255]
export const RGBToHex = (color: number[]) => {
  return (
    "#" +
    componentToHex(color[0]) +
    componentToHex(color[1]) +
    componentToHex(color[2])
  );
};

// convert Hex to RGB
// R = [0,255], G = [0,255], B = [0,255]
export const hexToRGB = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
      ]
    : null;
};

// convert RGB to HSL
// R = [0,255], G = [0,255], B = [0,255]
// H = [0,360], S = [0,100], L = [0,100]
const RGBToHSL = ([r, g, b]: number[]) => {
  r /= 255;
  g /= 255;
  b /= 255;
  const l = Math.max(r, g, b);
  const s = l - Math.min(r, g, b);
  const h = s
    ? l === r
      ? (g - b) / s
      : l === g
      ? 2 + (b - r) / s
      : 4 + (r - g) / s
    : 0;
  return [
    60 * h < 0 ? 60 * h + 360 : 60 * h,
    100 * (s ? (l <= 0.5 ? s / (2 * l - s) : s / (2 - (2 * l - s))) : 0),
    (100 * (2 * l - s)) / 2,
  ];
};

// convert HSL to RGB
// H = [0,360], S = [0,100], L = [0,100]
// R = [0,255], G = [0,255], B = [0,255]
export const HSLToRGB = ([h, s, l]: number[]) => {
  let r, g, b;
  h /= 360;
  s /= 100;
  l /= 100;
  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const hue2rgb = (p: number, q: number, t: number) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }
  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
};

// convert HSV to Hex, via RGB
// H = [0,360], S = [0,100], V = [0,100]
export const HSVToHex = (v: number[]) => RGBToHex(HSVToRGB(v));

// convert Hex to HSV, via RGB
// H = [0,360], S = [0,100], V = [0,100]
export const hexToHSV = (hex: string) => RGBToHSV(hexToRGB(hex) || [0, 0, 0]);

// convert HSL to Hex, via RGB
// H = [0,360], S = [0,100], L = [0,100]
export const HSLToHex = (v: number[]) => RGBToHex(HSLToRGB(v));

// convert Hex to HSL, via RGB
// H = [0,360], S = [0,100], L = [0,100]
export const hexToHSL = (hex: string) => RGBToHSL(hexToRGB(hex) || [0, 0, 0]);

// lighten a hex color by adding to the lightness channel (HSL)
export const lightenHex = (hex: string, num: number) => {
  const hsl = hexToHSL(hex);
  hsl[2] += num;
  return HSLToHex(hsl);
};
