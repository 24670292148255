import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../components/sideBar/AccordionComponents";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Team,
  MMUserProfile,
  MMUserProfileMetadata,
  MMUserProfiles,
} from "../lib/containers";
import { Grid, InputAdornment } from "@material-ui/core";
import TextField from "../blocks/components/textfield";
import NumberField from "../blocks/components/number-field";
import { Control } from "./matchmaker-slider";

const accordionStyles = makeStyles({
  root: {
    "&.Mui-focused": {
      backgroundColor: "inherit",
    },
  },
});

interface Props {
  newPossibleError?: React.ReactNode;
  team: Team;
  updateRow?: (i: number, data: Partial<Team>) => void;
  mmUserProfileMap: Record<MMUserProfile, MMUserProfileMetadata>;
  i?: number;
  editable: boolean;
  defaultOpen: boolean;
  onPercentChange?: (up: MMUserProfile) => (v: number) => void;
}

const SidebarMatchMakerCard: React.FC<Props> = ({
  newPossibleError,
  team,
  updateRow,
  mmUserProfileMap,
  i,
  editable,
  defaultOpen,
  onPercentChange,
}) => {
  const accordionClasses = accordionStyles();
  const [expand, setExpand] = useState<boolean>(defaultOpen);

  const toggleAccordion = () => {
    setExpand(!expand);
  };

  return (
    <Accordion
      expanded={expand}
      style={{
        background: "#FFFFFF",
        boxShadow: "0px 2px 8px 2px rgba(0,0,0,0.1)",
        marginBottom: 16,
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        IconButtonProps={{ onClick: toggleAccordion }}
        className={accordionClasses.root}
      >
        {/* Code to render MM Block Headerbelow */}
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={6} style={{ lineHeight: "28px" }}>
            <TextField
              value={team.teamName}
              label={"Name"}
              disabled={!editable}
              onChange={(v) =>
                updateRow &&
                i !== undefined &&
                updateRow(i, { teamName: v.target.value })
              }
            />
          </Grid>
          <Grid
            item={true}
            xs={4}
            style={{ marginLeft: 12, lineHeight: "28px" }}
          >
            <NumberField
              value={team.headcount}
              endAdornment={
                <InputAdornment position={"end"}>ppl</InputAdornment>
              }
              onChange={(v) =>
                updateRow && i !== undefined && updateRow(i, { headcount: v })
              }
              positive={true}
              min={1}
              max={40}
              disabled={!editable}
              onWheel={(e) => {
                if (e.target instanceof HTMLElement) e.target.blur();
              }}
              label={"Size"}
              hideScroll={false}
            />
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container={true}>
          {/* Code to render input headers */}
          <Grid container={true} spacing={2}>
            <Grid
              item={true}
              xs={6}
              style={{
                fontFamily: "GoogleSansRegular",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "14px",
                color: "#5F6166",
              }}
            >
              User Profile Ratio
            </Grid>

            <Grid
              item={true}
              xs={3}
              style={{
                fontFamily: "GoogleSansRegular",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "14px",
                color: "#5F6166",
              }}
            >
              Headcount
            </Grid>

            <Grid
              item={true}
              xs={3}
              style={{
                fontFamily: "GoogleSansRegular",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "14px",
                color: "#5F6166",
              }}
            >
              Percentage
            </Grid>
          </Grid>

          {/* Code to render text fields below*/}
          <Grid
            container={true}
            alignItems={"center"}
            spacing={2}
            style={{ marginTop: 4 }}
          >
            {MMUserProfiles.map((up) => (
              <>
                <Grid
                  item={true}
                  xs={6}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "#1B1D21",
                    fontFamily: "GoogleSansRegular",
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "20px",
                    letterSpacing: "0.25px",
                  }}
                >
                  <div
                    style={{
                      height: 29.27,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {mmUserProfileMap[up].name}
                  </div>
                </Grid>
                <Grid item={true} xs={3}>
                  <TextField
                    value={Math.ceil(
                      (team.userProfilePercentages[up] * team.headcount) / 100
                    )}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">ppl</InputAdornment>
                      ),
                      style: {
                        padding: 0,
                        borderRadius: 4,
                        background: "#F4F4F6",
                      },
                    }}
                    inputProps={{
                      style: {
                        paddingLeft: 8,
                      },
                    }}
                    disabled={true}
                    style={{ height: 29.28, padding: 0 }}
                  />
                </Grid>
                <Grid item={true} xs={3}>
                  <NumberField
                    endAdornment={
                      <InputAdornment position="end">%</InputAdornment>
                    }
                    value={team.userProfilePercentages[up]}
                    onChange={onPercentChange ? onPercentChange(up) : () => {}}
                    positive={true}
                    min={0}
                    max={100}
                    disabled={!editable}
                  />
                </Grid>
              </>
            ))}
          </Grid>

          {newPossibleError}

          <Control
            onUpdate={(v) => {
              if (updateRow && !Array.isArray(v) && i !== undefined) {
                updateRow(i, { ratio: v });
              }
            }}
            value={team.ratio}
          />
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default SidebarMatchMakerCard;
