import React, { useCallback, useEffect, useRef, useState } from "react";
import { CanvasReconciler } from "@outerlabs/canvas-reconciler";
import {
  Delete,
  DeleteCirculation,
  DrawCirculation,
  DrawRegion,
  EditCirculation,
  Pan,
  Transform,
  Xform,
} from "components/isp-canvas";
import {
  BlockType,
  FeaturesProvider,
  ProjectProvider,
  RendererMode,
  SettingsProvider,
  useBlockInstanceCtrl,
  useBlocksCtrl,
  useFeaturesCtrl,
  useISPTransformCtrl,
  useMatchMakerCtrl,
  useNavBarState,
  useProjectCtrl,
  useRendererCtrl,
  useSettingsCtrl,
} from "lib/containers";
import { useParams } from "react-router-dom";
import { RendererSettings, SelectedMeetingRoom } from "lib/types";
import Select from "components/isp-canvas/select";
import Menu from "./menu";
import { Graph } from "lib/metrics/buzz";
import { putFloorCirculation } from "lib/api/floorCirculation";
import { getUserDetails } from "lib/auth";
import { Button } from "@material-ui/core";
import analytics from "../lib/analytics";
import { encodeMetricId } from "lib/metrics/id";
import { buildMutation } from "components/controls/individualPicker";
import {
  createHiDPICanvas,
  getTransformedBox,
  pointInBlockRecursive,
  pointInPolygon,
} from "../blocks/lib/util";
import { Block } from "../blocks/lib/types";
import { mat4, vec3 } from "gl-matrix";
import { applyBlocks } from "../blocks/lib/blocks";
import { getters } from "../blocks/lib/constants";
import { calcDesks } from "lib/metrics/remove-desks";

interface Props {
  width: number;
  height: number;
  settings: RendererSettings | undefined;
}

export const ISPCanvasInteractions: React.FC<Props> = ({
  width,
  height,
  settings,
}) => {
  const [blockCoveredMeetingRooms, setBlockCoveredMeetingRooms] = useState<
    string[]
  >([]);
  const interCanvasRef = useRef<HTMLCanvasElement | null>();
  const claims = getUserDetails();
  const { getBlockById, getBlocksBySizeAndType } = useBlocksCtrl();
  const { transformBlockInstance, setTransformBlockInstance } =
    useISPTransformCtrl();
  const {
    xform,
    updateXform,
    selectRegion,
    selectRooms,
    setMeetingRoomMetricsSelectedRoom,
    selectedRooms,
    selectedRegion,
    rendererMode,
    setRendererMode,
    blockType,
    fitScreenScale,
    wpiActivitiesMode,
  } = useRendererCtrl();
  const {
    buildingID,
    strategyID,
    floorID,
    id: projectID,
  } = useParams<{ [key: string]: string }>();
  const { updateInstance, removeInstance, addInstance, moveInstances } =
    useBlockInstanceCtrl();
  const { blocks, isValidDimension, filteredLibraries, filterBlocksByLibrary } =
    useBlocksCtrl();
  const instances = settings && settings.blocks ? settings.blocks : [];

  const { updateNavBarState } = useNavBarState();
  const {
    saveFloor,
    historyGoBack,
    historyGoForward,
    getHistoryBackEnabled,
    getHistoryForwardEnabled,
    currentSettings,
  } = useSettingsCtrl();
  const { currentFeatures } = useFeaturesCtrl();
  const { project } = useProjectCtrl();
  const { setMatchMakerActive, setMatchMakerStep, matchMakerActive } =
    useMatchMakerCtrl();

  const { circulation } = currentSettings;

  useEffect(() => {
    const el = interCanvasRef.current;
    if (el) createHiDPICanvas(el, width, height);
  }, [width, height]);

  const handleUpdateInstance = useCallback(
    async ({ instance, resize }: { instance: Block[][]; resize: boolean }) => {
      instance.forEach((group) => {
        const block = group[0];
        // reset updated instance status and metrics
        applyBlocks(block, (b) => {
          if (b.props.definition?.status === "disabled") {
            b.props.definition.status = "";
          }
          const blockMetrics = b.props.metrics;
          if (blockMetrics) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            blockMetrics.headcount = blockMetrics.headcountRange![0];
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            blockMetrics.workpoints = blockMetrics.workpointsRange![0];
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            blockMetrics.seats = blockMetrics.seatsRange![0];
            const types = blockMetrics.types;
            if (types) {
              Object.keys(types).forEach((key) => {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const t = types![key];
                if (t) {
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  t.seats = t.seatsRange![0];
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  t.headcount = t.headcountRange![0];
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  t.workpoints = t.workpointsRange![0];
                }
              });
            }
          }
        });
        if (block.matrix) {
          // for each column, check if the block intersects
          const invertedMatrix = mat4.invert(mat4.create(), block.matrix);
          if (currentFeatures?.columns) {
            const intersectedAsset = new Set();
            currentFeatures.columns.forEach((col) => {
              const m = getters.getMetrics(block);
              const p = vec3.transformMat4(
                vec3.create(),
                vec3.fromValues(col.x, col.y, 0),
                invertedMatrix
              );
              if (block.props.layout?.mirrorX) p[0] = m.size[0] - p[0];
              if (block.props.layout?.mirrorY) p[1] = m.size[1] - p[1];
              const leaf = pointInBlockRecursive([p[0], p[1]], block, block);
              // if there is an intersection with a leaf block (asset), disable
              // it and update the metrics
              if (
                leaf &&
                leaf.children.length === 0 &&
                !intersectedAsset.has(leaf.id)
              ) {
                intersectedAsset.add(leaf.id);
                leaf.props.definition = {
                  ...leaf.props.definition,
                  status: "disabled",
                };
                const leafMetrics = getters.getMetrics(leaf);
                const blockMetrics = getters.getMetrics(block);
                blockMetrics.workpoints -= leafMetrics.workpoints;
                blockMetrics.headcount -= leafMetrics.headcount;
                blockMetrics.seats -= leafMetrics.seats;
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const type = blockMetrics.types[leaf.props.definition.type!];
                if (type) {
                  type.workpoints -= leafMetrics.workpoints;
                  type.headcount -= leafMetrics.headcount;
                  type.seats -= leafMetrics.seats;
                }
                block.props.metrics = blockMetrics;
              }
            });
          }
        }
      });
      if (selectedRegion === undefined) return;
      const newSettings = await Promise.all(
        instance.map((inst, i) =>
          updateInstance({
            buildingID,
            floorID,
            strategyID,
            regionIdx: selectedRegion[i],
            instance: inst,
            resize: resize,
          })
        )
      );
      const disable: SelectedMeetingRoom[] = [];
      const workstation: SelectedMeetingRoom[] = [];
      const collaboration: SelectedMeetingRoom[] = [];
      const enable: SelectedMeetingRoom[] = [];

      // This if block handles meeting rooms turning on and off when you
      // drag a block over them (or off of them).
      //
      // 10/31/22 - NM: this is like O^4 or something. Iterate through all
      // conferencerooms, individual rooms, coordinates in said conference
      // room, then every block on the floor, turn them all into 4 points and
      // iterate through all four points, then update state and the backend.
      // Extremely non-performant. If possible we should remove this feature.

      // 4/14/23 - LM: this function is still real non-performant, and needs major
      // refactoring. The central issues are as follows: comparing every meeting
      // room to every block is very computationally intensive, and accounting
      //for already converted rooms leads to an additional level of complexity.
      // To resolve this, ideally we would refactor how meeting rooms are stored
      // once they are converted, and the implementation of some form of
      // caching/memoization to prevent unneeded calculations on unmodified
      // meeting rooms and blocks.
      if (currentFeatures && project) {
        const metricID = encodeMetricId(buildingID, strategyID, floorID);
        const existingConvertedRooms = project.metrics[metricID]
          .userConvertedMeetingRooms || {
          convertToCollaboration: [],
          convertToWorkstations: [],
          disable: [],
        };
        const prevCoveredRooms: string[] = [];

        Object.keys(currentFeatures.conferenceRooms).forEach(
          (conferenceRoomType) => {
            Object.keys(
              currentFeatures.conferenceRooms[conferenceRoomType]
            ).forEach((conferenceRoomId) => {
              const isCovered = [];
              currentFeatures.conferenceRooms[conferenceRoomType][
                conferenceRoomId
              ].forEach((rm) => {
                let isCornerInAnyInstance = false;
                currentSettings.blocks.forEach((blockArray) =>
                  blockArray.forEach((blok) => {
                    if (isCornerInAnyInstance) return;
                    const transformed = getTransformedBox(blok);
                    if (pointInPolygon([rm.x, rm.y], transformed)) {
                      isCornerInAnyInstance = true;
                    }
                  })
                );
                if (isCornerInAnyInstance) {
                  isCovered.push(isCornerInAnyInstance);
                  if (
                    !prevCoveredRooms.find((room) => room === conferenceRoomId)
                  )
                    prevCoveredRooms.push(conferenceRoomId);
                  setBlockCoveredMeetingRooms(prevCoveredRooms);
                }
              });
              if (isCovered.length > 0) {
                disable.push({ conferenceRoomType, conferenceRoomId });
              } else {
                if (
                  existingConvertedRooms.convertToCollaboration &&
                  existingConvertedRooms.convertToCollaboration.find(
                    (obj) => obj.conferenceRoomId === conferenceRoomId
                  )
                ) {
                  collaboration.push({ conferenceRoomType, conferenceRoomId });
                } else if (
                  existingConvertedRooms.convertToWorkstations &&
                  existingConvertedRooms.convertToWorkstations.find(
                    (obj) => obj.conferenceRoomId === conferenceRoomId
                  )
                ) {
                  workstation.push({ conferenceRoomType, conferenceRoomId });
                } else if (
                  existingConvertedRooms.disable &&
                  existingConvertedRooms.disable.find(
                    (obj) => obj.conferenceRoomId === conferenceRoomId
                  ) &&
                  !blockCoveredMeetingRooms.includes(conferenceRoomId)
                ) {
                  disable.push({ conferenceRoomType, conferenceRoomId });
                } else {
                  if (blockCoveredMeetingRooms.includes(conferenceRoomId)) {
                    const filtered = blockCoveredMeetingRooms.filter(
                      (room) => room !== conferenceRoomId
                    );
                    setBlockCoveredMeetingRooms(filtered);
                  }
                  enable.push({ conferenceRoomType, conferenceRoomId });
                }
              }
            });
          }
        );
      }
      let mutation = {};
      const id = encodeMetricId(buildingID, strategyID, floorID);
      const metric = project?.metrics[id];
      if (
        (disable.length > 0 || enable.length > 0) &&
        metric &&
        metric.userConvertedMeetingRooms !== undefined
      ) {
        // First, find and disable meeting rooms newly covered by blocks.
        const meetingRoomsWithCoveredRoomsDisabled = buildMutation(
          disable,
          metric.userConvertedMeetingRooms,
          "disable"
        );
        // Second, persist any previously converted blocks.
        const meetingRoomsConvertedToWorkstation = buildMutation(
          workstation,
          meetingRoomsWithCoveredRoomsDisabled,
          "convertToWorkstations"
        );
        const meetingRoomsConvertedToCollab = buildMutation(
          collaboration,
          meetingRoomsConvertedToWorkstation,
          "convertToCollaboration"
        );
        // Lastly, find and enable meeting rooms not covered by blocks or
        // previously converted.
        const meetingRoomsWithUncoveredRoomsEnabled = buildMutation(
          enable,
          meetingRoomsConvertedToCollab,
          "noChange"
        );
        mutation = {
          userConvertedMeetingRooms: meetingRoomsWithUncoveredRoomsEnabled,
        };
      }

      // TODO replace with updateAllFloorMetrics, or deprecate that
      await Promise.all(
        newSettings.map((set) =>
          saveFloor({
            mutation,
            buildingID,
            strategyID,
            floorID,
            settings: set,
          })
        )
      );
    },
    [
      selectedRegion,
      currentFeatures,
      project,
      buildingID,
      strategyID,
      floorID,
      updateInstance,
      currentSettings.blocks,
      blockCoveredMeetingRooms,
      saveFloor,
    ]
  );

  // runs when you draw a region to populate a block
  const handleAddRegion = useCallback(
    async (region: number[][]) => {
      const newSettings = await addInstance({
        buildingID,
        floorID,
        strategyID,
        region,
        blockType,
      });
      analytics.blockAdded();
      await saveFloor({
        buildingID,
        strategyID,
        floorID,
        settings: newSettings,
      });
    },
    [buildingID, addInstance, floorID, strategyID, blockType, saveFloor]
  );

  const handleDeleteRegion = useCallback(
    async (r: number[]) => {
      const newSettings = await removeInstance(
        buildingID,
        floorID,
        strategyID,
        r
      );
      await saveFloor({
        buildingID,
        strategyID,
        floorID,
        settings: newSettings,
      });
      selectRegion(undefined);
      setTransformBlockInstance([]);
    },
    [
      buildingID,
      floorID,
      removeInstance,
      strategyID,
      saveFloor,
      selectRegion,
      setTransformBlockInstance,
    ]
  );

  const handleIsValidDimension = useCallback(
    (w: number, h: number): boolean => {
      return isValidDimension(w, h, blockType);
    },
    [isValidDimension, blockType]
  );

  const handleUpdateCirculation = useCallback(
    async (g: Graph) => {
      let desks;
      if (project && currentFeatures) {
        const id = encodeMetricId(buildingID, strategyID, floorID);
        const metric = project.metrics[id];
        desks = calcDesks(metric, currentFeatures);
      }
      if (settings) {
        await saveFloor({
          settings: { ...settings, circulation: g },
          buildingID,
          floorID,
          strategyID,
          projectID,
        });
      } else {
        await saveFloor({
          settings: { circulation, blocks: [], desks: desks },
          buildingID,
          floorID,
          strategyID,
          projectID,
        });
      }
    },
    [
      project,
      currentFeatures,
      settings,
      buildingID,
      strategyID,
      floorID,
      saveFloor,
      projectID,
      circulation,
    ]
  );

  const handleSaveDefaultCirculation = useCallback(async () => {
    if (circulation) {
      await putFloorCirculation(buildingID, floorID, circulation);
    }
  }, [circulation, buildingID, floorID]);

  const handleClearDefaultCirculation = useCallback(async () => {
    await putFloorCirculation(buildingID, floorID, {});
  }, [buildingID, floorID]);

  const fixBrokenCirculation = async (graph: Graph | null | undefined) => {
    if (graph && settings) {
      const tempGraph: Graph = graph;
      Object.values(graph).forEach((node) => {
        if (node.connectsTo.length !== 1) {
          const nodeConnections = new Map();
          node.connectsTo.forEach((connection) => {
            if (nodeConnections.has(connection)) {
              nodeConnections.set(
                connection,
                nodeConnections.get(connection) + 1
              );
            }
            if (!nodeConnections.has(connection)) {
              nodeConnections.set(connection, 1);
            }
          });
          // removes an isolated, unbroken node (can occur if user is deleting
          // paths and one line contained a broken node)
          if (nodeConnections.size === 1) {
            delete tempGraph[node.id];
          }
          // handling for node connected to itself and other nodes (can occur
          // when drawing, not deleting)
          if (nodeConnections.has(node.id) && nodeConnections.size !== 1) {
            const currNodeConnects = tempGraph[node.id].connectsTo;
            const cleanedNodeConnects = currNodeConnects.filter(
              (connection) => connection !== node.id
            );
            tempGraph[node.id].connectsTo = cleanedNodeConnects;
          }
        }
      });
      await saveFloor({
        settings: { ...settings, circulation: tempGraph },
        buildingID,
        floorID,
        strategyID,
        projectID,
      });
    }
  };

  const handleSelectRooms = (rooms: SelectedMeetingRoom[] | undefined) => {
    if (rendererMode === RendererMode.MeetingRoomMetric) {
      setMeetingRoomMetricsSelectedRoom(rooms?.[0]?.conferenceRoomId || null);
    } else {
      if (rooms !== undefined) {
        // avoid re-rendering if the rooms are the same
        if (rooms.length !== (selectedRooms || []).length) {
          selectRooms(rooms);
        }
      } else if (rooms === undefined) {
        selectRooms(undefined);
      }
    }
  };

  const onNudge = useCallback(
    async (x: number, y: number) => {
      if (selectedRegion !== undefined) {
        const blockIDs: string[] = [];
        selectedRegion.forEach((idx) => {
          if (currentSettings) blockIDs.push(currentSettings.blocks[idx][0].id);
        });
        await moveInstances({
          buildingID,
          floorID,
          strategyID,
          idx: selectedRegion,
          x,
          y,
        });
      }
    },
    [
      buildingID,
      currentSettings,
      floorID,
      moveInstances,
      selectedRegion,
      strategyID,
    ]
  );

  useEffect(() => {
    const handleKeyboardEvent = (event: KeyboardEvent) => {
      if (event.key === "Escape" && !matchMakerActive) {
        setRendererMode(RendererMode.Select);
      } else if (event.key === "Escape" && matchMakerActive) {
        setRendererMode(RendererMode.Select);
        setMatchMakerActive(false);
        setMatchMakerStep(0);
      }

      if (
        (event.metaKey || event.ctrlKey) &&
        event.code === "KeyY" &&
        getHistoryForwardEnabled()
      ) {
        event.preventDefault();
        selectRegion(undefined);
        historyGoForward({ projectID, buildingID, strategyID, floorID });
        setRendererMode(RendererMode.Select);
      }

      if (
        (event.metaKey || event.ctrlKey) &&
        event.code === "KeyZ" &&
        getHistoryBackEnabled()
      ) {
        event.preventDefault();
        selectRegion(undefined);
        historyGoBack({ projectID, buildingID, strategyID, floorID });
        setRendererMode(RendererMode.Select);
      }

      if (
        (event.key === "Backspace" || event.key === "Delete") &&
        selectedRegion
      ) {
        event.preventDefault();
        handleDeleteRegion(selectedRegion);
      }

      if (event.key === "ArrowLeft" && selectedRegion) {
        onNudge(-1, 0);
      }

      if (event.key === "ArrowRight" && selectedRegion) {
        onNudge(1, 0);
      }

      if (event.key === "ArrowUp" && selectedRegion) {
        onNudge(0, 1);
      }

      if (event.key === "ArrowDown" && selectedRegion) {
        onNudge(0, -1);
      }
    };

    document.addEventListener("keydown", handleKeyboardEvent);
    return () => {
      document.removeEventListener("keydown", handleKeyboardEvent);
    };
  }, [
    onNudge,
    handleDeleteRegion,
    buildingID,
    floorID,
    getHistoryForwardEnabled,
    getHistoryBackEnabled,
    selectedRegion,
    setRendererMode,
    selectRegion,
    historyGoForward,
    projectID,
    strategyID,
    historyGoBack,
    setMatchMakerActive,
    setMatchMakerStep,
    matchMakerActive,
    currentSettings,
  ]);

  // Use setTimeout so that HTML render completes immediately
  setTimeout(() => {
    if (interCanvasRef.current && xform) {
      const canvas = interCanvasRef.current;
      CanvasReconciler.render(
        <>
          <ProjectProvider initialState={projectID}>
            <SettingsProvider
              initialState={{ floorID, strategyID, buildingID, projectID }}
            >
              <FeaturesProvider
                initialState={{ floorID, strategyID, buildingID, projectID }}
              >
                <Pan
                  updateXform={updateXform}
                  minZoom={fitScreenScale}
                  active={rendererMode === RendererMode.Pan}
                  setRendererMode={setRendererMode}
                  rendererMode={rendererMode}
                >
                  <Xform xform={xform}>
                    <Select
                      blockInstances={transformBlockInstance}
                      setTransformBlockInstance={setTransformBlockInstance}
                      selectRegion={selectRegion}
                      selectRooms={handleSelectRooms}
                      selectedRooms={selectedRooms}
                      xform={xform}
                      active={
                        rendererMode === RendererMode.Select ||
                        rendererMode === RendererMode.Selected ||
                        rendererMode === RendererMode.Transforming ||
                        rendererMode === RendererMode.Draw ||
                        rendererMode === RendererMode.MeetingRoomMetric
                      }
                      instances={instances}
                      updateNavBarState={updateNavBarState}
                      conferenceRooms={
                        currentFeatures
                          ? currentFeatures.conferenceRooms
                          : undefined
                      }
                      selectedRegion={selectedRegion}
                      rendererMode={rendererMode}
                    />
                    <Delete
                      onDelete={handleDeleteRegion}
                      xform={xform}
                      active={rendererMode === RendererMode.Delete}
                      instances={instances}
                      settings={currentSettings}
                    />
                    <Transform
                      instances={
                        instances && selectedRegion !== undefined
                          ? instances.filter((inst, i) =>
                              selectedRegion.includes(i)
                            )
                          : undefined
                      }
                      wpiActivitiesMode={wpiActivitiesMode}
                      blocks={blocks}
                      active={
                        rendererMode === RendererMode.Selected ||
                        rendererMode === RendererMode.Transforming
                      }
                      xform={xform}
                      setRendererMode={setRendererMode}
                      handleUpdateInstance={handleUpdateInstance}
                      getBlockById={getBlockById}
                      transformBlockInstance={transformBlockInstance}
                      setTransformBlockInstance={setTransformBlockInstance}
                    />
                    <DrawRegion
                      width={width}
                      height={height}
                      setRendererMode={setRendererMode}
                      xform={xform}
                      active={rendererMode === RendererMode.Draw}
                      handleAddRegion={handleAddRegion}
                      isValidDimension={handleIsValidDimension}
                      isErasing={blockType === BlockType.EraseSeats}
                      getBlockById={getBlockById}
                      getBlocksBySizeAndType={getBlocksBySizeAndType}
                      filteredLibraries={filteredLibraries}
                      filterBlocksByLibrary={filterBlocksByLibrary}
                      settings={currentSettings}
                      addInstance={addInstance}
                      saveFloor={saveFloor}
                      buildingID={buildingID}
                      strategyID={strategyID}
                      floorID={floorID}
                    />
                    <DrawCirculation
                      graph={circulation || {}}
                      xform={xform}
                      active={
                        rendererMode === RendererMode.DrawCirculation ||
                        rendererMode === RendererMode.DrawWalkabilityCirculation
                      }
                      handleAddCirculation={handleUpdateCirculation}
                    />
                    <DeleteCirculation
                      graph={circulation || {}}
                      xform={xform}
                      active={
                        rendererMode === RendererMode.DeleteCirculation ||
                        rendererMode ===
                          RendererMode.DeleteWalkabilityCirculation
                      }
                      handleRemoveCirculation={handleUpdateCirculation}
                    />
                    <EditCirculation
                      graph={circulation || {}}
                      xform={xform}
                      active={
                        rendererMode === RendererMode.EditCirculation ||
                        rendererMode === RendererMode.EditWalkabilityCirculation
                      }
                      handleEditCirculation={handleUpdateCirculation}
                    />
                  </Xform>
                </Pan>
              </FeaturesProvider>
            </SettingsProvider>
          </ProjectProvider>
        </>,
        canvas
      );
    }
  }, 0);

  return (
    <>
      <canvas
        width={width}
        height={height}
        style={{ position: "absolute", top: 0 }}
        ref={(el) => (interCanvasRef.current = el)}
      />
      {rendererMode === RendererMode.Selected && (
        <Menu instances={instances} onDelete={handleDeleteRegion} />
      )}

      {/* TODO: Remove clear button and JSX syntax this is just for 
      assistance while working */}
      {claims &&
        claims.groups.includes("tools") &&
        (rendererMode === RendererMode.BuzzMetric ||
          rendererMode === RendererMode.DrawCirculation ||
          rendererMode === RendererMode.EditCirculation ||
          rendererMode === RendererMode.DeleteCirculation) && (
          <>
            <Button
              style={{ width: 220, position: "absolute", bottom: 40, left: 20 }}
              onClick={handleSaveDefaultCirculation}
              variant="contained"
              color="secondary"
            >
              Save Default Circulation
            </Button>
            <Button
              style={{
                width: 220,
                position: "absolute",
                bottom: 40,
                left: 250,
              }}
              onClick={handleClearDefaultCirculation}
              variant="contained"
              color="secondary"
            >
              Clear Default Circulation
            </Button>
            <Button
              style={{
                width: 220,
                position: "absolute",
                bottom: 40,
                left: 480,
              }}
              onClick={() => {
                fixBrokenCirculation(currentSettings.circulation);
              }}
              variant="contained"
              color="secondary"
            >
              Fix Circulation
            </Button>
          </>
        )}
    </>
  );
};

export default ISPCanvasInteractions;
