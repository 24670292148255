import { Metric } from "lib/types";

export const calculateDensity = (metric: any): number => {
  const usable = calculateUseableFloorArea(metric);
  return usable / Math.max(metric.assignableSeats, 1);
};

export const calculateBaselineDensity = (metric: any): number => {
  return metric.totalFloorArea / metric.baselineSeatCount;
};

export const calculateDeskToDoor = (metric: any): number => {
  if (
    calculateConferenceRoomCount(metric) === 0 ||
    calculateConferenceRoomCount === undefined
  ) {
    return NaN;
  }
  return (
    metric.assignableSeats / calculateConferenceRoomCount(metric) + metric.doors
  );
};

export const calculateBuildingDeskToDoor = (buildingMetrics: Metric[]) => {
  let allSeats = 0;
  let allDoors = 0;

  buildingMetrics.forEach((floorMetric) => {
    allSeats += floorMetric.assignableSeats;
    allDoors += floorMetric.doors
      ? floorMetric.doors
      : (floorMetric.phone ? floorMetric.phone.count : 0) +
        (floorMetric.phonesCount || 0) +
        (floorMetric.small ? floorMetric.small.count : 0) +
        (floorMetric.medium ? floorMetric.medium.count : 0) +
        (floorMetric.large ? floorMetric.large.count : 0) +
        (floorMetric.extraLarge ? floorMetric.extraLarge.count : 0) +
        (floorMetric.huddle ? floorMetric.huddle.count : 0) -
        floorMetric.convertedMeetingRooms;
  });

  return allSeats / allDoors;
};

export const calculateAverage = (ratios: number[]) => {
  ratios = ratios.filter((ratio) => !isNaN(ratio) && ratio !== Infinity);
  let result = ratios.reduce((a, b) => a + b, 0);
  result = result / ratios.length;

  return result;
};

export const calculateBaselineDeskToDoor = (metric: any): number => {
  return metric.baselineSeatCount / metric.baselineConferenceRoomsCount;
};

export const calculateBuildingBaselineDeskToDoor = (
  buildingMetrics: Metric[]
) => {
  const deskToDoorBaselineAllFloors = buildingMetrics.map((floorMetric) => {
    return calculateBaselineDeskToDoor(floorMetric);
  });

  return calculateAverage(deskToDoorBaselineAllFloors);
};

// takes average of desk-to-door ratio for all floors
// nooks are included in the count of "doors"
export const calculateBuildingDeskToDoorWithNooks = (
  buildingMetrics: Metric[]
) => {
  let allSeats = 0;
  let allDoors = 0;

  buildingMetrics.forEach((floorMetric) => {
    allSeats += floorMetric.assignableSeats;
    allDoors += floorMetric.doors
      ? floorMetric.doors + floorMetric.nooksCount
      : (floorMetric.phone ? floorMetric.phone.count : 0) +
        (floorMetric.phonesCount || 0) +
        (floorMetric.small ? floorMetric.small.count : 0) +
        (floorMetric.medium ? floorMetric.medium.count : 0) +
        (floorMetric.large ? floorMetric.large.count : 0) +
        (floorMetric.extraLarge ? floorMetric.extraLarge.count : 0) +
        (floorMetric.huddle ? floorMetric.huddle.count : 0) +
        (floorMetric.nooksCount || 0) -
        floorMetric.convertedMeetingRooms;
  });

  return allSeats / allDoors;
};

export const calculateConferenceRoomCount = (metric: any): number => {
  const phones = metric.phonesCount || 0;
  return (
    metric.baselineConferenceRoomsCount + phones - metric.convertedMeetingRooms
  );
};

export const calculateBaselineUsableDensity = (metric: any): number => {
  return (
    calculateUseableFloorArea(metric) / Math.max(metric.baselineSeatCount, 1)
  );
};

export const calculateUsableDensity = (metric: any): number => {
  return calculateUseableFloorArea(metric) / Math.max(metric.seatCount, 1);
};

export const calculateUseableFloorArea = (metric: any): number => {
  return metric.totalFloorArea * metric.efficiencyRatio;
};

export const baselineConferenceRoomsSeatsPerHC = (metric: any): number => {
  return metric.baselineConferenceRoomsCapacity / metric.baselineSeatCount;
};

export const conferenceRoomSeatsPerHC = (metric: any): number => {
  return metric.conferenceRoomSeats / Math.max(metric.seatCount, 1);
};
