import React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Option, Metric } from "lib/types";

interface Props {
  onChange: (apiName: string, title: string) => void;
  title: string;
  options?: Option[];
  tooltip?: string;
  disabled?: boolean;
  metric: Metric;
  value: string;
  disabledOption: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      margin: "16px 0px 0px",
      width: "175px",
    },
    select: {
      minHeight: 32,
      width: "200px",

      backgroundColor: "#EFF2F4",
      "& > div": {
        padding: "8px 20px 8px 8px",
        fontFamily: "GoogleSansRegular",
        color: "#2B2020",
      },
    },
  })
);

// Sub-component of individual picker.
const IndividualMultiPicker: React.FC<Props> = ({
  onChange,
  title,
  disabled = false,
  value,
  disabledOption,
}) => {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const apiName = event.target.value as string;
    onChange(apiName, title);
  };

  return (
    <div className={classes.container} key={title}>
      <Select
        id={title}
        disabled={disabled}
        displayEmpty={true}
        variant="outlined"
        onChange={handleChange}
        className={classes.select}
        value={value}
      >
        <MenuItem key={"No Change"} value={"noChange"}>
          No change
        </MenuItem>
        <MenuItem
          key={"Collaboration"}
          value={"convertToCollaboration"}
          disabled={disabledOption === "convertToCollaboration"}
        >
          Collaboration
        </MenuItem>
        <MenuItem
          key={"WorkStation"}
          value={"convertToWorkstations"}
          disabled={disabledOption === "convertToWorkstations"}
        >
          WorkStation
        </MenuItem>
        <MenuItem
          key={"Disable"}
          value={"disable"}
          disabled={disabledOption === "disable"}
        >
          Disabled
        </MenuItem>
        {value === "mixed" ? (
          <MenuItem key={"Mixed"} value={"mixed"}>
            Mixed
          </MenuItem>
        ) : null}
      </Select>
    </div>
  );
};

export default IndividualMultiPicker;
