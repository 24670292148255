import React from "react";
import { DisplayRendererMetric } from "lib/types";
import MetricsRow from "./metricsRow";
import MetricsRowHeader from "./metricsRowHeader";
import { formatMetric, truncateNumber } from "lib/number";
import { useProjectCtrl } from "lib/containers";
import { useParams } from "react-router-dom";
import { findBuildingMetrics } from "lib/metrics/renderer-metrics";
import {
  calculateBuildingDeskToDoor,
  calculateBuildingDeskToDoorWithNooks,
} from "lib/metrics/util";

interface FloorAndBuildingMetricsTableProps {
  floorMetrics: DisplayRendererMetric;
  buildingMetrics: DisplayRendererMetric;
}

// Build out metrics table in performance section of portfolio
const FloorAndBuildingMetricsTable: React.FC<
  FloorAndBuildingMetricsTableProps
> = ({ floorMetrics, buildingMetrics }) => {
  const { project } = useProjectCtrl();
  const { buildingID, strategyID } = useParams<{
    [key: string]: string;
  }>();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const buildingMetric = findBuildingMetrics(project!, buildingID, strategyID);

  const buildingDeskToDoorWithNooks = formatMetric(
    calculateBuildingDeskToDoorWithNooks(buildingMetric),
    2
  );

  const floorDeskToDoorWithNooks = formatMetric(
    floorMetrics.assignableSeats /
      (floorMetrics.doors + floorMetrics.nooksCount),
    2
  );

  const calcHeadCountSupported = (desks: number, shareRatio: number) => {
    return shareRatio * desks;
  };

  const buildingHeadcount = calcHeadCountSupported(
    buildingMetrics.seatCount,
    1
  );
  const floorHeadcount = calcHeadCountSupported(floorMetrics.seatCount, 1);

  return (
    <>
      <MetricsRowHeader firstColumn={"Building"} secondColumn={"Floor"} />
      <MetricsRow
        helpguideTooltip=""
        description={"Building Capacity"}
        firstColumn={buildingMetrics.seatCount}
        secondColumn={floorMetrics.seatCount}
      />
      <MetricsRow
        helpguideTooltip=""
        description={"Total Headcount Supported"}
        firstColumn={buildingHeadcount}
        secondColumn={floorHeadcount}
      />
      <MetricsRow
        helpguideTooltip="Desk to Door"
        description={"Desk To Door"}
        firstColumn={formatMetric(
          calculateBuildingDeskToDoor(buildingMetric),
          2
        )}
        secondColumn={formatMetric(
          floorMetrics.assignableSeats / floorMetrics.doors,
          2
        )}
      />
      <MetricsRow
        helpguideTooltip=""
        description={"Desk to Door (Incl. Nooks)"}
        firstColumn={buildingDeskToDoorWithNooks}
        secondColumn={floorDeskToDoorWithNooks}
      />
      <MetricsRow
        helpguideTooltip="Total Seats"
        description={"Total Seats"}
        firstColumn={buildingMetrics.seatCount || 0}
        secondColumn={floorMetrics.seatCount || 0}
      />
      <MetricsRow
        helpguideTooltip="Total Workpoints"
        description={"Total Workpoints"}
        firstColumn={buildingMetrics.workpoints || 0}
        secondColumn={floorMetrics.workpoints || 0}
      />
      <MetricsRow
        helpguideTooltip="Nooks"
        description={"Total Nooks"}
        firstColumn={buildingMetrics.nooksCount}
        secondColumn={floorMetrics.nooksCount}
      />
      <MetricsRow
        helpguideTooltip="Phonebooths"
        description={"Total Phonebooths"}
        firstColumn={buildingMetrics.phone}
        secondColumn={floorMetrics.phone}
      />
      <MetricsRow
        helpguideTooltip="Desk to Door"
        description={"Total Doors (incl. Nooks)"}
        firstColumn={buildingMetrics.doors + buildingMetrics.nooksCount}
        secondColumn={floorMetrics.doors + floorMetrics.nooksCount}
      />
      <MetricsRow
        helpguideTooltip="GSF / Total Seats"
        description={"GSF/Total Seats"}
        firstColumn={buildingMetrics.density || 0}
        secondColumn={floorMetrics.density || 0}
      />
      <MetricsRow
        helpguideTooltip="Converted Seats"
        description={"Converted Seats"}
        firstColumn={buildingMetrics.convertedSeats || 0}
        secondColumn={floorMetrics.convertedSeats || 0}
      />
      <MetricsRow
        helpguideTooltip="Collab Total SF"
        description={"Collab Total SF"}
        firstColumn={buildingMetrics.collabTotal || 0}
        secondColumn={floorMetrics.collabTotal || 0}
      />
      <MetricsRow
        helpguideTooltip="Collab Spaces"
        description={"Collab Spaces"}
        firstColumn={buildingMetrics.collaborationZones || 0}
        secondColumn={floorMetrics.collaborationZones || 0}
      />
      <MetricsRow
        helpguideTooltip="Avg SF / Collab Space"
        description={"Avg SF / Collab Space"}
        firstColumn={buildingMetrics.avgCollabArea || 0}
        secondColumn={floorMetrics.avgCollabArea || 0}
      />
      <MetricsRow
        helpguideTooltip="Collab Seats"
        description={"Collab Seats"}
        firstColumn={buildingMetrics.collaborationSeats || 0}
        secondColumn={floorMetrics.collaborationSeats || 0}
      />
      <MetricsRow
        helpguideTooltip="Total Budget (Relevant GSF)"
        description={"Total Budget"}
        firstColumn={truncateNumber(buildingMetrics.totalCost) || 0}
        secondColumn={truncateNumber(floorMetrics.totalCost) || 0}
      />
    </>
  );
};

export default FloorAndBuildingMetricsTable;
