import React, { useState } from "react";
import { omit } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { Building } from "lib/types";
import { ParsedDXFFile } from "./types";
import withBuildings from "lib/hoc/withBuildings";
import Setup from "./steps/stepSetup";
import Import from "./steps/stepImport";
import Confirm from "./steps/stepConfirm";
import withBackgrounds from "../lib/hoc/withBackgrounds";
import withFeatures from "../lib/hoc/withFeatures";
import {
  NewBuildingRequest,
  useBuildingsCtrl,
  useProjectCtrl,
} from "../lib/containers";
import { computeBuildingMetrics } from "./lib/dxf";

const useStyles = makeStyles({
  content: {
    overflow: "visible",
    display: "flex",
    flex: 1,
    margin: "2rem 24px",
  },
  dialog: {},
  paper: {
    height: "calc(100% - 200px)",
  },
  stepper: {
    padding: "26px 0 26px 32px",
  },

  closeButton: {
    position: "absolute",
    right: "1rem",
    top: "1rem",
    color: "#5F6166",
  },
  rootTitle: {
    margin: 0,
    padding: 6,
  },
  actions: {
    padding: "0 40px 32px",
  },
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DXFImportProps {}

export enum Stage {
  stepSetup = 0,
  stepImport = 1,
  stepConfirm = 2,
}

function getSteps() {
  return ["Setup", "Import", "Confirm"];
}

const defaultBuilding: Building = {
  AddressLine: "",
  BuildingId: "",
  Campus: "",
  City: "",
  CitySite: "",
  Country: "",
  Geo: "",
  Orientation: 0,
  PostalCode: "",
  Region: "",
  State: "",
  District: "",
  AvailableFloors: [],
  TypicalFloorHeight: 0,
  FirstFloorHeight: 0,
  Coordinates: { Lat: 0, Lng: 0 },
};

export interface DialogTitleProps {
  children: React.ReactNode;
  onClose: () => void;
}

const StepperTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;
  const classes = useStyles();

  return (
    <DialogTitle disableTypography className={classes.rootTitle} {...other}>
      <div style={{ marginRight: "4rem" }}>{children}</div>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const DXFImport: React.FC<DXFImportProps> = () => {
  const classes = useStyles();
  const history = useHistory();
  const { saveBuilding } = useBuildingsCtrl();
  const { addBuildings } = useProjectCtrl();
  const [activeStep, setStep] = useState<Stage>(Stage.stepSetup);
  const [files, setFiles] = useState<Record<string, ParsedDXFFile>>({});
  const [building, setBuilding] = useState<Building>(defaultBuilding);
  const [ready, setReady] = React.useState(false);
  const steps = getSteps();

  const handleClose = () => {
    history.goBack();
  };

  const handleNext = () => {
    setStep((prev) => Math.min(prev + 1, steps.length - 1));
    setReady(false);
    if (activeStep + 1 >= steps.length - 1) handleSave();
  };

  const handleBack = () => {
    setReady(true);
    setStep((prev) => Math.max(prev - 1, 0));
  };

  const handleAddFiles = (_files: File[]) => {
    setFiles((prev) => {
      const acc = { ...prev };
      _files.forEach(
        (file) => (acc[file.name] = { name: file.name, rawFile: file })
      );
      return acc;
    });
  };

  const handleSave = async () => {
    const metrics = computeBuildingMetrics(building, files);
    const data: NewBuildingRequest = {
      building,
      metrics: metrics.building,
      floors: {},
    };
    Object.values(files).forEach((file) => {
      if (file.parsed) {
        data.floors[file.parsed.level] = {
          features: file.parsed.features,
          background: file.parsed.background,
          metrics: metrics.floors[file.parsed.level],
          regions: file.parsed.regions,
        };
      }
    });
    await saveBuilding(data);
    await addBuildings([data.building]);
  };

  const handleUpdateAll = (next: Record<string, ParsedDXFFile>) => {
    // Update building levels
    const levels: string[] = [];
    Object.values(next).forEach((file) => {
      if (file.parsed) {
        levels.push(file.parsed.level + "");
      }
    });
    setFiles(next);
    setBuilding({ ...building, AvailableFloors: levels, HasData: true });
  };

  const handleRemoveFile = (name: string) => {
    setFiles((prev) => omit(prev, [name]));
  };

  const handleUpdateBuilding = (bldg: Building) => {
    setBuilding(bldg);
  };

  function getStepComponent(step: number): React.ReactNode {
    switch (step) {
      case Stage.stepSetup:
        return (
          <Setup
            building={building}
            updateBuilding={handleUpdateBuilding}
            ready={ready}
            setReady={setReady}
          />
        );
      case Stage.stepImport:
        return (
          <Import
            files={files}
            addFiles={handleAddFiles}
            updateAll={handleUpdateAll}
            removeFile={handleRemoveFile}
            ready={ready}
            setReady={setReady}
          />
        );
      case Stage.stepConfirm:
        return <Confirm building={building} files={files} />;
    }
  }

  return (
    <Dialog
      open
      fullWidth
      maxWidth="lg"
      classes={{ root: classes.dialog, paper: classes.paper }}
      onClose={handleClose}
    >
      <StepperTitle onClose={handleClose}>
        <Stepper activeStep={activeStep} className={classes.stepper}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </StepperTitle>

      <DialogContent className={classes.content}>
        {getStepComponent(activeStep)}
      </DialogContent>

      <DialogActions className={classes.actions}>
        {activeStep === Stage.stepSetup ||
        activeStep === Stage.stepConfirm ? null : (
          <div style={{ display: "flex", flex: 1 }}>
            <Button
              onClick={handleBack}
              color="primary"
              size="large"
              startIcon={<ChevronLeftIcon />}
            >
              Back
            </Button>
          </div>
        )}
        {activeStep === Stage.stepConfirm ? null : (
          <Button
            onClick={handleNext}
            size="large"
            color="primary"
            variant="contained"
            disabled={!ready}
          >
            Next
          </Button>
        )}
        {activeStep !== Stage.stepConfirm ? null : (
          <Button
            onClick={handleClose}
            size="large"
            variant="contained"
            color="primary"
          >
            Close
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

const importWithBuildings = withBuildings(
  withBackgrounds(withFeatures(DXFImport))
);
export default importWithBuildings;
