import { Metric, Project, Summary } from "../types";
import { emptySummary } from "./constants";
import {
  calculateAverage,
  calculateBuildingDeskToDoor,
  calculateDensity,
} from "./util";

// used to create metric summaries in sidebar header and in strategies header
export const makeSummaryMetrics = (metrics: Metric[]): Summary => {
  const sums = metrics.reduce(
    (acc, curr) => {
      return {
        totalFloorArea: acc.totalFloorArea + curr.totalFloorArea,
        seatCount: acc.seatCount + curr.seatCount,
        baselineSeatCount: acc.baselineSeatCount + curr.baselineSeatCount,
        convertedMeetingRooms:
          acc.convertedMeetingRooms + curr.convertedMeetingRooms,
        conferenceRoomsBaseline:
          acc.conferenceRoomsBaseline + curr.baselineConferenceRoomsCount,
        baselineConferenceRoomsCount:
          acc.baselineConferenceRoomsCount + curr.baselineConferenceRoomsCount,
        efficiencyRatio: (acc.efficiencyRatio = curr.efficiencyRatio),
        assignableSeats:
          acc.assignableSeats +
          ((curr.nooksAssignable || 0) +
            (curr.deskCount || 0) +
            // LM-Note: the logic below for handling floorscopeDesks is clarified
            // within the MR below, in the description and in a thread with JV
            // https://gitlab.com/outerlabs/portfolio/-/merge_requests/1039
            (curr.floorscopeDesks !== 0 ||
            (curr.floorscopeDesks === 0 &&
              (curr.deskCount || curr.phonesCount || curr.nooksCount))
              ? curr.floorscopeDesks
              : curr.seatCount)),
      };
    },
    { ...emptySummary }
  );

  // --- find average desk to door ratio for the entire project ---
  // extract unique strategies from `metrics`
  // extract all strategy ID's, then remove duplicates
  let uniqueStrategies = metrics.map((metric) => {
    return metric.strategyID;
  });
  uniqueStrategies = uniqueStrategies.filter(
    (strategyID, index) => uniqueStrategies.indexOf(strategyID) === index
  );

  // for each unique strategy, create an array of buildingMetrics
  const projectMetrics = uniqueStrategies.map((strategyID) => {
    const buildingMetrics: Metric[] = metrics.filter((metric) => {
      return metric.strategyID === strategyID;
    });
    return buildingMetrics;
  });

  // calculate summary desk to door baseline value
  let baselines = 0;
  let baselineCount = 0;
  projectMetrics.forEach((building) => {
    let buildingAssSeats = 0;
    let buildingDoors = 0;
    building.forEach((metric) => {
      buildingAssSeats += metric.baselineSeatCount;
      buildingDoors += metric.baselineConferenceRoomsCount;
    });
    baselines += buildingAssSeats / buildingDoors;
    baselineCount++;
  });

  // find the desk-to-door & baseline desk-to-door ratios for each building
  const deskToDoorRatios = projectMetrics.map((building) =>
    calculateBuildingDeskToDoor(building)
  );

  // find the average of the building ratios
  const deskToDoor = calculateAverage(deskToDoorRatios);

  return {
    ...sums,
    efficiencyRatio: sums.efficiencyRatio,
    assignableSeats: sums.assignableSeats,
    density: calculateDensity(sums),
    baselineDensity:
      (sums.totalFloorArea * sums.efficiencyRatio) / sums.baselineSeatCount,
    deskToDoor: deskToDoor,
    baselineDeskToDoor: baselines / baselineCount,
  };
};

// extracts metrics of all primary strategies within a given project
export const makeSummary = (project: Project): Summary => {
  const metrics = Object.values(project.metrics).reduce<Metric[]>(
    (accum, m) => {
      if (m.strategyID === project.primaryStrategies[m.buildingID])
        accum.push(m);
      return accum;
    },
    []
  );

  return makeSummaryMetrics(metrics);
};
