import React from "react";
import { Canvas } from "@outerlabs/canvas-reconciler";
import { renderMeetingRoomMetrics } from "lib/isp-canvas";
import { Features, RendererSettings } from "lib/types";
import {
  MeetingRoomMetricsLevels,
  MeetingRoomMetricsType,
  MeetingRoomSizeAndAllType,
} from "../lib/containers/meeting-room-metrics-manager";

interface Props {
  settings?: RendererSettings;
  features: Features;
  type: MeetingRoomMetricsType;
  levels: MeetingRoomMetricsLevels;
  level: string;
  size: MeetingRoomSizeAndAllType;
  meetingRoomMetricSelectedRoom: string | null;
}

export const MeetingRoomMetrics: React.FC<Props> = ({
  features,
  type,
  levels,
  level,
  size,
  meetingRoomMetricSelectedRoom,
}) => {
  const canvas = (
    <Canvas
      render={(ctx: CanvasRenderingContext2D) => {
        renderMeetingRoomMetrics(
          ctx,
          features.conferenceRooms,
          type,
          levels,
          level,
          size,
          meetingRoomMetricSelectedRoom
        );
      }}
    />
  );

  return <>{canvas}</>;
};

export default React.memo(MeetingRoomMetrics);
