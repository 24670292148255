import { format } from "./utils";

export type Insert = {
  type: "insert";
  name?: string;
  ref: string;
  x: number;
  y: number;
  xs?: number;
  ys?: number;
  r: number;
};

export const insert = (i: Insert): string => {
  return (
    format("0", "INSERT") +
    format("2", i.ref) +
    format("10", i.x) +
    format("20", i.y) +
    format("41", i.xs || 1) +
    format("42", i.ys || 1) +
    format("50", i.r)
  );
};
