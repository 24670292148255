import fetchNewFloorMetrics from "lib/metrics/fetch-new-floor-metrics";
import { decodeMetricId, encodeMetricId } from "lib/metrics/id";
import { ComponentType } from "react";
import { ContainerProviderProps, createContainer } from "unstated-next";
import { v4 as uuidv4 } from "uuid";
import { Metrics, Mutation } from "../types";
import { useProjectCtrl } from "./project";
import { useSettingsCtrl } from "./settings";
import { getSettingsFile } from "lib/api/rendererSettings";
import getFeatures from "lib/api/getFeatures";

export interface UseStrategyType {
  resetStrategy(arg: {
    buildingID: string;
    strategyID: string;
    mutation?: Mutation;
    floorID?: string;
  }): Promise<void>;
  newStrategy(buildingID: string, strategyName: string): Promise<void>;
  setStrategyAsPrimary(buildingID: string, strategyID: string): Promise<void>;
  deleteStrategy(buildingID: string, strategyID: string): Promise<void>;
  duplicateStrategy(
    buildingID: string,
    strategyID: string,
    duplicateStrategyName: string
  ): Promise<void>;
}

export const useStrategyContainer = (): UseStrategyType => {
  const { project, saveProject } = useProjectCtrl();
  const { duplicateSettings, saveFloor } = useSettingsCtrl();

  const newStrategy = async (
    buildingID: string,
    displayName: string
  ): Promise<void> => {
    if (!project) return;
    try {
      const availableFloors = project.buildings[buildingID].AvailableFloors;
      const newId = uuidv4();
      const floorMetrics = await fetchNewFloorMetrics(
        availableFloors,
        buildingID,
        newId
      );
      if (floorMetrics && Object.keys(floorMetrics).length > 0) {
        const keys = Object.keys(floorMetrics);
        for (let i = 0; i < keys.length; i++) {
          floorMetrics[keys[i]].displayName = displayName;
        }
        await saveProject({
          ...project,
          metrics: {
            ...project.metrics,
            ...floorMetrics,
          },
        });
      }
    } catch (error: any) {
      console.error("error", error);
    }
  };

  const setStrategyAsPrimary = async (
    buildingID: string,
    strategyID: string
  ) => {
    if (!project) return;
    try {
      await saveProject({
        ...project,
        primaryStrategies: {
          ...project.primaryStrategies,
          [buildingID]: strategyID,
        },
      });
    } catch (error: any) {
      console.error("error", error);
    }
  };

  const deleteStrategy = async (
    buildingID: string,
    strategyID: string
  ): Promise<void> => {
    if (!project) return;
    try {
      const { metrics, buildings, primaryStrategies } = project;
      const deleteKeys = Object.keys(metrics).filter(
        (k) => k.includes(buildingID) && k.includes(strategyID)
      );
      deleteKeys.forEach((k) => {
        delete metrics[k];
      });
      const otherMetricsForBuilding = Object.keys(metrics).filter((key) => {
        const { buildingID: bID } = decodeMetricId(key);
        return bID === buildingID;
      });
      if (otherMetricsForBuilding.length === 0) {
        delete buildings[buildingID];
        const buildingIds = project.buildingIds.filter(
          (i: string) => i !== buildingID
        );
        delete primaryStrategies[buildingID];
        await saveProject({
          ...project,
          buildingIds,
          buildings,
          metrics,
          primaryStrategies,
        });
      } else if (primaryStrategies[buildingID] === strategyID) {
        const otherMetricId = Object.keys(metrics).find((k) => {
          const { buildingID: bID } = decodeMetricId(k);
          return bID === buildingID;
        });
        if (otherMetricId) {
          const { strategyID: sID } = decodeMetricId(otherMetricId);

          await saveProject({
            ...project,
            metrics,
            primaryStrategies: { ...primaryStrategies, [buildingID]: sID },
          });
        }
      } else {
        await saveProject({ ...project, metrics });
      }
    } catch (error: any) {
      console.error("error", error);
    }
  };

  const duplicateStrategy = async (
    buildingID: string,
    strategyID: string,
    duplicateStrategyName: string
  ): Promise<void> => {
    if (!project) return;
    const newID = uuidv4();
    const availFloors = project.buildings[buildingID].AvailableFloors;
    if (!availFloors || !availFloors.length) return;
    const oldKeys = availFloors.map((fc) =>
      encodeMetricId(buildingID, strategyID, fc)
    );
    const newMetrics = oldKeys.reduce<Metrics>((acc, k) => {
      const currMetric = project.metrics[k];
      if (!currMetric) return acc;
      const metricCopy = { ...currMetric };
      const { floorID } = decodeMetricId(k);
      const newKey = encodeMetricId(buildingID, newID, floorID);
      metricCopy.displayName = duplicateStrategyName;
      metricCopy.strategyID = newID;

      acc[newKey] = metricCopy;
      return acc;
    }, {});

    await duplicateSettings({
      buildingID,
      floorIDs: availFloors,
      strategyID,
      newStrategyID: newID,
      projectID: project.id,
    });
    await saveProject({
      ...project,
      metrics: { ...project.metrics, ...newMetrics },
    });
  };

  // Return the strategy to its initial state.
  // FloorID is supplied because this can be called while viewing the strategy,
  // but also can be called from the table view.
  // You can also supply a mutation if you want to reset it and then do
  // one thing.
  const resetStrategy = async ({
    buildingID,
    strategyID,
    floorID,
    mutation,
  }: {
    buildingID: string;
    strategyID: string;
    floorID?: string;
    mutation?: Mutation;
  }): Promise<void> => {
    if (!project) return;

    const availableFloors = project.buildings[buildingID].AvailableFloors;

    const nm = await fetchNewFloorMetrics(
      availableFloors,
      buildingID,
      strategyID
    );
    // manually force clearing of blocks
    //TODO: look into improving resetSettings() to prevent the neeed for this block
    if (availableFloors) {
      await Promise.all(
        availableFloors.map(async (floor) => {
          // get the settings file for the floor
          const settings = await getSettingsFile(
            project.id,
            buildingID,
            floor,
            strategyID
          );
          const existingIds: string[] = [];

          // get features for each floor
          // features contains: circulation, columns, conferenceRooms, desks,
          // floorplate, pointsOfInterest, primaryCirculation, uniqueDesks
          // uniqueDesks is the set of desks that the floorplan originally starts out with
          const features = await getFeatures(buildingID, floor);
          const uniqueDesks = features.uniqueDesks;

          if (settings) {
            // for every block on the floor, take it's id and stash in existingIds to use later
            const blocks = settings.blocks;
            blocks.forEach((block) => {
              existingIds.push(block[0].id);
            });
            // clear the blocks and blockChanges.put arrays,
            // set blockChanges.delete to all the blocks on that floor
            settings.blockChanges = { put: [], delete: existingIds };
            settings.blocks = [];
            settings.desks = {
              disabledCirculationDesks: [],
              disabledDistanceDesks: [],
              removedDesks: [],
              keptCenters: uniqueDesks,
              disabledCenters: [],
            };
            settings.circulation = null;

            // save your new settings
            await saveFloor({
              mutation,
              settings: settings,
              buildingID: buildingID,
              floorID: floor,
              strategyID: strategyID,

              // This would make each setting change save in the history stack
              dontAddToHistoryStack: true,

              // This would update the currently visible state
              dontUpdateContainerState: floor !== floorID,
            });
          }
        })
      );
    }

    // save new metrics for each floor with updated mutation
    if (nm) {
      Object.keys(nm).forEach((key) => {
        nm[key] = { ...nm[key], ...mutation };
      });
    }

    saveProject({ ...project, metrics: { ...project.metrics, ...nm } });
  };

  return {
    resetStrategy,
    newStrategy,
    setStrategyAsPrimary,
    deleteStrategy,
    duplicateStrategy,
  };
};

export const StrategyController = createContainer(useStrategyContainer);
export const StrategyProvider: ComponentType<ContainerProviderProps> =
  StrategyController.Provider;
export const useStrategyCtrl = () => StrategyController.useContainer();
