import axios from "axios";
import { setupCache } from "axios-cache-adapter";
import config from "config";
import { getAuthHeader } from "lib/auth";
import { Metric } from "lib/types";

const cache = setupCache({
  maxAge: 30 * 60 * 1000,
});

export const getFloorMetric = async (
  buildingID: string,
  floorID: string
): Promise<Metric | undefined> => {
  try {
    const headers = await getAuthHeader();
    const api = axios.create({
      headers,
      adapter: cache.adapter,
    });
    const m = await api.get(
      `${config.baseUrl}/api/planning/buildings/${buildingID}/${floorID}/metrics.json`,
      {
        headers,
      }
    );
    return m.data;
  } catch (e) {
    return undefined;
  }
};
