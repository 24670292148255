import React from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  BlockType,
  RendererMode,
  useBlockInstanceCtrl,
  useBlocksCtrl,
  useRendererCtrl,
  useSettingsCtrl,
} from "lib/containers";
import BlockLibrary from "../blocks/components/block-library";
import { sortRegion } from "../lib/isp-canvas/utils";
import { getters } from "../blocks/lib/constants";
import analytics from "../lib/analytics";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Props {}

const useStyles = makeStyles({
  sideBarHeaderTitle: {
    marginLeft: "15px",
    marginBottom: "15px",
    marginTop: "15px",
    color: "#555555",
    fontSize: "16px",
  },
  metricsContainer: { height: "365px" },
  headerContainer: {
    marginLeft: "17px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  accordionPanelTitleMoreOrLessContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
    height: 40,
    fontFamily: "GoogleSansRegular",
    fontSize: "15.5px",
    color: "#ADADAD",
  },
  accordionPanelIconContainer: {
    color: "#3B3B3B",
    paddingRight: "10px",
  },
  accordionPanelIcon: {
    color: "#ADADAD",
  },
  accordionPanelTitleMoreOrLessTitle: {
    width: "35px",
    color: "#2F73FF",
    fontWeight: "bold",
    fontFamily: "GoogleSansBold",
    fontSize: 14,
  },
  sideBarContent: {
    padding: 16,
  },
  sideBarHeaderContainer: {
    height: "220px",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    padding: 12,
  },
  wrap: {
    position: "relative",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    overflowY: "auto",
    height: "100%",
  },
});

const SidebarDrawMode: React.FC<Props> = () => {
  const classes = useStyles();
  const { buildingID, strategyID, floorID } = useParams<{
    [key: string]: string;
  }>();
  const { addInstance } = useBlockInstanceCtrl();
  const { setRendererMode, rendererMode, width, height, xform } =
    useRendererCtrl();
  const { saveFloor } = useSettingsCtrl();
  const { getBlockById } = useBlocksCtrl();

  if (rendererMode !== RendererMode.Draw) {
    return null;
  }

  // runs when you click a block
  const onCreateBlock = async (id: string) => {
    const ref = getBlockById(id);
    if (!ref) return;
    const metrics = getters.getMetrics(ref);
    const ox =
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ((width / 2) * xform!.globalScale - xform!.position.x) *
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        (1 / (-1 * xform!.scale)) -
      metrics.sizeRange[0][0] / 2;
    const oy =
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ((height / 2) * xform!.globalScale - xform!.position.y) *
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        (1 / xform!.scale) -
      metrics.sizeRange[1][0] / 2;

    const hw = (metrics.sizeRange[0][0] + metrics.sizeRange[0][1]) / 2;
    const hh = (metrics.sizeRange[1][0] + metrics.sizeRange[1][1]) / 2;
    let region: number[][] = [
      [ox, oy],
      [ox + hw, oy],
      [ox + hw, oy + hh],
      [ox, oy + hh],
    ];
    region = sortRegion(region);
    const settings = await addInstance({
      region,
      blockType: BlockType.Focus,
      buildingID,
      floorID,
      strategyID,
      blockID: id,
    });
    analytics.blockAdded();
    await saveFloor({ buildingID, strategyID, floorID, settings });
    setRendererMode(RendererMode.Select);
  };

  return (
    <div className={classes.sideBarContent}>
      <BlockLibrary type="block" onClick={onCreateBlock} />
    </div>
  );
};

export default React.memo(SidebarDrawMode);
