import React, { ChangeEvent } from "react";
import {
  Collapse,
  makeStyles,
  Modal,
  Button as CoreButton,
  Box,
} from "@material-ui/core";
import { RadialChart } from "react-vis";
import { colors } from "lib/isp-canvas/constants";
import { emptyMetricsProp, getters } from "../../blocks/lib/constants";
import { mergeChildMetrics } from "../../blocks/lib/blocks";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { Block } from "../../blocks/lib/types";
import {
  findBuildingMetrics,
  makeRendererMetrics,
} from "lib/metrics/renderer-metrics";
import { Team, useBlocksCtrl, useProjectCtrl } from "lib/containers";
import { useParams } from "react-router-dom";
import { encodeMetricId } from "lib/metrics/id";
import { Colors, ModernTabs, Switch, Type, Button } from "@outerlabs/ol-ui";
import { Tab } from "@outerlabs/ol-ui";
import {
  getNookDeskToDoor,
  getNooks,
  lowerLevelGetNookDeskToDoor,
} from "lib/metrics/nook-calculations";
import { Settings, Visibility, VisibilityOff } from "@material-ui/icons";
import { formatMetric } from "lib/number";
import {
  calculateBuildingDeskToDoor,
  calculateBuildingDeskToDoorWithNooks,
} from "lib/metrics/util";
import { getDesks } from "lib/metrics/desk-calculations";
import {
  getBlockAreas,
  getCommunalMetrics,
  getIndividualMetrics,
  getProgramDisplayName,
} from "lib/metrics/wpi-metric-helpers";
import { makeBlockInstance } from "blocks/lib/instance";
import { mat4 } from "gl-matrix";

const METRIC_QUARTER_DIV_WIDTH = 109.5;
const METRIC_QUARTER_DIV_SIDE_MARGIN = 16;

const useStyles = makeStyles({
  zoneHeaderContainer: {
    marginLeft: -16,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    height: "180px",
  },
  selectedCount: {
    fontStyle: "italic",
    fontSize: "12px",
    position: "relative",
    height: 20,
    top: -27,
    color: "#919499",
  },
  leftContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
  },
  radialPercentageIndicator: {
    width: "115px",
    height: "115px",
    borderRadius: "50%",
    border: "10px solid #FFBD02",
    position: "relative",
    top: "25px",
  },
  radialChartContainer: { position: "relative", top: "30px" },
  metricInsidePiechartContainer: {
    position: "relative",
    bottom: "65px",
    left: "0px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: 60,
  },
  metricValue: {
    fontFamily: "GoogleSansRegular",
    fontSize: 20,
    lineHeight: 1,
    color: "#555555",
  },
  metricTitle: {
    fontFamily: "GoogleSansRegular",
    fontSize: 12,
    color: "#919499",
  },
  rightContainer: {
    display: "flex",
    height: "100%",
    width: METRIC_QUARTER_DIV_WIDTH * 2,
  },
  column: {
    display: "flex",
    flexDirection: "column",
    margin: `0 ${METRIC_QUARTER_DIV_SIDE_MARGIN}px`,
    height: "100%",
    justifyContent: "space-between",
  },
  summaryValue: {
    height: "60px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginBottom: 16,
    width: METRIC_QUARTER_DIV_WIDTH - METRIC_QUARTER_DIV_SIDE_MARGIN * 2,
  },
  zoneSummaryTitle: {
    fontFamily: "GoogleSansRegular",
    fontSize: 14,
    lineHeight: "20px",
    color: "#878787",
    whiteSpace: "nowrap",
  },
  zoneSummaryNumber: {
    fontFamily: "GoogleSansRegular",
    fontSize: "32px",
    lineHeight: "37.5px",
    color: "#555555",
  },
  pieChartLegenContainer: {
    marginTop: "10px",
    width: "150px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  singleLegendContainer: {
    width: "150px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  colorCircle: {
    width: "9px",
    height: "9px",
    borderRadius: "50%",
  },
  legendName: {
    paddingLeft: "10px",
  },
  buttonContainer: {
    display: "flex",
    width: "100%",
    padding: "8px 0px 8px 8px",
    borderBottom: "2px #C1C4C9 solid",
    alignContent: "left",
  },
  groupDetailText: {},
  groupDetailItem: {
    display: "flex",
    justifyContent: "space-between",
    fontFamily: "GoogleSansRegular",
    fontWeight: 400,
    fontSize: 12,
    color: "#5F6166",
    padding: "8px 0 8px 0",
  },
  menu: {
    alignContent: "center",
    width: "285px",
    background: "white",
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    position: "relative",
    left: 460,
    top: 100,
  },
  menuHeader: {
    display: "flex",
    margin: "0px",
    padding: "16px 12px 0px 16px",
    color: "#919499",
    textTransform: "uppercase",
    fontWeight: 700,
    fontSize: "12px",
    lineHeight: "16px",
    flexDirection: "row",
    alignItems: "flex-start",
    gap: "10px",
    letterSpacing: "1.5px",
  },
  menuSubHeader: {
    display: "flex",
    margin: "0px",
    padding: "4px 0px 0px 12px",
    color: "#5F6166",
    fontSize: "12px",
  },
  menuItem: {
    width: "100%",
    padding: "12px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "left",
    textTransform: "capitalize",
  },
  menuItemText: { margin: 0, paddingLeft: "12px" },
  buttonActive: {
    background: "#2F73FF",
    borderRadius: "18px",
    color: "white",
    padding: "8px",
    textTransform: "none",
    fontSize: "12px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    gap: "8px",
    width: "120px",
  },
  button: {
    background: "#F0F5FF",
    borderRadius: "18px",
    color: "black",
    padding: "8px",
    textTransform: "none",
    fontSize: "12px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    gap: "8px",
    width: "120px",
  },
});

export type MMMetricTab = "building" | "floor" | "block";
export const tabs: MMMetricTab[] = ["building", "floor", "block"];

interface Props {
  blocks: Block[];
  teams: Team[];
}

const captionStyles = {
  marginLeft: 6,
  fontSize: "9.5px",
  lineHeight: 1.4,
  paddingTop: 6,
  color: Colors.Text.Light,
};

const assetColors = ["#A4C18A", "#F5DA86", "#ECCDA7"];
const assetNames = ["Desks", "Nooks", "Workpoints"];

type MetricKey =
  | "headcount"
  | "density"
  | "capacity"
  | "totalSeats"
  | "deskToDoorWithNooks"
  | "deskToDoorWithoutNooks";

type BlockMetricKey =
  | "blockTotalSeats"
  | "blockBlockCapacity"
  | "blockAssignableSeats"
  | "blockDensity"
  | "blockMRRWN"
  | "blockMRRWON"
  | "blockTotalArea";

const allMetricKeys: MetricKey[] = [
  "headcount",
  "density",
  "capacity",
  "totalSeats",
  "deskToDoorWithNooks",
  "deskToDoorWithoutNooks",
];

const allBlockMetricKeys: BlockMetricKey[] = [
  "blockTotalSeats",
  "blockBlockCapacity",
  "blockAssignableSeats",
  "blockDensity",
  "blockMRRWN",
  "blockMRRWON",
  "blockTotalArea",
];

const metricModalNames = [
  "Supported HC",
  "Density",
  "Total Capacity",
  "Total Seats",
  "Desk To Door (With Nooks)",
  "Desk To Door (Without Nooks)",
];

const blockMetricModalNames = [
  "Total Seats",
  "Block Capacity",
  "Assignable Seats",
  "Density",
  "Desk to Door (Incl. Nooks)",
  "Desk to Door",
  "Total Area",
];

const ZoneHeader: React.FC<Props> = ({ blocks, teams }) => {
  const classes = useStyles();
  const baseMetrics = { ...emptyMetricsProp };
  const [metricType, setMetricType] = React.useState<MMMetricTab>("block");
  const [displayKpis, setDisplayKpis] = React.useState(false);
  const { project } = useProjectCtrl();
  const { getBlockById } = useBlocksCtrl();

  const { buildingID, strategyID, floorID } = useParams<{
    [key: string]: string;
  }>();
  const id = encodeMetricId(buildingID, strategyID, floorID);
  let metrics = baseMetrics;
  let communalData = {
    seats: 0,
    headcount: 0,
    workpoints: 0,
    area: 0,
    density: 0,
  };
  let individualData = communalData;

  const instancedBlocks: Block[][] = [];

  blocks.forEach((block) => {
    const { sizeRange } = getters.getMetrics(block);

    // we instance the block here in order to get communal/individual
    // data on the block. if we keep this instancing within this file long-term,
    // we may want to clean up some of the block instancing that happens
    // in `matchMaker.ts`
    const instance = makeBlockInstance(
      block,
      mat4.create(),
      [sizeRange[0][0], sizeRange[1][1]], // probably need to rethink this
      getBlockById
    );
    metrics = mergeChildMetrics([metrics, getters.getMetrics(instance)]);
    instancedBlocks.push([instance]);
  });

  let activityData = [];
  communalData = getCommunalMetrics(instancedBlocks);
  individualData = getIndividualMetrics(instancedBlocks);

  // prepopulate the radial chart with a breakdown of program categories
  // if a block has no individual or collab teamspace, show the breakdown of
  // different WPI program types within the block, but gray
  const blockAreas = getBlockAreas([blocks]);
  for (const programType in blockAreas) {
    if (blockAreas[programType].total !== 0) {
      activityData.push({
        angle: blockAreas[programType].total,
        color: "#E9E9EB", // light gray
        label: getProgramDisplayName(programType),
      });
    }
  }

  if (communalData.area !== 0 || individualData.area !== 0) {
    activityData = [];

    if (communalData.area !== 0) {
      activityData.push({
        angle: communalData.area,
        color: colors.collabColor,
        label: "Communal",
      });
    }

    if (individualData.area !== 0) {
      activityData.push({
        angle: individualData.area,
        color: colors.focusColor,
        label: "Individual",
      });
    }
  }

  // we ignore any area not designated as either communal or individual
  const ratioCommunal = Math.round(
    (communalData.area / (individualData.area + communalData.area)) * 100
  );
  const ratioIndividual = 100 - ratioCommunal;

  let teamsHC = 0;
  let teamsDailyPopulation = 0;
  let teamsGroupCapactiy = 0;

  // eslint-disable-next-line array-callback-return
  teams.map((team) => {
    const dailyFrequent =
      Math.ceil(team.userProfilePercentages.Frequent * team.headcount) / 100;
    const groupFrequent =
      Math.ceil(team.userProfilePercentages.Frequent * team.headcount) / 100;
    const dailyInfrequent = Math.ceil(
      (team.userProfilePercentages.Infrequent * team.headcount) / 100 / 5
    );
    const groupInfrequent = Math.ceil(
      (team.userProfilePercentages.Infrequent * team.headcount) / 100
    );
    const dailyOccasional = Math.ceil(
      (team.userProfilePercentages.Occasional * team.headcount) / 100 / 2
    );
    const groupOccasional = Math.ceil(
      (team.userProfilePercentages.Occasional * team.headcount) / 100
    );
    const dailyPopulation = dailyFrequent + dailyInfrequent + dailyOccasional;
    const groupCapacity = groupFrequent + groupInfrequent + groupOccasional;
    teamsDailyPopulation += dailyPopulation;
    teamsGroupCapactiy += groupCapacity;
    teamsHC += team.headcount;
  });

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const metric = project!.metrics[id];
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const buildingMetrics = findBuildingMetrics(project!, buildingID, strategyID);
  const reducedBuildingMetrics = makeRendererMetrics(buildingMetrics);
  const floorMetricsSubset = makeRendererMetrics([metric]);
  const [showGroupDetails, setShowGroupDetails] = React.useState(false);
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [selectedArr, setSelectedArr] = React.useState<MetricKey[]>([
    "density",
    "headcount",
    "deskToDoorWithNooks",
    "deskToDoorWithoutNooks",
  ]);
  const [blockSelectedArr, setBlockSelectedArr] = React.useState<
    BlockMetricKey[]
  >(allBlockMetricKeys.slice(0, 4));

  let supportedHC = 0;
  let density = 0;
  let deskToDoorNooks;
  let deskToDoorNoNooks;
  let assetValues: number[] = [];
  let totalCapacity = 0;
  let totalSeats = 0;

  // block specific metrics :DD
  let blockTotalSeats = 0;
  let blockBlockCapacity = 0;
  let blockAssignableSeats = 0;
  let blockDensity = 0;
  let blockMRRWN: string | number = 0;
  let blockMRRWON = "-";
  let blockTotalArea = 0;

  const groupNooks = getNooks([blocks]);
  const groupDesks = getDesks([blocks]);
  let groupSeats = 0;

  blocks.forEach((block) => {
    groupSeats += block.props.metrics?.seats || 0;
  });

  switch (metricType) {
    case "building": {
      const buildingNooks = buildingMetrics
        .map((bm) => bm.nooksCount || 0)
        .reduce((prev, curr) => prev + curr, 0);
      supportedHC =
        reducedBuildingMetrics.seatCount + reducedBuildingMetrics.workpoints;
      density = reducedBuildingMetrics.density;
      deskToDoorNooks = formatMetric(
        calculateBuildingDeskToDoorWithNooks(buildingMetrics),
        2
      );
      deskToDoorNoNooks = formatMetric(
        calculateBuildingDeskToDoor(buildingMetrics),
        2
      );
      assetValues = [
        reducedBuildingMetrics.seatCount,
        buildingNooks,
        reducedBuildingMetrics.workpoints,
      ];
      totalCapacity = reducedBuildingMetrics.seatCount;
      totalSeats = reducedBuildingMetrics.seatCount;
      break;
    }
    case "floor": {
      supportedHC =
        floorMetricsSubset.seatCount + floorMetricsSubset.workpoints;
      density = floorMetricsSubset.density;
      deskToDoorNooks = formatMetric(getNookDeskToDoor(metric), 2);
      deskToDoorNoNooks = formatMetric(floorMetricsSubset.deskToDoor, 2);
      assetValues = [
        floorMetricsSubset.seatCount,
        metric.nooksCount,
        floorMetricsSubset.workpoints,
      ];
      totalCapacity = floorMetricsSubset.seatCount;
      totalSeats = floorMetricsSubset.seatCount;
      break;
    }
    case "block": {
      supportedHC = metrics.seats + metrics.workpoints;
      density = metrics.area / 144 / metrics.seats;
      deskToDoorNooks = formatMetric(
        lowerLevelGetNookDeskToDoor(groupNooks, supportedHC, 0),
        2
      );
      deskToDoorNoNooks = "-";
      assetValues = [groupDesks, groupNooks, metrics.workpoints];
      totalCapacity = groupNooks + groupDesks + metrics.workpoints;
      totalSeats = metrics.seats;

      // block metrics stuff
      blockTotalSeats = groupSeats;
      blockBlockCapacity = groupDesks + groupNooks + metrics.workpoints;
      blockAssignableSeats = groupDesks + groupNooks;
      blockDensity = metrics.area / 144 / groupSeats;
      blockMRRWN = formatMetric(
        lowerLevelGetNookDeskToDoor(groupNooks, supportedHC, 0),
        2
      );
      blockMRRWON = "-";
      blockTotalArea = metrics.area / 144;
      break;
    }
  }

  // assetValues = assetValues.reverse();

  const assetData = assetValues.map((a, idx) => ({
    angle: a,
    color: assetColors[idx],
  }));

  // eslint-disable-next-line @typescript-eslint/ban-types
  const eHandler: any = (event: ChangeEvent<{}>, newValue: any) => {
    setMetricType(tabs[newValue]);
  };

  const BuildingFloorGroupModal = (
    <Modal
      keepMounted={true}
      open={openModal}
      onClose={() => {
        setOpenModal(false);
      }}
      disableBackdropClick={false}
      closeAfterTransition={false}
      BackdropProps={{
        timeout: 500,
        invisible: true,
      }}
    >
      <Box className={classes.menu}>
        <div className={classes.menuHeader}>Performance Metrics</div>
        <div className={classes.buttonContainer}>
          <ModernTabs
            indicatorColor="primary"
            textColor="primary"
            value={metricType}
            // eslint-disable-next-line @typescript-eslint/ban-types
            onChange={eHandler}
          >
            {tabs.map((t) => (
              <Tab
                format="modern"
                value={t}
                onClick={() => setMetricType(t)}
                label={t}
                style={{ textTransform: "capitalize" }}
              />
            ))}
          </ModernTabs>
        </div>

        <div className={classes.menuSubHeader}>Select up to 4 metrics</div>
        {metricType === "block" ? (
          <Box display="flex" flexDirection="column" alignItems="baseline">
            {allBlockMetricKeys.map((mk, idx) => {
              const selected = blockSelectedArr.includes(mk);
              const disabled =
                blockSelectedArr.length === 4 && !blockSelectedArr.includes(mk);
              let color = "black";
              if (selected && !disabled) {
                color = Colors.Text.Dark;
              } else if (!selected && !disabled) {
                color = Colors.Text.Light;
              } else {
                color = Colors.Text.Disabled;
              }
              return (
                <CoreButton
                  onClick={() => {
                    if (blockSelectedArr.indexOf(mk) > -1) {
                      blockSelectedArr.splice(blockSelectedArr.indexOf(mk), 1);
                      setBlockSelectedArr([...blockSelectedArr]);
                    } else {
                      setBlockSelectedArr([...blockSelectedArr, mk]);
                    }
                  }}
                  disabled={disabled}
                  className={classes.menuItem}
                  style={{ color }}
                >
                  {selected ? <Visibility /> : <VisibilityOff />}{" "}
                  <div className={classes.menuItemText}>
                    {blockMetricModalNames[idx]}
                  </div>
                </CoreButton>
              );
            })}
          </Box>
        ) : (
          <Box display="flex" flexDirection="column" alignItems="baseline">
            {allMetricKeys.map((mk, idx) => {
              const selected = selectedArr.includes(mk);
              const disabled =
                selectedArr.length === 4 && !selectedArr.includes(mk);
              let color = "black";
              if (selected && !disabled) {
                color = Colors.Text.Dark;
              } else if (!selected && !disabled) {
                color = Colors.Text.Light;
              } else {
                color = Colors.Text.Disabled;
              }
              return (
                <CoreButton
                  onClick={() => {
                    if (selectedArr.indexOf(mk) > -1) {
                      selectedArr.splice(selectedArr.indexOf(mk), 1);
                      setSelectedArr([...selectedArr]);
                    } else {
                      setSelectedArr([...selectedArr, mk]);
                    }
                  }}
                  disabled={disabled}
                  className={classes.menuItem}
                  style={{ color }}
                >
                  {selected ? <Visibility /> : <VisibilityOff />}{" "}
                  <div className={classes.menuItemText}>
                    {metricModalNames[idx]}
                  </div>
                </CoreButton>
              );
            })}
          </Box>
        )}
      </Box>
    </Modal>
  );

  const headerWrapper = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const divSize = { minHeight: 70 };

  const assetTotal = assetValues.reduce((prev, curr) => prev + curr, 0);

  const hcDiv = (
    <div style={divSize}>
      <div className={classes.zoneSummaryTitle}>Supported HC</div>
      <div className={classes.zoneSummaryNumber}>{supportedHC}</div>
    </div>
  );
  const tcapacityDiv = (
    <div style={divSize}>
      <div className={classes.zoneSummaryTitle}>Total Capacity</div>
      <div className={classes.zoneSummaryNumber}>{totalCapacity}</div>
    </div>
  );
  const seatDiv = (
    <div style={divSize}>
      <div className={classes.zoneSummaryTitle}>Seat Count</div>
      <div className={classes.zoneSummaryNumber}>{totalSeats}</div>
    </div>
  );
  const dtdwn = (
    <div style={divSize}>
      <div className={classes.zoneSummaryTitle}>Desk to Door</div>
      <div className={classes.zoneSummaryNumber} style={headerWrapper}>
        {deskToDoorNooks}
        <Type.Caption style={captionStyles}>With Nooks</Type.Caption>
      </div>
    </div>
  );
  const densityDiv = (
    <div style={divSize}>
      <div className={classes.zoneSummaryTitle}>Density</div>
      <div className={classes.zoneSummaryNumber} style={headerWrapper}>
        {density.toFixed(0)}
        <Type.Caption style={captionStyles}>
          NOA/
          <br />
          Assign.
          <br />
          Seats
        </Type.Caption>
      </div>
    </div>
  );
  const dtdnn = (
    <div style={divSize}>
      <div className={classes.zoneSummaryTitle}>Desk to Door</div>
      <div className={classes.zoneSummaryNumber} style={headerWrapper}>
        {deskToDoorNoNooks}
        <Type.Caption style={captionStyles}>Without Nooks</Type.Caption>
      </div>
    </div>
  );

  //block metrics things here
  const blockSeatsDiv = (
    <div style={divSize}>
      <div className={classes.zoneSummaryTitle}>Total Seats</div>
      <div className={classes.zoneSummaryNumber}>{blockTotalSeats}</div>
    </div>
  );
  const blockCapacityDiv = (
    <div style={divSize}>
      <div className={classes.zoneSummaryTitle}>Block Capacity</div>
      <div className={classes.zoneSummaryNumber}>{blockBlockCapacity}</div>
    </div>
  );
  const blockAssignableDiv = (
    <div style={divSize}>
      <div className={classes.zoneSummaryTitle}>Assignable Seats</div>
      <div className={classes.zoneSummaryNumber}>{blockAssignableSeats}</div>
    </div>
  );
  const blockDensityDiv = (
    <div style={divSize}>
      <div className={classes.zoneSummaryTitle}>Density</div>
      <div className={classes.zoneSummaryNumber} style={headerWrapper}>
        {blockDensity.toFixed(0)}
        <Type.Caption style={captionStyles}>
          NOA/
          <br />
          Assign.
          <br />
          Seats
        </Type.Caption>
      </div>
    </div>
  );
  const blockMMRWNDiv = (
    <div style={divSize}>
      <div className={classes.zoneSummaryTitle}>Desk to Door</div>
      <div className={classes.zoneSummaryNumber} style={headerWrapper}>
        {blockMRRWN}
        <Type.Caption style={captionStyles}>With Nooks</Type.Caption>
      </div>
    </div>
  );
  const blockMMRWONDiv = (
    <div style={divSize}>
      <div className={classes.zoneSummaryTitle}>Desk to Door</div>
      <div className={classes.zoneSummaryNumber} style={headerWrapper}>
        {blockMRRWON}
        <Type.Caption style={captionStyles}>Without Nooks</Type.Caption>
      </div>
    </div>
  );
  const totalBlockAreaDiv = (
    <div style={divSize}>
      <div className={classes.zoneSummaryTitle}>Total Area</div>
      <div className={classes.zoneSummaryNumber}>
        {Math.floor(blockTotalArea)}
        <Type.Caption style={captionStyles}>SF</Type.Caption>
      </div>
    </div>
  );

  const divs: React.ReactNode[] = [];
  if (metricType === "block") {
    blockSelectedArr.forEach((blockSelectedMetric) => {
      switch (blockSelectedMetric) {
        case "blockTotalSeats":
          divs.push(blockSeatsDiv);
          break;
        case "blockBlockCapacity":
          divs.push(blockCapacityDiv);
          break;
        case "blockAssignableSeats":
          divs.push(blockAssignableDiv);
          break;
        case "blockDensity":
          divs.push(blockDensityDiv);
          break;
        case "blockMRRWN":
          divs.push(blockMMRWNDiv);
          break;
        case "blockMRRWON":
          divs.push(blockMMRWONDiv);
          break;
        case "blockTotalArea":
          divs.push(totalBlockAreaDiv);
          break;
      }
    });
  } else {
    selectedArr.forEach((selectedMetric) => {
      switch (selectedMetric) {
        case "headcount":
          divs.push(hcDiv);
          break;
        case "density":
          divs.push(densityDiv);
          break;
        case "capacity":
          divs.push(tcapacityDiv);
          break;
        case "totalSeats":
          divs.push(seatDiv);
          break;
        case "deskToDoorWithNooks":
          divs.push(dtdwn);
          break;
        case "deskToDoorWithoutNooks":
          divs.push(dtdnn);
          break;
      }
    });
  }

  return (
    <>
      <Button.Sm.Sqr.Ghosted
        onClick={() => setOpenModal(!openModal)}
        style={{
          position: "absolute",
          top: 10,
          right: 20,
          cursor: "pointer",
          zIndex: 1000,
          color: Colors.Text.Light,
          textTransform: "capitalize",
        }}
        iconRight={<Settings />}
      >
        {metricType}
      </Button.Sm.Sqr.Ghosted>
      {BuildingFloorGroupModal}
      <div>
        <div className={classes.zoneHeaderContainer}>
          <div className={classes.leftContainer}>
            <div className={classes.radialChartContainer}>
              <RadialChart
                // https://app.shortcut.com/ol-google/story/31095/
                data={displayKpis ? assetData : activityData}
                width={130}
                height={130}
                radius={60}
                innerRadius={50}
                padAngle={0.05}
                colorType="literal"
              />
            </div>
            <div className={classes.metricInsidePiechartContainer}>
              <div className={classes.metricValue}>
                {displayKpis
                  ? assetValues
                      .map((av) => ((av / (assetTotal || 1)) * 100).toFixed(0))
                      .join("/")
                  : `${ratioIndividual}/${ratioCommunal}`}
              </div>

              <div className={classes.metricTitle}>
                {displayKpis ? (
                  "Desk/Nook/WP"
                ) : (
                  <span>
                    Individual/
                    <br />
                    Communal
                  </span>
                )}
              </div>
            </div>
            <div className={classes.selectedCount}>
              {!displayKpis && blocks.length > 1 && (
                <span>{blocks.length} Blocks Selected.</span>
              )}
            </div>
          </div>

          <div className={classes.rightContainer}>
            {displayKpis ? (
              <div style={{ margin: `0 ${METRIC_QUARTER_DIV_SIDE_MARGIN}px` }}>
                <Type.Subtitle1 style={{ color: Colors.Text.Light }}>
                  Total Desks + Nooks
                </Type.Subtitle1>
                <Type.H4
                  style={{
                    lineHeight: 1.1,
                    marginBottom: 8,
                    marginTop: 0,
                    color: Colors.Text.Medium,
                  }}
                >
                  {assetValues[0] + assetValues[1]}
                </Type.H4>
                {assetData.map((ad, idx) => {
                  return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        style={{
                          backgroundColor: assetColors[idx],
                          borderRadius: 100,
                          height: 8,
                          width: 8,
                          margin: "6px 8px 6px 0",
                        }}
                      />
                      <Type.Body2
                        style={{ color: Colors.Text.Light, lineHeight: 1.3 }}
                      >
                        {assetNames[idx]}: {ad.angle}
                      </Type.Body2>
                    </div>
                  );
                })}
              </div>
            ) : (
              <>
                <div className={classes.column}>
                  <div className={classes.summaryValue}>
                    {divs[0]}
                    <div style={{ minHeight: 16 }} />
                    {divs[2]}
                  </div>
                </div>
                <div className={classes.column}>
                  <div className={classes.summaryValue}>
                    {divs[1]}
                    <div style={{ minHeight: 16 }} />
                    {divs[3]}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          position: "relative",
          left: -10,
          right: -10,
          width: "calc(100% + 20px)",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            color: "#1B1D21",
            position: "relative",
            top: 14,
            marginBottom: 14,
          }}
        >
          <Switch
            checked={displayKpis}
            onChange={() => setDisplayKpis(!displayKpis)}
            color="primary"
          />
          {displayKpis ? "Assets" : "Activities"}
        </div>
        <Button.Sm.Sqr.Ghosted
          style={{ position: "relative", top: 8 }}
          iconRight={<ExpandMoreIcon />}
          onClick={() => setShowGroupDetails(!showGroupDetails)}
        >
          Group Details
        </Button.Sm.Sqr.Ghosted>
      </div>
      <Collapse in={showGroupDetails}>
        <div className={classes.groupDetailItem}>
          <div className={classes.groupDetailText}>Supported HC</div>
          <div className={classes.groupDetailText}>{teamsHC}</div>
        </div>
        <div className={classes.groupDetailItem}>
          <div className={classes.groupDetailText}>Daily Population</div>
          <div className={classes.groupDetailText}>
            {Math.round(teamsDailyPopulation)}
          </div>
        </div>
        <div className={classes.groupDetailItem}>
          <div className={classes.groupDetailText}>Density (NOA/Seats)</div>
          <div className={classes.groupDetailText}>{`${Math.round(
            metrics.area / 144 / metrics.seats
          )}`}</div>
        </div>
        <div className={classes.groupDetailItem}>
          <div className={classes.groupDetailText}>
            Density (NOA/Group Capacity)
          </div>
          <div className={classes.groupDetailText}>{`${Math.round(
            metrics.area / 144 / teamsGroupCapactiy
          )}`}</div>
        </div>
        <div className={classes.groupDetailItem}>
          <div className={classes.groupDetailText}>
            Density (NOA/Assignable Seats)
          </div>
          <div className={classes.groupDetailText}>{`${Math.round(
            metrics.area / 144 / (groupDesks + groupNooks)
          )}`}</div>
        </div>
      </Collapse>
    </>
  );
};

export default React.memo(ZoneHeader);
