import React from "react";
import { Canvas } from "@outerlabs/canvas-reconciler";
import { renderCirculationPath } from "lib/isp-canvas";
import { RendererSettings } from "lib/types";
import { EdgeValue, Graph } from "lib/metrics/buzz";

interface Props {
  settings?: RendererSettings;
  defaultCirculation?: Graph;
  calculatedBuzz: EdgeValue[];
}

export const CirculationPath: React.FC<Props> = ({
  settings,
  defaultCirculation,
}) => {
  const canvas = (
    <Canvas
      render={(ctx: CanvasRenderingContext2D) => {
        if (settings && settings.circulation) {
          renderCirculationPath(ctx, settings.circulation, false);
        } else if (defaultCirculation) {
          renderCirculationPath(ctx, defaultCirculation, false);
        }
      }}
    />
  );

  return <>{canvas}</>;
};

export default React.memo(CirculationPath);
