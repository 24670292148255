import React from "react";
import { Canvas } from "@outerlabs/canvas-reconciler";
import { Background as BackgroundType } from "lib/types";
import backgroundRenderer from "lib/isp-canvas/backgroundRenderer";

interface Props {
  background: BackgroundType | undefined;
  offsetX: number;
  offsetY: number;
}

export const Background: React.FC<Props> = ({
  background,
  offsetX,
  offsetY,
}) => {
  const canvas = (
    <Canvas
      render={(ctx: CanvasRenderingContext2D) => {
        if (background) backgroundRenderer(ctx, background, offsetX, offsetY);
      }}
    />
  );

  return <>{canvas}</>;
};

export default React.memo(Background);
