import React, { ChangeEvent, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Sidebar from "../components/sidebar";
import { useHistory } from "react-router-dom";
import { SideNavBar, NavButton } from "@outerlabs/ol-ui";
import { defaultValues } from "../../lib/constants";
import Nav from "../components/nav";
import { EmojiObjects } from "@material-ui/icons";
import { useBlocksCtrl } from "../../lib/containers";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "../components/accordion";
import Grid from "@material-ui/core/Grid";
import NumberField from "../components/number-field";
import {
  FormControl,
  FormLabel,
  InputLabel,
  MenuItem,
  Slider,
} from "@material-ui/core";
import Select from "../components/select";
import Tags from "../components/tags";
import { Block, BlockGetter, Range } from "../lib/types";
import BlockList from "../components/block-list";
import { getters } from "../lib/constants";
import { Point } from "@outerlabs/shapes-geometry";
import { createBlockImage } from "../../lib/isp-canvas";
import { formatInches } from "../lib/canvas";
import CanvasView from "../components/canvas-view";
import AutoSizer from "react-virtualized-auto-sizer";
import { makeBlockInstance } from "../lib/instance";
import { mat4 } from "gl-matrix";
import { useGAPageView } from "../../lib/hooks/use-ga";

const useStyles = makeStyles(() => ({
  canvas: {
    width: "calc(100% - 300px)",
    height: "100%",
    background: "rgba(0,0,0,0.04)",
  },
  helpContainer: {
    position: "absolute",
    top: "20px",
    right: "20px",
    zIndex: 999999,
  },
  container: {
    width: "100vw",
    height: "100vh",
  },
  sidebar: {
    display: "fixed",
    top: 0,
    bottom: 0,
    width: 400,
    height: "100%",
    backgroundColor: "white",
    overflow: "auto",
  },
  row: {
    marginBottom: 12,
  },
  sliderValue: {
    position: "absolute",
    color: "#999999",
    fontSize: "0.8rem",
    right: 0,
  },
  autoMagicResults: {
    width: 300,
  },
}));

type Search = {
  headcount: number[];
  tags: string[];
  library: string[];
  ratio: number;
  density: number;
};

type MatchMaker = {
  teams: Team[];
  density: number;
};

type Team = {
  headcount: number;
  ratio: number;
};

type AutoMagic = {
  width: number;
  height: number;
  ratio: number;
  density: number;
};

const makeSearch = (): Search => ({
  headcount: [0, 100],
  tags: [],
  library: [],
  ratio: 0.5,
  density: 1,
});

const makeAutoMagic = (): AutoMagic => ({
  width: 28 * 12,
  height: 24 * 12,
  ratio: 0.5,
  density: 1,
});
const makeMatchMaker = (): MatchMaker => ({
  teams: [
    { headcount: 10, ratio: 0.5 },
    { headcount: 8, ratio: 1 },
  ],
  density: 1,
});

const filterSearch = (blocks: Block[], data: Search): Block[] => {
  const filtered = blocks.filter((block) => {
    if (
      data.library.length &&
      (!block.props.definition?.library ||
        data.library.indexOf(block.props.definition.library) === -1)
    )
      return false;
    if (
      data.tags.length &&
      (!block.props.definition?.tags ||
        block.props.definition.tags.some(
          (t) => t && data.tags.indexOf(t) === -1
        ))
    )
      return false;
    return true;
  });
  return filtered;
};

const filterMatchMaker = (blocks: Block[], data: MatchMaker): Block[][] => {
  // TODO: cache
  const metrics = blocks.map((block) => ({
    block,
    metrics: getters.getMetrics(block),
  }));
  const matches = data.teams.map((team) => {
    const filtered = metrics
      .filter(({ block }) => {
        return (
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          block.props.metrics!.seatsRange![0]! <= team.headcount &&
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          block.props.metrics!.seatsRange![1]! >= team.headcount
        );
      })
      .sort((a, b) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const fa = it.only(a.metrics, team.ratio);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const fb = it.only(b.metrics, team.ratio);
        return fa > fb ? 1 : fa < fb ? -1 : 0;
      })
      .map(({ block }) => block);
    return filtered;
  });
  return matches;
};

const filterAutoMagic = (blocks: Block[], data: AutoMagic): Block[] => {
  return blocks
    .filter((block) => {
      if (block.role === "asset") return false;
      const { sizeRange } = getters.getMetrics(block);
      const { flexibility } = getters.getLayout(block);
      return (
        sizeRange[0][0] <= data.width &&
        sizeRange[0][1] + (flexibility ? flexibility[0] : 0) >= data.width &&
        sizeRange[1][0] <= data.height &&
        sizeRange[1][1] + (flexibility ? flexibility[1] : 0) >= data.height
      );
    })
    .sort((a, b) => {
      const ma = getters.getMetrics(a);
      const mb = getters.getMetrics(b);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const fa = it.only(ma, data.ratio);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const fb = it.only(mb, data.ratio);
      return fa > fb ? 1 : fa < fb ? -1 : 0;
    });
};

const BlockSandbox: React.FC = () => {
  const { blocks } = useBlocksCtrl();
  const classes = useStyles();
  const history = useHistory();
  const [expanded, setExpanded] = React.useState<string | false>("automagic");
  const [search, setSearch] = React.useState<Search>(makeSearch());
  const [searchBlocks, setSearchBlocks] = React.useState<Block[]>([]);
  const [autoMagicBlocks, setAutoMagicBlocks] = React.useState<Block[]>([]);
  const [matchMakerBlocks, setMatchMakerBlocks] = React.useState<Block[][]>([]);
  const [autoMagic, setAutoMagic] = React.useState<AutoMagic>(makeAutoMagic());
  const [autoMagicBlockId, setAutoMagicBlockId] = React.useState<string | null>(
    null
  );
  const [matchMaker, setMatchMaker] = React.useState<MatchMaker>(
    makeMatchMaker()
  );
  const [libraryOptions, setLibraryOptions] = React.useState<string[]>([]);
  const [numTeams, setNumTeams] = React.useState<number>(2);
  useGAPageView("Block Sandbox");
  useEffect(() => {
    const optionMap: { [k: string]: boolean } = {};
    blocks.forEach((block) => {
      if (block.props.definition && block.props.definition.library) {
        optionMap[block.props.definition.library] = true;
      }
    });
    setLibraryOptions(Object.keys(optionMap));
  }, [blocks]);
  useEffect(
    () => setSearchBlocks(filterSearch(blocks, search)),
    [search, blocks]
  );
  useEffect(
    () => setMatchMakerBlocks(filterMatchMaker(blocks, matchMaker)),
    [matchMaker, blocks]
  );
  useEffect(() => {
    const filtered = filterAutoMagic(blocks, autoMagic);
    if (!autoMagicBlockId) {
      if (filtered.length > 0) {
        setAutoMagicBlockId(filtered[0].id);
      }
    } else {
      const found = filtered.find((el) => el.id === autoMagicBlockId);
      if (!found) {
        if (filtered.length > 0) {
          setAutoMagicBlockId(filtered[0].id);
        } else {
          setAutoMagicBlockId(null);
        }
      }
    }
    setAutoMagicBlocks(filtered);
  }, [autoMagic, blocks, autoMagicBlockId]);
  useEffect(() => {
    setMatchMaker((_matchMaker) => {
      if (numTeams > _matchMaker.teams.length) {
        const added: Team[] = [];
        for (let i = _matchMaker.teams.length; i < numTeams; i++) {
          added.push({
            ratio: Math.random(),
            headcount: Math.floor(Math.random() * 10) + 6,
          });
        }
        return { ..._matchMaker, teams: [..._matchMaker.teams, ...added] };
      } else {
        return { ..._matchMaker, teams: _matchMaker.teams.slice(0, numTeams) };
      }
    });
  }, [numTeams]);
  const onAccordionChange =
    (panel: string) => (event: ChangeEvent<any>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  const onChangeSearch = (k: string, v: any) =>
    setSearch({ ...search, [k]: v });
  const onChangeAutoMagic = (k: string, v: any) =>
    setAutoMagic({ ...autoMagic, [k]: v });

  const onChangeTeam = (i: number, team: Team) => {
    const teams = matchMaker.teams.slice();
    teams.splice(i, 1, team);
    setMatchMaker({ ...matchMaker, teams });
  };
  const onMultipleChange =
    (fn: (value: string[]) => void) =>
    (event: React.ChangeEvent<{ value: unknown }>) =>
      fn(event.target.value as string[]);
  const onLibraryChange = onMultipleChange((v) => onChangeSearch("library", v));
  const onTagsChange = (v: string[]) => onChangeSearch("tags", v);

  return (
    <div className={classes.container} style={{ display: "flex" }}>
      <SideNavBar
        appIconAlt={"portfolioIcon"}
        appIconSrc={defaultValues.blockIconSrc}
        appIconClick={() => history.push(`/blocks`)}
        footerButton={
          <NavButton
            tooltipTitle="Sandbox"
            selected={window.location.pathname.startsWith("/sandbox")}
            onClick={() => {
              history.push(`/sandbox`);
            }}
          >
            <EmojiObjects />
          </NavButton>
        }
      >
        <Nav navTab={"sandbox"} />
      </SideNavBar>
      <div className={classes.sidebar}>
        <Sidebar title={"Block Sandbox"} subtitle={""}>
          <Accordion
            expanded={expanded === "search"}
            onChange={onAccordionChange("search")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              Search
            </AccordionSummary>
            <AccordionDetails>
              <Grid container={true} spacing={2} className={classes.row}>
                <Grid item={true} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel shrink={true} id="program-label">
                      Library
                    </InputLabel>
                    <Select
                      label="Library"
                      placeholder={"Library"}
                      multiple={true}
                      onChange={onLibraryChange}
                      value={search.library}
                    >
                      {libraryOptions.map((option) => (
                        <MenuItem value={option}>{option}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item={true} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel shrink={true} id="program-label">
                      Tags
                    </InputLabel>
                    <Tags
                      options={[
                        "Client Facing",
                        "Sprint Planning",
                        "Workshops",
                      ]}
                      value={search.tags}
                      onChange={onTagsChange}
                    />
                  </FormControl>
                </Grid>

                <Grid item={true} xs={6}>
                  <NumberField
                    label={"HC Min"}
                    value={search.headcount[0]}
                    onChange={(v) =>
                      onChangeSearch("headcount", [v, search.headcount[1]])
                    }
                    positive={true}
                    min={1}
                    max={10}
                  />
                </Grid>
                <Grid item={true} xs={6}>
                  <NumberField
                    label={"HC Max"}
                    value={search.headcount[1]}
                    onChange={(v) =>
                      onChangeSearch("headcount", [search.headcount[0], v])
                    }
                    positive={true}
                    min={1}
                    max={10}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel shrink={true}>Ratio</InputLabel>
                    <Slider
                      min={0}
                      max={1}
                      style={{ marginTop: 16 }}
                      step={0.01}
                      value={search.ratio}
                      onChange={(e, v) => {
                        if (!Array.isArray(v)) onChangeSearch("ratio", v);
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel shrink={true}>Density</InputLabel>
                    <Slider
                      min={0}
                      max={1}
                      style={{ marginTop: 16 }}
                      step={0.01}
                      value={search.density}
                      onChange={(e, v) => {
                        if (!Array.isArray(v)) onChangeSearch("density", v);
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "matchmaker"}
            onChange={onAccordionChange("matchmaker")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              MatchMaker
            </AccordionSummary>
            <AccordionDetails>
              <NumberField
                label={"Teams"}
                value={matchMaker.teams.length}
                onChange={(v) => setNumTeams(v)}
                positive={true}
                min={1}
                max={6}
              />
              {matchMaker.teams.map((team, i) => (
                <Grid container spacing={2} style={{ marginTop: 12 }}>
                  <Grid item xs={3}>
                    <div style={{ fontSize: "1rem", paddingTop: 16 }}>
                      Team {i + 1}
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <NumberField
                      label={"Headcount"}
                      value={team.headcount}
                      onChange={(v) =>
                        onChangeTeam(i, { ...team, headcount: v })
                      }
                      positive={true}
                      min={1}
                      max={20}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel shrink={true}>Ratio</InputLabel>
                      <Slider
                        min={0}
                        max={1}
                        style={{ marginTop: 16 }}
                        step={0.01}
                        value={team.ratio}
                        onChange={(e, v) => {
                          if (!Array.isArray(v)) {
                            onChangeTeam(i, { ...team, ratio: v });
                          }
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              ))}
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "automagic"}
            onChange={onAccordionChange("automagic")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              AutoMagic
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <FormLabel>Width</FormLabel>
                    <div className={classes.sliderValue}>
                      {formatInches(autoMagic.width)}
                    </div>
                    <Slider
                      min={60}
                      max={600}
                      value={autoMagic.width}
                      onChange={(e, v) => onChangeAutoMagic("width", v)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <FormLabel>Height</FormLabel>
                    <div className={classes.sliderValue}>
                      {formatInches(autoMagic.height)}
                    </div>
                    <Slider
                      min={60}
                      max={600}
                      value={autoMagic.height}
                      onChange={(e, v) => onChangeAutoMagic("height", v)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <FormLabel>Ratio</FormLabel>
                    <div className={classes.sliderValue}>{autoMagic.ratio}</div>
                    <Slider
                      min={0}
                      max={1}
                      step={0.01}
                      value={autoMagic.ratio}
                      onChange={(e, v) => onChangeAutoMagic("ratio", v)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <FormLabel>Density</FormLabel>
                    <div className={classes.sliderValue}>
                      {autoMagic.density}
                    </div>
                    <Slider
                      min={0}
                      max={1}
                      step={0.01}
                      value={autoMagic.density}
                      onChange={(e, v) => onChangeAutoMagic("density", v)}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Sidebar>
      </div>
      <div>
        {expanded === "search" && (
          <BlockList blocks={searchBlocks} onClick={() => {}} />
        )}
      </div>
      {expanded === "matchmaker" && (
        <div style={{ display: "flex", width: "calc(100vw - 465px)" }}>
          <BlockComboList
            blocks={matchMakerBlocks}
            mm={matchMaker}
            onClick={() => {}}
          />
        </div>
      )}
      {expanded === "automagic" && (
        <div style={{ display: "flex", width: "calc(100vw - 465px)" }}>
          <>
            <div className={classes.canvas}>
              <AutoSizer>
                {({ height, width }) => {
                  return (
                    <CanvasView
                      block={
                        autoMagicBlockId
                          ? autoMagicBlocks.find(
                              (el) => el.id === autoMagicBlockId
                            )
                          : undefined
                      }
                      size={[autoMagic.width, autoMagic.height]}
                      width={width}
                      height={height}
                    />
                  );
                }}
              </AutoSizer>
            </div>
            <div className={classes.autoMagicResults}>
              <BlockList
                blocks={autoMagicBlocks}
                onClick={(id) => {
                  setAutoMagicBlockId(id);
                }}
              />
            </div>
          </>
        </div>
      )}
    </div>
  );
};

const computeRegion = (
  block: Block,
  getBlockById: BlockGetter,
  previewSize: number,
  sizeRange: [Range, Range]
) => {
  const previewRotation = 0;
  const range: Point[] = sizeRange
    ? sizeRange
    : [
        [0, 100],
        [0, 100],
      ];
  const baseSize: Point = [
    range[0][0] * (1 - previewSize) + range[0][1] * previewSize,
    range[1][0] * (1 - previewSize) + range[1][1] * previewSize,
  ];
  const isHorizontal = (previewRotation / 90) % 2 === 0;
  const size: Point = isHorizontal ? baseSize : [baseSize[1], baseSize[0]];
  const region = [
    [0, size[1]],
    [0, 0],
    [size[0], 0],
    [size[0], size[1]],
  ];
  const bi = makeBlockInstance(block, mat4.create(), size, getBlockById);
  const metrics = getters.getMetrics(bi);
  return {
    region,
    headcount: metrics.headcount,
  };
};

const makeBlockThumbnails = async (
  blocks: Block[],
  team: Team,
  getBlockById: BlockGetter
) => {
  return Promise.all(
    blocks.map(async (block) => {
      if (!block) return "";
      if (!team) return "";
      const root = block;
      const { sizeRange, seatsRange } = getters.getMetrics(root);
      let previewSize =
        seatsRange[0] === seatsRange[1]
          ? 1
          : (team.headcount - seatsRange[0]) / (seatsRange[1] - seatsRange[0]);

      let res = computeRegion(block, getBlockById, previewSize, sizeRange);
      while (res.headcount < team.headcount && previewSize < 1) {
        previewSize += 0.1;
        res = computeRegion(block, getBlockById, previewSize, sizeRange);
      }

      return createBlockImage({
        region: res.region,
        block,
        getBlockById,
        wpiActivitiesMode: false,
      });
    })
  );
};

const useStyles1 = makeStyles(() => ({
  root: {
    height: "100%",
  },
  filters: {
    display: "flex",
  },
  list: {
    height: "100%",
    width: "100%",
    overflow: "auto",
    display: "flex",
    background: "#ffffff",
  },
  image: {
    marginBottom: 20,
    height: 160,
    width: 160,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 16,
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },
  },
}));

export const BlockComboListCol = ({
  blocks,
  team,
  bg,
  i,
}: {
  blocks: Block[];
  onClick: (id: string) => void;
  team: Team;
  bg: string;
  i: number;
}) => {
  const { getBlockById } = useBlocksCtrl();
  const classes = useStyles1();
  const [images, setImages] = React.useState<{ [k: string]: string }>({});
  useEffect(() => {
    const renderBlocks = async () => {
      const _images = await makeBlockThumbnails(blocks, team, getBlockById);
      const imageMap: { [k: string]: string } = {};
      blocks.forEach((b, idx) => {
        if (b) imageMap[b.id] = _images[idx];
      });
      console.warn("imageMap", imageMap, blocks);
      setImages(imageMap);
    };
    renderBlocks();
  }, [blocks, getBlockById, team]);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        background: bg,
        flexGrow: 1,
        alignItems: "center",
      }}
    >
      <div style={{ margin: "20px 0", fontSize: "1.2rem" }}>Team {i + 1}</div>
      {blocks.map((block) =>
        block ? (
          <div key={block.id}>
            <div className={classes.image}>
              <img alt={""} src={images[block.id]} />
            </div>
          </div>
        ) : (
          <div>
            <div>No result</div>
          </div>
        )
      )}
    </div>
  );
};

export const BlockComboList = ({
  blocks,
  onClick,
  mm,
}: {
  blocks: Block[][];
  onClick: (id: string) => void;
  mm: MatchMaker;
}) => {
  const classes = useStyles1();
  return (
    <div className={classes.list}>
      {blocks.length > 0 &&
        blocks.map((block, i: number) => (
          <BlockComboListCol
            key={i}
            blocks={block}
            onClick={onClick}
            team={mm.teams[i]}
            bg={i % 2 === 0 ? "#EEF2FD" : "#E0E8FC"}
            i={i}
          />
        ))}
    </div>
  );
};

export default BlockSandbox;
