import { format } from "./utils";

export type Arc = {
  type: "arc";
  x: number;
  y: number;
  r: number;
  startAngle: number;
  endAngle: number;
};

export const arc = ({ x, y, r, startAngle, endAngle }: Arc) => {
  return (
    format("0", "ARC") +
    format("10", x) +
    format("20", y) +
    format("30", 0) +
    format("40", r) +
    format("50", startAngle) +
    format("51", endAngle)
  );
};
