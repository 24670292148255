import React, { ChangeEvent } from "react";
import { Props } from "./sidebar-block";
import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Typography,
} from "@material-ui/core";
import AutoComplete from "../components/autocomplete";
import Select from "../components/select";
import Tags from "./tags";
import { useBlocksCtrl } from "../../lib/containers";
import { emptyConstraint, program, getters } from "../lib/constants";
import TextField from "../components/textfield";
import NumberField from "./number-field";
import { Accordion, AccordionDetails, AccordionSummary } from "./accordion";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import { useBlockLibrariesCtrl } from "../../lib/containers/block-libraries";
import BlockMetrics from "./block-metrics";
import {
  formatToInt,
  formatBudget,
  formatTotalBudget,
  formatCostHC,
} from "../../lib/number";
import BlockComponentsMetrics from "./block-components";
import { Option } from "../../lib/types";
import { Block } from "../lib/types";

const useStyles = makeStyles(() => ({
  row: {
    marginBottom: 12,
  },
  input: {
    background: "#F4F4F6",
    borderRadius: "4px",
    paddingLeft: "8px",
  },
  subtitle: {
    fontSize: "14px",
    marginBottom: "6px",
    color: "#444",
  },
  details: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  detailsTitle: {
    margin: "8px 0 16px 0",
  },
  miniKPI: {
    display: "flex",
  },
  miniKPITitle: {
    fontSize: "0.9rem",
    flexGrow: 1,
  },
  miniKPIValue: {
    fontSize: "0.9rem",
    color: "rgba(0,0,0,0.6)",
  },
  budgetAutocomplete: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

const facadeConstraintOptions = [
  { value: 1, name: "Near facade" },
  { value: 0, name: "Any" },
  { value: -1, name: "Near core" },
];

const vibrancyConstraintOptions = [
  { value: -1, name: "Very Quiet" },
  { value: -0.5, name: "Quiet" },
  { value: 0, name: "Any" },
  { value: 0.5, name: "Vibrant" },
  { value: 1, name: "Very Vibrant" },
];

const opennessConstraintOptions = [
  { value: 1, name: "Open" },
  { value: 0, name: "Any" },
  { value: -1, name: "Closed" },
];

const costTypes = [
  { value: "perUnit", title: "$/Unit" },
  { value: "perGSF", title: "$/GSF" },
];

const SidebarMainControlsWrapper = (props: Props) => {
  const { activeBlock: block } = useBlocksCtrl();
  if (!block) return null;
  return <SidebarMainControls {...props} block={block} />;
};

const SidebarMainControls: React.FC<Props & { block: Block }> = ({ block }) => {
  const { blocks, saveBlock, setLibrary, removeLibrary, activeBMInstance } =
    useBlocksCtrl();
  const { libraries, createLibrary } = useBlockLibrariesCtrl();
  const [expanded, setExpanded] = React.useState<string | false>("settings");
  const [subcategories, setSubcategories] = React.useState<
    Record<string, Option>
  >({});
  const [types, setTypes] = React.useState<Record<string, Option>>({});
  const [initialized, setInitialized] = React.useState(false);
  const [perUnit, setPerUnit] = React.useState(
    block.props.metrics?.costUnit === 0 ? false : true
  );
  const classes = useStyles();
  const onAccordionChange =
    (panel: string) => (event: ChangeEvent<unknown>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  const definition = getters.getDefinition(block);

  React.useEffect(() => {
    if (definition && !initialized) {
      const categoryKey =
        definition.category && definition.category !== ""
          ? definition.category
          : "team";
      const category = program[categoryKey];
      const defaultSubcategoryKey = Object.keys(category.children)[0];
      const subcategoryKey =
        definition.subcategory && definition.subcategory !== ""
          ? definition.subcategory
          : defaultSubcategoryKey;
      const subcategory =
        category.children[subcategoryKey] ||
        category.children[defaultSubcategoryKey];
      const defaultTypeKey = Object.keys(subcategory.children)[0];
      const typeKey =
        definition.type && definition.type !== ""
          ? definition.type
          : defaultTypeKey;
      const type =
        subcategory.children[typeKey] || subcategory.children[defaultTypeKey];
      const updatedDefinition = {
        ...definition,
        category: categoryKey,
        subcategory: subcategory.value,
        type: type.value,
      };
      const newBlock = {
        ...block,
        props: { ...block.props, definition: updatedDefinition },
      };
      setInitialized(true);
      setSubcategories(category.children);
      setTypes(subcategory.children);
      saveBlock(newBlock);
    }
    // eslint-disable-next-line
  }, [definition, initialized]);
  if (!block) return null;

  const sides = getters.getSides(block);
  const metrics = getters.getMetrics(block);
  const layout = getters.getLayout(block);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const flexibility = layout.flexibility || [0, 0];

  const onDefinitionChange = (key: string) => (value: any) => {
    const newBlock = {
      ...block,
      props: { ...block.props, definition: { ...definition, [key]: value } },
    };
    saveBlock(newBlock);
  };
  const onLibraryChange = async (value: any) => {
    if (value === "") removeLibrary(block.id);
    else {
      const found = libraries.find((el) => el.id === value);
      if (!found) {
        const created = await createLibrary(value);
        setLibrary(block.id, created.id);
      } else {
        setLibrary(block.id, value);
      }
    }
  };
  const onCategoryChange = (v: string) => {
    const category = program[v];
    const subcategoryKey = Object.keys(category.children)[0];
    const subcategory = category.children[subcategoryKey];
    const typeKey = Object.keys(subcategory.children)[0];
    const updatedDefinition = {
      ...definition,
      category: v,
      subcategory: subcategoryKey,
      type: typeKey,
      color: category.color,
    };
    const newBlock = {
      ...block,
      props: { ...block.props, definition: updatedDefinition },
    };
    setSubcategories(program[v].children);
    setTypes(subcategory.children);
    saveBlock(newBlock);
  };
  const onSubcategoryChange = (v: string) => {
    const c = definition.category;
    const subcategoryKey = v;
    const subcategory = category.children[v];
    const typeKey = Object.keys(subcategory.children)[0];
    const updatedDefinition = {
      ...definition,
      category: c,
      subcategory: subcategoryKey,
      type: typeKey,
      color: category.color,
    };
    const newBlock = {
      ...block,
      props: { ...block.props, definition: updatedDefinition },
    };
    setSubcategories(program[c].children);
    setTypes(subcategory.children);
    saveBlock(newBlock);
  };
  const onNameChange = onDefinitionChange("name");
  const onTagsChange = onDefinitionChange("tags");
  const onTypeChange = onDefinitionChange("type");
  const onDescriptionChange = onDefinitionChange("description");
  const onUrlChange = onDefinitionChange("url");
  const onActivityChange = onDefinitionChange("activity");
  const onSeatsChange = (v: number) => {
    saveBlock({
      ...block,
      props: {
        ...block.props,
        metrics: {
          ...metrics,
          seats: v,
          seatsRange: [v, v] as [number, number],
        },
      },
    });
  };
  const onWorkpointsChange = (v: number) => {
    saveBlock({
      ...block,
      props: {
        ...block.props,
        metrics: {
          ...metrics,
          workpoints: v,
          workpointsRange: [v, v] as [number, number],
        },
      },
    });
  };
  const onHeadcountChange = (v: number) => {
    saveBlock({
      ...block,
      props: {
        ...block.props,
        metrics: {
          ...metrics,
          headcount: v,
          headcountRange: [v, v] as [number, number],
        },
      },
    });
  };

  const onDoorsChange = (v: number) => {
    saveBlock({
      ...block,
      props: {
        ...block.props,
        metrics: {
          ...metrics,
          doors: v,
        },
      },
    });
  };

  const onCostTypeChange = (v: any) => {
    if (v === "perUnit") {
      setPerUnit(true);
      saveBlock({
        ...block,
        props: {
          ...block.props,
          metrics: {
            ...metrics,
            costGSF: 0,
          },
        },
      });
    } else if (v === "perGSF") {
      setPerUnit(false);
      saveBlock({
        ...block,
        props: {
          ...block.props,
          metrics: {
            ...metrics,
            costUnit: 0,
          },
        },
      });
    }
  };

  const subtypeSet = new Set<string>();
  const librarySet = new Set<string>();
  const tagSet = new Set<string>();
  blocks.forEach((_block) => {
    const { subtype, tags, library } = getters.getDefinition(_block);
    if (library !== "") librarySet.add(library);
    if (subtype !== "") subtypeSet.add(subtype);
    tags.forEach((tag) => tagSet.add(tag));
  });
  const tags = Array.from(tagSet);
  const libs = libraries.map((lib) => ({ title: lib.title, value: lib.id }));

  const updateBlockConstraints =
    (name: string) => (side: number, value: any) => {
      const _sides = getters.getSides(block);
      const constraints = _sides.constraints
        ? [..._sides.constraints]
        : [
            { ...emptyConstraint },
            { ...emptyConstraint },
            { ...emptyConstraint },
            { ...emptyConstraint },
          ];
      constraints[side] = { ...constraints[side], [name]: value };
      Object.assign(block.props, { sides: { ..._sides, constraints } });
      saveBlock(block);
    };

  const updateBlockLayout = (name: string) => (value: any) => {
    Object.assign(block.props, { layout: { ...layout, [name]: value } });
    saveBlock(block);
  };

  const onCostGSFChange = (costGSF: number) => {
    const _metrics = getters.getMetrics(block);
    Object.assign(block.props, { metrics: { ..._metrics, costGSF } });
    saveBlock(block);
  };

  const onCostAssetChange = (costUnit: number) => {
    const _metrics = getters.getMetrics(block);
    Object.assign(block.props, {
      metrics: { ..._metrics, costUnit: costUnit },
    });
    saveBlock(block);
  };

  const onFacadeConstraintChange = updateBlockConstraints("facade");
  const onVibrancyConstraintChange = updateBlockConstraints("vibrancy");
  const onOpennessConstraintChange = updateBlockConstraints("openness");
  const onFlexibilityChange = (i: number, value: number) => {
    const copy = [...flexibility];
    copy[i] = value;
    updateBlockLayout("flexibility")(copy);
  };

  let seats = "0";
  let density = "0 SF/HC";
  let density2 = "0 SF/WP";
  let area = "0 sf";
  let workpoints = "0";
  let assignedSeats = "0";
  if (metrics.seatsRange) {
    seats =
      metrics.seatsRange[0] === metrics.seatsRange[1]
        ? metrics.seatsRange[0] + ""
        : metrics.seatsRange.join(" - ");
  }
  if (metrics.workpointsRange) {
    workpoints =
      metrics.workpointsRange[0] === metrics.workpointsRange[1]
        ? metrics.workpointsRange[0] + ""
        : metrics.workpointsRange.join(" - ");
  }
  if (metrics.headcountRange) {
    assignedSeats =
      metrics.headcountRange[0] === metrics.headcountRange[1]
        ? metrics.headcountRange[0] + ""
        : metrics.headcountRange.join(" - ");
  }
  if (metrics.sizeRange) {
    const min = formatToInt(
      (metrics.sizeRange[0][0] / 12) * (metrics.sizeRange[1][0] / 12)
    );
    const max = formatToInt(
      (metrics.sizeRange[0][1] / 12) * (metrics.sizeRange[1][1] / 12)
    );
    area = min === max ? `${min} sf` : `${min} - ${max} sf`;
  }
  if (metrics.areaRange && metrics.headcountRange) {
    density =
      formatToInt(metrics.areaRange[0] / 144 / metrics.headcountRange[0]) +
      " SF/HC";
  }
  if (metrics.areaRange && metrics.workpointsRange) {
    density2 =
      formatToInt(metrics.areaRange[0] / 144 / metrics.workpointsRange[0]) +
      " sf/wp";
  }

  // Generate block and assets tables
  const category = program[definition.category];
  if (!category) return null;
  if (!subcategories) return null;
  if (!types) return null;
  return (
    <>
      {block.role === "block" && <BlockMetrics />}
      <Accordion
        expanded={expanded === "details"}
        onChange={onAccordionChange("details")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          Metrics
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.details}>
            <Grid container={true} spacing={4} justifyContent="space-between">
              <Grid item={true} xs={6}>
                <div className={classes.miniKPI}>
                  <div className={classes.miniKPITitle}>Area</div>
                  <div className={classes.miniKPIValue}>{area}</div>
                </div>
                <div className={classes.miniKPI}>
                  <div className={classes.miniKPITitle}>Density</div>
                  <div className={classes.miniKPIValue}>{density}</div>
                </div>
                <div className={classes.miniKPI}>
                  <div className={classes.miniKPITitle}> </div>
                  <div className={classes.miniKPIValue}>{density2}</div>
                </div>
              </Grid>
              <Grid item={true} xs={6}>
                <div className={classes.miniKPI}>
                  <div className={classes.miniKPITitle}>Seats</div>
                  <div className={classes.miniKPIValue}>{seats}</div>
                </div>
                <div className={classes.miniKPI}>
                  <div className={classes.miniKPITitle}>Workpoints</div>
                  <div className={classes.miniKPIValue}>{workpoints}</div>
                </div>
                <div className={classes.miniKPI}>
                  <div className={classes.miniKPITitle}>Assigned Seats</div>
                  <div className={classes.miniKPIValue}>{assignedSeats}</div>
                </div>
              </Grid>
            </Grid>
          </div>
        </AccordionDetails>
      </Accordion>
      {block.role === "block" && (
        <Accordion
          expanded={expanded === "components"}
          onChange={onAccordionChange("components")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            Components
          </AccordionSummary>
          <AccordionDetails>
            <BlockComponentsMetrics metrics={metrics} />
          </AccordionDetails>
        </Accordion>
      )}
      <Accordion
        expanded={expanded === "settings"}
        onChange={onAccordionChange("settings")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          Settings
        </AccordionSummary>
        <AccordionDetails>
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={12}>
              <TextField
                fullWidth={true}
                label={"Name"}
                onChange={(e) => onNameChange(e.target.value)}
                defaultValue={definition.name}
              />
            </Grid>
            <Grid item={true} xs={12}>
              <TextField
                fullWidth={true}
                rows={2}
                multiline={true}
                label={"Description"}
                onChange={(e) => onDescriptionChange(e.target.value)}
                value={definition.description}
              />
            </Grid>
            <Grid item={true} xs={12}>
              <AutoComplete
                label="Library"
                options={libs}
                onChange={onLibraryChange}
                value={definition.library}
              />
            </Grid>
            <Grid item={true} xs={12}>
              <TextField
                fullWidth={true}
                label={"URL"}
                onChange={(e) => onUrlChange(e.target.value)}
                defaultValue={definition.url}
              />
            </Grid>
            {block.role === "asset" && (
              <>
                <Grid item={true} xs={4}>
                  <NumberField
                    label={"Seats"}
                    value={metrics.seats || 0}
                    onChange={(v) => onSeatsChange(v)}
                    positive={true}
                    min={0}
                    max={12}
                  />
                </Grid>
                <Grid item={true} xs={4}>
                  <NumberField
                    label={"Workpoints"}
                    value={metrics.workpoints || 0}
                    onChange={(v) => onWorkpointsChange(v)}
                    positive={true}
                    min={0}
                    max={12}
                  />
                </Grid>
                <Grid item={true} xs={4}>
                  <NumberField
                    label={"Assigned Seats"}
                    value={metrics.headcount || 0}
                    onChange={(v) => onHeadcountChange(v)}
                    positive={true}
                    min={0}
                    max={12}
                  />
                </Grid>
                <Grid item={true} xs={6}>
                  <FormControl fullWidth>
                    <InputLabel shrink={true} id="program-label">
                      Program Category
                    </InputLabel>
                    <Select
                      id="programCategory"
                      label="Program Category"
                      placeholder={"Program Category"}
                      value={definition.category}
                      onChange={(e) =>
                        onCategoryChange(e.target.value as string)
                      }
                    >
                      {Object.keys(program)
                        .sort()
                        .map((key) => (
                          <MenuItem value={program[key].value}>
                            {program[key].name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item={true} xs={6}>
                  <FormControl fullWidth>
                    <InputLabel shrink={true} id="program-label">
                      Program L1
                    </InputLabel>
                    <Select
                      id="program"
                      label="Program"
                      placeholder={"Program"}
                      value={definition.subcategory}
                      onChange={(e) =>
                        onSubcategoryChange(e.target.value as string)
                      }
                    >
                      {Object.keys(subcategories)
                        .sort()
                        .map((key) => (
                          <MenuItem value={subcategories[key].value}>
                            {subcategories[key].name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item={true} xs={6}>
                  <FormControl fullWidth>
                    <InputLabel shrink={true} id="program-label">
                      Program L2
                    </InputLabel>
                    <Select
                      id="program"
                      label="Program"
                      placeholder={"Program"}
                      disabled={definition.subcategory === ""}
                      onChange={(e) => onTypeChange(e.target.value)}
                      value={definition.type}
                    >
                      {Object.keys(types)
                        .sort()
                        .map((key) => (
                          <MenuItem value={types[key].value}>
                            {types[key].name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                {definition.category === "meeting" && (
                  <Grid item={true} xs={6}>
                    <NumberField
                      label={"Doors"}
                      value={metrics.doors || 0}
                      onChange={(v) => onDoorsChange(v)}
                      positive={true}
                      disabled={
                        definition.category === "meeting" ? false : true
                      }
                      min={0}
                      max={12}
                    />
                  </Grid>
                )}
                {definition.category === "team" && (
                  <Grid item={true} xs={6}>
                    <FormControl fullWidth>
                      <InputLabel shrink={true} id="activity-label">
                        Activity
                      </InputLabel>
                      <Select
                        id="activity"
                        label="activity"
                        onChange={(e) => onActivityChange(e.target.value)}
                        value={definition.activity}
                      >
                        <MenuItem value="individual">Individual</MenuItem>
                        <MenuItem value="communal">Communal</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}
              </>
            )}
            <Grid item={true} xs={6}>
              <Tags
                options={tags}
                value={definition.tags}
                onChange={onTagsChange}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      {block.role === "block" && (
        <Accordion
          expanded={expanded === "flexibility"}
          onChange={onAccordionChange("flexibility")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            Flexibility
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant={"body1"} style={{ marginBottom: 24 }}>
              The flexibility of a Block controls how much it can stretch and
              grow to fill a space.
            </Typography>
            <Grid container={true} spacing={2} className={classes.row}>
              {["Width", "Depth"].map((side, i) => (
                <Grid key={i} item={true} xs={6}>
                  <NumberField
                    label={side}
                    value={flexibility[i]}
                    onChange={(v) =>
                      onFlexibilityChange(i, Math.max(v || 0, 0))
                    }
                    endAdornment={
                      <InputAdornment position={"end"}>in</InputAdornment>
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      )}
      {block.role === "block" && (
        <Accordion
          expanded={expanded === "sides"}
          onChange={onAccordionChange("sides")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            Adjacencies
          </AccordionSummary>
          <AccordionDetails>
            {["top", "right", "bottom", "left"].map((side, i) => (
              <div key={i}>
                <div className={classes.subtitle}>
                  {side.charAt(0).toUpperCase() + side.slice(1)}
                </div>
                <Grid container={true} spacing={2} className={classes.row}>
                  <Grid item={true} xs={4}>
                    <FormControl fullWidth={true}>
                      <InputLabel>Placement</InputLabel>
                      <Select
                        disableUnderline={true}
                        className={classes.input}
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        value={sides.constraints![i].facade || 0}
                        label={side}
                        onChange={(e) =>
                          onFacadeConstraintChange(i, e.target.value as number)
                        }
                      >
                        {facadeConstraintOptions.map((el) => (
                          <MenuItem key={el.value} value={el.value}>
                            {el.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item={true} xs={4}>
                    <FormControl fullWidth={true}>
                      <InputLabel>Vibrancy</InputLabel>
                      <Select
                        disableUnderline={true}
                        className={classes.input}
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        value={sides.constraints![i].vibrancy || 0}
                        label={side}
                        onChange={(e) =>
                          onVibrancyConstraintChange(
                            i,
                            e.target.value as number
                          )
                        }
                      >
                        {vibrancyConstraintOptions.map((el) => (
                          <MenuItem key={el.value} value={el.value}>
                            {el.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item={true} xs={4}>
                    <FormControl fullWidth={true}>
                      <InputLabel>Openness</InputLabel>
                      <Select
                        disableUnderline={true}
                        className={classes.input}
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        value={sides.constraints![i].openness || 0}
                        label={side}
                        onChange={(e) =>
                          onOpennessConstraintChange(
                            i,
                            e.target.value as number
                          )
                        }
                      >
                        {opennessConstraintOptions.map((el) => (
                          <MenuItem key={el.value} value={el.value}>
                            {el.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            ))}
          </AccordionDetails>
        </Accordion>
      )}
      {block.role === "block" && (
        <Accordion
          expanded={expanded === "guardrails"}
          onChange={onAccordionChange("guardrails")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            Budget
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant={"body1"} style={{ marginBottom: 24 }}>
              A block's budget is determined by assigning it a $/GSF or $/Asset
              cost rate, which can be selected from the predetermined
              guidelines, or entered as a custom value in the box below.
            </Typography>
            <Grid container={true}>
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={12}>
                  <div
                    className={classes.budgetAutocomplete}
                    style={{ paddingTop: 2, paddingBottom: 6 }}
                  >
                    <div style={{ width: 350, marginRight: 10 }}>
                      <FormControl fullWidth={true}>
                        <InputLabel>Cost Type</InputLabel>
                        <Select
                          disableUnderline={true}
                          value={perUnit ? "perUnit" : "perGSF"}
                          label="Cost Type"
                          onChange={(e) => onCostTypeChange(e.target.value)}
                        >
                          {costTypes.map((el) => (
                            <MenuItem key={el.value} value={el.value}>
                              {el.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div style={{ width: 350, marginLeft: 10 }}>
                      <NumberField
                        label={perUnit ? "$/Unit" : "$/GSF"}
                        value={perUnit ? metrics.costUnit : metrics.costGSF}
                        onChange={
                          perUnit
                            ? (e) => onCostAssetChange(e)
                            : (e) => onCostGSFChange(e)
                        }
                        min={0}
                        startAdornment={
                          <InputAdornment position={"start"}>$</InputAdornment>
                        }
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid item={true} xs={12} spacing={4}>
                <div className={classes.miniKPI} style={{ paddingTop: 8 }}>
                  <div className={classes.miniKPITitle}>Total Budget Range</div>
                  <div className={classes.miniKPIValue}>
                    {metrics.costGSF || metrics.costUnit
                      ? formatBudget(metrics.totalCostRange)
                      : "$" + 0}
                  </div>
                </div>
                <div className={classes.miniKPI} style={{ paddingTop: 8 }}>
                  <div className={classes.miniKPITitle}>Total Budget</div>
                  <div className={classes.miniKPIValue}>
                    {formatTotalBudget(
                      activeBMInstance,
                      metrics.costGSF,
                      metrics.costUnit
                    )}
                  </div>
                </div>
                <div className={classes.miniKPI} style={{ paddingTop: 8 }}>
                  <div className={classes.miniKPITitle}>$ / Headcount</div>
                  <div className={classes.miniKPIValue}>
                    {metrics.costGSF || metrics.costUnit
                      ? formatCostHC(metrics.costHCRange)
                      : "$" + 0}
                  </div>
                </div>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};

export default SidebarMainControlsWrapper;
