import React, { useEffect, useRef, useState } from "react";
import { CanvasReconciler } from "@outerlabs/canvas-reconciler";
import {
  Circulation,
  MeetingRooms,
  Desks,
  Xform,
  Site,
} from "components/isp-canvas";
import {
  useProjectCtrl,
  useRendererCtrl,
  useFeaturesCtrl,
} from "lib/containers";
import { useParams } from "react-router-dom";
import { RendererSettings } from "lib/types";
import { encodeMetricId } from "lib/metrics/id";
import makeRendererProgram from "lib/metrics/make-renderer-program";
import Loading from "components/loading";
import usePrevious from "lib/hooks/use-previous";
import { createHiDPICanvas } from "../blocks/lib/util";
import { calcDesks } from "lib/metrics/remove-desks";

interface Props {
  width: number;
  height: number;
  settings: RendererSettings;
}

const defaultProgram = {
  phoneBooths: null,
  huddle: null,
  small: null,
  large: null,
  medium: null,
  extraLarge: null,
};

export const ISPCanvasBottom: React.FC<Props> = ({
  width,
  height,
  settings,
}) => {
  const bottomCanvasRef = useRef<HTMLCanvasElement | null>();
  const prevWidth = usePrevious(width);
  const prevHeight = usePrevious(height);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const {
    setXform,
    xform,
    activeControl,
    selectedRoom,
    selectedRooms,
    meetingRoomMetricSelectedRoom,
  } = useRendererCtrl();
  const { currentFeatures } = useFeaturesCtrl();
  const { buildingID, strategyID, floorID } = useParams<{
    [key: string]: string;
  }>();
  const { project } = useProjectCtrl();
  const metricID = encodeMetricId(buildingID, strategyID, floorID);

  useEffect(() => {
    const el = bottomCanvasRef.current;
    if (el) createHiDPICanvas(el, width, height);
  }, [width, height]);
  useEffect(() => {
    const newXform = () => {
      if (
        (!isInitialized || prevWidth !== width || prevHeight !== height) &&
        currentFeatures
      ) {
        setXform(width, height, currentFeatures);
        setIsInitialized(true);
      }
    };
    newXform();
  }, [
    width,
    height,
    setXform,
    isInitialized,
    prevWidth,
    prevHeight,
    currentFeatures,
  ]);

  const { keptCenters, disabledCenters } = React.useMemo(() => {
    return settings.desks &&
      currentFeatures &&
      project &&
      project.metrics[metricID]
      ? calcDesks(project.metrics[metricID], currentFeatures, settings.blocks)
      : {
          keptCenters: currentFeatures?.uniqueDesks || [],
          disabledCenters: [],
        };
  }, [currentFeatures, settings.blocks, settings.desks, metricID, project]);

  if (!project || !currentFeatures) return <Loading />;

  const metric = project.metrics[metricID];
  const userConvertedMeetingRooms = metric.userConvertedMeetingRooms;
  const ms = makeRendererProgram(metric);
  const meetingSettings = { ...defaultProgram, ...ms };

  // Use setTimeout so that HTML render completes immediately
  setTimeout(() => {
    if (bottomCanvasRef.current && xform) {
      const canvas = bottomCanvasRef.current;

      const renderDesks = () => {
        if (!metric.desksOff) {
          return (
            <Desks
              boundaries={currentFeatures.desks}
              keptCenters={keptCenters}
              disabledCenters={disabledCenters}
            />
          );
        }
      };

      CanvasReconciler.render(
        <>
          <Xform xform={xform}>
            <Site floorplate={currentFeatures.floorplate || []} />
            {renderDesks()}
            <Circulation
              activeControl={activeControl}
              circulation={currentFeatures.circulation}
              circulationBuffer={metric.corridorWidth}
            />
            <MeetingRooms
              selectedRoom={selectedRoom}
              selectedRooms={selectedRooms}
              conferenceRooms={currentFeatures.conferenceRooms}
              meetingSettings={meetingSettings}
              userConvertedMeetingRooms={userConvertedMeetingRooms}
              meetingRoomMetricSelectedRoom={meetingRoomMetricSelectedRoom}
            />
          </Xform>
        </>,
        canvas
      );
    }
  }, 0);

  return (
    <>
      <canvas
        width={width}
        height={height}
        style={{ position: "absolute", top: 0 }}
        ref={(el) => (bottomCanvasRef.current = el)}
      />
    </>
  );
};

export default React.memo(ISPCanvasBottom);
