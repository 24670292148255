import React from "react";
import { makeStyles } from "@material-ui/core";
import { RadialChart } from "react-vis";
import { colors } from "lib/isp-canvas/constants";
import { getters, program } from "../lib/constants";
import { useBlocksCtrl } from "../../lib/containers";
import { formatToInt } from "lib/number";

const useStyles = makeStyles({
  blockHeaderContainer: {
    marginRight: "20px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    minHeight: "150px",
  },
  leftContainer: {
    height: 200,
  },
  radialPercentageIndicator: {
    width: "115px",
    height: "115px",
    borderRadius: "50%",
    border: "10px solid #FFBD02",
    position: "relative",
    top: "15px",
  },
  radialChartContainer: { position: "relative", top: "20px" },
  metricInsidePiechartContainer: {
    position: "relative",
    bottom: "86px",
    left: "2px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    height: 60,
  },
  metricValue: {
    fontFamily: "GoogleSansRegular",
    fontSize: 32,
    lineHeight: 1,
    color: "#555555",
  },
  metricTitle: {
    fontFamily: "GoogleSansRegular",
    fontSize: 15.5,
    color: "#555555",
  },
  rightContainer: {
    marginLeft: "30px",
    height: "90%",
    paddingBottom: 12,
  },
  summaryValue: {
    height: "60px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  blockSummaryTitle: {
    fontFamily: "GoogleSansRegular",
    fontSize: "15.5px",
    lineHeight: "20px",
    color: "#878787",
  },
  blockSummaryNumber: {
    fontFamily: "GoogleSansRegular",
    fontSize: "32px",
    lineHeight: "37.5px",
    color: "#555555",
  },
  pieChartLegenContainer: {
    marginTop: "10px",
    width: "150px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  singleLegendContainer: {
    width: "150px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  colorCircle: {
    width: "9px",
    height: "9px",
    borderRadius: "50%",
  },
  legendName: {
    paddingLeft: "10px",
  },
});

const BlockMetrics: React.FC = () => {
  const classes = useStyles();
  const { activeBlock } = useBlocksCtrl();
  if (!activeBlock) return null;
  const metrics = getters.getMetrics(activeBlock);
  const data: { title: string; angle: number; color: string }[] = [];
  Object.keys(metrics.types).forEach((type) => {
    let title = "";
    const value = Object.values(program).find((category) => {
      return Object.values(category.children).find((subcategory) => {
        if (subcategory.children[type] != null) {
          title = subcategory.name;
          return true;
        } else return false;
      });
    });
    let color = "#ffffff";
    if (value) {
      if (title === "") title = value.name;
      color = value.color;
    } else {
      if (type === "focus") {
        title = "Focus";
        color = colors.focusColor;
      } else if (type === "collaboration") {
        title = "Collaboration";
        color = colors.collabColor;
      }
    }
    data.push({ title, angle: metrics.types[type].area, color });
  });

  const formatArea = (sizeRange: number[][]) => {
    const min = formatToInt((sizeRange[0][0] / 12) * (sizeRange[1][0] / 12));
    const max = formatToInt((sizeRange[0][1] / 12) * (sizeRange[1][1] / 12));
    return min === max ? `${min}` : `${min} - ${max}`;
  };

  return (
    <div>
      <div className={classes.blockHeaderContainer}>
        <div className={classes.leftContainer}>
          <div className={classes.radialChartContainer}>
            <RadialChart
              data={data}
              width={160}
              height={160}
              radius={60}
              innerRadius={50}
              padAngle={0.05}
              colorType="literal"
            />
          </div>
          <div className={classes.metricInsidePiechartContainer}>
            <div className={classes.metricValue}>
              {metrics.seatsRange ? metrics.seatsRange.join("-") : "0"}
            </div>
            <div className={classes.metricTitle}>Seats</div>
          </div>
        </div>
        <div className={classes.rightContainer}>
          <div className={classes.summaryValue}>
            <div className={classes.blockSummaryTitle}>Total Area</div>
            <div className={classes.blockSummaryNumber}>
              {metrics.sizeRange ? formatArea(metrics.sizeRange) : "0"} sf
            </div>
          </div>

          <div className={classes.pieChartLegenContainer}>
            {data.map((el) => (
              <div className={classes.singleLegendContainer}>
                <div
                  className={classes.colorCircle}
                  style={{ backgroundColor: el.color }}
                />
                <div className={classes.legendName}>{el.title}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(BlockMetrics);
