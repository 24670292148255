import React from "react";
import { Canvas } from "@outerlabs/canvas-reconciler";
import { renderSite } from "lib/isp-canvas";
import { Coordinate } from "lib/types";

interface Props {
  floorplate: Coordinate[][];
}

export const Site: React.FC<Props> = ({ floorplate }) => {
  const canvas = (
    <Canvas
      render={(ctx: CanvasRenderingContext2D) => {
        renderSite(ctx, floorplate);
      }}
    />
  );

  return <>{canvas}</>;
};

export default React.memo(Site);
