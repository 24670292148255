import React from "react";

export interface Point {
  x: number;
  y: number;
}

export const SvgDottedLine = (startPoint: Point, endPoint: Point) => (
  <svg
    className="line"
    style={{
      overflow: "visible",
    }}
  >
    <g fill="none" stroke="black" strokeWidth="1">
      <path
        strokeDasharray="1,1"
        d={`M${startPoint.x} ${startPoint.y} L${endPoint.x} ${endPoint.y}`}
      />
    </g>
  </svg>
);
