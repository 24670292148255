import React from "react";
import { useParams, useHistory } from "react-router-dom";

import Container from "components/layout/container";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { setAuthToken } from "lib/storage/session";

import config from "config";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 300,
    },
  },
  imgContainer: {
    backgroundImage: `url("${config.storageUrl}/images/PortfolioLanding.svg")`,
    display: "flex",
    backgroundSize: "cover",
    flex: 1,
  },
  loginContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  header: {
    display: "flex",
    alignItems: "center",
    marginBottom: "60px",
  },
  logo: {
    marginRight: 30,
    height: 64,
    width: 64,
  },
  appName: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "40px",
    lineHeight: "51px",
    color: "#000000",
  },
  copy: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "140%",
    color: "#000000",
    opacity: "0.5",
    maxWidth: "340px",
  },
  loginButtonContainer: {
    marginTop: "60px",
  },
  loginButton: {
    padding: 0,
  },
  waterMark: {
    backgroundImage: `url("${config.storageUrl}/images/GoogleLightGray.png")`,
    position: "absolute",
    bottom: "40px",
    right: "50px",
    width: "73px",
    height: "23px",
  },
  container: {
    width: "100vw",
    height: "100vh",
    display: "flex",
  },
}));

const Login: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { token } = useParams<{ [key: string]: string }>();

  const loginUser = () => {
    const url = `${config.baseUrl}/api/auth/oauth`;
    window.location.href = url;
  };

  if (token) {
    setAuthToken(token);
    history.push("/");
  }

  const portfolioLogoSrc = `${config.storageUrl}/images/Portfolio256.svg`;
  const signinButtonSrc = `${config.storageUrl}/images/signinButton1x.png`;
  const copy = `Evaluate and visualize portfolio-level changes in real-time`;

  return (
    <Container>
      <div className={classes.container}>
        <div className={classes.imgContainer} />
        <div className={classes.loginContainer}>
          <div>
            <div className={classes.header}>
              <img
                className={classes.logo}
                src={portfolioLogoSrc}
                alt="Portfolio Logo"
              />
              <span className={classes.appName}>TestFit</span>
            </div>
            <div className={classes.copy}>{copy}</div>
            <div className={classes.loginButtonContainer}>
              <Button className={classes.loginButton}>
                <img
                  src={signinButtonSrc}
                  onClick={loginUser}
                  alt="Sign In with Google"
                  data-testid="login-button"
                />
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.waterMark} />
    </Container>
  );
};

export default Login;
