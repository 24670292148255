import axios from "axios";
import config from "config";
import { getAuthHeader } from "lib/auth";
import { RendererSettings } from "lib/types";
import { convertMatrixToArray } from "../../blocks/lib/util";

export const getSettingsFile = async (
  projectID: string,
  buildingID: string,
  floorID: string,
  strategyID: string
): Promise<RendererSettings | undefined> => {
  try {
    const headers = await getAuthHeader();
    const api = axios.create({
      headers,
    });
    const data = await api.get(
      `${config.baseUrl}/api/planning/projects/${projectID}/${buildingID}/settings/${floorID}/${strategyID}`
    );

    return data.data;
  } catch (e) {
    return undefined;
  }
};

export const putSettingsFile = async (
  projectID: string,
  buildingID: string,
  floorID: string,
  strategyID: string,
  content: any
): Promise<void> => {
  try {
    const headers = await getAuthHeader();
    // let t: AxiosTransformer[] = Array.isArray(axios.defaults.transformRequest)
    //   ? axios.defaults.transformRequest
    //   : axios.defaults.transformRequest
    //   ? [axios.defaults.transformRequest]
    //   : [];
    const api = axios.create({
      headers,
      transformRequest: [(data) => JSON.stringify(convertMatrixToArray(data))],
      // transformResponse: [(data, headers) => convertArrayToMatrix(JSON.parse(data))],
    });

    await api.put(
      `${config.baseUrl}/api/planning/projects/${projectID}/${buildingID}/settings/${floorID}/${strategyID}`,
      content
    );

    return;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const deleteSettingsFile = async (
  projectID: string,
  buildingID: string,
  floorID: string,
  strategyID: string
): Promise<void> => {
  try {
    const headers = await getAuthHeader();
    const api = axios.create({
      headers,
    });

    await api.delete(
      `${config.baseUrl}/api/planning/projects/${projectID}/${buildingID}/settings/${floorID}/${strategyID}`
    );
    return;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
