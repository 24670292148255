import { createStyles, makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import { TextField } from "@material-ui/core";
import { verifyEmailAddresses } from "../lib/api/verifyEmailAddresses";

const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      padding: 24,
      width: 500,
      backgroundColor: "white",
      borderRadius: 4,
    },
    title: {
      fontSize: 20,
      marginBottom: 24,
    },
    buttons: {
      marginTop: 24,
      display: "flex",
      justifyContent: "space-between",
    },
    inputWrapper: {
      marginTop: 5,
    },
    emailInput: {
      width: "100%",
    },
  })
);

export default function ShareModal({
  onAccept,
  open,
  handleClose,
}: {
  onAccept(emails: string[]): void;
  open: boolean;
  handleClose(e: React.MouseEvent): void;
}) {
  const classes = useStyles();
  const [text, setText] = useState("");
  const [helpText, setHelpText] = useState("");
  const [hasError, setHasError] = useState(false);

  const validatePriorToAccept = async () => {
    // voodoo
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const addresses = text
      .split(",")
      .map((l) => l.split(";"))
      .reduce<string[]>((acc, v) => [...acc, ...v], []);

    const badAddress = addresses.reduce<string>((acc, address) => {
      if (acc) return acc;

      if (!re.test(address)) {
        return address;
      }

      return "";
    }, "");

    if (badAddress) {
      setHelpText(`${badAddress} is not a valid email address`);
      setHasError(true);
      return;
    }

    const verificationResponse = await verifyEmailAddresses(addresses);
    if (!verificationResponse.verified) {
      setHelpText(
        `${verificationResponse.invalidAddresses[0]} is not a Portfolio user`
      );
      setHasError(true);
      return;
    }

    onAccept(addresses);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className={classes.title}>Share Project</div>
            <div>
              By sharing a project you will be providing full and unrestricted
              access with other users. They may make updates and delete the
              project from view.
            </div>
            <div className={classes.inputWrapper}>
              <TextField
                label="Email addresses separated by commas or semicolons"
                multiline
                rows={4}
                value={text}
                onChange={(e) => setText(e.target.value)}
                className={classes.emailInput}
                helperText={helpText}
                error={hasError}
              />
            </div>
            <div className={classes.buttons}>
              <Button onClick={handleClose}>Cancel</Button>
              <Button
                variant="contained"
                color="primary"
                onClick={validatePriorToAccept}
              >
                SHARE
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
