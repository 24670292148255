import Slider from "@material-ui/core/Slider";
import { makeStyles } from "@material-ui/core/styles";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  clearCanvas,
  renderShadow,
  renderBlockInstance,
  renderGeometry,
  applyMatrix,
  renderDividers,
  renderBlockDimensions,
  renderRectangle,
} from "../lib/canvas";
import {
  addSplit,
  applyBlockId,
  applyBlocks,
  findBlock,
  computeMetrics,
  getBlockInstances,
  getParentBlock,
  deleteBlock,
} from "../lib/blocks";
import { Block, HitBox, Direction } from "../lib/types";
import {
  cloneBlock,
  createHiDPICanvas,
  defaultByType,
  getMousePos,
  isInsideMat,
  lerp,
  makeHitBoxes,
} from "../lib/util";
import { makeBlockInstance } from "../lib/instance";
import { useBlocksCtrl } from "../../lib/containers";
import { Point, GeometryType, Box } from "@outerlabs/shapes-geometry";
import { getters } from "../lib/constants";
import { mat4, quat, vec3 } from "gl-matrix";
import { IconButton, Tooltip } from "@material-ui/core";
import {
  GridOn,
  SpaceBar,
  Undo,
  Redo,
  Layers as LayersIcon,
} from "@material-ui/icons";
import Layers from "./layers";
import { v4 as uuid } from "uuid";

const blue = "67,132,247";

interface Props {
  width: number;
  height: number;
}
interface ExtendedProps extends Props {
  activeBlock: Block;
  activeSubBlock?: string;
}
const useStyles = makeStyles(() => ({
  fab: {
    position: "absolute",
    bottom: 36,
    left: 36,
    display: "flex",
    flexDirection: "column",
  },
  name: {
    position: "absolute",
    top: 36,
    left: 36,
  },
  buttons: {
    marginRight: "5px",
    marginTop: "10px",
  },
  previewWidthControl: {
    position: "absolute",
    width: "300px",
    left: "calc(50% - 350px)",
    bottom: "60px",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
  },
  previewDepthControl: {
    position: "absolute",
    height: "300px",
    top: "calc(50% - 150px)",
    right: "300px",
    bottom: "20px",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
  },
  previewWidthControlTitle: {},
  previewDepthControlTitle: {
    position: "absolute",
    width: 300,
    textAlign: "center",
    transform: "rotate(270deg) translate(-288px, -20px)",
    transformOrigin: "left",
  },
  previewWidthControlLabels: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "0.8rem",
    color: "rgba(0,0,0,0.6)",
  },
  previewDepthControlLabels: {
    transform: "rotate(270deg) translate(-288px, 40px)",
    width: 300,
    transformOrigin: "left",
    display: "flex",
    justifyContent: "space-between",
    fontSize: "0.8rem",
    color: "rgba(0,0,0,0.6)",
  },
  previewWidthControlSlider: {},
  previewDepthControlSlider: {
    position: "absolute",
  },
  overflowY: {
    overflowY: "auto !important" as "auto",
  },
}));

const CanvasWrapper: React.FC<Props> = ({ width, height }) => {
  const { activeBlock, activeSubBlock } = useBlocksCtrl();
  if (!activeBlock) return null;
  return (
    <Canvas
      activeBlock={activeBlock}
      activeSubBlock={activeSubBlock}
      width={width}
      height={height}
    />
  );
};

const renderPadding = (
  ctx: CanvasRenderingContext2D,
  box: Box,
  padding: number[],
  mat: mat4
) => {
  ctx.save();
  applyMatrix(ctx, mat);
  if (padding[0] > 0) {
    renderPaddingBox(
      ctx,
      [box[0][0], box[0][1]],
      [box[1][0], box[0][1] + padding[0]]
    );
  }

  if (padding[1] > 0) {
    renderPaddingBox(
      ctx,
      [box[1][0] - padding[1], box[0][1]],
      [box[1][0], box[1][1]]
    );
  }

  if (padding[2] > 0) {
    renderPaddingBox(
      ctx,
      [box[0][0], box[1][1] - padding[2]],
      [box[1][0], box[1][1]]
    );
  }

  if (padding[3] > 0) {
    renderPaddingBox(
      ctx,
      [box[0][0], box[0][1]],
      [box[0][1] + padding[3], box[1][1]]
    );
  }

  ctx.restore();
};

const renderPaddingBox = (
  ctx: CanvasRenderingContext2D,
  p0: Point,
  p1: Point
) => {
  renderRectangle(ctx, [p0, p1], undefined, `rgba(255,255,255,0.4)`);
};

const Canvas: React.FC<ExtendedProps> = ({ activeBlock, width, height }) => {
  const classes = useStyles();
  const {
    activeSubBlock,
    getBlockById,
    undo,
    redo,
    stack,
    stackIndex,
    saveBlock,
    setActiveSubBlock,
    setActiveBMInstance,
  } = useBlocksCtrl();
  const canvas = useRef<HTMLCanvasElement>(null);
  const [scale, setScale] = useState<number>(1.5);
  const layout = getters.getLayout(activeBlock);
  const block = activeBlock;
  const { sizeRange } = getters.getMetrics(block);
  const [id, setId] = useState<string>("");
  const [previewWidth, setPreviewWidth] = useState(0.6);
  const [previewDepth, setPreviewHeight] = useState(0.6);
  const [viewLayers, setViewLayers] = useState<boolean>(false);
  // const [viewGuide, setViewGuide] = useState<boolean>(false);
  const [hoveredBlock, setHoveredBlock] = useState<string>("");
  const [grid, setGrid] = useState(false);
  const [dimensions, setDimensions] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const previewRotation = 0;
  const flexibility = useMemo(
    () => layout.flexibility || [0, 0],
    [layout.flexibility]
  );

  const handleKeyboardEvent = (event: KeyboardEvent) => {
    const selection = window.getSelection();
    if ((event.metaKey || event.ctrlKey) && event.code === "KeyY") {
      event.preventDefault();
      redo();
      setTrigger(!trigger);
    }

    if (
      (event.metaKey || event.ctrlKey) &&
      event.code === "KeyZ" &&
      stackIndex !== 0
    ) {
      event.preventDefault();
      undo();
      setTrigger(!trigger);
    }

    if (
      (event.key === "Backspace" || event.key === "Delete") &&
      selection?.focusNode?.textContent === "Preview Width"
    ) {
      if (block) {
        const deleted = deleteBlock(block, activeSubBlock);
        setActiveSubBlock("");
        saveBlock(deleted);
        setTrigger(!trigger);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyboardEvent);
    return () => {
      document.removeEventListener("keydown", handleKeyboardEvent);
    };
  });

  const toggleLayers = () => {
    setViewLayers(!viewLayers);
  };

  const size: Point = useMemo(() => {
    const range: Point[] = sizeRange
      ? sizeRange
      : [
          [0, 100],
          [0, 100],
        ];
    const baseSize: Point = [
      lerp(range[0][0], range[0][1] + flexibility[0], previewWidth),
      lerp(range[1][0], range[1][1] + flexibility[1], previewDepth),
    ];
    const isHorizontal = (previewRotation / 90) % 2 === 0;
    return isHorizontal ? baseSize : [baseSize[1], baseSize[0]];
  }, [sizeRange, previewWidth, previewDepth, flexibility]);

  const mat = mat4.fromRotationTranslationScale(
    mat4.create(),
    quat.fromValues(0, 0, previewRotation, 1),
    vec3.fromValues(0, 0, 0),
    vec3.fromValues(1, 1, 1)
  );

  computeMetrics(block, getBlockById);
  const instance = makeBlockInstance(block, mat, size, getBlockById);
  const boxes = makeHitBoxes(instance);
  const blockMap: { [k: string]: Block } = {};
  const bi = getBlockInstances(instance);
  // eslint-disable-next-line @typescript-eslint/no-shadow
  bi.map((block) => (blockMap[block.id] = block));
  const xform = mat4.fromRotationTranslationScale(
    mat4.create(),
    quat.fromValues(0, 0, previewRotation, 1),
    vec3.fromValues(
      width / 2 - (size[0] * scale) / 2,
      height / 2 - (size[1] * scale) / 2,
      0
    ),
    vec3.fromValues(scale, scale, scale)
  );

  const render = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (instance: Block, s?: [number, number], m?: mat4) => {
      const mm = m ? m : xform;
      const ss = s ? s : size;
      const el = canvas.current;
      if (!el) return;
      const ctx = el.getContext("2d");
      if (!ctx) return;
      clearCanvas(ctx, width, height);
      ctx.fillStyle = "#F9FBFF";
      ctx.fillRect(0, 0, 10000, 10000);
      renderShadow(
        ctx,
        width / 2 - (ss[0] * scale) / 2,
        height / 2 - (ss[1] * scale) / 2,
        ss[0] * scale,
        ss[1] * scale
      );
      if (activeSubBlock) {
        applyBlockId(instance, activeSubBlock, (b, _m) => {
          const x = mat4.mul(mat4.create(), xform, _m);
          // renderGeometry(ctx, b.geometry, { fill: true, fillStyle: `rgba(${blue},0.20)` }, x);
          if (dimensions) renderBlockDimensions(ctx, instance, b, xform, x);
        });
      } else {
        if (dimensions)
          renderBlockDimensions(ctx, instance, instance, xform, xform);
      }
      renderBlockInstance(
        ctx,
        instance,
        mm,
        getBlockById,
        false,
        grid,
        activeSubBlock,
        true
      );
      if (hoveredBlock !== "") {
        applyBlockId(instance, hoveredBlock, (b, _m) => {
          const x = mat4.mul(mat4.create(), xform, _m);
          renderGeometry(
            ctx,
            b.geometry,
            { stroke: true, strokeStyle: `rgba(${blue},1)`, lineWidth: 3 },
            x
          );
        });
      }
      if (activeSubBlock && activeSubBlock !== "") {
        applyBlockId(instance, activeSubBlock, (b, _m) => {
          const x = mat4.mul(mat4.create(), xform, _m);
          const { padding } = getters.getLayout(b);
          const box = b.geometry.box;
          if (padding && box) {
            const _x = mat4.mul(mat4.create(), xform, _m);
            renderPadding(ctx, box, padding, _x);
          }
          renderDividers(ctx, b, x, true);
          renderGeometry(
            ctx,
            b.geometry,
            { stroke: true, strokeStyle: `rgba(${blue},1)`, lineWidth: 3 },
            x
          );
        });
      }
      // renderHitBoxes(ctx, boxes, xform);
    },
    [
      activeSubBlock,
      getBlockById,
      height,
      size,
      width,
      xform,
      dimensions,
      grid,
      scale,
      hoveredBlock,
    ]
  );

  const handleZoom = (e: React.WheelEvent<HTMLCanvasElement>) => {
    setScale(Math.max(1, Math.min(2.5, scale - e.deltaY * -0.002)));
  };

  render(instance, size, xform);

  const handleClick = (evt: React.MouseEvent<HTMLElement>) => {
    const el = canvas.current;
    if (activeBlock.role === "asset") return;
    if (!el) return;
    const ctx = el.getContext("2d");
    if (!ctx) return;
    const mousePos = getMousePos(el, evt);
    let hasId = "";

    // check if user clicked on a hitbox
    if (!evt.shiftKey) {
      for (let i = boxes.length - 1; i >= 0; i--) {
        const box = boxes[i];
        const x = mat4.mul(mat4.create(), xform, box.mat);
        // if clicked on hitbox
        if (box.box && isInsideMat([mousePos.x, mousePos.y], box.box, x)) {
          // and the block associated with the hitbox is a block-instance
          if (
            box.block.children.every((child) => child.role === "block-instance")
          ) {
            applyBlockId(block, box.block.id, (b) => {
              // if it's on the periphery
              if (box.outer) {
                const active = addSplit(b, box.idx, true);
                setActiveSubBlock(active.id);
                saveBlock(block);
              } else {
                let item;
                const target = b.children[Math.max(0, box.idx - 1)];
                // if target is a container, create a "default" block, otherwise clone the target
                if (!target.symbol || target.symbol === "") {
                  const direction: Direction =
                    box.block.props.layout?.flex?.mode === "vertical"
                      ? "horizontal"
                      : "vertical";
                  item = defaultByType("focus", direction, 0);
                } else {
                  item = cloneBlock(target);
                  item.id = uuid();
                }
                b.children.splice(box.idx, 0, item);
                setActiveSubBlock(item.id);
                saveBlock(block);
              }
            });
          } else {
            applyBlockId(block, box.block.id, (b) => {
              if (box.outer) {
                const active = addSplit(b, box.idx, true);
                setActiveSubBlock(active.id);
                saveBlock(block);
              } else {
                const active = addSplit(b, box.idx);
                setActiveSubBlock(active.id);
                saveBlock(block);
              }
            });
          }
          return;
        }
      }
    }

    // check if clicked on a block
    applyBlocks(instance, (b, m) => {
      // get parents of leaf blocks
      if (
        b.role === "block-instance" &&
        (b.children.length === 0 ||
          b.children.every((c) => c.role === "asset-instance"))
      ) {
        const x = mat4.mul(mat4.create(), xform, m);
        // check if mouse is on the block
        if (
          b.geometry.box &&
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          isInsideMat([mousePos.x, mousePos.y], b.geometry.box!, x)
        ) {
          let active = b;
          if (evt.detail > 1) {
            let i = 1;
            while (i < evt.detail) {
              i++;
              const tmp = getParentBlock(instance, active.id);
              if (tmp) active = tmp;
              else break;
            }
          }
          setId(active.id);
          hasId = active.id;
          ctx.fillStyle = "rgba(255,255,255,0.8)";
          ctx.fillRect(0, 0, 10000, 10000);
          if (instance) {
            const found = findBlock(instance, active.id);
            if (found) {
              renderBlockInstance(
                ctx,
                found,
                xform,
                getBlockById,
                false,
                grid,
                activeSubBlock
              );
              renderGeometry(
                ctx,
                b.geometry,
                { stroke: true, strokeStyle: `rgba(${blue},1)`, lineWidth: 3 },
                x
              );
            }
          }
        }
      }
    });
    setActiveSubBlock(hasId);
    if (id && hasId === "") {
      if (instance) render(instance);
      setActiveSubBlock("");
    }
    setTrigger(!trigger);
  };

  const handleMouseMove = (evt: React.MouseEvent<HTMLElement>) => {
    evt.stopPropagation();
    const el = canvas.current;
    if (activeBlock.role === "asset") return;
    if (el) {
      const ctx = el.getContext("2d");
      if (!ctx) return;
      const mousePos = getMousePos(el, evt);
      el.style.cursor = "default";
      render(instance);
      let hoveredHitBox: HitBox | undefined;
      if (!evt.shiftKey) {
        for (let i = boxes.length - 1; i >= 0; i--) {
          const box = boxes[i];
          const x = mat4.mul(mat4.create(), xform, box.mat);
          if (box.box && isInsideMat([mousePos.x, mousePos.y], box.box, x)) {
            hoveredHitBox = box;
            break;
          }
        }
      }

      // hover block effect
      if (!hoveredHitBox) {
        applyBlocks(instance, (b, m) => {
          // get parents of leaf blocks
          if (
            b.role === "block-instance" &&
            (b.children.length === 0 ||
              b.children.every((c) => c.role === "asset-instance"))
          ) {
            const x = mat4.mul(mat4.create(), xform, m);
            // check if mouse is on the block
            if (
              b.geometry.box &&
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              isInsideMat([mousePos.x, mousePos.y], b.geometry.box!, x)
            ) {
              if (b.id === activeSubBlock) return;
              el.style.cursor = "pointer";
              renderGeometry(
                ctx,
                b.geometry,
                { stroke: true, strokeStyle: `rgba(${blue},1)`, lineWidth: 3 },
                x
              );
            }
          }
        });
      }
      // active block effect
      if (activeSubBlock) {
        applyBlockId(instance, activeSubBlock, (b, m) => {
          const x = mat4.mul(mat4.create(), xform, m);
          renderGeometry(
            ctx,
            b.geometry,
            { stroke: true, strokeStyle: `rgba(${blue},1)`, lineWidth: 3 },
            x
          );
        });
      }
      // create block
      if (hoveredHitBox) {
        const x = mat4.mul(mat4.create(), xform, hoveredHitBox.mat);
        el.style.cursor = "pointer";
        renderGeometry(
          ctx,
          { type: "polygon" as GeometryType, polygon: [hoveredHitBox.line] },
          { stroke: true, strokeStyle: `rgba(${blue},1)`, lineWidth: 3 },
          x
        );

        renderGeometry(
          ctx,
          hoveredHitBox.block.geometry,
          {
            stroke: true,
            strokeStyle: `rgba(${blue},1)`,
            lineWidth: 2,
            lineDash: [12, 6],
          },
          mat4.mul(mat4.create(), xform, hoveredHitBox.blockMat)
        );

        // draw (+)
        const [start, end] = hoveredHitBox.line;
        const center = [(start[0] + end[0]) / 2, (start[1] + end[1]) / 2];
        const _size = 5;
        const radius = 10;
        ctx.save();
        applyMatrix(ctx, x);
        ctx.beginPath();
        ctx.fillStyle = `rgba(${blue},1)`;
        ctx.ellipse(center[0], center[1], radius, radius, 0, 0, Math.PI * 2);
        ctx.fill();
        ctx.strokeStyle = "#ffffff";
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(center[0] - _size, center[1]);
        ctx.lineTo(center[0] + _size, center[1]);
        ctx.stroke();
        ctx.beginPath();
        ctx.moveTo(center[0], center[1] - _size);
        ctx.lineTo(center[0], center[1] + _size);
        ctx.stroke();

        ctx.restore();
      }
    }
  };

  // TODO(JV): Temporary to allow us to create fancy blocks. Remove once it's not needed anymore.
  (window as any).getBlock = () => JSON.stringify(activeBlock, null, 2);
  (window as any).setBlock = saveBlock;

  useEffect(() => {
    const el = canvas.current;
    if (el) createHiDPICanvas(el, width, height);
  }, [width, height]);

  useEffect(() => {
    render(instance, size, xform);
  }, [instance, size, xform, render]);

  useEffect(() => {
    setActiveBMInstance(instance);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewWidth, previewDepth, trigger]);

  return (
    <>
      <canvas
        ref={canvas}
        width={width}
        height={height}
        tabIndex={999} // allows the canvas to get focus
        onClick={handleClick}
        onMouseMove={handleMouseMove}
        onWheel={handleZoom}
      />
      {(sizeRange[0][0] !== sizeRange[0][1] || flexibility[0] > 0) && (
        <div className={classes.previewWidthControl}>
          <div className={classes.previewWidthControlTitle}>Preview Width</div>
          <Slider
            className={classes.previewWidthControlSlider}
            defaultValue={0.6}
            step={0.01}
            min={0}
            max={1}
            onChange={(e, v) => {
              if (!Array.isArray(v)) setPreviewWidth(v);
            }}
          />
          <div className={classes.previewWidthControlLabels}>
            <div>Smallest</div>
            <div>Largest</div>
          </div>
        </div>
      )}
      {(sizeRange[1][0] !== sizeRange[1][1] || flexibility[1] > 0) && (
        <div className={classes.previewDepthControl}>
          <div className={classes.previewDepthControlTitle}>Preview Depth</div>
          <div className={classes.previewDepthControlLabels}>
            <div>Smallest</div>
            <div>Largest</div>
          </div>
          <Slider
            className={classes.previewDepthControlSlider}
            defaultValue={0.6}
            orientation={"vertical"}
            step={0.01}
            min={0}
            max={1}
            onChange={(e, v) => {
              if (!Array.isArray(v)) setPreviewHeight(v);
            }}
          />
        </div>
      )}
      <Layers
        open={viewLayers}
        block={block}
        onHover={setHoveredBlock}
        onClick={setActiveSubBlock}
      />
      <div className={classes.fab}>
        {activeBlock.role !== "asset" && (
          <>
            <Tooltip title={"View Layers"} enterDelay={400} placement={"right"}>
              <IconButton
                color={viewLayers ? "primary" : "default"}
                onClick={toggleLayers}
              >
                <LayersIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={"View Grid"} enterDelay={400} placement={"right"}>
              <IconButton
                color={grid ? "primary" : "default"}
                onClick={() => setGrid(!grid)}
              >
                {grid ? <GridOn /> : <GridOn />}
              </IconButton>
            </Tooltip>
          </>
        )}
        <Tooltip title={"View Dimensions"} enterDelay={400} placement={"right"}>
          <IconButton
            color={dimensions ? "primary" : "default"}
            onClick={() => setDimensions(!dimensions)}
          >
            {dimensions ? <SpaceBar /> : <SpaceBar />}
          </IconButton>
        </Tooltip>
        <Tooltip
          title={stackIndex === 0 ? "" : "Undo"}
          enterDelay={400}
          placement={"right"}
        >
          <IconButton onClick={undo} disabled={stackIndex === 0}>
            <Undo />
          </IconButton>
        </Tooltip>
        <Tooltip
          title={stackIndex === stack.length - 1 ? "" : "Redo"}
          enterDelay={400}
          placement={"right"}
        >
          <IconButton onClick={redo} disabled={stackIndex === stack.length - 1}>
            <Redo />
          </IconButton>
        </Tooltip>
      </div>
    </>
  );
};

export default CanvasWrapper;
