import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { HelpguideHandle, HelpguideWrapperDiv } from "@outerlabs/helpguide";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    fontFamily: "GoogleSansRegular",
    fontSize: "15.5px",
    lineHeight: "20px",
    letterSpacing: "0.25px",
    marginBottom: "20px",
    paddingRight: "10px",
  },
  title: {
    textAlign: "end",
  },
  description: {
    color: "#878787",
  },
  valuesContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "150px",
  },
  value: {
    width: "75px",
    textAlign: "right",
    color: "#878787",
  },
}));

interface RowProps {
  description: string;
  firstColumn: any;
  secondColumn: any;
  helpguideTooltip?: string;
}

const MetricsRow: React.FC<RowProps> = ({
  description,
  firstColumn,
  secondColumn,
  helpguideTooltip = "",
}) => {
  const classes = useStyles();
  return (
    <HelpguideWrapperDiv>
      <div className={classes.container}>
        <div className={classes.description}>{description}</div>
        {helpguideTooltip && (
          <HelpguideHandle isSmall tooltipKey={helpguideTooltip} />
        )}
        <div className={classes.valuesContainer}>
          <div className={classes.value}>{firstColumn}</div>
          <div className={classes.value}>{secondColumn}</div>
        </div>
      </div>
    </HelpguideWrapperDiv>
  );
};

export default MetricsRow;
