import { createContainer } from "unstated-next";
import getFloorRegions from "../api/getFloorRegions";
import { BlockType } from "./renderer";
import { PortfolioBlockInstance, RendererSettings } from "../types";
import { calcDesks } from "../metrics/remove-desks";
import getFeatures from "../api/getFeatures";
import { encodeMetricId } from "../metrics/id";
import { useProjectCtrl } from "./project";
import { useBuildingsCtrl } from "./buildings";
import analytics from "../analytics";
import { useBlockInstanceCtrl } from "./block-instances";
import { Block } from "../../blocks/lib/types";
import { useBlocksCtrl } from "./blocks";
import { getters } from "../../blocks/lib/constants";
import { subdivideRegion } from "../../blocks/lib/util";
import { getSettingsFile } from "../api/rendererSettings";
import { useState } from "react";
import { sortRegion } from "../isp-canvas/utils";
import { useSettingsCtrl } from "./settings";

export interface UseAutoMagicState {
  autoMagicBuilding: (
    projectID: string,
    buildingId: string,
    strategyID: string
  ) => Promise<void>;
  regions: { [k: string]: number[][][] };
}

const useAutoMagicState = (): UseAutoMagicState => {
  const { buildings } = useBuildingsCtrl();
  const { newInstance } = useBlockInstanceCtrl();
  const { updateAllFloorMetrics } = useSettingsCtrl();
  const { blocks: blockDefs } = useBlocksCtrl();
  const { project } = useProjectCtrl();
  const [regions, setRegions] = useState<{ [k: string]: number[][][] }>({});

  const autoMagicFloor = async (
    projectID: string,
    buildingID: string,
    floorID: string,
    strategyID: string
  ): Promise<RendererSettings | undefined> => {
    if (!project) return;
    const features = await getFeatures(buildingID, floorID);
    const id = encodeMetricId(buildingID, strategyID, floorID);
    const metric = project.metrics[id];
    const settings = await getSettingsFile(
      projectID,
      buildingID,
      floorID,
      strategyID
    );
    let deleteIds: string[] = [];
    if (settings && settings.blocks && settings.blocks.length > 0) {
      deleteIds = settings.blocks.map((el) => el[0].id);
    }

    if (!metric) {
      return;
    }

    const data = await getFloorRegions(buildingID, floorID);
    if (!data || !data.length) {
      return;
    }

    let blocks: PortfolioBlockInstance[][] = [];
    setRegions((r) => ({ ...r, [floorID]: data }));

    // get blocks tagged with "automagic" to use for automagic layout
    const deskBlocks = blockDefs.filter((el) => {
      const def = getters.getDefinition(el);
      return def.tags.includes("automagic");
    });

    const newRegions = data.map((r) => sortRegion(r));

    for (let i = 0; i < newRegions.length; i++) {
      blocks = blocks.concat(subdivide(deskBlocks, newRegions[i]));
    }

    const desks = calcDesks(metric, features, blocks);
    return {
      desks,
      blocks,
      blockChanges: { put: blocks.map((el) => el[0].id), delete: deleteIds },
    };
  };

  const autoMagicBuilding = async (
    projectID: string,
    buildingID: string,
    strategyID: string
  ) => {
    const building = buildings[buildingID];
    if (
      !building ||
      !building.AvailableFloors ||
      !building.AvailableFloors.length
    )
      return;

    const settingSet: { [key: string]: RendererSettings } = {};
    for (let i = 0; i < building.AvailableFloors.length; i++) {
      const floorSettings = await autoMagicFloor(
        projectID,
        buildingID,
        building.AvailableFloors[i],
        strategyID
      );
      if (floorSettings)
        settingSet[building.AvailableFloors[i]] = floorSettings;
    }

    await updateAllFloorMetrics(buildingID, strategyID, settingSet);
    analytics.plannedAutomagically();
  };

  // given a series of blocks with min/max dimensions and a region, find the optimal subdivision
  const subdivide = (blocks: Block[], region: number[][]): Block[][] => {
    const newRegions = subdivideRegion(blocks, region);
    const instances = newRegions.map((s) => {
      return newInstance({
        blockId: s.blockId,
        region: s.region,
        blockType: BlockType.Focus,
      });
    });
    return instances;
  };

  return {
    autoMagicBuilding,
    regions,
  };
};

export const AutoMagicController = createContainer(useAutoMagicState);
export const AutoMagicProvider = AutoMagicController.Provider;
export const useAutoMagicCtrl = () => AutoMagicController.useContainer();
