import { sha1 } from "sha.js";

export type Point = [number, number];
export const format = (groupCode: string, value: string | number): string => {
  return groupCode + "\n" + value + "\n";
};

export const cleanString = (value: string): string => {
  value = value.replace("#", "_");
  value = value.replace(".", "_");
  return value;
};

export const encode = (s: string): string => {
  const sha = new sha1();
  return sha.update(s).digest("hex").slice(0, 10);
};

export const quadraticBezierValue = (
  t: number,
  v0: number,
  v1: number,
  v2: number
) => {
  const k = 1 - t;
  return k * k * v0 + 2 * k * t * v1 + t * t * v2;
};

export const cubicBezierValue = (
  t: number,
  v0: number,
  v1: number,
  v2: number,
  v3: number
) => {
  const k = 1 - t;
  return (
    k * k * k * v0 + 3 * k * k * t * v1 + 3 * k * t * t * v2 + t * t * t * v3
  );
};

export const polygonizeCurve = (
  points: Point[],
  resolution: number
): Point[] => {
  const out: Point[] = [];
  const n = resolution + 2;
  if (points.length === 3) {
    for (let i = 1; i < n; i++) {
      out.push([
        quadraticBezierValue(i / n, points[0][0], points[1][0], points[2][0]),
        quadraticBezierValue(i / n, points[0][1], points[1][1], points[2][1]),
      ]);
    }
  } else if (points.length === 4) {
    for (let i = 1; i < n; i++) {
      out.push([
        cubicBezierValue(
          i / n,
          points[0][0],
          points[1][0],
          points[2][0],
          points[3][0]
        ),
        cubicBezierValue(
          i / n,
          points[0][1],
          points[1][1],
          points[2][1],
          points[3][1]
        ),
      ]);
    }
  }
  out.push([...points[points.length - 1]]);
  return out;
};

export const polygonizeEllipse = (
  x: number,
  y: number,
  rx: number,
  ry: number,
  resolution: number
): Point[] => {
  const out: Point[] = [];
  for (let i = 0; i <= resolution; i++) {
    const t = (i / resolution) * Math.PI * 2;
    out.push([rx * Math.cos(t) + x, ry * Math.sin(t) + y]);
  }
  return out;
};
