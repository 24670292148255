import React, { ComponentType, FC } from "react";
import { AutoMagicProvider } from "../containers";

function withAutoMagic<Props>(Component: ComponentType<Props>): FC<Props> {
  return (props) => (
    <AutoMagicProvider>
      <Component {...props} />
    </AutoMagicProvider>
  );
}

export default withAutoMagic;
